/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import { useEffect, useState, Dispatch, SetStateAction, useMemo, Fragment } from "react";
import Axios from "helpers/Interceptor";
import GeneralPerformanceStats from "../CampaignDetails/GeneralPerformance";
import DailyStats from "../CampaignDetails/DailyStats";
import DemographicsStats from "../CampaignDetails/DemographicsStats";
import ImpactStats from "../CampaignDetails/ImpactStats/index";
import { daysCalculator, daysLeftCalculator } from "helpers/General";
import moment from "moment";
import {
  FollowersData,
  StreamsData,
  FollowersChartData,
  GrowthData,
  RecordType,
  StreamsChartData,
  AdCampaignStatus,
  OverallCampaignStatusType,
  OverallCampaignStatus,
  BrandContent,
  FacebookError,
  CurrentBrand,
  CurrencyCodesType,
  StreamingPlatformsData,
  ConnectorIndicatorTypes,
  Platforms,
} from "types/global";
import { track } from "analytics";
import { country_continents } from "helpers/Countries";
import { queryFB, queryFbAdCampaignInsights } from "helpers/FB";
import UnlockDataBanner from "components/shareable/UnlockDataBanner";
import UnlockPresavesByDayImage from "assets/images/unlock-presaves-by-day.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import EditBudgetModal from '../Components/Modals/EditBudgetModal';
import { DEFAULT_CURRENCY, MINIMUM_DAILY_BUDGET_PER_DAY_IN_DOLLARS } from './BudgetTab/constants';
import OverviewCampaignCard from "../Components/OverviewCampaignCard";
import { useStyles, CampaignDetailsContainer, TabsContainer } from "./styles";
import EditEndDateModal from '../Components/Modals/EditEndDateModal';
import PauseCampaignModal from '../Components/PauseCampaignModal';
import DuplicateCampaignModal from '../Components/DuplicateCampaignModal/index';
import DuplicateAnotherSongsAds from 'assets/images/campaignDetails/spotifyHeadphones.png';
import ErrorUpdatingCampaignModal from '../Components/ErrorUpdatingCampaignModal';
import { TabPanel, useTabs } from "react-headless-tabs";
import MarketsDetail from "../Components/MarketsDetail";
import { DEFAULT_TAB, TABS } from "./constants";
import SymphonyTabs from "components/shareable/SymphonyTabs";
import { NewLoader } from "components/Loader/ConnectBrandLoader";
import { isLoggedInFbUser, showAdsStatusLabel, showCampaignLengthStatus, isUsingConversionsApi, getAdAccountDetails, SelectedFbAdAccount, handleUpdateCampaignStatusIfMissMatch, getCardDetails, getCampaignAdAccountInfo } from '../utils/fbCampaigns';
import FbTokenExpired from '../Components/FbTokenExpired';
import AdsStatusMessage from '../Components/AdsStatusMessage';
import AdsPreview, { CreativePerformance, getViewType } from "../Components/AdsPreview";
import { SelectedPost } from "components/shareable/FacebookAdPreview";
import { getCreativePerformanceData } from "../Components/AdsPreview/api";
import useModal from "Hooks/useModal";
import { ColumnContainer, ConversionsEventBox, ConversionsEventContainer, RowContainer, SectionContainer, SectionTitleContainer, SpaceBetweenContainer } from '../CampaignDetails/styles';
import { Body1, Caption, Subtitle1 } from 'components/shareable/Typography';
import { SystemColors } from "types/globalStyles";
import Intercom from "helpers/Intercom";
import { Container, SymphonyTooltip } from "styles/shared";
import EditCampaignNameModal from "../Components/Modals/EditCampaignNameModal";
import { reportDownloader } from "../utils";
import { FacebookAdAccountInfo, FacebookCampaignIssuesBanner } from "../Components/FacebookCampaignIssuesBanner";
import {  renderErrorStates } from "../utils/FacebookErrorsActions";
import { getCampaignDetails } from "pages/post-auth/MarketingCampaignDetails/utils";
import getSymbolFromCurrency from "currency-symbol-map";
import { updateCampaignStatus as updateCampaignStatusInDb } from 'services/symphonyApi';
import { StreamingLinksBreakdown } from "../CampaignDetails/StreamingLinksBreakdown";

import { ImpactStatsOption } from "../CampaignDetails/ImpactStats/utils";
import getBrandContent from "pages/post-auth/Website/controllers/getBrandContent";
import useAttributionData, { IMPACT_STATS_OPTIONS } from "../hooks/useAttributionData";
import { CAMPAIGN_TYPE_MAPPINGS } from "../utils/marketing";

const STREAMING_SERVICES_LAUNCH_DATE = "2024-04-11 18:00:00.000"

export interface PlatformData {
  percentageChange: number;
  netChange: number;
  currentValue: number;
  dailyStats: FollowersChartData[] | StreamsChartData[];
}
export interface AttributionData {
  description: ImpactStatsOption;
  data: PlatformData;
}

export const initialStreamsData = {
  data: {
    data: [] as StreamsChartData[],
    viewsGrowth: {
      currentGrowthValue: "",
      difference: 0,
      percentage: 0,
    },
    platform: "",
    stat: "",
  },
  isError: false,
  message: null,
} as StreamsData;

export const initialFollowersData = {
  data: {
    platform: "",
    stat: "",
    chartData: [] as FollowersChartData[],
    growthData: {
      currentGrowthValue: "",
      difference: 0,
      percentage: 0,
    } as GrowthData,
  },
  isError: false,
  message: null,
} as FollowersData;

const impactActions: Record<string, string> = {
  all: "Symphony Website",
  "all streaming services": "Symphony Website",
  spotify: "Spotify",
  apple_music: "Apple Music",
  youtube: "YouTube",
  custom: 'Your Custom Link',
  soundcloud: 'SoundCloud',
  deezer: 'Deezer',
  tidal: 'Tidal'
};


const {
  ACTIVE,
  DEFAULT_OVERALL_CAMPAIGN_STATUS,
  DENIED,
  ERROR,
  IN_REVIEW,
  IN_REVIEW_AFTER_ACTIVE,
  LIVE_WITH_ISSUES,
  LIVE_WITH_NO_STATS,
  PAUSED,
  TO_RETRY,
  PENDING,
  ANTICIPATED_PAUSE,
} = OverallCampaignStatus;

const defaultOverallCampaignStatus = {
  status: DEFAULT_OVERALL_CAMPAIGN_STATUS,
  note: null,
} as OverallCampaignStatusType;

export default function CampaignDetails(props: any) {
  const theme = useTheme()
  const setLargeView = useMediaQuery(theme.breakpoints.up(1200));
  const setMediumView = useMediaQuery(theme.breakpoints.between(768, 1200));
  const setSmallView = useMediaQuery(theme.breakpoints.between(701, 768));
  const setMobileView = useMediaQuery(theme.breakpoints.down(701));
  const hideBannerImage = useMediaQuery(theme.breakpoints.down(701));
  const classes = useStyles(theme)

  const [campaignStatusFromDatabase, setCampaignStatusFromDatabase] = useState<string | null>(null);

  const { selectedCampaign, currentBrand, isProUser, handleGoBack } = props;
  if (campaignStatusFromDatabase) selectedCampaign.status = campaignStatusFromDatabase;
  const { campaign_metadata, id: campaignId, status: statusInDb, createdAt } = selectedCampaign;
  const {
    friendlyName = null,
    content,
    platform_details,
    budget: initialBudget,
    startDate,
    endDate: initialEndDate,
    campaign_state,
    creative,
    campaign_type,
    errors: facebookErrors,
    currency,
  } = campaign_metadata

  const createdAfterStreamingServicesUpdate = moment(createdAt).isAfter(STREAMING_SERVICES_LAUNCH_DATE)

  const currentAdAccountInfo = getCampaignAdAccountInfo(currentBrand, campaign_metadata)
  const facebookErrorCodes: string[] = facebookErrors?.map((error: FacebookError) => error.code)

  let { media_url, owner, all, media_type, preview_shareable_link, caption: creativeCaption, thumbnail_url } = creative

  const selectMediaURL = () => {
    if (media_url) return media_url
    if (all) return all[0].url
    return undefined
  }

  const {
    name: contentName,
    thumbnail_url: contentThumbnail,
    slug: contentSlug,
    type: contentType,
    cta: callToAction,
    mainTrack = null
  } = content;

  const recordType = campaign_metadata?.campaign_state?.record?.type as
    | RecordType
    | undefined;

  const tabsValues = TABS.map((tab) => tab.value)
  const [selectedTab, setSelectedTab] = useTabs(tabsValues, DEFAULT_TAB);

  const [isLoading, setLoading] = useState(true);
  const [performanceStats, setPerformanceStats] = useState<any>(null);
  const [impactStats, setImpactStats] = useState<any>(null);
  const [adAccountStatus, setAdAccountStatus] = useState<any>(null)
  const [campaignStatuses, setCampaignStatuses] = useState<any>(null);
  const [adStatuses, setAdStatuses] = useState<any>(null);
  const [dailyStats, setDailyStats] = useState<any>(null)
  const [streamingLinksBreakdown, setStreamingLinksBreakdown] = useState<StreamingPlatformsData | null>(null)
  const [geographicBreakdown, setGeographicBreakdown] = useState<any>(null)
  const [demographicBreakdown, setDemograhicBreakdown] = useState<any>(null)
  const [overallCampaignStatus, setOverallCampaignStatus] = useState<OverallCampaignStatusType>(defaultOverallCampaignStatus);
  const [isShazamConnected, setIsShazamConnected] = useState(false)
  const [selectedCurrentBrand, setSelectedCurrentBrand] = useState(currentBrand);


  const [budget, setBudget] = useState<number>(initialBudget);
  const [endDate, setEndDate] = useState<string>(initialEndDate);
  const [campaignName, setCampaignName] = useState<string>(friendlyName || content?.name || content?.campaign_name);
  const [showPauseCampaignModal, setShowPauseCampaignModal] = useState<boolean>(false);
  const [showDuplicateCampaignModal, setShowDuplicateCampaignModal] = useState<boolean>(false);
  const [currencyCode, setCurrencyCode] = useState<CurrencyCodesType>(currency || DEFAULT_CURRENCY);

  // =============
  // error states
  // =============
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [errorModalText, setErrorModalText] = useState<{
    title: string | null | undefined;
    message: string | null | undefined;
  }>({
    title: null,
    message: null
  });

  useEffect(() => {
    if (!showErrorModal) {
      setTimeout(() => {
        setErrorModalText({
          title: null,
          message: null
        })
      }, 500)
    }
  }, [showErrorModal])

  useEffect(() => {
    if (!currency) {
      getAdAccountDetails(
        currentAdAccountInfo as SelectedFbAdAccount,
        platform_details.access_token || currentBrand.connections?.logged_in_fb_user?.access_token
      ).then((response) => {
        if (response?.currency) setCurrencyCode(response.currency)
      })
    }
  }, [currencyCode])

  // campaign stuff
  const [campaignStatus, setCampaignStatus] = useState<string>('');
  const [loggedInFB, setLoggedInFb] = useState<boolean>(false);
  const [loadingFbToken, setLoadingFbToken] = useState<boolean>(true);
  const [performance, setPerformance] = useState<CreativePerformance[]>([])
  const [post, setPost] = useState<SelectedPost>({} as SelectedPost)

  const showViewOnInstagramButton =
    overallCampaignStatus.status !== OverallCampaignStatus.PENDING &&
    overallCampaignStatus.status !== OverallCampaignStatus.TO_RETRY

  const { id: brandId, name: brandName, slug: brandSlug } = selectedCurrentBrand;
  const trackObject = {
    campaign_id: campaignId,
    campaign_type: campaign_type,
    brand_id: brandId,
    brand_slug: brandSlug,
  };
  const budgetModal = useModal({
    nameTrack: 'Open Edit Budget',
    metadata: {
      ...trackObject,
      opened: 'Edit Budget Modal',
    }
  });
  const endDateModal = useModal({
    nameTrack: 'Open Edit End Date',
    metadata: {
      ...trackObject,
      opened: 'Edit End Date Modal',
    }
  });

  const campaignNameModal = useModal({
    nameTrack: 'Open Rename Campaign',
    metadata: {
      ...trackObject,
      opened: 'Rename Campaign Modal',
    }
  });



  const daysLeft = useMemo(() => daysLeftCalculator(new Date().toString(), endDate), [endDate]);
  const campaignLengthInDays = useMemo(() => daysCalculator(startDate, endDate).toFixed(0), [endDate]);

  const hasConversionsApi = isUsingConversionsApi(campaign_metadata)
  const conversionsEventName = campaign_state?.campaign?.conversions?.conversionsEventName || null


  let fbToken = "";

  const getMaxWidth = () => {
    if (setLargeView)
      return "85%"
    if (setMediumView)
      return "90%"
    if (setSmallView)
      return "95%"
    if (setMobileView)
      return "100%"
    return ""
  }


  // ===================================================================
  // ATTRIBUTION STATS FUNCTIONS
  // ===================================================================
  let mainImpactStats;
  if (contentType === 'album') {
    mainImpactStats = [
      IMPACT_STATS_OPTIONS[ConnectorIndicatorTypes.SPOTIFY_FOLLOWERS],
      IMPACT_STATS_OPTIONS[ConnectorIndicatorTypes.INSTAGRAM_FOLLOWERS],
      IMPACT_STATS_OPTIONS[ConnectorIndicatorTypes.INSTAGRAM_POST_TOTAL_INTERACTIONS]

    ].filter(Boolean) as ImpactStatsOption[];
  } else if (contentType === 'playlist') {
    mainImpactStats = [
      IMPACT_STATS_OPTIONS[ConnectorIndicatorTypes.SPOTIFY_PLAYLIST_FOLLOWERS],
      IMPACT_STATS_OPTIONS[ConnectorIndicatorTypes.INSTAGRAM_FOLLOWERS],
      IMPACT_STATS_OPTIONS[ConnectorIndicatorTypes.INSTAGRAM_POST_TOTAL_INTERACTIONS]


    ].filter(Boolean) as ImpactStatsOption[];
  } else {
    mainImpactStats = [
      IMPACT_STATS_OPTIONS[ConnectorIndicatorTypes.SPOTIFY_FOLLOWERS],
      IMPACT_STATS_OPTIONS[ConnectorIndicatorTypes.SPOTIFY_STREAMS],
      IMPACT_STATS_OPTIONS[ConnectorIndicatorTypes.SHAZAMS],
      IMPACT_STATS_OPTIONS[ConnectorIndicatorTypes.INSTAGRAM_FOLLOWERS],
      IMPACT_STATS_OPTIONS[ConnectorIndicatorTypes.INSTAGRAM_POST_TOTAL_INTERACTIONS]

    ].filter(Boolean) as ImpactStatsOption[];
  }

  const { attributionData, loadingAttributionData, fetchData } = useAttributionData({
    mainImpactStats,
    brandId,
    brandSlug,
    campaignId,
    contentSlug,
    startDate,
    endDate,
    subtract: true,
  });

  const handleOnReload = async () => {

    if (attributionData.length > 0) return
    try {

      const brandContent = await getBrandContent(brandSlug, contentSlug)
      const isShazamConnected = Boolean(brandContent?.content_metadata?.external_links?.some(
        (item: { url: string; name: string }) => item?.name === "shazam"
      ))

      IMPACT_STATS_OPTIONS.SHAZAMS!.needsConnection = !isShazamConnected
      if (isProUser) await fetchData();
      else {
        // handle data pulls (if any) for free users
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  // ===================================================================
  // EDITOR FUNCTIONS
  // ===================================================================


  const handleChangeBudget = (newBudget: number) => {
    setLoading(true)
    setBudget(newBudget)
    getCampaignData()
  }

  const handleChangeEndDate = (endDate: string, newBudget?: number) => {
    setLoading(true)
    setEndDate(endDate);
    if (newBudget) setBudget(newBudget)
    getCampaignData()
  }

  const handleChangeName = (name: string) => {
    setLoading(true)
    setCampaignName(name);
    getCampaignData()
  }

  const handlePause = async () => {
    setLoading(true)
    setOverallCampaignStatus(defaultOverallCampaignStatus);
    if (['TO_RETRY', 'ANTICIPATED_PAUSE'].includes(statusInDb)) {
      const updatedCampaign =
        await getCampaignDetails(selectedCampaign.id, currentBrand.connections?.logged_in_fb_user?.access_token)
      setCampaignStatusFromDatabase(updatedCampaign?.status)
    }
    getCampaignData()
  }

  const updateCampaignStatus = async () => {
    setOverallCampaignStatus(defaultOverallCampaignStatus);
    await getCampaignData()
  }

  const handleCloseErrorModal = () => setShowErrorModal(false)
  const handleCloseEditBudgetModal = () => budgetModal.closeModal();
  const handleCloseEditEndDateModal = () => endDateModal.closeModal();
  const handleCloseRenameCampaignModal = () => campaignNameModal.closeModal();


  const handleClosePauseModal = () => setShowPauseCampaignModal(false)
  const handleCloseDuplicateModal = () => setShowDuplicateCampaignModal(false);

  const handleOpenErrorModal = () => setShowErrorModal(true)
  const handleOpenEditBudgetModal = () => budgetModal.openModal();
  const handleOpenEditEndDateModal = () => endDateModal.openModal();
  const handleOpenRenameCampaignModal = () => campaignNameModal.openModal();

  const handleOpenPauseModal = () => setShowPauseCampaignModal(true)
  const handleOpenDuplicateModal = () => setShowDuplicateCampaignModal(true);


  const handleSetMarketsTab = () => {
    setSelectedTab('markets');
    track('Open Edit Locations', {
      ...trackObject,
      opened: 'Edit Locations Modal',
    });
  };

  useEffect(() => {
    if (selectedCampaign) {

      let campaignType = campaign_type === "grow_playlist_followers" ? "grow_playlist_followers" : "increase_record_streams";
      track("Campaign Details Viewed", {
        type: campaignType,

        brand_id: currentBrand.id,
        brand_name: currentBrand.name,
        brand_slug: currentBrand.slug,
        content_name: content.name,
        content_slug: content.slug,
        content_id: content.id,
        is_pro_user: isProUser,

        budget: budget,
        start_date: dayjs(startDate).format("YYYY-MM-DD"),
        end_date: dayjs(endDate).format("YYYY-MM-DD"),
      });
      getInitialState();
    } else {
      console.log("cant get all data");
    }
  }, [selectedCampaign]);


  useEffect(() => {
    if (brandSlug && contentSlug) {
      handleOnReload()
    } else {
      console.log("cant get all data");
    }
  }, [brandSlug, contentSlug]);


  // ===================================================================
  // IMPACT STATS FUNCTIONS
  // ===================================================================


  useEffect(() => {
    if (adAccountStatus && campaignStatuses && adStatuses) {
      // check the statuses of campaigns to seee if either or has an error
      let totalAds = 0;
      const STATUS_MAPPING: Record<string, number> = {
        ACTIVE: 0,
        PAUSED: 0,
        WITH_ISSUES: 0,
        IN_PROCESS: 0,
        PENDING_REVIEW: 0,
        DENIED: 0,
        CAMPAIGN_PAUSED: 0,
        ADSET_PAUSED: 0,
        DISAPPROVED: 0,
        DELETED: 0,
        PENDING_BILLING_INFO: 0,
        ARCHIVED: 0,
      };

      adStatuses.forEach((ad: AdCampaignStatus) => {
        const { effective_status } = ad;

        if (
          STATUS_MAPPING[effective_status] !== null &&
          typeof STATUS_MAPPING[effective_status] !== "undefined"
        ) {
          STATUS_MAPPING[effective_status] += 1;
        }

        totalAds++;
      });

      if (totalAds === 0) {
        if (adAccountStatus.error) {
          // error state
          setOverallCampaignStatus({
            status: ERROR,
            note: "There was an error setting up your campaign. Please try submitting it again or contact us at team@symphony.to.",
          });
        } else if (statusInDb === "ANTICIPATED_PAUSE") {
          setOverallCampaignStatus({
            status: ANTICIPATED_PAUSE,
            note: "Campaign or all adsets paused."
          });
        } else {
          // in review state
          setOverallCampaignStatus({
            status: TO_RETRY,
            note: "Your marketing campaign is in review by Facebook. Once it goes live, you'll be able to see performance stats. It may take up to 24 hours to go live.",
          });
        }
      } else {
        // if 80 - 100% of ads are ACTIVE, set as active
        const areCampaignsPaused = campaignStatuses.every((campaign: AdCampaignStatus) =>
          campaign.effective_status === "PAUSED"
        )

        const pausedAdsDueToParent =
          STATUS_MAPPING["CAMPAIGN_PAUSED"] + STATUS_MAPPING["ADSET_PAUSED"] + STATUS_MAPPING["PAUSED"];
        const totalUnpausedAds = (totalAds - pausedAdsDueToParent);

        const reviewPercentage =
          (STATUS_MAPPING["IN_PROCESS"] + STATUS_MAPPING["PENDING_REVIEW"]) / totalUnpausedAds;
        const activePercentage =
          STATUS_MAPPING["ACTIVE"] / totalUnpausedAds;
        const issuesPercentage =
          (STATUS_MAPPING["WITH_ISSUES"] + STATUS_MAPPING["DELETED"] + STATUS_MAPPING["PENDING_BILLING_INFO"] + STATUS_MAPPING["ARCHIVED"]) / totalUnpausedAds;
        const deniedPercentage =
          (STATUS_MAPPING["DENIED"] + STATUS_MAPPING["DISAPPROVED"]) / totalUnpausedAds;

        const adAccountGood = adAccountStatus.good
        if (totalAds === pausedAdsDueToParent || areCampaignsPaused) {
          setOverallCampaignStatus({
            status: PAUSED,
            note: "Campaign or all adsets paused."
          });
        } else if (activePercentage >= 0.5) {
          if (adAccountGood) {
            if (performanceStats) {
              setOverallCampaignStatus({
                status: ACTIVE,
                note: "Most ads are live."
              });
            } else {
              setOverallCampaignStatus({
                status: LIVE_WITH_NO_STATS,
                note: "Your campaign just went live! As data flows in, you'll start seeing it update here in real time."
              });
            }
          } else {
            setOverallCampaignStatus({
              status: ACTIVE,
              note: '',
            });
          }
        } else {
          if (reviewPercentage >= 0.5 && ["ACTIVE", "FINISHED", "PAUSED"].includes(statusInDb)) {
            setOverallCampaignStatus({
              status: IN_REVIEW_AFTER_ACTIVE,
              note: "Your marketing campaign is in review by Facebook. This is because you modified some fields in your campaign",
            });
          } else if (reviewPercentage >= 0.5) {
            setOverallCampaignStatus({
              status: IN_REVIEW,
              note: "Your marketing campaign is in review by Facebook. Once it goes live, you'll be able to see performance stats. It may take up to 24 hours to go live.",
            });
          } else if (issuesPercentage === 1.0 || deniedPercentage === 1.0) {
            setOverallCampaignStatus({
              status: DENIED,
              note: null,
            });
            return;
          } else if (
            activePercentage >= 0.01 &&
            ((issuesPercentage > 0 && issuesPercentage <= 0.99) ||
              (deniedPercentage > 0 && deniedPercentage <= 0.99))
          ) {
            setOverallCampaignStatus({
              status: LIVE_WITH_ISSUES,
              note: "Your campaign is live in Facebook, though some of your assets were denied by Facebook. We'll continue to run and optimize your campaign as much as we can.",
            });
            return;
          } else if (
            reviewPercentage >= 0.01 &&
            (issuesPercentage >= 0.01 || deniedPercentage >= 0.01) &&
            activePercentage === 0
          ) {
            setOverallCampaignStatus({
              status: IN_REVIEW,
              note: "Your marketing campaign is in review by Facebook. Once it goes live, you'll be able to see performance stats. It may take up to 24 hours to go live.",
            });
          } else if (
            (issuesPercentage > 0 || deniedPercentage > 0)
            && activePercentage === 0
            && reviewPercentage === 0
          ) {
            setOverallCampaignStatus({
              status: LIVE_WITH_ISSUES,
              note: "Some of your ads have issues.",
            });
          }
        }
      }

      setLoading(false)
    }
  }, [campaignStatuses, adStatuses, adAccountStatus, performanceStats]);

  useEffect(() => {
    if (impactStats) {
      setLoading(false);
    }
  }, [impactStats]);

  useEffect(() => {
    setCampaignStatus(showAdsStatusLabel(overallCampaignStatus.status, daysLeft))
  }, [overallCampaignStatus])

  const getInitialState = async () => {
    const logged = await checkFacebookToken();
    if (logged) {
      setLoggedInFb(logged);
      getCampaignData();
    }
    setLoadingFbToken(false);
  };

  const checkFacebookToken = async () => {
    if (loggedInFB) return true;
    const isLoggedInFb = await isLoggedInFbUser(currentBrand.id);
    if (!isLoggedInFb) return false;
    const { accessToken, valid } = isLoggedInFb;
    if (valid) {
      fbToken = accessToken || "";
      return true;
    }
    return false;
  }

  const selectPostCaption = () => {
    if (content.caption) return content.caption
    const { captions } = campaign_state.campaign.creative
    if (!captions) return ""
    const { all, apple, spotify, youtube, apple_music } = captions
    return all || apple || spotify || youtube || apple_music
  }

  async function getCampaignData() {
    try {
      let url = `campaign/brand/${currentBrand.id}/${selectedCampaign.id}/analytics`

      if (fbToken) {
        url += `?access_token=${fbToken}`
      }

      const creativePerformance = await getCreativePerformanceData({
        campaignId,
        access_token: selectedCurrentBrand?.connections?.logged_in_fb_user?.access_token,
        brandId,
      })
      if (creativePerformance) {
        setPerformance(creativePerformance)
        const viewType = getViewType(creativePerformance)
        const newPost = {
          postType: 'instagram',
          caption: creativeCaption || selectPostCaption(),
          media_url: selectMediaURL(),
          media_type: media_type || "VIDEO",
          type: viewType,
          thumbnail_url: all?.length ? all[0].thumbnail : thumbnail_url,
        } as SelectedPost
        setPost(newPost)
      }

      const getData = await Axios.get(url, { timeout: 60 * 1000 })

      if (getData.data && getData.data.data) {
        const {
          adAccountStatus,
          adStatuses,
          campaignPerformanceStats,
          campaignStatuses,
          impactStats,
          dailyStats,
          geographicBreakdown,
          demographicsBreakdown,
          streamingLinksBreakdown,
        } = getData.data.data

        setAdAccountStatus(adAccountStatus)
        setCampaignStatuses(campaignStatuses);
        setAdStatuses(adStatuses)
        setPerformanceStats(campaignPerformanceStats)
        setImpactStats(impactStats);
        setDailyStats(dailyStats)
        setGeographicBreakdown(geographicBreakdown)
        setDemograhicBreakdown(demographicsBreakdown)
        setStreamingLinksBreakdown(streamingLinksBreakdown)
      } else {
        console.error("Go clientside")
      }
    } catch (e) {
      console.log("Error getting data from server, trying locally", e)
      getAdAccountStatus()
      getCampaignStatuses();
      getAdStatuses();
      getCampaignPerfData();
      getPlatformSpecificData();
      getDemographicBreakdown()
      getDailyPerfStats()
      getCountriesBreakdown()
    }
  }

  async function getCountriesBreakdown() {
    try {
      const geographicData = await queryFbAdCampaignInsights({
        ad_account: platform_details.ad_account,
        fields:
          "actions,cpc,cpm,reach,impressions,spend,inline_link_clicks,outbound_clicks,clicks,cost_per_action_type",
        level: "account",
        startDate: startDate,
        endDate: endDate,
        campaignIds: platform_details.campaigns,
        adIds: null,
        daily: null,
        breakdowns: `["country"]`,
        limit: 10,
        sort: `["inline_link_clicks_descending"]`,
      });

      if (geographicData) {
        // map it out with cumulative stats
        const mapped = geographicData.map((o: any) => {
          return {
            country: o.country,
            countryName: (country_continents as any)[o.country]
              ? (country_continents as any)[o.country].name
              : "Unknown",
            cpc: o.cpc ? parseFloat(o.cpc) : 0,
            impressions: o.impressions ? parseInt(o.impressions) : 0,
            clicks: o.inline_link_clicks ? parseInt(o.inline_link_clicks) : 0,
            spend: o.spend ? parseFloat(o.spend) : 0,
          };
        });

        setGeographicBreakdown(mapped);
      }
    } catch (e) {
      console.error(e)
    }
  }

  async function getDemographicBreakdown() {
    try {
      const demographicBreakdown = await queryFbAdCampaignInsights({
        ad_account: platform_details.ad_account,
        fields: 'actions,cpc,cpm,reach,impressions,spend,inline_link_clicks,outbound_clicks,clicks,cost_per_action_type',
        level: 'account',
        startDate: startDate,
        endDate: endDate,
        campaignIds: platform_details.campaigns,
        adIds: null,
        daily: null,
        breakdowns: `["age", "gender"]`,
        limit: 10,
        sort: `["inline_link_clicks_descending"]`
      })


      if (demographicBreakdown) {
        // map it out with cumulative stats

        const mapped = demographicBreakdown.map((o: any) => {

          return {
            age: o.age,
            gender: o.gender,
            cpc: o.cpc ? parseFloat(o.cpc) : 0,
            impressions: o.impressions ? parseInt(o.impressions) : 0,
            clicks: o.inline_link_clicks ? parseInt(o.inline_link_clicks) : 0,
            spend: o.spend ? parseFloat(o.spend) : 0
          }
        })

        setDemograhicBreakdown(mapped)
      }
    } catch (e) {
      console.error(e)
    }
  }

  async function getAdAccountStatus() {
    try {
      const adAccountInfo = await queryFB(`/${platform_details.ad_account}`, {
        params: {
          fields: `account_status,disable_reason`
        }
      })

      if (adAccountInfo.account_status > 1) {
        // if ad account status === DISABLED (code 2), write the disable reason
        const reason = adAccountInfo.account_status === 2 ? adAccountInfo.disable_reason : null
        setAdAccountStatus({
          good: false,
          error: reason ? reason : "error"
        })
      } else {
        setAdAccountStatus({
          good: true,
          error: null
        })
      }
    } catch (e) {
      console.error(e)
    }
  }


  async function getDailyPerfStats() {
    try {
      const campaignDailyData = await queryFbAdCampaignInsights({
        ad_account: platform_details.ad_account,
        fields: 'actions,cpc,cpm,reach,impressions,spend,inline_link_clicks,outbound_clicks,clicks,cost_per_action_type',
        level: 'account',
        startDate: startDate,
        endDate: endDate,
        campaignIds: platform_details.campaigns,
        daily: true,
        sort: null,
        limit: null,
        adIds: null,
        breakdowns: null
      })

      if (campaignDailyData) {
        // map it out with cumulative stats
        var cumulativeSpend = 0
        var cumulativeClicks = 0
        var cumulativeImpressions = 0
        var cumulativeReach = 0

        var combined = campaignDailyData.map((o: any) => {

          const {
            date_start,
            clicks,
            cpc,
            inline_link_clicks,
            reach,
            impressions,
            spend
          } = o

          const formattedDate = dayjs(date_start).format("MM/DD")

          cumulativeSpend += parseFloat(spend)
          cumulativeClicks += parseInt(inline_link_clicks)
          cumulativeImpressions += parseInt(impressions)
          cumulativeReach += parseInt(reach)


          return {
            date: formattedDate,
            totalSpend: cumulativeSpend,
            totalReach: cumulativeReach,
            totalImpressions: cumulativeImpressions,
            totalClicks: cumulativeClicks,
            clicks: clicks,
            cpc: cpc ? parseFloat(cpc) : 0,
            spend: spend ? parseFloat(spend) : 0
          }
        })


        setDailyStats(combined)
      }

    } catch (e) {
      console.error(e)
    }
  }

  async function getCampaignStatuses() {
    try {
      const response = await Axios.get(
        `/campaign/brand/${brandId}/facebook/info`,
        {
          params: {
            fields: "effective_status,id,name,issues_info",
            ids: platform_details.campaigns,
            type: "campaigns",
            limit: null,
          },
        }
      );

      const campaigns = await response?.data?.data;

      const mappedCampaigns = campaigns.filter((o: any) => o.id);

      setCampaignStatuses(mappedCampaigns);
    } catch (error) {
      console.log("Error on getCampaignStatuses function ", error);
    }
  }

  async function getAdStatuses() {
    try {
      const response = await Axios.get(
        `/campaign/brand/${brandId}/facebook/info`,
        {
          params: {
            fields: "effective_status,id,name,issues_info",
            ids: platform_details.campaigns,
            type: "campaigns",
            limit: null,
          },
        }
      );

      const ads = await response?.data?.data;

      const mappedAds = ads.flat().filter((o: any) => o.id);

      setAdStatuses(mappedAds);
    } catch (e) {
      console.error(e)
    }
  }

  async function getCampaignPerfData() {
    try {
      const campaignPerfData = await queryFbAdCampaignInsights({
        ad_account: platform_details.ad_account,
        fields:
          "campaign_name,campaign_id,actions,cpc,cpm,reach,impressions,spend,inline_link_clicks,outbound_clicks,clicks,cost_per_action_type",
        level: "account",
        startDate: startDate,
        endDate: endDate,
        campaignIds: platform_details.campaigns,
        limit: null,
        adIds: null,
        breakdowns: null,
        sort: null,
        daily: null,
      });

      if (campaignPerfData) setPerformanceStats(campaignPerfData[0]);
    } catch (e) {
      console.error(e)
    }
  }

  async function getPlatformSpecificData() {
    try {
      const streamingDataPromises: Array<any> = [];

      for (const streamingSpecificData of platform_details.ad_types) {
        const puller = new Promise(async (resolve, _reject) => {
          const { name, ad_ids } = streamingSpecificData;

          try {
            const campaignPerfData = await queryFbAdCampaignInsights({
              ad_account: platform_details.ad_account,
              fields:
                "actions,cpc,cpm,reach,impressions,spend,inline_link_clicks,outbound_clicks,clicks,cost_per_action_type",
              level: "account",
              startDate: startDate,
              endDate: endDate,
              adIds: ad_ids,
              limit: null,
              campaignIds: null,
              breakdowns: null,
              sort: null,
              daily: null,
            });


            resolve({
              name,
              data: campaignPerfData ? campaignPerfData[0] : null,
            });
          } catch (e) {
            console.error(`error gtting data for ${name}`, e);
            resolve({
              name: name,
              data: null,
            });
          }
        });

        streamingDataPromises.push(puller);
      }

      const pulledData = await Promise.all(streamingDataPromises);

      setImpactStats(pulledData);
    } catch (e) {
      console.error(e)
    }
  }

  const transformImpactStats = (impactStats: any[]): StreamingPlatformsData => {
    return impactStats.map(stat => ({
      serviceName: stat.friendlyName || impactActions[stat.name],
      result: stat.data?.inline_link_clicks || 0
    }));
  };
  function renderStreamingLinkClicksBreakdown() {
    if (createdAfterStreamingServicesUpdate && streamingLinksBreakdown) {
      return (
        <StreamingLinksBreakdown
          isUsingConversionsApi={hasConversionsApi}
          data={streamingLinksBreakdown}
        />
      );
    }

    if (!!impactStats?.length && !hasConversionsApi) {
      return (
        <StreamingLinksBreakdown
          isUsingConversionsApi={hasConversionsApi}
          data={transformImpactStats(impactStats)}
        />
      );
    }

    return null;
  }

  function showAdsData() {
    switch (overallCampaignStatus.status) {
      case PENDING:
        return null;
      case ACTIVE:
      case LIVE_WITH_NO_STATS:
      case LIVE_WITH_ISSUES:
      case PAUSED:
      case IN_REVIEW_AFTER_ACTIVE:
      case DENIED:
        return (
          <>
            <GeneralPerformanceStats
              {...{
                budget,
                performanceStats,
                impactStats,
                currencyCode,
              }}
              campaign_state={campaign_state}
              content={content}
              currentBrand={currentBrand}
              contentType={contentType}
              setMobileView={setMobileView}
              isUsingConversionsApi={hasConversionsApi}
            />
            {renderStreamingLinkClicksBreakdown()}
            <ImpactStats
              {...{
                isShazamConnected,
                isProUser,
                recordType,
                contentType,
                contentName,
                contentSlug,
                brandSlug
              }}
              campaignStatus={statusInDb}
              loadingStats={loadingAttributionData}
              reloadState={handleOnReload}
              attributionStats={attributionData}
              divider={{
                bottom: true,
              }}
              campaignDetails={{
                budget,
                startDate,
                endDate,
              }}
            />
          </>
        );
      default:
        return null;
    }
  }

  function showFreeTierData() {
    return (
      <>
        <GeneralPerformanceStats
          {...{
            budget,
            performanceStats,
            impactStats,
            currencyCode,
          }}
          campaign_state={campaign_state}
          content={content}
          currentBrand={currentBrand}
          contentType={contentType}
          setMobileView={setMobileView}
          isUsingConversionsApi={hasConversionsApi}
        />
        <div className="px-6 mt-6 lg:px-10 py-5 flex flex-col justify-between">
          <UnlockDataBanner
            source="Music Streams Campaign Details - Impact Stats"
            title="Track exactly how your marketing is growing your fanbase"
            description="Go Pro to see how your Spotify followers, streams, and Shazams are growing every day"
            image={UnlockPresavesByDayImage}
            hideImage={hideBannerImage}
          />
        </div>
        <div className="px-6 lg:px-10 py-5 flex flex-col justify-between">
          <img src={require("../../../../assets/images/campaignDetails/impactBlurredSpotify.png").default} />
          <img src={require("../../../../assets/images/campaignDetails/impactBlurredChart.png").default} />
          <img src={require("../../../../assets/images/campaignDetails/impactBlurredInstagram.png").default} />
          <img src={require("../../../../assets/images/campaignDetails/impactBlurredChart.png").default} />
          <img src={require("../../../../assets/images/campaignDetails/impactBlurredShazam.png").default} />
          <img src={require("../../../../assets/images/campaignDetails/impactBlurredChart.png").default} />
        </div>
      </>
    )
  }

  // report doownloader 
  const [downloadingReport, setDownloadingReport] = useState<boolean>(false)
  async function downloadReport() {
    setDownloadingReport(true);


    try {

      await reportDownloader({
        brandId,
        campaignId,
        campaignName,
        campaignType: CAMPAIGN_TYPE_MAPPINGS[campaign_type].text || "Ads Campaign"
      })

      track("Downloaded Report", {
        brand_id: brandId,
        brand_name: brandName,
        brand_slug: brandSlug,
        campaign_id: campaignId,
        campaign_type: campaign_type,
        campaignName: campaignName,
      })

    } catch (error) {
      console.error(error);
      track("Error Downloading Report", {
        brand_id: brandId,
        brand_name: brandName,
        brand_slug: brandSlug,
        campaign_id: campaignId,
        campaign_type: campaign_type,
        campaignName: campaignName,
        error: error
      })
      setErrorModalText({
        title: "Error downloading report",
        message: "There was an error downloading your report. Please try again later."
      })
      handleOpenErrorModal();

    }

    setDownloadingReport(false);
  }

  const handleOnOpenPreview = () => {
    track("View Preview on Instagram", {
      brand_id: brandId,
      brand_name: brandName,
      brand_slug: brandSlug,
      campaign_id: campaignId,
      campaign_type: campaign_type,
      top_performing_placement: post.type,
    })
  }

  const campaignStatusLabel = showAdsStatusLabel(overallCampaignStatus.status, daysLeft)

  const handleUpdateCampaignStatus = async () => {
    const newStatus = await handleUpdateCampaignStatusIfMissMatch({
      campaignId,
      statusInDb,
      fbStatus: overallCampaignStatus.status,
      daysLeft
    })
    if (newStatus) setCampaignStatusFromDatabase(newStatus)
  }

  useEffect(() => {
    handleUpdateCampaignStatus()
  }, [overallCampaignStatus])


  return (
    <CampaignDetailsContainer backgroundColor="#F3F5F6" maxWidth={getMaxWidth()}>
      <OverviewCampaignCard
        showSetupOptions={true}
        isIGCampaign={true}
        isProUser={isProUser}
        downloadReport={downloadReport}
        downloadingReport={downloadingReport}
        campaignName={campaignName}
        isLoading={isLoading}
        campaignStatusLabel={campaignStatusLabel}
        campaignStatus={overallCampaignStatus.status}
        goBack={handleGoBack}
        selectedCampaign={selectedCampaign}
        handleOpenEditBudgetModal={handleOpenEditBudgetModal}
        handleOpenEditEndDateModal={handleOpenEditEndDateModal}
        handleOpenPauseModal={handleOpenPauseModal}
        handleOpenDuplicateModal={handleOpenDuplicateModal}
        handleSetMarketsTab={handleSetMarketsTab}
        handleOpenRenameCampaignModal={handleOpenRenameCampaignModal}
      />
      {renderErrorStates({
        adAccountInfo: currentAdAccountInfo,
        facebookErrors,
        campaignStatus: campaignStatusLabel === 'FINISHED' ? 'FINISHED' : overallCampaignStatus.status,
        adAccountStatus
      })}
      {!loggedInFB && !loadingFbToken ?
        <FbTokenExpired currentBrand={currentBrand} onSuccess={getInitialState} campaignId={campaignId} campaignType={campaign_type} />
        :
        <Container
          display="flex"
          flexDirection={setMobileView ? 'column' : 'row'}
        >
          <TabsContainer
            width={setMobileView ? "100%" : "70%"}
          >
            <nav className={classes.tabsHolder}>
              <SymphonyTabs
                data={TABS}
                selectedValue={selectedTab ? selectedTab : "overall"}
                onChange={(value) => setSelectedTab(value)}
              />
            </nav>
            <TabPanel
              hidden={selectedTab !== "overall"}
            >
              <div className="card-content bg-white shadow-none px-6 pt-6 middle-wrapper">
                <SectionContainer>
                  <SpaceBetweenContainer>
                    <Subtitle1 color={SystemColors.PRIMARY_TEXT_COLOR} weight={600}>Budget</Subtitle1>
                    <Body1 color={SystemColors.PRIMARY_TEXT_COLOR}>{getSymbolFromCurrency(currencyCode)}{budget.toLocaleString('en-US', { minimumFractionDigits: 2 })}</Body1>
                  </SpaceBetweenContainer>
                </SectionContainer>
                <SectionContainer>
                  <SpaceBetweenContainer>
                    <Subtitle1 color={SystemColors.PRIMARY_TEXT_COLOR} weight={600}>Campaign Length</Subtitle1>
                    <div className="flex flex-col text-right">
                      <Body1 color={SystemColors.PRIMARY_TEXT_COLOR}>
                        {campaignLengthInDays} days (
                        {new Date(startDate).toLocaleDateString()} to{" "}
                        {new Date(endDate).toLocaleDateString()})
                      </Body1>
                      <Body1 color={SystemColors.PRIMARY_TEXT_COLOR}>{showCampaignLengthStatus(overallCampaignStatus.status, daysLeft)}</Body1>
                    </div>
                  </SpaceBetweenContainer>
                </SectionContainer>

                {
                  !isLoading && <EditBudgetModal
                    budget={budget}
                    startDate={startDate}
                    endDate={endDate}
                    campaignId={campaignId}
                    closeModal={handleCloseEditBudgetModal}
                    currency={currencyCode}
                    minimalAmountPerDay={MINIMUM_DAILY_BUDGET_PER_DAY_IN_DOLLARS}
                    open={budgetModal.open}
                    totalSpent={performanceStats?.spend || 0}
                    reload={handleChangeBudget}
                    onErrorHandler={handleOpenErrorModal}
                    trackObject={trackObject}
                  />
                }
                {
                  !isLoading && <EditEndDateModal
                    budget={budget}
                    campaignId={campaignId}
                    closeModal={handleCloseEditEndDateModal}
                    startDate={startDate}
                    endDate={endDate}
                    minimalAmountPerDay={MINIMUM_DAILY_BUDGET_PER_DAY_IN_DOLLARS}
                    open={endDateModal.open}
                    reload={handleChangeEndDate}
                    totalSpent={performanceStats?.spend || 0}
                    onErrorHandler={handleOpenErrorModal}
                    trackObject={trackObject}
                    currency={currencyCode}
                  />
                }
                {!isLoading && <EditCampaignNameModal
                  campaignId={campaignId}
                  originalCampaignName={content?.name || content?.campaign_name}
                  closeModal={handleCloseRenameCampaignModal}
                  currentCampaignName={campaignName}
                  open={campaignNameModal.open}
                  reload={handleChangeName}
                  onErrorHandler={handleOpenErrorModal}
                  trackObject={trackObject}
                />}
                {
                  !isLoading && <PauseCampaignModal
                    campaignId={campaignId}
                    closeModal={handleClosePauseModal}
                    open={showPauseCampaignModal}
                    pause={overallCampaignStatus.status !== PAUSED && overallCampaignStatus.status !== ANTICIPATED_PAUSE}
                    reload={handlePause}
                    onErrorHandler={handleOpenErrorModal}
                    facebookErrorCode={facebookErrorCodes}
                  />
                }
                {
                  !isLoading && <DuplicateCampaignModal
                    currentBrand={currentBrand}
                    campaign={selectedCampaign}
                    closeModal={handleCloseDuplicateModal}
                    open={showDuplicateCampaignModal}
                    duplicateIcon={DuplicateAnotherSongsAds}
                  />
                }
                <ErrorUpdatingCampaignModal
                  closeModal={handleCloseErrorModal}
                  open={showErrorModal}
                  campaignId={campaignId}
                  errorTitle={errorModalText.title}
                  errorMessage={errorModalText.message}
                />
                <AdsStatusMessage overallCampaignStatus={overallCampaignStatus} />
                {
                  isLoading ? (
                    <div className="mx-auto my-6 flex-col flex items-center ">
                      <NewLoader black />
                      <p className="mt-1 text-center">Loading Campaign Stats...</p>
                    </div>
                  ) :
                    isProUser ? (
                      showAdsData()
                    )
                      : showFreeTierData()
                }
                {
                  setMobileView &&
                  <AdsPreview
                    callToAction={callToAction?.label}
                    performance={performance}
                    post={post}
                    postUrl={preview_shareable_link}
                    instagramAccount={owner}
                    isLoading={isLoading}
                    showViewOnInstagramButton={showViewOnInstagramButton}
                    handleOnOpenPreview={handleOnOpenPreview}
                  />
                }
              </div >
            </TabPanel >
            <TabPanel
              hidden={selectedTab !== "markets"}
            >
              <MarketsDetail
                campaignId={campaignId}
                getCardDetails={getCardDetails}
                markets={platform_details?.campaignsMapping}
                campaignStatus={campaignStatus}
                access_token={platform_details?.access_token}
                updateCampaignData={updateCampaignStatus}
                isPro={isProUser}
                currency={currencyCode}
                hasConversions={hasConversionsApi}
              />
            </TabPanel>
            <TabPanel
              hidden={selectedTab !== "demographics"}
            >
              <DemographicsStats
                shown
                isPro={isProUser}
                showMarkets={() => setSelectedTab("markets")}
                geographicBreakdown={geographicBreakdown}
                demographicBreakdown={demographicBreakdown}
                loading={isLoading}
                campaignDetails={{
                  startDate: startDate,
                  endDate: endDate,
                  platform_details: platform_details,
                  ad_account: platform_details.ad_account,
                }}
                currency={currencyCode}
              />
            </TabPanel>
            <TabPanel
              hidden={selectedTab !== "daily_stats"}
            >
              <DailyStats
                shown
                dailyStats={dailyStats}
                isPro={isProUser}
                loading={isLoading}
                campaignDetails={{
                  startDate: startDate,
                  endDate: endDate,
                  platform_details: platform_details,
                  ad_account: platform_details.ad_account,
                }}
                currency={currencyCode}
                hasConversions={hasConversionsApi}
                conversionsEventName={conversionsEventName}
              />
            </TabPanel>
          </TabsContainer >
          {!setMobileView &&
            <AdsPreview
              callToAction={callToAction?.label}
              performance={performance}
              post={post}
              postUrl={preview_shareable_link}
              instagramAccount={owner}
              isLoading={isLoading}
              showViewOnInstagramButton={showViewOnInstagramButton}
              handleOnOpenPreview={handleOnOpenPreview}
            />
          }
        </Container >
      }
    </CampaignDetailsContainer >
  );
}
