import { FunctionComponent, SetStateAction, Dispatch, useRef, useState, useContext } from "react";
import useStyles, { Card, StyledLabel } from "../../styles";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import CircularStep from "../CircularStep";
import { CurrentBrand, VideoAdsRecord } from "types/global";
import { useHistory } from "react-router-dom";
import { OnApproveActions, OnApproveData } from "@paypal/paypal-js";
import { createPayPalOrder, redirectToCheckout } from "../../api";
import { useTheme, useMediaQuery } from "@material-ui/core"
import { formatCountries } from "../../../utils";
import { DEFAULT_CURRENCY } from "pages/post-auth/MarketingPage/constants";
import SubmitOrUpgradeVideoViews from "./SubmitOrUpgrade";
import { BillingCycle } from "components/shareable/UpgradeToProModal/utils";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";


type PayPalOrder = {
  orderId: string | null;
  campaignId: string | null;
};

interface Props {
  campaignId: string | null
  startDate: string | Date | null;
  endDate: string | Date | null;
  budget: number;
  brand: CurrentBrand;
  creatingCampaign?: boolean;
  record: VideoAdsRecord | null;
  targeting: VideoAdsRecord[];
  selectedCountries: { label: string; value: string }[];
  targetingType: string;
  geographyType: string;
  onCancelPayPalOrder?: () => void;
  onErrorPayPalOrder?: () => void;
  setCreatingCampaign: Dispatch<SetStateAction<boolean>>;
  isProUser: boolean;
  cancelSaving: () => void;
  confirmed: boolean;
  refundFeeConfirmed: boolean;
}

const GoLiveCard: FunctionComponent<Props> = ({
  campaignId,
  startDate,
  endDate,
  budget,
  brand,
  record,
  creatingCampaign,
  selectedCountries,
  targeting,
  targetingType,
  geographyType,
  onCancelPayPalOrder,
  onErrorPayPalOrder,
  setCreatingCampaign,
  isProUser,
  cancelSaving,
  confirmed,
  refundFeeConfirmed
}: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();

  const { brandIsInOrganization, organization } = useCurrentTeam()
  const isMobile = useMediaQuery(theme.breakpoints.down(768))
  const payPalOrder = useRef<PayPalOrder>({ orderId: null, campaignId: null });
  const currency = brand?.currency?.code || DEFAULT_CURRENCY;

  const [selectedBillingCycle, setBillingCycle] = useState<BillingCycle>(BillingCycle.MONTHLY) // [BillingCycle.MONTHLY, BillingCycle.ANNUALLY

  console.log("selectedBillingCycle", selectedBillingCycle)
  // get organization details from user 
  const { currentUser } = useContext(CurrentUserContext);

  const inOrganization = brandIsInOrganization(brand?.slug!)

  const handleClickStripeButton = () => {
    cancelSaving();
    const data = {
      campaign: {
        id: campaignId,
        campaign_type: "increase_video_views",
        price_data: {
          price: budget,
          name: `YouTube Ads Campaign for "${record?.name}"`,
          description: `"${record?.name}" (Increase Video Views Campaign)`,
        },
        logistics: {
          refundFeeConfirmed,
          confirmedCampaignSettings: confirmed,
          budget,
          startDate,
          endDate,
        },
        content: {
          name: record?.name,
          id: record?.id,
          thumbnail_url: record?.primaryThumbnail,
          channelId: record?.channelId,
          channelTitle: record?.channelTitle,
        },
        targeting,
        targetingType,
        geographies: {
          type: geographyType,
          countries: formatCountries(geographyType, selectedCountries),
        },
        campaign_platforms: ["youtube"],
      },
      checkout_source: "Increase Video Views - Campaign Creation",
      recurring_interval: selectedBillingCycle,
      // only set this to true when:
      //  - the brand isn't already pro
      //  - the organization status is ready
      //  - and the brand is within the org 
      isOrganizationUpgrade: !isProUser && inOrganization && (organization?.status === 'ready')
    };

    setCreatingCampaign(true);
    redirectToCheckout({ brand, record, data });
  };

  const onPayPalOrderApprove = async (
    _data: OnApproveData,
    _actions: OnApproveActions
  ) => {
    const { campaignId, orderId } = payPalOrder.current;
    const url = `/marketing/success?campaign_id=${campaignId}&session_id=${orderId}`;

    history.push(url);
  };

  const createPaypalOrder = async () => {
    cancelSaving();
    const data = {
      campaign: {
        id: campaignId,
        campaign_type: "increase_video_views",
        price_data: {
          price: budget,
          name: `YouTube Ads Campaign for "${record?.name
            }"`,
          description: `"${record?.name}" (Increase Video Views Campaign)`,
        },
        logistics: {
          refundFeeConfirmed: true,
          confirmedCampaignSettings: true,
          budget: budget,
          startDate: startDate,
          endDate: endDate,
        },
        content: {
          name: record?.name,
          id: record?.id,
          thumbnail_url: record?.primaryThumbnail,
          channelId: record?.channelId,
          channelTitle: record?.channelTitle,
        },
        targeting: targeting,
        targetingType: targetingType,
        geographies: {
          type: geographyType,
          countries: formatCountries(geographyType, selectedCountries),
        },
        campaign_platforms: ["youtube"],
        payment_method: "paypal",
      },
    };

    setCreatingCampaign(true);

    const order = await createPayPalOrder({
      brand,
      record,
      data,
    });
    const { orderId } = order;

    payPalOrder.current = order;
    setCreatingCampaign(false);

    return orderId;
  };



  return (
    <Card borderRadius={isMobile ? undefined : "12px"}
      className={!confirmed || !refundFeeConfirmed ? 'pointer-events-none opacity-80	filter saturate-0	transition-all	' : 'filter saturate-1 transition-all	'}>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <CircularStep step="⚡" />
            </Grid>
            <StyledLabel fontSize={18} fontWeight={600}>
              Let’s go live
            </StyledLabel>
          </Grid>
        </Grid>
        <SubmitOrUpgradeVideoViews
          isProUser={isProUser}
          primaryOrganization={organization}
          handleClickStripeSubmitButton={handleClickStripeButton}
          creatingCampaign={creatingCampaign}
          currencyCode={currency}
          createPaypalOrder={createPaypalOrder}
          onCancelPayPalOrder={onCancelPayPalOrder}
          onErrorPayPalOrder={onErrorPayPalOrder}
          onPayPalOrderApprove={onPayPalOrderApprove}
          currentBrand={brand}
          selectedBillingCycle={selectedBillingCycle}
          setBillingCycle={setBillingCycle}
        />

      </Grid>
    </Card>
  );
};

export default GoLiveCard;
