import { FC } from 'react';
import { useStyles } from './styles';
import { ReactComponent as Checkmark } from 'assets/images/check-black.svg';

interface StepIconProps {
  connected: boolean;
}

export const ConnectedStepIcon: FC<StepIconProps> = ({
  connected,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.circle}>
      {connected && <Checkmark className={classes.checkMark} />}
    </div>
  );
};