import millify from "millify";
import { LoadingIndicator } from "components/Loader";
import RowItem from "components/shareable/RowItem";
import { Body2 } from "components/shareable/Typography";
import Loaderv2 from "components/Loader/v2";


export interface PresaveStreamingServiceDemographicsItem {
    name: string;
    platform: string;
    tier?: string;
    count: number;
    percentage: number;
    icon?: string;
}
interface DemographicsStatsProps {
    shown: boolean;
    contentName: string;
    demographicBreakdown: PresaveStreamingServiceDemographicsItem[],
    children?: any,
    loading: boolean
}
export default function Demographics(props: DemographicsStatsProps) {

    const {
        shown,
        contentName,
        demographicBreakdown,
        children,
        loading
    } = props


    function renderContent() {
        if (loading) {
            return (
                <div className='p-4 w-full flex flex-row gap-2 items-center justify-center'>
                    <div>
                        <Loaderv2
                        />
                    </div>
                    <Body2>Loading data...</Body2>
                </div>
            )
        }

        if (demographicBreakdown.length > 0) {
            return (
                <ul className="max-h-80 overflow-auto">
                    {
                        demographicBreakdown
                            .map((item: PresaveStreamingServiceDemographicsItem, index: number) => {
                                return (
                                    <RowItem
                                        key={index}
                                        image={item.icon}
                                        name={item.name}
                                        showRightValue={true}
                                        rightValue={`${millify(item.count)} (${item.percentage}%)`}
                                    />
                                );
                            })
                    }
                </ul>
            )
        } else {
            return (
                <div className='p-4 w-full flex flex-row gap-2 items-center justify-center'>
                    <Body2>No pre-saves have been received yet. Once you share your link and fans start pre-saving, you'll be able to see which streaming services they're using. Start sharing to see this data!</Body2>
                </div>
            )
        }
    }

    return (
        <>
            <div className={` pt-0 ${shown ? 'visible' : 'hidden'}`}>
                {!(demographicBreakdown) ?
                    (<div className="mx-auto my-6 flex-col flex items-center ">
                        <LoadingIndicator height="100px" color="black" />
                        <p className="mt-1 text-center">Loading...</p>
                    </div>) : (
                        <>
                            <div className="pb-5">
                                <p className="text-lg font-semibold">Streaming Services Breakdown</p>
                                <p className="text-sm text-gray-500">These are the streaming services your fans used to pre-save "{contentName}".</p>
                            </div>
                            <div>
                                {renderContent()}
                            </div>
                        </>
                    )}
            </div>
            {children}
        </>
    )
}