import { makeStyles } from '@material-ui/core';
import { SystemColors } from 'types/globalStyles';

export const useStyles = makeStyles(() => ({
  warningBanner: {
    backgroundColor: SystemColors.PAPER_ERROR_CONTAINER,
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px',
    alignItems: 'left',
    gap: '8px',
    borderRadius: '8px',
  },
}));
