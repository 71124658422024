import { useAuth0 } from "@auth0/auth0-react";
import { isAdmin } from "helpers/admin";
import Axios from "helpers/Interceptor";
import getUserByAuth0Id from "layouts/controllers/getUserByAuth0Id";
import { useState, createContext, ReactNode, FunctionComponent, useEffect } from "react";
import { getOrganizationData } from "services/symphonyApi/organizationService";
import { OrganizationData, UserData } from "types/global";
import * as Sentry from '@sentry/browser';

interface Context {
  currentUser: UserData | undefined;
  setCurrentUser: (user: UserData) => UserData | undefined;
  reloadUser: () => Promise<UserData> | undefined;
  loadingUser: boolean;
  organization: OrganizationData | undefined;
  setOrganization: (organization: OrganizationData) => void;

  // For Admin Search + Team Management for Admins
  setSpoofedUserId: (userId: number | null) => void;
  spoofedUserId: number | null;
}

// Creating a context with a similar structure to the CurrentBrandContext
export const CurrentUserContext = createContext<Context>({
  currentUser: undefined,
  setCurrentUser: () => undefined,
  reloadUser: () => undefined,
  loadingUser: false,
  organization: undefined,
  setOrganization: () => undefined,

  // For Admin Search + Team Management for Admins
  setSpoofedUserId: () => undefined,
  spoofedUserId: null,
});

type Props = {
  user?: UserData;
  children: ReactNode[] | ReactNode;
};

const CurrentUserProvider: FunctionComponent<Props> = ({ children }: Props) => {
  const [storedUser, setUser] = useState<UserData | undefined>(undefined);
  const [organization, setOrganization] = useState<OrganizationData | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  /**
 * Tracks the currently spoofed user ID, if an admin has chosen to impersonate another user
 * This is null when no spoofing is active
 */
  const [spoofedUserId, setSpoofedUserId] = useState<number | null>(null);


  const {
    user
  } = useAuth0();

    useEffect(() => {
        const fetchOrganizationDetails = async () => {
            if (!spoofedUserId) return;
            
            try {
                const organizationDetails = await getOrganizationData({
                    spoofedUserId
                });
                setOrganization(organizationDetails);
            } catch (error) {
                Sentry.captureException(error);
            }
        };

        fetchOrganizationDetails();
    }, [spoofedUserId]);

  /**
   * Allows admins to set a spoofed user ID for testing/support purposes
   * @param userId - The user ID to spoof, or null to clear spoofing
   */
  const handleSetSpoofedUserId = (userId: number | null) => {
    if (!storedUser || !isAdmin(storedUser)) {
      console.warn('Attempted to spoof user without admin privileges');
      return;
    }
    setSpoofedUserId(userId);
  };


  const reloadUser = async (): Promise<UserData> => {
    setLoading(true);
    const userData: UserData = await getUserByAuth0Id(user!.sub!)

    setUser(userData);
    setSpoofedUserId(null);

    setLoading(false);

    // If the user has an organization and their subscription isn't canceled,
    // we can trigger the organization setup immediately
    if (userData?.organization && userData?.metadata?.organization_subscription?.status !== 'canceled') {
      setOrganization(userData.organization);
    }
    return userData;
  }

  const setCurrentUser = (user: UserData): UserData | undefined => {
    setUser(user);
    setSpoofedUserId(null);

    // If the user has an organization and their subscription isn't canceled,
    // we can trigger the organization setup immediately
    if (user?.organization && user?.metadata?.organization_subscription?.status !== 'canceled') {
      setOrganization(user.organization);
    }
    return user;
  }

  const context = {
    currentUser: storedUser,
    setCurrentUser: setCurrentUser,
    reloadUser: reloadUser,
    loadingUser: loading,
    organization,
    setOrganization,

    // For Admin Search + Team Management for Admins
    setSpoofedUserId: handleSetSpoofedUserId,
    spoofedUserId,
  };

  return (
    <CurrentUserContext.Provider value={context}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserProvider;