import { setUserProperty, track } from "analytics";
import ProfileDropdown from "components/ProfileDropdown";
import { isAdmin } from "helpers/admin";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";

import { useContext, useEffect, useState } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { SymphonyTooltip } from "styles/shared";
import { CurrentBrand, UserData } from "types/global";
import { checkIfIsProTier, getStatusToShowPaymentFailedBanner, isOrganizationAdministrator } from "utils";
import { NavigationItem } from ".";
import miniSymphonyLogo from "../../assets/images/logos/sidebar-collapsed.png";
import symphonyLogo from "../../assets/images/symphonyLogo.svg";
import {
    DownArrow,
    FansIcon,
    GiftIcon,
    SettingsIcon,
    SidebarIcon
} from "../svg-icons";
import AdminOptions from "./Components/AdminOptions";
import Indicator from "./Components/indicator";
import NavItem from "./Components/NavItem";
import SubscriptionStatusIndicator from "./Components/SubscriptionStatusIndicator";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import { SystemColors } from "types/globalStyles";

interface DesktopNavigationProps {
    navigationItems: NavigationItem[];
    currentBrand: CurrentBrand;
    loadedBrands: CurrentBrand[] | null;
    setCurrentBrand: any;
    user: UserData | null;
}

export default function DesktopNavigation(props: DesktopNavigationProps) {
    const {
        navigationItems,
        loadedBrands,
        user
    } = props

    const {
        currentBrand,
        setCurrentBrand
    } = useContext(CurrentBrandContext);


    const isAdminUser = user ? isAdmin(user) : false

    const { teamMembers, organization: currentOrganization } = useCurrentTeam();

    const location = useLocation();

    // pro status
    const isProUser = checkIfIsProTier(currentBrand);
    const myRole = teamMembers.find((member) => member.id === user?.id)?.role;

    // free trial over status
    const freeTrialOver = currentBrand?.freeTrialOver

    // admin status
    const {
        reloadBrand
    } = useContext(CurrentBrandContext);

    const userIsAdmin = user ? isAdmin(user) : false
    const showEnablePro = userIsAdmin
    const [enablePro, setEnablePro] = useState(isProUser)

    const { handleOpenModal } = useContext(UpgradeModalContext);


    // shows an "add to Pro in your team"- type modal
    const [showUpgradeInTeamModal, setShowUpgradeInTeamModal] = useState(false);


    useEffect(() => {
        if (userIsAdmin) {
            setEnablePro(isProUser)
        }

    }, [currentBrand])

    const initialStatus = localStorage.getItem('sidebar')

    const [collapseBar, setCollapseBar] = useState(initialStatus && initialStatus === 'collapsed' ? true : false);
    const [tracked, setTracked] = useState(false);
    const [hoverSmallSidebar, setHoverSmallSidebar] = useState(false)
    const history = useHistory()

    useEffect(() => {
        setTracked(true)
    }, [])

    useEffect(() => {
        // hacky way of changing main content padding
        // when sidebar is collapsed
        if (tracked) {
            track("Toggle Sidebar", {
                to: collapseBar ? 'collapsed' : 'not collapsed'
            })
        }

        // only activate padding-left if the sidebar is shown (lg: // 1024px width)
        if (collapseBar) {
            document!.getElementById('main-content')!.classList.add('lg:pl-14')
            setHoverSmallSidebar(false)
            if (tracked) setUserProperty("desktop sidebar", "collapsed")
            localStorage.setItem('sidebar', 'collapsed')
        } else {
            document!.getElementById('main-content')!.classList.remove('lg:pl-14')
            if (tracked) setUserProperty("desktop sidebar", "not collapsed")
            localStorage.setItem('sidebar', 'open')

        }
    }, [collapseBar])

    // dropdown functions
    const [dropdownOpenId, setDropdownOpenId] = useState<string | null>(null)


    const selectedRoute = location.pathname
    const smallSidebar = collapseBar && !hoverSmallSidebar
    const hasAdminAccess = currentOrganization && isOrganizationAdministrator(currentOrganization)
    const isOrganizationReadyOrWaiting = (currentOrganization?.status === 'ready'
        || currentOrganization?.status === 'waiting_for_details') && user?.metadata?.organization_subscription?.status !== 'canceled';


    function renderActionButtons() {
        // Add check for view-only access
        const hasViewOnlyAccess = currentBrand?.permissionLevel === "view_only";


        function renderUpgradeToProText() {
            // Return early if view-only access
            if (hasViewOnlyAccess) {
                return "";
            }

            if (isOrganizationReadyOrWaiting) {
                return "Upgrade to Pro"
            }

            if (isProUser) return ""
            const isUnpaid = getStatusToShowPaymentFailedBanner(currentBrand)

            if (isUnpaid) {
                return "Reactivate Pro"
            } else if (freeTrialOver) {
                return "Upgrade to Pro"
            }

            return "Start Pro Trial"
        }
        const handleUpgradeClick = () => {
            handleOpenModal({
                source: "Desktop Navigation - Upgrade to Pro Button"
            });
        }

        return (
            <>
                {!smallSidebar && (
                    hasViewOnlyAccess ? (
                        <SubscriptionStatusIndicator
                            text="View Only Access"
                            showStars={false}
                            backgroundColor={SystemColors.VIEW_ONLY_BACKGROUND}
                            textColor={SystemColors.WHITE_TEXT_COLOR}
                        />
                    ) : !isProUser && (
                        <SubscriptionStatusIndicator
                            text={renderUpgradeToProText()}
                            showStars
                            handleOpenModal={handleUpgradeClick}
                        />
                    )
                )}
            </>
        );
    }

    /**
     * Renders the team onboarding button in the navigation based on user role and organization status
     * 
     * Logic flow:
     * 1. If user is an admin:
     *    - Show "Admin: Team Onboarding" button that links to admin onboarding page
     *    - Button text collapses to just "Team" when sidebar is small
     * 
     * 2. If user is not an admin:
     *    - Only show button if user doesn't belong to an organization yet
     *    - Shows "Start a Team" button that opens upgrade modal
     *    - Button text collapses to just "Team" when sidebar is small
     *    - If user already has an organization, returns null (no button shown)
     * 
     * @returns JSX.Element | null - The team onboarding button UI or null if not applicable
     */
    const renderTeamOnboardingButton = () => {
        if (isAdminUser) {
            return (
                <div onClick={() => { history.push('/team/admin/onboarding') }}
                    className={`adminUserTeamPlanContainer nav-link text-black lg:text-white block lg:flex items-center p-0 lg:py-1.5 lg:px-0 w-full lg:px-5 cursor-pointer`}
                >
                    <div className="flex justify-center lg:inline-block"
                        style={{ transform: "translateY(-2px)" }}>
                        <FansIcon color="#ffffff" />
                    </div>
                    <div className={`smallSidebar ${smallSidebar ? 'invisible' : 'visible'} block lg:inline-block px-1 py-1 lg:px-3 text-base text-gray-400 font-medium`}>
                        {smallSidebar ? `Team` : `Admin: Team Onboarding`}
                    </div>
                </div>
            )
        } else {
            // Regular users can only see "Start a Team" if they don't have an organization
            if (!currentOrganization) {
                return (
                    <div
                        onClick={() => handleOpenModal({
                            source: "Desktop Navigation - Upgrade to Pro Button",
                            tab: "team",
                        })}
                        className={`nonAdminTeamPlanContainer nav-link text-black lg:text-white block lg:flex items-center p-0 lg:py-1.5 lg:px-0 w-full lg:px-5 cursor-pointer`}
                    >
                        <div className="flex justify-center lg:inline-block"
                            style={{ transform: "translateY(-2px)" }}>
                            <FansIcon color="#ffffff" />
                        </div>
                        <div className={`${smallSidebar ? 'invisible' : 'visible'} block lg:inline-block px-1 py-1 lg:px-3 text-base text-gray-400 font-medium`}>
                            {smallSidebar ? `Team` : `Start a Team`}
                        </div>
                    </div>
                )
            }
            return null
        }
    }

    return (
        <div
            onMouseEnter={() => {
                setHoverSmallSidebar(true)
            }}
            onMouseLeave={() => {
                setHoverSmallSidebar(false)
            }}
            className={`
            ${hoverSmallSidebar ? `shadow-lg` : ``}
                        h-full
                        z-50
                        ${collapseBar
                    ? 'fixed top-0 left-0'
                    : 'relative'}

                            ${smallSidebar ? `lg:w-16` : ` lg:w-60`}
                        hidden
                        lg:block`
                // 'relative onboard-overlay' : 'relative'
            }>
            <section className="overflow-auto fixed border-t border-background-100 bottom-0 left-0 w-full z-50 px-0 py-0.5 bg-white w-full md:py-1 lg:h-full lg:border-none lg:relative lg:top-0 lg:pt-6 lg:pb-3 lg:bg-secondary">
                <nav className="relative block justify-center items-center h-14 w-full lg:flex lg:flex-col lg:justify-between lg:h-full">
                    <div className="lg:flex lg:flex-col lg:h-full lg:w-full">
                        <div className='lg:px-5 h-12'>
                            {collapseBar ? <img className={`w-10 absolute left-3 ${!smallSidebar ? `invisible` : `visible`}`} src={miniSymphonyLogo} /> : null}
                            <div className={` ${smallSidebar ? `invisible` : `visible`} items-start justify-center flex flex-row `}>
                                <Link
                                    className="hidden text-2xl text-white font-bold row items-center justify-center lg:flex lg:flex-col lg:items-start  "
                                    to="/">
                                    <img src={symphonyLogo} width={"auto"} className="h-auto w-9/12 flex-1" alt="logo" />
                                    <p className="text-xs font-medium lg:mt-1 text-gray-400">BETA{/* v{VERSION}*/}</p>
                                </Link>
                                <SymphonyTooltip title={collapseBar ? "Always Show" : "Collapse"} placement="right">

                                    <div
                                        style={{
                                            cursor: "pointer",
                                            background: smallSidebar ? 'red' : 'none'
                                        }}
                                        onClick={() => {
                                            setCollapseBar(!collapseBar)
                                        }}>
                                        <SidebarIcon filledIn={!collapseBar} />
                                    </div>
                                </SymphonyTooltip>
                            </div>
                        </div>
                        <div className="lg:flex lg:flex-col">
                            {loadedBrands && loadedBrands.length > 0 ?
                                (<ul className="flex items-center text-white justify-around lg:flex-col">
                                    {navigationItems
                                        .filter((o: NavigationItem) => !o.hideOnDesktop)
                                        .map((o: NavigationItem) => {
                                            let selected = Boolean((selectedRoute === o.href) || (o.alternateHrefs && o.alternateHrefs.indexOf(selectedRoute) > -1)
                                                || (o.href && o.href !== '/' && selectedRoute.includes(o.href)))

                                            const color = selected ? "#FFFFFF" : "#ffffff";

                                            const innerComponent = (
                                                <>
                                                    <div className="flex justify-center lg:inline-block"
                                                        style={{
                                                            transform: "translateY(-2px)"
                                                        }}>
                                                        {o.iconComponent ? <o.iconComponent color={color} /> : null}
                                                    </div>
                                                    <div className={`${smallSidebar ? 'invisible' : 'visible'} block lg:inline-block px-1 py-1 lg:px-3 text-xs sm:text-lg lg:text-lg transition-all ${selected ? `text-primary lg:text-white` : `hover:text-gray-300 text-gray-400`} font-medium`}>
                                                        {smallSidebar ? o.smallName ? o.smallName : o.name : o.name}
                                                    </div>
                                                </>
                                            )

                                            const returnedListItem = (<li key={o.name} className="lg:w-full mt-3 md:mt-2 lg:mt-3.5 relative flex items-center justify-center ">
                                                <Indicator
                                                    selected={selected} />
                                                <NavItem
                                                    selected={selected}
                                                    innerComponent={innerComponent}
                                                    link={o.href}
                                                    onClick={(e) => {
                                                        if (o.dropdownItems && o.dropdownItems.length > 0) {
                                                            if (dropdownOpenId === o.name) {
                                                                setDropdownOpenId(null)
                                                            } else {
                                                                setDropdownOpenId(o.name)
                                                            }
                                                        } else {
                                                            if (e.onClick) {
                                                                e.onClick(e)
                                                            }
                                                            setDropdownOpenId(null)
                                                        }
                                                    }} />
                                                {!smallSidebar && o.dropdownItems && o.dropdownItems.length > 0 && (
                                                    <div className="pr-4 cursor-pointer"
                                                        onClick={(e) => {
                                                            // if this is a dropdown, open it
                                                            // otherwise handle onclick normally
                                                            if (o.dropdownItems && o.dropdownItems.length > 0) {
                                                                if (dropdownOpenId === o.name) {
                                                                    setDropdownOpenId(null)
                                                                } else {
                                                                    setDropdownOpenId(o.name)
                                                                }
                                                            }
                                                        }}>
                                                        <DownArrow
                                                            position={dropdownOpenId === o.name ? 'up' : 'down'}
                                                            width="26"
                                                            height="26" />
                                                    </div>)}
                                            </li>)

                                            let additionalItems = null
                                            let showDropdown = dropdownOpenId === o.name

                                            if (!smallSidebar && o.dropdownItems) {
                                                const filteredDropdownItems = o.dropdownItems.filter(item => !item.hideOnDesktop);
                                                additionalItems = (
                                                    <ul className="flex items-center text-white justify-around lg:flex-col">
                                                        {filteredDropdownItems.map((dropdownItem: NavigationItem) => {

                                                            let dropdownSelected = Boolean((selectedRoute === dropdownItem.href) || (dropdownItem.alternateHrefs && dropdownItem.alternateHrefs.indexOf(selectedRoute) > -1))

                                                            return (
                                                                <li key={dropdownItem.name} className="lg:w-full relative flex items-center justify-center">
                                                                    <NavItem
                                                                        selected={dropdownSelected}
                                                                        innerComponent={dropdownItem.name}
                                                                        link={dropdownItem.href}
                                                                        onClick={(e) => {
                                                                            if (dropdownItem.onClick) {
                                                                                dropdownItem.onClick(e)

                                                                            }
                                                                        }} />
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                )
                                            }
                                            return (
                                                <>
                                                    {returnedListItem}
                                                    {additionalItems && o.dropdownItems ? (
                                                        <li className={`pl-9 w-full overflow-hidden transition-all duration-200 ease-in-out  ${showDropdown ? `h-28` : `h-0`}`}>
                                                            {additionalItems}
                                                        </li>
                                                    ) : null}
                                                </>
                                            )
                                        })}
                                </ul>
                                ) : null}
                        </div>
                    </div>
                    <div className={`hidden lg:flex lg:flex-col  lg:gap-2 w-full`}>
                        {renderActionButtons()}

                        {
                            // Notice: check if we should show the subscription status when is on active organization
                            !smallSidebar && !isOrganizationReadyOrWaiting &&
                            (isProUser && currentBrand?.subscription && !currentBrand?.subscription.details?.trialOver && currentBrand?.subscription.details?.trialDaysLeft) &&
                            (<SubscriptionStatusIndicator
                                text={`${currentBrand?.subscription.details?.trialDaysLeft} Day${currentBrand?.subscription.details?.trialDaysLeft === 1 ? '' : 's'} Left on Trial`}
                            />)
                        }
                        <div className={`
                        ${smallSidebar ? 'lg:bg-opacity-0' : 'lg:bg-opacity-25'}
                        hidden lg:mt-auto lg:block lg:w-10/12 lg:mx-auto lg:bg-gray-600 lg:rounded-2xl`}>
                            <ProfileDropdown
                                smallSidebar={smallSidebar}
                                setCurrentBrand={setCurrentBrand}
                                currentBrand={currentBrand}
                                loadedBrands={loadedBrands}
                                user={user}
                            />
                        </div>
                    </div>
                    {renderTeamOnboardingButton()}

                    <NavLink
                        className={`nav-link text-black lg:text-white block lg:flex items-center p-0 lg:py-1.5 lg:px-0 w-full  lg:px-5 lg:ml-1 `}
                        activeClassName="text-primary active-stroke"
                        to={'/refer'}
                        exact
                    >
                        <div className="flex justify-center lg:inline-block"
                            style={{
                                transform: "translateY(-2px)"
                            }}>
                            <GiftIcon color="#ffffff" />
                        </div>
                        <div className={`${smallSidebar ? 'invisible' : 'visible'} block lg:inline-block px-1 py-1 lg:px-3 text-base ${selectedRoute === '/refer' ? `text-primary lg:text-white` : `text-gray-400`} font-medium`}>
                            {smallSidebar ? `Refer` : `Refer & Earn`}
                        </div>
                    </NavLink>
                    <NavLink
                        className={`nav-link text-black lg:text-white block lg:flex items-center p-0 lg:py-1.5 lg:px-0 w-full  lg:px-5 `}
                        activeClassName="text-primary active-stroke"
                        to={'/settings'}
                        exact
                    >
                        <div className="flex justify-center lg:inline-block"
                            style={{
                                transform: "translateY(-2px)"
                            }}>
                            <SettingsIcon color="#ffffff" />
                        </div>
                        <div className={`${smallSidebar ? 'invisible' : 'visible'} block lg:inline-block px-1 py-1 lg:px-3 text-base ${selectedRoute === '/settings' ? `text-primary lg:text-white` : `text-gray-400`} font-medium`}>
                            Settings
                        </div>
                    </NavLink>
                    <AdminOptions
                        isAdmin={userIsAdmin}
                        subscription={currentBrand?.subscription || null}
                        showEnablePro={showEnablePro}
                        reloadBrand={reloadBrand}
                        brandSlug={currentBrand?.slug!}
                        enablePro={enablePro}
                        isCollapsed={collapseBar}
                    />
                </nav>
            </section>
        </div >
    )
}