import { FunctionComponent } from 'react';
import { PurpleContainer, PlatformsIconsContainer, CampaignTypeText, IconContainer } from './styles';

interface BadgeContainerProps {
    text: string;
    imageUrls?: string[];
}

const BadgeContainer: FunctionComponent<BadgeContainerProps> = ({ text, imageUrls }) => {
    return (
        <PurpleContainer>
            {imageUrls &&
                <PlatformsIconsContainer>
                    {imageUrls.map((url, index) => (
                        <IconContainer key={`${url}-${index}`}>
                            <img src={url} alt={`badge-image-${index}`} />
                        </IconContainer>
                    ))}
                </PlatformsIconsContainer>
            }
            <CampaignTypeText>
                {text}
            </CampaignTypeText>
        </PurpleContainer>
    );
};

export default BadgeContainer;
