import { track } from "analytics"
import { CurrentBrand } from "types/global"
import { MaestroRecommendation } from "../CreateNewCampaignModal/Recommender"


export type CampaignCreationSource = 'ai' | 'list' | 'popular' | 'helper-popup' | 'dashboard-quick-actions' | 'maestro-dashboard' | 'team-plan-quick-actions'

export function trackNewCampaign({
    type,
    from,
    zeroState,
    aiQuestion,
    currentBrand
}: {
    type?: string,
    from?: CampaignCreationSource,
    zeroState?: boolean,
    aiQuestion?: string | null,
    currentBrand: CurrentBrand | undefined
}) {
    track('Start New Campaign', {
        brand_id: currentBrand ? currentBrand.id : null,
        brand_slug: currentBrand ? currentBrand.slug : null,
        brand_name: currentBrand ? currentBrand.name : null,
        type: type,
        from: from,
        zeroState: zeroState,
        aiQuestion: aiQuestion
    })
}

export function trackAskMaestro({
    message,
    zeroState,
    currentBrand,
    source = 'Marketing'
}: {
    message?: string,
    zeroState?: boolean,
    currentBrand: CurrentBrand | undefined,
    source?: 'Dashboard' | 'Marketing' | 'Marketing - Team Plan'
}) {
    track('Ask Maestro', {
        brand_id: currentBrand ? currentBrand.id : null,
        brand_slug: currentBrand ? currentBrand.slug : null,
        brand_name: currentBrand ? currentBrand.name : null,
        aiQuestion: message,
        zeroState: zeroState,
        source: source
    })
}

export function trackMaestroResponse({
    message,
    zeroState,
    currentBrand,
    response
}: {
    message?: string,
    zeroState?: boolean,
    currentBrand: CurrentBrand | undefined,
    response: MaestroRecommendation
}) {
    if (response && (!response.recommendations
        || response.recommendations.length === 0)) {
        track('Maestro Response Error', {
            brand_id: currentBrand ? currentBrand.id : null,
            brand_slug: currentBrand ? currentBrand.slug : null,
            brand_name: currentBrand ? currentBrand.name : null,
            aiQuestion: message,
            zeroState: zeroState,
            responseMessage: response ? response.primaryMessage : null,
            recommendations: null,
            responseObject: response
        })
    } else {
        track('Maestro Response Success', {
            brand_id: currentBrand ? currentBrand.id : null,
            brand_slug: currentBrand ? currentBrand.slug : null,
            brand_name: currentBrand ? currentBrand.name : null,
            aiQuestion: message,
            zeroState: zeroState,
            responseMessage: response ? response.primaryMessage : null,
            recommendations: response && response.recommendations ? response.recommendations.map(r => r.title).join(', ') : null,
            responseObject: response
        })
    }
}