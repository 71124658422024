import Dialog from "@material-ui/core/Dialog";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import useStyles, {
  CloseIconContainer,
  DialogContent,
  RightContent
} from "./styles";

import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@material-ui/core";
import { ReactComponent as CloseIcon } from "assets/images/close-black.svg";
import CreatorContent from "./view/creators";
import { CreatorSideContent } from "./view/creators/side-content";
import { TeamContent } from "./view/teams";
import { BillingCycle } from "./utils";
import { checkIfIsProTier } from "utils";
import { CurrentBrand } from "types/global";
import { ExistingProfiles } from "pages/post-auth/OrganizationPage/Components/AddNewArtistModal/Components/ExistingProfileArtist";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";


const { MONTHLY } = BillingCycle;

interface Props {
  open: boolean;
  onClose: () => void;
  source?: string | null;

  currentBrand?: CurrentBrand | null;
  // for organization-specific functions
  isOrganization?: boolean;
  hasOrganizationOverage?: boolean | undefined;
  reloadBrands: () => void;
  tab?: "creator" | "team" | "add-to-team"
  isTeamAdmin?: boolean;
}

const UpgradeToProModal: FunctionComponent<Props> = ({
  open,
  source,
  onClose,
  isOrganization,
  hasOrganizationOverage,
  reloadBrands,
  tab,
  currentBrand,
  isTeamAdmin,
}) => {
  const theme = useTheme();
  const [billingCycle, setBillingCycle] = useState(MONTHLY);
  const mobileView = useMediaQuery(theme.breakpoints.down(901));
  const classes = useStyles({ isMobileView: mobileView });
  const { organization, getTeamArtists } = useCurrentTeam()


  const isPro = checkIfIsProTier(currentBrand)
  const [planTabs, setPlanTabs] = useState<"creator" | "team" | "add-to-team">(
    organization && isTeamAdmin ? "add-to-team" : (tab || "creator")
  );

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: "creator" | "team" | "add-to-team") => {
    setPlanTabs(newValue);
  };

  const handleClickCloseIconButton = () => {
    if (onClose) onClose();
  };

  useEffect(() => {
    if (open) {
      if (organization && isTeamAdmin) {
        setPlanTabs("add-to-team");
      } else if (tab) {
        setPlanTabs(tab);
      } else {
        setPlanTabs("creator");
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClickCloseIconButton}
      maxWidth={"md"}
      fullWidth={mobileView}
      fullScreen={mobileView}
      PaperProps={{ className: classes.paper }}
    >
      <DialogContent
        overflowY={mobileView ? undefined : "hidden"}
        flexDirection={mobileView ? "column" : "row"}
      >
        <CreatorSideContent
          onClose={handleClickCloseIconButton}
        ></CreatorSideContent>
        <RightContent
          padding={mobileView ? "18px 18px 0px" : "16px 32px 0px"}
          width={mobileView ? "100%" : "60%"}
          height="700px"
          overflowY={mobileView ? undefined : "scroll"}
        >
          <CloseIconContainer
            isMobileView={true}
            onClick={handleClickCloseIconButton}
          >
            <CloseIcon />
          </CloseIconContainer>

          {/* Tabs Section */}
          <Box
            sx={{ borderBottom: "1px solid #C8C7CC", marginBottom: "24px" }}
          >
            <Tabs
              value={planTabs}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#8800FF",
                },
              }}
            >
              {organization && isTeamAdmin ? (
                // Organization admin view: Add to Team + Upgrade Separately
                [
                  <Tab
                    key="add-to-team"
                    className="capitalize"
                    label="Add to Team"
                    value="add-to-team"
                    id="tab-add-to-team"
                    aria-controls="tabpanel-add-to-team"
                  />,
                  !isPro && <Tab
                    key="creator"
                    className="capitalize"
                    label="Upgrade Separately"
                    value="creator"
                    id="tab-creators"
                    aria-controls="tabpanel-creators"
                  />
                ].filter(Boolean)
              ) : (
                // Non-organization view: For Creators + For Teams
                [
                  !isPro && <Tab
                    key="creator"
                    className="capitalize"
                    label="For Creators"
                    value="creator"
                    id="tab-creators"
                    aria-controls="tabpanel-creators"
                  />,
                  <Tab
                    key="team"
                    className="capitalize"
                    label="For Teams"
                    value="team"
                    id="tab-teams"
                    aria-controls="tabpanel-teams"
                  />
                ].filter(Boolean)
              )}
            </Tabs>
          </Box>

          {planTabs === "creator" ? (
            <>
              <CreatorContent
                billingCycle={billingCycle}
                setBillingCycle={setBillingCycle}
                onClose={handleClickCloseIconButton}
                source={source}
                reloadBrands={reloadBrands}
                hasOrganizationOverage={hasOrganizationOverage}
                isOrganization={false}
                open
              />
            </>
          ) : planTabs === "add-to-team" ? (
            <div className="px-4">
              <ExistingProfiles
                onComplete={() => {
                  getTeamArtists().then(() => {
                    reloadBrands();
                    handleClickCloseIconButton();
                  });
                }}
                handleChangeCount={() => { }} // Optional: Handle count if needed
              />
            </div>
          ) : (
            <TeamContent
              billingCycle={billingCycle}
              setBillingCycle={setBillingCycle}
              onClose={handleClickCloseIconButton}
              source={source}
              reloadBrands={reloadBrands}
              hasOrganizationOverage={hasOrganizationOverage}
              isOrganization={isOrganization}
              open
            ></TeamContent>
          )}
        </RightContent>
      </DialogContent>
    </Dialog>
  );
};

export default UpgradeToProModal;
