import { useContext, useEffect, useState } from "react";
import { CAMPAIGN_TYPES, CurrentBrand } from "types/global";
import { Body2 } from "components/shareable/Typography"; import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import Axios from "helpers/Interceptor"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { SHARED_TOAST_OPTIONS_ERROR } from "pages/post-auth/MarketingPage/Components/Modals/utils"
import { TeamTabSection } from "../TeamTabSection";
import { GridColDef } from "@mui/x-data-grid";
import { OrganizationViewDefaultGrid } from "../OrganizationViewDefaultGrid";
import { format } from 'date-fns';
import WebsiteTypeBadge from 'components/shareable/ContentTypeBadge/website';
import { RowContainer } from 'pages/post-auth/MarketingPage/Components/CampaignCard/styles';
import LinkContainer from 'components/shareable/LinkContainer'
import LinkButton from 'components/shareable/LinkButton'
import { Container } from 'styles/shared'
import { SYMPHONY_WEBSITE_URL } from "../../../../../constants";
import BrandSelectionModal from "components/shareable/CreateCampaignModal/SelectBrandModal";
import CreateNewSiteModal from "pages/post-auth/Website/components/CreateNewSiteModal";
import EmptyState from "./TableEmptyState";
import { getOrganizationWebsites } from "services/symphonyApi/organizationService";
import { CurrentUserContext } from "Hooks/CurrentUserContext";

const { get_playlisted, pre_save, increase_video_views } = CAMPAIGN_TYPES;

const PAGE_SIZE = 8;

interface Props {
  organizationBrands: CurrentBrand[];
}

interface Website {
  id: string;
  name: string;
  type: string;
  creationType: 'automatic' | 'campaign' | 'presave';
  url: string;
  brandName: string;
  brandImage: string;
  createdAt: string;
  brandSlug: string;
  campaign_id?: string;
  slug: string;
  content_metadata?: {
    presave?: {
      saved_at: string | null;
    };
  };
}

export const OrganizationWebsitesTab = ({ organizationBrands }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [websites, setWebsites] = useState<Website[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [isBrandModalOpen, setIsBrandModalOpen] = useState(false);
  const [isCreateSiteModalOpen, setIsCreateSiteModalOpen] = useState(false);
  const [rowCount, setRowCount] = useState<number>(0);

  const history = useHistory();

  const { setCurrentBrand } = useContext(CurrentBrandContext);
  const { spoofedUserId } = useContext(CurrentUserContext);

  const fetchWebsites = async () => {
    setIsLoading(true);
    try {
      const response = await getOrganizationWebsites(page * PAGE_SIZE, PAGE_SIZE, spoofedUserId ? {
        spoofedUserId: spoofedUserId
      } : undefined);

      if (response?.websites) {
        const websitesWithFullUrls = response.websites.map((website: any) => ({
          ...website,
          url: `${SYMPHONY_WEBSITE_URL}${website.url}`
        }));
        setWebsites(websitesWithFullUrls);
        setRowCount(response.total);
      } else {
        throw new Error('Failed to fetch websites');
      }
    } catch (error) {
      toast.error('Failed to load websites', SHARED_TOAST_OPTIONS_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchWebsites();
  }, [page]);

  const handleRowClick = (row: Website) => {
    console.log('row', row);
    const brandForWebsite = organizationBrands.find(
      brand => brand.slug === row.brandSlug
    );
    if (brandForWebsite) {
      setCurrentBrand(brandForWebsite);
      const isUnsavedPresave = row.content_metadata?.presave &&
        !row.content_metadata.presave.saved_at;

      const editPath = isUnsavedPresave
        ? `/marketing/campaigns/${row.campaign_id}`
        : `/website/${row.type}/${row.slug}`;

      history.push(editPath);
    } else {
      toast.error('Unable to find matching brand', SHARED_TOAST_OPTIONS_ERROR);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "brand",
      headerName: "Profile",
      flex: 1.5,
      renderCell: (params) => (
        <div className="flex items-center gap-3">
          <img
            src={params.row.brandImage}
            alt={params.row.brandName}
            className="w-10 h-10 rounded-full object-cover"
          />
          <Body2>{params.row.brandName}</Body2>
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Website Name",
      flex: 2,
      renderCell: (params) => (
        <div className="flex flex-col gap-1">
          <Body2>{params.row.name}</Body2>
          <RowContainer>
            <WebsiteTypeBadge
              websiteType={params.row.type}
              additionalLabel={params.row.label}
            />
          </RowContainer>
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created",
      flex: 1,
      renderCell: (params) => (
        <Body2>{format(new Date(params.row.createdAt), 'MMM dd, yyyy')}</Body2>
      ),
    },
    {
      field: "urlAndActions",
      headerName: "Website URL & Actions",
      flex: 4,
      renderCell: (params) => (
        <Container
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
          alignItems='center'
          gap="24px"
          width='100%'
        >
          <LinkContainer
            url={params.row.url}
            showShareButton={false}
            width='80%'
          />
          <div className="flex gap-2">
            <LinkButton
              text='Edit site'
              onClick={() => handleRowClick(params.row)}
            />
          </div>
        </Container>
      ),
    },
  ];

  const handleCreateWebsite = () => {
    setIsBrandModalOpen(true);
  };

  const handleBrandSelect = (brandId: string) => {
    const selectedBrand = organizationBrands.find(brand => brand.id === Number(brandId));
    if (selectedBrand) {
      setCurrentBrand(selectedBrand);
      setIsBrandModalOpen(false);
      setIsCreateSiteModalOpen(true);
    }
  };

  return (
    <>
      <TeamTabSection
        title="Websites"
        buttonText="Create a Website"
        onButtonClick={handleCreateWebsite}
        showDividers
      >
        <div className="flex w-full">
          <div className="w-full h-full">
            <OrganizationViewDefaultGrid
              className="organizationWebsitesGrid"
              rows={websites}
              columns={columns}
              hideFooter={false}
              disableColumnMenu
              disableColumnSelector
              page={page}
              onRowClick={({ row }) => handleRowClick(row)}
              onPageChange={(newPage) => setPage(newPage)}
              itemsPerPage={PAGE_SIZE}
              itemName="Websites"
              rowCount={rowCount}
              paginationMode="server"
              autoHeight={true}
              isLoadingData={isLoading}
              emptyStateComponent={
                <EmptyState
                  primaryText="View and manage all of your team's websites and pre-save campaigns here."
                  buttonText="Create your First Website"
                  buttonAction={handleCreateWebsite}
                />
              }
            />
          </div>
        </div>
      </TeamTabSection>

      <BrandSelectionModal
        className="websitesBrandSelectionModal"
        isOpen={isBrandModalOpen}
        onClose={() => setIsBrandModalOpen(false)}
        brands={organizationBrands}
        onBrandSelect={handleBrandSelect}
        titleText="Select a Profile"
        subtitleText="Choose the profile you want to create a website for."
      />

      <CreateNewSiteModal
        open={isCreateSiteModalOpen}
        closeModal={() => setIsCreateSiteModalOpen(false)}
      />
    </>
  );
};

