import { FunctionComponent, Fragment, useEffect } from "react";
import PaymentMethodButton from "../PaymentMethodButton";
import { Card, StyledLabel, Icon } from "../../styles";
import useStyles, { StyledLink } from "./styles";
import Grid from "@material-ui/core/Grid";
import RightArrowIcon from "assets/images/right-arrow.svg";
import MusicKeyGreenBackground from "assets/images/music-key-green-bg.svg";
import { useTheme, useMediaQuery } from "@material-ui/core";

interface Props {
  fbAdAccountId?: string;
  fbAdAccountName?: string;
  paymentMethodSelected: any;
  loadingPayment: boolean;
  handleRefreshPaymentMethod: () => void;
}

const PaymentMethodCard: FunctionComponent<Props> = ({
  fbAdAccountId,
  fbAdAccountName,
  paymentMethodSelected,
  loadingPayment,
  handleRefreshPaymentMethod,
}: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const classes = useStyles();
  const paymentMethodButtonClassName = `flex items-center justify-center w-full text-center ${
    loadingPayment ? "p-4" : "pt-4 px-4"
  } text-primary font-normal border-backgound-100`;
  const billingSettingsUrl = `https://business.facebook.com/ads/manager/account_settings/account_billing/?act=${
    fbAdAccountId?.split("act_")[1]
  }&page=account_settings&tab=account_billing_settings`;

  useEffect(() => {
    handleRefreshPaymentMethod();
  }, [])

  return (
    <Card borderRadius={isMobile ? undefined : "12px"}>
      <Grid container direction="column">
        <StyledLabel fontWeight={600} fontSize={18}>
          Payment Method
        </StyledLabel>
        <StyledLabel
          color="#707070"
          fontSize={16}
          fontWeight={400}
          paddingBottom="16px"
          borderBottomWidth="1px"
        >
          The payment method linked to your ad account {fbAdAccountName && fbAdAccountId ? `${fbAdAccountName} (${fbAdAccountId}) ` : ''}will be charged once the campaign is approved and
          running.
        </StyledLabel>
        {paymentMethodSelected && fbAdAccountId ? (
          <Fragment>
            <Grid
              className={classes.connected}
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <StyledLabel fontSize={16} fontWeight={500}>
                Connected
              </StyledLabel>
              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <StyledLabel
                    color="rgba(136, 0, 255, 1)"
                    fontSize={16}
                    fontWeight={400}
                  >
                    {paymentMethodSelected!.display_string}
                  </StyledLabel>
                  <StyledLink
                    paddingLeft="12px"
                    rel="noreferrer"
                    target="_blank"
                    href={billingSettingsUrl}
                  >
                    <Icon alt="right-arrow.svg" src={RightArrowIcon} />
                  </StyledLink>
                </Grid>
              </Grid>
            </Grid>
            <PaymentMethodButton
              loading={loadingPayment}
              disabled={loadingPayment}
              onClick={handleRefreshPaymentMethod}
              className={paymentMethodButtonClassName}
            />
          </Fragment>
        ) : (
          <Fragment>
            <Grid
              className={classes.addPaymentMethod}
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <Icon
                alt="music-key-green-bg.svg"
                src={MusicKeyGreenBackground}
              />
              <StyledLink
                display="flex"
                fontSize={16}
                color="rgba(136, 0, 255, 1)"
                rel="noreferrer"
                target="_blank"
                href={billingSettingsUrl}
              >
                <StyledLabel marginRight="12px" fontSize={16} fontWeight={400}>
                  Add payment method
                </StyledLabel>
                <Icon alt="right-arrow.svg" src={RightArrowIcon} />
              </StyledLink>
            </Grid>
            <PaymentMethodButton
              loading={loadingPayment}
              disabled={loadingPayment}
              onClick={handleRefreshPaymentMethod}
              className={paymentMethodButtonClassName}
            />
          </Fragment>
        )}
      </Grid>
    </Card>
  );
};

export default PaymentMethodCard;
