/* eslint-disable react-hooks/exhaustive-deps */
import { Tab, Tabs, useMediaQuery, useTheme } from "@material-ui/core"
import { Alert, Typography } from "@mui/material"
import { pageView } from "analytics"
import PaymentFailedBanner from "components/shareable/PaymentFailedBanner"
import PrimaryButton from "components/shareable/PrimaryButton"
import { isAdmin } from "helpers/admin"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { UpgradeModalContext } from "Hooks/UpgradeModalContext"
import { useCurrentTeam } from "Hooks/CurrentTeamContext"
import isEqual from "lodash/isEqual"
import lowerCase from "lodash/lowerCase"
import startCase from "lodash/startCase"
import {
  Dispatch,
  Fragment,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { TabPanel } from "react-headless-tabs"
import { useLocation } from "react-router-dom"
import { CurrentBrand, UserData } from "types/global"
import { SystemColors } from "types/globalStyles"
import { checkIfIsProTier, checkIfOldPlan, getStatusToShowPaymentFailedBanner, isBrandAdministrator } from "utils"
import { ReactComponent as TeamsIcon } from '../../../assets/images/teams.svg'
import AccountTab from "./Components/AccountTab"
import { AdvancedSettingsTab } from "./Components/AdvancedSettingsTab"
import BillingTab from "./Components/BillingTab"
import UsersTab from "./Components/UsersTab"
import {
  BannerContainer,
  Card,
  MainContainer,
  StyledLabel,
  StyledNavBar,
  SupportButton,
  SupportButtonContainer,
  TabsPanelsContainer,
} from "./styles"
import { PageTabs, PageTabsViewOnly } from "./utils"
import { OrganizationSubscriptionStatusBanner } from "components/shareable/OrganizationFailedPayments"
import { Body2, Headline3 } from "components/shareable/Typography"

interface Props {
  loadedBrands: CurrentBrand[]
  deleteBrand?: () => Promise<void>
  user: UserData
}

type GlobalWindow = Window & {
  Intercom: (type: string) => void
}

const { ACCOUNT, BILLING, USERS, ADVANCED_SETTINGS } = PageTabs

// Create new organization tabs array
const organizationTabs = [
  ACCOUNT,
  ADVANCED_SETTINGS,
].map((tab) => ({
  value: tab,
  name: startCase(lowerCase(tab)),
}))


const tabs = [
  USERS,
  ACCOUNT,
  BILLING,
  ADVANCED_SETTINGS,
].map((tab) => ({
  value: tab,
  name: startCase(lowerCase(tab)),
}))

const tabsViewOnly = [
  ACCOUNT,
].map((tab) => ({
  value: tab,
  name: startCase(lowerCase(tab)),
}))

const SettingsPage: FunctionComponent<Props> = ({
  user,
}: Props) => {
  const theme = useTheme()

  const { currentBrand } = useContext(CurrentBrandContext)
  const { handleOpenModal } = useContext(UpgradeModalContext);
  const {
    organization,
    brandIsInOrganization
  } = useCurrentTeam()

  const hideSupportButton = useMediaQuery(theme.breakpoints.down(1024))
  const isProUser = checkIfIsProTier(currentBrand)
  const hasAdminAccess = isBrandAdministrator(currentBrand!)

  const isOnOldPlan = checkIfOldPlan(currentBrand)

  const userIsAdmin = user ? isAdmin(user) : false

  const showPaymentFailedBanner =
    getStatusToShowPaymentFailedBanner(currentBrand)
  let defaultTab: PageTabs | PageTabsViewOnly = PageTabsViewOnly.ACCOUNT;

  // used to check if the brand belongs to the organization
  // so we can render either organization-specific View, or regular pro/free view
  const inOrganization = brandIsInOrganization(currentBrand?.slug!)
 
  if (organization && inOrganization) {
    defaultTab = PageTabs.ACCOUNT;
  } else if (hasAdminAccess) {
    defaultTab = PageTabs.USERS;
  }

  const [materialTabValue, setMaterialTabValue] = useState<PageTabs | PageTabsViewOnly>(defaultTab);
  const { remainingFreeTrial, isOrganizationInactive } = useCurrentTeam()

  const handleClickSupportButton = () => {
    const globalWindow = window as unknown as GlobalWindow
    if (globalWindow.Intercom) globalWindow.Intercom("show")
  }

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabFromQuery = queryParams.get("tab") as PageTabs;

  useEffect(() => {
    if (tabFromQuery && Object.values(PageTabs).includes(tabFromQuery)) {
      setMaterialTabValue(tabFromQuery);
    }
  }, [tabFromQuery]);

  useEffect(() => {
    pageView("Settings");
  }, []);

  const getTabsToRender = (
    brandBelongsToOrganzation: boolean,
    hasAdminAccess: boolean
  ) => {
    if (brandBelongsToOrganzation) {
      return organizationTabs;
    }

    if (hasAdminAccess) {
      return tabs;
    }

    return tabsViewOnly;
  };

  return (
    <Fragment>
      <MainContainer>
        {(showPaymentFailedBanner || remainingFreeTrial > 0 || isOrganizationInactive) && (
          <BannerContainer>
            {showPaymentFailedBanner && <PaymentFailedBanner customerId={currentBrand?.subscription?.cid} />}
            <OrganizationSubscriptionStatusBanner />
          </BannerContainer>
        )}
        <Card padding="40px 24px" borderRadius="8px" marginBottom="16px">
          <StyledLabel fontSize={28}>Settings</StyledLabel>
          <StyledNavBar>
            <Tabs
              scrollButtons="auto"
              variant="scrollable"
              value={materialTabValue}
              onChange={(_event, newValue) => setMaterialTabValue(newValue)}
              aria-label="Settings Options Selector"
              TabIndicatorProps={{
                style: { backgroundColor: SystemColors.ACCENT_COLOR },
              }}
            >
              {getTabsToRender(inOrganization, hasAdminAccess).map(
                ({ name, value }) => (
                  <Tab
                    key={value}
                    disableRipple
                    value={value}
                    label={
                      <span className="capitalize font-medium text-base px-4 py-2">
                        {name}
                      </span>
                    }
                  />
                )
              )}
            </Tabs>
            {!hideSupportButton && (
              <SupportButtonContainer>
                <SupportButton
                  disableTouchRipple
                  onClick={handleClickSupportButton}
                >
                  Support / Help
                </SupportButton>
              </SupportButtonContainer>
            )}
          </StyledNavBar>
          {!isEqual(materialTabValue, ADVANCED_SETTINGS) && (
            <TabsPanelsContainer>
              <TabPanel hidden={!isEqual(materialTabValue, ACCOUNT)}>
                <AccountTab
                  isAdmin={userIsAdmin}
                  email={user?.email}
                  brandSlug={currentBrand?.slug}
                  brandName={currentBrand?.name}
                  brandId={currentBrand?.id}
                  brandConnections={currentBrand?.connections}
                  brandSourceDetails={currentBrand?.affiliate_details}
                />
              </TabPanel>
              <TabPanel hidden={!isEqual(materialTabValue, USERS)}>
                {organization?.status !== "ready" && <Alert
                  className="w-full"
                  icon={<TeamsIcon />}
                  sx={{
                    background: `${SystemColors.ACCENT_COLOR}14`,
                    borderRadius: "12px",
                    marginBottom: "32px",
                    "& .MuiAlert-message": {
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }
                  }
                  }
                >
                  <div className="flex flex-col">
                    <Headline3 color={SystemColors.PRIMARY_TEXT_COLOR}>Unlock discounted pricing with Symphony for Teams</Headline3>
                    <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>Save money + time with a Team Account</Body2>
                  </div>
                  <div>
                    <PrimaryButton height="40px" variant="outlined" text="Upgrade" onClick={() => handleOpenModal({
                      source: "Settings Page - Upgrade to Pro Button",
                      tab: "team"
                    })} />
                  </div>
                </Alert>}
                <UsersTab
                  organization={organization ? organization : undefined}
                  brandBelongsToOrganization={inOrganization}

                  isAdmin={userIsAdmin}
                  email={user?.email}
                  brandId={currentBrand?.id}
                  isProUser={isProUser}
                />
              </TabPanel>
              <TabPanel hidden={!isEqual(materialTabValue, BILLING)}>
                <BillingTab
                  brandBelongsToOrganization={inOrganization}
                  organization={organization ? organization : undefined}
                  subscriptionDetails={currentBrand?.subscription}
                  isAdmin={userIsAdmin}
                  isOnOldPlan={isOnOldPlan}
                  avatar={
                    currentBrand?.image ||
                    "https://www.w3schools.com/howto/img_avatar.png"
                  }
                  brandSlug={currentBrand?.slug}
                  brandName={currentBrand?.name}
                  isProUser={isProUser}
                  currency={currentBrand?.currency}
                />
              </TabPanel>
            </TabsPanelsContainer>
          )}
        </Card>
        {isEqual(materialTabValue, ADVANCED_SETTINGS) && (
          <AdvancedSettingsTab />
        )}
      </MainContainer>
    </Fragment>
  )
}

export default SettingsPage
