import React, { useContext, useState } from 'react';
import { clsx } from 'clsx';
import DefaultAdditionalActions from 'components/shareable/CommonComponent/CardTitle/DefaultAdditionalActions';
import useScreenQueryDimensions from 'Hooks/useScreenQueryDimensions';
import { CurrentBrand, MarketingCampaignType, WebsiteType } from 'types/global';
import MaestroCampaignRecommender from 'pages/post-auth/MarketingPage/Components/CreateNewCampaignModal/MaestroCampaignRecommender';
import { CampaignCreationSource, trackNewCampaign } from 'pages/post-auth/MarketingPage/Components/CampaignsSection/analytics';
import { availableGoals } from 'pages/post-auth/MarketingPage/utils/marketing';
import MusicSiteIcon from 'assets/images/websiteThemes/music-site.svg';
import CreateNewSiteModal from 'pages/post-auth/Website/components/CreateNewSiteModal';
import BrandSelectionModal from '../CreateCampaignModal/SelectBrandModal';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import Axios from 'helpers/Interceptor';

export const POPULAR_CAMPAIGNS = Object.values(availableGoals)
    .filter((o: MarketingCampaignType) => {
        return [
            "increase_record_streams",
            "pre_save",
            'increase_video_views',
            'grow_instagram_engagement'
        ].indexOf(o.key) !== -1
    })
    .map((goal: MarketingCampaignType) => {
        return {
            ...goal
        }
    })

type AskMaestroSource = "Dashboard" | "Marketing" | "Marketing - Team Plan";

export interface QuickActionItem {
    key: string;
    title: string;
    icon: string;
    link?: string;
    isWebsite?: boolean;
}

interface QuickActionPanelProps {
    showMaestro?: boolean;
    campaignCreationSource?: CampaignCreationSource;
    askMaestroSource?: AskMaestroSource;
    campaignItems?: QuickActionItem[];
    className?: string;
    currentBrand?: CurrentBrand;
    initialSiteType?: WebsiteType;
    parentStyle?: React.CSSProperties;
    allowBrandSelector?: boolean;
    loadedBrands?: CurrentBrand[];
    handleQuickActionClick?: (itemKey: string) => void;
}

const defaultQuickActionItems: QuickActionItem[] = [
    {
        key: "music",
        title: "Build a Music Site",
        icon: MusicSiteIcon,
        link: undefined,
        isWebsite: true
    },
    ...POPULAR_CAMPAIGNS.filter(campaignType => !campaignType.underMaintenance).map(campaignType => ({
        key: campaignType.link,
        title: campaignType.title,
        icon: campaignType.icon,
        link: `/marketing/new/${campaignType.link}`,
        isWebsite: false
    }))
];

const QuickActionPanel: React.FC<QuickActionPanelProps> = ({
    showMaestro = true,
    campaignCreationSource = 'dashboard',
    askMaestroSource = 'Dashboard',
    campaignItems = undefined,
    className,
    currentBrand,
    parentStyle,
    allowBrandSelector = false,
    loadedBrands = [],
    handleQuickActionClick = (itemKey: string) => {}
}) => {
    const { setCurrentBrand } = useContext(CurrentBrandContext);

    const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
    const [initialSiteType, setInitialSiteType] = useState<WebsiteType | null>('record_streams');

    let actionsToShow = campaignItems || defaultQuickActionItems;

    const { innerWidth } = useScreenQueryDimensions();
    const mobileView = innerWidth <= 768;


    const [showBrandSelector, setShowBrandSelector] = useState(false);
    const [inProgressQuickAction, setInProgressQuickAction] = useState<string | undefined>(undefined);

    // Handles brand selection when creating a new campaign or website
    // brandId: ID of the brand selected from the brand selector modal
    const handleBrandSelection = async (brandId: string) => {
        try {
            
            let newBrand = currentBrand;
            
            // Only fetch new brand data if selected brand is different from current
            // This prevents unnecessary API calls if user selects same brand
            if (currentBrand?.id !== parseInt(brandId)) {
                const response = await Axios.get(`/brand/${brandId}`);
                newBrand = response.data.data as CurrentBrand;
            }

            // Update global brand context with selected brand
            // Falls back to current brand if new brand fetch fails
            setCurrentBrand(newBrand || currentBrand);

            // Continue with campaign/website creation flow using stored action
            handleQuickActionClick(inProgressQuickAction!);
        } catch (error) {
            return null;
        }
    }

    const openCreateSiteModal = (websiteKey?: string) => {
        setInitialSiteType(websiteKey as WebsiteType || null);
        setOpenCreateModal(true);
    }

    const closeCreateSiteModal = () => {
        setOpenCreateModal(false);
        setInitialSiteType(null);
    }

    const handleCampaignItemClick = (itemKey: string) => {
        setInProgressQuickAction(itemKey);
        if (allowBrandSelector && loadedBrands?.length) {
            setShowBrandSelector(true);
            return;
        } else {
            defaultCampaignItemClick(itemKey);
        }
    }

    const defaultCampaignItemClick = (itemKey: string) => {
        // Find the campaign item that matches the itemKey

        const item = actionsToShow.find(i => i.key === itemKey);
        if (item?.isWebsite) {
            // Handle website creation tracking
            openCreateSiteModal(itemKey)
        } else {
            // Handle regular campaign tracking
            trackNewCampaign({
                type: itemKey,
                from: 'dashboard-quick-actions',
                zeroState: false,
                aiQuestion: null,
                currentBrand: currentBrand
            });

            if(handleQuickActionClick) {
                handleQuickActionClick(itemKey);
            }
        }
    }

    return (
        <>
            <div
                className={
                    `shortcutsBarComponent 
                    ${clsx(
                        'w-full',
                        innerWidth <= 1200 ? 'w-full' : 'lg:w-9/12',
                        'bg-white mx-auto',
                        className
                    )}`
                }
                style={{
                    borderRadius: 8,
                    ...parentStyle
                }}
            >
                {/*
                    TODO: Currently hidden by default. If want to show on mobile, remove the md:hidden
                    and follow the existing implementations in the codebase for reference for pro usage
                */}
                {showMaestro && (
                    <div className="bg-white mb-0 md:hidden w-11/12 mx-auto mt-2">
                        <MaestroCampaignRecommender
                            askMaestroSource={askMaestroSource}
                            hideAskAiBadge={mobileView}
                            defaultQueryOptions={[]}
                            campaignCreationSource={campaignCreationSource as CampaignCreationSource}
                        />
                    </div>
                )}
                <DefaultAdditionalActions
                    hidePrimaryButton
                    mobileView={mobileView}
                    onButtonClick={console.log}
                    campaignItems={actionsToShow}
                    stackedView={mobileView}
                    additionalItemsText={mobileView ? undefined : "Shortcuts:"}
                    onCampaignItemClick={handleCampaignItemClick}
                />
            </div>

            <CreateNewSiteModal
                open={openCreateModal}
                closeModal={closeCreateSiteModal}
                initialSiteType={initialSiteType}
            />

            <BrandSelectionModal
                className="shortcutsBarBrandSelectionModal"
                isOpen={showBrandSelector}
                onClose={() => setShowBrandSelector(false)}
                brands={loadedBrands}
                onBrandSelect={handleBrandSelection}
            />
        
        </>
    );
};

export default QuickActionPanel;