import { Switch } from "@mui/material";
import { TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE, TEAM_PLAN_INCLUDED_PRO_ACCOUNTS } from "../../../../constants";
import React from "react";
import { Container, StyledLabel, TextButton } from "styles/shared";
import { Body2, Caption, Subtitle1 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";


export default function SelectableArtist(props: {
    name: string;
    selected: boolean;
    image: string;
    isAlreadyPro: boolean;
    setSelected: (selected: boolean) => void;
    seatCount: number;
    overageSeat: boolean;
    removeBrand: () => void;
    canRemove: boolean;
}) {

    const {
        name, selected, image, isAlreadyPro, seatCount, overageSeat, removeBrand, canRemove
    } = props

    const styles: Record<string, React.CSSProperties> = {
        image: {
            width: '60px',
            height: '60px',
            borderRadius: '99%'
        },

    }

    function showHelperText() {
        if (isAlreadyPro) {
            if (selected) {
                return "This profile will remain on Pro."
            } else {
                return "This profile will be downgraded to the Free tier."
            }
        } else if (selected) {
            return "This profile will be upgraded to Pro."
        } else {
            return "This profile is on the Free tier."
        }
    }

    function renderSeatStatus() {
        if (selected) {
            if (overageSeat) {
                return (
                    <Caption color={SystemColors.ACCENT_COLOR}>
                        +${TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE} / month
                    </Caption>
                )
            } else {
                return (
                    <StyledLabel>
                        {seatCount} / {TEAM_PLAN_INCLUDED_PRO_ACCOUNTS}
                    </StyledLabel>
                )
            }
        }

        return null
    }
    return (
        <Container borderBottom="1px solid #E5E7EB" padding="12px" display="flex"
            flexDirection="row"

            justifyContent="space-between"
            backgroundColor={selected ? `#f7eeff` : '#fff'}
            style={{
                transition: `all 0.2s ease`
            }}>
            <Container display="flex" flexDirection="row" gap="8px"
                alignItems="center"
                justifyContent="center">
                <img style={styles.image} src={image}></img>
                <Container display="flex" flexDirection="column" gap="0px">
                    <Subtitle1 color={SystemColors.PRIMARY_TEXT_COLOR} >{name}</Subtitle1>
                    <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>{showHelperText()}</Body2>
                    {/* TODO: add back in when we have a way to remove artists from the team */}
                    {canRemove && (
                        <div>
                            <TextButton
                                onClick={removeBrand}
                                color={SystemColors.ERROR_COLOR}>
                                Remove from Team
                            </TextButton>
                        </div>
                    )}
                </Container>
            </Container>
            <Container display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <Container display="flex" flexDirection="column">
                    <StyledLabel>{isAlreadyPro ? 'Pro Profile' : 'Upgrade to Pro'}</StyledLabel>
                    {renderSeatStatus()}
                </Container>
                <Switch
                    disabled={isAlreadyPro}
                    checked={selected}
                    onChange={(e) => props.setSelected(e.target.checked)}
                />
            </Container>

        </Container>
    )
}