import { useEffect, useState } from 'react';
import Colors from 'modules/Colors';
import React from 'react';
import { usePalette } from 'react-palette';

interface ImagePaletteProps {
    imageUrl: string;
    selectedColor: string;
    showBlackAndWhite?: boolean;
    selectColor: (color: string) => void;
    onImageLoad?: () => void;
    autoSelectFirstColor?: boolean;
    autoSelectSecondColor?: boolean;
}

const ImagePalette: React.FC<ImagePaletteProps> = ({
    imageUrl,
    selectedColor,
    showBlackAndWhite,
    selectColor,
    onImageLoad = () => { },
    autoSelectFirstColor = false,
    autoSelectSecondColor = false
}) => {
    const { data, loading, error } = usePalette(imageUrl);
    const [hasAutoSelected, setHasAutoSelected] = useState(false);

    useEffect(() => {
        // if (!loading && !error) {
        //     onImageLoad();
        //     if (autoSelectFirstColor && !hasAutoSelected && Object.values(data).length > 0) {
        //         selectColor(Object.values(data)[0] || '');
        //         setHasAutoSelected(true);
        //     }
        //     if (autoSelectSecondColor && !hasAutoSelected && Object.values(data).length > 1) {
        //         selectColor(Object.values(data)[1] || '');
        //         setHasAutoSelected(true);
        //     }
        // }
    }, [loading, error, data, onImageLoad, autoSelectFirstColor, autoSelectSecondColor, selectColor, hasAutoSelected]);

    const renderBox = (color: string) => {
        let colorBoxContainerStyle = sty.colorBoxContainer;
        if (selectedColor === color) {
            colorBoxContainerStyle = {
                ...sty.colorBoxContainer,
                ...sty.colorBoxSelected,
            }
        }

        return (
            <div key={color} className="selectedColorBox" style={colorBoxContainerStyle}>
                <div
                    className="colorBox"
                    style={{ ...sty.colorBox, ...{ backgroundColor: color, border: color === "#FFF" ? "1px solid #adb7bc" : "none" } }}
                    onClick={() => selectColor(color)}
                />
            </div>
        )
    }

    const renderValues: string[] = []
    const uniqueValuesData = new Set(Object.values(data));
    uniqueValuesData.forEach((color) => { if (color) renderValues.push(color) });

    return (
        <div className="imagePaletteSharable">
            <div style={{ display: 'flex', gap: 8, alignItems: "center" }}>
                {renderValues.map((color) =>
                    renderBox(color)
                )}
                {showBlackAndWhite &&
                    <>
                        {renderBox("#FFF")}
                        {renderBox("#000")}
                    </>
                }
            </div>
        </div>
    );
};

const sty = {
    colorBoxContainer: {
        boxSizing: "border-box",
        borderRadius: 12,
        padding: 4,
    } as React.CSSProperties,
    colorBoxSelected: {
        border: `2px solid ${Colors.purple}`,
    } as React.CSSProperties,
    colorBox: {
        height: 46,
        width: 46,
        fontSize: 12,
        color: "white",
        borderRadius: 7,
        cursor: "pointer",
    } as React.CSSProperties,
}

export default ImagePalette;
