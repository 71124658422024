import { makeStyles } from '@material-ui/core/styles';
import { SystemColors } from 'types/globalStyles';

export const useStyles = makeStyles({
  grayIcon: {
    width: '24px',
    height: '24px',
    '& path': {
      stroke: SystemColors.SECONDARY_TEXT_COLOR,
    },
  },
  FacebookDisabledIcon: {
    width: '24px',
    height: '24px',
    '& path:first-of-type': {
      fill: SystemColors.DISABLED_TEXT_COLOR,
    },
  },
  greenIcon: {
    width: '24px',
    height: '24px',
    '& path': {
      stroke: SystemColors.SUCCESS,
    },
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'center',
    borderBottom: `1px solid ${SystemColors.DIVIDER_LINE}`,
    padding: '12px',
  },
  errorContainer: {
    display: 'flex',
    gap: '12px',
    borderRadius: '8px',
    padding: '8px',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: SystemColors.PAPER_ERROR_CONTAINER,
  },
  questionMarkIcon: {
    cursor: 'pointer',
    width: '16px',
    minWidth: '16px',
    height: '16px',
    minHeight: '16px',
    '& circle': {
      fill: SystemColors.SECONDARY_TEXT_COLOR,
    },
  },
  detailIcon: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    '& svg': {
      width: '24px',
      height: '24px',
    },
  },
  emptySectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px 12px',
    borderRadius: '8px',
    backgroundColor: SystemColors.DIVIDER_LINE,
  },
  accountsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  grayContainer: {
    backgroundColor: SystemColors.PAPER_DIVIDER_CONTAINER,
    padding: '4px',
    borderRadius: '8px',
  },
});