import {
  useState,
  createContext,
  createElement,
  ReactNode,
  FunctionComponent,
} from "react";

interface Upload {
  csv?: File;
  totalEmails: number;
}

interface Match {
  source: string;
  withSource: boolean;
  matches: { id: string; label: string; match: string }[];
}

interface Context {
  upload: Upload;
  match: Match;
  saveUpload: (upload: Upload) => void;
  saveMatch: (match: Match) => void;
}

export const defaultMatches = [
  { id: "names", label: "Names", match: "none" },
  { id: "email", label: "Email", match: "none" },
  { id: "source", label: "Source", match: "none" },
  { id: "tag", label: "Tag", match: "none" },
  { id: "city", label: "City", match: "none" },
  { id: "date-collected", label: "Date collected", match: "none" },
];

export const UploadEmailsByCsvContext = createContext<Context>({
  upload: {
    csv: undefined,
    totalEmails: 0,
  },
  match: {
    source: "",
    withSource: false,
    matches: defaultMatches,
  },
  saveUpload: () => {},
  saveMatch: () => {},
});

type Props = {
  children: ReactNode[] | ReactNode;
};

const MessageYourFansProvider: FunctionComponent<Props> = ({ children }) => {
  const [upload, saveUpload] = useState<Upload>({} as Upload);
  const [match, saveMatch] = useState<Match>({} as Match);

  const onSaveUpload = (upload: Upload) => saveUpload(upload);
  const onSaveMatch = (match: Match) => saveMatch(match);

  const context = {
    upload,
    match,
    saveUpload: onSaveUpload,
    saveMatch: onSaveMatch,
  };

  return createElement(
    UploadEmailsByCsvContext.Provider,
    { value: context },
    children
  );
};

export default MessageYourFansProvider;
