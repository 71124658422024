import { useEffect, useState, useContext } from "react";
import { Dialog, useMediaQuery, useTheme } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import { DebounceInput } from "react-debounce-input";
import Axios from "../../helpers/Interceptor";
import { ModalContainer } from "components/shareable/Modal/styles";
import HeaderModal from "pages/post-auth/MarketingPage/Components/HeaderModal";
import PrimaryButton from "components/shareable/PrimaryButton";
import Text from 'modules/Text';
import BandsInTownIcon from "assets/images/platforms/bandsintown_icon.svg";
import TickCheck from "assets/images/tick.svg";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import Loaderv2 from 'components/Loader/v2';

interface BandsInTownConnectProps {
    closeModal: () => void;
    artist: any;
    selected: any;
    set?: any;
    localSave: (data: any) => void;
    onSave?: any;
    isOpen: boolean;
    getDataCallback: (data: any) => void;
}

export default function BandsInTownConnect(props: BandsInTownConnectProps) {
    const { user } = useAuth0();
    const {
        closeModal,
        isOpen,
        artist,
        selected,
        set,
        localSave,
        onSave,
        getDataCallback,
    } = props;

    const { currentBrand } = useContext(CurrentBrandContext);
    const theme = useTheme();
    const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingConnect, setLoadingConnect] = useState<boolean>(false);
    const [statusMessage, setStatusMessage] = useState<{
        message?: string;
        successMessage?: string;
        errorMessage?: string;
        error?: boolean;
    } | null>(null);
    const [valueString, setValueString] = useState<string>(selected?.name ?? '');
    const [bandsintownTourData, setBandsintownTourData] = useState<any>([]);

    useEffect(() => {
        const normalizedArtistName = valueString.toLowerCase();
        if (normalizedArtistName.length > 2) {
            getBandsintownData();
        }
        setStatusMessage(null);
    }, [valueString]);

    // Fetch BandsInTown data for the given artist
    const getBandsintownData = async () => {
        try {
            setLoading(true);
            const cleanedArtistName = valueString
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
                .replace(/[^a-zA-Z0-9\s]/g, "") // Remove special characters
                .replace(/\s+/g, " ") // Replace multiple spaces with a single space
                .trim(); // Trim leading and trailing spaces
            const response = await Axios.get(`/website/bandsintown/tour-dates?artist=${encodeURIComponent(cleanedArtistName)}`);
            const { bandsintownTourData, artist } = response.data.data;
            setBandsintownTourData(bandsintownTourData);
            const displayName = artist?.name || cleanedArtistName;
            if (bandsintownTourData && bandsintownTourData.length > 0) {
                setStatusMessage({ successMessage: `Found ${bandsintownTourData.length} tour dates for ${displayName}!` });
            } else {
                setStatusMessage({ successMessage: "It looks like you don't have any events coming up. You'll be able to automatically pull events in the future." });
            }
        } catch (error) {
            setStatusMessage({ message: 'There was an error finding your BandsInTown data. Please try searching again.', error: true });
        } finally {
            setLoading(false);
        }
    };

    // Update the brand with BandsInTown connection
    const updateInBrand = async () => {
        setStatusMessage(null);
        try {
            setLoadingConnect(true);
            const res = await Axios.put(`/brand/${artist.slug}/connect`, {
                id: artist.id,
                name: artist.slug,
                service: 'bandsintown',
                value: bandsintownTourData?.[0]?.artist || null
            });

            // Call localSave with the updated data
            localSave(bandsintownTourData?.[0]?.artist || null);

            closeModal();
            getDataCallback(bandsintownTourData);
        } catch (err) {
            console.error('Error creating brand', err);
        } finally {
            setLoadingConnect(false);
        }
    };

    // Render status message or loading indicator
    const renderStatus = () => {
        if (statusMessage?.message) {
            return <p className="text-red-500">{statusMessage.message}</p>;
        }

        if (statusMessage?.successMessage) {
            return (
                <div className="bandsInTownSuccessMessage" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <img src={TickCheck} style={{ width: 20 }} alt="Success" />
                    {statusMessage.successMessage}
                </div>
            );
        }

        if (loading) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Loaderv2 style={{ margin: 0 }} />
                    <p>Loading events for {valueString}...</p>
                </div>
            );
        }

        return null;
    };

    return (
        <Dialog
            open={isOpen}
            onClose={closeModal}
            maxWidth="md"
        >
            <ModalContainer
                width={setResponsiveView ? "auto" : "678px"}
                padding={setResponsiveView ? "32px 24px" : "32px"}
            >
                <HeaderModal
                    closeModal={closeModal}
                    title="Connect BandsInTown"
                    subtitle="Enter your artist name on BandsInTown to connect."
                />
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <img src={BandsInTownIcon} alt="BandsInTown Icon" />
                        <DebounceInput
                            className="debounceInputBandsInTown"
                            minLength={2}
                            placeholder="eg. The Weeknd"
                            debounceTimeout={500}
                            value={valueString}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValueString(e.target.value)}
                            style={Text.input}
                        />
                    </div>
                    {renderStatus()}
                </div>
                <PrimaryButton
                    text='Connect'
                    onClick={updateInBrand}
                    width="100%"
                    className='connectBandsintownButton'
                    loading={loadingConnect}
                    disabled={bandsintownTourData.length === 0 || loading || statusMessage?.error}
                />
            </ModalContainer>
        </Dialog>
    );
}