import { useEffect, useState, Dispatch, SetStateAction, useContext } from "react"
import { TabPanel, useTabs } from "react-headless-tabs"
import MainContentContainer from "components/shareable/MainContentContainer"
import BudgetTab from "./BudgetTab"
import Finalization from "./FinalizationTab"
import { track } from "analytics"
import { scrollIntoView, scrollToTop } from "helpers/StyleUtils"
import { useHistory, useLocation } from "react-router-dom"
import { CurrentBrand, SavingDraftStatus, SelectedTikTokSparkAd, TikTokAdAccount } from "types/global"
import Grid from "@material-ui/core/Grid"
import useStyles from "./styles"
import TopBar from "components/shareable/TopBar"
import CustomStepper from "components/shareable/CustomStepper"
import { getNextButtonLabel, getNextButtonWidth, STEPS } from "./utils"
import { useMediaQuery, useTheme } from "@material-ui/core"
import {
  useDraftDataGetter,
  useDraftDataSetter,
} from "./Hooks/DraftsHandlers"
import CreativeTab from "./CreativeTab"
import clsx from "clsx"
import TiktokAdPreview, { SelectedCaption } from "components/shareable/TikTokAdPreview"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"

const { CREATIVE, BUDGET, FINALIZATION } = STEPS

const {
  INITIAL_SAVING_STATE,
} = SavingDraftStatus

interface Props {
  setGoal: Dispatch<SetStateAction<string>>
  refreshCampaigns: () => void
  reloadBrands: () => void
}




export const VideoCampaignTypes = [
  // {
  //     id: 'default',
  //     name: '⚡️ Recommended',
  //     text: "Run your video ads with Symphony's recommended approach."
  // },
  {
    id: "engagement",
    name: "🗣 More Engagement",
    text: "Run your video ads focused on driving more engagement to your video, including likes, comments, and subscribers.",
  },
  {
    id: "views",
    name: "📈 Cheapest Views",
    text: "Run your video ads to drive as many views as possible on your video.",
  },
]

const steps = [
  // { description: "Targeting", name: TARGETING },
  { description: "Creative", name: CREATIVE },
  { description: "Budget", name: BUDGET },
  { description: "Review", name: FINALIZATION },
]

const DEFAULT_TAB = CREATIVE

export default function TiktokAds({ }: Props) {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const location = useLocation()
  const draft = new URLSearchParams(location.search).get("draft")
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const isNextButtonResponsive = useMediaQuery(theme.breakpoints.down(601))
  const isNextButtonMobile = useMediaQuery(theme.breakpoints.down(401))
  const { currentBrand } = useContext(CurrentBrandContext) as { currentBrand: CurrentBrand }
  const tabs = steps.map(({ name }) => name)
  const [selectedTab, setSelectedTab] = useTabs(tabs, DEFAULT_TAB)

  const [selectedAudiences, setAudiences] = useState([])
  // const [campaignType, setCampaignType] = useState<string>("profile-visits")
  const [geographyType, setGeographyType] = useState<string>("english")
  const [selectedCountries, setSelectedCountries] = useState<
    { label: string; value: string }[]
  >([])
  const [budgetValue, setBudgetValue] = useState(20)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [isSavingDraft, setIsSavingDraft] = useState(false)
  const [campaignDraftData, setCampaignDraftData] = useState<Record<
    string,
    unknown
  > | null>(null)
  const [retrievingDraftData, setRetrievingDraftData] = useState(!!draft)
  const [requiredDataChanged, setRequiredDataChanged] = useState(false)
  const [savingDraftStatus, setSavingDraftStatus] = useState(INITIAL_SAVING_STATE)


  // link + creative 
  // React state hook for storing the selected posts
  const [selectedPosts, setSelectedPosts] = useState<SelectedTikTokSparkAd[]>([]);



  // handles the link that the user entered
  const userTiktok = currentBrand &&
    currentBrand.connections &&
    currentBrand.connections.tiktok &&
    currentBrand.connections.tiktok.username ? `https://www.tiktok.com/@${currentBrand.connections.tiktok.username}` : null


  const [tiktokLoggedIn, setTikTokLoggedIn] = useState<any>(currentBrand &&
    currentBrand.connections && currentBrand.connections.tiktok_business ? currentBrand.connections.tiktok_business : null);
  const [selectedTiktokAdAccount, setSelectedTikTokAdAccount] = useState<TikTokAdAccount | null>(
    currentBrand &&
      currentBrand.connections &&
      // && (currentBrand.connections.facebook_business_manager
      // && currentBrand.connections.facebook_ad_account)
      currentBrand.connections.tiktok_ad_account ? currentBrand.connections.tiktok_ad_account : null
  )

  const [link, setLink] = useState<string>(userTiktok ? userTiktok : "")

  // handles the Spark Code 
  const [sparkCode, setSparkCode] = useState<string>("")

  const [adConnectionsAdded, setAdConnectionsAdded] = useState(false)

  const commonTrackData = {
    brand_id: currentBrand.id,
    brand_name: currentBrand.name,
    brand_slug: currentBrand.slug,
    video_code: sparkCode
  }
  const mainContainerMb =
    selectedTab === CREATIVE || selectedTab === FINALIZATION ? 32 : 24
  const disableNextButtonWhenBudgetTab = !(
    typeof budgetValue !== "undefined" &&
    budgetValue !== null &&
    budgetValue > 0
    // &&
    // startDate &&
    // endDate
  )
  const dataToSaveDraft = [
    sparkCode,
    budgetValue,
    startDate,
    endDate,
    selectedAudiences,
    // campaignType,
    geographyType,
    selectedTab,
    selectedCountries,
  ]

  const mainTitle = "Run TikTok Ads"


  const {
    reloadBrand
  } = useContext(CurrentBrandContext);

  const handleBudgetTabNextButton = () => {
    setSelectedTab(FINALIZATION)
    track("Completed Campaign Step - Budget Selection", {
      type: "tiktok_ads",
      step: 3,
      name: "budget",
      budget: budgetValue,
      startDate: startDate,
      endDate: endDate,
      ...commonTrackData,
    })
  }

  const handleClickCloseTopBar = () => {
    history.push("/marketing")
  }

  const handleClickBackButton = () => {
    if (selectedTab === CREATIVE) {
      if (adConnectionsAdded) {
        setAdConnectionsAdded(false)
      } else {
        history.push("/marketing")
      }
    } else {
      setSelectedTab(tabs[tabs.indexOf(selectedTab as STEPS) - 1])
    }
  }


  const handleClickNextButton = () => {
    if (selectedTab === CREATIVE) {
      if (adConnectionsAdded) {
        setSelectedTab(BUDGET)
      } else {
        setAdConnectionsAdded(true)
      }
    } else if (selectedTab === BUDGET) {
      setSelectedTab(FINALIZATION)
    }
  }

  useEffect(() => {
    if (sparkCode) isValidSparkCode(sparkCode)
  }, [sparkCode])
  function isValidSparkCode(string: string) {
    var res = string.match(/\#[-a-zA-Z0-9@:%._\+~#=]{2,256}\=/);
    return (res !== null)
  };

  useEffect(() => {
    scrollToTop()
    scrollIntoView()
  }, [selectedTab])

  useEffect(() => {

    if (selectedTiktokAdAccount) {
      setAdConnectionsAdded(true)
    } else {

    }
  }, [])

  useDraftDataGetter({
    setters: {
      setSelectedCountries,
      setGeographyType,
      // setCampaignType,
      setAudiences,
      setBudgetValue,
      setStartDate,
      setEndDate,
      setSelectedTab,
      setRetrievingDraftData,
      setCampaignDraftData,
      setIsSavingDraft,
    } as Record<string, Dispatch<unknown>>,
    draft,
  })

  useDraftDataSetter({
    setters: {
      setRequiredDataChanged,
      setIsSavingDraft,
      setCampaignDraftData,
      setSavingDraftStatus
    } as Record<string, Dispatch<unknown>>,
    campaignDraftData,
    requiredDataChanged,
    sparkCode,
    retrievingDraftData,
    dataToSaveDraft: dataToSaveDraft as unknown as Record<string, unknown>[],
    draft,
    currentBrand,
    savingDraftStatus
  })

  const canShowAdPreview: boolean = (selectedTab === CREATIVE && adConnectionsAdded) ? true : false


  function renderAdPreview() {
    const video = selectedPosts && selectedPosts.length > 0 ? selectedPosts[0].video_info.preview_url : undefined
    const thumbnail = selectedPosts && selectedPosts.length > 0 ? selectedPosts[0].video_info.poster_url : undefined

    const mainCaptions: SelectedCaption[] | null = selectedPosts && selectedPosts.length > 0 ? [{
      caption: selectedPosts[0].item_info.text,
      type: 'all'
    }] : null

    const profile = selectedPosts && selectedPosts.length > 0 ? {
      name: selectedPosts[0].user_info.tiktok_name,
      picture: null
    } : undefined
    let previewModule = (shown: boolean, onClose?: () => void) => (<TiktokAdPreview
      callToAction={"Learn More"}
      closePreview={onClose}
      shown={shown}
      profile={profile}
      captions={mainCaptions}
      previewedPost={null}
      selectedPosts={
        selectedPosts ? [{
          postType: 'tiktok',
          caption: mainCaptions ? mainCaptions[0].caption : '',
          media_url: video,
          media_type: "VIDEO",
          thumbnail_url: thumbnail,
          type: 'story'
        }] : []}

    />)

    // if (window.innerWidth < 1200) {
    //   return <Modal
    //     aria-labelledby="connector-modal"
    //     aria-describedby="connector-modal for platforms"
    //     className={adPreviewStyles.previewAdModal}
    //     open={showPreview}
    //     onClose={() => enablePreviewMode(false)}
    //     closeAfterTransition
    //     BackdropComponent={Backdrop}
    //     BackdropProps={{
    //       timeout: 400,
    //     }}
    //   >
    //     <Fade
    //       in={showPreview}
    //     >

    //       {previewModule(true, () => enablePreviewMode(false))}

    //     </Fade>
    //   </Modal>
    // } else {
    return previewModule(canShowAdPreview)
    // }
  }

  return (
    <Grid
      className={
        selectedTab === CREATIVE && adConnectionsAdded
          ? classes.creativeTabHighMarginBottom
          : classes.normalMarginBottom
      }>
      <TopBar
        title={mainTitle}
        showSaveDraftChip={!!draft || !!sparkCode}
        showCloseIcon
        savingChanges={isSavingDraft}
        handleClose={handleClickCloseTopBar}
      />
      <CustomStepper
        {...{ steps }}
        activeStep={steps.map(({ name }) => name).indexOf(selectedTab as STEPS)}
        stepButtonsProps={{
          handleClickBackButton,
          handleClickNextButton,
          showNextButton: selectedTab !== FINALIZATION,
          disableNextButton:
            // (selectedTab ===  CREATIVE && !adConnectionsAdded) ||

            // (selectedTab === CREATIVE && adConnectionsAdded && !(selectedPosts.length > 0)) ||
            (selectedTab === BUDGET && disableNextButtonWhenBudgetTab) ||
            selectedTab === FINALIZATION,
          nextButtonLabel: getNextButtonLabel(isNextButtonMobile, selectedTab),
          nextButtonWidth: getNextButtonWidth({
            isNextButtonMobile,
            isNextButtonResponsive,
            selectedTab
          }),
        }}
      >
        <MainContentContainer className={
          clsx(
            classes.mainContainer,
            canShowAdPreview && classes.creativeTabContainer
          )}
        >
          <Grid
            className={clsx(
              `bg-white h-full w-full rounded-${isMobile ? "none" : "xl mt-6"
              }`,
              classes.mbWhenNotTargetingView,
              canShowAdPreview && classes.gridCreativeTabContainer,
            )}
          >
            {/* <TabPanel hidden={selectedTab !== TARGETING}>
              <VideoTargetingTab
                link={link}
                setLink={setLink}
                brand={currentBrand}
                showNextButton={false}
                setGeographyType={setGeographyType}
                // setTargetingType={setCampaignType}
                // targetingType={campaignType}
                geographyType={geographyType}
                selectedAudiences={selectedAudiences}
                setAudiences={setAudiences}
                nextTab={handleTargetingTabNextButton}
                setSelectedCountries={setSelectedCountries}
                selectedCountries={selectedCountries}
              />
            </TabPanel> */}
            <TabPanel hidden={selectedTab !== CREATIVE}>
              {/* <LinkEntryTab
                currentLink={sparkCode}
                setCurrentLink={setSparkCode}
                handleOnClickContinue={console.log}
                showNextButton={false}
                setSelectedPosts={setSelectedPosts}
                selectedPosts={selectedPosts}
                currentBrand={currentBrand}
              /> */}
              <CreativeTab
                reloadBrand={reloadBrand}
                setTiktokLoggedIn={setTikTokLoggedIn}
                tiktokLoggedIn={tiktokLoggedIn}
                brand={currentBrand}
                selectedTiktokAdAccount={selectedTiktokAdAccount}
                setSelectedTikTokAdAccount={setSelectedTikTokAdAccount}
                setSelectedAssets={setSelectedPosts}
                selectedAssets={selectedPosts}
                setSelectedTab={setSelectedTab}
                reloadBrands={console.log}
                setCTA={console.log}
                cta={console.log}

                adConnectionsAdded={adConnectionsAdded}
                setAdConnectionsAdded={setAdConnectionsAdded}
                showContinueButton={false}
                previewedVideo={null}
                setPreviewedVideo={console.log}

              />
            </TabPanel>

            <TabPanel hidden={selectedTab !== BUDGET}>
              <BudgetTab
                brand={currentBrand}
                geographyType={geographyType}
                // targetingType={campaignType}
                budgetValue={budgetValue}
                setBudgetValue={setBudgetValue}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                finishFlow={handleBudgetTabNextButton}
                showReviewCampaignButton={false}
              />
            </TabPanel>
            <TabPanel hidden={selectedTab !== FINALIZATION}>
              <Finalization
                selectedPosts={selectedPosts}
                sparkCode={sparkCode}
                link={link}
                campaignId={draft}
                budget={budgetValue}
                startDate={startDate}
                endDate={endDate}
                // targetingType={campaignType}
                geographyType={geographyType}
                brand={currentBrand}
                targeting={selectedAudiences}
                selectedCountries={selectedCountries}
              />
            </TabPanel>
          </Grid>
          {renderAdPreview()}

        </MainContentContainer>
      </CustomStepper>
    </Grid>
  )
}
