/* eslint-disable react-hooks/exhaustive-deps */
import millify from "millify";
import { useEffect, useState, Dispatch, SetStateAction, Fragment } from "react";
import { LoadingIndicator } from "components/Loader";
import Axios from "helpers/Interceptor";
import { track } from "analytics";
import dayjs from "dayjs";
import { VideoCampaignTypes, VideoGeographyTypes } from "./constants";
import InfoSection from "components/InfoSection";
import RowItem from "components/shareable/RowItem";
import UnlockDataBanner from "components/shareable/UnlockDataBanner";
import UnlockDataImage from "assets/images/unlock-data-geographies.svg";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { CampaignDetailsContainer } from "../SongAds/styles";
import SymphonyTabs, { TabContent } from "components/shareable/SymphonyTabs";
import { NewLoader } from "components/Loader/ConnectBrandLoader";
import { TabsContainer } from "../styles";
import DemographicsStats from "./CampaignDetails/DemographicsStats";
import AudiencesStats from "./CampaignDetails/AudienceStats";
import { TargetingBlock, videoViewRejectionIdToText } from "./utils";
import Intercom from "helpers/Intercom";
import OverviewCampaignCard from "../Components/OverviewCampaignCard";
import useModal from "Hooks/useModal";
import EditCampaignNameModal from "../Components/Modals/EditCampaignNameModal";
import ErrorUpdatingCampaignModal from "../Components/ErrorUpdatingCampaignModal";
import { DEFAULT_CURRENCY } from "../constants";
import getSymbolFromCurrency from "currency-symbol-map";
import { FacebookCampaignSimpleBanner } from "../Components/FacebookCampaignIssuesBanner/simple-banner";



const getCampaignDetails = async (
  id: string,
  setCampaignDetails: Dispatch<SetStateAction<any>>
) => {
  try {
    const path = `/campaign/${id}`;
    const response = await Axios.get(path);
    const data = response?.data?.data;
    if (data) setCampaignDetails(data);
  } catch (error) {
    //TODO: Add error handler
    console.log("Error on Playlisting/CampaignDetails component: ", error);
  }
};

enum PageTabs {
  PERFORMANCE = 'performance',
  DEMOGRAPHICS = 'demographics',
  AUDIENCE = 'audience'
}





export default function CampaignDetails(props: any) {
  const theme = useTheme()
  const hideBannerImage = useMediaQuery(theme.breakpoints.down(701))
  const { selectedCampaign, currentBrand, isProUser, handleGoBack } = props
  const { campaign_metadata, id: campaignId } = selectedCampaign
  const currency = campaign_metadata.currency?.code || DEFAULT_CURRENCY
  const currencySymbol = getSymbolFromCurrency(currency)

  const {
    campaign_type,
    content,
    friendlyName,
    price_data,
    logistics,
    targetingType, // default, views, engagement,
    geographies
    // type: global | english | custom,
    // countries: [{ value: 'country code', label: 'name' }]
  } = campaign_metadata;

  const { startDate, endDate, refundableAmount = null, refundFeeConfirmed = false } = logistics

  const {
    name: contentName,
    thumbnail_url: contentThumbnail,
    slug: contentSlug,
  } = content;

  const [isLoading, setLoading] = useState(true);

  const [selectedView, setSelectedView] = useState<PageTabs>(PageTabs.PERFORMANCE);

  const [videoStatsData, setVideoStatsData] = useState<any>(null)

  // =====================
  // menu options + modals 
  // =====================

  const trackObject = {
    campaign_id: campaignId,
    campaign_type: campaign_type,
    brand_id: currentBrand.id,
    brand_slug: currentBrand.slug,
  };

  // Campaign Name Modal
  const campaignNameModal = useModal({
    nameTrack: 'Open Rename Campaign',
    metadata: {
      ...trackObject,
      opened: 'Rename Campaign Modal',
    }
  });

  const [campaignName, setCampaignName] = useState<string>(friendlyName || content?.name || content?.campaign_name);

  const handleOpenRenameCampaignModal = () => campaignNameModal.openModal();
  const handleCloseRenameCampaignModal = () => campaignNameModal.closeModal();
  const handleChangeName = (name: string) => {
    setLoading(true)
    setCampaignName(name);
  }

  // Error Modal
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const handleOpenErrorModal = () => setShowErrorModal(true)
  const handleCloseErrorModal = () => setShowErrorModal(false)

  // =====================
  // campaign State
  // =====================
  const [campaignDetails, setCampaignDetails] = useState<{
    createdAt: string;
    status: string;
    campaign_metadata: {
      targeting: TargetingBlock[]
    }
  }>({
    createdAt: "",
    status: 'IN_REVIEW',
    campaign_metadata: {
      targeting: []
    }
  });
  const [overallCampaignStatus, setOverallCampaignStatus] = useState<any>({
    status: null,
    note: null,
  });

  useEffect(() => {
    if (selectedCampaign) {
      track("Campaign Details Viewed", {
        type: "increase_video_views",

        brand_id: currentBrand.id,
        brand_name: currentBrand.name,
        brand_slug: currentBrand.slug,
        content_name: content.name,
        content_slug: content.slug,
        content_id: content.id
      });
    }
    if (selectedCampaign && currentBrand) {
      getCampaignDetails(campaignId, setCampaignDetails);
    } else {
      //TODO: Add error handler
      console.log("could not get all data");
    }
  }, []);

  useEffect(() => {
    if (campaignDetails.createdAt) {
      getVideoAdsStatus()
    } else {
      //TODO: Add error handler
      console.log("could not get all data");
    }
  }, [campaignDetails.createdAt]);


  async function getVideoAdsStatus() {

    try {
      setLoading(true);

      const campaigns = await Axios.get(
        `/campaign/brand/${currentBrand.id}/${selectedCampaign.id}/status`, {
        timeout: 300000 // TODO 630: Remove it once query performance is fixed
      }
      );
      setLoading(true);

      const videoStats = await campaigns?.data?.data;
      if (videoStats) {

        switch (campaignDetails.status) {

          case 'ACTIVE':
            if (videoStats && videoStats.views === 0) {
              setOverallCampaignStatus({
                status: "active",
                label: "ACTVE",

                note: "Your campaign just went live! You'll be able to see data here as stats start coming in.",
              });
            } else {
              setOverallCampaignStatus({
                status: "active",
                label: "ACTVE",

                note: null,
              });
            }

            break
          case 'PAUSED':
            setOverallCampaignStatus({
              status: "paused",
              label: "PAUSED",

              note: "Your campaign has been paused per your request. If you have any questions, please reach out to the Symphony team at team@symphonyos.co.",
            });


            break
          case 'FINISHED':
            setOverallCampaignStatus({
              status: "finished",
              label: "FINISHED",

              note: null,
            });
            break

          case 'DENIED':

            setOverallCampaignStatus({
              status: "denied",
              label: "DENIED",

              note: null
            })
            break
          case 'PROCESSING':
            setOverallCampaignStatus({
              status: "processing",
              label: "PROCESSING",
              note: null
            });
            break

          default:
          case 'IN_REVIEW':
            setOverallCampaignStatus({
              status: "in_review",
              label: "IN REVIEW",
              note: null
            });
            break


        }
        setVideoStatsData(videoStats)

      }

      if (videoStats && videoStats.status) {
      }

      // get the stats
      setLoading(false);

    } catch (e) {
      console.log('error getting video stats data', e)
      setOverallCampaignStatus({
        status: "error",
        note: e,
      });

      setLoading(false)
    }
  }


  function showAdsStatusMessage() {
    const framework = (props: {
      title?: string,
      description: string | JSX.Element,
      type?: "success" | "warning"
    }) => {
      const {
        title,
        description,
        type
      } = props

      return (<div className="py-5 px-5 pb-0 lg:px-10">

        <div className="rounded-md"
          style={{
            backgroundColor: type === 'success' ? "#ebfaeb" : "#FDF6EC"
          }}>
          <div className="p-5">
            {title && (<p style={{
              color: type === 'success' ? "#07C806" : "#754600"
            }}>{title}</p>)}
            <p style={{
              color: "black"
            }}>
              {description}
              {/* <button
                className="font-medium"

                style={{
                  color: "#8800FF"
                }}
                onClick={() => {
                  Intercom.openInstagramBusinessAccountArticle()
                }}>Learn how to switch to a Business Account &gt;</button> */}
            </p>
          </div>

        </div>
      </div>)
    }

    switch (overallCampaignStatus.status) {
      case "pending":
        return null;
      case "active":
        if (!overallCampaignStatus.note) {
          return null;
        } else {
          return framework({
            description: overallCampaignStatus.note,
            type: "success"
          })
        }
      case "error":
        return (
          <div className="rounded-sm bg-red-500 text-white py-1 text-md px-3 text-center">
            <p className="w-8/12 m-auto">{overallCampaignStatus.note}</p>
          </div>
        );
      case "in_review":
        return framework({
          title: "YouTube is reviewing your campaign.",
          description: "Your campaign is currently in YouTube Ads review. It may take up to 72 hours to get through the review process. As soon as your campaign goes live, you'll be able to see updates here."
        })
      case "paused":
        return framework({
          title: "Your campaign has been manually paused.",
          description: overallCampaignStatus.note
        })


      case "live_with_issues":
        return (
          <div className="rounded-sm bg-green-500 text-white py-1 text-md px-3 text-center">
            <p className="w-8/12 m-auto">{overallCampaignStatus.note}</p>
          </div>
        );
      case "denied":
        const refunded = videoStatsData.refunded
        const cancelledReason: string = videoStatsData.cancelled_reason;
        let additionalContext = videoViewRejectionIdToText(cancelledReason);



        const price = campaign_metadata.price_data.price.toLocaleString('en', {
          minimumFractionDigits: 2
        })


        let refundText;

        // if refund fee confirmed - the user created the campaign after they confirmed to the 5% processing fee
        if (refundFeeConfirmed) {
          if (refunded) {
            refundText = (
              <span>We've already refunded your payment of {currencySymbol}{refundableAmount}. {currencySymbol}{price - refundableAmount} (5% of your budget) will be used to handle payment processing fees. Your refund should arrive within 3-5 days based on your bank's processing time.</span>
            )
          } else {
            refundText = (
              <span>You will receive a refund for {currencySymbol}{refundableAmount} in 3 - 5 business days, depending on your bank's processing time. {currencySymbol}{price - refundableAmount} (5% of your budget) will be used to handle payment processing fees.</span>
            )
          }
        } else {
          // otherwise, the user has not confirmed the refund fee
          if (refunded) {
            refundText = (
              <span>We've already refunded your payment of {currencySymbol}{price}. Your refund should arrive within 3-5 days based on your bank's processing time.</span>
            )
          } else {
            refundText = (
              <span>You will receive a refund for {currencySymbol}{price} in 3 - 5 business days, depending on your bank's processing time.</span>
            )
          }
        }

        return framework({
          title: "Your video wasn't approved by YouTube's Ads Review Process.",
          description: (
            <>
              <span>Unfortunately YouTube's Ads Review denied your video. This can happen for a variety of reasons, and is handled directly on YouTube's end and out of our team's control.
                {additionalContext ? (
                  <>
                    <span>Here is the Ads Rejection reason provided by YouTube:</span>
                    <br />
                    <span>{additionalContext}</span>
                    <br />
                    <br />
                  </>) : null}
              </span>
              <br />
              <br />
              {refundText}
              <br />
              <br />


              <span>YouTube Ads work well once the video is approved, so we recommend submitting future videos to try to get them past review.</span>
              <br />
              <button
                className="mt-2 font-medium"

                style={{
                  color: "#8800FF"
                }}
                onClick={() => {
                  Intercom.openYouTubeAdsReview()
                }}>Learn how YouTube reviews their ads &gt;</button>

            </>
          ),
          type: "warning"
        })
    }
  }

  function showVideoStatsData() {
    let spentAmount = 0

    // if the budget overspends by a bit, dont show the amount - just show the max budget
    if (videoStatsData && videoStatsData.cost) {
      const currentSpent = parseFloat(videoStatsData.cost)
      const totalBudget = parseFloat(price_data.price)

      // if the campaign is finished and the amount spent is less than
      // the budget, mark it up
      if (overallCampaignStatus && overallCampaignStatus.status === 'finished') {
        spentAmount = totalBudget
      } else {
        spentAmount = Math.min(currentSpent, totalBudget)
      }

    }
    return (
      <>
        <div className="px-6 lg:px-10 py-5 mt-2">
          <p className="text-lg font-semibold">Performance</p>
        </div>
        <div className="px-6 lg:px-10 py-5 border-b border-t flex flex-row justify-between">
          <div className="w-3/12">
            <p className="text-md font-normal ">Total Views Driven</p>
            <p className="text-xl font-semibold mt-2">
              {videoStatsData && videoStatsData.views
                ? videoStatsData.views.toLocaleString('en')
                : 0
              }
            </p>
          </div>
          <div className="w-3/12">
            <p className="text-md font-normal ">Avg. Cost-per-View</p>
            <p className="text-xl font-semibold mt-2">
              {currencySymbol}{videoStatsData && videoStatsData.cpv
                ? videoStatsData.cpv.toLocaleString('en', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  type: 'currency'
                })
                : (0).toFixed(2)}
            </p>
          </div>
          <div className="w-3/12">
            <p className="text-md font-normal ">Total Spent</p>
            <p className="text-xl font-semibold mt-2">
              {currencySymbol}{spentAmount.toFixed(2)}
            </p>
          </div>
        </div>
        {!isProUser ? (
          <>
            <div className="px-6 lg:px-10 py-5 flex flex-col justify-between">
              <UnlockDataBanner
                source="Video View Campaign Details - Demographics"
                title="Learn exactly who your fans are"
                description="Go Pro to see where fans love your music the most"
                image={UnlockDataImage}
                hideImage={hideBannerImage}
              />
            </div>
            <div className="px-0 lg:px-4 py-5 border-b flex flex-col justify-between">
              <img src={require("../../../../assets/images/campaignDetails/impactBlurredCountries.png").default} />
            </div>
          </>
        ) : videoStatsData && geographies && videoStatsData.locationsBreakdown && videoStatsData.locationsBreakdown.length > 0 ? (
          <>
            <InfoSection
              title={`Geography Targeting (${VideoGeographyTypes.find((o: any) => o.id === geographies.type) ? VideoGeographyTypes.find((o: any) => o.id === geographies.type)!.name : ''})`}
              description='These are the top countries that drove the most views to your video.'
            />
            <div>
              <ul className="max-h-80 overflow-auto">
                {
                  videoStatsData.locationsBreakdown
                    .sort((a: {
                      views: number
                    }, b: {
                      views: number
                    }) => {
                      return b.views - a.views
                    })
                    .map((item: {
                      country: string,
                      cpv: number,
                      views: number,
                      spend: number,
                    }) => {
                      // get friendly name of service
                      return (
                        <RowItem
                          key={item.country}
                          // onClick={() => selectYoutubeChannel(item)}
                          // image={image}
                          // imageComponent={
                          //   <ReactCountryFlag
                          //     style={{
                          //       fontSize: '1.6em'
                          //     }}
                          //     countryCode={item.country} />
                          // }
                          imageStyles={`h-6`}
                          name={item.country}
                          showRightValue={true}
                          rightValueComponent={(
                            <div className="pl-6 pr-3  text-right">
                              <p>
                                {millify(item.views)} views
                              </p>
                              <p className="text-xs text-gray-400">{currencySymbol}{item.cpv.toLocaleString('en', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })} Cost-per-View</p>
                            </div>
                          )}

                        />
                      );
                    })
                }
              </ul>
            </div>
          </>
        ) :
          videoStatsData && videoStatsData.geographicsBreakdown && videoStatsData.v2Data ? (
            <>
              <DemographicsStats
                demographicBreakdown={videoStatsData.ageGenderBreakdown}
                geographicBreakdown={videoStatsData.geographicsBreakdown}
                marketsType={geographies && geographies.type ? geographies.type : 'global'}
                currency={currency}
              />
            </>)
            : null}
      </>
    );
  }

  return (
    <CampaignDetailsContainer backgroundColor="#F3F5F6">
      <OverviewCampaignCard
        isIGCampaign={false}
        isLoading={isLoading}
        selectedCampaign={selectedCampaign}
        disableCampaignUpdates={true}
        campaignName={campaignName}
        thumbnail={contentThumbnail}
        campaignStatusLabel={campaignDetails.status}
        campaignStatus={campaignDetails.status.toLowerCase()}
        goBack={handleGoBack}
        handleOpenRenameCampaignModal={handleOpenRenameCampaignModal}
      />
      {overallCampaignStatus.status === 'processing' && (
        <FacebookCampaignSimpleBanner
          title="Your payment is still being processed."
          description="Once your payment is successfully processed, your campaign will be submitted directly. You'll only be charged if your payment is processed fully, otherwise you won't be charged for your campaign."
        />
      )}
      <TabsContainer gap={"0px"}>
        <nav className="border-b">
          <SymphonyTabs
            data={[
              {
                value: PageTabs.PERFORMANCE,
                label: "Stats"
              },
              {
                value: PageTabs.AUDIENCE,
                label: "Audience"
              }
            ]}
            onChange={(value) => setSelectedView(value as PageTabs)}
            selectedValue={selectedView}
          />
        </nav>
        {isLoading ? <div className="my-4 flex-col flex items-center ">
          <NewLoader black />
          <p className="mt-1 text-center">Loading Dashboard...</p>
        </div> : (
          <>
            <TabContent hidden={selectedView !== PageTabs.PERFORMANCE}>
              <Fragment>
                {targetingType && targetingType !== 'default' ? (<div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                  <p className="text-lg font-semibold w-6/12">Campaign Type</p>
                  <div className="flex flex-col text-right">
                    <p className="text-lg">{VideoCampaignTypes.find((o: any) => o.id === targetingType) ? VideoCampaignTypes.find((o: any) => o.id === targetingType)!.name : ''}</p>
                  </div>
                </div>) : <Fragment />}

                {geographies ? (<div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                  <p className="text-lg font-semibold w-6/12">Geographic Targeting</p>
                  <div className="flex flex-col text-right">
                    <p className="text-lg">{VideoGeographyTypes.find((o: any) => o.id === geographies.type) ? VideoGeographyTypes.find((o: any) => o.id === geographies.type)!.name : ''}</p>
                    {geographies.type === 'custom' ? (<div className="block">
                      <p>&#40;{geographies.countries.map((item: string, idx: number) => {
                        return item
                      }).join(', ')}&#41;</p>
                    </div>) : null}
                  </div>
                </div>) : <Fragment />}

                <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                  <p className="text-lg font-semibold">Budget</p>
                  <p className="text-lg">
                    {currencySymbol}{(price_data.price).toLocaleString('en', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      type: 'currency'
                    })}
                  </p>
                </div>

                <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                  <p className="text-lg font-semibold">Campaign Length</p>
                  <div className="flex flex-col text-right">

                    <p className="text-lg">{dayjs(endDate).diff(startDate, 'day')} day{dayjs(endDate).diff(startDate, 'day') > 1 ? 's' : ''}</p>

                  </div>
                </div>
                {showAdsStatusMessage()}

                {showVideoStatsData()}

              </Fragment>
            </TabContent>
            <TabContent hidden={selectedView !== PageTabs.AUDIENCE}>
              <AudiencesStats
                placements={videoStatsData && videoStatsData?.placementsBreakdown ? videoStatsData.placementsBreakdown : []} currency={currency} />
            </TabContent>
          </>
        )}
      </TabsContainer>
      {!isLoading && <EditCampaignNameModal
        campaignId={campaignId}
        originalCampaignName={content?.name || content?.campaign_name}
        closeModal={handleCloseRenameCampaignModal}
        currentCampaignName={campaignName}
        open={campaignNameModal.open}
        reload={handleChangeName}
        onErrorHandler={handleOpenErrorModal}
        trackObject={trackObject}
      />}
      <ErrorUpdatingCampaignModal
        closeModal={handleCloseErrorModal}
        open={showErrorModal}
        campaignId={campaignId}
      />

    </CampaignDetailsContainer>
  );
}
