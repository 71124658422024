import { Body2 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import { ReactComponent as MarketingGray } from "assets/images/marketingIcon-gray.svg";
import PrimaryButton from "components/shareable/PrimaryButton";

const EmptyState = ({
    isFiltering = false,
    buttonAction,
    primaryText = "No campaigns found.",
    buttonText = "Create a Campaign",
}: { isFiltering?: boolean, buttonAction?: () => void, primaryText?: string, buttonText?: string }) => {
    return (
        <div className="h-full w-full flex justify-center items-center rounded-lg px-6 py-12 gap-4" style={{ background: SystemColors.BACKGROUND_EMPTY_STATE }}>
            <div className="flex flex-col items-center gap-2">
                {
                    isFiltering ? (
                        <>
                            <MarketingGray style={{ height: 18, width: 18 }} />
                            <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>No campaigns match your filters</Body2>
                            {/* TODO: add a button to clear filters here */}
                        </>
                    ) : (
                        <>
                            <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>{primaryText}</Body2>
                            {buttonAction && buttonText && <PrimaryButton text={buttonText} onClick={buttonAction} />}
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default EmptyState;