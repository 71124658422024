import { Fragment, useContext, useEffect, useState } from "react";
import CloudinaryUploadWidget from "../../../../components/shareable/CloudinaryUploadWidget";
import { uploadAdVideo } from "../../../../helpers/Facebook";
import InfoSection from "../../../../components/InfoSection";
import InstagramPosts from "../../../../components/shareable/InstagramPosts";
import { InstagramPost } from "../../../../components/shareable/InstagramPosts/types";
import { getRefreshInstagramPost, showNotFoundIGPostWarningToast, isValidURL } from "utils";
import { ConversionsPlatforms, DataPlatform, getFromDraftDataSelectedPlatforms, getWebsiteLinkWithUsersConfigurationPreview } from "./utils";
import { AssetUploadTypeSelector, containsExplicitContent, loadInstagramPosts, UploadedAssetType, VideoUploader } from "../utils/fbCampaigns";
import { DebounceInput } from "react-debounce-input";
import { useStyles, BannerHeader, ConversionsBannerContainer, PlatformDetails, PlatformContainer, CheckedIcon, PlatformIconContainer } from "./styles";
import { Body2, Caption, Subtitle2 } from "components/shareable/Typography";
import { ReactComponent as BoostsIcon } from "assets/images/campaignDetails/Boost.svg";
import LinkButton from "components/shareable/LinkButton";
import { ReactComponent as ExternalLinkIcon } from "assets/images/external-link.svg";
import { Container } from "styles/shared";
import SwitchButton from "components/shareable/SwitchButton";
import { SystemColors } from "types/globalStyles";
import { CreativeAssetTypes } from "../constants";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { ConnectionsContext } from "../hooks/ConnectionsContext";
import { CampaignConnectionsDetails } from "components/connect/CampaignConnectionsDetails";
import { ConnectionsSetup } from "../Components/ConnectionsSection";
import { AdPreviewSection } from "../Components/AdPreviewSection";
import { LoadingLottie } from "components/Loader/LoadingLottie";

const siteIconsData = {
    deezer: require("../../../../assets/images/streaming/icons/deezer-icon.svg").default,
    spotify: require("../../../../assets/images/streaming/square-icons/spotify-sqr.svg").default,
    tidal: require("../../../../assets/images/streaming/small/tidal-bw.svg").default,
    youtube: require("../../../../assets/images/youtube.svg").default,
    apple_music: require("../../../../assets/images/apple_music.svg").default,
    amazonStore: require("../../../../assets/images/streaming/small/amazon-store.png").default,
    amazon_music: require("../../../../assets/images/streaming/small/amazon-bw.svg").default,
    instagram: require("../../../../assets/images/insta.svg").default,
    tiktok: require("../../../../assets/images/tiktok.svg").default,
    soundcloud: require("../../../../assets/images/soundcloud.svg").default,
    all: require("../../../../assets/images/symphony_website.svg").default,
    custom: require("../../../../assets/images/custom_link.svg").default,
}
const { UPLOAD_VIDEO, INSTAGRAM_POSTS } = CreativeAssetTypes

export default function CreativeTab(props: any) {
    const {
        creativeSelectorTab,
        setCreativeSelectorTab,
        setAddVisualSelected,
        previewedVideo,
        setPreviewedVideo,
        selectedRecord,
        setSelectedAssets,
        selectedAssets,
        setSpotifyAssets,
        selectedSpotifyAssets,
        setAppleAssets,
        selectedAppleAssets,
        setYoutubeAssets,
        selectedYoutubeAssets,
        captions,
        selectedInstagramPost,
        setSelectedInstagramPost,
        setSelectedPlatforms,
        selectedPlatforms,
        campaignDraftData,
        setLinks,
        links,
        handleOnChangePlatformsSwitch,
        platformsSwitch,
        enablePlatform,
        disablePlatform,
        setCaptionForService,
        setCaptionForAllSelectedServices,
        selectedCampaignType,
        enablePreviewMode,
        showPreview,
    } = props

    const { currentBrand: brand } = useContext(CurrentBrandContext)

    const {
        loggedInFbUser,
        instagramPage: selectedInstaPage,
        facebookAdAccount: selectedFBAdAccount,
        areConnectionsValid: adConnectionsAdded,
        connectionsConfirmed: adConnectionsConfirmed,
        preConnectionConfigurations,
        areTermsAccepted,
        conversionsEnabled: isConversionsEnabled,
        setConnectionsConfirmed,
    } = useContext(ConnectionsContext)

    const classes = useStyles()
    const [uploadQueue, setUploadQueue] = useState<any>(null)
    const [LoadingPosts, setLoadingPosts] = useState(false)
    const [instagramPosts, setInstagramPosts] = useState<InstagramPost[]>([])
    const { isError: showConversionsError } = brand?.conversionsStatus || {}

    useEffect(() => {
        if (campaignDraftData) {
            const platforms = getFromDraftDataSelectedPlatforms(campaignDraftData)
            setSelectedPlatforms(platforms)
        }
    }, [])

    const loadIGPosts = async () => {
        setLoadingPosts(true)
        if (selectedInstaPage) {
            const instagramPosts = await loadInstagramPosts({ instagramPage: selectedInstaPage, access_token: loggedInFbUser?.access_token })

            const hasInstagramPosts = Boolean(instagramPosts.length)
            const hasVideosUploaded = Boolean(selectedAssets?.length)

            if (hasInstagramPosts) {
                const mappedIgPosts = instagramPosts.map((post: InstagramPost) => {
                    const caption = post.caption || ''
                    var explicitContent = false
                    if (caption) {
                        explicitContent = containsExplicitContent(caption)
                    }

                    return {
                        ...post,
                        explicit_content: explicitContent
                    }
                })

                setInstagramPosts(mappedIgPosts)

                if (hasVideosUploaded) {
                    setCreativeSelectorTab(UPLOAD_VIDEO)
                    setAddVisualSelected(UPLOAD_VIDEO)
                } else {
                    setCreativeSelectorTab(INSTAGRAM_POSTS)
                    setAddVisualSelected(INSTAGRAM_POSTS)
                    // If there is not an selectedInstagramPost, default to selecting first post
                    const refreshSelectedInstaPost = getRefreshInstagramPost(selectedInstagramPost, instagramPosts);
                    setSelectedInstagramPost(refreshSelectedInstaPost);
                }
            } else {
                setCreativeSelectorTab(UPLOAD_VIDEO)
                setAddVisualSelected(UPLOAD_VIDEO)
                setInstagramPosts([])
            }

            const igPostFound = showNotFoundIGPostWarningToast(instagramPosts, selectedInstagramPost)
            if (!igPostFound) setSelectedInstagramPost(null)
        } else {
            setCreativeSelectorTab(UPLOAD_VIDEO)
            setInstagramPosts([])
        }
        setLoadingPosts(false)
    }

    useEffect(() => {
        // TODO 630: switch validation for the commented one once we fix the connections issues
        if ((selectedInstaPage && adConnectionsAdded) || (preConnectionConfigurations?.status && !instagramPosts.length)) {
            loadIGPosts()
        } else {
            setCreativeSelectorTab(UPLOAD_VIDEO)
            setInstagramPosts([])
            setSelectedInstagramPost(null)
        }
    }, [selectedInstaPage, adConnectionsAdded, loggedInFbUser])

    useEffect(() => {
        if (uploadQueue) {
            const { type, items } = uploadQueue
            if (type === 'all') {
                var existing = selectedAssets ? selectedAssets : []
                existing = existing.concat(items).slice(0, 2)
                setSelectedAssets(existing)
            } else if (type === 'spotify') {
                var existing = selectedSpotifyAssets ? selectedSpotifyAssets : []
                existing = existing.concat(items).slice(0, 2)
                setSpotifyAssets(existing)
            } else if (type === 'apple_music') {
                var existing = selectedAppleAssets ? selectedAppleAssets : []
                existing = existing.concat(items).slice(0, 2)
                setAppleAssets(existing)
            } else if (type === 'youtube') {
                var existing = selectedYoutubeAssets ? selectedYoutubeAssets : []
                existing = existing.concat(items).slice(0, 2)
                setYoutubeAssets(existing)
            }
            setUploadQueue(null)
        }
    }, [uploadQueue])

    function cancelUploadingAssets(key: string) {
        const currentPlatformsStatus = {
            ...selectedPlatforms
        }

        currentPlatformsStatus[key].uploadingAssets = false

        // update to selected ++ set the default caption for the service
        setSelectedPlatforms(currentPlatformsStatus)
    }

    const onClickCreativeSelectorTab = (evt: any) => {
        const tabClicked = evt.target.id;
        setCreativeSelectorTab(tabClicked)
        setAddVisualSelected(tabClicked)
    }

    async function uploadCreativeAssets(assets: Array<any>) {

        var uploadedAssets = assets.map((asset: any) => {
            return new Promise(async (resolve: any, reject: any) => {
                const { url } = asset
                try {
                    const uploadedVideo = await uploadAdVideo(url, selectedFBAdAccount?.id, loggedInFbUser?.access_token)
                    resolve({
                        ...asset,
                        fb_id: uploadedVideo.id,
                        type: 'video'
                    })
                } catch (e) {
                    alert("Error uploading video - " + asset.name + `\n${e}`)
                    console.error('e', e)
                    resolve(null)
                }
            })
        })

        var uploadedItems = await Promise.all(uploadedAssets)

        uploadedItems = uploadedItems.filter(o => o !== null)

        return uploadedItems
    }

    const handleOnClickWebsitePreview = () => {
        const websiteUrl = getWebsiteLinkWithUsersConfigurationPreview({
            brand,
            selectedRecord,
            selectedPlatforms,
            isConversionsEnabled,
        })
        window.open(websiteUrl, '_blank')
    }

    let uploadingAssets = selectedPlatforms['all'].uploadingAssets

    const [cloudinaryUploadQueue, setCloudinaryUploadQueue] = useState<any[]>([]);

    function addToCloudinaryUploadQueue(fileId: string) {
        setCloudinaryUploadQueue((previous) => previous.concat(fileId));
    }

    function removeFromCloudinaryUploadQueue(fileId: string) {
        const newQueue = [
            ...cloudinaryUploadQueue
        ]

        const indexOfId = newQueue.indexOf(fileId)
        if (indexOfId > -1) {
            newQueue.splice(indexOfId, 1)
        }
        setCloudinaryUploadQueue(newQueue);
    }

    const successfulCloudinaryUpload = (file: any) => {
        const {
            info
        } = file

        const {
            id: fileId
        } = info

        removeFromCloudinaryUploadQueue(fileId)
        try {
            addToFacebookUploadQueue(file)
        } catch (e: any) {
            const errors = [...uploadErrors]
            errors.push(e)
            setUploadErrors(errors)
        }
    }

    const [uploadErrors, setUploadErrors] = useState<Array<{
        name: string,
        message: string
    }>>([])

    const [facebookUploadQueue, setFacebookUploadQueue] = useState<Array<any>>(selectedAssets || [])
    const [queueToUpload, setQueueToUpload] = useState<Array<any>>([]);

    const addToFacebookUploadQueue = (file: any) => {

        const {
            info,
        } = file

        const {
            thumbnail_url,
            asset_id,
            height,
            width,
            secure_url,
            original_filename: name,
            format
        } = info

        var sizeRatio = width / height

        // error handling
        //  - must be minimum with of 500px
        if (width < 500) {
            const error = new Error("Video must be wider than 500px.")
            error.name = `${name}.${format}`
            throw error
        }

        var type = 'feed'

        // IG Story Sizing:
        /**
         * 16/9 to 4/5
         * 9/16
         */

        // IG Feed Sizing:
        /**
         * 16/9 to 9/16
         */
        if (sizeRatio <= 16 / 9 &&
            sizeRatio >= 4 / 5) {
            type = 'feed'
        } else if ((sizeRatio <= 16 / 9 &&
            sizeRatio >= 4 / 5) || sizeRatio < 9 / 16 || sizeRatio >= 996 / 2156) {
            type = 'story'
        } else {
            const error = new Error("Video isn't formatted for feed or story. Please make sure the video is sized in an aspect ratio of 4x5 or 16x9 for feed, or 9x16 for story.")
            error.name = `${name}.${format}`
            throw error
        }

        // upload the asset
        const formattedValue = {
            thumbnail: thumbnail_url,
            cloudinary_id: asset_id,
            height: height,
            width: width,
            name: name,
            url: secure_url,
            formatType: type,
            uploading: true,
            fb_id: null,
        }

        setQueueToUpload(previous => previous.concat(formattedValue))
        uploadToFb(formattedValue);
    }

    async function uploadToFb(file: any) {
        const {
            thumbnail,
            cloudinary_id,
            height,
            width,
            name,
            url,
            formatType,
        } = file

        let fbUploaded: any = await uploadCreativeAssets([
            {
                thumbnail,
                cloudinary_id,
                height,
                width,
                name,
                url,
                formatType
            }
        ])
        const fbUploadedValue = fbUploaded[0];
        setFacebookUploadQueue(previous => previous.concat(fbUploadedValue));
        setQueueToUpload((previous: any) => previous.filter((file: any) => file.cloudinary_id !== fbUploadedValue.cloudinary_id))
    }

    useEffect(() => {
        setSelectedAssets(facebookUploadQueue);
    }, [facebookUploadQueue])

    function removeAsset(cloudinary_id: string) {
        const updatedWithoutAsset = selectedAssets?.filter((item: UploadedAssetType) => {
            return item.cloudinary_id !== cloudinary_id
        }) || []
        setFacebookUploadQueue(updatedWithoutAsset);
    }

    const isPlaylistCampaign = selectedRecord.type === 'playlist'
    const showConnectionsSetup = !adConnectionsAdded || !adConnectionsConfirmed || showConversionsError || !areTermsAccepted

    if (showConnectionsSetup) {
        return <ConnectionsSetup showConversions selectedCampaignType={selectedCampaignType} />
    }

    return (
        <div className="flex gap-6">
            <div className="flex flex-col gap-4 w-full">
                <div className="rounded-lg bg-white">
                    <CampaignConnectionsDetails
                        onEditConnections={() => setConnectionsConfirmed(false)}
                    />
                </div>
                <div className="rounded-lg bg-white">
                    <InfoSection title="Add Visuals" description="Personalize your ads with the visuals and messaging you want to use for your ads." />
                    <AssetUploadTypeSelector
                        instagramPage={selectedInstaPage}
                        selected={creativeSelectorTab}
                        onChange={onClickCreativeSelectorTab}
                    />
                    {creativeSelectorTab === INSTAGRAM_POSTS ? (
                        LoadingPosts ? (
                            <div className="pt-6 pb-4">
                                <LoadingLottie black />
                            </div>
                        ) : (
                            <div className="border-b pb-6">
                                <InstagramPosts
                                    posts={instagramPosts}
                                    setSelectedInstagramPost={setSelectedInstagramPost}
                                    selectedInstagramPost={selectedInstagramPost}
                                    showCaption={true}
                                    allowPostPreviews={true}
                                />
                            </div>
                        )
                    ) : creativeSelectorTab === UPLOAD_VIDEO && (
                        <>
                            <div className="sy-card px-5 lg:px-10">
                                <VideoUploader
                                    maxAssets={3}
                                    uploadingAssets={uploadingAssets}
                                    selectedAssets={[...(selectedAssets || []), ...queueToUpload]}
                                    removeAsset={removeAsset}
                                    setPreviewedVideo={setPreviewedVideo}
                                    previewedVideo={previewedVideo}
                                />
                            </div>
                            {uploadErrors.length > 0 ? <div className="rounded-sm bg-yellow-500 text-white py-1 text-md py-3 px-3 text-center">
                                <p className="w-8/12 m-auto">There was an error uploading the following assets:</p>
                                {uploadErrors.map((o: {
                                    message: string,
                                    name: string
                                }) => {
                                    return (
                                        <p>{o.name}: {o.message}</p>
                                    )
                                })}
                                <button className="mt-3" onClick={() => {
                                    setUploadErrors([])
                                }}>Close</button>
                            </div> : null}
                        </>
                    )}
                    {isConversionsEnabled && creativeSelectorTab === UPLOAD_VIDEO &&
                        <div className="px-6 lg:px-10 py-5 border-b">
                            <p className="text-md font-medium mb-0 mt-6">Caption</p>
                            <p className="text-sm text-gray-500">Set the caption you want fans to see in your IG + FB ads.</p>
                            <textarea
                                value={captions['all']}
                                placeholder={`${selectedRecord.name} out now on all streaming services`}
                                className="rounded-md border w-full resize-none p-2 h-14 mt-2"
                                onChange={(e) => { setCaptionForAllSelectedServices(e.target.value) }}
                            />
                        </div>
                    }
                    <div className="px-6 lg:px-10 py-5 border-b">
                        <p className="text-lg font-bold">{isConversionsEnabled ? 'Which platforms do you want to send fans to?' : 'Platforms'}</p>
                        {!isPlaylistCampaign && (<p className="text-sm sm:text-sm text-gray-500 lg:text-base">
                            Select the streaming services you want to send fans to with your ads. {isConversionsEnabled && 'When using the Conversions API, the selected platforms will show up on the link-in-bio we send fans to.'}
                        </p>)}
                        {isConversionsEnabled &&
                            <Fragment>
                                <ConversionsBannerContainer>
                                    <BannerHeader>
                                        <BoostsIcon height={16} width={16} />
                                        <Caption>Symphony Conversions Enabled</Caption>
                                    </BannerHeader>
                                    {isPlaylistCampaign ? (<Body2>When using Symphony Conversions, we'll be able to better target fans who engage with your playlist.</Body2>) : (<Body2>When using Symphony Conversions, the selected platforms will show up on the link-in-bio we send fans to.</Body2>)}
                                </ConversionsBannerContainer>
                                <Container paddingTop="8px">
                                    <LinkButton
                                        text="Preview your ad link"
                                        rightIcon={<ExternalLinkIcon className={classes.externalLink} />}
                                        verticalAlign="flex-start"
                                        onClick={handleOnClickWebsitePreview}
                                    />
                                </Container>
                            </Fragment>
                        }
                    </div>
                    {!isPlaylistCampaign && isConversionsEnabled && (
                        <PlatformContainer>
                            <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>Show all streaming services on website</Body2>
                            <SwitchButton
                                checked={platformsSwitch.enabled}
                                onChange={handleOnChangePlatformsSwitch}
                            />
                        </PlatformContainer>
                    )}
                    {Object.values(selectedPlatforms).filter(
                        (platform) => {
                            const platformInfo = platform as DataPlatform
                            const shouldBeHidden = ['all', 'custom'].indexOf(platformInfo.key) > -1

                            if (shouldBeHidden) {
                                if (isPlaylistCampaign) return false
                                // hide the "landing page" or "custom link" options

                                return !isConversionsEnabled
                            }
                            const links = selectedRecord?.external_links
                            if (!selectedRecord || (selectedRecord && !links) || (!isConversionsEnabled && ConversionsPlatforms.includes(platformInfo.key))) {
                                return false
                            } else {
                                const platformFound = selectedRecord.external_links.find((record: { name: string }) => record.name === platformInfo.key)
                                return platformFound
                            }
                        })
                        .map((platform) => {
                            const platformInfo = platform as DataPlatform
                            const { name, key, selected, subtitle = null } = platformInfo

                            let linkSubtitle = subtitle


                            if (!linkSubtitle) {
                                if (selected) {
                                    if (isConversionsEnabled) {
                                        if (selectedRecord.type === 'playlist') {
                                            linkSubtitle = `We'll show ${name} as an option for fans to stream on playlist landing page.`
                                        } else {
                                            linkSubtitle = `We'll show ${name} as an option for fans to stream on your music landing page.`

                                        }
                                    } else {
                                        if (selectedRecord.spotify_release_type === 'album') {
                                            linkSubtitle = `We'll create an ad that sends people directly to your album on ${name}.`
                                        } else if (selectedRecord.spotify_release_type === 'single') {
                                            linkSubtitle = `We'll create an ad that sends people directly to your song on ${name}.`
                                        } else if (selectedRecord.type === 'playlist') {
                                            linkSubtitle = `We'll create an ad that sends people directly to your playlist on ${name}.`
                                        }
                                    }
                                } else {
                                    if (isConversionsEnabled) {
                                        linkSubtitle = `If you select this, we'll show ${name} as an option on your music landing page..`
                                    } else {
                                        linkSubtitle = `If you select this, we'll automatically create an ad that sends people directly to your ${selectedRecord.spotify_release_type === 'album' ? 'album' : 'song'} on ${name}.`
                                    }
                                }
                            }
                            return (
                                <PlatformContainer key={name} flexDirection="column">
                                    <PlatformDetails>
                                        <Container display="flex" alignItems="center">
                                            <PlatformIconContainer>
                                                <img className={classes.platformIcon} src={(siteIconsData as any)[key]} />
                                                {(selected && creativeSelectorTab === INSTAGRAM_POSTS) && (
                                                    <CheckedIcon src={require("../../../../assets/images/tick.svg").default} />
                                                )}
                                            </PlatformIconContainer>
                                            <div className="flex flex-col ml-4">
                                                <p className="text-md font-medium">
                                                    {key !== 'all' ? (
                                                        <Fragment>
                                                            {isConversionsEnabled ? selected ? "" : "Link to" : 'Direct to'} {name} {selected && "Enabled"}
                                                        </Fragment>
                                                    ) : (
                                                        <Container display="flex" flexDirection="column">
                                                            <Subtitle2>Symphony Website</Subtitle2>
                                                            <LinkButton
                                                                text="Show Preview"
                                                                verticalAlign="flex-start"
                                                                onClick={handleOnClickWebsitePreview}
                                                                fontSize="12px"
                                                            />
                                                        </Container>
                                                    )}
                                                </p>
                                                <p className="text-sm text-gray-500">{linkSubtitle}</p>
                                            </div>
                                        </Container>
                                        <SwitchButton
                                            checked={selected}
                                            onChange={(e) => {
                                                if (selected) {
                                                    disablePlatform(key)
                                                } else {
                                                    enablePlatform(key)
                                                }
                                            }}
                                        />
                                    </PlatformDetails>
                                    {selected && key === 'custom' ?
                                        <Container display="inline" width="100%">
                                            <p className="text-md font-medium mb-0 mt-6">Link</p>
                                            <p className="text-sm text-gray-500">Enter your custom link.</p>
                                            <div
                                                className="rounded-md border w-full resize-none mt-2 relative">
                                                <DebounceInput
                                                    className="w-full h-full h-12 p-3.5 rounded-md"
                                                    debounceTimeout={0}
                                                    placeholder={`e.g. linktree.com/xyz`}
                                                    value={links[key].value}
                                                    onChange={(e) => {
                                                        const isValid = isValidURL(links.custom.value)
                                                        setLinks({
                                                            ...links,
                                                            [key]: {
                                                                ...links[key],
                                                                valid: isValid,
                                                                checkingValidity: e.target.value ? true : false,
                                                                value: e.target.value
                                                            }
                                                        })
                                                    }
                                                    }
                                                />
                                            </div>
                                        </Container>
                                        : null}
                                    {!isConversionsEnabled && selected && creativeSelectorTab === UPLOAD_VIDEO &&
                                        <Container display="inline" width="100%">
                                            <p className="text-md font-medium mb-0 mt-6">Caption</p>
                                            <p className="text-sm text-gray-500">Enter the caption you want people to see alongside your ad.</p>
                                            <textarea
                                                placeholder={`${selectedRecord.name} out now on ${key === 'all' ? 'all streaming services' : name}`}
                                                className="rounded-md border w-full resize-none p-2 h-14 mt-2"
                                                value={captions[key]}
                                                onChange={(e) => setCaptionForService(key, e.target.value)}
                                            ></textarea>
                                        </Container>
                                    }
                                </PlatformContainer>
                            )
                        })}
                </div>
                <CloudinaryUploadWidget
                    onUploadAdded={(fileId: string) => {
                        addToCloudinaryUploadQueue(fileId)
                    }}
                    onUploadSuccess={successfulCloudinaryUpload}
                    onUploadAbortAll={() => {
                        setUploadQueue([])
                    }}
                    onUploadAbort={(fileId: string) => {
                        removeFromCloudinaryUploadQueue(fileId)
                    }}
                    uploadButtonId={"all_widget"}
                    onCancel={(info: any) => cancelUploadingAssets('all')}
                />
            </div>
            <AdPreviewSection
                showPreview={showPreview}
                creativeSelectorTab={creativeSelectorTab}
                selectedInstagramPost={selectedInstagramPost}
                captions={creativeSelectorTab === INSTAGRAM_POSTS ?
                    (selectedInstagramPost ? [{ type: 'all', caption: selectedInstagramPost.caption }] : [])
                    : Object.keys(captions)
                        .filter((key: string) => {
                            const captionValue: string = (captions as any)[key];
                            return captionValue
                        })
                        .map((key: string) => {
                            const captionValue: string = (captions as any)[key];
                            return {
                                type: key,
                                caption: captionValue
                            }
                        })
                }
                previewedVideo={previewedVideo}
                uploadedImages={selectedAssets}
                canShowAdPreview={adConnectionsAdded && adConnectionsConfirmed}
                enablePreviewMode={enablePreviewMode}
            />
        </div>
    )
}