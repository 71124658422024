import { Container } from "styles/shared";
import { Divider } from "@material-ui/core";
import PageHeaderWithSearch from "../PageHeaderWithSearch";

interface TeamTabSectionProps {
  className?: string;
  title: string;
  onSearch?: (searchTerm: string) => void;
  searchValue?: string;
  buttonText?: string;
  onButtonClick?: () => void;
  children: React.ReactNode;
  showDividers?: boolean;
  buttonLoading?: boolean;
}

export const TeamTabSection = ({
  className,
  title,
  onSearch,
  searchValue = "",
  buttonText,
  onButtonClick,
  children,
  showDividers = false,
  buttonLoading = false,
}: TeamTabSectionProps) => {
  return (
    <Container className={`teamTabSectionComponent ${className}`} display="flex" flexDirection="column" gap="8px">
      <PageHeaderWithSearch
        title={title}
        onSearch={onSearch ? (value: string) => onSearch?.(value) : undefined} // Added type and optional chaining
        searchValue={searchValue}
        buttonText={buttonText}
        onButtonClick={onButtonClick}
        loading={buttonLoading}
      />
      <Container display="flex" flexDirection="column">
        {showDividers && <Divider />}
        {children}
        {showDividers && <Divider />}
      </Container>
    </Container>
  );
};
