/* eslint-disable react-hooks/exhaustive-deps */
import {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react"
import { CurrentBrand, MarketingCampaignType, OrganizationData, UserData } from "types/global"
import { Tab, Tabs } from "@material-ui/core"
import { pageView } from "analytics"
import {
  StyledNavBar,
  TabsPanelsContainer,
  MainContainer,
  Card,
  StyledLabel,
  BannerContainer,
} from "./styles"
import { TabPanel } from "react-headless-tabs"
import isEqual from "lodash/isEqual"
import startCase from "lodash/startCase"
import lowerCase from "lodash/lowerCase"
import { SystemColors } from "types/globalStyles"
import { PageTabs } from "./utils"
import { isAdmin } from "helpers/admin"
import { useLocation } from "react-router-dom"
import OrganizationZeroState from "./ZeroState"
import OrganizationNeedsSetupState from "./SetupStatus"
import OrganizationProfiles from "./ReadyState"
import { OrganizationBilling } from "./BillingTab"
import { CurrentUserContext } from "Hooks/CurrentUserContext"
import MainConfetti from "components/shareable/Confetti"
import DialogPaper from "components/shareable/DialogPaper"
import { ReactComponent as TadaIcon } from "assets/images/tada.svg"
import HeaderModal from "components/connect/connectModals/Spotify/Components/HeaderModal";
import { ModalContainer } from "components/shareable/DialogPaper/style"
import PrimaryButton from "components/shareable/PrimaryButton"
import { TeamTab } from "./Components/TeamTab"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { isOrganizationAdministrator } from "utils"
import { useHistory } from "react-router-dom";
import { OrganizationMarketingTab } from "./Components/OrganizationMarketingTab"
import CreateCampaignModal from "components/shareable/CreateCampaignModal"
import { OutlinedButton, TextButton } from "styles/shared"
import { OrganizationSubscriptionStatusBanner } from "components/shareable/OrganizationFailedPayments"
import { useCurrentTeam } from "Hooks/CurrentTeamContext"
import QuickActionPanel, { POPULAR_CAMPAIGNS, QuickActionItem } from "components/shareable/ShortcutsBar"
import MusicSiteIcon from 'assets/images/websiteThemes/music-site.svg';
import { OrganizationWebsitesTab } from "./Components/OrganizationMarketingTab/websites"
import { OrganizationFansTab } from "./Components/OrganizationMarketingTab/fans"
import { Body2 } from "components/shareable/Typography"
import Intercom from "helpers/Intercom"

interface Props {
  loadedBrands: CurrentBrand[]
  user: UserData;
  reloadBrands: () => void;
}

const { BRANDS, MARKETING_CAMPAIGNS, BILLING, TEAM, WEBSITES, FANS } = PageTabs

const tabs = [
  BRANDS, TEAM, MARKETING_CAMPAIGNS, WEBSITES, FANS, BILLING,
].map((tab) => ({
  value: tab,
  name: startCase(lowerCase(tab)),
}))


enum OrganizationSetupStatus {
  NOT_STARTED = "NOT_STARTED",
  PAID_BUT_NEEDS_SETUP = "PAID_BUT_NEEDS_SETUP",
  SETUP = "SETUP"
}

// loadedBrands represents ALL of the brands a user has access to
// teamArtists represents brands that are on the team
const OrganizationPage: FunctionComponent<Props> = ({
  loadedBrands,
  user,
  reloadBrands,
}: Props) => {
  const history = useHistory()
  const { reloadUser, currentUser } = useContext(CurrentUserContext);
  const { organization } = useContext(CurrentUserContext);
  const { currentBrand, setCurrentBrand, reloadBrand } = useContext(CurrentBrandContext);
  const userIsAdmin = user ? isAdmin(user) : false
  const [materialTabValue, setMaterialTabValue] = useState<PageTabs>(
    PageTabs.BRANDS
  )

  const {
    remainingFreeTrial,
    isOrganizationInactive,
    isPaymentFailed,
    teamMembers,
    teamArtists,
    getTeamArtists
  } = useCurrentTeam()

  // uses the user.metadata.organization_subscription to determine the status of the organization
  // and display the relevant page:
  //  - not started (payment not set up)
  //  - paid but needs setup (user paid but needs to select artists)
  // - setup (user has selected artists)
  const determineOrganizationStatus = () => {
    const organizationSetupStatus = organization?.status

    if (!organization) {
      return OrganizationSetupStatus.NOT_STARTED
    } else {
      switch (organizationSetupStatus) {
        case "ready":
          return OrganizationSetupStatus.SETUP
        case "waiting_for_details":
          return OrganizationSetupStatus.PAID_BUT_NEEDS_SETUP
        default:
          return OrganizationSetupStatus.NOT_STARTED
      }
    }
  }

  const currentOrganization: OrganizationData | undefined | null = organization
  const currentOrganizationStatus = determineOrganizationStatus()
  const [organizationSetupStatus, setOrganizationSetupStatus] = useState<OrganizationSetupStatus>(currentOrganizationStatus || OrganizationSetupStatus.NOT_STARTED)

  // only show confetti if a user is on the screen, and just finished pro setup. 
  // dont show it any other times
  const [showConfetti, setShowConfetti] = useState(false)
  const [openCompletedModal, setOpenCompletedModal] = useState(false)
  const prevOrganizationSetupStatus = useRef<OrganizationSetupStatus | null>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabFromQuery = queryParams.get("tab") as PageTabs;

  const [campaignModalOpen, setCampaignModalOpen] = useState(false);
  const openCreateCampaignModal = () => {
    setCampaignModalOpen(true);
  }

  useEffect(() => {
    if (tabFromQuery && Object.values(PageTabs).includes(tabFromQuery)) {
      setMaterialTabValue(tabFromQuery);
    }
  }, [tabFromQuery]);

  useEffect(() => {
    pageView("Organization");
    if (currentOrganization) {
      if (!isOrganizationAdministrator(currentOrganization)) history.push("/dashboard");
    }
  }, []);

  useEffect(() => {
    setOrganizationSetupStatus(determineOrganizationStatus())
  }, [currentOrganization])

  useEffect(() => {
    if (showConfetti) {
      setOpenCompletedModal(true)
    }
  }, [showConfetti])

  useEffect(() => {
    // Capture the status change specifically from PAID_BUT_NEEDS_SETUP to SETUP
    if (prevOrganizationSetupStatus.current === OrganizationSetupStatus.PAID_BUT_NEEDS_SETUP && organizationSetupStatus === OrganizationSetupStatus.SETUP) {
      setShowConfetti(true);
    } else {
      setShowConfetti(false);
    }
    // Update the ref to the current status for the next render
    prevOrganizationSetupStatus.current = organizationSetupStatus;
  }, [organizationSetupStatus]);

  /**
   * Refreshes all data related to the organization status
   * This includes reloading the current brand, user data, and all brands in the organization
   * Used after making changes that affect organization setup status
   */
  const refreshStatus = async () => {
    await reloadBrand()
    await reloadUser()
    await reloadBrands()
  }

  const defaultQuickActionItems: QuickActionItem[] = [
    {
      key: "music",
      title: "Build a Music Site",
      icon: MusicSiteIcon,
      link: undefined,
      isWebsite: true
    },
    ...POPULAR_CAMPAIGNS.filter((campaignType: MarketingCampaignType) => !campaignType.underMaintenance).map((campaignType: MarketingCampaignType) => ({
      key: campaignType.link,
      title: campaignType.title,
      icon: campaignType.icon,
      link: undefined,
      isWebsite: false
    }))
  ];

  const handleQuickActionClick = (itemKey: string) => {
    const item = defaultQuickActionItems.find(i => i.key === itemKey);
    if (item?.isWebsite) {
      // Handle website creation
      return
    } else {
      // Navigate to campaign creation
      history.push(`/marketing/new/${itemKey}`);
    }
  };

  // Renders the UI based on organization setup status
  const renderPrimaryView = () => {
    switch (organizationSetupStatus) {
      case OrganizationSetupStatus.NOT_STARTED:
        return (
          <Card className="organizationZeroStateCardComponent" padding="40px 24px" borderRadius="8px" marginBottom="16px" width="650px">
            <OrganizationZeroState currentBrand={currentBrand!} />
          </Card>
        )
      case OrganizationSetupStatus.PAID_BUT_NEEDS_SETUP:
        return (
          <Card className="organizationNeedsSetupCardComponent" padding="40px 24px" borderRadius="8px" marginBottom="16px"
            width="750px">
            <OrganizationNeedsSetupState currentBrand={currentBrand!}
              existingBrands={loadedBrands}
              organization={currentOrganization!}
              onFinished={() => refreshStatus()} />
          </Card>
        )
      case OrganizationSetupStatus.SETUP:
        const proArtists = teamArtists.filter(brand => brand.isPro);
        let showBanner = isPaymentFailed || remainingFreeTrial > 0 || isOrganizationInactive

        return (
          <>
            {showBanner &&
              <BannerContainer>
                <OrganizationSubscriptionStatusBanner />
              </BannerContainer>
            }
            <QuickActionPanel
              currentBrand={currentBrand}
              askMaestroSource="Marketing - Team Plan"
              campaignCreationSource="team-plan-quick-actions"
              parentStyle={{
                width: '86%',
                maxWidth: '1200px'
              }}
              campaignItems={defaultQuickActionItems}
              allowBrandSelector
              loadedBrands={loadedBrands}
              handleQuickActionClick={handleQuickActionClick}
            />

            <Card
              className="organizationHeaderCardComponent"
              marginTop={showBanner ? "12px" : "16px"}
              padding="40px 24px"
              borderRadius="8px"
              maxWidth="1200px"
            >
              <StyledLabel className="organizationHeaderTitle" fontSize={28}>
                {currentOrganization?.name}
              </StyledLabel>
              <div className="organizationHeaderStats flex flex-row items-center mt-5">
                <Body2 className="totalProfilesLabel mr-2">
                  Total Profiles
                </Body2>
                <p className="totalArtistsCount mr-1">
                  {teamArtists?.length}
                </p>
                <Body2 className="activeProAccounts ml-5 mr-2">
                  Active Pro Accounts
                </Body2>
                <p className="proArtistsCount">
                  {proArtists.length}
                </p>
                <TextButton
                  className="ml-auto"
                  onClick={() => Intercom.showNewMessage()}>
                  Contact Support
                </TextButton>

              </div>

              <StyledNavBar className="organizationNavHeaderTabs">
                <Tabs
                  style={{ flex: '1' }}
                  scrollButtons="auto"
                  variant="scrollable"
                  value={materialTabValue}
                  onChange={(_event, newValue) => setMaterialTabValue(newValue)}
                  aria-label="Settings Options Selector"
                  TabIndicatorProps={{
                    style: { backgroundColor: SystemColors.ACCENT_COLOR },
                  }}
                >
                  {tabs.map(({ name, value }) => (
                    <Tab
                      key={value}
                      disableRipple
                      value={value}
                      label={
                        <span className="capitalize font-medium text-base px-4 py-2">
                          {name}
                        </span>
                      }
                    />
                  ))}
                </Tabs>
                {/* {
                    isEqual(materialTabValue, MARKETING_CAMPAIGNS) && (
                      <CreateCampaignButtonContainer>
                        <GeneralPurpleButton
                          fullWidthOnMobile={true}
                          onClick={openCreateCampaignModal}>
                          Create campaign
                        </GeneralPurpleButton>
                      </CreateCampaignButtonContainer>
                    )
                  } */}
              </StyledNavBar>
              <TabsPanelsContainer className="organizationTabsPanelsContainer">
                <TabPanel hidden={!isEqual(materialTabValue, BRANDS)}>
                  <OrganizationProfiles
                    setCurrentBrand={setCurrentBrand}
                    reloadOrganization={() => refreshStatus()}
                    organization={currentOrganization!}
                    currentBrand={currentBrand!}
                    existingBrands={loadedBrands}
                    reloadBrands={reloadBrands}
                    user={user}
                  />
                  {showConfetti &&
                    <MainConfetti
                      numberOfPieces={600}
                    />
                  }
                  <DialogPaper open={openCompletedModal}>
                    <ModalContainer>
                      <HeaderModal
                        closeModal={() => setOpenCompletedModal(false)}
                        title="Welcome to Symphony for Teams!"
                        subtitle="We've got a lot of exciting new features on the way for the Team plan. If you have any ideas or feedback, reach out to us - we'd love to hear from you."
                        HeaderIcon={<TadaIcon />}
                      />
                      <PrimaryButton
                        className="letsGoPrimaryButton"
                        onClick={() => {
                          setOpenCompletedModal(false)
                        }}
                        text="Let's Go"
                      />
                    </ModalContainer>
                  </DialogPaper>
                </TabPanel>
                <TabPanel hidden={!isEqual(materialTabValue, BILLING)}>
                  <OrganizationBilling
                    teamMembers={teamMembers}
                    teamArtists={teamArtists}
                    isAdmin={userIsAdmin}
                    organizationBrands={loadedBrands}
                    organization={currentOrganization!}
                  />
                </TabPanel>
                <TabPanel hidden={!isEqual(materialTabValue, TEAM)}>
                  <TeamTab />
                </TabPanel>
                <TabPanel hidden={!isEqual(materialTabValue, MARKETING_CAMPAIGNS)}>
                  <OrganizationMarketingTab organizationBrands={loadedBrands} />
                </TabPanel>
                <TabPanel hidden={!isEqual(materialTabValue, WEBSITES)}>
                  <OrganizationWebsitesTab organizationBrands={loadedBrands} />
                </TabPanel>
                <TabPanel hidden={!isEqual(materialTabValue, FANS)}>
                  <OrganizationFansTab />
                </TabPanel>
                <CreateCampaignModal
                  isOpen={campaignModalOpen}
                  onClose={() => setCampaignModalOpen(false)}
                  existingBrands={loadedBrands}
                  setCurrentBrand={setCurrentBrand}
                  currentBrand={currentBrand}
                />
              </TabsPanelsContainer>
            </Card>
          </>
        )
    }
  }

  return (
    <MainContainer className="organizationPageComponent">
      {renderPrimaryView()}
    </MainContainer>
  )
}

export default OrganizationPage
