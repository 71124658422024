import { useEffect, useState, useContext } from 'react';
import { LoadingIndicator } from 'components/Loader';
import { SHARED_WEBSITE_STYLES } from '../../styles';
import { checkIfIsProTier } from 'utils';
import { ScrollPosition, TABS, TABS_VIEW_ONLY, WEBSITE_TABS, WEBSITE_TABS_VIEW_ONLY } from './utils';
import WebsitePreviewV2 from '../WebsitePreview/WebsitePreviewV2';
import { useTabs } from 'react-headless-tabs';
import WebsiteDashboardv2 from '../WebsiteDashboardv2';
import { useStyles } from './styles';
import { WEBSITE_TYPES, WebsiteType } from '../../types';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import { MusicSiteSetupSection } from './components/SetupSection/MusicSiteSetupSection';
import { DataCollectorSetupSection } from './components/SetupSection/DataCollectorSetupSection';
import { TourSetupSection } from './components/SetupSection/TourSetupSection';
import { WebsiteContext } from './hooks/WebsiteContext';
import { StyleSection } from './components/StyleSection';
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints';
import { ArtistPermissions, BrandContent, RecordSelected } from 'types/global';
import { ConnectSection } from './components/ConnectSection';
import SymphonyTabs from 'components/shareable/SymphonyTabs';
import { DATE_OPTIONS } from 'pages/post-auth/DashboardModule/utils';
import dayjs from 'dayjs';
import getWebsiteData from '../../controllers/getWebsiteData';
import UpgradeToProModal from 'components/shareable/UpgradeToProModal';
import { pageView } from 'analytics';
import { usePalette } from 'react-palette';
const { VIEW_ONLY } = ArtistPermissions

interface WebsiteProps {
    brandContent: any;
    websiteType: WebsiteType;
    reloadBrands: () => void;
}

const { DATA_COLLECTOR, RECORD_STREAMS, TOUR } = WEBSITE_TYPES
const { ANALYTICS, CONNECT, SETUP, STYLE } = WEBSITE_TABS;

const SetupSection = (props: { websiteType: WebsiteType }) => {
    const { websiteType } = props;
    switch (websiteType) {
        case RECORD_STREAMS:
            return <MusicSiteSetupSection />
        case DATA_COLLECTOR:
            return <DataCollectorSetupSection />
        case TOUR:
            return <TourSetupSection />
        default:
            return null
    }
}

const AnalyticsSection = (props: { recordSelected?: RecordSelected, brandContent: BrandContent, websiteType: WebsiteType }) => {
    const { recordSelected, brandContent, websiteType } = props;
    const { currentBrand } = useContext(CurrentBrandContext);
    const { metrics, setMetrics } = useContext(WebsiteContext);
    const isProUser = checkIfIsProTier(currentBrand);
    const classes = useStyles();

    async function getMainData(currentTimeRange: DATE_OPTIONS = 'last-30-days', customStartDate?: string, customEndDate?: string) {
        try {
            let startDate = null
            let endDate = null
            switch (currentTimeRange) {
                case 'last-30-days':
                    endDate = dayjs().format('YYYY-MM-DD')
                    startDate = dayjs().subtract(30, 'days').startOf('day').format('YYYY-MM-DD')
                    break;
                case 'last-7-days':
                    endDate = dayjs().format('YYYY-MM-DD')
                    startDate = dayjs().subtract(7, 'days').startOf('day').format('YYYY-MM-DD')
                    break;
                case 'last-3-months':
                    endDate = dayjs().format('YYYY-MM-DD')
                    startDate = dayjs().subtract(3, 'months').startOf('day').format('YYYY-MM-DD')
                    break;
                case 'all-time':
                    endDate = dayjs().format('YYYY-MM-DD')
                    startDate = null // This will fetch data from the beginning
                    break;
                case 'custom':
                    if (customStartDate && customEndDate) {
                        startDate = customStartDate
                        endDate = customEndDate
                    } else {
                        throw new Error('Custom date range requires both start and end dates')
                    }
                    break;
                default:
                    break;
            }

            const websiteData = await getWebsiteData(currentBrand?.slug || '', brandContent.slug, startDate, endDate)
            setMetrics(websiteData)
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        const type = websiteType === RECORD_STREAMS ? 'music'
            : websiteType === DATA_COLLECTOR ? 'data collector'
                : 'pre-save';
        pageView('Website Specific Analytics', { type })
    }, [])

    useEffect(() => {
        if (!metrics) {
            getMainData()
        }
    }, [metrics]);

    return (
        <div className={classes.customizeWebsiteV2Variable}>
            <WebsiteDashboardv2
                id={brandContent.id}
                slug={brandContent.slug}
                websiteType={websiteType}
                isProUser={isProUser}
                handleRefreshData={getMainData}
            />
        </div>
    )
}

const SelectedTabPanel = (props: {
    websiteType: WebsiteType,
    brandContent: BrandContent,
    selectedTab?: WEBSITE_TABS | null,
    recordSelected?: RecordSelected,
}) => {
    const { websiteType, selectedTab, recordSelected, brandContent } = props;
    switch (selectedTab) {
        case SETUP:
            return <SetupSection websiteType={websiteType} />
        case STYLE:
            return <StyleSection websiteType={websiteType} />
        case ANALYTICS:
            return <AnalyticsSection
                websiteType={websiteType}
                recordSelected={recordSelected}
                brandContent={brandContent}
            />
        case CONNECT:
            return <ConnectSection />
        default:
            return <></>
    }
};

export function WebsiteEditorV2({
    brandContent,
    websiteType,
    reloadBrands,
}: WebsiteProps) {
    const { mobileView } = useMediaBreakpoints();
    const { currentBrand } = useContext(CurrentBrandContext)
    const classes = useStyles();
    const sharedClasses = SHARED_WEBSITE_STYLES();

    const previewStateScrollPositionInitObj = {
        customize: 0,
        preview: 0,
    };

    const { published } = brandContent?.content_metadata || {};
    const { permissionLevel } = currentBrand || {};

    const tabs = Object.values(permissionLevel === VIEW_ONLY ? WEBSITE_TABS_VIEW_ONLY : WEBSITE_TABS);
    let defaultTab = published ? ANALYTICS : SETUP;

    const [selectedTab, setSelectedTab] = useTabs(tabs, defaultTab);

    const {
        youtubeVideoLink, setYTVideoLink,
        embedYt,
        mainColor = '',
        setMainColor,
        recordSelected,
        secondaryColor = '',
        setSecondaryColor,
        youtubeID, setYoutubeEmbedId,
        customThumbnailUrl,
    } = useContext(WebsiteContext)

    const [loadingRecordDetails, setLoadingRecordDetails] = useState<any>(false);
    const [previewState, setPreviewState] = useState(true);
    const [previewStateScrollPosition, setPreviewStateScrollPosition] = useState<ScrollPosition>(previewStateScrollPositionInitObj);
    const [validMainColor, setValidMainColor] = useState(true);
    const [validSecondaryColor, setValidSecondaryColor] = useState(true);
    const [openProModal, setOpenProModal] = useState(false);

    const imageUrl = recordSelected?.thumbnail_url || currentBrand?.image || '';
    let paletteImageUrl = currentBrand?.image || '';
    if(websiteType === RECORD_STREAMS) {
        paletteImageUrl = recordSelected?.thumbnail_url || '';
    }

    const { data: paletteData } = usePalette(paletteImageUrl);

    useEffect(() => {
        if (customThumbnailUrl || imageUrl) {
            const paletteColors = Object.values(paletteData);

            const newMainColor = mainColor || paletteColors[0] || '';
            const newSecondaryColor = secondaryColor || paletteColors[1] || '';

            if (newMainColor !== mainColor) {
                setMainColor(newMainColor);
            }
            if (newSecondaryColor !== secondaryColor) {
                setSecondaryColor(newSecondaryColor);
            }
        }
    }, [paletteData, customThumbnailUrl, imageUrl, mainColor, secondaryColor]);

    useEffect(() => {
        if (previewState) {
            setPreviewStateScrollPosition((prevState) => ({
                ...prevState,
                customize: window.scrollY,
            }));
        } else {
            setPreviewStateScrollPosition((prevState) => ({
                ...prevState,
                preview: window.scrollY,
            }));
        }
    }, [previewState]);

    useEffect(() => {
        if (previewState) {
            window.scrollTo(0, 0);
        } else {
            window.scrollTo(0, previewStateScrollPosition.customize);
        }
    }, [previewStateScrollPosition]);

    useEffect(() => {
        if (youtubeVideoLink) {
            const validId = youtube_parser(youtubeVideoLink);
            if (validId) {
                setYoutubeEmbedId(validId);
            } else {
                setYoutubeEmbedId('');
            }
        } else {
            setYoutubeEmbedId('');
        }
    }, [youtubeVideoLink]);

    useEffect(() => {
        if (embedYt && !youtubeVideoLink && !youtubeID) {
            // default to the one linekd to the song
            const recordLinks = recordSelected?.external_links;
            if (recordLinks) {
                const yt = recordLinks.find((o: { name: string; url: string }) => {
                    return o.name === 'youtube';
                });
                if (yt) {
                    const defaultYouTubeId = youtube_parser(yt.url);
                    if (defaultYouTubeId) {
                        setYTVideoLink(yt.url);
                    }
                }
            }
        }
    }, [embedYt]);

    useEffect(() => {
        if (!/^#[0-9A-F]{6}$/i.test(mainColor)) {
            setValidMainColor(false);
        } else {
            setValidMainColor(true);
        }

        if (!/^#[0-9A-F]{6}$/i.test(secondaryColor)) {
            setValidSecondaryColor(false);
        } else {
            setValidSecondaryColor(true);
        }
    }, [mainColor, secondaryColor]);

    function youtube_parser(url: string) {
        var regExp =
            /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return match && match[7].length === 11 ? match[7] : false;
    }

    const TabPanelTabs = () => {
        const { currentBrand } = useContext(CurrentBrandContext)

        return (
            <div className={classes.tabSelectorContainer}>
                <SymphonyTabs
                    data={permissionLevel === VIEW_ONLY ? TABS_VIEW_ONLY : TABS}
                    onChange={(value) => setSelectedTab(value as WEBSITE_TABS)}
                    selectedValue={selectedTab as WEBSITE_TABS}
                    hideTabs={!published ? [3] : undefined}
                    tabContentStyle={{ paddingTop: 0, paddingBottom: 0 }}
                    onProTabClick={() => setOpenProModal(true)}
                />
                <UpgradeToProModal
                    {...{ brandSlug: currentBrand?.slug }}
                    open={openProModal}
                    onClose={() => setOpenProModal(false)}
                    reloadBrands={reloadBrands}
                />
            </div>
        );
    };

    const LoadingState = () => {
        return (
            <div className='loadingRecordDetails flex-col flex items-center m-8 '>
                <LoadingIndicator height='80px' color='black' />
                <p className='mt-1'>Loading details for "{recordSelected?.name}"...</p>
            </div>
        )
    }

    // render() NOTE: this is the main tabbed interface
    return (
        <div className={classes.customizeWebsiteV2Component}>
            <nav className='customizeWebsiteV2NavTabsContainer items-start flex border-b border-gray-300 hidden lg:flex-col lg:flex justify-between items-center'>
                <div className={classes.mainContainer}>
                    <div className={sharedClasses.editorPane}>
                        <TabPanelTabs />
                        {websiteType === RECORD_STREAMS && (!recordSelected?.full_info || loadingRecordDetails) ?
                            <LoadingState />
                            :
                            <SelectedTabPanel {...{ brandContent, websiteType, selectedTab, recordSelected }} />
                        }
                    </div>
                    {!mobileView && (
                        <div className={classes.websitePreviewContainer}>
                            <WebsitePreviewV2 websiteType={websiteType} />
                        </div>
                    )}
                </div>
            </nav>
        </div>
    );
}
