import { Chip, makeStyles } from "@material-ui/core";
import { FaceOutlined } from "@material-ui/icons";
import clsx from "clsx";

const useStyles = makeStyles({
    chip: {
        backgroundColor: "#F5EBFF",
        color: "#8800FF",
        display: 'flex',
        width: 'auto',
        padding: '10px',
        "&:hover": {
            backgroundColor: "#C7B3FF"
        },

        "& .MuiChip-label": {
            whiteSpace: 'nowrap',
            paddingLeft: '12px',
            paddingRight: '12px',
            textOverflow: 'unset',
            overflow: 'visible'
        }

    },
    stackedChip: {
        padding: '5px 10px',
        alignItems: 'center',
        justifyContent: 'center',
        width: '120px',
        height: 'auto',
        flexDirection: 'column',
        textAlign: 'center',
        marginLeft: '0px',
        marginRight: '0px',

        "& .MuiChip-label": {
            whiteSpace: 'wrap',
            paddingLeft: '0px',
            paddingRight: '0px',
        }
    },
    label: {

    }
});

export default function ClickableChip(props: {
    campaignName: string,
    campaignImage?: string;
    campaignType?: string | undefined;
    onClick?: () => void;
    stacked?: boolean;
}) {

    const {
        campaignName,
        campaignImage,
        onClick,
        stacked = false
    } = props

    const classes = useStyles();

    return (
        <Chip
            onClick={onClick}
            icon={campaignImage ? (
                <img
                    className={clsx("w-6 h-6 flex-shrink-0", stacked ? "ml-0" : "ml-4")}
                    src={campaignImage} />
            ) : undefined}
            label={campaignName}
            clickable
            color="primary"
            className={clsx(classes.chip, stacked && classes.stackedChip)}
        />
    )
}
