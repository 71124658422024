import PrimaryButton from "components/shareable/PrimaryButton"
import { saveBrandWebsite, saveWebsiteChanges } from "services/symphonyApi/websiteService"
import { TopBarContentContainer } from "../../styles"
import { useContext, useState } from "react"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { WebsiteContext } from "../CustomizeWebsite/hooks/WebsiteContext"
import { formatDataToPublish } from "../PublishSiteModal/utils"
import { FontThemeStyleType, WebsiteLink, fontThemeStyles } from "../CustomizeWebsite/utils"
import { checkIfIsProTier, isBrandAdministrator, showToast } from "utils"
import { dataCollectorThemeStyles, musicThemeStyles } from "../CustomizeWebsite/utils"
import { SystemColors } from "types/globalStyles"
import { WEBSITE_TYPES } from "../../types"
import { ArtistPermissions } from "types/global"

interface Props {
  brandContent: any,
  published: boolean,
  mobileView: boolean,
  handleOpenPublishModal: () => void,
  renderUrlComponent: () => JSX.Element | undefined,
  websiteType?: string,
}

export const RenderRightSideTopBarContent = ({
  brandContent,
  published,
  mobileView,
  handleOpenPublishModal,
  renderUrlComponent,
}: Props) => {
  const { currentBrand } = useContext(CurrentBrandContext)
  const [loading, setLoading] = useState(false)
  const {
    bgType,
    bgValue,
    customLinkOptions,
    customLinksAdded,
    embedYt,
    enableSocialIcons,
    fields,
    fontThemeStyle,
    isNewLink,
    isProject,
    mainColor,
    socialMediaIcons,
    recordSelected,
    removeSymphonyBrand,
    secondaryColor,
    selectedPreviewTrack,
    showRecordSelector,
    submitButtonText,
    showSecondaryLine,
    streamingLinks,
    styleType,
    subtitle,
    themeStyle,
    fullVideoUrl,
    titleText,
    youtubeID,
    youtubeVideoLink,
    customThumbnailUrl,
    customThumbnailViewStyle,
    addAffiliateLinkToLogo,
    tourDates,
    hidePastTourDates,
    titleAlignment,
  } = useContext(WebsiteContext);

  if (!currentBrand || !brandContent) return <></>

  const hasAdminAccess = isBrandAdministrator(currentBrand!)
  const isProUser = checkIfIsProTier(currentBrand)
  const themeStylesOptions = { ...musicThemeStyles, ...dataCollectorThemeStyles }
  type themeStyleType = keyof typeof themeStylesOptions
  const disableButton = themeStylesOptions[themeStyle as themeStyleType]?.isProOnly && !isProUser

  const handleSaveChanges = async () => {
    try {
      const contextData = {
        bgType,
        bgValue,
        customLinkOptions,
        customLinksAdded,
        embedYt,
        enableSocialIcons,
        fields,
        fontThemeStyle,
        isNewLink,
        isProject,
        mainColor,
        socialMediaIcons,
        recordSelected,
        removeSymphonyBrand,
        secondaryColor,
        selectedPreviewTrack,
        showRecordSelector,
        submitButtonText,
        showSecondaryLine,
        streamingLinks,
        styleType,
        subtitle,
        themeStyle,
        fullVideoUrl,
        titleText,
        youtubeID,
        youtubeVideoLink,
        previewUrl: customThumbnailUrl || currentBrand?.image,

        tourDates,
        hidePastTourDates,
        customThumbnailViewStyle: customThumbnailViewStyle || 'cover',
        titleAlignment,
      }

      const dataFormatted = formatDataToPublish(contextData, brandContent)

      let socialIcons = currentBrand?.website?.socialIcons || []
      if (enableSocialIcons && socialMediaIcons) {
        socialIcons = Object.entries(socialMediaIcons).map(([key, value]: [string, WebsiteLink]) => value as WebsiteLink)
      }

      const brandDataToUpdate = {
        buttonConfig: styleType,
        enableSocialIcons,
        removeSymphonyBrand,
        socialIcons,
        addAffiliateLinkToLogo
      }
      setLoading(true)
      await saveWebsiteChanges(currentBrand?.slug || '', dataFormatted)
      await saveBrandWebsite(currentBrand?.slug || '', brandDataToUpdate)
      setLoading(false)

      showToast({
        message: "Successfully saved changes to your website!",
        error: false,
        mobile: mobileView,
      })
    } catch (e) {
      showToast({
        message: "We couldn't save the changes to your website!",
        error: true,
        mobile: mobileView,
      })
      console.error("Error saving site's changes", e)
    }
  }


  let fontAttributes
  let disableByFields = false
  if (fontThemeStyle) fontAttributes = fontThemeStyles[fontThemeStyle as FontThemeStyleType]
  if (brandContent.type === WEBSITE_TYPES.DATA_COLLECTOR) disableByFields = !fields?.length
  const disableByFont = !isProUser && fontAttributes?.isProOnly;
  const disableByStreamingLinks = streamingLinks && Object.keys(streamingLinks).length === 0 && !youtubeID && !embedYt && !customLinksAdded.length;
  const isDisabled = disableByStreamingLinks || disableByFont || disableByFields

  return (
    <TopBarContentContainer published={published}>
      {published && !mobileView && renderUrlComponent()}
      {hasAdminAccess &&
        <PrimaryButton
          className="saveChangesWebsiteButton"
          loading={loading}
          disabled={isDisabled || disableButton}
          text={published ? "Save changes" : "Publish"}
          disabledColor={SystemColors.SECONDARY_DISABLE_CONTAINER}
          onClick={() => {
            published ? handleSaveChanges() : handleOpenPublishModal()
          }}
        />
      }
    </TopBarContentContainer>
  )
}
