import React, { useContext, useEffect } from "react";
import { FunctionComponent } from "react";
import useStyles from "pages/post-auth/MarketingPage/PreSave/styles";
import RecordPreview from "components/shareable/RecordPreview";
import { BackgroundTypes, TitleAlignments, Website } from "types/global";
import InfoSection from "components/InfoSection";
import { PreSaveDataContext } from "pages/post-auth/MarketingPage/Data/PreSaveProvider";
import isEqual from "lodash/isEqual";
import moment from "moment";
import {
  getRecordPreviewProps,
  getRecordPreviewContainerStyles,
} from "../../utils";
import { STEPS } from "pages/post-auth/MarketingPage/PreSave";
import upperFirst from "lodash/upperFirst";
import SuccessInfoLabel from "components/shareable/SuccessInfoLabel";
import { scrollIntoView } from "helpers/StyleUtils";

interface MusicPlatformsProps {
  platforms: { url?: string; name: string; show: boolean }[];
}

interface Props {
  website: Website;
  brandArtwork: string | null;
  contentName: string | null;
  selectedTab: STEPS;
}

const MusicPlatforms: FunctionComponent<MusicPlatformsProps> = ({
  platforms,
}: MusicPlatformsProps) => {
  return (
    <React.Fragment>
      {platforms.map((p, idx) => {
        const { name, show } = p;
        const isSpotify = isEqual(name, "spotify") && show;
        const isDeezer = isEqual(name, "deezer") && show;
        const isAppleMusic = isEqual(name, "apple_music") && show;
        const isAudiomack = isEqual(name, "audiomack") && show;
        const isSoundcloud = isEqual(name, "soundcloud") && show;

        if (isSpotify) return <div key={`${name}-${idx}`}>Spotify</div>;
        if (isDeezer) return <div key={`${name}-${idx}`}>Deezer</div>;
        if (isAppleMusic) return <div key={`${name}-${idx}`}>Apple Music</div>;
        if (isAudiomack) return <div key={`${name}-${idx}`}>Audiomack</div>;
        if (isSoundcloud) return <div key={`${name}-${idx}`}>Soundcloud</div>;
        return <React.Fragment key={`fragment-${idx}`} />;
      })}
    </React.Fragment>
  );
};

//TODO: Uncomment when adding url again
// const formatValueAsUrl = (value?: string) =>
//   value?.split(" ").join("-").toLocaleLowerCase();

const ReviewTab: FunctionComponent<Props> = ({
  website,
  brandArtwork,
  selectedTab,
}: Props) => {
  //TODO: Uncomment when adding url again
  // const content = contentName || undefined;
  const { details, customize } =
    useContext(PreSaveDataContext);
  const {
    releaseDate,
    musicPlatforms,
    timezone,
    boosts: { collectEmails, boostFollows, autoPresaves }
  } = details;
  const abbrev = timezone.abbrev ? `[${timezone.abbrev}]` : "";
  const {
    releaseTitle,
    artwork: artworkUrl,
    subtitle
  } = customize;
  const classes = useStyles();
  const boosts = [collectEmails, boostFollows, autoPresaves];
  const showBoosts = boosts.filter((b) => b).length > 0;
  // const formattedContent = formatValueAsUrl(content);
  // const formattedReleaseTitle = formatValueAsUrl(releaseTitle);
  // const link = `https://symphony.to/${formattedContent}/${formattedReleaseTitle}`;

  const recordPreviewProps = getRecordPreviewProps({
    brandArtwork,
    releaseTitle,
    subtitle,
    titleAlignment: TitleAlignments.CENTER,
    positionsOptions: musicPlatforms.filter((p) => p.show),
    customize,
    website,
  });
  const { style: containerStyle, className: containerClassName } =
    getRecordPreviewContainerStyles({
      classes,
      backgroundColor: recordPreviewProps.backgroundColor,
      backgroundType: recordPreviewProps.backgroundType,
    });

  useEffect(() => {
    scrollIntoView();
  }, [selectedTab]);

  return (
    <div className="flex">
      <div className={classes.dataColumn}>
        <InfoSection
          title="Let's launch your pre-save"
          description="Confirm all the details look right before we go-live."
        />
        <InfoSection title="" description="">
          <div className="flex flex-row items-center gap-x-2">
            <div className="number-blk added">01</div>
            <div>Pre-save Details</div>
          </div>
        </InfoSection>
        <InfoSection
          title={
            <div className="flex flex-row items-center gap-x-4">
              <img
                className={classes.artworkPreview}
                alt="artwork"
                src={artworkUrl || ""}
              />
              <span>{upperFirst(releaseTitle)}</span>
            </div>
          }
          description=""
        />
        <InfoSection
          className="py-4 px-5 w-full border-b lg:px-10"
          title=""
          descriptionClassName=""
          description=""
        >
          <div className="flex justify-between flex-grow items-center">
            <div className="font-semibold text-lg text-black">Release Date</div>
            <div>
              {moment(releaseDate).format(`MMMM D, YYYY h:mm a ${abbrev}`)}
            </div>
          </div>
        </InfoSection>
        <InfoSection
          className="py-4 px-5 w-full border-b lg:px-10"
          descriptionClassName=""
          title=""
          description=""
        >
          <div className="flex justify-between flex-grow items-center">
            <div className="font-semibold text-lg text-black">
              Enabled Platforms
            </div>
            <div>
              <MusicPlatforms platforms={musicPlatforms} />
            </div>
          </div>
        </InfoSection>
        <InfoSection title="" description="">
          <div className="flex flex-row items-center gap-x-2">
            <div className="number-blk added">02</div>
            <div>Configurations</div>
          </div>
        </InfoSection>
        <InfoSection
          className="py-4 px-5 w-full border-b lg:px-10"
          title=""
          descriptionClassName=""
          description=""
        >
          <div className="flex justify-between items-center">
            <div className="font-semibold text-lg text-black">
              Enabled Boosts
            </div>
            {showBoosts && (
              <div>
                {collectEmails && <div>Collect Fan Data</div>}
                {boostFollows && <div>Boost Follows</div>}
                {autoPresaves && <div>Forever Saves</div>}
              </div>
            )}
            {!showBoosts && <div>None</div>}
          </div>
        </InfoSection>
        <InfoSection
          className={classes.reviewTabInfoBanner}
          title=""
          description=""
        >
          <div>
            {/* 
            //TODO: Uncomment when adding url again
            <div>
              <p className="font-semibold text-lg text-black">
                Your pre-save will be live at:
              </p>
            </div>
            <div className="mt-2">
              <p className="font-semibold text-xl text-black">{link}</p>
            </div> */}
            <div className="mt-2">
              <SuccessInfoLabel label="Once your song releases, the pre-save will automatically convert into a landing page with all of your song's streaming links." />
            </div>
          </div>
        </InfoSection>
      </div>
      <div className={classes.previewColumn}>
        <div className={containerClassName} style={containerStyle}>
          {recordPreviewProps.backgroundType === BackgroundTypes.ARTWORK &&
            <img
              alt="artwork_as_background"
              src={recordPreviewProps.contentDetails.artworkUrl}
              className="filter blur-lg absolute w-auto object-cover h-full"
            />
          }
          <RecordPreview
            {...recordPreviewProps}
            className={classes.principalPreviewContainer}
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewTab;
