import { UserData } from "types/global";

export function isAdmin(user: UserData) {
    return [
      // Symphony
      'google-oauth2|100861418396090810955', // drew@symphony
      'google-oauth2|109928451047378948621', // megh@symphony.to
      'auth0|63719605694ed42ee5884d4e', // chuka@symphony
      'google-oauth2|114607043071641915174', // sharlea@symphony
      'google-oauth2|100218368742720794406', // bo@symphony
      'google-oauth2|100634977223041076880', // nick@symphony.to
      'google-oauth2|102770451781496773919', // adriel@symphony


      // Integral
      'auth0|63adf1dde2c4faec70d521b2', // megh@integral on dev
      'auth0|6181f2fcefdbb1006a590a3d', // megh@integral,
      'auth0|61857e1598d36700706eac1f', // chuka@integral
      'auth0|62fab883d964a3c9abce8e15', // v@integral
      'google-oauth2|110844876248911915612', // v@integral on goolge
      'auth0|6185c588af55ae0070d8830f', // drew@integral
      'google-oauth2|109503874546068079709', // drew@integral on google
      'google-oauth2|116894796085309426143', // bo@integral
      'auth0|6185802ee08f7b006916007d', // ahmed@integral
      'auth0|62185d5412228b007092306e', // chris@integral,
      'google-oauth2|105617967553917671484', //fredo@integral,
      'google-oauth2|105979536974247865896', // jimmy@integral on google

      // additional
      'auth0|6279474e3a205b0068d8e24c',  // moalopes01@gmail.com ,
      'auth0|640b5a050cb9e0a4c4cb80bc', // wilkebo@gmail.com
      'google-oauth2|112266159569858601049', // julian (advisor)
      'google-oauth2|111548720692285016308', // andres (advisor)

      // triagons
      'auth0|62a95e96aa8bb15baefb47a0', // mike
      'facebook|100542842626901', // mike
      'auth0|63ae33824e7c4abd8841cec3', // mike-dev
      'google-oauth2|111919550142354047443', // Daniel triagons
      'google-oauth2|114641792258146081986', // Ani gmail
      'google-oauth2|116704120084018979656', // jorge triagons
      'google-oauth2|101557292925897187101', // fabian triagons
      'google-oauth2|105395460822195502371', // brandon triagons
      'google-oauth2|104624373210547756848', // Benjamin
      'google-oauth2|100251716658226641902', // Erick
      'google-oauth2|104896989674013413688', // Manuel
      'google-oauth2|100367626551898370108', // Daniel Cruz
      'auth0|66d4f84075eb4dac07e917ef', // Daniel Cruz (email login)
      'google-oauth2|110364611175737201121', // Manuel Moreno
      'google-oauth2|109857411633429921224' // Alejandro Collazos
    ].indexOf(`${user.userid}`) > -1;
}

export function isYouTubeLogin(user: UserData) {
    return [
        // youtube-api-test@gmail.com
        'auth0|64b01af42be3d38416c79c48'
    ].indexOf(`${user.userid}`) > -1;

}