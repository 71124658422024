import { makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";
import { MEDIA_BREAKPOINTS } from "types/global";
import { SystemColors } from 'types/globalStyles';

const useStyles = makeStyles((_theme: Theme) => ({
  withBorderRadius: {
    borderRadius: 12,
  },
  input: {
    '& .MuiInputBase-root': {
      height: '45px',
      marginBottom: '16px'
    },
    '& input': {
      fontFamily: 'DIN',
      fontSize: '16px',
    },
    '& input::placeholder': {
      color: `${SystemColors.SECONDARY_TEXT_COLOR} !important`,
      opacity: '1 !important',
    },
    '& fieldset': {
      borderColor: `${SystemColors.PAPER_DIVIDER_CONTAINER} !important`,
    },
    '& .Mui-focused > fieldset': {
      borderColor: `${SystemColors.ACCENT_COLOR} !important`,
    },
    '& .Mui-error > fieldset': {
      borderColor: `${SystemColors.ERROR_COLOR} !important`,
    },
  },
  accountsContainer: {
    maxHeight: '234px',
    overflowY: 'scroll',
    [MEDIA_BREAKPOINTS.mobileView]: {
      maxHeight: 'none',
    },
  },
}))

interface AddAccountCtnProps {
  valid: boolean;
}

export const AddAccountChip = styled.div`
    display: flex;
    padding: 4px 8px;
    align-items: center;
    border-radius: 4px;
    background: var(--divider-line, #EDECF2);
    color: ${SystemColors.SECONDARY_TEXT_COLOR};
    font-size: 12px;
    gap: 4px;
`

export const AddAccountInvalidCtn = styled.div`
    display: flex;
    border-radius: 4px;
    background: ${SystemColors.PAPER_ERROR_CONTAINER};
    padding: 8px;
    gap: 10px;
    font-size: 16px;
    color: ${SystemColors.SECONDARY_TEXT_COLOR};
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const ExternalLink = styled.a`
    color: ${SystemColors.ACCENT_COLOR};
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`


export const AddAccountCtn = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    ${({ valid }: AddAccountCtnProps) => (!valid ? 'color: #707070;' : '')}
`

export default useStyles;
