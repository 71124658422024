import { MainContainer } from "styles/shared";
import OrganizationNeedsSetupState from "../SetupStatus";
import { isAdmin } from "helpers/admin";
import { CurrentBrand, UserData } from "types/global";
import { Card } from "../styles";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { TEAM_PLAN_INCLUDED_PRO_ACCOUNTS } from "../../../../constants";
import { CurrentTeamContext } from "Hooks/CurrentTeamContext";

interface AdminOnboardingProps {
    user: UserData;
    currentBrand: CurrentBrand;
}

function AdminOnboarding({ user, currentBrand }: AdminOnboardingProps) {

    const userIsAdmin = isAdmin(user)
    const history = useHistory()

    const { brandIsInOrganization } = useContext(CurrentTeamContext)

    useEffect(() => {
        if (!userIsAdmin) {
            history.push('/')
        }
    }, [userIsAdmin])


    let defaultBrands = currentBrand?.slug && brandIsInOrganization(currentBrand?.slug) ? [] : [currentBrand]

    // Renders the UI based on organization setup status
    const renderPrimaryView = () => {
        return (
            <Card className="organizationNeedsSetupCardComponent" padding="40px 24px" borderRadius="8px" marginBottom="16px"
                width="750px">
                <OrganizationNeedsSetupState
                    currentBrand={currentBrand}
                    existingBrands={defaultBrands}
                    title="Admin: Onboard a Team"
                    description={`Create a team on behalf of an organization or profile.\nYou can manage unlimited profiles through your Team, and you have ${TEAM_PLAN_INCLUDED_PRO_ACCOUNTS} Pro accounts included by default.`}
                    organization={null}
                    onFinished={() => { }}
                    isAdminSetup={true} />
            </Card>
        )
    }

    return (
        <MainContainer className="organizationPageComponent">
            {renderPrimaryView()}
        </MainContainer>
    )
}

export default AdminOnboarding