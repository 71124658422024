import { makeStyles } from '@material-ui/core/styles';
import { MEDIA_BREAKPOINTS } from 'types/global';
import { SystemColors } from 'types/globalStyles';

export const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    border: `1px solid ${SystemColors.DIVIDER_LINE}`,
    padding: '12px',
    [MEDIA_BREAKPOINTS.mobileView]: {
      flexDirection: 'column',
    },
  },
  warnContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    borderRadius: '8px',
    padding: '16px',
    backgroundColor: SystemColors.PAPER_WARNING_CONTAINER,
    [MEDIA_BREAKPOINTS.mobileView]: {
      flexDirection: 'column',
    },
  },
  errorContainer: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    border: `1px solid ${SystemColors.DIVIDER_LINE}`,
    padding: '12px',
    backgroundColor: SystemColors.PAPER_ERROR_CONTAINER,
    [MEDIA_BREAKPOINTS.mobileView]: {
      flexDirection: 'column',
    },
  },
});