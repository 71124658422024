import { makeStyles } from '@material-ui/core/styles';
import { SystemColors } from 'types/globalStyles';

export const useStyles = makeStyles({
  grayIcon: {
    width: '24px',
    height: '24px',
    '& path': {
      stroke: SystemColors.SECONDARY_TEXT_COLOR,
    },
  },
  connectionsName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});
