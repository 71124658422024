import { getInstagramAccountFromFbPage } from 'services/facebookApi';
import { FacebookPageType, InstagramPageType } from 'types/global';

export interface FbIgPageOption {
  fbAccount: FacebookPageType;
  igAccount: InstagramPageType | null;
}

export const formatFacebookPages = async (pages: { data: { name: string, is_published?: boolean }[] }, access_token?: string) => {
  const sortedPages = pages.data
    .sort((a: any, b: any) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    })
    .sort((a, b) => {
      const accountStatusA = a?.is_published;
      const accountStatusB = b?.is_published;

      if ((accountStatusA && accountStatusB) || (!accountStatusA && !accountStatusB)) return 0;
      if (accountStatusB && !accountStatusA) return 1;
      if (!accountStatusB && accountStatusA) return -1;
      return 0;
    });

  const formattedPages: FbIgPageOption[] = await Promise.all(
    sortedPages.map(async (page: any) => {
      const facebookPage: FacebookPageType = {
        id: page.id,
        name: page.name,
        access_token: page?.access_token,
        category: page?.category,
        followers_count: page?.followers_count,
        picture: page?.picture,
      };
      const instagramPage = await getInstagramAccountFromFbPage(facebookPage, access_token);
      return { fbAccount: facebookPage, igAccount: instagramPage };
    })
  );

  return formattedPages;
};
