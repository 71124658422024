import { Divider, TextField } from "@material-ui/core";
import SearchIcon from '@mui/icons-material/Search';

import { Theme, makeStyles } from "@material-ui/core/styles";
import { Headline3 } from "components/shareable/Typography";
import PrimaryButton from "components/shareable/PrimaryButton";
import { SystemColors } from "types/globalStyles";

const useStyles = makeStyles((theme: Theme) => ({
    input: {
        '& .MuiInputBase-root': {
            height: '45px',
            width: '182px',
        },
        '& input': {
            fontFamily: 'DIN',
            fontSize: '16px',
        },
        '& input::placeholder': {
            color: `${SystemColors.SECONDARY_TEXT_COLOR} !important`,
            opacity: '1 !important',
        },
        '& fieldset': {
            borderColor: `${SystemColors.PAPER_DIVIDER_CONTAINER} !important`,
        },
        '& .Mui-focused > fieldset': {
            borderColor: `${SystemColors.ACCENT_COLOR} !important`,
        },
        '& .Mui-error > fieldset': {
            borderColor: `${SystemColors.ERROR_COLOR} !important`,
        },
    }
}));

interface PageHeaderWithSearchProps {
    title: string;
    onSearch?: (value: string) => void;
    buttonText?: string;
    onButtonClick?: () => void;
    searchValue?: string;
    loading?: boolean;
}

export default function PageHeaderWithSearch({
    title,
    onSearch,
    buttonText,
    onButtonClick,
    searchValue = "",
    loading = false,

}: PageHeaderWithSearchProps) {
    const classes = useStyles();

    return (
        <div className='flex gap-2 items-center justify-between'>
            <Headline3 color={SystemColors.PRIMARY_TEXT_COLOR}>{title}</Headline3>
            <div className='flex gap-4 items-center'>
                {onSearch && (<TextField
                    value={searchValue}
                    type="search"
                    variant="outlined"
                    className={classes.input}
                    placeholder="Search"
                    onChange={(event) => onSearch(event.target.value)}
                    InputProps={{ startAdornment: (<SearchIcon height={24} width={24} className="mr-3" />) }}
                />)}
                {buttonText && onButtonClick && (
                    <PrimaryButton
                        loading={loading}
                        disabled={loading}
                        text={buttonText}
                        onClick={onButtonClick}
                        height='39px'
                    />
                )}
            </div>
            {/* <Divider /> */}

        </div>
    );
} 