import { Body2, Caption } from "components/shareable/Typography";
import { BadgeContainer, EmptyIconCenter, EmptyIconContainer } from "./styles";
import { SystemColors } from "types/globalStyles";
import LinkButton from "components/shareable/LinkButton";
import { TEAM_PLAN_INCLUDED_SEATS, TEAM_PLAN_ADDITIONAL_SEAT_PRICE } from "../../../../../constants"

interface AvailableSeatBadgeProps {
  onAddMember: () => void;
  currentSeats: number;
}

export const AvailableSeatBadge = ({ onAddMember, currentSeats }: AvailableSeatBadgeProps) => {
  return (
    <BadgeContainer>
      <div className="flex gap-4 items-center">
        <EmptyIcon />
        <div className="flex flex-col gap-1">
          <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>You have {TEAM_PLAN_INCLUDED_SEATS - currentSeats} user seats available</Body2>
          <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>{TEAM_PLAN_INCLUDED_SEATS} seats are included with the team plan, +${TEAM_PLAN_ADDITIONAL_SEAT_PRICE} / month for additional</Caption>
        </div>
      </div>
      <LinkButton text="Add member" onClick={onAddMember} />
    </BadgeContainer>
  );
};

export const EmptyIcon = () => {
  return (
    <EmptyIconContainer>
      <EmptyIconCenter />
    </EmptyIconContainer>
  );
};