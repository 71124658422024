import Axios from "helpers/Interceptor"

export const forceRelease = async (contentSlug: string, spotifyId?: string) => {
    try {
        const { data } = await Axios.post(`/campaign/presave/${contentSlug}/force-release`, { spotifyId })
        if (data.isError) throw new Error("Error pulling releases")
        return data
    } catch (e) {
        return null
    }
}

export const getCampaignDetails = async (brandId: number, campaignId: string | null) => {
    if (!campaignId) return null
    try {
        const { data } = await Axios.get(`/campaign/${campaignId}`)
        if (data.isError) throw new Error("Error pulling campaign data")
        return data
    } catch (e) {
        return null
    }
}