import { FunctionComponent, useState, useEffect, CSSProperties } from "react";
import { Backdrop, Fade, Modal } from "@material-ui/core";
import { ReactComponent as SearchIcon } from "assets/images/search-icon.svg";
import { ReactComponent as UserIcon } from 'assets/images/user.svg';

import { CurrentBrand, Tiers } from "types/global"; // Adjust import path as needed
import InputWithDebounce from "../InputWithDebounce";
import HeaderModal from "components/connect/connectModals/Spotify/Components/HeaderModal";
import BrandsListItemModal from "./BrandListItemModal";


interface Props {
    className?: string;
    isOpen: boolean;
    onClose: () => void;
    brands: CurrentBrand[];
    onBrandSelect: (brandId: string) => void;
    titleText?: string;
    subtitleText?: string;
}

interface DisplayedBrand {
    name: string;
    image: string;
    slug: string;
    isPro: boolean;
    isLoading: boolean;
    id: string;
}

const BrandSelectionModal: FunctionComponent<Props> = ({
    className = '',
    isOpen,
    onClose,
    brands = [],
    onBrandSelect = () => { },
    titleText = "Select a Profile",
    subtitleText = "Choose the profile you want to create a campaign for."
}) => {
    const [displayedBrands, setDisplayedBrands] = useState<DisplayedBrand[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
        const filteredBrands = formatBrands().filter((brand) =>
            brand.name.toLowerCase().includes(value.toLowerCase())
        );
        setDisplayedBrands(filteredBrands);
    };

    const handleChangeText = (value: string) => {
        if (!value) {
            setDisplayedBrands([]);
        } else {
            setSearchTerm(value);
        }
    };

    const formatBrands = (): DisplayedBrand[] => {
        return brands.map((brand) => ({
            id: String(brand.id),
            name: brand.name!,
            image: brand.image!,
            slug: brand.slug!,
            isPro: brand.tier === Tiers.PRO,
            isLoading: false,
        }));
    };

    const renderBrandsList = () => {
        if (!displayedBrands.length) {
            return (
                <div className="noBrandsFoundContainer flex flex-col justify-center items-center h-64 bg-gray-200">
                    <UserIcon className="w-8 h-8 text-gray-500" />
                    <p className="text-gray-500">No results found, try another search.</p>
                </div>
            );
        }

        return (
            <div className="brandsListContainer" style={sty.brandsListContainer}>
                {displayedBrands.map((brand, idx) => (
                    <BrandsListItemModal
                        className="selectBrandModalListItem"
                        key={idx}
                        isPro={brand.isPro}
                        slug={brand.slug}
                        name={brand.name}
                        image={brand.image}
                        onClickListItem={() => onBrandSelect(brand.id)}
                    />
                ))}
            </div>
        );
    };

    useEffect(() => {
        if (brands?.length) {
            setDisplayedBrands(formatBrands());
        }
    }, [brands]);

    return (
        <Modal
            className="brandSelectionModalContainer"
            style={sty.brandSelectionModalContainer}
            open={isOpen}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 200 }}
        >
            <Fade in={isOpen}>
                <div
                    className={`brandSelectionModalContentContainer ${className} md:w-4/6 lg:w-2/6 max-w-5xl bg-white md:rounded-md`}
                    style={sty.brandSelectionModalContentContainer}
                >
                    <HeaderModal
                        title={titleText}
                        subtitle={subtitleText}
                        closeModal={onClose}
                    />
                    <InputWithDebounce
                        onChange={handleChangeText}
                        debounceHandler={handleSearchChange}
                        value={searchTerm}
                        placeholder="Search..."
                        loading={false}
                        debounceTime={800}
                        leftIcon={<SearchIcon />}
                    />
                    {renderBrandsList()}
                </div>
            </Fade>
        </Modal>
    );
};

const sty = {
    brandSelectionModalContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    } as CSSProperties,
    brandSelectionModalContentContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        padding: 16,
        width: "100%",
        maxWidth: 400,
    } as CSSProperties,
    brandsListContainer: {
        marginTop: 8,
        borderRadius: 8,
        border: "1px solid #E0E0E0",
        boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.1)",
        maxHeight: 320,
        overflow: "scroll",
    } as CSSProperties
}

export default BrandSelectionModal;