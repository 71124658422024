import styled from "styled-components";
import { SystemColors } from '../../types/globalStyles';
import { MEDIA_BREAKPOINTS } from "types/global";

interface ImageContainerProps {
    backgroundColor?: string;
}

export const ImageContainer = styled.div<ImageContainerProps>`
    display: flex;
    align-items: center;
    background-color: ${(props) => props.backgroundColor || "#1977F329"};
    padding: 0.75rem;
    border-radius: 8px;
`;

export const SuccessChip = styled.div`
    background-color: #07C80614;
    border-radius: 48px;
    padding: 12px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;

export const MainContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${SystemColors.DIVIDER_LINE};
    padding: 12px;
    border-radius: 8px;
    gap: 16px;
    ${MEDIA_BREAKPOINTS.mediumView} {
        flex-direction: column;
        gap: 8px;
    }
`;

export const DescriptionContainer = styled.div`
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    ${MEDIA_BREAKPOINTS.mediumView} {
        flex-direction: column;
        gap: 8px;
    }
`;

export const ConnectionDescription = styled.div`
    display: flex;
    width: 40%;
    align-items: center;
    gap: 8px;
`;

export const LinkedIgContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;