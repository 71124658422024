import { setUserProperty, track } from "analytics";
import { FanbaseTasksNames } from "pages/post-auth/Fans/Components/FanbaseTasks";
import { FANBASE_TASKS, SymphonyTasks } from "types/global";
import Axios from "helpers/Interceptor";
import mixpanel from "mixpanel-browser";
import { FunctionComponent, ReactNode, createContext, useEffect, useState } from "react";

interface Context {
  fetchFanbaseTasks: () => void;
  finishedTasks: FANBASE_TASKS[];
  completed: boolean;
  finishTask: (task: FANBASE_TASKS) => void;
}

export const FanbaseContext = createContext<Context>({
  fetchFanbaseTasks: () => {},
  finishedTasks: [],
  completed: true,
  finishTask: (task: FANBASE_TASKS) => {},
});

type Props = {
  currentBrand?: number | null | undefined;
  children: ReactNode[] | ReactNode;
}

export const FanbaseProvider: FunctionComponent<Props> = ({
  currentBrand,
  children,
}: Props) => {
  const [finishedFanbaseTasks, setFanbaseTasks] = useState<FANBASE_TASKS[]>(Object.values(FANBASE_TASKS));
  const [completed, setCompleted] = useState(true);
  
  const fetchFanbaseTasks = async () => {
    try {
      if (!currentBrand) return;
      const response = await Axios.post(`/brand/${currentBrand}/fanbasetasks`);
      const tasks = response.data.data as SymphonyTasks;
      if(!completed && tasks.fanbase_tasks_completed) {
        setUserProperty("Completed All Fanbase", true);
        track('Completed All Fanbase Steps');
      }
      setFanbaseTasks(tasks.fanbase_tasks || []);
      setCompleted(tasks.fanbase_tasks_completed);
    } catch (err) {
      console.error(err);
    }
  }

  const finishTask = async (task: FANBASE_TASKS) => {
    try {
      if (!currentBrand) return;
      const response = await Axios.put(`/brand/${currentBrand}/fanbasetasks`, {
        task_id: task,
      });
      await fetchFanbaseTasks();
      setUserProperty(`Completed "${FanbaseTasksNames[task]}" Fanbase Step`, true)
      track(`Completed "${FanbaseTasksNames[task]}" Fanbase Step`, {
          remainingSteps: Object.keys(FANBASE_TASKS).length - finishedFanbaseTasks.length - 2,
      });
      mixpanel.people.set({
        "completed_fanbase_steps": finishedFanbaseTasks.length + 1, 
      });
    } catch (err) {
      console.error(err);
    }
  }
  
  useEffect(() => {
    if (currentBrand)
      fetchFanbaseTasks();
  }, [currentBrand]);
  
  const context = {
    fetchFanbaseTasks,
    finishedTasks: finishedFanbaseTasks,
    completed,
    finishTask,
  };

  return (
    <FanbaseContext.Provider value={context}>
      {children}
    </FanbaseContext.Provider>
  )
}