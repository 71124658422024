import { useMediaQuery, useTheme } from "@material-ui/core";
import { CSSProperties, FunctionComponent } from "react";
import CampaignDescription, { CampaignBadges, CampaignTitleAndDate } from "../../../Components/CampaignDescription";
import { ButtonFinish, ButtonsContainer, TextButtonFinish, TrashButton } from "./styles";
import defaultImage from 'assets/images/music-key-green-bg.svg';
import trashIcon from 'assets/images/trash.svg';
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";
import { Thumbnail } from "pages/post-auth/MarketingPage/Components/CampaignCard/CardContent";

interface Props {
  imageUrl: string;
  campaignName: string;
  typeTitle: string;
  date: string;
  campaignId: number;
  campaignType: string;
  deleteDraft: (campaignId: number) => void;
  goToDraft: (campaignType: string, campaignId: number) => void;
}

/**
 * Component for campaign action buttons (Finish creating and Delete)
 */
const CampaignActions: React.FC<{
  onFinish: () => void;
  onDelete: () => void;
}> = ({ 
  onFinish, 
  onDelete, 
}) => {
  const { mobileView } = useMediaBreakpoints();
  const sty =
    {
      marginTop: '1rem',
      marginLeft: !mobileView && 'auto'
    } as CSSProperties;

  return (
    <div className="cardCampaignDraftActions" style={sty}>
      <ButtonsContainer>
        {mobileView ? (
          <>
            <TrashButton
              onClick={onDelete}
              src={trashIcon}
              alt="trash-icon"
            />
            <ButtonFinish onClick={onFinish}>
              <TextButtonFinish>
                Finish creating
              </TextButtonFinish>
            </ButtonFinish>
          </>
        ) : (
          <>
            <ButtonFinish onClick={onFinish}>
              <TextButtonFinish>
                Finish creating
              </TextButtonFinish>
            </ButtonFinish>
            <TrashButton
              onClick={onDelete}
              src={trashIcon}
              alt="trash-icon"
            />
          </>
        )}
      </ButtonsContainer>
    </div>
  );
}
  



/**
 * Component for campaign information display
 */
const CampaignInfo: React.FC<{
  imageUrl: string;
  campaignName: string;
  date: string;
  campaignId: number;
  campaignType: string;
}> = ({ 
  imageUrl, 
  campaignName, 
  date, 
  campaignId, 
  campaignType, 
}) => {
  const { mobileView } = useMediaBreakpoints();
  const sty: {
    responsiveDraftInfo: CSSProperties;
    desktopDraftInfo: CSSProperties;
  } = {
    responsiveDraftInfo: {
      display: 'flex', 
      flexDirection: 'row', 
      alignItems: 'center' 
    },
    desktopDraftInfo: {
      maxWidth: '75%', 
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }  
  }
  return mobileView ? 
    <div className="cardCampaignDraftInfo" style={sty.responsiveDraftInfo}>
      <Thumbnail src={imageUrl ? imageUrl : defaultImage} />
      <CampaignTitleAndDate campaignName={campaignName} date={date} />
    </div>
   : 
    <div className="cardCampaignDraftInfo" style={sty.desktopDraftInfo}>
      <Thumbnail src={imageUrl ? imageUrl : defaultImage} />
      <CampaignDescription
        campaignName={campaignName}
        date={date}
        key={campaignId}
        campaignType={campaignType}
      />
    </div>
}

const CardCampaignDraft: FunctionComponent<Props> = ({
  imageUrl,
  campaignName,
  date,
  campaignId,
  campaignType,
  deleteDraft,
  goToDraft,
}: Props) => {
  const { mobileView } = useMediaBreakpoints();
  const handleFinishCampaign = () => goToDraft(campaignType, campaignId);
  const handleConfirmDeleteDraft = () => deleteDraft(campaignId);

  const sty = {
    desktopView: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingTop: '1.75rem',
      paddingBottom: '1.75rem'
    } as CSSProperties,
    mobileView : {
      display: 'flex',
      flexDirection: 'column', 
      paddingLeft: 0,
      paddingBottom: '1.25rem',
      marginBottom: '1rem',
    } as CSSProperties,
    mobileViewCampaignInfo: {
      width: '100%',
      marginTop: '1rem'
    } as CSSProperties,
    mobileViewCampaignActions: {
      width: '100%'
    } as CSSProperties
  }
  return (
    <>
      {!mobileView ? (
        <div className="cardCampaignDraftDesktopView border-b" style={sty.desktopView}>
          <CampaignInfo
            imageUrl={imageUrl}
            campaignName={campaignName}
            date={date}
            campaignId={campaignId}
            campaignType={campaignType}
          />
          <CampaignActions
            onFinish={handleFinishCampaign}
            onDelete={handleConfirmDeleteDraft}
          />
        </div>
      ) : (
        <>
          <CampaignBadges campaignType={campaignType} campaignStatus="draft" />
          <div className="cardCampaignDraftMobileView border-b" style={sty.mobileView}>
            <div style={sty.mobileViewCampaignInfo}>
              <CampaignInfo
                imageUrl={imageUrl}
                campaignName={campaignName}
                date={date}
                campaignId={campaignId}
                campaignType={campaignType}
              />
            </div>
            <div style={sty.mobileViewCampaignActions}>
              <CampaignActions
                onFinish={handleFinishCampaign}
                onDelete={handleConfirmDeleteDraft}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CardCampaignDraft;

