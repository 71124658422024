import { ReactComponent as ChevronIcon } from 'assets/images/chevronDown.svg'
import { ReactComponent as WarningIcon } from 'assets/images/campaignDetails/warning.svg'
import { SystemColors } from 'types/globalStyles'
import styled from 'styled-components'

export const StyledBannerDetails = styled.details`
  width: 100%;
  padding: 16px 24px;
  border-radius: 8px;
  background-color: ${SystemColors.PAPER_WARNING_CONTAINER};
`
interface StyledArticleProps {
  displayBottomBorderLine: boolean
}

export const StyledArticle = styled.article<StyledArticleProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding-bottom: ${({ displayBottomBorderLine }) =>
    displayBottomBorderLine
    ? '24px;'
    : 'none'};
  border-bottom: ${({ displayBottomBorderLine }) =>
    displayBottomBorderLine
      ? `1px solid ${SystemColors.DARK_WARNING};`
      : 'none'};
`

export const StyledAnchor = styled.a`
  width: 100%;
  text-decoration: none;
  color: ${SystemColors.ACCENT_COLOR};
  cursor: pointer;
`

export const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    display: inline-block;
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
  }
`

export const StyledWarningIcon = styled(WarningIcon)`
  width: 18px;
  height: 18px;
  margin-right: 4px;
  path {
    stroke: ${SystemColors.WARNING_COLOR};
  }
`

interface StyledChevronIconProps {
  isFlipped: boolean
}

export const StyledChevronIcon = styled(ChevronIcon)<StyledChevronIconProps>`
  width: 24px;
  height: 24px;
  transform: scale(${(props) => (props.isFlipped ? -1 : 1)});
`
