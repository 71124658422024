import { AdAccountPageType, CurrentBrand, FacebookBusinessManager, FBPixel, GeographicTargetingType, InstagramPageType, PixelConversionEvent } from "types/global"
import { Dispatch, SetStateAction } from "react"
import Axios from "helpers/Interceptor"
import dayjs from "dayjs"
import { InstagramPost } from "components/shareable/InstagramPosts/types"
import { CONTINUE, NEXT, REVIEW, REVIEW_YOUR_CAMPAIGN, SAVE, CONNECT } from "./constants"
import { PreConnectionConfigs, UploadedAssetType } from "../utils/fbCampaigns"
import Logger from "Logger"

export enum STEPS {
  LINK = "link",
  CREATIVE = "creative",
  BUDGET = "budget",
  FINALIZATION = "review",
}

const { BUDGET, CREATIVE } = STEPS

const dev = process.env.NODE_ENV !== "production"

export enum Platforms {
  SPOTIFY = "spotify",
  ALL = "all",
  APPLE_MUSIC = "apple_music",
  YOUTUBE = "youtube",
}

type DataPlatform = {
  name: string
  key: string
  goal: string
  selected: boolean
  subtitle: string | null
  uploadingAssets: boolean
}

export type SelectedPlatforms = {
  spotify: DataPlatform
  all: DataPlatform
  apple_music: DataPlatform
  youtube: DataPlatform
}

export const getIfTargetingSaveButtonIsDisabled = (
  targets: GeographicTargetingType[],
  totalBudget: number
) => {
  const locations = targets.map(({ locations }) => Object.values(locations))
  const budgets = targets.map(({ budget }) => budget)
  return (
    totalBudget !== 1
    || !locations.filter((location) => location.length > 0).length
    || budgets.includes(0)
  )
}

export const getWhenCreativeTabNextButtonIsDisabled = (args: {
  adConnectionsAdded: boolean
  adConnectionsConfirmed: boolean
  selectedInstagramPost?: InstagramPost | null
  preConnectionConfigurations?: PreConnectionConfigs
  selectedCreativeType: string
  arePagesSelected: boolean
  uploadingAssets: boolean
  uploadedImages: UploadedAssetType[] | null;
  cta: string | { value: string; label: string }
  caption: string
}) => {
  const {
    uploadedImages,
    adConnectionsAdded,
    adConnectionsConfirmed,
    uploadingAssets,
    selectedInstagramPost,
    arePagesSelected,
    preConnectionConfigurations,
    selectedCreativeType,
    cta,
  } = args

  if (!adConnectionsAdded) {
    return !(
        arePagesSelected &&
        preConnectionConfigurations &&
        preConnectionConfigurations.status
      )
  }

  if (!adConnectionsConfirmed) {
    return false
  }

  return selectedCreativeType === "instagram_posts"
    ? !(arePagesSelected && selectedInstagramPost && cta)
    : !(
        !uploadingAssets &&
        arePagesSelected &&
        uploadedImages &&
        uploadedImages.length > 0 &&
        cta
      )
}

export const getNewCampaignName = (currentLink: string | URL) => {
  try {
    const url = new URL(currentLink)

    if (url) {
      return `${url.hostname} Campaign - ${dayjs().format("MM/DD")}`
    }
  } catch (e) {
    console.log("Not valid link yet - ", e)
    return `Link Clicks Campaign - ${dayjs().format("MM/DD")}`
  }
}

export const handleConnectionsType = (args: {
  connectionsType: string
  selectedFBBusinessManager: FacebookBusinessManager
  selectedFBAdAccount: { id: string; business: { id: string } }
  selectedFB_page: { id: string }
  currentBrand: CurrentBrand
  previouslySelectedBizMgr: { current: { id: string } }
  previouslySelectedAdAccount: { current: { id: string } }
  previouslySelectedFbPage: { current: { id: string } }
  setFBBusinessManager: Dispatch<SetStateAction<FacebookBusinessManager | undefined>>
  setFBAdAccount: Dispatch<SetStateAction<AdAccountPageType| undefined>>
  setFBPixel: Dispatch<SetStateAction<FBPixel| undefined>>
  setSelectedInstaPage: Dispatch<SetStateAction<InstagramPageType | undefined>>
}) => {
  const {
    connectionsType,
    selectedFBBusinessManager,
    selectedFBAdAccount,
    currentBrand,
    previouslySelectedBizMgr,
    previouslySelectedAdAccount,
    selectedFB_page,
    previouslySelectedFbPage,
    setSelectedInstaPage,
    setFBBusinessManager,
    setFBAdAccount,
    setFBPixel,
  } = args

  if (connectionsType === "custom") {
    if (dev) console.log("Custom connection")
    if (dev) console.log("bizmgr", selectedFBBusinessManager)
    if (dev) console.log("ad account", selectedFBAdAccount)

    // if a user changes their business manager,
    // fb ad account + pixel must be deselected
    // OR if a user goes from quick to custom
    // and the business ID in the ad account doesnt match the selected biz mgr
    if (
      selectedFBBusinessManager &&
      selectedFBAdAccount &&
      (!selectedFBAdAccount.business ||
        (selectedFBAdAccount.business &&
          selectedFBAdAccount.business.id &&
          selectedFBAdAccount.business.id !== selectedFBBusinessManager.id))
    ) {
      if (dev) console.log("remove biz mgr")
      setFBBusinessManager(undefined)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "facebook_business_manager",
        value: selectedFBBusinessManager,
      })
        .then((result) => {
          // reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting biz mgr", err)
        })
    }

    if (
      !selectedFBBusinessManager ||
      (selectedFBBusinessManager &&
        (!previouslySelectedBizMgr.current ||
          previouslySelectedBizMgr.current.id !== selectedFBBusinessManager.id))
    ) {
      if (dev) console.log("selected biz mgr changed")

      if (!selectedFBBusinessManager) {
        Axios.put(`/brand/${currentBrand.slug}/connect`, {
          id: currentBrand.id,
          name: currentBrand.slug,
          service: "facebook_business_manager",
          value: selectedFBBusinessManager,
        })
          .then((result) => {
            // reloadBrand()
          })
          .catch((err) => {
            console.log("error connecting biz mgr", err)
          })
      }
      // set the ad account null in backend too
      setFBAdAccount(undefined)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "facebook_ad_account",
        value: null,
      })
        .then((result) => {
          // reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting ad account", err)
        })

      setFBPixel(undefined)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "facebook_pixel",
        value: null,
      })
        .then((result) => {
          // reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting fb pixel", err)
        })

      previouslySelectedBizMgr.current = selectedFBBusinessManager
    }

    // if a user changes their selected ad account,
    // fb pixel must be deselected
    if (
      selectedFBAdAccount &&
      (!previouslySelectedAdAccount.current ||
        previouslySelectedAdAccount.current.id !== selectedFBAdAccount.id)
    ) {
      // set the pixel null in backend too
      if (dev) console.log("selected FB Ad Account changed")

      setFBPixel(undefined)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "facebook_pixel",
        value: null,
      })
        .then((result) => {
          // reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting fb pixel", err)
        })
      previouslySelectedAdAccount.current = selectedFBAdAccount
    }

    // if a user changes their selected Facebook page,
    // IG page must be deselected
    if (
      selectedFB_page &&
      (!previouslySelectedFbPage.current ||
        previouslySelectedFbPage.current.id !== selectedFB_page.id)
    ) {
      // set the IG to null
      if (dev) console.log("selected FB Page changed")
      setSelectedInstaPage(undefined)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "instagram_page",
        value: null,
      })
        .then((result) => {
          // reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting instagram", err)
        })

      previouslySelectedFbPage.current = selectedFB_page
    }
  } else if (connectionsType === "quick") {
    // if a user changes their selected ad account,
    // fb pixel must be deselected
    if (
      selectedFBAdAccount &&
      (!previouslySelectedAdAccount.current ||
        previouslySelectedAdAccount.current.id !== selectedFBAdAccount.id)
    ) {
      // set the pixel null in backend too
      if (dev) console.log("selected FB Ad Account changed")

      setFBPixel(undefined)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "facebook_pixel",
        value: null,
      })
        .then((result) => {
          // reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting fb pixel", err)
        })
      previouslySelectedAdAccount.current = selectedFBAdAccount
    }

    // if a user changes their selected Facebook page,
    // IG page must be deselected
    if (
      selectedFB_page &&
      (!previouslySelectedFbPage.current ||
        previouslySelectedFbPage.current.id !== selectedFB_page.id)
    ) {
      // set the IG to null
      if (dev) console.log("selected FB Page changed")
      setSelectedInstaPage(undefined)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "instagram_page",
        value: null,
      })
        .then((result) => {
          // reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting instagram", err)
        })

      previouslySelectedFbPage.current = selectedFB_page
    }
  }
}

export const getNextButtonLabel = (args: {
  showTargetingView: boolean
  selectedTab?: STEPS | null
  isNextButtonMobile: boolean
}) => {
  const {
    showTargetingView,
    selectedTab,
    isNextButtonMobile,
  } = args

  if (showTargetingView) return SAVE
  if (selectedTab === BUDGET && isNextButtonMobile) return REVIEW
  if (selectedTab === BUDGET && !isNextButtonMobile) return REVIEW_YOUR_CAMPAIGN
  if (selectedTab === CREATIVE) return CONTINUE
  return NEXT
}

export const getNextButtonWidth = (args: {
  selectedTab?: STEPS | null
  isNextButtonResponsive: boolean
  isNextButtonMobile: boolean
}) => {
  const { selectedTab, isNextButtonMobile, isNextButtonResponsive } = args

  if (selectedTab === BUDGET && isNextButtonMobile) return undefined
  if (selectedTab === BUDGET && !isNextButtonMobile && isNextButtonResponsive)
    return "220px"
  if (selectedTab === BUDGET) return "300px"
  return undefined
}

export const convertArrayDraftDataToSendAsObject = (
  data: Record<string, unknown>[]
) => {
  const [
    currentLink,
    selectedFB_page,
    selectedFBAdAccount,
    selectedInstaPage,
    uploadedImages,
    selectedInstagramPost,
    caption,
    budgetValue,
    startDate,
    endDate,
    targets,
    audiences,
    customAudiences,
    additionalPageAudiences,
    selectedTab,
    cta,
    campaignName,
    minAge,
    maxAge,
    gender,
    languages,
    addVisualSelected,
    conversionsEnabled,
    conversionsEventName,
    facebook_pixel,
    facebook_business_manager,
    logged_in_fb_user,
  ] = data

  return {
    currentLink,
    selectedFB_page,
    selectedFBAdAccount,
    selectedInstaPage,
    uploadedImages,
    selectedInstagramPost,
    caption,
    budgetValue,
    startDate,
    endDate,
    targets,
    audiences,
    customAudiences,
    additionalPageAudiences,
    selectedTab,
    cta,
    campaignName,
    demographics: {
      age: {
        min: minAge,
        max: maxAge,
      },
      gender,
      languages,
    },
    addVisualSelected,
    conversionsEnabled,
    conversionsEventName,
    facebook_pixel,
    facebook_business_manager,
    logged_in_fb_user,
  }
}

export const getIfDraftHasConversionsEnabled = (data: {
  campaignDraftData: Record<string, unknown> | null,
}) => {
  const { campaignDraftData } = data
  if  (!campaignDraftData) return false

  const draftMetadata = campaignDraftData?.campaign_metadata as { campaign_input: { brand: CurrentBrand } }
  const draftConnections = draftMetadata?.campaign_input.brand.connections
  if (!draftConnections?.facebook_ad_account) return false

  const campaignDraftMetadata = campaignDraftData.campaign_metadata as { campaign_input: { conversionsEnabled: boolean } }
  return Boolean(campaignDraftMetadata.campaign_input.conversionsEnabled)
}

export const getConversionsEvents = async () => {
  try {
    const response = await Axios.get('/brand/conversions/events')
    const events: PixelConversionEvent[] = response.data.data
    return events
  } catch (error) {
    Logger.error(error)
  }
}
