import React, { useEffect, Dispatch, SetStateAction, useContext } from "react";
import { useLocation, } from "react-router-dom";
import { CurrentBrand, UserData } from "types/global";
import {
  DashIcon,
  MktgIcon,
  SettingsIcon,
  SupportIcon,
  WebsiteIcon,
  FansIcon,
  FansIconPath,
  DashboardIconPath,
  MarketingIconPath,
  WebsiteIconPath,
  SettingsIconPath,
  GiftIcon,
  GiftIconPath,
  SupportIconPath,
  LearnIcon,
  LearnIconPath,
  TeamIconPath,
  TeamIcon
} from "../svg-icons"
import Axios from "helpers/Interceptor";
import useScreenQueryDimensions from "Hooks/useScreenQueryDimensions";
import MobileNavigation from "./MobileNavigation";
import DesktopNavigation from "./DesktopNavigation";
import { CAMPAIGN_WIZARD_ROUTES } from "../../constants";
import Intercom from "helpers/Intercom";
import { checkIfIsProTier } from "utils";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";

export function showIntercom() {
  // @ts-ignore
  Intercom.open();
}

export function showNewMessageIntercom() {
  // @ts-ignore
  Intercom.showNewMessage();
}

export interface NavigationItem {
  name: string;
  smallName?: string;
  iconComponent?: React.ComponentType<{ color?: string; height?: string; width?: string }>;
  onClick?: (e: any) => void;
  href?: string;
  alternateHrefs?: string[];
  iconPath?: React.ReactElement,
  hideOnMobile?: boolean;
  hideOnDesktop?: boolean;
  svgIconViewbox?: string;
  dropdownItems?: NavigationItem[]
}

export const getNavLinks = (isPro: boolean): NavigationItem[] => [
  {
    name: "Dashboard",
    href: "/",
    alternateHrefs: ["/dashboard"],
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <DashIcon {...props} />,
    iconPath: <DashboardIconPath />,
    svgIconViewbox: "0 0 18 18"
  },
  {
    name: "Marketing",
    href: "/marketing",
    alternateHrefs: [],
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <MktgIcon {...props} />,
    iconPath: <MarketingIconPath />,
    svgIconViewbox: "0 0 18 18"
  },
  {
    name: "Website",
    href: "/website",
    alternateHrefs: [],
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <WebsiteIcon {...props} />,
    iconPath: <WebsiteIconPath />,
    svgIconViewbox: "0 0 18 18"
  },
  {
    name: "Fans",
    href: "/fans",
    alternateHrefs: [],
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <FansIcon {...props} />,
    iconPath: <FansIconPath />,
    svgIconViewbox: "0 0 18 18"
  },

  {
    name: "Team",
    href: "/team",
    alternateHrefs: [],
    hideOnMobile: true,
    hideOnDesktop: true,
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <TeamIcon {...props} />,
    iconPath: <TeamIconPath />,
    svgIconViewbox: "0 0 20 18"
  },
  {
    hideOnMobile: true,
    name: "Learn",
    href: "/learn",
    alternateHrefs: [],
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <LearnIcon {...props} />,
    iconPath: <LearnIconPath />,
    svgIconViewbox: "0 0 18 18",
    dropdownItems: [
      {
        name: "Videos",
        href: "/learn",
        alternateHrefs: [],
        iconComponent: (props: { color?: string, height?: string, width?: string }) => <FansIcon {...props} />,
        iconPath: <FansIconPath />,
        svgIconViewbox: "0 0 18 18",

      },
      {
        name: "Help Center",
        alternateHrefs: [],
        iconComponent: (props: { color?: string, height?: string, width?: string }) => <FansIcon {...props} />,
        iconPath: <FansIconPath />,
        svgIconViewbox: "0 0 18 18",
        onClick: (e: any) => {
          e.preventDefault()
          Intercom.showHelpCenter()
        },

      },
      {
        hideOnMobile: true,
        hideOnDesktop: !isPro,
        name: "Contact Support",
        iconComponent: (props: { color?: string, height?: string, width?: string }) => <SupportIcon {...props} />,
        iconPath: <SupportIconPath />,
        onClick: (e: any) => {
          e.preventDefault()
          Intercom.showMessages()
        },
        svgIconViewbox: "0 0 18 18"
      },
    ]
  },

  // only shown on mobile - additional options


  {
    hideOnMobile: true,
    hideOnDesktop: true,
    name: "Settings",
    href: '/settings',
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <SettingsIcon {...props} />,
    iconPath: <SettingsIconPath />,
    svgIconViewbox: "0 0 22.54 22.59"
  },
  {
    hideOnMobile: true,
    hideOnDesktop: true,
    name: "Refer & Earn",
    smallName: "Refer",
    href: '/refer',
    alternateHrefs: [],
    iconComponent: (props: {}) => <GiftIcon {...props} />,
    iconPath: <GiftIconPath />,
    svgIconViewbox: "0 0 18 18"
  },
  // {
  //   hideOnMobile: true,
  //   hideOnDesktop: true,
  //   name: "Help Center",
  //   alternateHrefs: [],
  //   iconComponent: (props: { color?: string, height?: string, width?: string }) => <LearningIndicatorIcon {...props} />,
  //   iconPath: <LearningIndicatorIconPath />,
  //   svgIconViewbox: "0 0 18 18",
  //   onClick: (e: any) => {
  //     e.preventDefault()
  //     Intercom.showHelpCenter()
  //   },
  // },
  {
    hideOnMobile: true,
    hideOnDesktop: true,
    name: "Contact Support",
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <SupportIcon {...props} />,
    iconPath: <SupportIconPath />,
    onClick: (e: any) => {
      e.preventDefault()
      showIntercom()
    },
    svgIconViewbox: "0 0 18 18"
  },
]


export interface NavProps {
  isHidden?: boolean
  loadedBrands: CurrentBrand[] | null
  user: UserData | null;
}

const Navbar = (props: NavProps) => {
  // Context and props extraction
  const location = useLocation();
  const { currentBrand, setCurrentBrand } = useContext(CurrentBrandContext) as {
    currentBrand: CurrentBrand,
    setCurrentBrand: Dispatch<SetStateAction<CurrentBrand>>
  };
  const { loadedBrands, isHidden = false, user } = props;
  const { organization } = useCurrentTeam();
  

  /** 
   * Determines if there's a valid organization associated with the current context
   * True if either:
   * 1. There's an active organization with a name from the team context
   * 2. The current brand is from admin search and has an associated organization
   */
  const hasOrganization = (organization && organization.name) || currentBrand?.isFromAdminSearch && currentBrand?.organization;
  
  // Device and UI state detection
  const { isMobile } = useScreenQueryDimensions();

  // Brand-related states
  /** ID of the currently selected brand */
  const brandId = currentBrand?.id;
  /** Indicates if the user's free trial has expired */
  const freeTrialOver = currentBrand?.freeTrialOver || false;
  /** Determines if the current user has pro-tier access */
  const isProUser = checkIfIsProTier(currentBrand);

  // Route-based visibility flags
  /** True when user is on the artist addition page */
  const isAddArtist = location.pathname === '/add-artist';
  /** True when on campaign creation or website editing routes */
  const isCampaignWizard = CAMPAIGN_WIZARD_ROUTES.includes(location.pathname) ||
    location.pathname.indexOf('/website/') > -1;
  /** True when mobile campaign view is active */
  const hideMobileBar = location.pathname.indexOf('/campaigns/') > -1;

  // Navbar visibility control
  /** Determines if the navbar should be hidden based on multiple conditions */
  const notShowNavbar = (isAddArtist && isMobile) ||
    isHidden ||
    user?.organization?.status === "waiting_for_details";

  // Side effects
  useEffect(() => {
    /** Set the brand ID in axios headers, falling back to localStorage if needed */
    const id = brandId || Number(localStorage.getItem("selected-brand"));
    Axios.defaults.headers.common["brand-id"] = id;
  }, [brandId]);

  useEffect(() => {
    /** Adjust main content padding when in special views */
    if (isCampaignWizard || isAddArtist || notShowNavbar) {
      document?.getElementById('main-content')?.classList.remove('lg:pl-14');
    }
  }, [isCampaignWizard, isAddArtist, notShowNavbar]);

  // Early return if navbar shouldn't be shown
  if (notShowNavbar || !currentBrand) return null;

  // Navigation links processing
  /** Get base navigation links based on user's pro status */
  let navLinks = getNavLinks(isProUser);

  /** Remove Fans tab if specified in brand settings */
  if (currentBrand?.hideFansTab) {
    navLinks = navLinks.filter((navItem: NavigationItem) => navItem.name !== "Fans");
  }

  /** Update Team visibility based on organization status */
  if (hasOrganization) {
    navLinks = navLinks.map(item =>
      item.name === "Team"
        ? { ...item, hideOnDesktop: false }
        : item
    );
  }
  return (
    <>
      {!isCampaignWizard && <>
        <DesktopNavigation
          user={user}
          currentBrand={currentBrand}
          navigationItems={navLinks}
          loadedBrands={loadedBrands}
          setCurrentBrand={setCurrentBrand} />
        {!hideMobileBar && (
          <MobileNavigation
            freeTrialOver={freeTrialOver}
            navigationItems={navLinks}
            isProUser={isProUser} />
        )}
      </>}
    </>
  )
}

export default Navbar
