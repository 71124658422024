import PrimaryButton from 'components/shareable/PrimaryButton';
import { FC, ReactNode, useState } from 'react';
import { useStyles } from './styles';
import { Body2, Caption } from 'components/shareable/Typography';
import { SystemColors } from 'types/globalStyles';

interface BusinessAccountDetailProps {
  descriptions: string[];
  title: string;
  icon: ReactNode;
  connected?: boolean;
  iconContainerColor: string;
  disabled?: boolean;
  onConnect: () => void;
}

export const BusinessAccountDetail: FC<BusinessAccountDetailProps> = ({
  descriptions,
  title,
  icon,
  connected,
  iconContainerColor,
  disabled,
  onConnect,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleConnect = async () => {
    setLoading(true);
    await onConnect();
    setLoading(false);
  };

  return (
    <div className={classes.detailContainer}>
      <div className='flex justify-between gap-4 w-full'>
        <div className='flex gap-2 items-center'>
          <div className={classes.detailIcon} style={{ backgroundColor: iconContainerColor }}>
            {icon}
          </div>
          <div className='flex flex-col gap-1s'>
            <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>{title}</Body2>
            {descriptions.map((description, index) => (
              <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>{description}</Caption>
            ))}
          </div>
        </div>
        <PrimaryButton
          text={connected ? 'Connected' : 'Use'}
          onClick={handleConnect}
          loading={loading}
          disabled={disabled}
        />
      </div>
    </div>
  );
};