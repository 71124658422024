import { StyledAnchor, StyledArticle, StyledBannerDetails, StyledChevronIcon, StyledDiv, StyledWarningIcon } from './styles';
import { Subtitle1, Body2, ButtonText, Subtitle2 } from 'components/shareable/Typography';
import { SystemColors } from 'types/globalStyles';
import Intercom from 'helpers/Intercom';

export interface SimpleBannerProps {
    title: string;
    description: string;
    ctaLink?: string;
    ctaText?: string;
    intercomArticleId?: string;
    supportMessage?: string;
    ctaComponent?: JSX.Element
}

export function FacebookCampaignSimpleBanner({
    title,
    description,
    ctaLink,
    ctaText,
    intercomArticleId,
    supportMessage,
    ctaComponent
}: SimpleBannerProps) {
    return (
        <StyledBannerDetails open>
            <summary className="flex justify-between cursor-pointer mb-2">
                <StyledDiv>
                    <StyledWarningIcon />
                    <Subtitle2 color={SystemColors.WARNING_COLOR}>
                        Issues detected
                    </Subtitle2>
                </StyledDiv>
            </summary>
            <StyledArticle displayBottomBorderLine={false}>
                <div className="flex flex-col gap-1">
                    <Subtitle1>{title}</Subtitle1>
                    <Body2>{description}</Body2>
                </div>
                {ctaComponent && ctaComponent}
                {ctaLink && (
                    <StyledAnchor href={ctaLink} target="_blank" rel="noreferrer">
                        <ButtonText>{ctaText}</ButtonText>
                    </StyledAnchor>
                )}
                {ctaLink && (
                    <StyledAnchor href={ctaLink} target="_blank" rel="noreferrer">
                        <ButtonText>{ctaText}</ButtonText>
                    </StyledAnchor>
                )}
                {intercomArticleId && (
                    <ButtonText onClick={() => Intercom.openArticleWithId(intercomArticleId)}>
                        {ctaText}
                    </ButtonText>
                )}
                {supportMessage && (
                    <Subtitle2 color={SystemColors.WARNING_COLOR}>{supportMessage}</Subtitle2>
                )}
            </StyledArticle>
        </StyledBannerDetails>
    );
}
