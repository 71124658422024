import { getCookie } from "analytics";
import { CurrentBrand, UserData } from "types/global";

const partnerMapper: {
    [key: string]: string
} = {
    'um-test-1': 'UM',
    'unitedmasters': 'UM',
    'um-select': 'UM',
    'unitedmasters-panel': 'UM',

    'audiomack-exclusive': 'Audiomack',

    'cdbaby-exclusive': 'CDBaby',
    'daily-playlists-exclusive': 'Daily Playlists',

    'downtown': 'Downtown',
    'downtown-exclusive': 'Downtown',

}

export const getPartnerDiscount: {
    [key: string]: {
        partnerName: string,
        partnerId: string,
        discountAmount: number,
        cookieExpirationFromSignup?: number,
        startDate?: string
    }
} = {
    'UM': {
        discountAmount: 0.75,
        partnerId: 'UM',
        partnerName: 'United Masters',
        cookieExpirationFromSignup: 90,
        startDate: '2023-09-26'
    },

    'Audiomack': {
        discountAmount: 0.75,
        partnerId: 'Audiomack',
        partnerName: 'Audiomack',
        cookieExpirationFromSignup: 90,
        startDate: '2023-11-14'
    },

    'Daily Playlists': {
        discountAmount: 0.75,
        partnerId: 'Daily Playlists',
        partnerName: 'Daily Playlists',
        cookieExpirationFromSignup: 90,
        startDate: '2024-10-29'
    },

    'CDBaby': {
        discountAmount: 0.75,
        partnerId: 'CDBaby',
        partnerName: 'CDBaby',
        cookieExpirationFromSignup: 90,
        startDate: '2023-12-10'
    },
    'Downtown': {
        discountAmount: 0.75,
        partnerId: 'Downtown',
        partnerName: 'Downtown',
        cookieExpirationFromSignup: 90,
        startDate: '2024-06-03'
    }
}

/**
 * Function to check if a user is a partner and get their affiliate details.
 * 
 * @param {Object} userData - The user data object.
 * @returns {Object|null} Returns the affiliate details if the user is a partner, otherwise returns null.
 */
export function isPartner(userData?: UserData | null | undefined) {
    if (!userData) return null
    if (userData.metadata && userData.metadata.affiliate_details) {
        return userData.metadata.affiliate_details;
    }
    return null;
}

/**
 * Function to check if a user is a partner and get their affiliate details.
 * 
 * @param {Object} userData - The user data object.
 * @returns {Object|null} Returns the affiliate details if the user is a partner, otherwise returns null.
 */
export function getBrandPartnerDetails(currentBrand?: CurrentBrand | null | undefined) {
    if (!currentBrand) return null
    if (currentBrand.affiliate_details) {
        return currentBrand.affiliate_details;
    }
    return null;
}