import {
  useEffect,
  useState,
  FunctionComponent,
  SetStateAction,
  Dispatch,
  useContext,
  useMemo,
  ChangeEvent,
} from "react"
import { TabPanel, useTabs } from "react-headless-tabs"
import MainContentContainer from "components/shareable/MainContentContainer"
import BudgetTab from "./BudgetTab"
import CampaignCreationPage from "./CampaignCreationPage"
import CreativeTab from "./CreativeTab"
import Finalization from "./FinalizationTab"
import { track } from "analytics"
import TargetingView from "./TargetingView"
import {
  CurrentBrand,
  FbSuggestion,
  CreatedCampaign,
  SavingDraftStatus,
  FBCustomAudience,
  FacebookPageResponse,
  ConversionsStatus,
  GeographicTargetingType,
  CurrencyCodesType,
  UserData,
} from "types/global"
import { scrollIntoView, scrollToTop } from "helpers/StyleUtils"
import LinkSelectorTab from "./LinkSelectorTab"
import dayjs from "dayjs"
import { useHistory, useLocation } from "react-router-dom"
import { InstagramPost } from "components/shareable/InstagramPosts/types"
import { useMediaQuery, useTheme } from "@material-ui/core"
import useStyles from "./styles"
import {
  getNewCampaignName,
  getWhenCreativeTabNextButtonIsDisabled,
  getIfTargetingSaveButtonIsDisabled,
  STEPS,
  getNextButtonLabel,
  getNextButtonWidth,
} from "./utils"
import startCase from "lodash/startCase"
import Grid from "@material-ui/core/Grid"
import CustomStepper from "components/shareable/CustomStepper"
import TopBar from "components/shareable/TopBar"
import { MarketingDataContext } from "../Data/Provider"
import { geographyTargetingDefaults, lowBudgetGeographyTargetingDefaults } from "../SongAds/TargetingView/data"
import { Option } from "./TargetingView/Components/AutoCompleteSpotifyArtists/utils"
import {
  CAMPAIGN_CREATED,
  CREATING_CAMPAIGN,
  GOAL,
  mainTitle,
} from "./constants"
import { ConnectModalsContext } from "Hooks/ConnectModalsProvider"
import clsx from "clsx"
import {
  AssetUploadTypes,
  getIfPagesAreSelected,
  UploadedAssetType,
} from "../utils/fbCampaigns"
import { getNotRemovableOptions, NOT_REMOVABLE } from "./TargetingView/Components/AutoCompleteAudiences/utils"
import uniqBy from "lodash/uniqBy"
import { checkIsValidUrl, fixGeographicTargets } from "utils"
import { useDraftDataGetter, useDraftDataSetter } from "./Hooks/DraftsHandlers"
import { LoadingIndicator } from "components/Loader"
import { LoadingContainer } from "./styles"
import SpendBudgetProvider from "Hooks/BudgetTabSpendContext"
import { CAMPAIGN_TYPE_ENUM, GEOGRAPHY_TARGETING_DEFAULTS, CAMPAIGN_TYPES as GLOBAL_CAMPAIGN_TYPES } from "../../../../constants"
import { getInitialTargetAudiences, getTargetingViewGenre } from "../Components/TargetingView/utils"
import { MobileAdPreviewBar } from "../SongAds/MobileAdPreviewBar"
import useMediaBreakpoints from "Hooks/useMediaBreakpoints"
import { CreativeAssetTypes } from "../constants"
import { getConvertedCurrency } from "services/symphonyApi"
import { CurrentBrandContext } from "../../../../Hooks/CurrentBrandContext"
import { ConnectionsContext } from "../hooks/ConnectionsContext"

const { LINK, CREATIVE, BUDGET, FINALIZATION } = STEPS
const { INITIAL_SAVING_STATE } = SavingDraftStatus
const tabs = [LINK, CREATIVE, BUDGET, FINALIZATION]
const { UPLOAD_VIDEO, INSTAGRAM_POSTS } = CreativeAssetTypes

interface Props {
  setGoal: Dispatch<SetStateAction<string | null>>
  refreshCampaigns: () => void
  reloadBrands: () => void
  currentUser: UserData
}

const LinkClicks: FunctionComponent<Props> = ({
  setGoal,
  refreshCampaigns,
  reloadBrands,
  currentUser
}: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const location = useLocation()
  const { mediumView } = useMediaBreakpoints()
  const { currentBrand } = useContext(CurrentBrandContext) as { currentBrand: CurrentBrand, reloadBrand: () => Promise<CurrentBrand> }

  const {
    facebookPage: selectedFB_page,
    instagramPage: selectedInstaPage,
    facebookAdAccount: selectedFBAdAccount,
    facebookPixel: selectedFBPixel,
    facebookBusinessManager: selectedFBBusinessManager,
    loggedInFbUser,
    areConnectionsValid: adConnectionsAdded,
    areTermsAccepted,
    conversionsEnabled,
    conversionsEventName,
    connectionsType,
    connectionsConfirmed: adConnectionsConfirmed,
    preConnectionConfigurations,
    validatingConnections,
    needsToReLogIn,
    setConnectionsConfirmed: setAdConnectionsConfirmed,
    setAreConnectionsValid: setAdConnectionsAdded,
    setConversionsEnabled,
  } = useContext(ConnectionsContext)

  const draft = new URLSearchParams(location.search).get("draft")
  const { data: connectModalData } = useContext(ConnectModalsContext)
  const { loading } = connectModalData
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const isNextButtonResponsive = useMediaQuery(theme.breakpoints.down(601))
  const isNextButtonMobile = useMediaQuery(theme.breakpoints.down(401))
  const isTargetingViewResponsive = useMediaQuery(theme.breakpoints.down(551))
  const history = useHistory()
  const {
    saveTargets,
    saveAudiences,
    saveCustomAudiences,
    saveAdditionalPageAudiences,
    saveArtists,
    saveDemographics,
    demographics,
  } = useContext(MarketingDataContext)

  const defaultGeographyTargeting = geographyTargetingDefaults(CAMPAIGN_TYPE_ENUM.link_clicks)
  const [targets, setTargets] = useState<GeographicTargetingType[]>(defaultGeographyTargeting)
  const [audiences, setAudiences] = useState<FbSuggestion[]>([])
  const [artists, setArtists] = useState<Option[]>([])
  const [totalBudget, setTotalBudget] = useState(Number(Object.values(targets).reduce((p, c) => p + c.budget, 0).toFixed(2)))
  const [customAudiences, setCustomAudiences] = useState<FBCustomAudience[]>([])
  const [additionalPageAudiences, setAdditionalPagesAudiences] = useState<FacebookPageResponse[]>([])
  const [isEditingTargeting, setIsEditingTargeting] = useState(false)
  const [selectedTab, setSelectedTab] = useTabs(tabs, LINK)
  const [finished, setFinished] = useState(false)
  const [recentlyCreatedCampaign, setRecentlyCreatedCampaign] = useState<CreatedCampaign | null>(null)
  const [showTargetingView, setShowTargetingView] = useState(false)
  const [fbSuggestions, setFbSuggestions] = useState<FbSuggestion[]>([])
  const [loadingInitialAudiences, setLoadingInitialAudiences] = useState(false)

  // This is primarily used for validation purposes
  // when connecting an IG
  const [selectedInstagramPost, setSelectedInstagramPost] = useState<InstagramPost>()
  const [addVisualSelected, setAddVisualSelected] = useState<AssetUploadTypes>(UPLOAD_VIDEO);
  const [currentLink, setCurrentLink] = useState<string>("")

  // organization
  const primaryOrganization = currentUser.organization

  // for video uploader & ad previews
  const [uploadedImages, setUpload] = useState<UploadedAssetType[] | null>(null)
  const [creativeSelectorTab, setCreativeSelectorTab] = useState<AssetUploadTypes>(UPLOAD_VIDEO)
  const [previewedVideo, setPreviewedVideo] = useState<UploadedAssetType | null>(null)

  useEffect(() => {
    if (!previewedVideo && uploadedImages && uploadedImages.length > 0
      || uploadedImages && uploadedImages.length === 1) {
      setPreviewedVideo(uploadedImages[0])
    }
  }, [uploadedImages])


  const [campaignName, setCampaignName] = useState<string>("")
  const [caption, setCaption] = useState<string>("")
  const [showPreview, enablePreviewMode] = useState<boolean>(false)

  // budgeting
  const [budgetValue, setBudgetValue] = useState<number>(0);
  const [currency, setCurrency] = useState<CurrencyCodesType>("USD");
  const [isBelowRecommendedBudget, setIsBelowRecommendedBudget] = useState<boolean>(false);

  // start and end date logistics
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [creatingCampaign, allowCampaignCreation] = useState(false)
  const [isBudgetTabNextButtonDisabled, setIsBudgetTabNextButtonDisabled] = useState(false)
  const [uploadQueueCreativeTab, setUploadQueueCreativeTab] = useState<Record<string, unknown>[] | null>(null)
  const [isSavingDraft, setIsSavingDraft] = useState(false)
  const [campaignDraftData, setCampaignDraftData] = useState<Record<string, unknown> | null>(null)
  const [retrievingDraftData, setRetrievingDraftData] = useState(!!draft)
  const [requiredDataChanged, setRequiredDataChanged] = useState(false)
  const [savingDraftStatus, setSavingDraftStatus] = useState(INITIAL_SAVING_STATE)
  const [isValidURL, setIsValidURL] = useState(false)

  const [cta, setCTA] = useState<{
    value: string
    label: string
  }>({ value: "LEARN_MORE", label: "Learn More" })

  // if the budget is less than the recommended budget
  // TODO: finalize actual recommended budget

  useEffect(() => {
    if (!previewedVideo && uploadedImages && uploadedImages.length > 0
      || uploadedImages && uploadedImages.length === 1) {
      setPreviewedVideo(uploadedImages[0])
    }
  }, [uploadedImages])

  function updatedCurrency(newCurrency: CurrencyCodesType) {
    setCurrency(newCurrency);
  }

  let recommendedUsdBudget = 100;
  const checkMinimumBudgetStatus = async () => {
    const convertedBudget = await getConvertedCurrency({ from: currency, to: "USD", amount: budgetValue });
    return convertedBudget < recommendedUsdBudget;
  }
  async function recheckIfBudgetOverRecommendation() {
    const belowRecommendedBudget = await checkMinimumBudgetStatus() || false
    setIsBelowRecommendedBudget(belowRecommendedBudget)
  }

  useEffect(() => {
    recheckIfBudgetOverRecommendation()
  }, [budgetValue, currency]);

  useEffect(() => {
    if (isBelowRecommendedBudget) {
      setTargets(lowBudgetGeographyTargetingDefaults);
      saveTargets(lowBudgetGeographyTargetingDefaults);
      // when the budget goes below recommended budget:
      // - default to the below recommended budget targets
      // - otherwise use the default targeting
    } else {
      setTargets(defaultGeographyTargeting);
      saveTargets(defaultGeographyTargeting);
    }
  }, [isBelowRecommendedBudget]);

  const showLoading = !!draft && retrievingDraftData

  const steps = Object.values(STEPS).map((value) => ({
    description: startCase(value),
    name: value,
    onClickHandler: (step: string) => () => {
      if (step === LINK) setSelectedTab(step)
    },
  }))

  const conversionsStatus = currentBrand?.conversionsStatus || {} as ConversionsStatus
  const isEventRegistered = conversionsStatus?.isEventRegistered

  const genres = currentBrand.connections?.spotify_artist_page?.genres
  const genre = getTargetingViewGenre(genres)

  let igMedia = undefined
  if (creativeSelectorTab === INSTAGRAM_POSTS || addVisualSelected === INSTAGRAM_POSTS) {
    igMedia = selectedInstagramPost
  }

  const uploadingAssets =
    !!uploadQueueCreativeTab && uploadQueueCreativeTab.length > 0

  const disableTargetingSaveButton = getIfTargetingSaveButtonIsDisabled(
    targets,
    totalBudget
  )

  const dataToSaveDraft = [
    currentLink,
    selectedFB_page,
    selectedFBAdAccount,
    selectedInstaPage,
    uploadedImages,
    selectedInstagramPost,
    caption,
    budgetValue,
    startDate,
    endDate,
    targets,
    audiences,
    customAudiences,
    additionalPageAudiences,
    selectedTab,
    cta,
    campaignName,
    demographics?.age?.min || null,
    demographics?.age?.max || null,
    demographics?.gender || null,
    demographics?.languages || null,
    addVisualSelected,
    conversionsEnabled,
    conversionsEventName,
    selectedFBPixel,
    selectedFBBusinessManager,
    loggedInFbUser,
  ]
  const notRemovableOptions = getNotRemovableOptions(genre)
  const initialTargetingAudiences = uniqBy([...notRemovableOptions, ...fbSuggestions], "id")
  const targetAudiences = !!audiences.length ? audiences : initialTargetingAudiences
  const fixedGeographicTargets = !!targets.length
    ? fixGeographicTargets(targets)
    : fixGeographicTargets(GEOGRAPHY_TARGETING_DEFAULTS)
  const fixedTargetingAudiences = targetAudiences
    .filter(({ id }) => !id?.includes(NOT_REMOVABLE))
    .map(({ id, name }) => ({
      fbId: id,
      name,
    }));
  const audiencesData = {
    geographicTargets: fixedGeographicTargets,
    interestTargetingAudiences: fixedTargetingAudiences,
  }

  const handleSaveTargetingData = () => {
    saveTargets(targets)
    saveAudiences(audiences || [])
    saveCustomAudiences(customAudiences || [])
    saveAdditionalPageAudiences(additionalPageAudiences || [])
    saveArtists(artists || [])
    setShowTargetingView(false)
  }

  const saveUploadQueueCreativeTab = (
    queue: Record<string, unknown>[] | null
  ) => {
    setUploadQueueCreativeTab(queue)
  }

  const saveWhenBudgetTabButtonIsDisabled = (isDisabled: boolean) => {
    setIsBudgetTabNextButtonDisabled(isDisabled)
  }

  const handleConfirmConnections = () => {
    track("Completed Campaign Step - Account Connections", {
      type: "increase_link_clicks",
      step: 2,
      name: "connect_accounts",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      addedFacebook: selectedFB_page ? true : false,
      addedInstagram: selectedInstaPage ? true : false,
    })

    setAdConnectionsAdded(true)
    setAdConnectionsConfirmed(true)
  }

  const handleClickContinueCreativeTab = () => {
    track("Completed Campaign Step - Creative Assets", {
      type: "increase_link_clicks",
      step: 3,
      name: "add_creatives",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      creative_assets: selectedInstagramPost ? 1 : uploadedImages?.length || 0,
      caption: caption,
      cta: typeof cta === "object" ? cta.value : cta,
    })

    setSelectedTab(BUDGET)
  }

  const handleClickCloseTopBar = () => {
    setGoal(null)
    history.push("/marketing")
  }

  const handleClickBackButton = () => {
    if (showTargetingView) {
      setShowTargetingView(false)
    } else if (selectedTab === STEPS.LINK) {
      history.push("/marketing")
    }  else if (selectedTab === CREATIVE) {
      setSelectedTab(tabs[tabs.indexOf(selectedTab as STEPS) - 1])
    }  else {
      setSelectedTab(tabs[tabs.indexOf(selectedTab as STEPS) - 1])
    }
  }

  const handleClickNextButton = () => {
    if (selectedTab === LINK && currentLink) {
      setSelectedTab(CREATIVE)
    } else if (selectedTab === CREATIVE) {
      if (adConnectionsAdded && adConnectionsConfirmed) {
        handleClickContinueCreativeTab()
      } else {
        handleConfirmConnections()
      }
    } else if (selectedTab === BUDGET) {
      setSelectedTab(FINALIZATION)
    }
  }

  const createdCampaign = (campaign: CreatedCampaign) => {
    setFinished(true)
    setRecentlyCreatedCampaign(campaign)
  }

  const handleLinkTabNextButton = () => {
    track("Completed Campaign Step - Link Selection", {
      type: "increase_link_clicks",
      step: 1,
      name: "add_link",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      link: currentLink,
    })

    setSelectedTab(CREATIVE)
  }

  const handleBudgetTabNextButton = () => {
    track("Completed Campaign Step - Budget", {
      type: "increase_link_clicks",
      step: 4,
      name: "set_budget",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      budget: budgetValue,
      start_date: dayjs(startDate).format("YYYY-MM-DD"),
      end_date: dayjs(endDate).format("YYYY-MM-DD"),
    })

    setSelectedTab(FINALIZATION)
  }

  useEffect(() => {
    if (draft && selectedTab !== LINK && (!adConnectionsAdded || !areTermsAccepted)) {
      setSelectedTab(CREATIVE)
      setConversionsEnabled(false)
    }
  }, [adConnectionsAdded, areTermsAccepted])

  useEffect(() => {
    getInitialTargetAudiences({
      genre,
      setFbSuggestions,
      setLoading: setLoadingInitialAudiences,
      access_token: loggedInFbUser?.access_token,
    })
  }, [])

  useEffect(() => {
    scrollToTop()
    scrollIntoView()
  }, [selectedTab, showTargetingView, adConnectionsConfirmed])

  useEffect(() => {
    if (conversionsEnabled && !conversionsEventName) {
      setSelectedTab(CREATIVE)
    }
  }, [conversionsEnabled])

  useEffect(() => {
    if (currentLink && currentLink.length > 0) {
      const newCampaignName = getNewCampaignName(currentLink)

      if (newCampaignName) setCampaignName(newCampaignName)
    } else {
      setSelectedTab(LINK)
    }
  }, [currentLink])

  useDraftDataGetter({
    setters: {
      setCurrentLink,
      setCaption,
      setCTA,
      setRetrievingDraftData,
      setBudgetValue,
      setSelectedTab,
      setCampaignDraftData,
      setTargets,
      setAudiences,
      saveDemographics,
      setSelectedInstagramPost,
      setUpload,
      setEndDate,
      setStartDate,
      setCustomAudiences,
      setAdditionalPagesAudiences,
      saveTargets,
      saveAudiences,
      saveArtists,
      setAddVisualSelected,
      setIsValidURL,
    } as Record<string, Dispatch<unknown>>,
  })

  const { cancelSavingDraft } = useDraftDataSetter({
    audiencesData,
    dataToSaveDraft: dataToSaveDraft as Record<string, unknown>[],
    requiredDataChanged,
    retrievingDraftData,
    currentLink,
    setters: {
      setRequiredDataChanged,
      setIsSavingDraft,
      setCampaignDraftData,
      setSavingDraftStatus
    } as Record<string, Dispatch<unknown>>,
    campaignDraftData,
    currentBrand,
    draft,
    savingDraftStatus,
  })

  // only allow ad preview to be shown on creative page
  const canShowAdPreview: boolean = useMemo(
    () => selectedTab === CREATIVE && adConnectionsAdded && adConnectionsConfirmed,
    [selectedTab, adConnectionsAdded, adConnectionsConfirmed]
  )

  const getArePagesSelected = () => {
    return getIfPagesAreSelected(
      connectionsType,
      {
        fbPixel: selectedFBPixel,
        fbAdAccount: selectedFBAdAccount,
        fbPage: selectedFB_page,
        instaPage: selectedInstaPage,
      },
      {
        fbPixel: false,
        fbAdAccount: true,
        fbPage: true,
        instaPage: false,
      }
    )
  }

  const getDisableNextButtonWhenCreativeTab = () => {
    const arePagesSelected = getArePagesSelected()
    return getWhenCreativeTabNextButtonIsDisabled({
      uploadedImages,
      adConnectionsAdded,
      adConnectionsConfirmed,
      uploadingAssets,
      selectedInstagramPost,
      arePagesSelected,
      preConnectionConfigurations,
      selectedCreativeType: creativeSelectorTab,
      cta,
      caption,
    })
  }

  const getDisableNextButton = () => {
    const disableForTargeting = showTargetingView && (disableTargetingSaveButton || isEditingTargeting)

    if (disableForTargeting) return true
    switch (selectedTab) {
      case LINK:
        return Boolean(!currentLink || (currentLink && !isValidURL))
      case CREATIVE:
        const disableNextButtonWhenCreativeTab = getDisableNextButtonWhenCreativeTab()
        const conditionsToDisable = [
          disableNextButtonWhenCreativeTab,
          selectedInstagramPost && selectedInstagramPost.explicit_content,
          conversionsEnabled && !conversionsEventName,
          selectedFBAdAccount && !areTermsAccepted,
          !selectedFBAdAccount || !selectedFB_page,
          needsToReLogIn,
        ]
        return conditionsToDisable.some(condition => Boolean(condition))
      case BUDGET:
        return isBudgetTabNextButtonDisabled
      case FINALIZATION:
        return !showTargetingView

      default:
        return false
    }
  }

  if (creatingCampaign || finished) {
    return (
      <>
        <TopBar title={finished ? CAMPAIGN_CREATED : CREATING_CAMPAIGN} />
        <CampaignCreationPage
          reloadBrands={reloadBrands}
          primaryOrganization={primaryOrganization ? primaryOrganization : undefined}
          draft={campaignDraftData?.id as string}
          link={currentLink}
          goal={GOAL}
          igMedia={igMedia}
          budget={budgetValue}
          startDate={startDate}
          endDate={endDate}
          assets={{ all: uploadedImages }}
          caption={caption}
          cta={cta}
          createdCampaign={createdCampaign}
          finished={finished}
          customAudiences={customAudiences}
          additionalPageAudiences={additionalPageAudiences}
          recentlyCreatedCampaign={recentlyCreatedCampaign}
          refreshCampaigns={refreshCampaigns}
          setSelectedTab={setSelectedTab}
          setFinished={setFinished}
          allowCampaignCreation={allowCampaignCreation}
          fbSuggestions={fbSuggestions}
        />
      </>
    )
  }

  return (
    <Grid
      className={
        selectedTab === CREATIVE && adConnectionsAdded
          ? classes.creativeTabHighMarginBottom
          : classes.normalMarginBottom
      }
    >
      <TopBar
        showSaveDraftChip={!!draft || !!currentLink}
        savingChanges={isSavingDraft}
        showCloseIcon
        title={mainTitle}
        handleClose={handleClickCloseTopBar}
      />
      <CustomStepper
        {...{ steps }}
        activeStep={steps.map(({ name }) => name).indexOf(selectedTab as STEPS)}
        stepButtonsProps={{
          handleClickBackButton,
          handleClickNextButton: showTargetingView
            ? handleSaveTargetingData
            : handleClickNextButton,
          showNextButton: !(selectedTab === FINALIZATION && !showTargetingView),
          disableNextButton: getDisableNextButton(),
          nextButtonLabel: getNextButtonLabel({
            isNextButtonMobile,
            showTargetingView,
            selectedTab,
          }),
          nextButtonWidth: getNextButtonWidth({
            isNextButtonMobile,
            isNextButtonResponsive,
            selectedTab,
          }),
          loadingNextButton: loading || validatingConnections,
        }}
        stepperButtonAmendments={
          <MobileAdPreviewBar
            {...{
              creativeSelectorTab,
              enablePreviewMode,
              previewedVideo,
              selectedInstagramPost,
              uploadedImages,
            }}
            show={mediumView && canShowAdPreview}
          />
        }
      >
        {showLoading && (
          <LoadingContainer>
            <LoadingIndicator color="black" height="80px" />
            <p className="text-center mt-4">Loading Campaign Draft...</p>
          </LoadingContainer>
        )}
        {!showLoading && (
          <MainContentContainer
            backgroundColor="transparent"
            className={clsx(
              classes.mainContainer,
              canShowAdPreview && classes.creativeTabContainer,
              `${selectedTab === CREATIVE && 'w-100 max-w-full flex gap-4 justify-center'}`
            )}>
            <Grid
              className={clsx(
                `h-full w-full rounded-${isMobile ? "none" : "xl"}`,
                showTargetingView && classes.mbWhenTargetingView,
                !showTargetingView && classes.mbWhenNotTargetingView,
                canShowAdPreview && classes.gridCreativeTabContainer,
              )}
            >
              {showTargetingView ? (
                <TargetingView
                  isBelowRecommendedBudget={isBelowRecommendedBudget}
                  deleteIconColor="#80f"
                  isResponsive={isTargetingViewResponsive}
                  showTrashIcon={showTargetingView}
                  setShowTargetingView={setShowTargetingView}
                  fbSuggestions={fbSuggestions}
                  loading={loadingInitialAudiences}
                  genre={genre}
                  handleArtists={(artists: Option[]) => setArtists(artists)}
                  handleAudiences={(audiences: FbSuggestion[]) => setAudiences(audiences)}
                  customAudiences={customAudiences}
                  setCustomAudiences={setCustomAudiences}
                  additionalPageAudiences={additionalPageAudiences}
                  setAdditionalPagesAudiences={setAdditionalPagesAudiences}
                  handleTargets={(targets: GeographicTargetingType[]) => setTargets(targets)}
                  handleTotalBudget={(budget: number) => setTotalBudget(budget)}
                  handleIsEditing={(isEditing: boolean) => setIsEditingTargeting(isEditing)}
                  showSaveButton={false}
                />
              ) : (
                <>
                  <div className="w-full">
                    <TabPanel hidden={selectedTab !== LINK}>
                      <LinkSelectorTab
                        currentLink={currentLink}
                        handleChangeLink={(e: ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value.trim()
                          const isValidURL = checkIsValidUrl(value)
                          setIsValidURL(isValidURL)
                          setCurrentLink(value)
                        }}
                        handleOnClickContinue={handleLinkTabNextButton}
                        showNextButton={false}
                      />
                    </TabPanel>
                    <TabPanel hidden={selectedTab !== CREATIVE}>
                      <CreativeTab
                        setPreviewedVideo={setPreviewedVideo}
                        previewedVideo={previewedVideo}
                        creativeSelectorTab={creativeSelectorTab}
                        setCreativeSelectorTab={setCreativeSelectorTab}
                        setAddVisualSelected={setAddVisualSelected}
                        selectedAssets={uploadedImages}
                        setSelectedAssets={setUpload}
                        caption={caption}
                        setCaption={setCaption}
                        cta={cta}
                        setCTA={setCTA}
                        selectedInstagramPost={selectedInstagramPost}
                        setSelectedInstagramPost={setSelectedInstagramPost}
                        saveUploadQueue={saveUploadQueueCreativeTab}
                        showContinueButton={false}
                        isEventRegistered={isEventRegistered}
                        selectedCampaignType={GLOBAL_CAMPAIGN_TYPES.link_clicks}
                        showPreview={showPreview}
                        enablePreviewMode={enablePreviewMode}
                      />
                    </TabPanel>
                    <SpendBudgetProvider
                      {...{ budgetValue }}
                      currentUser={currentUser}
                      step={selectedTab as string}
                      fbAccountId={selectedFBAdAccount?.id || null}
                      campaignType={GLOBAL_CAMPAIGN_TYPES.link_clicks}
                      access_token={loggedInFbUser?.access_token}
                    >
                      <TabPanel hidden={selectedTab !== BUDGET}>
                        <BudgetTab
                          updatedCurrency={updatedCurrency}
                          budgetValue={budgetValue}
                          setBudgetValue={setBudgetValue}
                          startDate={startDate}
                          setStartDate={setStartDate}
                          endDate={endDate}
                          setEndDate={setEndDate}
                          finishFlow={handleBudgetTabNextButton}
                          saveWhenNextButtonIsDisabled={saveWhenBudgetTabButtonIsDisabled}
                          showReviewButton={false}
                        />
                      </TabPanel>
                      <TabPanel hidden={selectedTab !== FINALIZATION}>
                        <Finalization
                          isBelowRecommendedBudget={isBelowRecommendedBudget}
                          budget={budgetValue}
                          startDate={startDate}
                          endDate={endDate}
                          setSelectedTab={setSelectedTab}
                          allowCampaignCreation={allowCampaignCreation}
                          setShowTargetingView={setShowTargetingView}
                          fbSuggestions={fbSuggestions}
                          selectedLink={currentLink}
                          goal={GOAL}
                          cta={cta}
                          caption={caption}
                          campaignName={campaignName}
                          assets={{ all: uploadedImages }}
                          igMedia={igMedia}
                          draft={campaignDraftData?.id}
                          addVisualSelected={addVisualSelected}
                          cancelSaving={cancelSavingDraft}
                          customAudiences={customAudiences}
                          additionalPageAudiences={additionalPageAudiences}
                          primaryOrganization={primaryOrganization}
                        />
                      </TabPanel>
                    </SpendBudgetProvider>
                  </div>
                </>
              )}
            </Grid>
          </MainContentContainer>
        )}
      </CustomStepper>
    </Grid>
  )
}

export default LinkClicks
