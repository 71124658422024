import {
  useStyles,
  RowFlexContainer,
  ConversionsDetailsContainer,
  ColumnFlexContainer,
  ConversionsHeaderContainer,
  ConversionsIconContainer,
  ConversionsContainer,
  ErrorBanner,
  ConversionsDataContainer,
} from "./styles";
import { ReactComponent as ConversionsIcon } from "assets/images/campaignDetails/Boost.svg";
import { ReactComponent as StarsIcon } from "assets/images/stars-icon.svg"
import { ReactComponent as ArrowRightIcon } from "assets/images/arrowRight.svg"
import { Body2, Caption, Subtitle1 } from "components/shareable/Typography";
import SwitchButton from "components/shareable/SwitchButton";
import { FunctionComponent } from "react";
import { useTheme } from "styled-components";
import { SystemColors } from "types/globalStyles";
import { ReactComponent as WarningIcon } from "assets/images/campaignDetails/warning.svg"
import SecondaryButton from "components/shareable/SecondaryButton";
import { useHistory } from "react-router-dom";
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";
import { Body } from "../HeaderModal/styles";
import LinkButton from "components/shareable/LinkButton";

interface Props {
  isConversionsEnabled?: boolean;
  conversionsEnabledDescription?: string;
  pixelId?: string;
  hasError?: boolean;
  title?: string;
  description?: string;
  adAccountId?: string;
  padding?: string;
  responsiveOnSmallView?: boolean;
  bottomDescription?: string;
  showAsRecommended?: boolean;
  showSwitchButton?: boolean;
  intercomButtonText?: string;
  toggleConversionsIndicator?: () => void;
  onIntercomClick?: () => void;
}


const ConversionsIndicator: FunctionComponent<Props> = ({
  isConversionsEnabled = false,
  conversionsEnabledDescription = '',
  pixelId,
  hasError,
  title,
  description,
  adAccountId,
  padding,
  responsiveOnSmallView,
  bottomDescription,
  showSwitchButton = true,
  showAsRecommended = true,
  intercomButtonText,
  toggleConversionsIndicator = () => { },
  onIntercomClick,
}: Props) => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const { smallView } = useMediaBreakpoints()

  const redirect = (url: string, taskName: string) => {
    history.push(url, { taskName });
  }

  const handleOnClickFixSetupError = () => {
    redirect("/settings?tab=ADVANCED_SETTINGS", "Setup Conversions API");
  };

  return (
    <ConversionsContainer
      padding={padding}
      borderRadius={responsiveOnSmallView && smallView ? "0px" : "12px"}
    >
      <ConversionsIconContainer>
        <ConversionsIcon />
      </ConversionsIconContainer>
      <ColumnFlexContainer gap='8px'>
        <ConversionsHeaderContainer>
          <ColumnFlexContainer>
            {showAsRecommended && !isConversionsEnabled && (
              <RowFlexContainer>
                <StarsIcon className={classes.svgPrimary} />
                <Caption>Recommended</Caption>
              </RowFlexContainer>
            )}
            <Subtitle1>{title || (isConversionsEnabled ? "Conversions Enabled" : "Enable Symphony Conversions")}</Subtitle1>
            {isConversionsEnabled ? (<Body2>{conversionsEnabledDescription}</Body2>) :
              (<Body2>{description || "Achieve 4-6x better results in your campaigns"}</Body2>)}
          </ColumnFlexContainer>
          {hasError ? (
            <RowFlexContainer gap='8px'>
              <ErrorBanner>
                <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>Issues detected</Body2>
                <WarningIcon className={classes.svgError} width={24} height={24} />
              </ErrorBanner>
              <SecondaryButton
                text="Fix"
                onClick={handleOnClickFixSetupError}
                height="48px"
                error
              />
            </RowFlexContainer>
          ) : showSwitchButton && (
            <SwitchButton
              checked={isConversionsEnabled}
              onChange={toggleConversionsIndicator}
              disabled={!pixelId || !adAccountId}
            />
          )}
        </ConversionsHeaderContainer>
        <ConversionsDetailsContainer>
          <ConversionsDataContainer
            flexDirection={responsiveOnSmallView ? "column" : "row"}
          >
            {pixelId ? (
              <RowFlexContainer>
                <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                  Facebook Pixel:
                </Caption>
                <Caption color={SystemColors.PRIMARY_TEXT_COLOR}>
                  {pixelId}
                </Caption>
              </RowFlexContainer>
            ) : (
              <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                Your Facebook Pixel is not connected
              </Caption>
            )}
            {pixelId && adAccountId && (
              <RowFlexContainer>
                <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                  Facebook Ad Account:
                </Caption>
                <Caption color={SystemColors.PRIMARY_TEXT_COLOR}>
                  {adAccountId}
                </Caption>
              </RowFlexContainer>
            )}
          </ConversionsDataContainer>
        </ConversionsDetailsContainer>
        {(bottomDescription || onIntercomClick) && (
          <Body>
            {bottomDescription && <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>{bottomDescription}</Body2>}
            {onIntercomClick && (
              <LinkButton
                showInline
                text={intercomButtonText || "Learn how"}
                onClick={onIntercomClick}
              />
            )}
          </Body>
        )}
      </ColumnFlexContainer>
    </ConversionsContainer>
  );
};

export default ConversionsIndicator;