import { Dispatch, SetStateAction } from "react";
import Axios from "helpers/Interceptor";
import { CampaignDetails, EmailGrowth, SpotifyGrowth } from "types/global";
import moment from "moment"
import { FB_CAMPAIGN_TYPES } from "../../../constants";
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

export const getCampaignDetails = async (id: string, access_token?: string) => {
  try {
    const path = `/campaign/${id}`;
    const response = await Axios.get(path);
    const data = response?.data?.data;
    if (!data) return null;

    const isFbCampaign = FB_CAMPAIGN_TYPES.includes(
      data.campaign_metadata.campaign_type
    );
    if (!isFbCampaign || !access_token) return data;
    const brandId = data.campaign_metadata.campaign_state?.brand?.id;
    try {
      const updatedData = await Axios.put(
        `campaign/${id}/facebook-ad-media`,
        {
          brandId,
          access_token,
        }
      );
      const { media_url, owner, preview_shareable_link, thumbnail_url } = updatedData.data.data;
      data.campaign_metadata.creative.media_url = media_url;
      data.campaign_metadata.creative.thumbnail_url = thumbnail_url;
      data.campaign_metadata.creative.owner = owner;
      data.campaign_metadata.creative.preview_shareable_link = preview_shareable_link;
      return data;
    } catch (e) {
      return data;
    }
  } catch (error) {
    //TODO: Add error handler
    console.log('Error on Playlisting/CampaignDetails component: ', error);
  }
};

export const getSpotifyGrowth = async (startDate: string, endDate: string, brandId: number): Promise<SpotifyGrowth | null> => {
  try {
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD")
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD")
    const path = `/dashboard/brand/${brandId}/fanbase/spotify/followers?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    const response = await Axios.get(path);
    if (response.data && response.data.data) {
      const data = response?.data?.data;
      return data as SpotifyGrowth
    }

    return null
  } catch (error) {
    console.log("Error on fetching spotify growth: ", error);
    return null
  }
}

export const getEmailsGrowth = async (startDate: string, endDate: string, brand: string, name: string): Promise<EmailGrowth | null> => {
  try {
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD")
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD")
    const path = `/dashboard/brand/${brand}/content/${name}/email/adds?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    const response = await Axios.get(path);
    const data = response?.data?.data;
    return data as EmailGrowth
  } catch (error) {
    console.log("Error on fetching emails growth: ", error);
    return null
  }
}

export const getDemographics = async (brand: string, name: string) => {
  try {
    const path = `/brand-content/${brand}/${name}/presaves/demographics`;
    const response = await Axios.get(path);
    const data = response?.data?.data;
    return data
  } catch (error) {
    console.log("Error on fetching demographics: ", error);
  }
}

export const getPresaveDailyStats = async (brand: string, name: string, startDate?: string | null, endDate?: string | null) => {
  try {
    const path = `/brand-content/${brand}/${name}/presaves/daily`;
    const userTimezone = dayjs.tz.guess()
    const response = await Axios.get(path, {
      params: {
        startDate,
        endDate,
        timezone: userTimezone
      }
    });

    const data = response?.data?.data;
    return data
  } catch (error) {
    console.log("Error on fetching daily pre-saves: ", error);
  }
}

export const getAudience = async (brand: string, name: string, currentOrPreviousRelease: 'current' | 'previous', limit: number, offset: number, orderBy?: string, direction?: string) => {
  try {
    const path = `/brand-content/${brand}/${name}/presaves/audience`;
    const response = await Axios.get(path, {params: {currentOrPreviousRelease, limit, offset, orderBy, direction}});
    const data = response?.data?.data;
    return data
  } catch (error) {
    console.log("Error on fetching audience: ", error);
  }
}

export const getGeographies = async (brand: string, name: string, type = 'country') => {
  try {
    const path = `/brand-content/${brand}/${name}/presaves/geographies/${type}`;
    const response = await Axios.get(path);
    const data = response?.data?.data;
    return data
  } catch (error) {
    console.log("Error on fetching geographies: ", error);
  }
}
