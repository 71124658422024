import styled from "styled-components";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(0.5),
  },
  colorPrimary: {
    color: "#80f",
  },
}));

interface MainContainerProps {
  maxWidth?: string;
}

interface ClickableLabelProps {
  cursor?: string;
}

interface IconProps {
  cursor?: string;
}

export const MainContainer = styled.div`
  margin-top: 40px;
  max-width: ${({ maxWidth }: MainContainerProps) => maxWidth || "380px"};
`;

export const ClickableLabel = styled.label`
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #707070;
  border-style: dashed;
  padding: 16px;
  cursor: ${({ cursor }: ClickableLabelProps) => cursor};
`;

export const UploadLabel = styled.span`
  cursor: pointer;
  color: #80f;
`;

export const TrashCanIconContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
  width: 24px;
  height: 24px;
`;

export const HiddenInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

export const UploadIconContainer = styled.div`
  width: 24px;
`;

export const UploadLabelContainer = styled.div`
  width: 100%;
  padding-left: 16px;
`;

export const ReloadIconContainer = styled.label`
  padding-right: 16px;
  padding-left: 4px;
`;

export const Icon = styled.img`
  cursor: ${({ cursor }: IconProps) => cursor};
`;

export default useStyles;
