import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json";
import { ReactComponent as ProfileIcon } from "assets/images/profileIcon.svg";
import StarsIcon from "assets/images/stars-icon.svg";
import { ButtonText } from "components/shareable/Typography";
import { MainContainer } from "components/shareable/UpgradeToProChip/styles";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { OutlinedButton, StyledLabel, SymphonyTooltip } from "styles/shared";
import { SystemColors } from "types/globalStyles";

import { RowArtistItemProps } from "../interfaces";
import { IconStyled, NotArtistContainer, useStyles } from "../styles";
import { OrganizationBrand } from "types/global";
import { addBrandToOrganization, listExistingArtists } from "services/symphonyApi/organizationService";

function RowArtistItem({ artist, onComplete }: RowArtistItemProps) {
  const classes = useStyles();

  const [loadingAddArtist, setLoadingAddArtist] = useState(false);

  const handleAddArtist = async (item: OrganizationBrand) => {
    try {
      if (!item.slug) return;
      setLoadingAddArtist(true);
      await addBrandToOrganization(item.slug);
      onComplete();
    } catch (error) {
    } finally {
      setLoadingAddArtist(false);
    }
  };

  const proItem = (
    <MainContainer gap={4} alignItems="center" width="fit-content">
      <IconStyled alt="stars-icon.svg" src={StarsIcon} />
      <StyledLabel
        fontSize={14}
        fontWeight={480}
        color={SystemColors.WHITE_TEXT_COLOR}
      >
        PRO
      </StyledLabel>
    </MainContainer>
  );

  return (
    <li
      key={artist.id}
      className="cursor-pointer sy-card flex items-center justify-between p-3"
    >
      <div className="flex items-center space-x-4" style={{ flex: 1 }}>
        {artist.profile_picture ? (
          <img
            src={artist.profile_picture}
            alt="platform icon"
            className={classes.imgStylesUserCircle}
          />
        ) : (
          <ProfileIcon
            className={classes.imgStylesUserCircle}
            style={{
              backgroundColor: SystemColors.SECONDARY_TEXT_COLOR,
            }}
          />
        )}

        <div className="flex flex-row justify-center">
          <h5 className="text-dark basis-1/4 mb-1">{artist.name}</h5>
          {artist.isPro && (
            <SymphonyTooltip
              className="basis-1/4 ml-1"
              title="This is a Pro-exclusive feature."
              arrow
            >
              {proItem}
            </SymphonyTooltip>
          )}
        </div>
      </div>

      <div className="flex justify-end" style={{ flex: "0 0 auto" }}>
        <OutlinedButton onClick={() => handleAddArtist(artist)}>
          {loadingAddArtist ? (
            <Lottie
              height={24}
              width={24}
              options={{
                loop: true,
                autoplay: true,
                animationData: SymphonyLoadingLottie,
              }}
            />
          ) : (
            <ButtonText>{"Add to Team"}</ButtonText>
          )}
        </OutlinedButton>
      </div>
    </li>
  );
}

export function ExistingProfiles({
  onComplete,
  handleChangeCount,
}: {
  handleChangeCount: (count: number) => void;
  onComplete: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [listArtistExisting, setListArtistExisting] = useState<
    OrganizationBrand[]
  >([]);

  const handleSearch = async () => {
    try {
      setIsLoading(true);
      const artistList = await listExistingArtists();
      setListArtistExisting(artistList);
      handleChangeCount(artistList?.length || 0);
    } catch (error) {
      // handle error
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const hasExistingArtists = listArtistExisting?.length > 0;

  return (
    <div>
      {hasExistingArtists ? (
        <p className="mb-5">
          You have access to the following profiles, but they aren't managed by your Team:
        </p>
      ) : null}
      {!isLoading ? (
        <ul className="add-new border-b mb-5 max-h-80 overflow-auto">
          {hasExistingArtists ? (
            <>
              {listArtistExisting.map((artistItem) => {
                return (
                  <RowArtistItem
                    key={artistItem.id}
                    artist={artistItem}
                    onComplete={onComplete}
                  />
                );
              })}
            </>
          ) : (
            <NotArtistContainer>
              You don't have access to any others profiles.
            </NotArtistContainer>
          )}
        </ul>
      ) : (
        <Box sx={{ width: 300 }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      )}
    </div>
  );
}
