import { Avatar, Chip, InputAdornment, Slider, TextField, makeStyles } from "@material-ui/core";
import { ArtistInputContainer, BackArrowImage, BackButtonContainer, BackButtonText, BackToDashboardContainer, EnterArtistContainer, EnterArtistLabel, FlexContainer, LeftColumn, LogoContainer, LottieContainer, MainTitle, PlatformLogoContainer, RightColumn, SubTitle, Titles } from "../../style";
import Lottie from "react-lottie";
import GetStartedLottie from 'assets/images/lotties/GetStarted.json';
import GrayArrow from "assets/images/arrow-right-icon.svg"
import { ReactComponent as SymphonyLogo } from 'assets/images/logo.svg';
import HappyFace from './images/high-bar.svg';
import NeutralFace from './images/medium-bar.svg';
import SadFace from './images/none-bar.svg';

import { Container } from "styles/shared";
import OptionCard from "./OptionCard";
import CreatorAccountTypeIcon from "./images/creator-account-type.png"
import TeamAccountTypeIcon from "./images/team-account-type.png"
import { Headline1, Headline2, Headline3 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import { MARKETING_GOALS, MarketingGoalType, OnboardingAccountTypes } from "../..";
import MarketingSelectorVertical, { MarketingSelectorOption } from "./MarketingSelectorVertical";
import { Theme } from "@mui/material";
import PrimaryButton from "components/shareable/PrimaryButton";
import { CSSProperties, useState } from "react";
import { DataGoal, MerchGoal, MusicGoal, SocialsGoal, TicketsGoal, UnderstandAnalyticsGoal, VideoGoal } from "./images/goal-icons";
import { useStyles } from "./styles";
import PrimaryButtonContainer from "./PrimaryButtonContainer";
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";


export default function MarketingGoals(props: {
    showBackButton: boolean,
    handleGoBack: () => void,
    accountType?: OnboardingAccountTypes | null,
    handleSelected: (selectedGoals: MarketingGoalType[]) => void,
    multiSelect?: boolean
}) {

    const {
        showBackButton,
        handleGoBack,
        accountType,
        handleSelected
    } = props;

    const { mobileView } = useMediaBreakpoints();
    const classes = useStyles();
    const [selectedGoals, setSelectedGoals] = useState<MarketingGoalType[]>([]);

    const MARKETING_GOALS_DATA: Record<string, MarketingGoalType> = {
        [MARKETING_GOALS.GROW_STREAMS]: {
            type: MARKETING_GOALS.GROW_STREAMS,
            title: accountType === 'creator' ? "Grow My Music" : "Grow Music Streams"
        },
        [MARKETING_GOALS.COLLECT_DATA]: {
            type: MARKETING_GOALS.COLLECT_DATA,
            title: accountType === 'creator' ? "Grow My Email List & Fan Data" : "Grow Email Lists & Fan Data"
        },
        // [MARKETING_GOALS.LINK_IN_BIO]: {
        //     type: MARKETING_GOALS.LINK_IN_BIO,
        //     title: "Grow My Videos"
        // },

        [MARKETING_GOALS.GROW_SOCIALS]: {
            type: MARKETING_GOALS.GROW_SOCIALS,
            title: accountType === 'creator' ? "Grow My Social Following" : "Grow Socials"
        },
        [MARKETING_GOALS.SELL_TICKETS]: {
            type: MARKETING_GOALS.SELL_TICKETS,
            title: "Sell More Tickets"
        },
        [MARKETING_GOALS.SELL_MERCH]: {
            type: MARKETING_GOALS.SELL_MERCH,
            title: "Sell More Merch"
        },
        [MARKETING_GOALS.UNDERSTAND_DATA]: {
            type: MARKETING_GOALS.UNDERSTAND_DATA,
            title: accountType === 'creator' ? "Understand My Analytics" : "Understand Analytics"
        },
    };

    const getImageForGoal = (goal: MarketingGoalType, selected: boolean) => {
        switch (goal.type) {
            case MARKETING_GOALS.TRACK_DATA:
                return <VideoGoal
                    isSelected={Boolean(selected)} />
            case MARKETING_GOALS.COLLECT_DATA:
                return <DataGoal
                    isSelected={Boolean(selected)} />

            case MARKETING_GOALS.GROW_STREAMS:
                return <MusicGoal
                    isSelected={Boolean(selected)} />
            case MARKETING_GOALS.GROW_SOCIALS:
                return <SocialsGoal
                    isSelected={Boolean(selected)} />
            case MARKETING_GOALS.SELL_TICKETS:
                return <TicketsGoal
                    isSelected={Boolean(selected)} />
            case MARKETING_GOALS.SELL_MERCH:
                return <MerchGoal
                    isSelected={Boolean(selected)} />
            case MARKETING_GOALS.UNDERSTAND_DATA:
                return <UnderstandAnalyticsGoal
                    isSelected={Boolean(selected)} />
            default:
                return <VideoGoal
                    isSelected={Boolean(selected)} />
        }
    }

    function handleChipSelected(option: MarketingGoalType) {
        if (!props.multiSelect) {
            setSelectedGoals([option]);
            return;
        } else {
            const isSelected = selectedGoals.find(goal => goal.type === option.type);
            if (isSelected) {
                setSelectedGoals(selectedGoals.filter(goal => goal.type !== option.type));
            } else {
                setSelectedGoals([...selectedGoals, option]);
            }
        }
    }

    const goalOptions = Object.keys(MARKETING_GOALS_DATA).map(key => MARKETING_GOALS_DATA[key as MARKETING_GOALS]);
    return (
        <>
            <LeftColumn width={mobileView ? "100%" : "50%"} isMobile={mobileView}>
                {showBackButton && mobileView &&
                    <BackToDashboardContainer>
                        <BackButtonContainer onClick={handleGoBack}>
                            <BackArrowImage src={GrayArrow} />
                        </BackButtonContainer>
                    </BackToDashboardContainer>
                }
                <LogoContainer>
                    <SymphonyLogo height={'30px'} />
                </LogoContainer>
                <Titles gap={mobileView ? "8px" : "18px"}>
                    {showBackButton && !mobileView &&
                        <BackToDashboardContainer>
                            <BackButtonContainer onClick={handleGoBack}>
                                <BackArrowImage src={GrayArrow} />
                            </BackButtonContainer>
                        </BackToDashboardContainer>
                    }
                    <MainTitle>What's your main goal at the moment?</MainTitle>
                    <SubTitle>Symphony can help with all of these, and we'll focus our recommendations on helping you accomplish this goal.</SubTitle>
                </Titles>
                <Container width="100%" display="flex" gap="18px" flexWrap="wrap" paddingBottom={mobileView ? "72px" : undefined}>
                    {goalOptions.map((option, index) => {
                        const selected = selectedGoals.find(goal => goal.type === option.type);
                        return (
                            <Chip
                                className={`${selected ? 'selected' : ''} ${classes.chip}`}
                                icon={getImageForGoal(option, Boolean(selected))}
                                variant="outlined"
                                onClick={() => {
                                    handleChipSelected(option);

                                }}
                                label={option.title}
                            />
                        )
                    })}


                </Container>

                <PrimaryButtonContainer>

                    <PrimaryButton
                        width={mobileView ? "90%" : "300px"}
                        text="Continue"
                        disabled={selectedGoals.length === 0}
                        onClick={() => {
                            handleSelected(selectedGoals)
                        }} />
                </PrimaryButtonContainer>

            </LeftColumn>
            <RightColumn display={mobileView ? 'none' : 'flex'}>
                <LottieContainer>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: GetStartedLottie,
                        }}
                    />
                </LottieContainer>
            </RightColumn>
        </>
    )
}