import React, { useContext } from 'react';
import {
    BillingChipContainer,
} from '../../styles'; // Adjust the import path as necessary
import { BillingCycle } from '../../utils';
import { BillingChip } from '../BillingChip';
import { DEFAULT_CURRENCY } from 'pages/post-auth/MarketingPage/constants';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import { CurrencyCodesType } from 'types/global';

type BillingPlanOptionsProps = {
    mobileView?: boolean;
    billingCycle: BillingCycle;
    partnerDiscount?: number | undefined;
    setBillingCycle: (billingCycle: BillingCycle) => void;
    specificCurrencyCode?: CurrencyCodesType;
};

const BillingPlanOptions: React.FC<BillingPlanOptionsProps> = ({
    mobileView,
    billingCycle,
    setBillingCycle,
    partnerDiscount,
    specificCurrencyCode
}) => {

    const { currentBrand } = useContext(CurrentBrandContext)


    const handleChangeBillingCycle = (billingCycle: BillingCycle) => () => {
        // ...your logic to change billing cycle
        setBillingCycle(billingCycle);
    };


    const { ANNUALLY, MONTHLY } = BillingCycle;

    const currencyCode = specificCurrencyCode ? specificCurrencyCode : (currentBrand?.currency?.code || DEFAULT_CURRENCY);

    return (
        <BillingChipContainer
            isMobileView={mobileView}
            flexDirection={'column'}
        >
            <BillingChip
                discountPercentage={partnerDiscount}
                isMobileView={mobileView}
                cycle={MONTHLY}
                isActive={billingCycle === MONTHLY}
                onClick={handleChangeBillingCycle(MONTHLY)}
                currency={currencyCode}
            />
            <BillingChip
                isMobileView={mobileView}
                cycle={ANNUALLY}
                isActive={billingCycle === ANNUALLY}
                onClick={handleChangeBillingCycle(ANNUALLY)}
                currency={currencyCode}
            />
        </BillingChipContainer>
    );
};

export default BillingPlanOptions;
