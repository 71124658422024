import { CSSProperties, useEffect, useState } from "react";
import { InputAdornment, Slider, TextField, makeStyles } from "@material-ui/core";
import { ArtistInputContainer, BackArrowImage, BackButtonContainer, BackButtonText, BackToDashboardContainer, EnterArtistContainer, EnterArtistLabel, FlexContainer, LeftColumn, LogoContainer, LottieContainer, MainTitle, PlatformLogoContainer, RightColumn, SubTitle, Titles } from "../../style";
import Lottie from "react-lottie";
import GetStartedLottie from 'assets/images/lotties/GetStarted.json';
import GrayArrow from "assets/images/arrow-right-icon.svg"
import { ReactComponent as SymphonyLogo } from 'assets/images/logo.svg';
import HappyFace from './images/high-bar.svg';
import NeutralFace from './images/medium-bar.svg';
import SadFace from './images/none-bar.svg';

import { Container } from "styles/shared";
import OptionCard from "./OptionCard";
import CreatorAccountTypeIcon from "./images/creator-account-type.png"
import TeamAccountTypeIcon from "./images/team-account-type.png"
import { Headline1, Headline2, Headline3 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import { OnboardingAccountTypes } from "../..";
import MarketingSelectorVertical, { MarketingSelectorOption } from "./MarketingSelectorVertical";
import { Theme } from "@mui/material";
import PrimaryButton from "components/shareable/PrimaryButton";
import { useStyles } from "./styles";
import PrimaryButtonContainer from "./PrimaryButtonContainer";
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";

export default function TeamsTotalCreators(props: {
    showBackButton: boolean,
    handleGoBack: () => void,
    handleSelected: (option: {
        value: number;
        label: string;
    }) => void,
}) {

    const {
        showBackButton,
        handleGoBack,

        handleSelected
    } = props;

    const { mobileView } = useMediaBreakpoints();

    const classes = useStyles();

    const [selectedValue, setSelectedValue] = useState<number>(0);

    const [userChangedValue, setUserChangedValue] = useState<boolean>(false);

    const marks: {
        value: number;
        label: string;
    }[] = [
            {
                value: 0,
                label: '1-2',
            },
            {
                value: 1,
                label: '3-5',
            },
            {
                value: 2,
                label: '6-10',
            },
            {
                value: 3,
                label: '10+',
            },
        ];



    const selectedItem = marks.find(item => item.value === selectedValue)
    return (
        <>
            <LeftColumn width={mobileView ? "100%" : "50%"} isMobile={mobileView}>
                {showBackButton && mobileView &&
                    <BackToDashboardContainer>
                        <BackButtonContainer onClick={handleGoBack}>
                            <BackArrowImage src={GrayArrow} />
                        </BackButtonContainer>
                    </BackToDashboardContainer>
                }
                <LogoContainer>
                    <SymphonyLogo height={'30px'} />
                </LogoContainer>
                <Titles gap={mobileView ? "8px" : "18px"}>
                    {showBackButton && !mobileView &&
                        <BackToDashboardContainer>
                            <BackButtonContainer onClick={handleGoBack}>
                                <BackArrowImage src={GrayArrow} />
                            </BackButtonContainer>
                        </BackToDashboardContainer>
                    }
                    <MainTitle>How many brands or creators do you usually work with?</MainTitle>
                    <SubTitle>This will help our team onboard you in the best way.</SubTitle>
                </Titles>
                <Container width={mobileView ? "85%" : "100%"}
                    margin={mobileView ? 'auto' : 'inherit'}
                    display={mobileView ? 'flex' : 'block'}
                    flexDirection={mobileView ? 'column' : 'unset'}
                    justifyContent={mobileView ? 'center' : 'unset'}
                    alignItems={mobileView ? 'center' : 'unset'}

                >

                    <Headline1>{!userChangedValue ? 'Slide to select.' : (selectedItem ? selectedItem.label : '')}</Headline1>

                    <Slider
                        onChange={(event: any, value: any) => {
                            setUserChangedValue(true);
                            setSelectedValue(value as number)
                        }}
                        className={classes.ageSlider}
                        defaultValue={0}
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="off"
                        marks={marks}
                        step={1}
                        min={0}
                        max={3}
                        value={selectedValue}
                    />

                </Container>


                <PrimaryButtonContainer>
                    <PrimaryButton
                        text="Continue"
                        width={mobileView ? "100%" : "300px"}
                        onClick={() => {
                            const foundItem = marks.find(item => item.value === selectedValue);
                            handleSelected(foundItem!)
                        }} />
                </PrimaryButtonContainer>

            </LeftColumn>
            <RightColumn display={mobileView ? 'none' : 'flex'}>
                <LottieContainer>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: GetStartedLottie,
                        }}
                    />
                </LottieContainer>
            </RightColumn>
        </>
    )
}