import { Container, OutlinedButton, StyledLabel } from "styles/shared";
import { redirectToOrganizationCheckout } from "../controllers";
import { BillingCycle, FeatureType } from "components/shareable/UpgradeToProModal/utils";
import { CurrentBrand, PaymentMethods } from "types/global";
import UpgradeToProLabel from "components/shareable/UpgradeToProLabel";
import { Body2, Caption, Headline1, Subtitle2 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import PrimaryButton from "components/shareable/PrimaryButton";
import { BillingChipContainer, Feature, FeatureIcon, FeatureList, TextContainer } from "components/shareable/UpgradeToProModal/styles";
import { BillingChip } from "components/shareable/UpgradeToProModal/Components/BillingChip";
import { SimpleBillingChip } from "components/shareable/UpgradeToProModal/Components/BillingChip/simple";
import { ReactComponent as AutomatedIcon } from "assets/images/automated.svg"
import { ReactComponent as MoneyIcon } from "assets/images/money-purple.svg"
import { ReactComponent as GlobeIcon } from "assets/images/globe.svg"

import { useState } from "react";
import { TEAM_PLAN_INCLUDED_SEATS } from "../../../../constants";

export const getFeatures = (): Array<any> => [
    {
        icon: GlobeIcon,
        feature: `${TEAM_PLAN_INCLUDED_SEATS} Pro profiles included at a 10% discount`,
        caption: `Each Team plan comes with ${TEAM_PLAN_INCLUDED_SEATS} Pro accounts by default. You can upgrade any existing or new profile.`,
    },
    {
        icon: AutomatedIcon,
        feature: "Unlock more Pro profiles at a 25% discount",
        caption: `$15 / month instead of $20 / month, per Pro profile when you have the Team plan.`,
    },
    {
        icon: AutomatedIcon,
        feature: "3 user invites included across all of your Pro profiles",
        caption: `$5 / month for any additional users you invite via email`,
    },
    {
        icon: MoneyIcon,
        feature: "Centralize your billing",
        caption: `Handle invoices across all of your accounts under 1 payment method.`,
    }
]

export default function OrganizationZeroState(props: {
    currentBrand: CurrentBrand
}) {

    const [loadingCheckout, setLoadingCheckout] = useState(false)

    async function handleStartClick() {
        setLoadingCheckout(true)
        await redirectToOrganizationCheckout({
            recurring_interval: BillingCycle.MONTHLY,
            payment_method: PaymentMethods.STRIPE
        }, props.currentBrand.slug)
    }
    return (
        <Container display="flex" flexDirection="column" gap="8px">
            <UpgradeToProLabel
                maxWidth="210px"
                color="PURPLE"
                label="Symphony for Teams"
            />
            <Headline1 color={SystemColors.PRIMARY_TEXT_COLOR}>
                Scale your marketing with Symphony's Team Plan.</Headline1>

            <Subtitle2
                color={SystemColors.PRIMARY_TEXT_COLOR}
            >
                Save up to 25% on multiple Pro profiles, with cost savings + better tools to manage your entire roster.
            </Subtitle2>
            <BillingChipContainer>
                <SimpleBillingChip
                    isActive={true}
                    onClick={console.log}
                    currency="USD"
                    monthlyPrice={55.00} />
            </BillingChipContainer>
            <Subtitle2>What’s included?</Subtitle2>
            <FeatureList
                marginTop={false ? '12px' : null}>
                {getFeatures().map(
                    ({ icon: Icon, feature, caption, className }, index) => (
                        <Feature key={`upgrade-to-pro-modal-feature-${index}`}>
                            <FeatureIcon>
                                <Icon {...{ className }} />
                            </FeatureIcon>
                            <TextContainer>
                                <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                                    {feature}
                                </Body2>
                                <Caption
                                    color={SystemColors.SECONDARY_TEXT_COLOR}>
                                    {caption}
                                </Caption>
                            </TextContainer>
                        </Feature>
                    )
                )}
            </FeatureList>

            <PrimaryButton
                width="100%"
                loading={loadingCheckout}
                disabled={loadingCheckout}
                onClick={handleStartClick}
                text="Checkout + Start your Team" />
        </Container>
    )
}