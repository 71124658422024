import { CSSProperties, FC, useContext, useState } from "react";
import { Grid } from "@material-ui/core";
import { ConnectionsStepsDescription } from "components/connect/ConnectionsStepsDescription";
import CampaignCreationFacebookConnect from "components/connect/Facebook";
import { FacebookAdAccountConnectModal } from "components/connect/FacebookAdAccountConnectModal";
import { InstagramModal } from "components/connect/InstagramModal";
import StepVideoContainer from "components/shareable/StepVideoContainer";
import { Subtitle1, Body2 } from "components/shareable/Typography";
import { CONVERSIONS_VIDEOS, VideoData } from "types/global";
import { EventSelector } from "../../LinkClicks/EventSelector";
import ConversionsCallToAction from "../ConversionsCallToAction";
import ConversionsIndicator from "../ConversionsIndicator";
import { StepsSocialMedia } from "../StepsSocialMedia";
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";
import { ConnectionsContext } from "../../hooks/ConnectionsContext";
import useModal from "Hooks/useModal";
import useBanner from "Hooks/useBanner";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { useStyles } from "./styles";
import Intercom from "helpers/Intercom";
import { SystemColors } from "types/globalStyles";
import { useDetectAdBlock } from "adblock-detect-react";
import { TextButton } from "styles/shared";
import { ReactComponent as ArrowRightIcon } from 'assets/images/arrowRight.svg';
const { stepVideos } = CONVERSIONS_VIDEOS

interface ConnectionsSetupProps {
  showConversions?: boolean;
  showEventSelector?: boolean;
  selectedCampaignType?: string;
}

export const ConnectionsSetup: FC<ConnectionsSetupProps> = ({
  showConversions = false,
  showEventSelector = false,
  selectedCampaignType,
}) => {
  const { mobileView } = useMediaBreakpoints();
  const watchVideoModal = useModal()
  const classes = useStyles()
  const { currentBrand } = useContext(CurrentBrandContext)
  const adBlockDetected = useDetectAdBlock();
  const { conversionsStatus } = currentBrand || {}
  const { conversions_tasks: conversionsTasks, isError: showConversionsError, conversions_tasks_completed: isConversionsSetUp } = conversionsStatus || {};
  const showConversionsIndicator = Boolean(isConversionsSetUp || showConversionsError)
  const conversionsCallToAction = useBanner({ initiallyVisible: !isConversionsSetUp });

  const {
    connectionsType: setupType,
    facebookAdAccount,
    conversionsEnabled,
    facebookPixel,
    conversionsEventOptions: conversionsEvents,
    conversionsEventName: selectedConversionsEvent,
    setConversionsEnabled,
    setConversionsEventName: selectConversionsEvent,
    eventsLoading,
  } = useContext(ConnectionsContext);

  const [showConnectModal, setConnectModal] = useState('');
  const [currentVideoGetStarted, setCurrentVideoGetStarted] = useState<VideoData>(stepVideos.GETTING_STARTED)

  const toggleConversionsIndicator = () => setConversionsEnabled(prev => !prev)

  return (
    <div className={`connectionsSectionComponent ${classes.sectionsContainer}`}>
      {mobileView ?
        <Grid style={sty.hidden} className='connectionsSectionGrid'>
          <StepVideoContainer
            {...currentVideoGetStarted}
            openModal={watchVideoModal.open}
            onCloseModal={watchVideoModal.closeModal}
            onOpenModal={watchVideoModal.openModal}
            showDescription
            buttonPadding="12px 16px"
          />
        </Grid>
        :
        <ConnectionsStepsDescription showConversionsStep={showConversions} />
      }

      <div className={`mainSectionContainer ${classes.mainSectionContainer}`}>
        <div className={classes.stepsContainer}>
          <div className="flex flex-col pb-8">
            <Subtitle1>Connect your Advertising Accounts</Subtitle1>
            <Body2 className='body2ConnectionsSection'>
              Symphony automatically runs ads on your behalf using your Facebook and Instagram accounts. Connect your accounts below to get started. You'll only have to do this once.
            </Body2>
            {mobileView &&
              <TextButton
                style={sty.learnMoreButton}
                onClick={() => {
                  watchVideoModal.openModal();
                }}
              >
                Learn more <ArrowRightIcon />
              </TextButton>
            }
            {adBlockDetected &&
              <div className={`adBlockDetectedContainer ${classes.errorContainer}`}>
                <div className="errorContent" style={sty.errorContent}>
                  <Subtitle1>
                    Please disable your ad blocker to proceed.
                  </Subtitle1>
                  <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                    Symphony may not work correctly if you have an ad blocker enabled because we rely on advertising platforms like Facebook to automate marketing. Please disable your ad blocker to ensure your experience works properly.
                  </Body2>
                </div>
              </div>
            }
          </div>
          <StepsSocialMedia
            selectedCampaignType={selectedCampaignType}
            handleShowFacebookModal={() => setConnectModal('facebook-instagram')}
            handleShowInstagramModal={() => setConnectModal('facebook-instagram')}
            handleShowAdAccountModal={() => setConnectModal('facebook-ad-account')}
          />
          <FacebookAdAccountConnectModal
            open={showConnectModal === 'facebook-ad-account'}
            onClose={() => setConnectModal('')}
            setupType={setupType}
          />
          <CampaignCreationFacebookConnect
            open={showConnectModal === 'facebook-instagram' || showConnectModal === 'facebook-pixel'}
            withBorderRadius
            closeModal={() => setConnectModal('')}
          />
          <InstagramModal
            open={showConnectModal === 'instagram'}
            withBorderRadius
            closeModal={() => setConnectModal('')}
          />
        </div>
        {showConversions && (
          <div className={classes.conversionsSection}>
            {showConversionsIndicator ? (
              <div>
                <ConversionsIndicator
                  padding="16px 0px"
                  showAsRecommended={false}
                  description="Optimize our AI-targeting towards driving specific goals on your website."
                  conversionsEnabledDescription={selectedConversionsEvent ? `Your ads will be geared to drive more "${selectedConversionsEvent}" events on your website.` : "Your ads will be geared towards driving conversions on your selected goal."}
                  isConversionsEnabled={conversionsEnabled}
                  toggleConversionsIndicator={toggleConversionsIndicator}
                  pixelId={facebookPixel?.id}
                  adAccountId={facebookAdAccount?.id}
                  hasError={showConversionsError}
                  intercomButtonText="Learn more"
                  onIntercomClick={() => Intercom.OpenGrowConversionsArticle()}
                />
                {showEventSelector && (
                  <EventSelector
                    {...{
                      conversionsEvents,
                      selectConversionsEvent,
                      selectedConversionsEvent,
                    }}
                    eventsLoading={eventsLoading}
                  />
                )}
              </div>
            ) : (
              <ConversionsCallToAction
                conversionsTasks={conversionsTasks}
                videoUrl={CONVERSIONS_VIDEOS.onboardingVideo.videoUrl}
                hide={conversionsCallToAction.hide}
                enableCloseButton={false}
                showAsRecommended={false}
                padding="16px 0px"
              />
            )}
          </div>
        )}
      </div>
      {!mobileView && (
        <Grid className={classes.videoContainer}>
          <StepVideoContainer
            {...currentVideoGetStarted}
            openModal={watchVideoModal.open}
            onCloseModal={watchVideoModal.closeModal}
            onOpenModal={watchVideoModal.openModal}
            showDescription
            buttonPadding="12px 16px"
          />
        </Grid>
      )}
    </div>
  );
};

const sty = {
  hidden: {
    display: 'none'
  } as CSSProperties,
  learnMoreButton: {
    alignSelf: 'flex-start',
    padding: 0,
  } as CSSProperties,
  errorContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8
  } as CSSProperties,
}
