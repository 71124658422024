/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext,
  Dispatch,
  SetStateAction,
  useState,
  ChangeEvent,
  useMemo,
  useEffect,
} from "react";
import InfoSection from "components/InfoSection";
import { FunctionComponent } from "react";
import {
  TextField,
  SvgIcon,
  Checkbox,
  InputAdornment,
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import useStyles from "pages/post-auth/MarketingPage/PreSave/styles";
import { ReactComponent as EventIcon } from "assets/images/calendar.svg";
import DraggableList from "../DraggableList";
import { ReactComponent as DragAndDropIcon } from "assets/images/drag-and-drop-icon.svg";
import SpotifyIcon from "assets/images/streaming/square-icons/spotify-sqr.svg";
import DeezerIcon from "assets/images/streaming/square-icons/deezer-sqr.svg";
import AppleMusicIcon from "assets/images/streaming/square-icons/apple-sqr.svg";
import AudiomackIcon from "assets/images/streaming/square-icons/audiomack-sqr.svg";
import SoundcloudIcon from "assets/images/streaming/square-icons/soundcloud-sqr.svg";
import { PreSaveDataContext } from "pages/post-auth/MarketingPage/Data/PreSaveProvider";
import { STEPS } from "pages/post-auth/MarketingPage/PreSave";
import RecordPreview from "components/shareable/RecordPreview";
import { BackgroundTypes, CurrentBrand, Website } from "types/global";
import {
  showPositionOption,
  validateReleaseUrlUriUpcIsrc,
  getSelectedDatePickerDate,
  getRecordPreviewProps,
  getRecordPreviewContainerStyles,
  getDatePickerTextFieldClassName,
  getDatePickerWidthClassName,
  getTimezonePickerWidthClassName,
  getDatePickerDateFormat,
  getBoostOptions,
  findPlatform,
} from "../../utils";
import moment from "moment";
import isEqual from "lodash/isEqual";
import "./Custom.css";
import TimeZoneSelect from "components/shareable/TimezoneSelect";
import { ITimezoneOption } from "components/shareable/TimezoneSelect/types/timezone";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import momentTz from "moment-timezone";
import Intercom from "helpers/Intercom";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import QuestionMarkIcon from "assets/images/question-mark.svg";
import InputUPCISRC from "pages/post-auth/MarketingPage/PreSave/Components/EditUPCISRCModal/InputUPCISRC";
import { Body2 } from 'components/shareable/Typography';
import AudiomackConnectv2 from "components/connect/Audiomack";
import SoundcloudConnectv2 from "components/connect/Soundcloud";
import BoostOption from "./BoostOption";
import DeezerConnectv2 from "components/connect/Deezer";
import { SystemColors } from "types/globalStyles";
import clsx from "clsx";
import ReleaseTypeSwitch from "../ReleaseTypeSwitch";

interface PositionOption {
  name: string;
  show: boolean;
}

interface DraggableOptionOptions {
  alt: string;
  src: string;
  platformName: string;
  platformId: string;
  checkboxProps: {
    showCheckbox: boolean;
    checked: boolean;
    disabled: boolean;
    onChange?: () => void;
  };
  autofollowOptions: {
    allowed: boolean;
    connected: boolean;
  }
  userEnableAutofollowBoost: boolean;
  label: string;
  onConnectClick?: (platformId: string) => void;
}

const ReleaseUrlDescription: FunctionComponent = () => (
  <span>
    Enter your release’s UPC/ISRC.{" "}
    <u>
      <span className="font-medium text-black">
        <a
          onClick={() => {
            Intercom.openFindPresaveUPCArticle()
          }}>
          Need help finding this info?
        </a>
      </span>
    </u>
  </span>
);

const DraggableOption: FunctionComponent<DraggableOptionOptions> = ({
  alt,
  src,
  platformName,
  platformId,
  checkboxProps,
  label,
  autofollowOptions,
  onConnectClick,
  userEnableAutofollowBoost
}: DraggableOptionOptions) => {
  const classes = useStyles();
  const { showCheckbox, checked, disabled, onChange } = checkboxProps;

  function renderAutofollowOptions() {
    let text;
    if (!autofollowOptions.allowed) {
      text = <p className="text-gray-500 text-xs">This platform doesn't enable auto-following.</p>
    } else {
      if (autofollowOptions.connected) {
        text = <p className="text-xs">⚡️ Fans who presave will auto-follow your profile.</p>
      } else {
        text = <button onClick={() => onConnectClick && onConnectClick(platformId)} className="text-purple-500 text-xs my-0">Connect {platformName} to enable autofollows.</button>
      }
    }

    return (
      <div className="mt-0.25">
        {text}
      </div>
    )
  }

  return (
    <div className="flex p-4 align-middle items-center gap-4 border w-full">
      <div>
        <SvgIcon viewBox="0 0 32 46">
          <DragAndDropIcon />
        </SvgIcon>
      </div>

      <div className="flex items-center gap-4">
        <div>
          <img className="w-10 h-10" {...{ alt, src }} />
        </div>
        <div className="flex flex-col align-start">
          <div>{label}</div>
          {userEnableAutofollowBoost && renderAutofollowOptions()}
        </div>
      </div>
      {showCheckbox && (
        <div className="flex flex-row-reverse flex-grow">
          <Checkbox
            {...{ checked, disabled, onChange }}
            color="default"
            classes={{
              root: classes.rootCheckbox,
              checked: classes.checkedCheckbox,
            }}
          />
        </div>
      )}
    </div>
  );
};

const getDraggableOptions = (args: {
  isCheckedDeezerOption: boolean;
  isCheckedAppleMusicOption: boolean;
  isCheckedAudiomackOption: boolean;
  isCheckedSoundcloudOption: boolean;
  autofollowConnectedPlatforms: {
    spotify: boolean,
    deezer: boolean,
    audiomack: boolean,
    soundcloud: boolean
  },
  userEnableAutofollowBoost: boolean;

  positionsOptions: {
    name: string;
    show: boolean;
  }[];
  setCheckDeezerOption: Dispatch<SetStateAction<boolean>>;
  setCheckAppleMusicOption: Dispatch<SetStateAction<boolean>>;
  setCheckAudiomackOption: Dispatch<SetStateAction<boolean>>;
  setCheckSoundcloudOption: Dispatch<SetStateAction<boolean>>;
  setPositionsOptions: Dispatch<SetStateAction<PositionOption[]>>;
  showConnectModalforStreamingService: (platformId: string) => void;
}) => {
  const {
    isCheckedDeezerOption,
    isCheckedAppleMusicOption,
    isCheckedAudiomackOption,
    isCheckedSoundcloudOption,
    positionsOptions,
    setCheckDeezerOption,
    setCheckAppleMusicOption,
    setCheckAudiomackOption,
    setCheckSoundcloudOption,
    setPositionsOptions,
    showConnectModalforStreamingService,
    autofollowConnectedPlatforms,
    userEnableAutofollowBoost
  } = args;

  // Include Audiomack in draggableOptions
  const handleChangeAudiomackOption = () => {
    setCheckAudiomackOption(!isCheckedAudiomackOption);
    setPositionsOptions((prev) =>
      showPositionOption(prev, "audiomack", isCheckedAudiomackOption)
    );
  };

  const handleChangeDeezerOption = () => {
    setCheckDeezerOption(!isCheckedDeezerOption);
    setPositionsOptions((prev) =>
      showPositionOption(prev, "deezer", isCheckedDeezerOption)
    );
  };

  const handleChangeAppleMusicOption = () => {
    setCheckAppleMusicOption(!isCheckedAppleMusicOption);
    setPositionsOptions((prev) =>
      showPositionOption(prev, "apple_music", isCheckedAppleMusicOption)
    );
  };

  const handleChangeSoundcloudOption = () => {
    setCheckSoundcloudOption(!isCheckedSoundcloudOption);
    setPositionsOptions((prev) =>
      showPositionOption(prev, "soundcloud", isCheckedSoundcloudOption)
    );
  };


  const draggableOptions = [
    {
      id: "spotify-draggable-option",
      props: {
        alt: "spotify-icon.svg",
        src: SpotifyIcon,
        label: "Pre-save on Spotify",
        platformName: "Spotify",
        platformId: "spotify",
        checkboxProps: {
          showCheckbox: false,
          checked: false,
          disabled: false,
        },
        autofollowOptions: {
          allowed: true,
          connected: autofollowConnectedPlatforms.spotify
        },
        onConnectClick: showConnectModalforStreamingService
      },
    },
    {
      id: "deezer-draggable-option",
      props: {
        alt: "deezer-icon.svg",
        src: DeezerIcon,
        label: "Pre-save on Deezer",
        platformName: "Deezer",
        platformId: "deezer",
        checkboxProps: {
          showCheckbox: true,
          checked: isCheckedDeezerOption,
          disabled: false,
          onChange: handleChangeDeezerOption,
        },
        autofollowOptions: {
          allowed: true,
          connected: autofollowConnectedPlatforms.deezer
        },
        onConnectClick: showConnectModalforStreamingService
      },
    },
    {
      id: "apple-music-draggable-option",
      props: {
        alt: "apple-music-icon.svg",
        src: AppleMusicIcon,
        label: "Pre-add on Apple Music",
        platformName: "Apple Music",
        platformId: "apple_music",

        checkboxProps: {
          showCheckbox: true,
          checked: isCheckedAppleMusicOption,
          disabled: false,
          onChange: handleChangeAppleMusicOption,
        },
        autofollowOptions: {
          allowed: false,
          connected: false
        },
        onConnectClick: showConnectModalforStreamingService
      },
    },
    {
      id: "audiomack-draggable-option",
      props: {
        alt: "audiomack-icon.svg",
        src: AudiomackIcon,
        label: "Pre-save on Audiomack",
        platformName: "Audiomack",
        platformId: "audiomack",

        checkboxProps: {
          showCheckbox: true,
          checked: isCheckedAudiomackOption,
          disabled: false,
          onChange: handleChangeAudiomackOption,
        },
        autofollowOptions: {
          allowed: true,
          connected: autofollowConnectedPlatforms.audiomack
        },
        onConnectClick: showConnectModalforStreamingService
      },
    },
    {
      id: "soundcloud-draggable-option",
      props: {
        alt: "soundcloud-icon.svg",
        src: SoundcloudIcon,
        label: "Pre-save on SoundCloud",
        platformName: "SoundCloud",

        platformId: "soundcloud",
        checkboxProps: {
          showCheckbox: true,
          checked: isCheckedSoundcloudOption,
          disabled: false,
          onChange: handleChangeSoundcloudOption,
        },
        autofollowOptions: {
          allowed: true,
          connected: autofollowConnectedPlatforms.soundcloud
        },
        onConnectClick: showConnectModalforStreamingService
      },
    },
  ].map(({ id, props }) => ({
    id,
    component: <DraggableOption userEnableAutofollowBoost={userEnableAutofollowBoost} {...props} />,
  }));

  const order = positionsOptions.map((o) => {
    switch (o.name) {
      case "spotify":
        return "spotify-draggable-option";
      case "apple_music":
        return "apple-music-draggable-option";
      case "audiomack":
        return "audiomack-draggable-option";
      case "deezer":
        return "deezer-draggable-option";
      case "soundcloud":
        return "soundcloud-draggable-option";
      default:
        return o.name;
    }
  });

  const orderedDraggableOptions = draggableOptions.sort(
    (a, b) => order.indexOf(a.id) - order.indexOf(b.id)
  );

  return orderedDraggableOptions;
};

interface Props {
  website: Website;
  brandArtwork: string | null;
  setSelectedTab: Dispatch<SetStateAction<STEPS>>;
  isProUser: boolean;
  currentBrand: CurrentBrand;
}

const DetailsTab: FunctionComponent<Props> = ({
  website,
  brandArtwork,
  isProUser,
  currentBrand
}: Props) => {

  const { details, customize, saveDetails } = useContext(PreSaveDataContext);
  const { handleOpenModal: handleOpenUpgradeToProModal } = useContext(UpgradeModalContext);
  const {
    releaseDate: detailsReleaseDate,
    releaseUrl: detailsReleaseUrl,
    musicPlatforms,
  } = details;
  const { backgroundColor: customizeBackgroundColor } = customize;
  const defaultSelectedTimezone =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  const theme = useTheme();
  const responsiveDatePickers = useMediaQuery(theme.breakpoints.up(1561));
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));

  const classes = useStyles();
  const [releaseUrl, setReleaseUrl] = useState(detailsReleaseUrl || "");
  const [selectedDate, setSelectedDate] = useState<string | null>(
    detailsReleaseDate || null
  );

  // State hook for Audiomack
  const [isCheckedAudiomackOption, setCheckAudiomackOption] =
    useState<boolean>(!!findPlatform("audiomack", musicPlatforms) || true);

  const [isCheckedDeezerOption, setCheckDeezerOption] =
    useState<boolean>(!!findPlatform("deezer", musicPlatforms) || false);
  const [isCheckedAppleMusicOption, setCheckAppleMusicOption] =
    useState<boolean>(!!findPlatform("apple_music", musicPlatforms) || true);
  const [isCheckedSoundcloudOption, setCheckSoundcloudOption] =
    useState<boolean>(!!findPlatform("soundcloud", musicPlatforms) || true);


  const [positionsOptions, setPositionsOptions] = useState(
    musicPlatforms || [
      { name: "spotify", show: true },
      { name: "apple_music", show: isCheckedAppleMusicOption },
      { name: "audiomack", show: isCheckedAudiomackOption },
      { name: "soundcloud", show: isCheckedSoundcloudOption },
      { name: "deezer", show: isCheckedDeezerOption },
    ]
  );
  const [selectedTimezone, setSelectedTimezone] = useState<
    ITimezoneOption | string
  >(defaultSelectedTimezone);

  const datePickerSelectedDate = getSelectedDatePickerDate(selectedDate ? moment(selectedDate) : null);
  const datePickerDateFormat = getDatePickerDateFormat(selectedTimezone);

  // boost arguments
  const [collectEmails, setCollectEmails] = useState(details?.boosts ? details?.boosts.collectEmails : true);
  const [boostFollows, setBoostFollows] = useState(details?.boosts ? details?.boosts.boostFollows : true);
  const [autoPresaves, setAutoPresaves] = useState(
    details?.boosts ?
      details?.boosts.autoPresaves
      : isProUser ? true : false);
  const [isLocalRelease, setIsLocalRelease] = useState(true);
  const [isHideReleaseDate, setIsHideReleaseDate] = useState(false);
  const [focusedTimezoneSelect, setFocusedTimezoneSelect] = useState<boolean>(false);

  const toggleSwitch = () => setIsLocalRelease(prev => !prev);
  const handleHideReleaseDate = () => setIsHideReleaseDate(prev => !prev);

  const handleChangeCollectEmails = () => {
    setCollectEmails((prev) => !prev);
  };

  const handleChangeBoostFollows = () => {
    setBoostFollows((prev) => !prev);
  };

  const handleChangeAutoPresaves = () => {
    if (!isProUser) {
      handleOpenUpgradeToProModal({
        source: "Presave Campaign Details - Enable Forever Saves"
      });
      return;
    }
    setAutoPresaves((prev) => !prev);
  };

  const boostOptions = getBoostOptions({
    collectEmails,
    boostFollows,
    handleChangeCollectEmails,
    handleChangeBoostFollows,
  });

  // DSP selector

  // If a user has enabled auto-follow, this will be used to denote 
  // that the platform is properly connect to allow for autofollows.
  const [showConnectBox, setShowConnectBox] = useState<string | null>(null);
  const [autofollowConnectedPlatforms, setAutofollowConnectPlatforms] = useState<{
    spotify: boolean,
    deezer: boolean,
    audiomack: boolean,
    soundcloud: boolean
  }>({
    spotify: true,
    deezer: currentBrand?.connections?.deezer ? true : false,
    audiomack: currentBrand && currentBrand.connections.audiomack ? true : false,
    soundcloud: currentBrand?.connections?.soundcloud_artist_page?.id ? true : false
  });

  function showConnectModalforStreamingService(platformId: string) {
    setShowConnectBox(platformId)
  }

  // this will update the draggable options, and set the connected value
  // to true for the platform that was just connected.
  function enableAutosaveOnPlatform(platformId: string) {
    setAutofollowConnectPlatforms({
      ...autofollowConnectedPlatforms,
      [platformId]: true
    })
  }

  const draggableOptions = useMemo(
    () =>
      getDraggableOptions({
        isCheckedAudiomackOption,
        isCheckedDeezerOption,
        isCheckedAppleMusicOption,
        isCheckedSoundcloudOption,
        positionsOptions,
        setCheckDeezerOption,
        setCheckAppleMusicOption,
        setCheckAudiomackOption,
        setCheckSoundcloudOption,
        setPositionsOptions,
        showConnectModalforStreamingService,
        autofollowConnectedPlatforms: autofollowConnectedPlatforms,
        userEnableAutofollowBoost: boostFollows
      }),
    [isCheckedAppleMusicOption, isCheckedDeezerOption, autofollowConnectedPlatforms, boostFollows, positionsOptions]
  );


  const InputProps = {
    classes: {
      root: classes.inputBorder,
    },
  };
  const datePickerTextFieldClassName = getDatePickerTextFieldClassName(
    selectedDate ? moment(selectedDate) : null,
    classes
  );
  const widthDatePickerClassName = getDatePickerWidthClassName(
    selectedDate ? moment(selectedDate) : null,
    responsiveDatePickers
  );
  const widthTimezonePickerClassName = getTimezonePickerWidthClassName(
    selectedDate ? moment(selectedDate) : null,
    responsiveDatePickers
  );

  const handleOnChangeReleaseUrl = (newReleaseUrl: string) => setReleaseUrl(newReleaseUrl)

  const handleChangeDate = (date: Date) => {
    setSelectedDate(moment(date).format('YYYY-MM-DD HH:mm:ss'));
  };

  //TODO: Set selectedDate value as new Date if not let its own value
  const handleChangeTimezone = (value: ITimezoneOption | string) => {
    setSelectedTimezone(value);
  };

  const filterPassedTime = (time: string | number | Date) => {
    const selectedTz =
      typeof selectedTimezone === "string"
        ? selectedTimezone
        : selectedTimezone.value;
    const currentDateTimezone = momentTz.tz(new Date(), selectedTz).toDate();
    const selectedDateTimezone = momentTz
      .tz(new Date(time), selectedTz)
      .toDate();

    return currentDateTimezone.getTime() < selectedDateTimezone.getTime();
  };

  // auto-saves changes
  function saveDetailsData() {
    const value =
      typeof selectedTimezone === "string"
        ? selectedTimezone
        : selectedTimezone.value;
    const abbrev =
      typeof selectedTimezone === "string"
        ? ""
        : selectedTimezone?.abbrev || "";

    const isValidReleaseUrl = validateReleaseUrlUriUpcIsrc(releaseUrl);

    saveDetails({
      releaseUrl,
      releaseUrlValid: isValidReleaseUrl,
      releaseDate: moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'),
      musicPlatforms: positionsOptions,
      timezone: { value, abbrev },
      boosts: {
        collectEmails,
        boostFollows,
        autoPresaves,
      },
      localRelease: isLocalRelease,
      hideReleaseDate: isHideReleaseDate,
    });
  }

  const handleOnDragEnd = (items: { id: string; component: JSX.Element }[]) => {
    const options = items.map((o) => {
      if (o.id.includes("deezer")) {
        return { name: "deezer", show: isCheckedDeezerOption };
      }
      if (o.id.includes("apple-music")) {
        return {
          name: "apple_music",
          show: isCheckedAppleMusicOption,
        };
      }

      if (o.id.includes("audiomack")) {
        return { name: "audiomack", show: isCheckedAudiomackOption };
      }

      if (o.id.includes("soundcloud")) {
        return { name: "soundcloud", show: isCheckedSoundcloudOption };
      }
      return { name: "spotify", show: true };
    });

    setPositionsOptions(options);
  };

  const styleBackgroundColor =
    customizeBackgroundColor || website?.bgColor || SystemColors.DARK_GRAY;

  const isValidReleaseUrl = validateReleaseUrlUriUpcIsrc(releaseUrl);

  const recordPreviewProps = useMemo(
    () =>
      getRecordPreviewProps({
        brandArtwork,
        releaseTitle: customize.releaseTitle,
        positionsOptions,
        customize,
        website,
        subtitle: customize.subtitle,
        titleAlignment: customize.titleAlignment
      }),
    [brandArtwork, customize, positionsOptions, website]
  );

  const { style: containerStyle, className: containerClassName } =
    getRecordPreviewContainerStyles({
      classes,
      backgroundColor: styleBackgroundColor,
      backgroundType: recordPreviewProps.backgroundType,
    });

  const handleChangeReleaseUrlIsrcCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
  }

  useEffect(() => {
    saveDetailsData();
  }, [
    selectedDate,
    selectedTimezone,
    releaseUrl,
    positionsOptions,
    collectEmails,
    boostFollows,
    autoPresaves,
    isHideReleaseDate,
  ]);

  return (
    <div className="flex">
      <div className={classes.dataColumn}>
        <InfoSection
          title="Release Details"
          description="Add in your release details to automatically setup your pre-save page."
        />
        {/* <InfoSection title="⚡ Boosts" description="">
          <div className="flex flex-col justify-start items-center gap-y-4 sm:gap-y-0">
            {boostOptions.map((props) => (
              <React.Fragment key={`${props.boldLabel}-${props.label}`}>
                <BoostOption {...props} />
              </React.Fragment>
            ))}
          </div>
        </InfoSection> */}
        {/* Boost Followers boost */}
        <InfoSection title="⚡ Boosts" description="Enable boosts to make it even easier to grow.">
          <div className="my-4">
            <BoostOption
              showBorderTop={true}
              showBorderBottom={false}
              mainText="Collect Fan Data"
              requiresPro={false}
              descriptionText={
                "When enabled, fans who pre-save will be asked to subscribe and consent to sharing their fan data, including name, email, and location."
              }
              isProUser={isProUser}
              boostEnabled={collectEmails}
              isResponsiveView={setResponsiveView}

              onChange={(ev) => {
                setCollectEmails(!collectEmails)
              }} />
            <BoostOption
              showBorderTop={true}
              showBorderBottom={false}
              mainText="Boost Followers"
              requiresPro={false}
              descriptionText={
                "When enabled, fans who pre-save will automatically follow your streaming service profile."
              }
              isProUser={isProUser}
              boostEnabled={boostFollows}
              isResponsiveView={setResponsiveView}

              onChange={(ev) => {
                setBoostFollows(!boostFollows)
              }}
            // tooltipIcon={QuestionMarkIcon}
            // tooltipText="Click to learn more about how Forever Saves work."
            // tooltipOnClick={() => {
            //   Intercom.openForeverSavesArticle()
            // }}

            />

            {/* Forever Saves boost */}
            <BoostOption
              showBorderTop={true}
              showBorderBottom={true}
              mainText="Forever Saves"
              requiresPro={true}
              descriptionText="Grow saves with every release by asking fans if they want to automatically save your future releases."
              isProUser={isProUser}
              boostEnabled={autoPresaves}
              isResponsiveView={setResponsiveView}

              onChange={(ev) => {
                const change = ev.target.checked
                console.log('test', change)
                handleChangeAutoPresaves()
              }}
              tooltipIcon={QuestionMarkIcon}
              tooltipText="Click to learn more about how Forever Saves work."
              tooltipOnClick={() => {
                Intercom.openForeverSavesArticle()
              }}

            />
          </div>
        </InfoSection>
        <InfoSection
          className="py-4 px-5 w-full lg:px-10 border-b"
          title="UPC/ISRC"
          description={<ReleaseUrlDescription />}
        >
          <div className="pt-4 flex gap-4 flex-col">
            <InputUPCISRC
              releaseUrl={releaseUrl}
              onChangeInput={handleOnChangeReleaseUrl}
              isValidReleaseUrl={isValidReleaseUrl}
            />
            <Body2>
              You can always add this info after setting up your campaign.
            </Body2>
          </div>
        </InfoSection>
        <InfoSection
          title="Release Date"
          description="Enter the release date for your song / album - this is when the song will be added to your fans’ music libraries."
        >
          <div
            className={`flex flex-row items-end gap-x-2`}
          >
            <div className={widthDatePickerClassName}>
              <div className="customDatePickerContainer">
                <DatePicker
                  showTimeSelect
                  wrapperClassName={classes.datePicker}
                  placeholderText="Set release date"
                  dateFormat={datePickerDateFormat}
                  minDate={moment().add(1, "day").toDate()}
                  filterTime={filterPassedTime}
                  selected={datePickerSelectedDate}
                  onChange={handleChangeDate}
                  customInput={
                    <TextField
                      className={datePickerTextFieldClassName}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        ...InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <EventIcon className={classes.eventIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                />
              </div>
            </div>
            <div className={`pt-3 ${widthTimezonePickerClassName}`}>
              <TimeZoneSelect
                styles={{
                  control: (provided, _state) => ({
                    ...provided,
                    height: 56,
                    borderRadius: 8,
                    maxWidth: 300,
                  }),
                  singleValue: (provided, _state) => ({
                    ...provided,
                    color: SystemColors.PRIMARY_TEXT_COLOR,
                  }),
                }}
                className={clsx(focusedTimezoneSelect && classes.focusedTimezoneSelect)}
                onFocus={() => setFocusedTimezoneSelect(true)}
                onBlur={() => setFocusedTimezoneSelect(false)
                }
                labelStyle="abbrevNoPrefix"
                value={selectedTimezone}
                onChange={handleChangeTimezone}
              />
            </div>
          </div>
          <div className="flex items-center">
            <Checkbox 
              checked={isHideReleaseDate}
              onChange={handleHideReleaseDate}
              color="default"
              classes={{
                root: classes.rootCheckbox,
                checked: classes.checkedCheckbox,
              }}
            />
            <Body2>
              Hide release date from your page
            </Body2>
          </div>
          <ReleaseTypeSwitch
            isLocalRelease={isLocalRelease}
            toggleSwitch={toggleSwitch}
          />
        </InfoSection>
        <InfoSection
          className="p-4 items-center justify-between px-5 w-full lg:px-10"
          title="Music Platforms"
          description="Select the platforms you want fans to be able to save on."
        >
          <div className="mt-3">
            <DraggableList
              {...{ handleOnDragEnd }}
              options={draggableOptions}
            />
          </div>
        </InfoSection>
      </div>
      <div className={classes.previewColumn}>
        <div className={containerClassName} style={containerStyle}>
          {isEqual(
            recordPreviewProps.backgroundType,
            BackgroundTypes.ARTWORK
          ) && (
              <img
                alt="artwork_as_background"
                src={recordPreviewProps.contentDetails.artworkUrl}
                className="filter blur-lg absolute w-auto object-cover h-full"
              />
            )}
          <RecordPreview
            {...recordPreviewProps}
            className={classes.principalPreviewContainer}
          />
        </div>
      </div>
      {showConnectBox === 'audiomack' && (
        <AudiomackConnectv2
          closeModal={() => setShowConnectBox(null)}
          artist={currentBrand}
          open={true}
          selected={currentBrand && currentBrand.connections.audiomack ? currentBrand.connections.audiomack.url : null}
          onSave={() => enableAutosaveOnPlatform('audiomack')}
        />
      )}

      {showConnectBox === 'soundcloud' && (
        <SoundcloudConnectv2
          closeModal={() => setShowConnectBox(null)}
          artist={currentBrand}
          open={true}
          selected={currentBrand?.connections?.soundcloud_artist_page?.id ? currentBrand?.connections?.soundcloud_artist_page?.id : null}
          onSave={() => enableAutosaveOnPlatform('soundcloud')}
        />
      )}

      {showConnectBox === 'deezer' && (
        <DeezerConnectv2
          closeModal={() => setShowConnectBox(null)}
          artist={currentBrand}
          open={true}
          selected={currentBrand?.connections?.deezer?.id ? currentBrand?.connections?.deezer?.id : null}
          onSave={() => enableAutosaveOnPlatform('deezer')}
        />
      )}
    </div>
  );
};

export default DetailsTab;