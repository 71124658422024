import { CSSProperties } from "react";
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";
import { SystemColors } from "types/globalStyles";

const PrimaryButtonContainer = ({children, fullWidth}: {children: React.ReactNode, fullWidth?: boolean}) => {
    const { mobileView } = useMediaBreakpoints();

    let style = mobileView ? sty : fullWidth ? {width: '100%'} : {};
    return (
        <div style={style}>

            {children}
        </div>
    );
}

const sty = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: 16,
    boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: SystemColors.PAPER_BACKGROUND_CONTAINER
} as CSSProperties

export default PrimaryButtonContainer;