import Avatar from '@mui/material/Avatar';
import { Body2, Caption } from 'components/shareable/Typography';
import UpgradeToProChip from 'components/shareable/UpgradeToProChip';
import { OrganizationBrand, ArtistPermission, ArtistPermissions } from "types/global";
import { SystemColors } from 'types/globalStyles';
import { ReactComponent as ChevronDownIcon } from 'assets/images/chevronDown.svg';
import { ReactComponent as TrashIcon } from 'assets/images/trash.svg';
import { MenuItem, Select } from '@material-ui/core';
import { useStyles } from './styles';
import { AddArtistsPopUp } from '../AddMemberModal/AddArtistsPopUp';

export const ArtistsPermissionsNames = {
  administrator: 'Full Access',
  view_only: 'View only',
};

interface ArtistsListProps {
  artists: OrganizationBrand[];
  canEdit?: boolean;
  isCurrentUser?: boolean;
  onUpdateArtists?: (artists: OrganizationBrand[]) => void;
}

export const ArtistsList = ({ artists, canEdit = true, isCurrentUser = false, onUpdateArtists }: ArtistsListProps) => {

  const onUpdatePermission = (id: number, permission: ArtistPermission) => {
    const updatedArtists = artists.map(artist => artist.id === id ? { ...artist, permissionLevel: permission } : artist);
    if (onUpdateArtists) onUpdateArtists(updatedArtists);
  };

  const onDeleteArtist = (id: number) => {
    const updatedArtists = artists.filter(artist => artist.id !== id);
    if (onUpdateArtists) onUpdateArtists(updatedArtists);
  };

  const onAddArtist = (artist: OrganizationBrand) => {
    if (onUpdateArtists) onUpdateArtists([...artists, { ...artist, permissionLevel: ArtistPermissions.ADMINISTRATOR }]);
  }

  let noArtistsUi = (
    <div className="flex flex-col gap-4">
      
    </div>
  )

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>{`${isCurrentUser ? 'You have' : 'This teammate has'} access to:`}</Body2>
        {canEdit && (
          <AddArtistsPopUp addedArtists={artists} onAddArtist={onAddArtist} />
        )}
      </div>
      {artists.map(artist =>
        <ArtistItem
          key={artist.id}
          artist={artist}
          onUpdatePermission={onUpdatePermission}
          onDelete={onDeleteArtist}
          canEdit={canEdit}
        />
      )}
    </div>
  );
};

interface ArtistItemProps {
  artist: OrganizationBrand;
  canEdit: boolean;
  onUpdatePermission: (id: number, permission: ArtistPermission) => void;
  onDelete: (id: number) => void;
}

export const ArtistItem = ({ artist, canEdit, onUpdatePermission, onDelete }: ArtistItemProps) => {
  const classes = useStyles();
  return (
    <div className={classes.itemContainer}>
      <div className='flex gap-4 items-center'>
        <Avatar src={artist.profile_picture} sx={{ width: 24, height: 24 }} />
        <Body2
          color={SystemColors.PRIMARY_TEXT_COLOR}
          style={{ textWrap: 'nowrap', maxWidth: 120, textOverflow: 'ellipsis', overflow: 'hidden' }}
        >
          {artist.name}
        </Body2>
        {artist.isPro && <UpgradeToProChip type='label' withMargin={false} />}
      </div>
      <div className={classes.optionsContainer}>
        <Select
          required
          displayEmpty
          value={artist.permissionLevel}
          onChange={(e) => onUpdatePermission(artist.id, e.target.value as ArtistPermission)}
          variant="standard"
          className={classes.select}
          IconComponent={() => canEdit ? <ChevronDownIcon className="h-8 w-8" /> : null}
          disabled={!canEdit}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            }
          }}
          renderValue={(value) => {
            if (!value) {
              return <Body2 color={SystemColors.DISABLED_TEXT_COLOR}>Select a permission</Body2>
            }
            return <Body2>{ArtistsPermissionsNames[value as ArtistPermission]}</Body2>
          }}
        >
          <MenuItem value="administrator">
            <div className='flex flex-col gap-1'>
              <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>Full Access</Body2>
              <Caption color={SystemColors.SECONDARY_TEXT_COLOR} style={{ width: 300, textWrap: 'wrap' }}>Full Access permissions allow this teammate to create and edit campaigns & websites.</Caption>
            </div>
          </MenuItem>
          <MenuItem value="view_only">
            <div className='flex flex-col gap-1'>
              <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>View Only</Body2>
              <Caption color={SystemColors.SECONDARY_TEXT_COLOR} style={{ width: 300, textWrap: 'wrap' }}>View-only access to the profile.This teammate will not have the ability to edit or create campaigns or websites.</Caption>
            </div>
          </MenuItem>
        </Select>
        {canEdit && <TrashIcon height={24} width={24} cursor='pointer' onClick={() => onDelete(artist.id)} />}
      </div>
    </div>
  );
};

