import { useContext, useState } from 'react';
import { FC } from 'react';
import { SystemColors } from 'types/globalStyles';
import { Body2 } from 'components/shareable/Typography';
import LinkButton from 'components/shareable/LinkButton';
import { ReactComponent as InstagramGoldIcon } from 'assets/images/instagram-gold.svg';
import { useStyles } from './styles';
import Intercom from 'helpers/Intercom';
import { CAMPAIGN_TYPE_MAPPINGS } from '../../../constants';
import SecondaryButton from 'components/shareable/SecondaryButton';
import { formatFacebookPages, FbIgPageOption } from '../utils';
import { queryFB } from 'helpers/FB';
import { ConnectionsContext } from 'pages/post-auth/MarketingPage/hooks/ConnectionsContext';
import { updateInBrand } from 'services/symphonyApi/brandService';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import { toast } from 'react-toastify';
import { track } from 'analytics';

interface IgBannerProps {
  campaignType?: string;
  isNotBusiness?: boolean;
}

export const IgBanner: FC<IgBannerProps> = ({ campaignType, isNotBusiness }) => {
  const {
    loggedInFbUser,
    facebookPage,
    setValidateConnections,
    setFacebookPage: selectFBPage,
    setInstagramPage: selectIgPage,
    setInstagramPagesAvailable,
  } = useContext(ConnectionsContext);
  const { currentBrand: artist, reloadBrand } = useContext(CurrentBrandContext);
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const icon = <InstagramGoldIcon />;
  const campaignName = CAMPAIGN_TYPE_MAPPINGS[campaignType || ''];
  const isInstagramCampaign = campaignName === 'Boost Instagram Engagement';
  const isNotBussinessAccount = isNotBusiness && isInstagramCampaign;

    const getMessage = () => {
      if (isNotBussinessAccount) {
        return "It looks like your Instagram Page isn’t a Business Account. Your ads will be 4-5x more effective if you turn it into a business account before running ads. Click below to learn how to do this.";
      }
      if (isInstagramCampaign) {
        return `To run a ${campaignName} campaign, you'll need to link your Instagram + Facebook together so we can run ads on your behalf.`;
      }
      return "It looks like you don’t have an Instagram Page linked to your Facebook Page. Your ads will be 4-5x more effective if you link them together. Click below to learn how to link.";
    }

  const verifyConnection = async () => {
    setLoading(true);
    try {
      const results = await queryFB(`/me/accounts`, {
        params: {
          fields:
            'name,category,id,access_token,followers_count,picture{url},is_published',
          limit: 200,
          access_token: loggedInFbUser?.access_token,
        },
      });

      const fbPages = await formatFacebookPages(results);
      const fbPage = fbPages.find((page) => page.fbAccount.id === facebookPage?.id);

      if (fbPage?.igAccount) {
        handleConnectInstagramPage(fbPage);
      }
      toast.success('Connection verified successfully');
    } catch (e) {
      console.error('Error pulling Facebook - getPages in Facebook Connector', e);
      track('Error verifying connection - verifyConnection in Ig Banner', e);
    }
    setLoading(false);
  };

  const handleConnectInstagramPage = async (pages: FbIgPageOption) => {
    setValidateConnections(false);
    try {
      await updateInBrand(artist!.id, artist!.slug!, {
        facebook_page: pages.fbAccount,
        logged_in_fb_user: null,
      }, 'facebook_page');
      selectFBPage(pages.fbAccount);

      if (pages.igAccount) {
        await updateInBrand(artist!.id, artist!.slug!, pages.igAccount, 'instagram_page');
        selectIgPage(pages.igAccount);
        setInstagramPagesAvailable(true)
      } else {
        setInstagramPagesAvailable(false);
        selectIgPage(undefined);
      }
      await reloadBrand();
    } catch (e) {
      selectIgPage(undefined);
      track('Error connecting Instagram - updateInBrand in ig banner', { error: e });
    }
    setValidateConnections(true);
  };

  return (
    <div className={classes.warnContainer}>
      <div className='flex gap-4 items-center justify-center flex-initial w-full'>
        <div>{icon}</div>
        <div className='flex flex-col'>
          <div className='flex flex-col gap-1'>
            <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>{getMessage()}</Body2>
          </div>
        </div>
      </div>
      <div className='flex items-center gap-4 flex-shrink-0'>
        <LinkButton
          text={isInstagramCampaign || !isNotBussinessAccount ? 'Learn more' : 'Learn how to link IG + FB'}
          onClick={() => Intercom.openFacebookInstagramConnectorArticle()}
        />
        <SecondaryButton
          text={isInstagramCampaign || !isNotBussinessAccount ? 'Verify Connection' : 'Check again'}
          onClick={() => verifyConnection()}
          loading={loading}
          padding='8px 24px'
        />
      </div>
    </div>
  );
};
