import {
  useState,
  FunctionComponent,
  Fragment,
  useEffect,
  useContext,
} from "react";
import { ArtistPermissions, CurrentBrand, Fan } from "types/global";
import Fanbase from "./Components/Fanbase";
import { TabSelector } from "helpers/TabSelector";
import { TabPanel } from "react-headless-tabs";
import isEqual from "lodash/isEqual";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import {
  TabsPanelsContainer,
  DownloadCsvButton,
  FansTabButtonContainer,
  TabsContainer,
  FansContainer,
  DefaultImage,
  UnlockBannerContainer,
  BannerContainer,
} from "./styles";
import { FanbaseTasks } from "./Components/FanbaseTasks";
import { FanbaseContext } from "Hooks/FanbaseContext";
import { CardMetricsContainer } from "./styles";
import { getFansMetrics, getDownloadSignedUrl } from './api';
import { FansMetrics } from './constants';
import CardMetrics from './Components/CardMetrics';
import emptyUserPink from "assets/images/empty-user-pink.png";
import YellowStar from "assets/images/yellow-star.png";
import Grid from '@material-ui/core/Grid';
import FansDetailsModal from './Components/FansDetailsModal';
import { checkIfIsProTier, getStatusToShowPaymentFailedBanner, isBrandAdministrator } from "utils";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import { NewLoader } from 'components/Loader/ConnectBrandLoader';
import { LoadingLottie } from 'components/Loader/LoadingLottie';
import UnlockDataBanner from "components/shareable/UnlockDataBanner";
import UnlockDataImage from "assets/images/unlock-data-geographies.svg";
import { pageView, track } from "analytics";
import CardTitle from "components/shareable/CommonComponent/CardTitle";
import PaymentFailedBanner from "components/shareable/PaymentFailedBanner";
import DefaultLearningIndicator, { LearningIndicatorType } from "components/shareable/LearningIndicator/default";
import { MainContainer, StyledNavBar } from "styles/shared";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import Intercom from "helpers/Intercom";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { OrganizationSubscriptionStatusBanner } from "components/shareable/OrganizationFailedPayments";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";

enum Tabs {
  FANBASE = "Fanbase",
  CAMPAIGNS = "Campaigns",
}

const { FANBASE } = Tabs;

interface Props {
  loadedBrands: CurrentBrand[];
  reloadBrands: () => void;
}

const Fans: FunctionComponent<Props> = () => {
  const theme = useTheme();
  const { completed, fetchFanbaseTasks } = useContext(FanbaseContext);
  const { currentBrand } = useContext(CurrentBrandContext);
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));
  const setTasksListResponsive = useMediaQuery(theme.breakpoints.down(1200));
  const [selectedTab, setSelectedTab] = useState(FANBASE);
  const [hasFans, setHasFans] = useState<boolean>(false);
  const [loadingMetrics, setLoadingMetrics] = useState<boolean>(true);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [fansMetrics, setFansMetrics] = useState<FansMetrics>({
    fanbaseGrowth: "0.0",
    foreverPresaveFans: 0,
    foreverPresaveFansGrowth: "0.0",
    totalFanbase: 0,
  });
  const [showFanModal, setShowFanModal] = useState<boolean>(false);
  const [selectedFan, seSelectedFan] = useState<Fan | null>(null)
  const isProUser = checkIfIsProTier(currentBrand);
  const showPaymentFailedBanner = getStatusToShowPaymentFailedBanner(currentBrand)
  const { handleOpenModal: handleOpenUpgradeToProModal } = useContext(UpgradeModalContext);
  const hasAdminAccess = isBrandAdministrator(currentBrand!);
  const {
    remainingFreeTrial,
    redirectToSubscriptionStatus,
    isLoadingSubscriptionView,
    isOrganizationInactive } = useCurrentTeam()

  useEffect(() => {
    fetchFanbaseTasks()
    pageView("Fans");

  }, [])

  const handleOpenFanModal = (fan: Fan) => {
    track("Viewed Fan Details")
    seSelectedFan(fan)
    setShowFanModal(true)
  }

  const handleCloseFanModal = () => setShowFanModal(false)

  const handleSelectFanbaseTab = () => {
    setSelectedTab(FANBASE);
  };

  const getFanbaseMetrics = async (brandId: number) => {
    setLoadingMetrics(true)
    setHasFans(false)
    const { data, error } = await getFansMetrics(brandId);
    if (error) {
      setLoadingMetrics(false);
      setHasFans(false);
      return;
    }
    const formattedFansMetrics = data as FansMetrics;
    setFansMetrics(formattedFansMetrics);
    setHasFans(formattedFansMetrics.totalFanbase > 0)
    setLoadingMetrics(false);
  }

  const handleDownloadCsv = async () => {
    if (!isProUser) {
      track("Tried Downloading Fans CSV")
      handleOpenUpgradeToProModal({
        source: "Fans Tab - CSV Download"
      });
      return;
    } else {
      track("Download Fans CSV")
    }

    setIsDownloading(true);

    // then download the csv
    const downloadUrl = await getDownloadSignedUrl(currentBrand?.id!);
    if (!downloadUrl.error) {
      window.location.assign(downloadUrl.data);
    } else {
      Intercom.showNewMessage("I'd like to download my Fans CSV. Can you help me with that?")
    }
    setIsDownloading(false);
  }

  useEffect(() => {
    getFanbaseMetrics(currentBrand?.id!)
  }, [currentBrand?.id])

  if (loadingMetrics) return (
    <div className="flex-col fixed top-1/2 left-1/2 lg:pl-56 flex items-center transform-gpu -translate-x-1/2 -translate-y-1/2 ">
      <NewLoader black />
      <p className="mt-1 text-center">Loading Fans...</p>
    </div>
  )

  return (
    <MainContainer
      flexDirection={!completed && !setTasksListResponsive ? "row" : "column"}
      padding={setResponsiveView ? "0 0 92px 0" : "32px 80px"}
    >
      <FansContainer>
        {(showPaymentFailedBanner || remainingFreeTrial > 0 || isOrganizationInactive) && (
          <BannerContainer>
            {showPaymentFailedBanner && <PaymentFailedBanner customerId={currentBrand?.subscription?.cid} />}
            <OrganizationSubscriptionStatusBanner onlyShowFailedPayments={true} />
          </BannerContainer>
        )}
        <CardTitle
          title="Fans"
          subtitle="Your fans, all in one place."
          mobile={setResponsiveView}
          additionalContent={
            <DefaultLearningIndicator
              type={LearningIndicatorType.FANS}
              text="Learn how to build your Fans list"
            />}

          additionalButtons={(
            <>
              {false && <button >Message your fans</button>}
            </>
          )}
        />
        <CardMetricsContainer
          padding={setResponsiveView ? "0 8px 0" : "0px"}
        >
          <CardMetrics
            image={<DefaultImage src={emptyUserPink} width='18px' height='24px' />}
            percentage={fansMetrics.fanbaseGrowth}
            title='Fans'
            total={fansMetrics.totalFanbase}
            mobile={setResponsiveView}
            key='total-fans-metrics'
          />
          <CardMetrics
            image={<DefaultImage src={YellowStar} width='24px' height='24px' />}
            percentage={fansMetrics.foreverPresaveFansGrowth}
            title={(
              <div className="flex flex-row gap-1">
                Forever Saves <UpgradeToProChip type="gem" color="purple" height={18} width={18} />
              </div>

            )}
            total={fansMetrics.foreverPresaveFans}
            mobile={setResponsiveView}
            key='forever-presave-fans'
            isProUser={isProUser}
            elementKey='forever-presave-fans'
          />
        </CardMetricsContainer>
        {!completed && setTasksListResponsive && hasAdminAccess && <FanbaseTasks />}
        {!isProUser && hasFans && (
          <UnlockBannerContainer padding={setResponsiveView ? "0 8px 0" : "0px"}>
            <UnlockDataBanner
              source="Fans Tab - Unlock Data"
              title="Learn exactly who your fans are"
              description="Go Pro to see your fans name + locations, down to the city"
              image={UnlockDataImage}
              hideImage={setResponsiveView}
              imageMaxWidth='230px'
            />
          </UnlockBannerContainer>
        )}
        <TabsContainer
          borderRadius={setResponsiveView ? "0px" : "8px"}
          padding={setResponsiveView ? "16px 8px" : "16px 24px"}
        >
          <StyledNavBar>
            <Fragment>
              <TabSelector
                isActive={isEqual(selectedTab, FANBASE)}
                onClick={handleSelectFanbaseTab}
              >
                {FANBASE}
              </TabSelector>
            </Fragment>
            <FansTabButtonContainer>
              {isDownloading
                ? <Grid className="w-6">
                  <LoadingLottie
                    black
                    height={24}
                    width={24}
                  />
                </Grid>
                : hasFans && hasAdminAccess && <DownloadCsvButton onClick={handleDownloadCsv}>  {/*TODO: change onclick to the download functionality handleDownloadCsv*/}
                  <div className="flex items-center justify-center flex-row">
                    <div className="mr-1.5">
                      <UpgradeToProChip
                        type="gem"
                        color="purple"
                        height={18}
                        width={18}
                      />
                    </div>
                    <p style={{
                      fontWeight: 400
                    }}>
                      Download CSV
                    </p>
                  </div>
                </DownloadCsvButton>
              }
            </FansTabButtonContainer>
          </StyledNavBar>
          <TabsPanelsContainer>
            <TabPanel hidden={!isEqual(selectedTab, FANBASE)}>
              <Fanbase
                brandId={currentBrand?.id!}
                hasFans={hasFans}
                loading={loadingMetrics}
                isProUser={isProUser}
                openFanDetailModal={handleOpenFanModal}
              />
            </TabPanel>
          </TabsPanelsContainer>
        </TabsContainer>
      </FansContainer>
      {!completed && !setTasksListResponsive && hasAdminAccess && <FanbaseTasks />}
      {selectedFan && <FansDetailsModal
        closeModal={handleCloseFanModal}
        open={showFanModal}
        fan={selectedFan}
        isProUser={isProUser}
      />}
    </MainContainer>
  );
};

export default Fans;
