import { Chip, useMediaQuery, useTheme } from "@material-ui/core";
import { pageView, track } from "analytics";
import CardTitle from "components/shareable/CommonComponent/CardTitle";
import { useEffect, useState } from "react";
import { Container, ContentContainer, MainContainer, StyledLabel } from "styles/shared";
import { CurrentBrand } from "types/global";
import { VideoHolder } from "./styles";
import ReactPlayer from 'react-player/youtube'
import { VideosRow } from "./Components/VideosContainer";
import VideoModalStacked from "components/shareable/VideoModalStacked";
import miniSymphonyLogo from "../../../assets/images/logos/sidebar-collapsed.png";
import Axios from "helpers/Interceptor";

export type Video = {
    embedUrl: string;
    title: string;
    description: string;
    thumbnail?: string;
    chipLinks: {
        label: string;
        onClick: () => void;
    }[];
};

export type Category = {
    id: string;
    name: string;
    videos: Video[];
};

interface Props {
    loadedBrands: CurrentBrand[];
    reloadBrands: () => void;
}

function Learn(props: Props) {
    const theme = useTheme();
    const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));

    const [videoRows, setVideoRows] = useState<Category[]>([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [popupVideo, setPopupVideo] = useState<Video | null>(null);

    // Initially defined videoDetails
    const videoDetails: Video = {
        embedUrl: "https://youtu.be/VfFgAwrqH3U",
        title: "Symphony University // HOW TO: Get your First 100 Fans",
        description: "Learn how to get your first 100 fans from Symphony's CEO Megh Vakharia. During this session, we'll dive into playbooks to grow your fanbase and references of how other artist's have grown.",
        chipLinks: [
            {
                label: "Download the Deck",
                onClick: () => window.open('https://docsend.com/view/icv7xbhcduwz8d5b', '_blank')
            },
            {
                label: "Watch on YouTube",
                onClick: () => window.open('https://youtu.be/VfFgAwrqH3U', '_blank')
            },
        ]
    };

    // New video based on given details
    const newVideo: Video = {
        embedUrl: "https://www.youtube.com/watch?v=L4lVISTuShY",
        title: "Symphony University // HOW TO: Book your First Tour",
        description: "Discover the steps to book your first tour from Symphony University, featuring insights from Saba's manager Cristela Rodriguez. This session explores strategies similar to growing your fanbase as discussed in our earlier session.",
        chipLinks: [
            {
                label: "Download the Deck",
                onClick: () => window.open('https://docsend.com/view/h6xci7cdwhgsdf5d', '_blank')
            },
            {
                label: "Watch on YouTube",
                onClick: () => window.open('https://www.youtube.com/watch?v=L4lVISTuShY', '_blank')
            },
        ]
    };

    // Defining a new category with the given videos
    const newCategory: Category = {
        id: "1",
        name: "Symphony University",
        videos: [newVideo, videoDetails]
    };

    // Adding new category to videoCategories array
    const videoCategories: Category[] = [newCategory];

    // Now videoCategories has the Symphony University category with the specified videos.

    const primaryVideo = newCategory.videos[0];

    useEffect(() => {
        pageView("Learn");
        getPlaylists();
    }, []);

    async function getPlaylists() {
        const playlist = await Axios.get("/learn/videos");
        const categoryPlaylist: Category[] = playlist.data as Category[];
        setVideoRows(categoryPlaylist);
    }

    return (
        <MainContainer
            flexDirection="column"
            padding={setResponsiveView ? "0 0 92px 0" : "32px 80px"}
        >

            <CardTitle
                title="Learn"
                subtitle="Discover how-to's, best practices, and lessons to maximize your Symphony experience."
                mobile={setResponsiveView}

            />

            <ContentContainer>
                <VideoHolder>
                    <ReactPlayer
                        controls={true}
                        playsinline={true}
                        width={'100%'}
                        url={primaryVideo.embedUrl} />

                </VideoHolder>
                <Container display="flex" flexDirection="column" gap="14px">
                    <StyledLabel
                        fontWeight={600}
                        fontSize={26}
                    >{primaryVideo.title}</StyledLabel>
                    <StyledLabel
                        fontSize={18}>{primaryVideo.description}</StyledLabel>
                </Container>
                <Container display="flex" flexDirection="column" gap="8px">
                    <StyledLabel
                        color="#000000"
                        fontWeight={400}
                        fontSize={14}
                    >Learn more:</StyledLabel>

                    <Container display="flex" flexDirection="row" gap="8px">
                        {primaryVideo.chipLinks.map((chipLink, index) => {
                            return (
                                <Chip
                                    onClick={(e) => {
                                        track("Clicked Additional Content", {
                                            ...primaryVideo
                                        })
                                        chipLink.onClick()
                                    }}
                                    // color="primary"
                                    style={{
                                        backgroundColor: "rgba(136, 0, 255, 0.08)",
                                    }}

                                    label={chipLink.label} />
                            )
                        })}


                    </Container>
                </Container>
            </ContentContainer>
            <VideosRow
                title="Symphony University"
                imgSrc={miniSymphonyLogo}
                videos={newCategory.videos}
                onVideoClick={(video) => {
                    track("Clicked Video", {
                        ...video
                    })

                    setPopupVideo(video)
                    setOpenPopup(true)

                }} />

            {videoRows.map((videoRow: Category, videoRowIndex: Number) => {                
                return (
                    <VideosRow
                        title={videoRow.name}
                        imgSrc={miniSymphonyLogo}
                        videos={videoRow.videos}
                        onVideoClick={(video) => {
                            track("Clicked Video", {
                                ...video
                            })
                            setPopupVideo(video)
                            setOpenPopup(true)
                        }} />
                )
            })}

            <VideoModalStacked
                // chipIcon={popupChipIcon}
                open={openPopup}
                closeModal={() => setOpenPopup(false)}
                chipText={"Symphony University"}
                title={popupVideo ? popupVideo.title : ""}
                subtitle={popupVideo ? popupVideo.description : ""}
                videoUrl={popupVideo ? popupVideo.embedUrl : ""}

            />

        </MainContainer>
    );
};

export default Learn;
