import { FunctionComponent } from 'react';
import {
  BodyContainer,
  CloseIconContainer,
  HeaderContainer,
  InformationContainer,
  TextContainer,
  BackButtonContainer,
  ChipContainer,
  Body,
  ImageProfile
} from './styles';
import { ReactComponent as CloseIcon } from 'assets/images/close-black.svg';
import { ReactComponent as BackArrowIcon } from 'assets/images/BackArrow.svg';
import { Body2, Headline1, Headline2, Caption } from 'components/shareable/Typography';
import { SystemColors } from 'types/globalStyles';

interface Props {
  title: string;
  subtitle?: string | JSX.Element;
  body?: string;
  chipText?: string;
  closeModal: () => void;
  HeaderIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  ChipIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  handleGoBack?: () => void;
  className?: string;
  textCenter?: boolean;
  imageIcon?: string;
  titleBigger?: boolean;
}

const HeaderModal: FunctionComponent<Props> = ({
  title,
  subtitle,
  body,
  chipText,
  ChipIcon,
  closeModal,
  HeaderIcon,
  handleGoBack,
  textCenter,
  imageIcon,
  titleBigger,
}: Props) => {
  return (
    <InformationContainer>
      <HeaderContainer justifyContent={Boolean(handleGoBack) ? 'space-between' : 'flex-end'}>
        {Boolean(handleGoBack) &&
          <BackButtonContainer onClick={handleGoBack}>
            <BackArrowIcon />
          </BackButtonContainer>
        }
        <CloseIconContainer onClick={closeModal}>
          <CloseIcon />
        </CloseIconContainer>
      </HeaderContainer>
      <BodyContainer>
        {HeaderIcon && <HeaderIcon width={32} height={32} />}
        {chipText && <ChipContainer>
          {ChipIcon && <ChipIcon width={18} height={18} />}
          <Caption color={SystemColors.ACCENT_COLOR}>{chipText}</Caption>
        </ChipContainer>}
        <TextContainer textCenter={textCenter}>
          {imageIcon && <ImageProfile src={imageIcon} />}
          {!titleBigger ? <Headline2 color={SystemColors.PRIMARY_TEXT_COLOR}>{title}</Headline2> : <Headline1 color={SystemColors.PRIMARY_TEXT_COLOR}>{title}</Headline1>}
          {subtitle && <Body2>{subtitle}</Body2>}
          {body &&
            <Body>
              {body}
            </Body>
          }
        </TextContainer>
      </BodyContainer>
    </InformationContainer>
  )
};

export default HeaderModal;
