import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { ReactComponent as Download2Icon } from "assets/images/download-2.svg"
import { ReactComponent as FacebookIcon } from "assets/images/facebook-i.svg"
import { ReactComponent as GlobeIcon } from "assets/images/globe.svg"
import { ReactComponent as HeadphonesIcon } from "assets/images/headphones.svg"
import { ReactComponent as AutomatedIcon } from "assets/images/automated.svg"
import { ReactComponent as MoneyIcon } from "assets/images/money-purple.svg"
import { ReactComponent as PreSaveIcon } from "assets/images/PreSaves.svg"
import { ReactComponent as UserIcon } from "assets/images/user.svg"
import getSymbolFromCurrency from "currency-symbol-map"
import millify from "millify"
import { CurrencyCodesType } from "types/global"
import { TEAM_PLAN_INCLUDED_SEATS } from "../../../../../constants"
import { FeatureType } from "../../utils"

export const getFeatures = (classes: ClassNameMap): Array<FeatureType> => [
  {
    featureId: 'teams-dashboard',
    icon: GlobeIcon,
    feature: "Centralize your workflow with a custom Teams-only Dashboard",
    caption: `All of your profiles, campaigns, and insights in one place.`,
    className: classes.icon,
  },
  {
    featureId: 'included-pro-profiles',
    icon: UserIcon,
    feature: "3 Pro Profiles included with your plan",
    caption: (currency: CurrencyCodesType, convertedAmount: number) =>
      `Unlock the full power of automated marketing and advanced analytics.`,
    className: classes.icon,
  },
  {
    featureId: 'pro-profiles-discount',
    icon: MoneyIcon,
    feature: "25% discount on additional Pro Profiles",
    caption: (currency: CurrencyCodesType, convertedAmount: number) =>
      `Only ${getSymbolFromCurrency(currency)}${millify(convertedAmount)} a month for additional Pro profiles when you have the Team plan.`,
    className: classes.icon,
  },
  {
    featureId: 'invite-users-teams',
    icon: Download2Icon,
    feature: `${TEAM_PLAN_INCLUDED_SEATS} included seats for your Team`,
    caption: (currency: CurrencyCodesType, convertedAmount: number) =>
      `${getSymbolFromCurrency(currency)}${convertedAmount} monthly per additional invited team member`,
    className: classes.icon,
  },
  {
    featureId: 'permissions-management',
    icon: HeadphonesIcon,
    feature: "Advanced permissions management for your Team",
    caption: `Invite teammates with full Admin access or View-Only access to your team's profiles.`,
    className: classes.icon,
  },
  {
    featureId: 'ad-spend-limit-teams',
    icon: AutomatedIcon,
    feature: "Increased ad spend limit",
    caption: (currency: CurrencyCodesType, convertedAmount: number) =>
      `Monthly ad spend limit of ${getSymbolFromCurrency(currency)}${millify(convertedAmount)} + 2% automation fee after`,
    className: classes.icon,
  },
]