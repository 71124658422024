import { Backdrop, Fade, IconButton, Modal, SvgIcon } from "@material-ui/core";
import { setUserProperty, track } from "analytics";
import InstagramBusinessDiscoveryConnect from "components/connect/InstagramBusinessDiscovery";
import CloseButton from "components/shareable/CloseButton";
import { fbLoggedIn, loginWithFB } from "helpers/FB";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import React, { Component, useContext, useEffect, useState } from "react";
import { GettingStartedContext } from 'Hooks/GettingStartedContext';
import FacebookConnectv2 from "components/connect/FacebookConnectv2";
import { siteIconsData } from "pages/post-auth/DashboardModule/Dashboard";
import { PlatformType } from "pages/post-auth/DashboardModule/PlatformsConnector";
import InstagramConnect from "components/connect/Instagram";
import { FacebookPageType } from "types/global";

interface PlatformsConnectorProps {
    open: boolean;
    closeModal: () => void;
}

export default function IGConnector(props: PlatformsConnectorProps) {

    const {
        open,
        closeModal
    } = props



    const {
        currentBrand,
        setCurrentBrand,
        reloadBrand,
        loadingBrand
    } = useContext(CurrentBrandContext);

    const brand = currentBrand!

    const [editedPlatform, setEditedPlatform] = useState<PlatformType | undefined>(undefined)

    function onSave(platform: string) {
        updateBrandConnections()
        track('Linked Platform',
            {
                brand_id: brand!.id,
                brand_slug: brand!.slug,
                brand_name: brand!.name,
                platform: platform,
                page: "Dashboard",
                from: "connector"
            });

        setUserProperty(`${platform}_linked`, true)
        reloadBrand()
        setEditedPlatform(undefined)
    }

    const selectedFacebookPage: FacebookPageType = currentBrand!.connections!.facebook_page!

    const selectedInstaPage = currentBrand &&
        currentBrand.connections &&
        currentBrand.connections.facebook_page &&
        !currentBrand.connections.reauthenticateFbUser &&
        currentBrand.connections.instagram_page

    const PLATFORMS: PlatformType[] =
        [
            {
                id: 'facebook',
                name: "Facebook",
                imageAsset: siteIconsData['facebook'],
                needsConnection: brand && (brand.connections.facebook_page && brand.connections.facebook_page.id) && (!brand.connections.reauthenticateFbUser) ? false : true,
                username: brand && brand.connections.facebook_page ? brand.connections.facebook_page.name : null,
                connectorComponent: <FacebookConnectv2
                    autoconnectInstagram={true}
                    onSave={() => onSave('facebook')}
                    closeModal={() => setEditedPlatform(undefined)}
                    artist={brand}
                    selected={{
                        ...brand.connections.facebook_page
                    }}
                />
            },

            {
                id: 'instagram',
                name: "Instagram",
                imageAsset: siteIconsData['instagram'],
                needsConnection: brand && brand.connections.business_discovery_instagram ? false : true,
                username: brand?.connections?.business_discovery_instagram?.username || '',
                connectorComponent: <InstagramConnect
                    withBorderRadius
                    showSave={true}
                    closeModal={() => setEditedPlatform(undefined)}
                    selectedInstaPage={selectedInstaPage}
                    updateFBPages={() => setEditedPlatform({
                        id: 'facebook',
                        name: "Facebook",
                        imageAsset: siteIconsData['facebook'],
                        needsConnection: brand && (brand.connections.facebook_page && brand.connections.facebook_page.id) && (!brand.connections.reauthenticateFbUser) ? false : true,
                        username: brand && brand.connections.facebook_page ? brand.connections.facebook_page.name : null,
                        connectorComponent: <FacebookConnectv2
                            autoconnectInstagram={true}
                            onSave={() => onSave('facebook')}
                            closeModal={() => setEditedPlatform(undefined)}
                            artist={brand}
                            selected={{
                                ...brand.connections.facebook_page
                            }}
                        />
                    })}
                    selectedFBPage={selectedFacebookPage}
                    selectInstagramPage={console.log}
                />
            }
        ]

    useEffect(() => {
        // if modal is closed, reset back to default state
        if (!open) {
            setEditedPlatform(undefined)
        }
    }, [open])

    async function selectedEditedPlatform(platformType: PlatformType) {
        // if its Facebook or IG, we should call FB login within this first
        // if (platformType.id === 'instagram') {
        //     if (!fbLoggedIn()) {
        //         console.log('not logged in, calling login')
        //         await loginWithFB()
        //         console.log('user logged in')
        //     }
        //     setEditedPlatform(platformType)
        // }
        setEditedPlatform(platformType)
    }

    async function updateBrandConnections() {
        await reloadBrand()

    }
    return (
        <Modal
            aria-labelledby="connector-modal"
            aria-describedby="connector-modal for platforms"
            className={"flex items-center justify-center"}
            open={open}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 200,
            }}
        >
            <Fade in={open}>
                {editedPlatform ? (
                    <div className="w-full h-full overflow-auto md:h-auto md:w-4/6 lg:w-2/6 bg-white md:rounded-md">
                        {editedPlatform.connectorComponent}
                    </div>
                ) : (
                    <div className="w-full h-full overflow-auto md:h-auto md:w-4/6 lg:w-2/6 bg-white md:rounded-md px-6 py-5 ">
                        <div className="flex justify-end">
                            <CloseButton onClick={closeModal} />
                        </div>
                        <h1 className=" text-2xl">Connect Instagram</h1>
                        <h2 className=" text-md">Connect your Facebook + Instagram Page to Superboost posts.</h2>
                        <div className="mt-4">
                            <hr className="h-px	bg-gray-200" />

                            <div className="add-new">
                                {PLATFORMS
                                    .sort((a: PlatformType, b: PlatformType) => {
                                        if (a.needsConnection && !b.needsConnection) {
                                            return 1
                                        }

                                        if (b.needsConnection && !a.needsConnection) {
                                            return -1
                                        }

                                        return 0
                                    })
                                    .map((platform: PlatformType, index: Number) => {

                                        const {
                                            id,
                                            name,
                                            needsConnection,
                                            imageAsset,
                                            username
                                        } = platform

                                        return (
                                            <div key={id} className="sy-card">
                                                <div className="flex items-center">
                                                    <div className="artist-img">
                                                        <img
                                                            src={
                                                                imageAsset
                                                            }
                                                        />
                                                        {!needsConnection ? (<div className="absolute bottom-2 right-0 w-4 h-4">
                                                            <img
                                                                src={
                                                                    require("assets/images/tick.svg")
                                                                        .default
                                                                } style={{ maxWidth: 20 }}
                                                            />
                                                        </div>
                                                        ) : null}
                                                    </div>
                                                    <div className="px-3">
                                                        <h5 className="text-dark">{name}</h5>
                                                    </div>
                                                </div>
                                                <div>
                                                    {!needsConnection ? (
                                                        <div className="flex items-center">
                                                            <p className="pl-6 pr-3 text-fontColor-100 text-right break-all	">{username}</p>
                                                            <a
                                                                className="flex-shrink-0 cursor-pointer"
                                                                onClick={() => {
                                                                    selectedEditedPlatform(platform)
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        require("assets/images/right-arrow.svg")
                                                                            .default
                                                                    }
                                                                />
                                                            </a>
                                                        </div>

                                                    ) : (
                                                        <div>
                                                            <button id="spotify-connect" className="text-green cursor-pointer"
                                                                onClick={() => selectedEditedPlatform(platform)}
                                                            >
                                                                Connect
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })}

                            </div>

                        </div>
                    </div>
                )}

            </Fade>
        </Modal>
    )
}