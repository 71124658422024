import styled from "styled-components";

export interface MainContainerProps {
  gap?: number;
  alignItems?: string;
  flexShrink?:string;
  flexDirection?: string;
  margin?: string;
  height?: string;
  maxHeight?: string;
  width?: string;
  padding?: string;
  borderRadius?: string;
  minWidth?: string;
}

export interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  opacity?: number
  margin?: string;
  width?: string;


}

export const MainContainer = styled.div`
  padding: ${({ padding }: MainContainerProps) => padding || "4px"};
  max-height: ${({ maxHeight }: MainContainerProps) => maxHeight || "28px"};
  min-width: ${({ minWidth }: MainContainerProps) => minWidth };
  flex-shrink: ${({ flexShrink }: MainContainerProps) => flexShrink || "inherit"};

  border-radius: ${({ borderRadius }: MainContainerProps) =>
    borderRadius || "4px"};
  gap: ${({ gap }: MainContainerProps) => gap}px;
  background-color: #f5ba23;
  display: flex;
  align-items: ${({ alignItems }: MainContainerProps) =>
    alignItems || "flex-start"};
  flex-direction: ${({ flexDirection }: MainContainerProps) =>
    flexDirection || "row"};
  margin: ${({ margin }: MainContainerProps) => margin};
  height: ${({ height }: MainContainerProps) => height};
  width: ${({ width }: MainContainerProps) => width ? width : '100%'};
`;

export const StyledLabel = styled.span`
margin: ${({ margin }: StyledLabelProps) => margin};
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 16}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  opacity: ${({ opacity }: StyledLabelProps) => opacity || 80}%;
  align-content: baseline;
  align-self: baseline;
`;

export const Icon = styled.img``;
