import { Fragment, FunctionComponent, ReactElement, ReactNode } from "react"
import { ButtonContainer } from "./styles"
import { LoadingLottie } from "components/Loader/LoadingLottie"
import { SystemColors } from "types/globalStyles"
import { ButtonText } from "../Typography"
import { CSSProperties } from "@material-ui/core/styles/withStyles"

const { DISABLED_TEXT_COLOR, ERROR_COLOR, ACCENT_COLOR } = SystemColors

const getColor = (data: {
  disabled?: boolean
  error?: boolean
  customColor?: string
}) => {
  const { customColor, error, disabled } = data

  if (customColor) return customColor as SystemColors
  if (disabled) return DISABLED_TEXT_COLOR
  if (error) return ERROR_COLOR
  return ACCENT_COLOR
}

interface Props {
  text?: string
  content?: ReactNode
  loadingText?: string
  disabled?: boolean
  loading?: boolean
  error?: boolean
  width?: string
  height?: string
  gap?: string
  leftComponent?: ReactElement | null
  rightComponent?: ReactElement | null
  customColor?: string
  minWidth?: string
  padding?: string | undefined;
  style?: CSSProperties,
  className?: string,
  onClick?: () => void
}

const SecondaryButton: FunctionComponent<Props> = ({
  disabled,
  loading,
  loadingText = "",
  error,
  padding,
  text,
  content,
  width = "fit-content",
  height = "fit-content",
  customColor,
  gap,
  leftComponent,
  rightComponent,
  minWidth,
  className,
  style = {},
  onClick,
}: Props) => {
  const color = getColor({
    customColor,
    error,
    disabled,
  })

  return (
    <ButtonContainer
      disabled={disabled}
      onClick={onClick}
      width={width}
      height={height}
      gap={gap}
      minWidth={minWidth}
      color={color}
      padding={padding}
      style={style}
      className={className}
    >
      {loading ? (
        <Fragment>
          <LoadingLottie height={18} width={18} disabled={loading || disabled} black />
          <ButtonText color={color}>{loadingText}</ButtonText>
        </Fragment>
      ) : text ? (
        <Fragment>
          {leftComponent && leftComponent}
          <ButtonText color={color}>{text}</ButtonText>
          {rightComponent && rightComponent}
        </Fragment>
      ) : (
        content
      )}
    </ButtonContainer>
  )
}

export default SecondaryButton
