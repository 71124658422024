/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  FunctionComponent,
  Fragment,
} from "react";
import millify from "millify";
import BrandConnectorIndicator, {
  Props as BrandConnectorIndicatorProps,
} from "components/shareable/BrandConnectorIndicator";
import SpotifyIcon from "assets/images/streaming/square-icons/spotify-sqr.svg";
import InstagramIcon from "assets/images/insta.svg";
import ShazamIcon from "assets/images/shazam.svg";
import {
  FollowersData,
  StreamsData,
  RecordType,
  Platforms,
  ConnectorIndicatorTypes,
  CurrencyCodesType,
  CurrentBrand,
  BrandContent,
  StreamingPlatformsData,
} from "types/global";
import { AdActions, getAdComments, getAdReactions, getAdShares, getAdVideoViews, getConversions, getCostPerConversion, getTotalRemaining, getTotalSpent } from "helpers/CampaignDetails";
import { Container, SymphonyTooltip } from "styles/shared";
import QuestionMarkIcon from "assets/images/question-mark.svg";
import Intercom from "helpers/Intercom";
import getSymbolFromCurrency from "currency-symbol-map";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import { AttributionData } from "../SongAds/CampaignDetails";
import { ShazamConnector } from "./ImpactStats";
import StatsCardRow from "components/shareable/StatCards/row";
import { StatCardDataPoint } from "components/shareable/StatCards/utils";
import MoneyIcon from "assets/images/campaignDetails/money.svg"
import ClickIcon from "assets/images/campaignDetails/click.svg"
import CommentsIcon from "assets/images/campaignDetails/comments.svg"
import EyeIcon from "assets/images/campaignDetails/eye.svg"
import BoostIcon from "assets/images/campaignDetails/Boost.svg"

import MainStatCard from "components/shareable/StatCards/main-stat";
import { SystemColors } from "types/globalStyles";
import { ColumnContainer, ConversionsEventBox, ConversionsEventContainer, RowContainer, SectionContainer, SectionTitleContainer, SpaceBetweenContainer } from './styles';
import { ReactComponent as ConversionsIcon } from "assets/images/campaignDetails/Boost.svg";
import LinkButton from "components/shareable/LinkButton";
import DetailCard from "../PreSave/Details/DetailCard";


import { Caption, Subtitle1 } from "components/shareable/Typography";
import { getWebsiteLinkWithUsersConfiguration } from "../SongAds/utils";

const {
  SPOTIFY_FOLLOWERS,
  SPOTIFY_PLAYLIST_FOLLOWERS,
  SPOTIFY_STREAMS,
  INSTAGRAM_FOLLOWERS,
  SHAZAMS,
  NONE,
} = ConnectorIndicatorTypes;

interface GeneralPerformanceStatsProps {
  impactStats: any;
  performanceStats: any;
  budget: any;
  isUsingConversionsApi?: boolean;
  currencyCode: CurrencyCodesType;
  setMobileView: boolean;
  contentType: 'album' | 'single' | 'playlist';
  currentBrand: CurrentBrand;
  content: any;
  campaign_state: any;
}


const getActions = (
  actions: {
    action_type: string;
    value: number;
  }[],
  actionType: AdActions
) => actions.find((type) => type.action_type === actionType);


export const getIndicatorValue = (
  platform: Platforms.followers | Platforms.streams,
  data: FollowersData | StreamsData
) => {
  let dataByType = null;
  let value = 0;

  if (platform === Platforms.followers) {
    dataByType = data as FollowersData;
    value = dataByType?.data?.growthData?.difference || 0;
  } else {
    dataByType = data as StreamsData;
    value = dataByType?.data?.viewsGrowth?.difference || 0;
  }

  return value;
};

export const getIndicatorPercentage = (
  platform: Platforms.followers | Platforms.streams,
  data: FollowersData | StreamsData
) => {
  let percent = 0;
  let dataByType = null;

  if (platform === Platforms.followers) {
    dataByType = data as FollowersData;
    percent = dataByType?.data?.growthData?.percentage || 0;
  } else {
    dataByType = data as StreamsData;
    const percentageFromData = dataByType?.data?.viewsGrowth?.percentage || 0;
    percent = percentageFromData <= 0 ? 0 : percentageFromData;
  }

  return percent;
};

const getIfConnect = (data: {
  indicatorType: ConnectorIndicatorTypes;
  currentBrand: any;
  isShazamConnected?: boolean;
}) => {
  const { indicatorType, currentBrand, isShazamConnected } = data;

  if (indicatorType === ConnectorIndicatorTypes.SHAZAMS) {
    return !isShazamConnected;
  }

  if (currentBrand) {
    switch (indicatorType) {
      case SPOTIFY_STREAMS:
      case SPOTIFY_FOLLOWERS:
        return !currentBrand.connections.spotify_artist_page;
      case SPOTIFY_PLAYLIST_FOLLOWERS:
        return true
      case INSTAGRAM_FOLLOWERS:
        return (
          !currentBrand.connections.instagram_page ||
          !currentBrand.connections.instagram_page.instagramAccount ||
          !currentBrand.connections.facebook_page ||
          !currentBrand.connections.facebook_page.id ||
          currentBrand.connections.reauthenticateFbUser
        );

      default:
        //TODO: Add error handler
        console.error(
          "Not valid connector indicator type on GeneralPerformance component"
        );
        return false;
    }
  }

  return true;
};

const getIfShowShazamIndicator = (args: {
  recordType?: RecordType;
  data?: FollowersData | StreamsData;
  connect?: boolean;
  platform?: Platforms;
}) => {
  let chartData = [];
  const { recordType, data, connect, platform } = args;

  if (platform === Platforms.followers) {
    chartData = (data as FollowersData)?.data?.chartData;
  } else {
    chartData = (data as StreamsData)?.data?.data;
  }

  return (
    recordType === RecordType.record &&
    (connect ? connect : Boolean(chartData?.length))
  );
};

export const getBrandConnectorIndicatorProps = (args: {
  indicatorType: ConnectorIndicatorTypes;
  platform: Platforms.followers | Platforms.streams;
  data: FollowersData | StreamsData;
  currentBrand: any;
  contentName?: string;
  brandSlug?: string;
  contentSlug?: string;
  isShazamConnected?: boolean;
  recordType?: RecordType;
  handleSaveIgFbConnector?: () => void;
  reloadOnConnectShazam?: () => void;
}) => {


  const {
    indicatorType,
    currentBrand,
    platform,
    data,
    contentName,
    brandSlug,
    contentSlug,
    isShazamConnected,
    recordType,
    handleSaveIgFbConnector,
    reloadOnConnectShazam,
  } = args;
  const value = getIndicatorValue(platform, data);
  const percent = getIndicatorPercentage(platform, data);
  const connect = getIfConnect({
    indicatorType,
    currentBrand,
    isShazamConnected,
  });
  const followersVariationLabel = value > 0 ? "up" : value < 0 ? "down" : "";
  const showShazams = getIfShowShazamIndicator({
    recordType,
    data,
    platform,
    connect,
  });

  switch (indicatorType) {
    case SPOTIFY_FOLLOWERS:
      return {
        alt: "spotify_icon.svg",
        icon: SpotifyIcon,
        mainLabel: `Spotify followers ${followersVariationLabel}`,
        value,
        unit: "followers",
        percent,
        connectorType: SPOTIFY_FOLLOWERS,
        currentBrand,
        roundPercent: true,
        show: true,
      };
    case SPOTIFY_PLAYLIST_FOLLOWERS:
      return {
        alt: "spotify_icon.svg",
        icon: SpotifyIcon,
        mainLabel: `Spotify Playlist followers ${followersVariationLabel}`,
        value,
        unit: "streams",
        percent,
        connectorType: SPOTIFY_PLAYLIST_FOLLOWERS,
        currentBrand,
        roundPercent: true,
        show: true,
      };

    case SPOTIFY_STREAMS:
      return {
        alt: "spotify_icon.svg",
        icon: SpotifyIcon,
        mainLabel: "Spotify streams up",
        value,
        unit: "total streams",
        percent,
        connectorType: SPOTIFY_STREAMS,
        currentBrand,
        roundPercent: true,
        show: value > 0,
      };
    case INSTAGRAM_FOLLOWERS:
      return {
        alt: "instagram_icon.svg",
        icon: InstagramIcon,
        mainLabel: `Instagram followers ${followersVariationLabel}`,
        value,
        unit: "followers",
        percent,
        connect,
        connectorType: INSTAGRAM_FOLLOWERS,
        currentBrand,
        roundPercent: true,
        show: true,
        handleSaveIgFbConnector,
      };
    case SHAZAMS:
      return {
        alt: "shazam_icon.svg",
        icon: ShazamIcon,
        mainLabel: "Shazams up",
        value,
        unit: "Shazams",
        percent,
        connectorType: SHAZAMS,
        connect,
        currentBrand,
        contentName,
        brandSlug,
        contentSlug,
        roundPercent: true,
        show: showShazams,
        reloadOnConnectShazam,
      };
    default:
      return {
        alt: "",
        icon: "",
        mainLabel: "",
        value: 0,
        unit: "",
        percent: 0,
        connectorType: NONE,
        connect: false,
        currentBrand,
        roundPercent: true,
        show: false,
      };
  }
};



interface ConnectorsProps {
  attributionData: AttributionData[];
  brandSlug: string;
  contentSlug?: string;
  contentName?: string;
  reloadState: () => void;
  showWhenEmpty: boolean;
}

export const Connectors: FunctionComponent<ConnectorsProps> = ({
  attributionData,
  brandSlug,
  contentSlug,
  contentName,
  reloadState,
  showWhenEmpty = true
}: ConnectorsProps) => {
  const connectors = attributionData.filter(({ description }) => description.show);
  const filteredConnectors = showWhenEmpty ? connectors : connectors.filter(connector => connector.data.dailyStats.length > 0);
  
  // Filter out connectors with showGrowthCalculation set to false
  const growthCalculationConnectors = filteredConnectors.filter(connector => {
    const connectorType = connector.description.connectorType;
    return ![
      ConnectorIndicatorTypes.INSTAGRAM_POST_REACH,
      ConnectorIndicatorTypes.INSTAGRAM_POST_TOTAL_INTERACTIONS,
      ConnectorIndicatorTypes.INSTAGRAM_PROFILE_REACH,
      ConnectorIndicatorTypes.INSTAGRAM_PROFILE_VISITS,
    ].includes(connectorType);
  });

  const groupConnectors = getGroupConnectors(growthCalculationConnectors);

  return (
    <React.Fragment>
      {groupConnectors.map((arr, idx) => (
        <React.Fragment key={idx}>
          {Boolean(arr.length) && (
            <div className="border-b border-t py-3 md:py-5 grid grid-cols-12">
              {arr.map((props: AttributionData, jdx) => {
                let label = `${props.description.mainLabel}`
                const percent = props.data.percentageChange
                if (percent >= 0) {
                  label = `${props.description.mainLabel} up`
                } else {
                  label = `${props.description.mainLabel} down`
                }

                // if shazams need connection, show the connector here.
                // otherwise render this as we normally would
                if (contentSlug && contentName && props.description.connectorType === SHAZAMS
                  && props.description.needsConnection
                ) {
                  return (
                    <div key={`${idx}-${jdx}`} className="col-span-12 md:col-span-6">
                      <ShazamConnector
                        brandSlug={brandSlug}
                        contentSlug={contentSlug}
                        songName={contentName}
                        reload={reloadState}
                      />
                    </div>
                  )
                }

                // In the case where we're still pulling data,
                // let the user know
                if (props.data.dailyStats.length === 0) {
                  return (
                    <div key={`${idx}-${jdx}`} className="col-span-12 md:col-span-6">
                      <BrandConnectorIndicator
                        showGrowthCalculation={true}
                        icon={props.description.icon}
                        alt={props.description.alt}
                        secondaryLabel={`We're still pulling data for ${props.description.mainLabel}. Please check back later.`}
                      />
                    </div>
                  )
                }

                return (
                  <div key={`${idx}-${jdx}`} className="col-span-12 md:col-span-6">
                    <BrandConnectorIndicator
                      icon={props.description.icon}
                      alt={props.description.alt}
                      mainLabel={label}
                      showGrowthCalculation={true}
                      percent={props.data.percentageChange}
                      value={props.data.netChange}
                      roundPercent={true}
                      unit={props.description.unit}
                    />
                  </div>
                )
              })}
            </div>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

const getGroupConnectors = (connectors: AttributionData[]) => {
  const columns = window.innerWidth < 768 ? 1 : 2; // Adjust columns based on screen width
  const rows = Math.ceil(connectors.length / columns);
  const groupConnectors: AttributionData[][] = Array.from({ length: rows }, () => []);

  connectors.forEach((connector, idx) => {
    groupConnectors[Math.floor(idx / columns)].push(connector);
  });

  return groupConnectors;
};
export default function GeneralPerformanceStats({
  performanceStats,
  budget,
  isUsingConversionsApi = false,
  currencyCode,
  setMobileView,
  currentBrand,
  contentType,
  content,
  campaign_state
}: GeneralPerformanceStatsProps) {

  const {
    actions = [],
    inline_link_clicks = "0",
    cost_per_inline_link_click = "0",
    spend = "0",
    impressions = "0",
    reach = "0",
    cpm = "0",
  } = performanceStats || {};


  const shareActions = getActions(actions, AdActions.post);
  const thruplayActions = getActions(actions, AdActions.video_view);
  const commentActions = getActions(actions, AdActions.comment);
  const reactions = getActions(actions, AdActions.likes);

  const inlineLinkClicks = inline_link_clicks || 0;
  const totalImpressions = (impressions || 0)
  const totalReach = (reach || 0)

  const costPerClickOnAds = parseFloat(cost_per_inline_link_click).toFixed(2);
  const totalSpent = getTotalSpent(spend);
  const totalRemaining = getTotalRemaining(budget, spend);
  const adShares = getAdShares(shareActions);
  const adComments = getAdComments(commentActions);
  const adThruplays = getAdVideoViews(thruplayActions);
  const adLikes = getAdReactions(reactions);


  // Example array of stats
  const stats: StatCardDataPoint[] = [
    {
      title: `Total Spent`, value: totalSpent,
      hideCurrencyLabel: true,
      isCurrency: true, currency: currencyCode, icon: MoneyIcon
    },
    { title: 'Reach', value: totalReach, isCurrency: false, icon: EyeIcon },
    { title: 'Likes & Reactions', value: adLikes, isCurrency: false, icon: EyeIcon },
    { title: 'Ad Link Clicks', value: inlineLinkClicks, isCurrency: false, currency: currencyCode, icon: ClickIcon },

    { title: 'Ad Comments', value: adComments, isCurrency: false, currency: currencyCode, icon: CommentsIcon },
    { title: 'Ad Shares', value: adShares, isCurrency: false, icon: EyeIcon },

    { title: 'Impressions', value: totalImpressions, isCurrency: false, currency: currencyCode, icon: CommentsIcon },
    {
      title: 'CPM', value: parseFloat(cpm), isCurrency: true,
      hideCurrencyLabel: true,

      currency: currencyCode, icon: CommentsIcon
    },

    // Add more stats as needed
  ];
  // Function to render StatsCardRows based on the stats array
  const renderStatsCardRows = (stats: StatCardDataPoint[]) => {
    return <StatsCardRow stats={stats} />
  };



  function renderConversionsStats() {

    // conditionally render different UI based on the type of campaign
    const isPlaylistCampaign = contentType === 'playlist';

    const getConversionsStats = () => {
      const {
        inline_link_clicks = 0,
        cost_per_inline_link_click = 0,
        cost_per_conversion = [],
        conversions = [],
      } = performanceStats || {}


      return {
        totalClicks: parseInt(inline_link_clicks),
        costPerClickToStreamingService: Number(getCostPerConversion(cost_per_conversion)) || 0,
        costPerClickOnAds: Number(parseFloat(cost_per_inline_link_click).toFixed(2)) || 0,
        totalStreamingLinkClicks: Number(getConversions(conversions)),
      }
    }


    const handleOnClickWebsitePreview = () => {
      try {
        const websiteUrl = getWebsiteLinkWithUsersConfiguration({
          brand: currentBrand,
          selectedRecord: content,
          selectedPlatforms: campaign_state?.campaign?.creative?.captions,
          isConversionsEnabled: isUsingConversionsApi,
        })

        window.open(websiteUrl, '_blank')
      } catch (e) {
        console.log('e', e)
      }
    }

    let primaryGoal = "Your ads will optimize to grow streams."
    let primaryConversionAction = "Clicks to Streaming Services"
    let primaryConversionStatTitle = "Streaming Link Clicks"
    let conversionActionText = `${getConversionsStats().totalStreamingLinkClicks} people clicked out to a streaming service after visiting your music landing page.`
    if (isPlaylistCampaign) {
      primaryGoal = "Your ads will optimize to grow playlist engagement."
      primaryConversionAction = "Clicks to your Playlist"
      primaryConversionStatTitle = "Clicks to Playlist"
      conversionActionText = `${getConversionsStats().totalStreamingLinkClicks} people clicked out to your playlist on Spotify after visiting your landing page.`
    }


    const {
      mainTrack = null
    } = content;
    return (
      <Fragment>
        <SectionContainer>
          <SectionTitleContainer
            flexDirection={setMobileView ? "column" : "row"} alignItems={setMobileView ? "flex-start" : "end"} marginTop="16px">
            <SectionTitleContainer alignItems="flex-start">
              <RowContainer>
                <ConversionsIcon width={16} height={16} />
                <Caption>Symphony Conversions Enabled</Caption>
              </RowContainer>
              <Subtitle1 weight={600}>Performance</Subtitle1>
            </SectionTitleContainer>
            <ConversionsEventContainer>
              <Container backgroundColor={SystemColors.PAPER_BACKGROUND_CONTAINER} padding="8px"
                borderRadius="8px" display="flex" flexDirection="column"
                alignItems="flex-start" justifyContent={"flex-start"}>
                <Container display={setMobileView ? "block" : "flex"}>
                  <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>Goal:&nbsp;</Caption>
                  <Caption color={SystemColors.PRIMARY_TEXT_COLOR}>{primaryGoal}</Caption>
                </Container>
                {mainTrack?.name && (
                  <Container display={setMobileView ? "block" : "flex"}
                  >
                    <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>Autoplay:&nbsp;</Caption>
                    <Caption color={SystemColors.PRIMARY_TEXT_COLOR}>"{mainTrack.name}" on your playlist</Caption>
                  </Container>
                )}

              </Container>
              <SymphonyTooltip
                className="block w-6 h-6 cursor-pointer"
                title="See more"
                arrow
                onClick={() => Intercom.OpenGrowConversionsArticle()}
              >
                <img
                  className="w-6 h-6"
                  alt="question-mark-icon.svg"
                  src={QuestionMarkIcon}
                />
              </SymphonyTooltip>
            </ConversionsEventContainer>
          </SectionTitleContainer>
        </SectionContainer>

        <ColumnContainer>
          <DetailCard
            svgIcon={ClickIcon}
            title="Ad Clicks"
            firstStatTitle="Clicks"
            // subtitle={`The number of people who saw your ad on IG + FB and clicked to your link.`}
            secondStatTitle="Cost-per-click"
            value={getConversionsStats().totalClicks}
            subtitle={
              <Caption color={SystemColors.PRIMARY_TEXT_COLOR}>
                The number of people who saw your ad on IG + FB and clicked to your {' '}
                <LinkButton
                  text="music landing page"
                  textAlign="left"
                  fontSize="12px"
                  showInline
                  onClick={handleOnClickWebsitePreview}
                />.
              </Caption>
            }
            secondStatValue={getConversionsStats().costPerClickOnAds}
            isSecondStatMoney={true}
            upgradeToPro={false}
            isMoney={false}
            currency={currencyCode}
          />
          <DetailCard
            currency={currencyCode}
            svgIcon={BoostIcon}
            title={primaryConversionAction}
            firstStatTitle={primaryConversionStatTitle}
            secondStatTitle="Cost-per-conversion"
            isSecondStatMoney={true}
            upgradeToPro={false}
            value={getConversionsStats().totalStreamingLinkClicks}
            subtitle={
              <Caption color={SystemColors.PRIMARY_TEXT_COLOR}>
                {conversionActionText}
              </Caption>
            }
            secondStatValue={getConversionsStats().costPerClickToStreamingService}
            isMoney={false}
          />
        </ColumnContainer>


      </Fragment >
    )
  }

 


  return (
    <React.Fragment>
      {isUsingConversionsApi ? (
        renderConversionsStats()
      ) : (
        <Fragment>
          <MainStatCard
            currencyCode={currencyCode}
            stats={[
              {
                primaryTitle: 'Total Ad Clicks',
                subtitle: inlineLinkClicks <= 1 ? `The number of people who clicked your ad link.` : `${inlineLinkClicks} people clicked to streaming services on your ad link.`,
                icon: ClickIcon,
                stats: [
                  {
                    title: 'Total Clicks',
                    value: inlineLinkClicks,
                    isMoney: false,
                  },
                  {
                    title: 'Cost-per-Click',
                    value: costPerClickOnAds,
                    isMoney: true
                  }
                ]
              }
            ]}
          />
        </Fragment>
      )
      }
      {renderStatsCardRows(stats)}
     
    </React.Fragment>
  );
}
