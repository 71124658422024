import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints'
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext'
import { isBrandAdministrator } from 'utils'
import WebsiteTypeBadge from 'components/shareable/ContentTypeBadge/website'
import LinkButton from 'components/shareable/LinkButton'
import LinkContainer from 'components/shareable/LinkContainer'
import { Caption } from 'components/shareable/Typography'
import { StarsIcon } from 'components/svg-icons'
import PresaveMiniature from 'pages/post-auth/MarketingPage/PreSave/Components/PresaveMiniature'
import { Container, StyledLabel } from 'styles/shared'
import { BrandContent, Website, WEBSITE_TYPES, WebsiteType } from 'types/global'
import { SystemColors } from 'types/globalStyles'
import { WEBSITE_CREATION_TYPE } from '../../types'
import { RowContainer } from 'pages/post-auth/MarketingPage/Components/CampaignCard/styles'

dayjs.extend(relativeTime)

interface SubtitleProps {
    children: React.ReactNode
}

interface WebsiteCardProps {
    thumbnailSrc: string
    content: BrandContent
    titleText: string
    subtitle?: React.ReactNode
    createdAt?: string
    updatedAt?: string
    websiteUrl: string
    style?: Website
    websiteType?: WebsiteType
    linkTo?: string
    websiteSubtypeLabel?: string | null
    creationType?: WEBSITE_CREATION_TYPE
    onClick: () => void
    setActionedItem?: undefined | null | ((item: BrandContent) => void)
}


export const Title: React.FC<{ text: string }> = ({ text }) => <h3 className="font-normal text-lg">{text}</h3>

export const Subtitle: React.FC<SubtitleProps> = ({ children }) => (
    <div className="text-sm text-gray-500">{children}</div>
)

const defaultStyle = {
    bgType: 'color',
    primaryColor: '#ffffff',
    buttonConfig: 'stacked',
    secondaryColor: '#ffffff',
    bgColor: 'red',
}

const WebsiteListItem: React.FC<WebsiteCardProps> = ({
    thumbnailSrc,
    titleText,
    createdAt,
    updatedAt,
    linkTo,
    websiteUrl,
    websiteSubtypeLabel,
    creationType,
    websiteType = WEBSITE_TYPES.RECORD_STREAMS,
    style = defaultStyle,
    content,
    onClick,
}) => {
    const { mobileView, largeView } = useMediaBreakpoints()
    const { currentBrand } = useContext(CurrentBrandContext)
    const history = useHistory()

    const hasAdminAccess = isBrandAdministrator(currentBrand!)

    const handleOnEditWebsite = () => {
        if (linkTo) history.push(linkTo)
    }

    function renderCreationType() {
        let text = null

        if (websiteType === WEBSITE_TYPES.RECORD_STREAMS) {
            switch (creationType) {
                case WEBSITE_CREATION_TYPE.AUTOMATIC:
                    text = 'This site was created automatically when your release came out.'
                    break
                case WEBSITE_CREATION_TYPE.PRESAVE:
                    text = 'This site was converted to a release page after your pre-save campaign ended.'
                    break
                case WEBSITE_CREATION_TYPE.CAMPAIGN:
                    text = 'This website is linked to an ads campaign you\'re running.'
                    break
                default:
                    break
            }
        }

        if (!text) return null

        return (
            <div className='mb-1'>
                <Subtitle>
                    <Container display='flex' flexDirection='row' gap="4px">
                        <StarsIcon color={SystemColors.ACCENT_COLOR} />
                        <StyledLabel>{text}</StyledLabel>
                    </Container>
                </Subtitle>
            </div>
        )
    }

    let linkedContent = (
        <div className='flex flex-row items-center'
            style={linkTo ? { width: '100%' } : !largeView ? { width: '100%' } : { width: '50%' }}
        >
            <div className='mr-4'>
                <PresaveMiniature
                    image={thumbnailSrc}
                    style={style} />
            </div>
            <div className="flex flex-col justify-center items-start">
                <div className="mb-1">
                    <Title text={titleText} />
                </div>
                <div className="mb-1">
                    <RowContainer>
                        <WebsiteTypeBadge websiteType={websiteType} additionalLabel={websiteSubtypeLabel} />
                    </RowContainer>
                </div>
                {renderCreationType()}
                {dayjs(createdAt).isSame(dayjs(updatedAt)) ? (
                    <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>Created {dayjs(createdAt).fromNow()}</Caption>
                ) : (
                    <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>Last updated {dayjs(updatedAt).fromNow()}</Caption>
                )}
            </div>
        </div>
    )

    if (linkTo) {
        linkedContent = (
            <Link
                to={linkTo} style={{ textDecoration: 'none', width: !largeView ? '100%' : '50%' }}>
                {linkedContent}
            </Link>
        )
    }

    return (
        <div className={`cursor-pointer flex ${largeView ? 'flex-row' : 'flex-col'} justify-between items-center border-b px-0 py-5 md:px-4 md:py-7`}>
            <div className={`w-full flex items-center ${largeView ? 'justify-between' : 'justify-center'} gap-4 ${!largeView ? 'flex-col' : ''}`}>
                {linkedContent}
                <Container
                    gap="24px"
                    display='flex'
                    flexDirection={mobileView ? 'column' : 'row'}
                    justifyContent={mobileView ? 'center' : 'flex-end'}
                    alignItems='center'
                    width={!largeView ? '100%' : '50%'}
                >
                    <LinkContainer
                        url={websiteUrl}
                        showShareButton={false}
                        width={largeView ? '80%' : '100%'}
                    />
                    {hasAdminAccess && <LinkButton text='Edit site' onClick={handleOnEditWebsite}/>}
                </Container>
            </div>
        </div>
    )
}

export default WebsiteListItem