import { ReactComponent as PlayIcon } from "assets/images/play-purple.svg";
import {
  useStyles,
  VideoContainer,
  VideoCover,
  HeadlineContainer,
} from "./styles";
import PrimaryButton from "../PrimaryButton";
import { FunctionComponent, useMemo, useRef } from "react";
import VideoModalLandscape from "pages/post-auth/MarketingPage/Components/VideoModalLandscape";
import { Headline1, Body2 } from "../Typography";
import { SystemColors } from "types/globalStyles";

interface Props {
  title: string;
  videoUrl: string;
  thumbnail: string;
  description?: string;
  titleModalOpened?: string;
  descriptionModalOpened?: string;
  showDescription?: boolean;
  openModal: boolean;
  hideThumbnail?: boolean;
  buttonPadding?: string;
  onOpenModal: () => void;
  onCloseModal: () => void;
  intercomArticle?: () => void;
}

const StepVideoContainer: FunctionComponent<Props> = ({
  title,
  videoUrl,
  thumbnail,
  titleModalOpened,
  descriptionModalOpened,
  description,
  showDescription = false,
  hideThumbnail = false,
  openModal,
  buttonPadding,
  onOpenModal,
  onCloseModal,
  intercomArticle,
}: Props) => {
  const classes = useStyles();
  const parent = useRef<HTMLDivElement>(null);

  const parentWidth = useMemo(() => parent.current?.offsetWidth || 0, [parent.current]);

  if (hideThumbnail)
    return (
      <VideoModalLandscape
        {...{ title, description, videoUrl }}
        open={openModal}
        buttonAction={intercomArticle}
        closeModal={onCloseModal}
      />
    );

  return (
    <VideoContainer thumbnail={thumbnail} ref={parent}>
      <VideoCover />
      <HeadlineContainer>
        <Headline1 color={SystemColors.WHITE_TEXT_COLOR}>{title}</Headline1>
        {description && showDescription && (
          <Body2 color={SystemColors.WHITE_TEXT_COLOR}>{description}</Body2>
        )}
        <PrimaryButton
          width="fit-content"
          padding={buttonPadding}
          height="48px"
          variant="dark"
          text="Watch video"
          iconLeft={parentWidth >= 194 ? <PlayIcon className={classes.videoIconStroke} /> : undefined}
          onClick={onOpenModal}
        />
      </HeadlineContainer>
      <VideoModalLandscape
        {...{
          title,
          description,
          videoUrl,
          titleModalOpened,
          descriptionModalOpened,
          showDescription,
        }}
        open={openModal}
        buttonAction={intercomArticle}
        closeModal={onCloseModal}
      />
    </VideoContainer>
  );
};

export default StepVideoContainer;
