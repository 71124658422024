import LinkButton from 'components/shareable/LinkButton';
import { Body2, Caption } from 'components/shareable/Typography';
import { FC } from 'react';
import { SystemColors } from 'types/globalStyles';
import { ConnectionIcon, useStyles } from './styles';
import { ReactComponent as CheckIcon } from 'assets/images/check-black.svg';
import SecondaryButton from 'components/shareable/SecondaryButton';
import { IgBanner } from '../IgBanner';

interface StepDetailProps {
  isCompleted: boolean;
  stepName: string;
  stepIcon?: JSX.Element;
  showStepName?: boolean;
  iconContainerColor?: string;
  thumbnail?: string;
  title: string;
  descriptions?: string[];
  canEdit?: boolean;
  tooltip?: string;
  disabledButton?: boolean;
  errorText?: string; 
  onButtonClick: () => void;
}

export const StepDetail: FC<StepDetailProps> = ({
  isCompleted,
  stepName,
  stepIcon,
  showStepName = true,
  iconContainerColor,
  thumbnail,
  title,
  descriptions,
  canEdit = true,
  tooltip,
  disabledButton,
  errorText,
  onButtonClick,
}) => {
  const classes = useStyles();

  if (isCompleted) {
    return (
      <div className={classes.mainContainer}>
        <div className={classes.descriptionContainer}>
          <div>
            {thumbnail ? (
              <img src={thumbnail} alt={title} className={classes.thumbnail} />
            ) : (
              stepIcon && (
                <ConnectionIcon backgroundColor={iconContainerColor}>
                  {stepIcon}
                </ConnectionIcon>
              )
            )}
          </div>
          <div className='flex flex-col'>
            {showStepName && (
              <div className='flex gap-1 items-center'>
                {stepIcon && <div className={classes.smallIcon}>{stepIcon}</div>}
                <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                  {stepName}
                </Caption>
              </div>
            )}
            <div className='flex flex-col gap-1'>
              <Body2>{title}</Body2>
              {descriptions?.map((description, index) => (
                <Caption key={index} color={SystemColors.SECONDARY_TEXT_COLOR}>
                  {description}
                </Caption>
              ))}
              {errorText && (
                <Caption color={SystemColors.WARNING_COLOR}>
                  {errorText}
                </Caption>
              )}
            </div>
          </div>
        </div>
        <div className='flex gap-4 items-center'>
          <LinkButton
            text='Edit'
            onClick={onButtonClick}
            disabled={!canEdit}
            tooltip={tooltip}
          />
          <div className={classes.greenContainer}>
            <Body2 color={SystemColors.SUCCESS}>Connected</Body2>
            <CheckIcon height={24} width={24} className={classes.greenIcon} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.descriptionContainer}>
        {stepIcon && <ConnectionIcon grayedOut>{stepIcon}</ConnectionIcon>}
        <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>{title}</Body2>
      </div>
      <SecondaryButton text='Connect' onClick={onButtonClick} height='39px' disabled={disabledButton}/>
    </div>
  );
};
