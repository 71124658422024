import { FunctionComponent } from "react";
import { StyledLabel, Container } from "../../styles";
import { AvatarUserStatus } from "components/shareable/AvatarUserStatus";
import FreePlanView from "../FreePlanView";
import ProPlanView from "../ProPlanView";
import { BrandCurrency, BrandSubscriptionDetails, OrganizationData } from "types/global";
import { CurrencyDropdown } from './CurrencyDropdown/index';
import { CURRENCY_SYMBOLS } from "../../../../../constants";
import { DEFAULT_CURRENCY } from "pages/post-auth/MarketingPage/constants";
import OrganizationProPlanView from "../ProPlanView/organization";

interface Props {
  brandSlug?: string | null;
  brandName?: string | null;
  isProUser?: boolean;
  avatar?: string;
  isOnOldPlan: boolean;
  isAdmin: boolean;
  subscriptionDetails?: BrandSubscriptionDetails | undefined;
  currency?: BrandCurrency;
  organization?: OrganizationData;
  brandBelongsToOrganization?: boolean;
}

const BillingTab: FunctionComponent<Props> = ({
  brandSlug,
  brandName,
  isProUser,
  avatar,
  isOnOldPlan,
  isAdmin,
  subscriptionDetails,
  currency,
  organization,
  brandBelongsToOrganization
}: Props) => {

  function renderPrimaryView() {
    // Helper function to render Pro/Free view based on user status
    const renderPlanView = () => {
      if (isProUser) {
        return <ProPlanView
          isAdmin={isAdmin}
          subscriptionDetails={subscriptionDetails}
          isOnOldPlan={isOnOldPlan}
          brandSlug={brandSlug} 
        />;
      }
      return <FreePlanView
        subscriptionDetails={subscriptionDetails}
        isAdmin={isAdmin}
        brandSlug={brandSlug}
      />;
    };

    // If organization is ready, check if brand belongs to org
    if (organization?.status === 'ready') {
     

      // Show org view if brand belongs to org, otherwise show plan view
      return brandBelongsToOrganization ? (
        <OrganizationProPlanView
          organization={organization}
          isAdmin={isAdmin}
          isProUser={!!isProUser}
        />
      ) : renderPlanView();
    }

    // Default to plan view if no org or org not ready
    return renderPlanView();
  }

  return (
    <Container>
      <StyledLabel>
        <Container
          margin="32px 0px 0px 0px"
          padding="0px 0px 24px 0px"
          borderBottom="1px solid #E5E7EB"
        >
          <StyledLabel fontSize={20}>Billing</StyledLabel>
        </Container>
      </StyledLabel>
      <Container padding="24px 0px" borderBottom="1px solid #E5E7EB">
        <AvatarUserStatus
          {...{ brandName, isProUser, avatar }}
          userContainerProps={{ gap: "4px" }}
          mainContainerProps={{ gap: "16px" }}
          userNameTextProps={{ fontSize: 18 }}
          proTextProps={{ fontSize: 12, color: "#E08507" }}
        />
      </Container>
      <CurrencyDropdown
        initialValue={currency?.code
          ? `${currency.code} (${CURRENCY_SYMBOLS[currency.code]})`
          : `${DEFAULT_CURRENCY} (${CURRENCY_SYMBOLS[DEFAULT_CURRENCY]})`
        }
      />
      {renderPrimaryView()}
    </Container>
  );
};

export default BillingTab;
