import { Fragment, FunctionComponent, useContext } from 'react'
import {
  CardContainer,
  IconContainer,
  CardTitle,
  CardStatHeader,
  CardHeaderLabel,
  CardStatsContainer,
  CardSingleStatContainer,
  SingleStatTitle,
  StatValueLabel,
  TitleContainer,
} from './styles'
import { useMediaQuery, useTheme } from '@material-ui/core'
import ArticleTitle, { Article } from 'components/shareable/ArticleTitle'
import { ReactComponent as GreyArrowRightIcon } from 'assets/images/arrow-right-icon.svg'
import { ReactComponent as GreyPlusIcon } from 'assets/images/plus.svg'
import { ReactComponent as GreyEqualIcon } from 'assets/images/equal.svg'
import { UpgradeModalContext } from 'Hooks/UpgradeModalContext'
import { CurrencyCodesType } from 'types/global'
import getSymbolFromCurrency from 'currency-symbol-map'
import { DEFAULT_CURRENCY } from '../../constants'
import { Container } from 'styles/shared'
import UpgradeToProChip from 'components/shareable/UpgradeToProChip'

interface Props {
  svgIcon?: any
  title: string
  subtitle?: string | JSX.Element
  upgradeToPro: boolean
  proExclusive?: boolean
  value: string | number
  description?: JSX.Element
  isMoney?: boolean
  arrow?: boolean
  firstStatTitle: string
  secondStatTitle?: string
  secondStatValue?: string | number
  isSecondStatMoney?: boolean
  currency?: CurrencyCodesType
  showCurrencyLabel?: boolean
  additionalContent?: JSX.Element | string
  // New props for DetailCard2 compatibility
  thirdStatTitle?: string
  thirdStatValue?: number
  useThreeColumnLayout?: boolean  // Renamed from useDetailCard2Layout
}

const DetailCard: FunctionComponent<Props> = ({
  title,
  value,
  description,
  isMoney,
  firstStatTitle,
  secondStatTitle,
  secondStatValue,
  svgIcon,
  subtitle,
  upgradeToPro,
  isSecondStatMoney,
  arrow,
  currency,
  showCurrencyLabel = true,
  proExclusive,
  additionalContent = null,
  // New props
  thirdStatTitle,
  thirdStatValue,
  useThreeColumnLayout = false,  // Renamed from useDetailCard2Layout
}) => {
  const { handleOpenModal: handleOpenUpgradeToProModal } = useContext(UpgradeModalContext)

  const theme = useTheme()
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(useThreeColumnLayout ? 2101 : 701))
  const mobileView = useMediaQuery(theme.breakpoints.down(1000))

  const secondStat = secondStatTitle && secondStatValue !== undefined

  const valueToDisplay: string | number = typeof value === 'number' ? Math.trunc(value) : parseInt(value)
  const secondValueToDisplay: number | string | undefined = secondStatValue !== undefined 
    ? (typeof secondStatValue === 'number' ? Math.trunc(secondStatValue) : parseInt(secondStatValue))
    : undefined
  const thirdValueToDisplay: string | undefined = thirdStatValue !== undefined ? Math.trunc(thirdStatValue).toLocaleString() : undefined

  const getCardTitle = () => {
    const titleElement = <CardTitle>{title}</CardTitle>
    return title !== 'Forever Saves' ? (
      titleElement
    ) : (
      <ArticleTitle
        children={titleElement}
        article={Article.ForeverSaves}
        toolTipText="Click to learn more about how Forever Saves work."
      />
    )
  }

  function renderSubtitle() {
    if (subtitle) {
      if (typeof subtitle === 'string') {
        return <SingleStatTitle>{subtitle}</SingleStatTitle>
      } else if (typeof subtitle === 'object') {
        return subtitle
      }
    } else {
      return null
    }
  }

  return (
    <CardContainer flexDirection={'column'}>
      <Container 
        width="100%" 
        flexDirection={setResponsiveView ? 'column' : 'row'}
        alignItems={setResponsiveView ? 'flex-start' : 'space-between'}
        justifyContent={setResponsiveView ? 'flex-start' : 'space-between'}
        gap={setResponsiveView ? '16px' : '8px'}
        display='flex'
      >
        <CardStatHeader maxWidth={setResponsiveView ? '100%' : '50%'}>
          <IconContainer>{typeof svgIcon === 'string' ? <img src={svgIcon} /> : svgIcon}</IconContainer>
          <TitleContainer>
            <CardHeaderLabel>
              {getCardTitle()}
              {proExclusive && <div className='ml-2'><UpgradeToProChip type="gem" color="purple" height={18} width={18} /></div>}
            </CardHeaderLabel>
            {renderSubtitle()}
            {useThreeColumnLayout && <p className="text-3xl font-medium">{thirdValueToDisplay}</p>}
          </TitleContainer>
        </CardStatHeader>
        {upgradeToPro ? (
          <button
            className="text-sm text-primary border border-primary text-semibold rounded-full px-4 py-2 font-semibold"
            onClick={() => {
              handleOpenUpgradeToProModal({
                source: `Presave Campaign Details - ${title}`
              })
            }}
          >
            Subscribe
          </button>
        ) : (
          <CardStatsContainer
            justifyContent={useThreeColumnLayout ? 'space-between' : (setResponsiveView ? 'space-around' : 'space-between')}
            width={setResponsiveView ? '100%' : 'auto'}
            maxWidth={setResponsiveView ? 'auto' : description ? '70%' : '40%'}
            gap={setResponsiveView ? '0px' : useThreeColumnLayout ? '24px' : (arrow ? '24px' : '32px')}
          >
            <CardSingleStatContainer width={useThreeColumnLayout ? "auto" : "auto"}>
              <SingleStatTitle>{firstStatTitle}</SingleStatTitle>
              {description || (showCurrencyLabel && !useThreeColumnLayout) ? (
                <StatValueLabel>
                  {isMoney ? `${getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)}${valueToDisplay.toLocaleString('en')}` : valueToDisplay.toLocaleString('en', { minimumFractionDigits: 0 })}
                </StatValueLabel>
              ) : null}
              {useThreeColumnLayout && <StatValueLabel>{valueToDisplay.toLocaleString()}</StatValueLabel>}
            </CardSingleStatContainer>
            {secondStat && (
              <Fragment>
                {useThreeColumnLayout ? <GreyPlusIcon /> : (arrow && <GreyArrowRightIcon />)}
                <CardSingleStatContainer width={useThreeColumnLayout ? "auto" : "auto"}>
                  <SingleStatTitle>{secondStatTitle}</SingleStatTitle>
                  <StatValueLabel>
                    {useThreeColumnLayout
                      ? secondValueToDisplay?.toLocaleString()
                      : (isSecondStatMoney
                        ? `${getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)}${secondStatValue?.toLocaleString('en', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`
                        : secondValueToDisplay)}
                  </StatValueLabel>
                </CardSingleStatContainer>
              </Fragment>
            )}
            {useThreeColumnLayout && !mobileView && thirdStatTitle && (
              <Fragment>
                <GreyEqualIcon />
                <CardSingleStatContainer width="auto">
                  <SingleStatTitle>{thirdStatTitle}</SingleStatTitle>
                  <StatValueLabel>{thirdValueToDisplay}</StatValueLabel>
                </CardSingleStatContainer>
              </Fragment>
            )}
          </CardStatsContainer>
        )}
      </Container>
      {additionalContent}
    </CardContainer>
  )
}

export default DetailCard