import { FacebookAdAccount, FacebookBusinessManager, FacebookPageType, InstagramPageType } from 'types/global'
import FB, { AD_ACCOUNT_STATUS, queryFB } from 'helpers/FB'
import Axios from 'helpers/Interceptor'
import Logger from 'Logger'
import * as Sentry from "@sentry/react";
import { getFacebookPermissions } from 'services/symphonyApi'
import { getFbAccountDetails } from 'pages/post-auth/MarketingPage/utils/fbCampaigns';

export type FacebookAdAccountAccess = FacebookAdAccount & {
  userHasAccess: boolean
  userHasPermissions: boolean
}

export async function getAdAccounts(
  businessManagerId?: string,
  access_token?: string
): Promise<FacebookAdAccountAccess[]> {
  try {
    let queryUrl = `/me/adaccounts`
    if (businessManagerId) {
      queryUrl = `/${businessManagerId}/owned_ad_accounts`
    }

    const results = await queryFB(queryUrl, {
      params: {
        fields: 'amount_spent,name,id,account_status,user_tasks,business,currency,adspixels{name,id,is_unavailable}',
        limit: 250,
        access_token,
      },
    })

    const formattedAdAccounts = await Promise.all(
      results.data.map(async (account: FacebookAdAccount) => {
        const { user_tasks } = account

        let userHasCorrectAccess = false

        if (user_tasks) {
          try {
            const hasAnalyze: boolean = user_tasks.indexOf('ANALYZE') > -1
            const hasAdvertise: boolean = user_tasks.indexOf('ADVERTISE') > -1

            userHasCorrectAccess = hasAnalyze && hasAdvertise
          } catch (error) {
            Logger.error(error)
          }
        }

        let userHasPermissions = true;
        if (businessManagerId) {
          const permissionsResponse = await getFacebookPermissions({ businessId: businessManagerId, adAccountId: account.id })
          userHasPermissions = Boolean(permissionsResponse?.data?.ad_account)
        }

        return {
          ...account,
          amount_spent: account.amount_spent / 100,
          userHasAccess: userHasCorrectAccess,
          userHasPermissions,
        }
      })
    )

    const sortedAdAccounts = formattedAdAccounts.sort((a: any, b: any) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1
      }
      return 0
    }).sort((a: any, b: any) => {
      const accountAccessA = a.userHasAccess
      const accountAccessB = b.userHasAccess

      if (!accountAccessA && accountAccessB) {
        return 1
      }

      if (accountAccessA && !accountAccessB) {
        return -1
      }
      return 0
    }).sort((a: any, b: any) => {
      const accountStatusA = (AD_ACCOUNT_STATUS as any)[a.account_status].valid
      const accountStatusB = (AD_ACCOUNT_STATUS as any)[b.account_status].valid

      if (accountStatusB && !accountStatusA) {
        return 1
      }

      if (!accountStatusB && accountStatusA) {
        return -1
      }

      return 0
    })

    return sortedAdAccounts;
  } catch (error) {
    Logger.error(error)
    return []
  }
}

export const getBusinessManagers = async (access_token?: string): Promise<FacebookBusinessManager[]> => {
  try {
    const results = await queryFB(`/me/businesses`, {
      params: {
        fields: `id,name,profile_picture_uri,permitted_roles`,
        limit: 50,
        access_token,
      },
    })

    const sorted: FacebookBusinessManager[] = results.data
      .sort((a: any, b: any) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        }
        return 0
      })
      .sort((a: any, b: any) => {
        if (
          b.permitted_roles.indexOf('ADMIN') > -1 &&
          a.permitted_roles.indexOf('ADMIN') > -1
        ) {
          return 0
        }

        if (
          b.permitted_roles.indexOf('ADMIN') > -1 &&
          a.permitted_roles.indexOf('ADMIN') === -1
        ) {
          return 1
        }

        if (
          b.permitted_roles.indexOf('ADMIN') === -1 &&
          a.permitted_roles.indexOf('ADMIN') > -1
        ) {
          return -1
        }
      })

    return sorted
  } catch (error) {
    return []
  }
}

export const updateInBrand = async (
  brandId: number,
  brandSlug: string,
  selectedAdAccount: FacebookAdAccountAccess,
) => {
  const facebook_ad_account = JSON.parse(JSON.stringify(selectedAdAccount))
  localStorage.setItem('facebook_ad_account', JSON.stringify(facebook_ad_account))

  // connect to brand
  await Axios.put(`/brand/${brandSlug}/connect`, {
    id: brandId,
    name: brandSlug,
    service: 'facebook_ad_account',
    value: selectedAdAccount,
  }).catch((error) => {
    Logger.error('Error creating brand', error)
  })
}

export const createNewAdAccount = async (businessManager: FacebookBusinessManager, access_token?: string) => {
  try {
    const creationUrl = `/${businessManager.id}/adaccount`
    const creationPayload = {
      name: 'Symphony Ad Account',
      currency: 'USD',
      timezone_id: 1,
      media_agency: 'NONE',
      end_advertiser: businessManager.id,
      partner: process.env.REACT_APP_FACEBOOK_APP_ID,
      access_token,
    }

    const creationPromise = new Promise<FacebookAdAccountAccess>(
      async (resolve, reject) => {
        try {
          const response = await queryFB(creationUrl, {
            method: 'post',
            params: creationPayload,
          })

          if (!response || response.error) {
            Logger.error('Error response', response)
            reject(response.error)
          } else {
            resolve(response as FacebookAdAccountAccess)
          }
        } catch (error: any) {
          Logger.error('Error response', error)
          reject(error.error)
        }
      }
    )

    const createdAdAccount: FacebookAdAccountAccess = await creationPromise

    // once created, assign the current user as an admin to the
    // ad account ~ first find the "business user id"
    // by looping thru business users and finding user where
    // business.id matches current business id

    const getUserId = await queryFB('/me', {
      method: 'get',
      params: {
        fields: 'business_users',
      },
    })

    let userId = null

    const foundBusinessUser = getUserId.business_users.data.find(
      (o: {
        id: string
        name: string
        business: {
          id: string
          name: string
        }
      }) => {
        return o.business.id === businessManager.id
      }
    )

    if (foundBusinessUser) {
      userId = foundBusinessUser.id
    } else {
      throw new Error('No business found for user')
    }

    const url = `/${createdAdAccount.id}/assigned_users`
    const payload = {
      user: userId,
      tasks: ['MANAGE', 'ADVERTISE', 'ANALYZE', 'DRAFT'],
    }

    await new Promise(async (resolve, reject) => {
      const response = await queryFB(url, {
        method: 'post',
        params: payload,
      })
      if (!response || response.error) {
        Logger.error('Error adding user to ad account', response)
        reject(response.error)
      } else {
        resolve(response)
      }
    })

    const { user_tasks } = createdAdAccount
    let userHasAccess = false

    if (user_tasks) {
      // check if they have:
      //  - ADVERTISE
      //  - ANALYZE
      const hasAnalyze: boolean = user_tasks.indexOf('ANALYZE') > -1
      const hasAdvertise: boolean = user_tasks.indexOf('ADVERTISE') > -1

      userHasAccess = hasAnalyze && hasAdvertise
    }

    return {
      ...createdAdAccount,
      name: 'Symphony Ad Account',
      userHasAccess,
    }
  } catch (error) {
    Logger.error('Error creating ad account', error)
    throw error;
  }
}

export const healthCheckCampaign = async (brandId: number, campaignId: number) => {
  const healthUrl = `/campaign/${campaignId}/health`

  try {
    const authResp = FB().getAuthResponse()
    await Axios.put(healthUrl, authResp ? { access_token: authResp } : {})
  } catch(error) {
    Sentry.captureException(`Error performing health check for campaign with ID: ${campaignId}. ERROR: ${error}`);
    throw error;
  }
}

export const isBusinessAdmin = async (businessId: string, access_token?: string) => {
  try {
    const response = await queryFB(`/${businessId}`, {
      params: {
        fields: 'business_users{id,name,business,role}',
        limit: 250,
        access_token,
      },
    })

    const businessUsers = response.business_users.data

    const businessAdmins = businessUsers.filter((user: any) => {
      return user.role === 'ADMIN'
    })

    const personalBusinessUsersResponse = await queryFB('me', {
      params: {
        fields: 'business_users{id,name}',
      }
    })

    const personalBusinessUsers = personalBusinessUsersResponse.business_users.data

    let isAdmin = false;
    for (const businessUser of businessAdmins) {
      const foundAdminUser = personalBusinessUsers.find((personalBusinessUser: any) => personalBusinessUser.id === businessUser.id)
      if (foundAdminUser) {
        isAdmin = true
        break
      }
    }

    return isAdmin
  } catch (error: any) {
    if (error?.error?.code === 104) return true;
    return false
  }
}

export const loggedInFb = async () => {
  try {
    const request = await Axios.get("/platform/facebook/me");
    const { logged, profile } = request.data;
    if (logged && profile) {
      return profile;
    } else {
      return null;
    }
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getInstagramAccountFromFbPage = async (selectedFBPage: FacebookPageType, access_token?: string) => {
  try {
    let fbAccountDetails = selectedFBPage;
    if (!selectedFBPage.access_token) fbAccountDetails = await getFbAccountDetails(selectedFBPage, access_token)
    const instagramAccount = await queryFB(
      `/${fbAccountDetails.id}/instagram_accounts`,
      {
        params: {
          fields:
            'id,follow_count,followed_by_count,username,profile_pic,is_private,is_published',
          access_token: fbAccountDetails?.access_token,
          forceToken: fbAccountDetails?.access_token,
        },
      }
    );

    const businessAccounts = await queryFB(`/${fbAccountDetails.id}`, {
      params: {
        fields: 'name,category,id,access_token,followers_count,picture{url},is_published,instagram_business_account{username,id,followers_count,profile_picture_url},connected_instagram_account{username,id,followers_count,profile_picture_url}',
        access_token,
      },
    });

    if (!businessAccounts || !instagramAccount?.data?.length) return null;

    const { connected_instagram_account, instagram_business_account } =
      businessAccounts;

    const response: InstagramPageType = {
      id: instagramAccount.data[0].id,
      name: businessAccounts.name,
      businessAccount: instagram_business_account,
      connected_instagram_account,
      instagramAccount: instagramAccount.data[0],
    };

    return response;
  } catch (error) {
    console.error('Error fetching Instagram account:', error);
    return null;
  }
};

export const isValidAccessToken = async (access_token: string) => {
  try {
    const response = await queryFB(`/me?access_token=${access_token}`, {
      method: 'get',
    });
    return Boolean(response)
  } catch (error) {
    Logger.error('Error validating access token', error)
    return false
  }
};

export const getFacebookImage = async (page: string, access_token?: string) => {
  try {
    const response = await queryFB(`/${page}`, {
      params: {
        fields: 'id,name,picture',
        access_token,
      },
    });
    return response.picture.data.url;
  } catch (error) {
    Logger.error('Error fetching Instagram image', error)
    return null
  }
}
