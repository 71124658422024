import { track } from "analytics";
import UpgradeToProModal from "components/shareable/UpgradeToProModal";
import Axios from "helpers/Interceptor";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import { SHARED_TOAST_OPTIONS, SHARED_TOAST_OPTIONS_ERROR } from "pages/post-auth/MarketingPage/Components/Modals/utils";
import ConfirmChangeSubscriptionModal from "pages/post-auth/OrganizationPage/ReadyState/ConfirmChangeSubscriptionModal";
import { createContext, FunctionComponent, ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { upgradeToProInOrganization } from "services/symphonyApi/organizationService";
import { CurrentBrand, UserData } from "types/global";
import { isOrganizationAdminOrOwner, triggerVWOVirtualPageView } from "utils";

interface Context {
  openModal: boolean;
  handleOpenModal: (props?: {
    source?: string;
    tab?: "creator" | "team"
  }) => void;
  handleCloseModal: () => void;
}

export const UpgradeModalContext = createContext<Context>({
  openModal: false,
  handleOpenModal: (props?: {
    source?: string;
    tab?: "creator" | "team"
  }) => { },
  handleCloseModal: () => { },
});

type Props = {
  currentBrand?: CurrentBrand | null;
  children: ReactNode[] | ReactNode;
  user?: UserData | null;
  reloadBrands: () => void
};

declare global {
  interface Window {
    VWO: any[];
  }
}

const UpgradeModalProvider: FunctionComponent<Props> = ({
  currentBrand,
  children,
  user,
  reloadBrands,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [source, setSource] = useState<string | null>(null);
  const [tab, setTab] = useState<"creator" | "team">("creator");

  const history = useHistory()

  // for ogranization upgrade
  const { hasProAccountOverage, organization } = useCurrentTeam()
  const [upgradeInTeamButtonLoading, setUpgradeInTeamButtonLoading] = useState(false);
  const hasEnabledOrganization: boolean = Boolean(organization && organization.status !== "not_started")
  const hasOrganizationOverage: boolean = hasProAccountOverage
  // Function to trigger VWO virtual page view with full URL

  async function handleProceedWithOrganizationUpgradeAction() {
    setUpgradeInTeamButtonLoading(true);
    try {
      if (currentBrand?.slug) {
        await upgradeToProInOrganization(currentBrand?.slug)
      } else {
        throw new Error("No brand slug provided")
      }
      reloadBrands();
      toast.success("Successfully upgraded to Pro!", SHARED_TOAST_OPTIONS);
      handleCloseModal();
    } catch (error) {
      console.error(error);
      toast.error("There was an error enabling Pro Plan access. Please try again.", SHARED_TOAST_OPTIONS_ERROR);
    } finally {
      setUpgradeInTeamButtonLoading(false);
    }
  }

  const isAdminOrOwner = isOrganizationAdminOrOwner(user)

  const handleOpenModal = (props?: {
    source?: string
    tab?: "creator" | "team"
  }) => {
    triggerVWOVirtualPageView('/upgrade-to-pro-modal'); // Trigger VWO virtual page view with full URL
    track("Upgrade to Pro Modal Opened", { source: props?.source ? props?.source : null });
    setSource(props?.source ? props?.source : null);
    setTab(props?.tab ? props?.tab : "creator");
    setOpen(true)
  }
  const handleCloseModal = () => setOpen(false);

  const context = {
    openModal: open,
    handleOpenModal,
    handleCloseModal,
  };

  return (
    <UpgradeModalContext.Provider value={context}>
      {hasEnabledOrganization ? (
        <ConfirmChangeSubscriptionModal
          open={open}
          type="upgrade"
          onClose={handleCloseModal}
          buttonLoading={upgradeInTeamButtonLoading}
          onConfirm={handleProceedWithOrganizationUpgradeAction}
          proAccounts={user?.organization?.totalProBrands || 0}
          brandName={currentBrand?.name || ''}
          isTeamAdmin={isAdminOrOwner}
        />
      ) : (
        <UpgradeToProModal
          currentBrand={currentBrand}
          isOrganization={hasEnabledOrganization}
          hasOrganizationOverage={hasOrganizationOverage}
          source={source}
          open={open}
          onClose={handleCloseModal}
          reloadBrands={reloadBrands}
          tab={tab}
          isTeamAdmin={isAdminOrOwner}
        />
      )}
      {children}
    </UpgradeModalContext.Provider>
  );
};

export default UpgradeModalProvider;
