import {
  FunctionComponent,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  Fragment,
} from "react"
import momentTz from "moment-timezone"
import MainContentContainer from "components/shareable/MainContentContainer"
import isEqual from "lodash/isEqual"
import { BackgroundTypes, CurrentBrand, StyleTypes, SavingDraftStatus } from "types/global"
import { TabPanel } from "react-headless-tabs"
import { PreSaveDataContext } from "../Data/PreSaveProvider"
import PostCreation from "./Components/PostCreation"
import { track } from "analytics"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import TopBar from "components/shareable/TopBar"
import CustomStepper from "components/shareable/CustomStepper"
import some from "lodash/some"
import isEmpty from "lodash/isEmpty"
import {
  getTabViews,
  getMainTitle,
  getContainerClassName,
  getRecordPreviewContainerStyles,
  checkIsValidName,
} from "./utils"
import {
  FINISH,
  CONTINUE,
  ARTWORK,
  STACKED,
} from "./constants"
import { SystemColors } from "types/globalStyles"
import { scrollIntoView } from "helpers/StyleUtils"
import {
  Backdrop,
  Fade,
  Modal,
} from "@material-ui/core"
import useStyles from "pages/post-auth/MarketingPage/PreSave/styles"
import RecordPreview from "components/shareable/RecordPreview"
import { useDraftDataGetter, useDraftDataSetter } from "./Hooks/DraftsHandlers"
import Loading from "./Components/Loading"
import { checkIfIsProTier } from "utils";
import useMediaBreakpoints from "Hooks/useMediaBreakpoints"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"

export enum STEPS {
  DETAILS = "DETAILS",
  CUSTOMIZE = "CUSTOMIZE",
  REVIEW = "REVIEW",
}

const { INITIAL_SAVING_STATE } = SavingDraftStatus

interface Props {
  setGoal: Dispatch<SetStateAction<string | null>>
}

const PreSave: FunctionComponent<Props> = ({
  setGoal,
}: Props) => {
  const location = useLocation()
  const history = useHistory()
  const classes = useStyles()
  const { mobileView } = useMediaBreakpoints()
  const [, path] = location.pathname.split("/")
  const draft = new URLSearchParams(location.search).get("draft")
  const { currentBrand } = useContext(CurrentBrandContext) as { currentBrand: CurrentBrand }
  const {
    loading,
    showPostCreation,
    customize,
    details,
    postCreationErrorType,
    dataComplete,
    setPostCreationErrorType,
    setShowPostCreation,
    saveCustomize,
    saveDetails
  } = useContext(PreSaveDataContext)


  const { releaseDate, releaseUrlValid: isValidReleaseUrl, releaseUrl } = details
  const { website, name: contentName, image: brandArtwork } = currentBrand
  const brandId = currentBrand.id
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState(STEPS.CUSTOMIZE)
  const [isSavingDraft, setIsSavingDraft] = useState(false)
  const [retrievingDraftData, setRetrievingDraftData] = useState(!!draft)
  const [requiredDataChanged, setRequiredDataChanged] = useState(false)
  const [campaignDraftData, setCampaignDraftData] = useState<Record<
    string,
    unknown
  > | null>(null)
  const [savingDraftStatus, setSavingDraftStatus] = useState(INITIAL_SAVING_STATE);
  const isProUser = checkIfIsProTier(currentBrand);

  const views = getTabViews(selectedTab)
  const mainTitle = getMainTitle({
    showPostCreation,
    loading,
    errorType: postCreationErrorType,
  })
  const containerClassName = getContainerClassName(showPostCreation, mobileView)
  const steps = [
    { description: "Customize", name: STEPS.CUSTOMIZE },
    { description: "Details", name: STEPS.DETAILS },
    { description: "Review", name: STEPS.REVIEW },
  ]
  const isReviewStepDisabled = some([
    (!isEmpty(releaseUrl) && !isValidReleaseUrl),
    customize.releaseTitle && !checkIsValidName(customize.releaseTitle),
    !releaseDate || releaseDate === "Invalid date"
  ])
  const selectedBackgroundType =
    customize?.backgroundType || website?.bgType || ARTWORK
  const backgroundColor =
    customize?.backgroundColor || website?.bgColor || SystemColors.DARK_GRAY
  const {
    style: previewModalContainerStyle,
    className: previewModalContainerClassName,
  } = getRecordPreviewContainerStyles({
    classes,
    backgroundColor,
    backgroundType: selectedBackgroundType,
  })
  const showLoading = !!draft && retrievingDraftData

  const handleClickCloseTopBar = () => {
    if (showPostCreation) {
      setPostCreationErrorType(null)
      setGoal(null)
    } else {
      momentTz.tz.setDefault()
      if (isEqual(path, "fans")) setGoal(null)
      else history.push("/marketing")
    }
  }

  const handleClickStep = (tab: string) => () => {
    const isDetailsTab = isEqual(tab, STEPS.DETAILS)
    const isReviewTab = isEqual(tab, STEPS.REVIEW)
    const isAllDataSaved = dataComplete()

    if (isReviewTab) {
      if (isAllDataSaved) setSelectedTab(tab as STEPS)
    } else if (isDetailsTab) {
      if (customize?.releaseTitle) setSelectedTab(tab as STEPS)
    } else {
      setSelectedTab(tab as STEPS)
    }
  }

  const handleClickBackButton = () => {
    if (isEqual(selectedTab, STEPS.REVIEW)) {
      setSelectedTab(STEPS.DETAILS)
    } else if (isEqual(selectedTab, STEPS.DETAILS)) {
      setSelectedTab(STEPS.CUSTOMIZE)
    } else if (isEqual(selectedTab, STEPS.CUSTOMIZE)) {
      momentTz.tz.setDefault()
      if (isEqual(path, "fans")) setGoal(null)
      else history.push("/marketing")
    }
  }

  const handleClickNextButton = () => {
    if (isEqual(selectedTab, STEPS.CUSTOMIZE)) {
      setSelectedTab(STEPS.DETAILS)
    } else if (isEqual(selectedTab, STEPS.DETAILS)) {
      setSelectedTab(STEPS.REVIEW)
    } else {
      cancelSavingDraft()
      setShowPostCreation(true)
    }
  }

  const handleClosePreview = () => setShowPreview(false)

  const handleOpenPreview = () => setShowPreview(true)

  useEffect(() => {
    const data = {
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      ...(customize ? customize : {}),
      ...(details ? details : {}),
    }

    scrollIntoView()

    if (selectedTab === STEPS.DETAILS) {
      track("Completed Campaign Step - Content Details", {
        type: "pre_save",
        step: 1,
        name: "customize",
        ...data,
      })
    } else if (selectedTab === STEPS.REVIEW) {
      track("Completed Campaign Step - Customize Pre-save", {
        type: "pre_save",
        step: 2,
        name: "customize",
        ...data,
      })
    }
  }, [selectedTab])

  useDraftDataGetter({
    draft,
    website,
    setters: {
      setSelectedTab,
      setRetrievingDraftData,
      setCampaignDraftData,
      setIsSavingDraft,
      saveCustomize,
      saveDetails
    } as Record<string, Dispatch<unknown>>,
  })

  const { cancelSavingDraft } = useDraftDataSetter({
    setters: {
      setRequiredDataChanged,
      setIsSavingDraft,
      setCampaignDraftData,
      setSavingDraftStatus
    } as Record<string, Dispatch<unknown>>,
    campaignDraftData,
    requiredDataChanged,
    releaseTitle: customize.releaseTitle,
    retrievingDraftData,
    dataToSaveDraft: { customize, details, selectedTab },
    draft,
    currentBrand,
    savingDraftStatus
  })

  if (showPostCreation) {
    return (
      <Grid>
        <TopBar title={mainTitle} />
        <MainContentContainer className="h-full w-full">
          <PostCreation {...{ draft: campaignDraftData?.id as string, brandId, setGoal, setSelectedTab }} />
        </MainContentContainer>
      </Grid>
    )
  }

  return (
    <Grid>
      <TopBar
        title={mainTitle}
        showSaveDraftChip={!!draft || !!customize.releaseTitle}
        showCloseIcon
        savingChanges={isSavingDraft}
        handleClose={handleClickCloseTopBar}
      />
      <CustomStepper
        activeStep={steps.map(({ name }) => name).indexOf(selectedTab)}
        steps={steps.map((data) => ({
          ...data,
          onClickHandler: handleClickStep,
        }))}
        stepButtonsProps={{
          handleClickBackButton,
          handleClickNextButton,
          disableNextButton: isEqual(selectedTab, STEPS.CUSTOMIZE)
            ? !customize?.releaseTitle || !checkIsValidName(customize.releaseTitle)
            : isEqual(selectedTab, STEPS.DETAILS)
              ? isReviewStepDisabled
              : false,
          nextButtonLabel: isEqual(selectedTab, STEPS.REVIEW)
            ? FINISH
            : CONTINUE,
        }}
        stepperButtonAmendments={(
          <button
            onClick={handleOpenPreview}
            className={`${isEqual(selectedTab, STEPS.REVIEW)
              ? "hidden"
              : classes.shownPreviewPresaveButton
              } ${classes.previewPresaveButton}`}
          >
            Preview Pre-Save
          </button>
        )}
      >
        {showLoading && <Loading />}
        {!showLoading && (
          <MainContentContainer className="h-4/5 max-w-7xl w-full md:w-9/12 mx-auto rounded-xl">
            <Grid className={containerClassName}>
              <Grid className="w-full">
                {views.map(({ id, hidden, component: Component }) => (
                  <Fragment key={id}>
                    <TabPanel {...{ hidden }}>
                      <Component
                        {...{
                          currentBrand,
                          draft,
                          selectedTab,
                          website,
                          contentName,
                          brandArtwork,
                          setSelectedTab,
                          isProUser
                        }}
                      />
                    </TabPanel>
                  </Fragment>
                ))}
              </Grid>
            </Grid>
          </MainContentContainer>
        )}
      </CustomStepper>
      <Modal
        aria-labelledby="connector-modal"
        aria-describedby="connector-modal for platforms"
        className={classes.previewPresaveWebsiteModal}
        open={showPreview}
        onClose={handleClosePreview}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 400 }}
      >
        <Fade in={showPreview}>
          <>
            <div
              className={previewModalContainerClassName}
              style={previewModalContainerStyle}
            >
              {isEqual(selectedBackgroundType, BackgroundTypes.ARTWORK) && (
                <img
                  alt="artwork_as_background"
                  src={customize?.artwork || brandArtwork || ''}
                  className={classes.previewPresaveBackground}
                />
              )}
              <RecordPreview
                className={classes.previewPresaveWebsiteContainer}
                top="0px"
                contentDetails={{
                  name: customize.releaseTitle,
                  options: details.musicPlatforms
                    ? details.musicPlatforms.filter((o) => o.show)
                    : [
                      { name: "spotify", show: true },
                      { name: "apple_music", show: true },
                      { name: "audiomack", show: true },
                      { name: "soundcloud", show: true },

                    ],
                  artworkUrl: customize?.artwork || brandArtwork || '',
                  subtitle: customize.subtitle ? customize.subtitle : '',
                }}
                backgroundType={selectedBackgroundType}
                backgroundColor={backgroundColor}
                mainColor={
                  customize?.primaryColor ||
                  website?.primaryColor ||
                  SystemColors.ACCENT_COLOR
                }
                secondaryColor={
                  isEqual(
                    customize?.styleType ||
                    website?.buttonConfig ||
                    StyleTypes.STACKED,
                    STACKED
                  )
                    ?
                    customize?.primaryColor ||
                    website?.primaryColor ||
                    SystemColors.ACCENT_COLOR
                    :
                    customize?.buttonColor ||
                    website?.secondaryColor ||
                    SystemColors.PRIMARY_TEXT_COLOR
                }
                styleType={
                  customize?.styleType ||
                  website?.buttonConfig ||
                  StyleTypes.STACKED
                }
                titleAlignment={customize.titleAlignment}
                relative
              />
            </div>
            <button
              onClick={handleClosePreview}
              className={`${classes.shownPreviewPresaveButton} ${classes.previewPresaveButton}`}
            >
              Close Preview
            </button>
          </>
        </Fade>
      </Modal>
    </Grid>
  )
}

export default PreSave
