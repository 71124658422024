import { Dispatch, SetStateAction } from "react";
import { queryFB } from "helpers/FB";
import {
  PAYMENT_METHODS,
  DEFAULT_CURRENCY,
  MINIMUM_DAILY_BUDGET_PER_DAY_IN_DOLLARS,
  CREDIT_CARD,
  PAYPAL_BILLING_AGREEMENT
} from "./constants";
import moment from "moment";
import { getDiffInDays } from '../../utils/daysCalculator';
import millify from "millify";
import { getMinimalAmount } from '../../utils/fbCampaigns';
import { getConvertedCurrency } from "services/symphonyApi";
import { CurrencyCodesType } from "types/global";

export const loadPaymentMethod = async (args: {
  fbAdAccountId?: string;
  setLoadingPayment: Dispatch<SetStateAction<boolean>>;
  setPaymentMethod: Dispatch<
    SetStateAction<{
      display_string: string;
      id: string;
      type: string;
    } | null>
  >;
  setSpendCap: Dispatch<SetStateAction<number>>;
  setCurrency: Dispatch<SetStateAction<CurrencyCodesType>>;
  onChangeSpendCurrency: (currency: CurrencyCodesType) => void;
  setMinimumCurrencyBudgetPerDay: Dispatch<SetStateAction<number>>;
  accessToken?: string;
}) => {
  const {
    fbAdAccountId,
    setLoadingPayment,
    setPaymentMethod,
    setSpendCap,
    setCurrency,
    onChangeSpendCurrency,
    setMinimumCurrencyBudgetPerDay,
    accessToken,
  } = args;

  localStorage.getItem("facebook_token");
  setLoadingPayment(true);

  if (!accessToken) {
    setLoadingPayment(false);
    return;
  };

  let results;
  try {
    results = await queryFB(`/${fbAdAccountId}`, {
      params: {
        fields:
          "funding_source,funding_source_details,capabilities,spend_cap,currency",
        access_token: accessToken,
      },
    });
  } catch (e) {
    results = await queryFB(`/${fbAdAccountId}`, {
      params: {
        fields: "funding_source,capabilities,spend_cap,currency",
        access_token: accessToken,
      },
    });
  }

  // HAS_VALID_PAYMENT_METHODS

  setLoadingPayment(false);
  // need to make sure the capabilities array includes:
  /**
   * HAS_VALID_PAYMENT_METHODS
   *
   *
   * see: https://developers.facebook.com/docs/marketing-api/reference/ad-account/#capabilities
   */
  const hasValidPaymentMethods =
    results.capabilities &&
    results.capabilities.indexOf("HAS_VALID_PAYMENT_METHODS") > -1;
  if (hasValidPaymentMethods && results?.funding_source) {
    const info: any = results.funding_source_details || {};
    const type: any = info.type || 0;

    let display_string = "";

    switch (type) {
      case PAYPAL_BILLING_AGREEMENT:
        display_string = info.display_string;
        break;
      case CREDIT_CARD:
        display_string = info.display_string
          ? info.display_string
          : "Credit Card";
        break;
      default:
        display_string = "Connected";
        break;
    }
    const obj = {
      display_string: display_string,
      id: info.id,
      type: PAYMENT_METHODS[type],
    };
    setPaymentMethod(obj);
  } else {
    setPaymentMethod(null);
  }

  if (results && results.spend_cap) {
    const cap = parseInt(results.spend_cap);
    if (cap > 0) {
      setSpendCap(cap);
    }
  }

  if (results && results.currency && results.currency !== DEFAULT_CURRENCY) {
    const minimalAmount = await getMinimalAmount(results.currency);
    if (minimalAmount) {
      setCurrency(results.currency);
      onChangeSpendCurrency(results.currency)
      setMinimumCurrencyBudgetPerDay(Number(minimalAmount.toFixed(2)));
    }
  } else {
    setCurrency(DEFAULT_CURRENCY);
    setMinimumCurrencyBudgetPerDay(MINIMUM_DAILY_BUDGET_PER_DAY_IN_DOLLARS);
  }
};

export const totalLengthCalculator = (args: {
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const { startDate, endDate } = args;

  if (!(startDate && endDate)) return "...";

  const difference = getDiffInDays(args);

  if (difference > 1) return `${difference} days`;
  return `${difference} day`;
};

export const dailyBudgetCalculator = (args: {
  startDate: Date | null;
  endDate: Date | null;
  budgetValue: number;
}) => {
  const { startDate, endDate, budgetValue } = args;

  if (!(startDate && endDate && budgetValue && budgetValue > 0)) return "...";

  const diffInDays = getDiffInDays({ startDate, endDate });

  const estDaily = budgetValue / diffInDays;

  return estDaily.toFixed(0);
};

const getConvertedCost = async (defaultValue: number, currency: CurrencyCodesType) => {
  if (currency !== DEFAULT_CURRENCY) {
    return await getConvertedCurrency({ amount: defaultValue, from: DEFAULT_CURRENCY, to: currency });
  }
  return defaultValue;
}

export const estimatedStreamsCalculator = async (budgetValue: number, currency?: CurrencyCodesType) => {
  if (budgetValue === 0) return "...";
  currency = currency ?? DEFAULT_CURRENCY;

  const lowestCostPerStream = await getConvertedCost(0.2, currency);
  const highestCostPerStream = await getConvertedCost(0.6, currency);

  const lowValue = (budgetValue / highestCostPerStream).toFixed(0);
  const highValue = (budgetValue / lowestCostPerStream).toFixed(0);

  return `${lowValue} - ${highValue}`;
};

export const estimatedReachCalculator = async (budgetValue: number, currency?: CurrencyCodesType) => {
  if (budgetValue === 0) return "...";
  currency = currency ?? DEFAULT_CURRENCY;

  const lowestCPM = await getConvertedCost(0.75, currency);
  const highestCPM = await getConvertedCost(4, currency);

  const lowValue = millify(((budgetValue / highestCPM) * 1000))
  const highValue = millify(((budgetValue / lowestCPM) * 1000))

  return `${lowValue} - ${highValue}`;
};

export const getBudgetData = (args: {
  startDate: string | Date | null;
  endDate: string | Date | null;
  budgetValue: number;
  minimumCurrencyBudgetPerDay: number;
}) => {
  const { startDate, endDate, budgetValue, minimumCurrencyBudgetPerDay } = args;
  const diffInDays = getDiffInDays({ startDate, endDate });
  const budgetPerDay = budgetValue / diffInDays;
  const isLow = budgetPerDay < minimumCurrencyBudgetPerDay;
  const minimumSuggestedBudget =
    minimumCurrencyBudgetPerDay * diffInDays;
  const suggestedEndDate = moment(startDate)
    .add(Math.floor(budgetValue / minimumCurrencyBudgetPerDay), "days")
    .format("MM/DD/YYYY");

  return { isLow, minimumSuggestedBudget, suggestedEndDate };
};
