import Colors from "modules/Colors";
import styled from "styled-components";
import { SystemColors } from "types/globalStyles";
import Text from "modules/Text"
import { Theme, makeStyles } from "@material-ui/core/styles";
import { MEDIA_BREAKPOINTS } from "types/global";

export const MainContainer = styled.div`
    background-color: ${SystemColors.PAPER_CONTAINER};
`;

export const TabsContainer = styled.div`
    display: flex;
    padding: 8px;
`;

export const useStyles = makeStyles((_theme: Theme) => ({
    mainContainer: {
        width: "100%",
        display: "flex",
        padding: "24px 0px",
        [MEDIA_BREAKPOINTS.mobileView]: {
            padding: "0px 0px 24px",
        },
    },
    banner: {
        backgroundColor: SystemColors.PAPER_ACCENT_CONTAINER,
        padding: "16px 24px",
        gap: "4px",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        [MEDIA_BREAKPOINTS.mobileView]: {
            borderRadius: 0,
        }
    },
    subTabPanel: {
        display: "flex",
        flexDirection: "column",
        maxWidth: 1120,
        width: "100%",
        padding: 16,
        gap: "24px",
        backgroundColor: "#F5F3F6",
    },
    websitePreviewContainer: {
        width: "40%",
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
        maxWidth: 410,
        borderRadius: 12,
    },
    customizeWebsiteV2Variable: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 16,
    },
    recordSelectedDropdownImage: {
        width: 56,
        height: 56,
        borderRadius: 10,
        marginRight: 16,
    },
    mainRecordDropdown: {
        marginBottom: 24,
    },
    mainRecordDropdownButton: {
        borderRadius: 8,
        border: "1px solid #EDECF2",
        width: "100%",
        padding: "16px 16px 16px 8px",
    },
    selectRecordRow: {
        display: "flex",
        alignItems: "center",
        marginRight: "auto",
    },
    recordSelectedInfoContainer: {
        textAlign: "left",
    },
    recordSelectedName: {
        ...Text.primary,
    },
    albumSingleText: {
        ...Text.secondary,
    },
    socialMediaIconsContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 16,
    },
    socialMediaInputContainer: {
        display: "flex",
        alignItems: "center",
        gap: 8,
        padding: 16,
        borderRadius: 12,
        border: "1px solid #EDECF2",
    },
    socialIconContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 46,
        width: 46,
        borderRadius: 12,
        aspectRatio: "1/1",
        flexShrink: 0,
    },
    socialIconEmptyContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 46,
        width: 0,
    },
    socialMediaInput: {
        ...Text.input,
    },
    secondaryTextSection: {
        display: "flex",
        alignItems: "center",
    },
    iconBackground: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 8,
        borderRadius: 12,
        backgroundColor: "rgba(25, 119, 243, 0.16)",
        alignSelf: "center",
    },
    themeBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 64,
        borderRadius: 10,
        backgroundColor: "white",
        border: "1px solid #EDECF2",
        cursor: "pointer",
        color: Colors.textGreyLight,
        gap: 12,
        paddingLeft: 16,
        paddingRight: 16,
        textWrap: 'nowrap',
    },
    themeBoxSelected: {
        backgroundColor: Colors.lightPurple,
        color: Colors.purple,
        textWrap: 'nowrap',
    },
    renderSelectorBoxRow: {
        display: "flex",
        gap: 8,
        color: Colors.purple,
        overflowY: 'scroll',
    },
    fontThemeStyleIcon: {
        fontSize: 24,
    },
    colorPickerContainer: {
        display: "flex",
        alignItems: "center",
        border: `1px solid #ADB7BC`,
        padding: 8,
        paddingRight: 12,
        borderRadius: 12,
        height: 48,
    },
    hexColorInput: {
        width: 70,
        height: 32,
        fontFamily: "DIN",
        fontSize: 18,
        "&:focus": {
            outline: "none",
        }
    },
    colorPickerBox: {
        border: "none",
        background: "transparent",
        height: 34,
        width: 31,
        marginRight: 8,
        cursor: "pointer",
    },
    tabSelectorContainer: {
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: "white",
        borderRadius: 12,
        width: "100%",
        padding: 8,
        paddingBottom: '0px',
        "& > button": {
            padding: "8px 16px",
        },
        [MEDIA_BREAKPOINTS.mobileView]: {
            borderRadius: 0,
        },
    },
    customizeWebsiteV2Component: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "rgb(245, 243, 246)",
        height: "100%",
        "& .customizeWebsiteV2NavTabsContainer": {
            width: "90%",
            height: "fit-content",
            maxWidth: 1000,
            display: "flex",
            justifyContent: "center",
            [MEDIA_BREAKPOINTS.mobileView]: {
                width: "100%",
            },
            [`@media (max-width: 800px)`]: {
                width: "95%",
            },
        }
    },
    saveUpdateButtonContainer2: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 16,
        borderRadius: 12,
        background: "rgba(237, 236, 242, 0.08)",
        position: "absolute",
        top: -56,
        right: 12,
        padding: "4px 16px",
    },
    iconTitleContainer: {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },
    iconTitleInputContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 8,
    },
    switchTrashContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 16,
    },
    titleTrashContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 16,
        width: "100%",
        marginBottom: 4,
    },
    fieldsMenu: {
        '& li': {
            fontFamily: 'DIN !important',
            gap: 8,
        }
    },
    submitButtonInput: {
        fontFamily: 'DIN !important',
    },
    changeButton: {
        display: "flex",
        textTransform: "none",
        backgroundColor: SystemColors.PAPER_ACCENT_CONTAINER_ON_DARK,
        borderRadius: 10,
        "& .MuiButton-label": {
            gap: 16,
            paddingRight: 8,
        },
        "&:hover": {
            backgroundColor: SystemColors.PAPER_ACCENT_CONTAINER,
        },
    },
    uploadButton: {
        backgroundColor: SystemColors.ACCENT_COLOR,
        color: SystemColors.PAPER_CONTAINER,
        width: 140,
        height: 40,
        "&:hover": {
            backgroundColor: SystemColors.ACCENT_COLOR,
        },
    },
    artworkPreview: {
        width: 48,
        height: 48,
        objectFit: "cover",
        borderRadius: 8,
    },
    tableContainer: {
        border: "1px solid #c8c7cc",
        borderRadius: 6,
        maxHeight: 458,
    },
    avatar: {
        width: 24,
        height: 24,
    },
    switchEnabled: {
        "& > span.MuiButtonBase-root": {
            color: SystemColors.ACCENT_COLOR + " !important",
        },
        "& > span.MuiSwitch-track": {
            backgroundColor: SystemColors.PAPER_ACCENT_CONTAINER_ON_DARK! + " !important",
        }
    },
    radioButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    }
}));
