import { Body2, Caption } from "components/shareable/Typography";
import { SystemColors } from "../../types/globalStyles";
import { ReactComponent as FacebookRoundedIcon } from "assets/images/facebook-rounded-icon.svg";
import { ReactComponent as InstagramIcon } from "assets/images/instagram-bordered-icon.svg";
import { ReactComponent as InstagramBwIcon } from "assets/images/instagram-bw.svg";
import { ReactComponent as ArrowRight } from "assets/images/arrow-right-icon.svg";
import { ImageContainer, MainContainer, DescriptionContainer, ConnectionDescription } from "./styles";
import SecondaryButton from "components/shareable/SecondaryButton";
import { useState } from "react";

interface Props {
  fbAccount: string;
  fbImg?: string;
  instagramImg?: string;
  instagramAccount?: string;
  buttonText?: string;
  buttonWidth?: string;
  disabledButton?: boolean;
  onClick: () => void;
}

export function AccountContainerIg({
  fbAccount,
  fbImg,
  instagramImg,
  instagramAccount,
  buttonText,
  buttonWidth,
  disabledButton,
  onClick,
}: Props) {
  const [loading, setLoading] = useState(false);

  const handleConnect = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  return (
    <MainContainer>
      <DescriptionContainer>
        <ConnectionDescription>
          {fbImg ? (
            <img className="w-10 h-10 rounded-lg" src={fbImg} />
          ) : (
            <ImageContainer>
              <FacebookRoundedIcon />
            </ImageContainer>
          )}
          <div className="flex flex-col">
            <div className="flex gap-1 items-center">
              <FacebookRoundedIcon width={16} height={16} />
              <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                FB Page
              </Caption>
            </div>
            <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>{fbAccount}</Body2>
          </div>
        </ConnectionDescription>
        <ArrowRight width={24} height={24} />
        <ConnectionDescription>
          {instagramImg ? (
            <img className="w-10 h-10 rounded-lg" src={instagramImg} alt="Instagram" />
          ) : (
            <ImageContainer backgroundColor="#E9E9E9">
              <InstagramBwIcon />
            </ImageContainer>
          )}
          <div className="flex flex-col min-w-0">
            <div className="flex gap-1 items-center">
              <InstagramIcon width={16} height={16} />
              <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                IG Page
              </Caption>
            </div>
            {instagramAccount && (
              <div className="min-w-0 truncate">
                <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                  {instagramAccount}
                </Body2>
              </div>
            )}
          </div>
        </ConnectionDescription>
      </DescriptionContainer>
      {buttonText && (
        <SecondaryButton
          text={buttonText}
          onClick={handleConnect}
          height="39px"
          loading={loading}
          minWidth={buttonWidth}
          disabled={disabledButton}
        />
      )}
    </MainContainer>
  );
}