import { FunctionComponent, useContext, useEffect, useMemo, useState } from "react"
import { ReactComponent as WarningIcon } from "assets/images/campaignDetails/warning.svg"
import {
  Body2,
  Caption,
  Subtitle2,
} from "components/shareable/Typography"
import { SystemColors } from "types/globalStyles"
import LinearProgress from "@material-ui/core/LinearProgress"
import useStyles, {
  Card,
  ProgressContainer,
  FlexContainer,
  MainContainer,
  FeeContainer,
} from "./styles"
import { OrganizationBillingData, SpendBudgetData } from "types/global"
import millify from "millify"
import getSymbolFromCurrency from "currency-symbol-map"
import dayjs from "dayjs"
import { checkIfIsProTier } from "utils"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"


const { WARNING_COLOR, PRIMARY_TEXT_COLOR, SECONDARY_TEXT_COLOR } = SystemColors

const format = (value: number, showDigits?: boolean) => {
  const numberOfDigits = showDigits ? 2 : 0

  return (value || 0).toLocaleString("en-US", {
    minimumFractionDigits: numberOfDigits,
    maximumFractionDigits: numberOfDigits,
  })
}

const SpendLimitAlertCard: FunctionComponent<SpendBudgetData> = ({
  currentAttemptedSpend = 0,
  isOrganization = false,
  feePrice = 0,
  feePercentage = 0,
  feeTotal = 0,
  feeUnit = 1000,
  projectedSpendAfterCampaign = 0,
  spentLeft = 0,
  monthlySpendCap = 0,
  status = 'healthy',
  spentToDate = 0,
  effectiveSpendLimit = 1000,
  total = 0,
  disableSpendLimit = false,
  firstTimeSpending = false,
  currencyCode = 'USD',
  usage,
  billingPeriod,
}: SpendBudgetData) => {
  const classes = useStyles()
  const { currentBrand } = useContext(CurrentBrandContext)
  const isProUser = checkIfIsProTier(currentBrand)



  let percentage = 0
  let bufferValue = 0

  let spendAfterCampaign = projectedSpendAfterCampaign || 0
  const showCurrencyCode = currencyCode && currencyCode !== 'USD' ? ` ${currencyCode}` : ''

  const [loading, setLoading] = useState<boolean>(false)
  // const subscriptionActive = useMemo(() => !["canceled"].includes(billingData?.status!), [billingData])

  if (spentToDate === 0 && spendAfterCampaign > 0) {
    percentage = (spendAfterCampaign * 100) / effectiveSpendLimit
  } else {
    percentage = (spentToDate * 100) / effectiveSpendLimit
    bufferValue = ((projectedSpendAfterCampaign || 0) * 100) / effectiveSpendLimit
  }

  if (isOrganization) {
    percentage = ((usage?.usage || 0) / (usage?.limit || 5000)) * 100
    bufferValue = ((spendAfterCampaign || 0) / (usage?.limit || 5000)) * 100
  }


  const freeLimit = effectiveSpendLimit === feeUnit
  const currencySymbol = currencyCode ? getSymbolFromCurrency(currencyCode) : ''

  function renderExplainerText() {
    if (isOrganization) {

      return `If you want to spend more than ${currencySymbol}${format(monthlySpendCap)}${showCurrencyCode} a month across all of your Team's profiles, we charge a 2% automation fee on any additional ad budget you spend.`
    } else {
      return `If you want to spend more than ${currencySymbol}${format(monthlySpendCap)}${showCurrencyCode} a month, we charge an automation fee of ${currencySymbol}${format(feePrice)}${showCurrencyCode} on additional ad budget.`
    }

  }

  if (disableSpendLimit) {
    return null
  }

  let cardBgColor = null
  let primaryTextColor = WARNING_COLOR
  let iconColor = classes.warningIcon
  let barProgressColor = classes.colorPrimary
  let barBackgroundColor = classes.barColorPrimary
  let automationFeeSubtitle = `+${currencySymbol}${format(feePrice)}${showCurrencyCode} per additional ${currencySymbol}${format(feeUnit)}`

  if (isOrganization) {
    automationFeeSubtitle = `This campaign will put your monthly spend at ${currencySymbol}${format(projectedSpendAfterCampaign || 0)}. We'll charge a 2% automation fee on any additional spend over ${currencySymbol}${format(monthlySpendCap)}${showCurrencyCode}.`
  }


  if (firstTimeSpending || status !== 'exceeded') {
    cardBgColor = SystemColors.DARK_SUCCESS
    primaryTextColor = SystemColors.GREEN_COLOR
    iconColor = classes.positiveIcon
    barProgressColor = classes.zeroState_BarColorPrimary
    barBackgroundColor = classes.zeroState_BarColorSecondary
  }


  const renderCurrentSpendDetails = () => {


    const startDate = billingPeriod?.startDate
    const endDate = billingPeriod?.endDate

    if (isOrganization) {
      const spentThisPeriod = usage?.usage || 0

      return (
        <FlexContainer flexDirection="column">
          <Subtitle2 color={SECONDARY_TEXT_COLOR} weight={400}>
            Your Budget: {currencySymbol}{format(currentAttemptedSpend || 0)}
          </Subtitle2>
          {startDate && endDate && (
            <Caption color={SECONDARY_TEXT_COLOR}>
              Billing Period: {dayjs(startDate).format('MMM DD')} -{' '}
              {dayjs(endDate).format('MMM DD')}
            </Caption>
          )}

          <Caption color={SECONDARY_TEXT_COLOR}>
            Spent this month: {currencySymbol}{format(spentThisPeriod)}
          </Caption>
        </FlexContainer>
      )
    } else {
      return (
        <FlexContainer flexDirection="column">
          <Subtitle2 color={SECONDARY_TEXT_COLOR} weight={400}>
            {`Your Budget: ${currencySymbol}${format(currentAttemptedSpend || 0)}`}
          </Subtitle2>
          {spentToDate > 0 && (
            <>
              {startDate && endDate && (
                <Caption color={SECONDARY_TEXT_COLOR}>
                  Billing Period: {dayjs(startDate).format('MMM DD')} -{' '}
                  {dayjs(endDate).format('MMM DD')}
                </Caption>
              )}
              <Caption color={SECONDARY_TEXT_COLOR}>
                Spent this month: {currencySymbol}{format(spentToDate || 0)}
              </Caption>
              <Caption color={SECONDARY_TEXT_COLOR}>
                After this campaign: {currencySymbol}{format(projectedSpendAfterCampaign || 0)}
              </Caption>

            </>
          )}
        </FlexContainer>
      )
    }
  }

  return (
    <MainContainer>
      <Card
        backgroundColor={cardBgColor}>
        <FlexContainer gap="8px">
          <WarningIcon className={`${iconColor} flex-shrink-0`} height={16} />
          <Subtitle2 color={primaryTextColor}>
            {renderExplainerText()}

          </Subtitle2>
        </FlexContainer>
        <ProgressContainer>
          <LinearProgress
            classes={{
              root: classes.root,
              colorPrimary: barBackgroundColor,
              barColorPrimary: barProgressColor,
            }}
            className=""
            color="primary"
            variant="buffer"
            value={percentage}
            valueBuffer={bufferValue}
          />
        </ProgressContainer>
        <FlexContainer
          alignItems="flex-start"
          justifyContent="space-between"
          marginTop="4px"
        >
          {renderCurrentSpendDetails()}
          <Subtitle2 color={SECONDARY_TEXT_COLOR}
            weight={400}>
            {currencySymbol}{millify(effectiveSpendLimit)} Limit
          </Subtitle2>
        </FlexContainer>
      </Card>
      {feeTotal > 0 && (
        <>
          <FeeContainer>
            <FlexContainer alignItems="center" justifyContent="space-between">
              <Body2 color={PRIMARY_TEXT_COLOR}>Automation Fee</Body2>
              <Body2 color={PRIMARY_TEXT_COLOR}>+{currencySymbol}{format(feeTotal, true)}</Body2>
            </FlexContainer>
            <FlexContainer
              maxWidth="50%">
              <Caption color={SECONDARY_TEXT_COLOR}>
                {automationFeeSubtitle}
              </Caption>
            </FlexContainer>
          </FeeContainer>
          {/* {!isOrganization && (<FeeContainer>
            <FlexContainer alignItems="center" justifyContent="space-between">
              <Subtitle2 color={PRIMARY_TEXT_COLOR}>Your New Monthly Ad Spend Limit</Subtitle2>
              <Subtitle2 color={PRIMARY_TEXT_COLOR}>{currencySymbol}{format(newSpentLimit)}{showCurrencyCode}</Subtitle2>
            </FlexContainer>
          </FeeContainer>)} */}
        </>
      )}
    </MainContainer>
  )
}

export default SpendLimitAlertCard
