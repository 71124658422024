import { MemberRole, MemberRoles, TeamMember, TeamPricesData } from 'types/global';
import { GridColDef } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import { Body2, Caption } from 'components/shareable/Typography';
import { SystemColors } from 'types/globalStyles';
import { ArtistThumbnailsSection } from 'components/ArtistsThumbnail';
import gravatar from "gravatar";
import { TeamMemberMenu } from './TeamMemberMenu';
import LinkButton from 'components/shareable/LinkButton';

const { MEMBER } = MemberRoles;

export const MemberRolesNames: Record<MemberRole, { name: string; priority: number }> = {
  owner: {
    name: 'Owner',
    priority: 0
  },
  administrator: {
    name: 'Team Admin',
    priority: 1
  },
  member: {
    name: 'Member',
    priority: 2
  },
};

export const getColumnDefinition = (
  openEditMemberModal: (member: TeamMember) => void,
  handleRemoveTeamMember: () => void,
  myRole?: MemberRole,
  teamPrices?: TeamPricesData,
) => {
  const defaultColDef: GridColDef = {
    field: "field",
    headerName: "column",
    type: "string",
    editable: false,
    align: "center",
    flex: 1,
  }
  return [
    {
      ...defaultColDef,
      field: "details",
      align: 'left',
      headerAlign: 'left',
      width: 200,
      minWidth: 300,
      maxWidth: 400,
      renderCell: (params) => {
        const formattedRow = params.row as TeamMember;
        const { name, lastName, email, isCurrentUser } = formattedRow;
        return (
          <div className='flex gap-4 items-center max-w-full overflow-hidden'>
            <Avatar src={gravatar.url(email, { protocol: 'https', d: 'retro' })} sx={{ width: 32, height: 32 }} />
            <div className='flex flex-col gap-1 overflow-ellipsis' style={{ maxWidth: '84%' }}>
              {name && lastName && (
                <Body2
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textWrap: 'nowrap',
                  }}
                  color={SystemColors.PRIMARY_TEXT_COLOR}
                >
                  {name} {lastName}
                </Body2>
              )}
              <Body2
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textWrap: 'nowrap',
                }}
              >
                {email}
              </Body2>
              {isCurrentUser && <Caption color={SystemColors.ACCENT_COLOR}>Your Profile</Caption>}
            </div>
          </div>
        )
      },
    },
    {
      ...defaultColDef,
      field: "role",
      align: 'center',
      headerAlign: 'center',
      width: 100,
      minWidth: 100,
      renderCell: (params) => {
        const formattedRow = params.row as TeamMember;
        return (
          <div className='pl-4'>
            <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>{MemberRolesNames[formattedRow.role].name}</Body2>
          </div>
        )
      },
    },
    {
      ...defaultColDef,
      field: "artistsCount",
      align: 'right',
      headerAlign: 'right',
      width: 150,
      minWidth: 180,
      maxWidth: 250,
      renderCell: (params) => {
        const formattedRow = params.row as TeamMember;
        const { role, artists } = formattedRow;
        return (
          <div className='pr-4'>
            <LinkButton
              underlined
              weight={400}
              onClick={() => openEditMemberModal(formattedRow)}
              text={`Can view ${role === MEMBER ? `${artists.length} profile${artists.length > 1 ? 's' : ''}` : 'all profiles'}`}
            />
          </div>
        )
      },
    },
    {
      ...defaultColDef,
      field: "artistsThumbnails",
      align: 'left',
      headerAlign: 'left',
      width: 100,
      minWidth: 150,
      maxWidth: 250,
      renderCell: (params) => {
        const formattedRow = params.row as TeamMember;
        const { artists } = formattedRow;
        return (
          <ArtistThumbnailsSection thumbnails={artists.map(artist => artist.profile_picture)} />
        )
      },
    },
    {
      ...defaultColDef,
      field: "menu",
      align: 'left',
      headerAlign: 'left',
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      renderCell: (params) => {
        const formattedRow = params.row as TeamMember;
        const {
          isCurrentUser,
          role,
        } = formattedRow;

        if (isCurrentUser) return null;
        return (
          <TeamMemberMenu
            member={formattedRow}
            myRole={myRole}
            onEditPermissions={() => openEditMemberModal(formattedRow)}
            teamPrices={teamPrices}
            handleRemoveTeamMember={() => handleRemoveTeamMember()}
          />
        )
      },
    },
  ] as GridColDef[];
}