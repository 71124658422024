import { NewLoader } from "components/Loader/ConnectBrandLoader";
import AdPreviewBox, { SelectedPost } from "components/shareable/FacebookAdPreview";
import { Fragment, FunctionComponent } from "react";
import { AdsPreviewContainer, Header, Subtitle, ImageContainer, Performance, PerformanceContainer, PerformanceType, PerformanceValue, GraphContainer, HeaderContainer } from "./styles";
import MultiProgress from "react-multi-progress";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { ReactComponent as PreviewIcon } from 'assets/images/campaignDetails/instagram-purple.svg'
import millify from "millify";
import startCase from "lodash/startCase"
import { SymphonyTooltip } from "styles/shared";
import VideoAsImageBanner from "components/shareable/FacebookAdPreview/VideoAsImageBanner";
import { InstagramPageType } from "types/global";

interface Props {
  callToAction?: string
  post: SelectedPost
  performance: CreativePerformance[]
  postUrl: string
  instagramAccount: InstagramPageType
  isLoading: boolean
  showViewOnInstagramButton?: boolean
  handleOnOpenPreview: () => void
}

export interface CreativePerformance {
  type: string,
  clicks: number,
  percentage: number,
}

export const getViewType = (
  values: CreativePerformance[]
) => {
  const [clicksReels, clicksFeed, clicksStories] = values
  const maxValue = Math.max(clicksReels.clicks, clicksFeed.clicks, clicksStories.clicks)
  if (clicksReels.clicks === maxValue) return clicksReels.type
  if (clicksFeed.clicks === maxValue) return clicksFeed.type
  if (clicksStories.clicks === maxValue) return clicksStories.type
  return ""
}

const AdsPreview: FunctionComponent<Props> = ({
  post,
  performance,
  postUrl,
  instagramAccount,
  isLoading,
  showViewOnInstagramButton,
  callToAction = "Listen now",
  handleOnOpenPreview,
}: Props) => {

  const theme = useTheme()
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));

  if (isLoading && setResponsiveView) return <Fragment />

  if (isLoading && !setResponsiveView) {
    return (
      <AdsPreviewContainer
        width={setResponsiveView ? "100%" : "30%"}
        paddingBottom="16px"
      >
        <NewLoader black />
      </AdsPreviewContainer>
    );
  }

  const getAligmentforPerformance = (position: number) => {
    if (position === 0) return "flex-start"
    if (position === performance.length - 1) return "flex-end"
    return "center"
  }

  return (
    <AdsPreviewContainer
      width={setResponsiveView ? "100%" : "30%"}
    >
      <HeaderContainer>
        <div>
          <Header>Creative Performance</Header>
          <Subtitle>by clicks</Subtitle>
        </div>
        {showViewOnInstagramButton && (
          <a
            href={postUrl}
            target="_blank"
            onClick={handleOnOpenPreview}
            rel="noreferrer"
          >
            <SymphonyTooltip title="View on Instagram">
              <PreviewIcon />
            </SymphonyTooltip>
          </a>
        )}
      </HeaderContainer>
      <div>
        <PerformanceContainer>
          {performance.map((value, index) =>
            <Performance alignItems={getAligmentforPerformance(index)} key={value.type}>
              <PerformanceValue>{millify(value.clicks)}</PerformanceValue>
              <PerformanceType>{startCase(value.type)}</PerformanceType>
            </Performance>
          )}
        </PerformanceContainer>
        <GraphContainer>
          <MultiProgress
            elements={[
              { value: performance[0]?.percentage || 0, color: '#8800ffa8' },
              { value: performance[1]?.percentage || 0, color: '#8800ff4f' },
              { value: performance[2]?.percentage || 100, color: '#8800ff29' },
            ]}
          />
        </GraphContainer>
      </div>
      <ImageContainer
        borderRadius={setResponsiveView ? "0" : "0 0 12px 12px"}
      >
        <AdPreviewBox
          callToAction={callToAction}
          captions={[{ type: 'all', caption: post.caption }]}
          selectedPosts={[post]}
          instagramAccount={instagramAccount}
          shown
          simplePreview={post.type}
          muted={false}
        />
        {!post.media_url && post.thumbnail_url && (
          <VideoAsImageBanner borderRadius="6px"/>
        )}
      </ImageContainer>
    </AdsPreviewContainer>
  );
}

export default AdsPreview