import { useEffect } from "react";


function useBaremetricsCancellationWidget(customerId?: string | undefined | null, cancellationEventId: string = "symphony:subscription-cancelled") {

    useEffect(() => {
        if (!customerId) return;

        if (!window.barecancel || !window.barecancel.created) {
            // Check if the script is already loaded
            if (!window.barecancel || !window.barecancel.created) {
                console.log("Initializing Baremetrics cancellation widget");

                const script = document.createElement('script');
                script.src = "https://baremetrics-barecancel.baremetrics.com/js/application.js";
                script.async = true;

                // Ensure window.barecancel is initialized before setting params
                if (!window.barecancel) {
                    window.barecancel = { created: true } as any;
                }

                if (customerId) {
                    window.barecancel!.params = {
                        access_token_id: "3126ce03-b1c3-41d4-935b-894bc48e0237", // Your Cancellation API public key
                        customer_oid: customerId, // The provider id of this customer. For example, the Stripe Customer ID
                        callback_send: function (data) {
                            // Dispatch custom event to notify our app that cancellation is complete
                            // This triggers the handleBaremetricsCancellation function in ProPlanView
                            window.dispatchEvent(new CustomEvent(cancellationEventId));
                            return
                        },
                        callback_error: function (error) {
                            // Handle errors
                            console.error(error);
                            // onCancel && onCancel()

                        }
                    };
                    window.barecancel!.created = true;
                };

                script.onload = () => {


                };

                document.body.appendChild(script);
            }
        }

    }, [customerId]); // Empty dependency array ensures this runs once on mount
}


export default useBaremetricsCancellationWidget