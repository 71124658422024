import { Dispatch, SetStateAction, HTMLProps } from "react";
import Axios from "helpers/Interceptor";
import { queryFB } from "helpers/FB";
import {
    DataFBQueryAdInterest,
    FBCustomAudience,
    FacebookPageResponse,
    FbSuggestion,
    FbSuggestionsResponse,
    GeographicTargetingType,
    SpotifyArtistsOption,
    SuggestedAudience,
} from "types/global";
// import { Option as SpotifyArtistsOption } from "./Components/AutoCompleteSpotifyArtists/utils";
import _ from 'lodash';
import { CAMPAIGN_TYPES, CAMPAIGN_TYPE_ENUM } from "../../../../../constants";
import { geographyTargetingDefaults } from "../../SongAds/TargetingView/data";

export type ContinueButtonProps = HTMLProps<HTMLButtonElement> & {
    type?: "button" | "submit" | "reset";
};

export interface TargetingViewProps {
    isBelowRecommendedBudget?: boolean;
    fbSuggestions: FbSuggestion[];
    loading?: boolean;
    spotifyId?: string;
    contentName?: string;
    setCustomAudiences: (audiences: FBCustomAudience[]) => void;
    customAudiences: FBCustomAudience[];
    genre: string;
    showTrashIcon?: boolean;
    isResponsive?: boolean;
    deleteIconColor?: string;
    showSaveButton?: boolean;
    handleIsEditing?: (isEditing: boolean) => void;
    handleTargets?: (targets: GeographicTargetingType[]) => void;
    handleAudiences?: (audiences: FbSuggestion[]) => void;
    handleArtists?: (artists: SpotifyArtistsOption[]) => void;
    handleTotalBudget?: (budget: number) => void;
    setShowTargetingView: Dispatch<SetStateAction<boolean>>;
    setAdditionalPagesAudiences: (audiences: FacebookPageResponse[]) => void;
    additionalPageAudiences: FacebookPageResponse[];
    minimumRecommendedBudgetText?: string | undefined;
}

export interface SimilarArtistLoaderProps {
    artistName: string;
}

export type NotFoundSimilarArtistsProps = SimilarArtistLoaderProps;

export interface AudienceTargetingProps {
    genre: string;
    spotifyId?: string;

    initialTargetingAudiences: FbSuggestion[];
    deleteIconColor?: string;
    handleTargets?: (targets: GeographicTargetingType[]) => void;
    handleAudiences?: (audiences: FbSuggestion[]) => void;
    handleArtists?: (artists: SpotifyArtistsOption[]) => void;
    handleTotalBudget?: (budget: number) => void;
    setAudiences: Dispatch<SetStateAction<FbSuggestion[]>>;
    setArtists: Dispatch<SetStateAction<SpotifyArtistsOption[]>>;

    setCustomAudiences: (audiences: FBCustomAudience[]) => void;
    customAudiences: FBCustomAudience[];
    openTargetingModal: () => void;

    setAdditionalPagesAudiences: (audiences: FacebookPageResponse[]) => void;
    additionalPageAudiences: FacebookPageResponse[];
    openAdditionalPagesModal: () => void;

}

export const getTargetingViewGenre = (genres?: string[] | null) => {
    const [genre] = genres || [];
    if (genre) return genre;
    return "";
};

export const getInitialTargetAudiences = async (args: {
    genre: string | null;
    access_token?: string;
    setFbSuggestions: Dispatch<SetStateAction<FbSuggestion[]>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
}) => {
    const { genre, access_token, setFbSuggestions, setLoading } = args;

    if (genre) {
        try {
            const path = `/brand/suggested_audiences`;
            const data = { genre };
            const response = await Axios.post(path, data);
            const interestList = response?.data?.data?.map(
                (item: SuggestedAudience) => item.name
            );
            const results = (await queryFB(`/search`, {
                params: {
                    type: "adinterestsuggestion",
                    interest_list: interestList,
                    limit: 10,
                    access_token,
                },
            })) as FbSuggestionsResponse;
            const fbSuggestions = results?.data;

            if (fbSuggestions) {
                setFbSuggestions(fbSuggestions as FbSuggestion[]);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error: unknown) {
            console.error("Error on TargetingView component ", error);
            setFbSuggestions([]);
            setLoading(false);
        }
    } else {
        setLoading(false);
    }
};

export const getLastSpotifyArtist = (
    spotifyArtists: SpotifyArtistsOption[]
) => {
    const lastIndex = spotifyArtists?.length - 1;

    if (Boolean(spotifyArtists.length)) return spotifyArtists[lastIndex];
    return {} as SpotifyArtistsOption;
};

export const getAudienceTargetingGenre = (
    spotifyArtist: SpotifyArtistsOption
) => {
    const [genre] = spotifyArtist?.genres || [];
    if (genre) return genre;
    return "";
};

export const getFbSuggestionsByArtist = async (args: {
    spotifyArtists: SpotifyArtistsOption[];
    spotifyId?: string;
    setSuggestedTargetingAudienceByArtist: Dispatch<
        SetStateAction<FbSuggestion[]>
    >;
    setLoading: Dispatch<SetStateAction<boolean>>;
    access_token?: string;
}) => {
    const {
        spotifyArtists,
        spotifyId,
        setSuggestedTargetingAudienceByArtist,
        setLoading,
        access_token,
    } = args;
    const lastIndex = spotifyArtists.length - 1;
    const lastArtist = spotifyArtists[lastIndex];
    const path = `/spotify/artists/${lastArtist.id}/related-artists`;
    const params: any = {
        seed_genres: lastArtist.genres?.join(","),
    };
    if (spotifyId) params.seed_tracks = spotifyId;
    const options = { params };
    const targetingAudiencesByArtist: FbSuggestion[] = [];

    try {
        setLoading(true);

        const response = await Axios.get(path, options);
        const artistsNames = response?.data?.data?.map(
            (o: SpotifyArtistsOption) => o?.name
        );

        for (const name of artistsNames) {
            const results = await queryFB(`/search`, {
                params: {
                    type: "adinterestvalid",
                    interest_list: [name],
                    limit: 10,
                    access_token,
                },
            });
            const data: DataFBQueryAdInterest = results?.data;
            const filtered = data?.filter((o) => o.name === name);
            const newSuggested = filtered.map((o) => ({
                id: o.id,
                name: o.name,
                path: o.path,
                topic: o.topic,
                description: o.description,
                artistId: lastArtist.id,
                audience_size: o.audience_size || o.audience_size_lower_bound || null,
                country: null,
                country_access: null,
                partner: null,
                source: null,
                type: null,
            }));

            targetingAudiencesByArtist.push(...newSuggested);
        }

        setSuggestedTargetingAudienceByArtist(targetingAudiencesByArtist);
        setLoading(false);
    } catch (error: unknown) {
        console.error("Error on component AudienceTargeting", error);
        setLoading(false);
    }
};

export const getFormattedGeographyTargeting = (campaignType: CAMPAIGN_TYPE_ENUM, geographyTargeting?: GeographicTargetingType[]) => {
    if (!geographyTargeting) return geographyTargetingDefaults(campaignType || CAMPAIGN_TYPES.record_streams);
    return geographyTargeting.map((geographyTarget) => {
        return {
            ...geographyTarget,
            locations: _.keyBy(geographyTarget.locations, 'value')
        } as GeographicTargetingType
    })
}
