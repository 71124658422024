import { useState, createContext, ReactNode, FunctionComponent } from "react";
import { CurrentBrand } from "types/global";
import Axios from "helpers/Interceptor";

interface Context {
    currentBrand: CurrentBrand | undefined;
    setCurrentBrand: (brand: any) => CurrentBrand | undefined;
    reloadBrand: () => Promise<CurrentBrand> | undefined;
    loadingBrand: boolean;
}

export const CurrentBrandContext = createContext<Context>({
    currentBrand: undefined,
    setCurrentBrand: () => undefined,
    reloadBrand: () => undefined,
    loadingBrand: false
});

type Props = {
    brand?: CurrentBrand;
    children: ReactNode[] | ReactNode;
};
/**
 * Provider component that manages the current brand state and provides methods to update it
 * @param {Props} props - Component props
 * @param {ReactNode[] | ReactNode} props.children - Child components that will have access to the brand context
 * @returns {JSX.Element} Provider component wrapping children with brand context
 */
const CurrentBrandProvider: FunctionComponent<Props> = ({
    children,
}: Props): JSX.Element => {
    // State to store the currently selected brand
    const [storedBrand, setBrand] = useState<CurrentBrand | undefined>(undefined);
    // Loading state for async operations
    const [loading, setLoading] = useState<boolean>(false);

    /**
     * Reloads the current brand data from the API
     * @returns {Promise<CurrentBrand>} Promise resolving to the reloaded brand data
     * @throws {Error} If the API request fails
     */
    async function reloadBrand(): Promise<CurrentBrand> {
        setLoading(true);
        var getBrand = await Axios.get(`/brand/${storedBrand?.id}`);
        const reloadedBrand: CurrentBrand = getBrand.data.data
        setBrand(reloadedBrand);
        setLoading(false);

        return reloadedBrand
    }

    /**
     * Updates the current brand and persists the selection to localStorage
     * @param {CurrentBrand} brand - The brand to set as current
     * @returns {CurrentBrand | undefined} The updated brand or undefined
     */
    function setCurrentBrand(brand: CurrentBrand): CurrentBrand | undefined {
        // Prevent unnecessary updates if the brand ID hasn't changed
        if (brand?.id === storedBrand?.id) {
            return storedBrand;
        }

        // Persist selected brand ID to localStorage if available
        if (brand?.id) {
            localStorage.setItem('selected-brand', brand.id.toString());
        }

        setBrand(brand);
        return brand;
    }

    /**
     * Context value containing current brand state and methods
     */
    const context = {
        currentBrand: storedBrand,
        setCurrentBrand,
        reloadBrand,
        loadingBrand: loading
    };

    return (
        <CurrentBrandContext.Provider value={context}>
            {children}
        </CurrentBrandContext.Provider>
    );
};

export default CurrentBrandProvider;
