import axios from 'axios';

export const checkIfUrlExpired = async (url: string) => {
  try {
    const response = await axios.get(url);
    if (!response || response.status === 404) {
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error checking if URL is expired: ', error);
    return true;
  }
};
