import dayjs from "dayjs"
import {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  FunctionComponent,
  useContext,
  useMemo,
} from "react"
import { TabPanel, useTabs } from "react-headless-tabs"
import MainContentContainer from "components/shareable/MainContentContainer"
import BudgetTab from "./BudgetTab"
import CampaignCreationPage from "./CampaignCreationPage"
import CreativeTab from "./CreativeTab"
import Finalization from "./FinalizationTab"
import RecordSelectorTab, { AddedLinksType } from "components/shareable/RecordSelectorTab"
import { track } from "analytics"
import TargetingView from "./TargetingView"
import {
  CurrentBrand,
  FbSuggestion,
  CreatedCampaign,
  SavingDraftStatus,
  FBCustomAudience,
  FacebookPageResponse,
  CurrencyCodesType,
  UserData,
} from "types/global"
import { useHistory } from "react-router-dom"
import { InstagramPost } from "components/shareable/InstagramPosts/types"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { useStyles, LoadingContainer } from "./styles"
import {
  Platforms,
  SelectedPlatforms,
  getWhenCreativeTabNextButtonIsDisabled,
  getIfTargetingSaveButtonIsDisabled,
  STEPS,
  getNextButtonLabel,
  getNextButtonWidth,
  formatValidLinksAdded,
} from "./utils"
import startCase from "lodash/startCase"
import TopBar from "components/shareable/TopBar"
import CustomStepper from "components/shareable/CustomStepper"
import Grid from "@material-ui/core/Grid"
import { CAMPAIGN_CREATED, CREATING_CAMPAIGN, PLATFORMS } from "./constants"
import { GeographicTargetingType } from "./TargetingView/reducer"
import { Option } from "./TargetingView/Components/AutoCompleteSpotifyArtists/utils"
import { geographyTargetingDefaults, lowBudgetGeographyTargetingDefaults } from "./TargetingView/data"
import { MarketingDataContext } from "../Data/Provider"
import { scrollIntoView, scrollToTop } from "helpers/StyleUtils"
import { ConnectModalsContext } from "Hooks/ConnectModalsProvider"
import clsx from "clsx"
import { useLocation } from "react-router-dom"
import { getNotRemovableOptions, NOT_REMOVABLE } from "./TargetingView/Components/AutoCompleteAudiences/utils"
import uniqBy from "lodash/uniqBy"
import { fixGeographicTargets } from "utils"
import { useDraftDataGetter, useDraftDataSetter } from "./Hooks/DraftsHandlers"
import { AssetUploadTypes, getIfPagesAreSelected, hasAtLeastOnePlatform, hasEnteredCaption, UploadedAssetType } from "../utils/fbCampaigns"
import { LoadingIndicator } from "components/Loader"
import useSwitch from "Hooks/useSwitch"
import SpendBudgetProvider from "Hooks/BudgetTabSpendContext"
import { CAMPAIGN_TYPE_ENUM, GEOGRAPHY_TARGETING_DEFAULTS, CAMPAIGN_TYPES as GLOBAL_CAMPAIGN_TYPES } from "../../../../constants"
import { getInitialTargetAudiences, getTargetingViewGenre } from "../Components/TargetingView/utils"
import { MobileAdPreviewBar } from "./MobileAdPreviewBar"
import useMediaBreakpoints from "Hooks/useMediaBreakpoints"
import { usePlatformsHandler } from "./Hooks/PlatformsHandlers"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { CreativeAssetTypes } from "../constants"
import { getConvertedCurrency } from "services/symphonyApi"
import getSymbolFromCurrency from "currency-symbol-map"
import { updateBrandContentExternalLinks } from "services/symphonyApi/brandContentService"
import { ConnectionsContext } from "../hooks/ConnectionsContext"

const { RECORD, BUDGET, CREATIVE, FINALIZATION } = STEPS
const { INITIAL_SAVING_STATE } = SavingDraftStatus
const tabs = [RECORD, CREATIVE, BUDGET, FINALIZATION]
const { UPLOAD_VIDEO, INSTAGRAM_POSTS } = CreativeAssetTypes

export type SongAdsCampaignSubtype = "grow_playlist_followers" | "increase_record_streams"
interface Props {
  setGoal: Dispatch<SetStateAction<string | null>>
  refreshCampaigns: () => void
  reloadBrands: () => void;
  currentUser: UserData;
  subtype: SongAdsCampaignSubtype
}

const SongAds: FunctionComponent<Props> = ({
  refreshCampaigns,
  reloadBrands,
  currentUser,
  subtype = "increase_record_streams"
}: Props) => {
  const classes = useStyles()

  // set the campaign type enum based on the subtype
  let campaignTypeEnum = subtype === "grow_playlist_followers" ? CAMPAIGN_TYPE_ENUM.grow_playlist_followers : CAMPAIGN_TYPE_ENUM.record_streams

  const theme = useTheme()
  const location = useLocation()
  const { mediumView, mobileView } = useMediaBreakpoints()
  const draft = new URLSearchParams(location.search).get("draft")
  const { data: connectModalData, saveData } = useContext(ConnectModalsContext)
  const { loading } = connectModalData
  const { currentBrand } = useContext(CurrentBrandContext) as { currentBrand: CurrentBrand }

  const {
    saveTargets,
    saveAudiences,
    saveCustomAudiences,
    saveAdditionalPageAudiences,
    saveArtists,
    saveDemographics,
    demographics,
  } = useContext(MarketingDataContext)

  const {
    connectionsType,
    facebookPage: selectedFB_page,
    instagramPage: selectedInstaPage,
    facebookAdAccount: selectedFBAdAccount,
    facebookPixel: selectedFBPixel,
    facebookBusinessManager: selectedFBBusinessManager,
    loggedInFbUser,
    areConnectionsValid: adConnectionsAdded,
    connectionsConfirmed: adConnectionsConfirmed,
    conversionsEnabled,
    preConnectionConfigurations,
    areTermsAccepted,
    validatingConnections,
    needsToReLogIn,
    setConnectionsConfirmed: setAdConnectionsConfirmed,
    setAreConnectionsValid: setAdConnectionsAdded,
  } = useContext(ConnectionsContext)

  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const isNextButtonResponsive = useMediaQuery(theme.breakpoints.down(601))
  const isNextButtonMobile = useMediaQuery(theme.breakpoints.down(401))
  const isTargetingViewResponsive = useMediaQuery(theme.breakpoints.down(551))
  const history = useHistory()
  const [customAudiences, setCustomAudiences] = useState<FBCustomAudience[]>([])
  const [additionalPageAudiences, setAdditionalPagesAudiences] = useState<FacebookPageResponse[]>([])
  const defaultGeographyTargeting = geographyTargetingDefaults(campaignTypeEnum)
  const [targets, setTargets] = useState<GeographicTargetingType[]>(defaultGeographyTargeting)
  const [audiences, setAudiences] = useState<FbSuggestion[]>([])
  const [artists, setArtists] = useState<Option[]>([])
  const [totalBudget, setTotalBudget] = useState(Number(Object.values(targets).reduce((p, c) => p + c.budget, 0).toFixed(2)))
  const [isEditingTargeting, setIsEditingTargeting] = useState(false)
  const [selectedTab, setSelectedTab] = useTabs(tabs, RECORD)
  const [finished, setFinished] = useState(false)
  const [recentlyCreatedCampaign, setRecentlyCreatedCampaign] = useState<CreatedCampaign | null>(null)
  const [showTargetingView, setShowTargetingView] = useState(false)
  const [fbSuggestions, setFbSuggestions] = useState<FbSuggestion[]>([])
  const [loadingInitialAudiences, setLoadingInitialAudiences] = useState(false)

  // DSP specific assets (not used)
  const [spotifyAssets, setSpotifyAssets] = useState<any>(null)
  const [appleAssets, setAppleAssets] = useState<any>(null)
  const [youtubeAssets, setYouTubeAssets] = useState<any>(null)

  // This is primarily used for validation purposes
  // when connecting an IG
  const [selectedInstagramPost, setSelectedInstagramPost] = useState<InstagramPost>()
  const [addVisualSelected, setAddVisualSelected] = useState<AssetUploadTypes>(UPLOAD_VIDEO);
  const [recordSelected, setRecordSelected] = useState<any>(false)

  // for video uploader & ad previews
  const [uploadedImages, setUpload] = useState<UploadedAssetType[] | null>(null)
  const [creativeSelectorTab, setCreativeSelectorTab] = useState<AssetUploadTypes>(UPLOAD_VIDEO)
  const [previewedVideo, setPreviewedVideo] = useState<UploadedAssetType | null>(null)

  // for mobile + tablet - opens the modal to show ad preview
  const [showPreview, enablePreviewMode] = useState<boolean>(false)

  // budgeting
  const [budgetValue, setBudgetValue] = useState<number>(0);
  const [currency, setCurrency] = useState<CurrencyCodesType>("USD");
  const [isBelowRecommendedBudget, setIsBelowRecommendedBudget] = useState<boolean>(false);
  const [minimumRecommendedBudgetValue, setMinimumRecommendedBudgetValue] = useState<number | null>(null);
  const [minimumRecommendedBudgetFormattedString, setMinimumRecommendedBudgetFormattedString] = useState<string>(`$100 USD`);

  // start and end date logistics
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [creatingCampaign, allowCampaignCreation] = useState(false)
  const [selectedPlatforms, setSelectedPlatforms] = useState<SelectedPlatforms>(PLATFORMS)
  const [isBudgetTabNextButtonDisabled, setIsBudgetTabNextButtonDisabled] = useState(false)
  const [isSavingDraft, setIsSavingDraft] = useState(false)
  const [campaignDraftData, setCampaignDraftData] = useState<Record<string, unknown> | null>(null)
  const [retrievingDraftData, setRetrievingDraftData] = useState(!!draft)
  const [requiredDataChanged, setRequiredDataChanged] = useState(false)
  const [savingDraftStatus, setSavingDraftStatus] = useState(INITIAL_SAVING_STATE)
  const [addedLinks, setAddedLinks] = useState<AddedLinksType[]>([])
  const primaryOrganization = currentUser.organization
  useEffect(() => {
    if (!previewedVideo && uploadedImages && uploadedImages.length > 0
      || uploadedImages && uploadedImages.length === 1) {
      setPreviewedVideo(uploadedImages[0])
    }
  }, [uploadedImages])

  // platform specific captions
  const [captions, setCaptions] = useState<any>({
    all: null,
    spotify: null,
    apple_music: null,
    youtube: null,
    soundcloud: null,
    custom: null,
  })

  // custom link
  const [links, setLinks] = useState<any>({
    custom: {
      value: "",
      valid: false,
      checkingValidity: false
    }
  })

  let recommendedUsdBudget = 100;

  // TODO: finalize actual recommended budget
  function updatedCurrency(newCurrency: CurrencyCodesType) {
    setCurrency(newCurrency);
  }

  // TOOD: Optimize this call so it doesnt call getConvertedCurrency so much
  const checkMinimumBudgetStatus = async () => {
    let localizedMinimumBudget = recommendedUsdBudget
    localizedMinimumBudget = await getConvertedCurrency({ from: "USD", to: currency, amount: recommendedUsdBudget });
    setMinimumRecommendedBudgetValue(localizedMinimumBudget)

    // set the currency string so we can use it if the budget is below recommended
    const currencySymbol = getSymbolFromCurrency(currency)
    setMinimumRecommendedBudgetFormattedString(`${currencySymbol}${localizedMinimumBudget.toFixed(0)} ${currency}`)

    return budgetValue < localizedMinimumBudget;
  }

  async function recheckIfBudgetOverRecommendation() {
    const belowRecommendedBudget = await checkMinimumBudgetStatus() || false
    setIsBelowRecommendedBudget(belowRecommendedBudget)
  }

  useEffect(() => {
    if (!previewedVideo && uploadedImages && uploadedImages.length > 0
      || uploadedImages && uploadedImages.length === 1) {
      setPreviewedVideo(uploadedImages[0])
    }
  }, [uploadedImages])

  useEffect(() => {
    recheckIfBudgetOverRecommendation()
  }, [budgetValue, currency]);

  useEffect(() => {
    if (isBelowRecommendedBudget) {
      setTargets(lowBudgetGeographyTargetingDefaults);
      saveTargets(lowBudgetGeographyTargetingDefaults);
      // when the budget goes below recommended budget:
      // - default to the below recommended budget targets
      // - otherwise use the default targeting
    } else {
      setTargets(defaultGeographyTargeting);
      saveTargets(defaultGeographyTargeting);
    }
  }, [isBelowRecommendedBudget]);

  const platformsSwitch = useSwitch()

  const uploadingAssets = selectedPlatforms["all"].uploadingAssets

  const steps = Object.values(STEPS).map((value) => ({
    description: startCase(value),
    name: value,
    onClickHandler: (step: string) => () => {
      if (step === RECORD || (step === CREATIVE && recordSelected))
        setSelectedTab(step)
    },
  }))

  let mainTitle;

  if (recordSelected) {
    mainTitle = `Promote "${(recordSelected as { name: string }).name}"`
  } else {
    if (subtype === "grow_playlist_followers") {
      mainTitle = "Grow Playlist Followers"
    } else {
      mainTitle = "Increase Music Streams"
    }
  }


  const genres = currentBrand?.connections?.spotify_artist_page?.genres
  const genre = getTargetingViewGenre(genres)

  const spotifyConnected =
    currentBrand && currentBrand.connections.spotify_artist_page
      ? currentBrand.connections.spotify_artist_page
      : null

  const disableTargetingSaveButton = getIfTargetingSaveButtonIsDisabled(
    targets,
    totalBudget
  )

  let igMedia = undefined
  if (creativeSelectorTab === INSTAGRAM_POSTS || addVisualSelected === INSTAGRAM_POSTS) {
    igMedia = selectedInstagramPost
  }

  const showLoading = !!draft && retrievingDraftData
  const dataToSaveDraft = [
    recordSelected,
    selectedFB_page,
    selectedFBAdAccount,
    selectedInstaPage,
    uploadedImages,
    selectedInstagramPost,
    captions,
    budgetValue,
    startDate,
    endDate,
    targets,
    audiences,
    customAudiences,
    additionalPageAudiences,
    spotifyAssets,
    appleAssets,
    youtubeAssets,
    selectedTab,
    demographics?.age?.min || null,
    demographics?.age?.max || null,
    demographics?.gender || null,
    demographics?.languages || null,
    addVisualSelected,
    conversionsEnabled,
    selectedFBPixel,
    selectedFBBusinessManager,
    loggedInFbUser,
  ]
  const notRemovableOptions = getNotRemovableOptions(genre)
  const initialTargetingAudiences = uniqBy([...notRemovableOptions, ...fbSuggestions], "id")
  const targetAudiences = Boolean(audiences.length) ? audiences : initialTargetingAudiences
  const fixedGeographicTargets = Boolean(targets.length)
    ? fixGeographicTargets(targets)
    : fixGeographicTargets(GEOGRAPHY_TARGETING_DEFAULTS)
  const fixedTargetingAudiences = targetAudiences
    .filter(({ id }) => !id?.includes(NOT_REMOVABLE))
    .map(({ id, name }) => ({
      fbId: id,
      name,
    }));
  const audiencesData = {
    geographicTargets: fixedGeographicTargets,
    interestTargetingAudiences: fixedTargetingAudiences,
  }

  const handleSaveTargetingData = () => {
    saveTargets(targets)
    saveAudiences(audiences || [])
    saveCustomAudiences(customAudiences || [])
    saveAdditionalPageAudiences(additionalPageAudiences || [])
    saveArtists(artists || [])
    setShowTargetingView(false)
  }

  const saveWhenBudgetTabButtonIsDisabled = (isDisabled: boolean) => {
    setIsBudgetTabNextButtonDisabled(isDisabled)
  }

  const handleConfirmConnections = () => {
    track("Completed Campaign Step - Account Connections", {
      type: subtype,
      step: 2,
      name: "connect_accounts",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      content_name: recordSelected.name,
      content_slug: recordSelected.slug,
      content_id: recordSelected.id,
      addedFacebook: selectedFB_page ? true : false,
      addedInstagram: selectedInstaPage ? true : false
    })

    setAdConnectionsAdded(true)
    setAdConnectionsConfirmed(true)
  }

  const handleClickContinueCreativeTab = () => {
    const keys = Object.keys(selectedPlatforms)

    const objects = keys
      .map((o: string) => {
        return {
          ...selectedPlatforms[o as Platforms],
        }
      })
      .filter((o: { selected: boolean }) => {
        return o.selected
      })
      .map((o: { name: string }) => {
        return o.name
      })

    track("Completed Campaign Step - Creative Assets", {
      type: subtype,
      step: 3,
      name: "add_creatives",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      content_name: recordSelected.name,
      content_slug: recordSelected.slug,
      content_id: recordSelected.id,
      creative_assets: selectedInstagramPost ? 1 : uploadedImages?.length || 0,
      selected_platforms: objects,
    })

    setSelectedTab(BUDGET)
  }

  const handleClickCloseTopBar = () => {
    history.push("/marketing")
  }

  const handleClickBackButton = () => {
    if (showTargetingView) {
      setShowTargetingView(false)
    } else if (selectedTab === STEPS.RECORD) {
      if (recordSelected) {
        setRecordSelected(false)
      } else {
        history.push("/marketing")
      }
    } else if (selectedTab === CREATIVE) {
      setSelectedTab(tabs[tabs.indexOf(selectedTab as STEPS) - 1])
    } else {
      setSelectedTab(tabs[tabs.indexOf(selectedTab as STEPS) - 1])
    }
  }

  const handleClickNextButton = () => {
    if (selectedTab === RECORD && recordSelected) {
      handleRecordTabNextButton()
    } else if (selectedTab === CREATIVE) {
      if (adConnectionsAdded && adConnectionsConfirmed) {
        handleClickContinueCreativeTab()
      } else {
        handleConfirmConnections()
      }
    } else if (selectedTab === BUDGET) {
      setSelectedTab(FINALIZATION)
    }
  }

  useEffect(() => {
    if (draft && selectedTab !== RECORD && (!adConnectionsAdded || !areTermsAccepted)) {
      setSelectedTab(CREATIVE)
    }
  }, [adConnectionsAdded, areTermsAccepted, selectedTab])

  useEffect(() => {
    getInitialTargetAudiences({
      genre,
      setFbSuggestions,
      setLoading: setLoadingInitialAudiences,
      access_token: loggedInFbUser?.access_token,
    })
  }, [])

  const handleRecordTabNextButton = async () => {
    if (addedLinks.length) {
      const formattedLinksToUpdate = formatValidLinksAdded(addedLinks, recordSelected.external_links);
      saveData(prev => ({ ...prev, loading: true }))

      for (const linkToAdd of formattedLinksToUpdate) {
        await updateBrandContentExternalLinks(currentBrand.slug as string, recordSelected.slug, linkToAdd)
      }

      const formattedLinksToAdd = formattedLinksToUpdate.map(link => {
        return {
          name: link.serviceName,
          url: link.url
        }
      })

      saveData(prev => ({ ...prev, loading: false }))
      setRecordSelected((prev: any) => ({ ...prev, external_links: [...prev.external_links, ...formattedLinksToAdd] }))
    }

    setSelectedTab(CREATIVE)
    track("Completed Campaign Step - Content Selection", {
      type: subtype,
      step: 1,
      name: "select_song",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      content_name: recordSelected.name,
      content_slug: recordSelected.slug,
      content_id: recordSelected.id,
    })
  }

  const handleBudgetTabNextButton = () => {
    track("Completed Campaign Step - Budget", {
      type: subtype,
      step: 4,
      name: "set_budget",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      content_name: recordSelected.name,
      content_slug: recordSelected.slug,
      content_id: recordSelected.id,
      budget: budgetValue,
      start_date: dayjs(startDate).format("YYYY-MM-DD"),
      end_date: dayjs(endDate).format("YYYY-MM-DD"),
    })

    setSelectedTab(FINALIZATION)
  }

  const createdCampaign = (campaign: CreatedCampaign | null) => {
    setFinished(true)
    setRecentlyCreatedCampaign(campaign)
  }

  useEffect(() => {
    if (!recordSelected) {
      setCaptions({
        all: null,
        spotify: null,
        apple_music: null,
        youtube: null,
        custom: null,
      })

      setUpload(null)
      setSpotifyAssets(null)
      setAppleAssets(null)
      setYouTubeAssets(null)
    }
  }, [recordSelected])

  useEffect(() => {
    scrollToTop()
    scrollIntoView();
  }, [selectedTab, showTargetingView, adConnectionsConfirmed])

  const platformsSetters = usePlatformsHandler({
    conversionsEnabled,
    platformsSwitch,
    captions,
    selectedPlatforms,
    recordSelected,
    creativeSelectorTab,
    campaignDraftData,
    setters: {
      setCaptions,
      setSelectedPlatforms,
    }
  })

  useDraftDataGetter({
    draft,
    subtype,
    setters: {
      setRetrievingDraftData,
      setRecordSelected,
      setBudgetValue,
      setSelectedTab,
      setCampaignDraftData,
      setTargets,
      setAudiences,
      saveDemographics,
      setSelectedInstagramPost,
      setUpload,
      setSpotifyAssets,
      setAppleAssets,
      setYouTubeAssets,
      setCaptions,
      setEndDate,
      setStartDate,
      setCustomAudiences,
      setAdditionalPagesAudiences,
      saveTargets,
      saveAudiences,
      saveArtists,
      setAddVisualSelected,
    } as Record<string, Dispatch<unknown>>,
  })

  const { cancelSavingDraft } = useDraftDataSetter({
    subtype,
    audiencesData,
    dataToSaveDraft,
    recordSelected,
    requiredDataChanged,
    retrievingDraftData,
    setters: {
      setRequiredDataChanged,
      setIsSavingDraft,
      setCampaignDraftData,
      setSavingDraftStatus,
    } as Record<string, Dispatch<unknown>>,
    campaignDraftData,
    currentBrand,
    draft,
    savingDraftStatus
  })

  // only allow ad preview to be shown on creative page
  const canShowAdPreview: boolean = useMemo(
    () => selectedTab === CREATIVE && adConnectionsAdded && adConnectionsConfirmed,
    [selectedTab, adConnectionsAdded, adConnectionsConfirmed]
  )

  const getArePagesSelected = () => {
    return getIfPagesAreSelected(connectionsType, {
      fbPixel: selectedFBPixel,
      fbAdAccount: selectedFBAdAccount,
      fbPage: selectedFB_page,
      instaPage: selectedInstaPage,
    }, {
      fbPixel: false,
      fbAdAccount: true,
      fbPage: true,
      instaPage: false,
    })
  }

  const getDisableNextButtonWhenCreativeTab = () => {
    const arePagesSelected = getArePagesSelected()
    return getWhenCreativeTabNextButtonIsDisabled({
      uploadedImages,
      adConnectionsAdded,
      adConnectionsConfirmed,
      uploadingAssets,
      selectedInstagramPost,
      selectedFBPixel,
      connectionsType,
      selectedFB_page,
      selectedInstaPage,
      arePagesSelected,
      selectedFBAdAccount,
      preConnectionConfigurations,
      selectedCreativeType: creativeSelectorTab,
    })
  }

  const getDisableNextButton = () => {
    const disableForTargeting = showTargetingView && (disableTargetingSaveButton || isEditingTargeting)

    if (disableForTargeting) return true

    switch (selectedTab) {
      case RECORD:
        return !recordSelected
      case CREATIVE:
        const disableNextButtonWhenCreativeTab = getDisableNextButtonWhenCreativeTab()

        const conditionsToDisable = [
          disableNextButtonWhenCreativeTab,
          (!hasEnteredCaption(captions) &&
            !conversionsEnabled) &&
          adConnectionsAdded &&
          adConnectionsConfirmed,
          !hasAtLeastOnePlatform(selectedPlatforms) &&
          conversionsEnabled &&
          adConnectionsAdded &&
          adConnectionsConfirmed,
          adConnectionsAdded &&
          adConnectionsConfirmed &&
          captions.custom &&
          (!links.custom.value || !links.custom.valid),
          selectedInstagramPost && selectedInstagramPost.explicit_content,
          selectedFBAdAccount && !areTermsAccepted,
          !selectedFBAdAccount || !selectedFB_page,
          needsToReLogIn,
        ]
        return conditionsToDisable.some(condition => condition)
      case BUDGET:
        return isBudgetTabNextButtonDisabled
      case FINALIZATION:
        return !showTargetingView
      default:
        return false
    }
  }

  if (creatingCampaign || finished) {
    return (
      <>
        <TopBar title={finished ? CAMPAIGN_CREATED : CREATING_CAMPAIGN} />
        <CampaignCreationPage
          reloadBrands={reloadBrands}
          primaryOrganization={primaryOrganization ? primaryOrganization : undefined}
          draft={campaignDraftData?.id as string}
          links={links}
          goal={subtype}
          subtype={subtype}
          igMedia={igMedia}
          budget={budgetValue}
          startDate={startDate}
          endDate={endDate}
          record={recordSelected}
          assets={{
            all: uploadedImages,
            spotify: spotifyAssets,
            apple: appleAssets,
            youtube: youtubeAssets,
            custom: []
          }}
          captions={captions}
          createdCampaign={createdCampaign}
          finished={finished}
          customAudiences={customAudiences}
          additionalPageAudiences={additionalPageAudiences}
          recentlyCreatedCampaign={recentlyCreatedCampaign}
          refreshCampaigns={refreshCampaigns}
          setSelectedTab={setSelectedTab}
          setFinished={setFinished}
          allowCampaignCreation={allowCampaignCreation}
          fbSuggestions={fbSuggestions}
        />
      </>
    )
  }

  return (
    <Grid>
      <TopBar
        showCloseIcon
        showSaveDraftChip={!!draft || !!recordSelected}
        savingChanges={isSavingDraft}
        title={mainTitle}
        handleClose={handleClickCloseTopBar}
      />
      <CustomStepper
        {...{ steps }}
        activeStep={steps.map(({ name }) => name).indexOf(selectedTab as STEPS)}
        stepButtonsProps={{
          handleClickBackButton,
          handleClickNextButton: showTargetingView
            ? handleSaveTargetingData
            : handleClickNextButton,
          showNextButton: !(selectedTab === FINALIZATION && !showTargetingView),
          disableNextButton: getDisableNextButton(),
          nextButtonLabel: getNextButtonLabel({
            isNextButtonMobile,
            showTargetingView,
            selectedTab,
          }),
          nextButtonWidth: getNextButtonWidth({
            isNextButtonMobile,
            isNextButtonResponsive,
            selectedTab
          }),
          loadingNextButton: loading || validatingConnections,
        }}
        stepperButtonAmendments={
          <MobileAdPreviewBar
            {...{
              creativeSelectorTab,
              enablePreviewMode,
              previewedVideo,
              selectedInstagramPost,
              uploadedImages,
            }}
            show={mediumView && canShowAdPreview}
          />
        }      >
        {showLoading && (
          <LoadingContainer>
            <LoadingIndicator color="black" height="80px" />
            <p className="text-center mt-4">Loading Campaign Draft...</p>
          </LoadingContainer>
        )}
        {!showLoading && (
          <MainContentContainer
            backgroundColor="transparent"
            style={{
              marginTop: mobileView ? 0 : 16,
            }}
            className={clsx(
              classes.mainContainer,
              canShowAdPreview && classes.creativeTabContainer,
              `${selectedTab === CREATIVE && 'w-full max-w-full flex gap-4 justify-center'}`,
            )}
          >
            <Grid
              className={clsx(
                `h-full w-full rounded-${isMobile ? "none" : "xl"}`,
                showTargetingView && classes.mbWhenTargetingView,
                !showTargetingView && classes.mbWhenNotTargetingView,
                canShowAdPreview && classes.gridCreativeTabContainer,
              )}
            >
              {showTargetingView ?
                <TargetingView
                  minimumRecommendedBudgetText={minimumRecommendedBudgetFormattedString}
                  isBelowRecommendedBudget={isBelowRecommendedBudget}
                  deleteIconColor="#80f"
                  isResponsive={isTargetingViewResponsive}
                  showTrashIcon={showTargetingView}
                  setShowTargetingView={setShowTargetingView}
                  spotifyId={recordSelected?.spotify_id}
                  contentName={recordSelected?.name || ""}
                  fbSuggestions={fbSuggestions}
                  loading={loadingInitialAudiences}
                  genre={genre}
                  handleArtists={(artists: Option[]) => setArtists(artists)}
                  handleAudiences={(audiences: FbSuggestion[]) => setAudiences(audiences)}
                  customAudiences={customAudiences}
                  setCustomAudiences={setCustomAudiences}
                  additionalPageAudiences={additionalPageAudiences}
                  setAdditionalPagesAudiences={setAdditionalPagesAudiences}
                  handleTargets={(targets: GeographicTargetingType[]) => setTargets(targets)}
                  handleTotalBudget={(budget: number) => setTotalBudget(budget)}
                  handleIsEditing={(isEditing: boolean) => setIsEditingTargeting(isEditing)}
                  showSaveButton={false}
                />
                :
                <div className="w-full">
                  <TabPanel hidden={selectedTab !== RECORD}>
                    <RecordSelectorTab
                      className="songAdsRecordSelectorTab"
                      title={subtype === "grow_playlist_followers" ? "Select the playlist you want to promote." : "Select the release you want to promote."}
                      subtype={subtype}
                      spotifyConnected={spotifyConnected}
                      setRecordSelected={setRecordSelected}
                      recordSelected={recordSelected}
                      columns={[
                        "artist",
                        "type",
                        "links",
                        "links-adder",
                      ]}
                      requireLinks={["spotify", "apple_music"]}
                      setAddedLinks={setAddedLinks}
                    />
                  </TabPanel>
                  <TabPanel hidden={selectedTab !== CREATIVE}>
                    <CreativeTab
                      setLinks={setLinks}
                      links={links}
                      captions={captions}
                      setPreviewedVideo={setPreviewedVideo}
                      previewedVideo={previewedVideo}
                      creativeSelectorTab={creativeSelectorTab}
                      setCreativeSelectorTab={setCreativeSelectorTab}
                      setAddVisualSelected={setAddVisualSelected}
                      selectedRecord={recordSelected}
                      selectedAssets={uploadedImages}
                      setSelectedAssets={setUpload}
                      setSpotifyAssets={setSpotifyAssets}
                      selectedSpotifyAssets={spotifyAssets}
                      setAppleAssets={setAppleAssets}
                      selectedAppleAssets={appleAssets}
                      setYoutubeAssets={setYouTubeAssets}
                      selectedYoutubeAssets={youtubeAssets}
                      selectedInstagramPost={selectedInstagramPost}
                      setSelectedInstagramPost={setSelectedInstagramPost}
                      setSelectedPlatforms={setSelectedPlatforms}
                      selectedPlatforms={selectedPlatforms}
                      campaignDraftData={campaignDraftData}
                      platformsSwitch={platformsSwitch}
                      selectedCampaignType={campaignTypeEnum}
                      enablePreviewMode={enablePreviewMode}
                      showPreview={showPreview}
                      {...platformsSetters}
                    />
                  </TabPanel>
                  <SpendBudgetProvider
                    {...{ budgetValue }}
                    currentUser={currentUser}
                    step={selectedTab as string}
                    fbAccountId={selectedFBAdAccount?.id || null}
                    campaignType={subtype === "grow_playlist_followers" ? GLOBAL_CAMPAIGN_TYPES.grow_playlist_followers : GLOBAL_CAMPAIGN_TYPES.record_streams}
                    access_token={loggedInFbUser?.access_token}
                  >
                    <TabPanel hidden={selectedTab !== BUDGET}>
                      <BudgetTab
                        updatedCurrency={updatedCurrency}
                        budgetValue={budgetValue}
                        setBudgetValue={setBudgetValue}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        record={recordSelected}
                        finishFlow={handleBudgetTabNextButton}
                        saveWhenNextButtonIsDisabled={
                          saveWhenBudgetTabButtonIsDisabled
                        }
                        showReviewButton={false}
                        selectedTab={selectedTab}
                      />
                    </TabPanel>
                    <TabPanel hidden={selectedTab !== FINALIZATION}>
                      <Finalization
                        {...{ selectedTab }}
                        isBelowRecommendedBudget={isBelowRecommendedBudget}
                        draft={campaignDraftData?.id}
                        budget={budgetValue}
                        startDate={startDate}
                        endDate={endDate}
                        record={recordSelected}
                        setSelectedTab={setSelectedTab}
                        addVisualSelected={addVisualSelected}
                        allowCampaignCreation={allowCampaignCreation}
                        setShowTargetingView={setShowTargetingView}
                        fbSuggestions={fbSuggestions}
                        captions={captions}
                        assets={{
                          all: uploadedImages,
                          spotify: spotifyAssets,
                          apple: appleAssets,
                          youtube: youtubeAssets,
                          custom: []
                        }}
                        goal={subtype}
                        igMedia={igMedia}
                        cancelSaving={cancelSavingDraft}
                        handleSaveTargetingData={handleSaveTargetingData}
                        customAudiences={customAudiences}
                        additionalPageAudiences={additionalPageAudiences}
                        primaryOrganization={primaryOrganization}
                        subtype={subtype}
                      />
                    </TabPanel>
                  </SpendBudgetProvider>
                </div>
              }
            </Grid>
          </MainContentContainer>
        )}
      </CustomStepper>
    </Grid>
  )
}

export default SongAds
