import { Dialog, useMediaQuery, useTheme } from "@material-ui/core"
import { FunctionComponent, useState, useMemo, useEffect } from "react"
import { ModalContainer, useStyles } from "./style"
import RecordStreamsReview from "./Components/RecordStreamsReview"
import ChooseAnotherProjectView from "./Components/ChooseAnotherProjectView"
import { DuplicateSteps, getReviewInformation } from "./utils"
import SelectDuplicateOption from "./Components/SelectDuplicateOption"
import {
  CreatedCampaign,
  CurrentBrand,
  DuplicateSongAdsRecord as IncreaseMusicStreamsRecord,
  RecordSelectorType,
  Track,
} from "types/global"
import { CAMPAIGN_TYPES } from "../../../../../constants"
import DuplicateLinkClicksConfirmView from "./Components/LinkClicksConfirmView"
import {
  duplicateBoostInstagramCampaign,
  duplicateLinkClicksCampaign,
  duplicateSongAdsCampaign,
} from "./api"
import { useHistory } from "react-router-dom"
import { isValidURL } from "utils"
import { searchSpotifyReleases } from "../SuperboostModal/Components/shared-music-selector-utils"
import getBrandContent from "pages/post-auth/Website/controllers/getBrandContent"
import { getFormattedRecords } from "../../utils"
import { getRecordDetails } from "services/symphonyApi/brandContentService"

const {
  REVIEW,
  SELECT_DUPLICATE_OPTION,
  CHOOSE_ANOTHER_PROJECT,
  CHOOSE_ANOTHER_LINK,
} = DuplicateSteps

interface Props {
  open: boolean
  campaign: CreatedCampaign
  currentBrand: CurrentBrand
  duplicateIcon: string
  closeModal: () => void
}

const DuplicateCampaignModal: FunctionComponent<Props> = ({
  open,
  campaign,
  currentBrand,
  duplicateIcon,
  closeModal,
}: Props) => {
  const history = useHistory()
  const theme = useTheme()
  const classes = useStyles()
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701))
  const { campaign_metadata } = campaign
  const { campaign_type: campaignType, content: existingContent } = campaign_metadata as {
    campaign_type: string,
    content: Track
  }
  const [loadingDuplicateCampaign, setLoadingDuplicateCampaign] = useState(false)
  const [errorDuplicateCampaign, setErrorDuplicateCampaign] = useState(false)
  const [errorRecordDetails, setErrorRecordDetails] = useState(false)
  const [loadingRecordDetails, setLoadingRecordDetails] = useState(false)
  const [step, setStep] = useState<DuplicateSteps>(SELECT_DUPLICATE_OPTION)
  const [recordSelected, setRecordSelected] =
    useState<IncreaseMusicStreamsRecord | null>(null)

  const campaignId = campaign.id
  const isSpotifyConnected = !!currentBrand?.connections?.spotify_artist_page
  const {
    brandName,
    campaignName,
    genres,
    platforms,
    recordName,
    recordType,
    thumbnailUrl,
  } = useMemo(
    () => getReviewInformation(campaign_metadata, recordSelected),
    [campaignId, recordSelected?.spotify_id]
  )

  const handleChangeRecord = async (inputValue: string, slug: string | null) => {
    if (!slug) return

    let record = null
    let type: RecordSelectorType = 'single'
    try {
      setLoadingRecordDetails(true)
      if (isValidURL(inputValue)) {
        const response = await searchSpotifyReleases(inputValue)
        if (response?.data) {
          record = response.data

          switch (record.content_metadata.type) {
            case 'record':
              type = 'single'
              break
            case 'project':
              type = 'project'
              break
            case 'playlist':
              type = 'playlist'

              break
          }
        }
      } else if (currentBrand) {
        const brandContent = await getBrandContent(currentBrand?.slug!, slug)
        if (brandContent) {
          record = brandContent
          type = brandContent?.content_metadata?.type === 'record' ? 'single' : 'project'
        }
      }

      // if its a playlist, we dont need to save this in the DB
      // (that's what getRecordDetails does)
      // we just need to return the right format
      const [recordFormatted] = getFormattedRecords({
        recentReleases: [record],
        releaseType: type,
        search: ''
      })

      const recordDetail = await getRecordDetails(recordFormatted.spotify_id, type, currentBrand.id, );
      if (type === 'playlist') {
        try {
          const url = new URL(inputValue);
          const prid = url.searchParams.get('prid');

          if (prid && prid.startsWith('spotify:track:')) {
            const trackId = prid.split(':')[2];
            recordDetail.prid = trackId;
          }

        } catch (e) {
          console.error('Error parsing URL', e);
        }
      }

      setRecordSelected(recordDetail)
      setStep(REVIEW)
    } catch (e) {
      setErrorRecordDetails(true)
    } finally {
      setLoadingRecordDetails(false)

    }
  }

  const handleConfirmDuplicateSongAds = async () => {
    setLoadingDuplicateCampaign(true)

    // the server will automatically handle the case where the recordSelected is null
    // and duplicate the campaign with the same record
    const { data, error } = await duplicateSongAdsCampaign({
      campaign,
      recordSelected: recordSelected
    })

    if (data?.id && !error) {
      let url = `/marketing/new/music-streams?draft=${data.id}`
      if (campaign_metadata.campaign_type === CAMPAIGN_TYPES.grow_playlist_followers) {
        url = `/marketing/new/grow-playlist-followers?draft=${data.id}`
      }

      history.push(url)
      setLoadingDuplicateCampaign(false)
      closeModal()
    } else {
      setLoadingDuplicateCampaign(false)
      setErrorDuplicateCampaign(true)
    }
  }

  const handleDuplicateLinkClicksData = async (linkSelected?: string) => {
    setLoadingDuplicateCampaign(true)

    const { data, error } = await duplicateLinkClicksCampaign({ campaign, linkSelected })

    if (data?.id && !error) {
      const url = `/marketing/new/link-clicks?draft=${data.id}`
      history.push(url)
      setLoadingDuplicateCampaign(false)
      closeModal()
    } else {
      setLoadingDuplicateCampaign(false)
      setErrorDuplicateCampaign(true)
    }
  }

  const handleDuplicateBoostInstagramData = async (isSameProject?: boolean) => {
    const { data, error } = await duplicateBoostInstagramCampaign({ campaign, isSameProject })

    if (data?.id && !error) {
      const url = `/marketing/new/grow-instagram-engagement?draft=${data.id}`
      history.push(url)
      setLoadingDuplicateCampaign(false)
      closeModal()
    } else {
      setLoadingDuplicateCampaign(false)
      setErrorDuplicateCampaign(true)
    }
  }

  const handleClickDuplicateSame = () => {
    setErrorDuplicateCampaign(false)
    switch (campaignType) {
      case CAMPAIGN_TYPES.link_clicks:
        handleDuplicateLinkClicksData()
        break
      case CAMPAIGN_TYPES.record_streams:
      case CAMPAIGN_TYPES.grow_playlist_followers:
        setStep(REVIEW)
        break
      case CAMPAIGN_TYPES.boost_instagram:
        handleDuplicateBoostInstagramData(true)
        break
      default:
        setStep(SELECT_DUPLICATE_OPTION)
        break
    }
  }

  const handleClickDuplicateAnother = async () => {
    setErrorDuplicateCampaign(false)
    switch (campaignType) {
      case CAMPAIGN_TYPES.link_clicks:
        setStep(CHOOSE_ANOTHER_LINK)
        break
      case CAMPAIGN_TYPES.record_streams:
      case CAMPAIGN_TYPES.grow_playlist_followers:
        setStep(CHOOSE_ANOTHER_PROJECT)
        break
      case CAMPAIGN_TYPES.boost_instagram:
        handleDuplicateBoostInstagramData(false)
        break
      default:
        setStep(SELECT_DUPLICATE_OPTION)
        break
    }
  }

  const handleGoStep = (step: DuplicateSteps) => () => {
    setErrorDuplicateCampaign(false)
    setStep(step)
  }

  const handleGoBackAnotherProjectView = () => {
    setRecordSelected(null)
    setStep(SELECT_DUPLICATE_OPTION)
  }

  useEffect(() => {
    if (open) {
      setRecordSelected(null)
      setStep(SELECT_DUPLICATE_OPTION)
      setLoadingRecordDetails(false)
      setErrorRecordDetails(false)
      setErrorDuplicateCampaign(false)
      setLoadingDuplicateCampaign(false)
    }
  }, [open])

  return (
    <Dialog open={open} classes={{ root: classes.rootDialog }}>
      <ModalContainer
        width={setResponsiveView ? "auto" : "678px"}
        padding={setResponsiveView ? "32px 24px" : "32px"}
      >
        {step === SELECT_DUPLICATE_OPTION && (
          <SelectDuplicateOption
            error={errorDuplicateCampaign}
            loading={loadingDuplicateCampaign}
            campaignName={campaignName}
            recordType={recordType as string}
            closeModal={closeModal}
            handleDuplicateSame={handleClickDuplicateSame}
            handleDuplicateAnother={handleClickDuplicateAnother}
            duplicateAnotherIcon={duplicateIcon}
            campaignType={campaignType}
            thumbnailUrl={(campaignType === CAMPAIGN_TYPES.record_streams ||
              campaignType === CAMPAIGN_TYPES.grow_playlist_followers) ? thumbnailUrl : undefined}
          />
        )}
        {step === CHOOSE_ANOTHER_PROJECT && (
          <ChooseAnotherProjectView
            {...{
              currentBrand,
              closeModal,
              isSpotifyConnected,
              campaignType,
              loadingRecordDetails,
              errorRecordDetails
            }}
            handleChangeRecord={handleChangeRecord}
            handleGoBack={handleGoBackAnotherProjectView}
          />
        )}
        {step === REVIEW && (
          <RecordStreamsReview
            loading={loadingDuplicateCampaign}
            error={errorDuplicateCampaign}
            recordType={recordType as string}
            brandName={brandName as string}
            closeModal={closeModal}
            handleGoNext={handleConfirmDuplicateSongAds}
            recordName={recordName as string}
            genres={genres as string}
            handleGoBack={handleGoStep(
              recordSelected ? CHOOSE_ANOTHER_PROJECT : SELECT_DUPLICATE_OPTION
            )}
            platforms={platforms || []}
            thumbnailUrl={thumbnailUrl as string}
            mobile={setResponsiveView}
          />
        )}
        {step === CHOOSE_ANOTHER_LINK && (
          <DuplicateLinkClicksConfirmView
            loading={loadingDuplicateCampaign}
            error={errorDuplicateCampaign}
            closeModal={closeModal}
            confirmDuplicateLinkClicks={handleDuplicateLinkClicksData}
            handleGoBack={handleGoStep(SELECT_DUPLICATE_OPTION)}
          />
        )}
      </ModalContainer>
    </Dialog>
  )
}

export default DuplicateCampaignModal
