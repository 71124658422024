import { useMediaQuery, useTheme } from '@material-ui/core'
import { ReactComponent as CloseIcon } from "assets/images/close-black.svg"
import SpotifyIcon from "assets/images/logos/sidebar-collapsed.png"
import { Body2 } from 'components/shareable/Typography'
import times from "lodash/times"
import { FC } from 'react'
import { SystemColors } from 'types/globalStyles'
import Marquee from '../../../../../components/shareable/Marquee'
import TestimonialsCarousel from '../../../../../components/shareable/TestimonialsCarousel'
import useStyles, { CloseIconContainer, LeftContent, MarqueeItemContainer, MarqueesContainer, SpotifyIconContainer } from '../../styles'
import { TESTIMONIALS } from './utils'

interface SideContentProps {
    onClose: () => void
}

const list = times(8, () => (
    <MarqueeItemContainer>
      <SpotifyIconContainer>
        <img src={SpotifyIcon} width={16} height={16} />
      </SpotifyIconContainer>
      <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
        The easiest way to grow.
      </Body2>
    </MarqueeItemContainer>
  ))

const getMarqueeProps = (toRight: boolean) => ({
    list,
    marqueeContainerProps: {
      styles: {
        width: "600px",
        marginTop: toRight ? "0px" : "20px",
      },
    },
    toRight,
  })

export const CreatorSideContent: FC<SideContentProps> = ({onClose}) => {
    const theme = useTheme()
    const mobileView = useMediaQuery(theme.breakpoints.down(901))
    const classes = useStyles({ isMobileView: mobileView })

  const handleClickCloseIconButton = () => {
    if (onClose) onClose()
  }
  return (
    !mobileView ? <LeftContent
    height={mobileView ? "500px" : "700px"}
    width={mobileView ? "100%" : "40%"}
  >
    {mobileView && (
      <CloseIconContainer
        isMobileView={mobileView}
        onClick={handleClickCloseIconButton}
      >
        <CloseIcon className={classes.closeIcon} />
      </CloseIconContainer>
    )}
    <TestimonialsCarousel
      testimonials={TESTIMONIALS}
      slideHeight={mobileView ? "500px" : "100%"}
      slidePadding={
        mobileView ? "64px 24px 46px 24px" : "120px 48px 0px 48px"
      }
    />
    <MarqueesContainer isMobile={mobileView}>
      <Marquee {...getMarqueeProps(true)} />
      {!mobileView && <Marquee {...getMarqueeProps(false)} />}
    </MarqueesContainer>
  </LeftContent>
  : <></>
  )
}
