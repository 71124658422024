import { FunctionComponent, useContext } from "react"
import BoostIcon from "assets/images/campaignDetails/Boost.svg"
import { ReactComponent as CheckGreen } from "assets/images/check-black.svg"
import { ReactComponent as ClockIcon } from "assets/images/clock.svg"
import ExternalLinkIcon from "assets/images/external-link.svg"
import { Body2, Caption, Subtitle1, Subtitle2 } from "components/shareable/Typography"
import SecondaryButton from "components/shareable/SecondaryButton"
import { useMediaQuery, useTheme } from "@material-ui/core"
import {
  Container,
  ItemContainer,
  StyledImage,
  RowItem,
  StatusCircle,
  ButtonContainer,
  DummyDiv,
  useStyles,
} from "./styles"
import Intercom from "helpers/Intercom"
import QuestionMarkIcon from "assets/images/question-mark.svg";
import { SystemColors } from "types/globalStyles"
import { ConnectionsContext } from "pages/post-auth/MarketingPage/hooks/ConnectionsContext"

const MOBILE_BUTTON_LABEL = "Open Facebook"
const BUTTON_LABEL = "Open Facebook to Accept"

type Data =
  | {
    action?: string | null
    name?: string | null
    status?: boolean | null
  }
  | null
  | undefined

interface Props {
  timeStarted: boolean
  verifyingTime: number
  onStartTimer: (type: string) => void
}

interface StatusIconProps {
  verified: boolean
}

const StatusIcon: FunctionComponent<StatusIconProps> = ({
  verified,
}: StatusIconProps) => {
  const classes = useStyles()

  return (
    <StatusCircle {...{ verified }}>
      {verified ? <CheckGreen className={classes.svgSuccess} /> : <ClockIcon />}
    </StatusCircle>
  )
}

const formatRowSubtitle = (termsName?: string | null) =>
  `Click the button to accept the ${termsName} on Facebook.`

const AcceptFBTermsOfServiceCard: FunctionComponent<Props> = ({
  timeStarted,
  verifyingTime,
  onStartTimer,
}: Props) => {
  const theme = useTheme()
  const { preConnectionConfigurations: configurations } = useContext(ConnectionsContext)
  const isMobile = useMediaQuery(theme.breakpoints.down(601))
  const showButtonIcon = useMediaQuery(theme.breakpoints.up(361))
  const { custom_audience_accepted, value_based_custom_audience_tos, user_tos_accepted } =
    configurations || {}
  const {
    action: customAudienceUrl,
    name: customAudienceTermsName,
    status: customAudienceStatus,
  } = custom_audience_accepted || {}
  const {
    action: valueBasedAudienceUrl,
    name: valueBasedAudienceTermsName,
    status: valueBasedAudienceStatus,
  } = value_based_custom_audience_tos || {}
  const {
    action: userTosUrl,
    name: userTosTermsName,
    status: userTosStatus,
  } = user_tos_accepted || {}

  const verifiedCustomAudience = Boolean(customAudienceStatus)
  const verifiedValueBasedAudience = Boolean(valueBasedAudienceStatus)
  const verifiedUserTos = Boolean(userTosStatus)
  const rowSubtitleCustomAudience = formatRowSubtitle(customAudienceTermsName)
  const rowSubtitleValueBasedAudience = formatRowSubtitle(valueBasedAudienceTermsName)
  const rowSubtitleUserTos = formatRowSubtitle(userTosTermsName)
  const buttonLabel = showButtonIcon ? BUTTON_LABEL : MOBILE_BUTTON_LABEL

  const handleClickGoToCustomAudience = () => {
    if (customAudienceUrl) {
      window.open(customAudienceUrl, "_blank", 'noreferrer')
      onStartTimer("Custom")
    }
  }

  const handleClickGoToValueBasedAudience = () => {
    if (valueBasedAudienceUrl) {
      window.open(valueBasedAudienceUrl, "_blank", 'noreferrer')
      onStartTimer("ValueBased")
    }
  }

  const handleClickGoToUserTos = () => {
    if (userTosUrl) {
      window.open(userTosUrl, "_blank", 'noreferrer')
      onStartTimer("UserTos")
    }
  }

  const handleClickQuestionMarkButton = () => {
    Intercom.openAcceptFBTermsOfService()
  }

  return (
    <Container>
      <RowItem alignItems="center" justifyContent="space-between">
        <ItemContainer>
          <StyledImage alt="boost-icon.svg" src={BoostIcon} />
          <RowItem flexDirection="column" alignItems="flex-start" justifyContent="center">

            <Subtitle1>One last step to maximize fanbase growth!</Subtitle1>
            <Subtitle2 color={SystemColors.SECONDARY_TEXT_COLOR}>Accept Facebook's ads targeting Terms of Service to improve your ads performance with Symphony.</Subtitle2>
          </RowItem>
        </ItemContainer>

        {/* <GrayCircle onClick={handleClickQuestionMarkButton}>
          {QUESTION_MARK}
        </GrayCircle> */}
        <button className="ml-1.5 w-6 h-6"
          onClick={handleClickQuestionMarkButton}>

          <img
            className="w-6 h-6"
            alt="question-mark-icon.svg"
            src={QuestionMarkIcon}
          />
        </button>
      </RowItem>
      {timeStarted && (
        <RowItem>
          <ItemContainer>
            <DummyDiv />
            <Caption>
              {verifyingTime < 0
                ? "Verifying..."
                : `Verifying in ${String(verifyingTime)}`}
            </Caption>
          </ItemContainer>
        </RowItem>
      )}
      {!customAudienceStatus && (
        <RowItem flexDirection={isMobile ? "column" : "row"}>
          <ItemContainer>
            <StatusIcon verified={verifiedCustomAudience} />
            <Body2>{rowSubtitleCustomAudience}</Body2>
            {!isMobile && (
              <ButtonContainer>
                <SecondaryButton
                  gap="8px"
                  width="274px"
                  text={buttonLabel}
                  onClick={handleClickGoToCustomAudience}
                  rightComponent={
                    <StyledImage
                      alt="external-link.svg"
                      src={ExternalLinkIcon}
                    />
                  }
                />
              </ButtonContainer>
            )}
          </ItemContainer>
          {isMobile && (
            <ItemContainer marginTop="16px">
              <DummyDiv />
              <SecondaryButton
                gap="8px"
                width="100%"
                text={buttonLabel}
                onClick={handleClickGoToCustomAudience}
                rightComponent={
                  showButtonIcon ? (
                    <StyledImage
                      alt="external-link.svg"
                      src={ExternalLinkIcon}
                    />
                  ) : null
                }
              />
            </ItemContainer>
          )}
        </RowItem>
      )}
      {!valueBasedAudienceStatus && (
        <RowItem flexDirection={isMobile ? "column" : "row"}>
          <ItemContainer>
            <StatusIcon verified={verifiedValueBasedAudience} />
            <Body2>{rowSubtitleValueBasedAudience}</Body2>
            {!isMobile && (
              <ButtonContainer>
                <SecondaryButton
                  gap="8px"
                  width="274px"
                  text={buttonLabel}
                  onClick={handleClickGoToValueBasedAudience}
                  rightComponent={
                    <StyledImage
                      alt="external-link.svg"
                      src={ExternalLinkIcon}
                    />
                  }
                />
              </ButtonContainer>
            )}
          </ItemContainer>
          {isMobile && (
            <ItemContainer marginTop="16px">
              <DummyDiv />
              <SecondaryButton
                gap="8px"
                width="100%"
                text={buttonLabel}
                onClick={handleClickGoToValueBasedAudience}
                rightComponent={
                  showButtonIcon ? (
                    <StyledImage
                      alt="external-link.svg"
                      src={ExternalLinkIcon}
                    />
                  ) : null
                }
              />
            </ItemContainer>
          )}
        </RowItem>
      )}
      {!userTosStatus && (
        <RowItem flexDirection={isMobile ? "column" : "row"}>
          <ItemContainer>
            <StatusIcon verified={verifiedUserTos}/>
            <Body2>{rowSubtitleUserTos}</Body2>
            {!isMobile && (
              <ButtonContainer>
                <SecondaryButton
                  gap="8px"
                  width="274px"
                  text={buttonLabel}
                  onClick={handleClickGoToUserTos}
                  rightComponent={
                    <StyledImage
                      alt="external-link.svg"
                      src={ExternalLinkIcon}
                    />
                  }
                />
              </ButtonContainer>
            )}
          </ItemContainer>
          {isMobile && (
            <ItemContainer marginTop="16px">
              <DummyDiv />
              <SecondaryButton
                gap="8px"
                width="100%"
                text={buttonLabel}
                onClick={handleClickGoToUserTos}
                rightComponent={
                  showButtonIcon ? (
                    <StyledImage
                      alt="external-link.svg"
                      src={ExternalLinkIcon}
                    />
                  ) : null
                }
              />
            </ItemContainer>
          )}
        </RowItem>
      )}
    </Container>
  )
}

export default AcceptFBTermsOfServiceCard
