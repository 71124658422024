import { Container, MainContainer } from "styles/shared";
import {
  OverallChartContainer,
  ChartHeader,
  LeftSideHeader,
  RightSideHeader,
  StatsCardsContainer,
  LowerSection,
  DateButton,
} from "./styles";
import { Body1, Headline2 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import { useContext, useEffect, useState } from "react";
import { StatCard } from 'components/shareable/StatCard';
import { getWebsiteOverallData } from "services/symphonyApi/brandService";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import dayjs from "dayjs";
import { LoadingLottie } from "components/Loader/LoadingLottie";
import { SitesList } from './components/SitesList';
import { contrastLinkImgMapping, streamingLinkNamesToDbKeysMapping } from "helpers/StreamingMappings";
import { TopGeographiesBarChart } from './components/CountriesBarChart';
import { SymphonyLineChart } from "components/shareable/DATA_PRESENTATION/Charts/LineChart";
import { checkIfIsProTier } from "utils";
import MenuProOption from "components/shareable/MenuProOption";
import UnlockDataBanner from "components/shareable/UnlockDataBanner";
import UnlockChartImage from "assets/images/unlock-data-chart.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core";
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";

type ChartOption = {
  label: string;
  value: string;
  selected: boolean;
  proOption: boolean;
}

const ChartOptions: ChartOption[] = [
  {
    label: "7 days",
    value: "7-days",
    selected: true,
    proOption: false,
  },
  {
    label: "30 days",
    value: "30-days",
    selected: false,
    proOption: true,
  },
  {
    label: "3 months",
    value: "3-months",
    selected: false,
    proOption: true,
  },
  {
    label: "All Time",
    value: "all",
    selected: false,
    proOption: true,
  },
];

type Metrics = {
  topLevelMetrics: {
    pageVisits: number,
    streamingLinkClicks: number,
    ctr: number
  },
  referrers: [],
  overallActivity: [],
  geographicBreakdown: {
    countries: [],
    cities: []
  },
  contentBreakdown: [],
  streamingServicesBreakdown: []
}


export function OverallWebsiteDashboard() {
  const { mobileView } = useMediaBreakpoints();

  const { currentBrand } = useContext(CurrentBrandContext);
  const theme = useTheme();
  const hideBannerImage = useMediaQuery(theme.breakpoints.down(601));
  const isProUser = checkIfIsProTier(currentBrand);

  const [chartOptions, setChartOptions] = useState(ChartOptions)
  const [loading, setLoading] = useState(false);
  const [emptyView, setEmptyView] = useState(false);
  const [mainMetrics, setMainMetrics] = useState<Metrics>()

  const getMainMetrics = async () => {
    setEmptyView(false)
    setLoading(true);
    if (!currentBrand) return

    const selectedCharOption = chartOptions.find(option => option.selected);
    let startDate = undefined;
    let endDate = undefined;
    switch (selectedCharOption?.value) {
      case '7-days':
        endDate = dayjs().startOf('day').format('YYYY-MM-DD')
        startDate = dayjs().subtract(7, 'days').startOf('day').format('YYYY-MM-DD')
        break
      case '30-days':
        endDate = dayjs().startOf('day').format('YYYY-MM-DD')
        startDate = dayjs().subtract(30, 'days').startOf('day').format('YYYY-MM-DD')
        break
      case '3-months':
        endDate = dayjs().startOf('day').format('YYYY-MM-DD')
        startDate = dayjs().subtract(3, 'months').startOf('day').format('YYYY-MM-DD')
        break
    }

    const mainMetrics = await getWebsiteOverallData(currentBrand.slug!, startDate, endDate);

    if (mainMetrics?.data) {
      setMainMetrics(mainMetrics.data)
    }
    else {
      setEmptyView(true)
    }
    setLoading(false);
  }

  useEffect(() => {
    getMainMetrics();
  }, [chartOptions])

  const getChartButtons = () => {
    return chartOptions.map((option, index) => {
      return (
        <DateButton
          key={`chartOption-${index}`}
          onClick={() => handleChartOptionClick(index)}
          selected={option.selected}
          width="fit-content"
        >
          {option.proOption
            ? <MenuProOption
              {...{ isProUser }}
              mainContainerClassName="flex items-center gap-2 w-full"
              labelClassName={!isProUser ? "w-5/12" : ""}
              label={option.label}
              selected={option.selected}
            />
            : option.label
          }
        </DateButton>
      )
    })
  }

  function handleChartOptionClick(index: number) {
    setLoading(true);
    setChartOptions(chartOptions.map((option, i) => {
      return {
        ...option,
        selected: i === index
      }
    }))
    setLoading(false);
  }

  const formatDataToSitesList = (data: { count: number, image: string, name: string, slug: string }[]) => {
    const dataFormatted = data.map((content) => ({
      title: content.name,
      image_url: content.image,
      value: content.count,
      subValue: 'Clicks',
    }))
    return dataFormatted.sort((a, b) => b.value! - a.value!)
  }

  const formatDataToStreamingServicesList = (data: { count: number, percent: number, service: string }[]) => {
    const dataFormatted = data.map((content) => {
      const imageIdentifier = (streamingLinkNamesToDbKeysMapping as any)[content.service]
      return {
        title: content.service,
        value: content.count,
        subValue: `${(content.percent * 100).toFixed(1)}%`,
        image_url: contrastLinkImgMapping(null, 'light', imageIdentifier)?.default || '',
      }
    })
    return dataFormatted.sort((a, b) => b.value! - a.value!)
  }

  const formatDataToReferrersList = (data: { count: number, referrer?: string }[]) => {
    const dataFormatted = data.map((content) => ({
      title: content.referrer ? content.referrer : "Direct",
      value: content.count,
    }))

    let userAdClicks = 0;

    for (const data of dataFormatted) {
      if (data.title.includes('fbclid=')) {
        userAdClicks = userAdClicks + data.value;
      }
    }

    if (userAdClicks > 0) {
      dataFormatted.push({
        title: "FB/Instagram Ads",
        value: userAdClicks,
      })
    }

    return dataFormatted.sort((a, b) => b.value! - a.value!)
  }

  const hasAccessToOption = (): boolean => {
    return Boolean(!isProUser && chartOptions.find(option => option.selected)?.proOption)
  }

  if (emptyView) {
    return (
      <MainContainer padding="8px 0px 8px 0px" backgroundColor={SystemColors.PAPER_CONTAINER} justifyContent="center" borderRadius="8px">
        <Container
          display="flex"
          padding="24px"
          gap="16px"
          width="100%"
          flexDirection="column"
        >
          <Container
            display={mobileView ? "ruby" : "flex"}
            justifyContent="flex-end"
          >
            {getChartButtons()}
          </Container>
          <div style={{ width: '100%' }}>
            <UnlockDataBanner
              title="Unlock more data"
              description="Get more insights with Symphony Pro"
              image={UnlockChartImage}
              hideImage={hideBannerImage}
            />
          </div>
        </Container>
      </MainContainer>
    )
  }

  if (!mainMetrics || loading) {
    return (
      <MainContainer padding="8px 0px 8px 0px" backgroundColor={SystemColors.PAPER_CONTAINER} justifyContent="center" borderRadius="8px">
        <LoadingLottie height={36} width={36} black />
      </MainContainer>
    )
  }

  const chartDatasets = [
    { key: "pageViews", name: "Page Views", color: "#8884d8", showIfPositive: false },
    { key: "streamingLinkClicks", name: "Streaming Link Clicks", color: "#42c221", showIfPositive: false },
    { key: "signups", name: "Signups", color: "#ff7300", showIfPositive: true },
    { key: "tourLinkClicks", name: "Tour Link Clicks", color: "#ffa500", showIfPositive: true }
  ];

  return (
    <MainContainer gap="16px" flexDirection="column">
      <OverallChartContainer>
        <ChartHeader flexDirection={mobileView ? 'column' : 'row'}>
          <LeftSideHeader>
            <Headline2 color={SystemColors.PRIMARY_TEXT_COLOR}>
              Overall Activity
            </Headline2>
            <Body1>
              This is the performance across all your website pages.
            </Body1>
          </LeftSideHeader>
          <RightSideHeader
            overflow={mobileView ? 'scroll' : undefined}
            maxWidth={mobileView ? '100%' : undefined}
            display={mobileView ? "ruby" : "flex"}
          >{getChartButtons()}</RightSideHeader>
        </ChartHeader>
        <SymphonyLineChart
          data={mainMetrics.overallActivity}
          datasets={chartDatasets}
          xAxisKey="date"
        />
      </OverallChartContainer>
      <StatsCardsContainer flexDirection={mobileView ? 'column' : 'row'}>
        <StatCard
          title="Page Visits"
          statValue={mainMetrics.topLevelMetrics.pageVisits}
        />
        <StatCard
          title="Streaming Link Clicks"
          statValue={mainMetrics.topLevelMetrics.streamingLinkClicks}
        />
        <StatCard
          title="Clickthrough Rate"
          statValue={`${(mainMetrics.topLevelMetrics.ctr * 100).toFixed(1)}%`}
        />
      </StatsCardsContainer>
      <LowerSection flexDirection={mobileView ? 'column' : 'row'}>
        <SitesList
          title="Top Sites"
          items={formatDataToSitesList(mainMetrics.contentBreakdown)}
          numbered
        />
        <SitesList
          title="Top Streaming Services"
          items={formatDataToStreamingServicesList(
            mainMetrics.streamingServicesBreakdown
          )}
          numbered
        />
      </LowerSection>
      <LowerSection>
        <SitesList
          title="Top Referrers"
          items={formatDataToReferrersList(mainMetrics.referrers)}
          numbered
        />
      </LowerSection>
      <OverallChartContainer>
        <LeftSideHeader>
          <Headline2 color={SystemColors.PRIMARY_TEXT_COLOR}>
            Top Countries by Visits
          </Headline2>
          <Body1>The top countries your fans are clicking from.</Body1>
        </LeftSideHeader>
        <TopGeographiesBarChart
          metrics={mainMetrics.geographicBreakdown}
          areaType="countries"
        />
      </OverallChartContainer>
      <OverallChartContainer>
        <LeftSideHeader>
          <Headline2 color={SystemColors.PRIMARY_TEXT_COLOR}>
            Top Cities by Visits
          </Headline2>
          <Body1>The top cities your fans are clicking from.</Body1>
        </LeftSideHeader>
        <TopGeographiesBarChart
          metrics={mainMetrics.geographicBreakdown}
          areaType="cities"
        />
      </OverallChartContainer>
    </MainContainer>
  );
}
