
import { StyledLabel, Container } from 'styles/shared';
import { MenuItem, Select, TextField, Checkbox, ListItemText, useMediaQuery, useTheme } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import Loaderv2 from "components/Loader/v2";
import dayjs from "dayjs";
import Axios from "helpers/Interceptor";
import { useEffect, useState } from "react";
import PrimaryButton from 'components/shareable/PrimaryButton';
import { DataGridPro, GridColDef, GRID_TREE_DATA_GROUPING_FIELD, GridCellParams } from '@mui/x-data-grid-pro';
import useStyles from './styles';


interface SourceItemStat {
    columns: string[];
    rows: RowItem[];
    sources: string[];
}

const defaultColDef: GridColDef = {
    field: "field",
    headerName: "column",
    type: "string",
    sortable: true,
    editable: false,
    disableColumnMenu: true,
    align: "center",
    headerAlign: "center",
    minWidth: 120,
    maxWidth: 120,
    flex: 1,
  }

const colGroupDef : GridColDef = {
    ...defaultColDef,
    field: "source",
    maxWidth: 320,
    minWidth: 320,
    headerName: "Source",
    headerAlign: "left",
}

interface RowItem {
    id: string;
    path: string[];
    [key: string]: number | string | string[];
} 

export const SourcesTable = () => {
    const [loading, setLoading] = useState(true);
    const [source, setSource] = useState<string[]>([]);
    const [startDate, setStartDate] = useState<string>(dayjs().subtract(5, 'day').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
    const [period, setPeriod] = useState<string>('day');
    const [availableSources, setAvailableSources] = useState<string[]>([]);
    const [cols, setCols] = useState<GridColDef[]>([]);
    const [type, setType] = useState<string>('signups');
    const [rows, setRows] = useState<RowItem[]>([]);
    const classes = useStyles();
    const theme = useTheme();

    const fetchData = async () => {
        setLoading(true);
        try {
            const uri = '/admin/funnel/get-tiers';
            const response = await Axios.get(uri, {
                params: {
                    startDate, endDate, period, type, sources: source.join(',')
                }
            });
            const data: SourceItemStat = response.data.data;
            const cols = data.columns.map((col, index) => ({
                ...defaultColDef,
                field: `${col}`,
                headerName: col,
            } as GridColDef));
            setCols(cols);
            setRows(data.rows);
            setAvailableSources(data.sources);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() =>{
        fetchData();
    },[])
    


    const handleFilterSources = () => {
        fetchData();
    };

    const handleSourceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSource(event.target.value as string[]);
    };


    return (
        <Container flexDirection="column" alignItems="center" justifyContent="center" padding="20px" gap="20px">
            {loading ? (
                <Loaderv2 />
            ) : (
                <>
                    <Container flexDirection="column" backgroundColor="#fff" padding="20px" borderRadius="8px" display='flex'>
                        <StyledLabel fontSize={20} padding="0px auto 20px">Table Tracker</StyledLabel>
                        <Container flexDirection="row" gap="12px" alignItems="center" display='flex'>
                            <TextField
                                label="Start Date"
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                label="End Date"
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                            <Select
                                value={period}
                                onChange={(e) => setPeriod(e.target.value as string)}
                                IconComponent={ArrowDropDown}
                            >
                                <MenuItem value="day">Day</MenuItem>
                                <MenuItem value="week">Week</MenuItem>
                                <MenuItem value="month">Month</MenuItem>
                            </Select>
                            <Select
                                multiple
                                value={source}
                                onChange={handleSourceChange}
                                renderValue={(selected) => (selected as string[]).join(', ')}
                                IconComponent={ArrowDropDown}
                            >
                                <MenuItem value="" disabled>All Sources</MenuItem>
                                {availableSources.map((src, index) => (
                                    <MenuItem key={index} value={src}>
                                        <Checkbox checked={source.indexOf(src) > -1} />
                                        <ListItemText primary={src} />
                                    </MenuItem>
                                ))}
                            </Select>
                            <Select
                                value={type}
                                onChange={(e) => setType(e.target.value as string)}
                                IconComponent={ArrowDropDown}
                            >
                                <MenuItem value="signups">Signups</MenuItem>
                                <MenuItem value="lastTouch">Last Touch</MenuItem>
                                <MenuItem value="trials">Trials</MenuItem>
                            </Select>
                            <PrimaryButton text="Filter" loading={loading} onClick={handleFilterSources} />
                        </Container>

                        {cols && rows && <Container flexDirection="row" gap="12px" padding='10px' marginTop='20px' alignItems="center" display='flex'>
                            <DataGridPro 
                                treeData 
                                getTreeDataPath={(row) => row.path} 
                                rows={rows} 
                                columns={cols} 
                                rowHeight={58}
                                autoHeight
                                getCellClassName={(params: GridCellParams<RowItem, any, number>) => {
                                    switch (params.row.path.length) {
                                        case 2:
                                            return 'bg-blue-100';
                                        case 3:
                                            return 'bg-blue-200';
                                        default:
                                            return '';
                                    }
                                  }}
                                groupingColDef={{...colGroupDef, hideDescendantCount: true}} 
                                pinnedColumns={{ left: [GRID_TREE_DATA_GROUPING_FIELD] }} 
                                pinnedRows={{ bottom:[rows[rows.length - 1]] }}
                                classes={{ root: classes.datagridRoot }}
                            />
                        </Container>}
                    </Container>
                </>
            )}
        </Container>
    );

}