import { FunctionComponent } from "react";
import { TextButton } from "styles/shared";
import { FooterContainer } from "./styles";
import { Body2, ButtonText } from "components/shareable/Typography";
import { ReactComponent as ExternalLinkIcon } from "assets/images/external-link.svg";

interface Props {
  footerText?: string;
  footerButton?: string;
  footerButtonOnClick?: () => void;
}

export const FooterModal: FunctionComponent<Props> = ({
  footerText,
  footerButton,
  footerButtonOnClick,
}: Props) => {

  return (
    <FooterContainer>
      {footerText && <Body2>{footerText}</Body2>}
      {footerButton && footerButtonOnClick && (
        <div className="mx-w-5">
          <TextButton>
            <div
              className="flex items-center"
              onClick={footerButtonOnClick}
            >
              <ButtonText>{footerButton}</ButtonText>
              <ExternalLinkIcon />
            </div>
          </TextButton>
        </div>
      )}
    </FooterContainer>
  );
};