import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

interface ModalContainerProps {
  className?: string
}

export const useStyles = makeStyles({
  confetti: {
    position: 'fixed !important' as 'fixed',
  },
});

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ModalContainer = styled.div<ModalContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-x: hidden;
`;