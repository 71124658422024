import { FunctionComponent, useEffect, useState } from "react"
import PrimaryButton from "components/shareable/PrimaryButton"
import { ReactComponent as UserIcon } from "assets/images/user.svg"
import { SystemColors } from "types/globalStyles"
import DialogPaper from "components/shareable/DialogPaper"
import HeaderModal from "components/connect/connectModals/Spotify/Components/HeaderModal"
import { makeStyles } from "@material-ui/core"
import { Theme } from "@mui/material"
import styled from "styled-components"
import { Container, TextButton } from "styles/shared"
import { TEAM_PLAN_INCLUDED_PRO_ACCOUNTS } from "../../../../constants"

interface Props {
    open: boolean
    onClose: () => void
    onConfirm: () => void
    type?: 'upgrade' | 'downgrade'
    proAccounts: number
    brandName: string
    buttonLoading?: boolean
    isTeamAdmin?: boolean
}

const useStyles = makeStyles((_theme: Theme) => ({
    userIcon: {
        "& path": {
            stroke: SystemColors.ACCENT_COLOR,
        },
    },
}))

interface AlertContainerProps {
    type?: string
}

export const AlertContainer = styled.div<AlertContainerProps>`
    padding: 16px;
    background-color: ${({ type }) => {
        if (type === 'success') return `${SystemColors.SUCCESS}18`
        return `${SystemColors.PAPER_WARNING_CONTAINER}`
    }};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
`

export const InfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
`

export const ActionsContainer = styled.div`
    align-self: flex-end;
    gap: 16px;
    display: flex;
    margin-top: 16px;
`

const ConfirmChangeSubscriptionModal: FunctionComponent<Props> = ({
    open,
    type,
    onClose,
    onConfirm,
    proAccounts,
    brandName,
    buttonLoading,
    isTeamAdmin,
}: Props) => {
    const classes = useStyles()
    const [subtitle, setSubtitle] = useState<string>('')

    useEffect(() => {
        if (isTeamAdmin) {
            if (type === 'downgrade') {
                if (proAccounts <= TEAM_PLAN_INCLUDED_PRO_ACCOUNTS) {
                    setSubtitle(`This profile won't be able to create campaigns, view advanced analytics, or take Pro-only actions once downgraded. You'll have ${TEAM_PLAN_INCLUDED_PRO_ACCOUNTS - (proAccounts - 1)} Pro account${TEAM_PLAN_INCLUDED_PRO_ACCOUNTS - (proAccounts - 1) !== 1 ? 's' : ''} available.`)
                } else {
                    setSubtitle(`This profile won't be able to create campaigns or take Pro-only actions once downgraded. Your monthly bill will be reduced by $15.`)
                    // 'You can downgrade to Free for this profile at a discounted rate of $0 / month.'
                }
            } else if (proAccounts >= TEAM_PLAN_INCLUDED_PRO_ACCOUNTS) {
                setSubtitle(`Once upgraded, this profile will be able to create campaigns, view advanced analytics, and take Pro-only actions. Since you've used all ${TEAM_PLAN_INCLUDED_PRO_ACCOUNTS} team Pro account slots, you'll be charged $15/month for this profile.`)
            } else {
                setSubtitle(`Once upgraded, this profile will be able to create campaigns, view advanced analytics, and take Pro-only actions. You'll have ${TEAM_PLAN_INCLUDED_PRO_ACCOUNTS - (proAccounts + 1)} Pro account${TEAM_PLAN_INCLUDED_PRO_ACCOUNTS - (proAccounts + 1) !== 1 ? 's' : ''} remaining.`)
            }
        } else {
            setSubtitle(`Please contact your Team Admin to upgrade to Pro and unlock this functionality.`)
        }
    }, [brandName, proAccounts, type, isTeamAdmin])

    let title = 'Upgrade to Pro'
    let buttonCta = 'Confirm Upgrade'
    if (type === 'downgrade') {
        title = 'Downgrade to Free'
        buttonCta = 'Confirm Downgrade'
    }

    return (
        <DialogPaper {...{ open }} onClose={onClose}>
            <>
                <HeaderModal
                    HeaderIcon={
                        <UserIcon width={32} height={32} className={classes.userIcon} />
                    }
                    title={title}
                    subtitle={subtitle}
                    closeModal={onClose}
                />
                <Container
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    gap="12px"
                >
                    {isTeamAdmin ?
                        <>
                            <TextButton
                                disabled={buttonLoading}
                                onClick={onClose}
                            >
                                Cancel
                            </TextButton>
                            <PrimaryButton
                                loading={buttonLoading}
                                disabled={buttonLoading}
                                width="159px"
                                text={buttonCta}
                                onClick={onConfirm}
                            />
                        </>
                        :
                        <PrimaryButton
                            loading={buttonLoading}
                            disabled={buttonLoading}
                            width="100px"
                            text="Got it"
                            onClick={onClose}
                        />
                    }
                </Container>
            </>
        </DialogPaper>
    )
}

export default ConfirmChangeSubscriptionModal
