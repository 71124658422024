import { Body2, Caption, Subtitle2 } from "components/shareable/Typography"
import { SystemColors } from "types/globalStyles"
import QuestionMarkIcon from "assets/images/question-mark.svg"
import { Container } from "styles/shared"
import { 
  Feature, 
  FeatureIcon, 
  FeatureList, 
  StyledImg, 
  TextContainer 
} from "../styles"
import { getFeatures as getCreatorFeatures } from "./creators/utils"
import { getFeatures as getTeamFeatures } from "./teams/utils"

interface FeatureListSectionProps {
  classes: any
  mobileView?: boolean
  handleClickQuestionMarkIcon: () => void
  getCaption: (feature: any) => string
  containerStyle?: React.CSSProperties
  isTeam?: boolean
}

const FeatureListSection = ({
  classes,
  mobileView = false,
  handleClickQuestionMarkIcon,
  getCaption,
  containerStyle,
  isTeam = false
}: FeatureListSectionProps) => {
  return (
    <Container
      marginTop="8px"
      display="flex" 
      flexDirection="column" 
      gap={mobileView ? "8px" : "8px"}
      style={containerStyle}
    >
      <Container
        display="flex" 
        alignItems="center" 
        justifyContent="space-between"
      >
        <Subtitle2>What's included?</Subtitle2>
        <StyledImg
          src={QuestionMarkIcon}
          onClick={handleClickQuestionMarkIcon}
          alt="question-mark-icon.svg"
        />
      </Container>
      <FeatureList marginTop={"0px"}>
        {(isTeam ? getTeamFeatures : getCreatorFeatures)(classes).map(
          ({ icon: Icon, feature, caption, className, featureId }, index) => (
            <Feature key={`upgrade-to-pro-modal-feature-${index}`}>
              <FeatureIcon>
                <Icon {...{ className }} />
              </FeatureIcon>
              <TextContainer>
                <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                  {feature}
                </Body2>
                {caption && (
                  <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                    {getCaption({ icon: Icon, feature, caption, className, featureId })}
                  </Caption>
                )}
              </TextContainer>
            </Feature>
          )
        )}
      </FeatureList>
    </Container>
  )
}

export default FeatureListSection