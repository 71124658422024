import { InputAdornment, TextField } from "@material-ui/core";
import { ReactComponent as InstagramLogo } from "assets/images/instagram-bordered-icon.svg";
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json";
import { ReactComponent as SpotifyIcon } from "assets/images/streaming/icons/spotify-i.svg";
import { ReactComponent as YoutubeLogo } from "assets/images/streaming/icons/youtube-logo.svg";
import { ReactComponent as TiktokLogo } from "assets/images/tiktok-black.svg";
import debounce from "lodash.debounce";
import { ChangeEvent, useMemo, useState } from "react";
import Lottie from "react-lottie";
import { SystemColors } from "types/globalStyles";
import {
  createMinimalBrandService,
  searchArtistSpotifyService,
} from "../api";
import { SpotifyArtistSearch } from "../interfaces";
import {
  AnotherSocialPlatformContainer,
  ArtistInputContainer,
  CreateAccountWithoutSpotifyText,
  EnterArtistContainer,
  EnterArtistLabel,
  PlatformButtonInstagramContainer,
  PlatformButtonTikTokContainer,
  PlatformButtonYoutubeContainer,
  PlatformLogoContainer,
  PlatformsButtonsContainer,
  useStyles,
} from "../styles";
import { RowProfileItem } from "./RowProfileItem";
import { checkIfSpotifyArtistExistsForUser } from "services/symphonyApi/brandService";
import { getArtistBySpotifyIdService } from "services/symphonyApi/spotifyService";
import { useHistory } from "react-router-dom";
import { addBrandToOrganization } from "services/symphonyApi/organizationService";

export interface ArtistListProps {
  spotifyArtistList: SpotifyArtistSearch[];
  keyword: string;
  onSelectArtist: (artist: SpotifyArtistSearch, isExistingProfile?: boolean) => void;
}

function ArtistList({
  spotifyArtistList,
  keyword,
  onSelectArtist,
}: ArtistListProps) {


  return (
    <>
      {spotifyArtistList?.length > 0 ?
        <ul className="add-new border-b mb-5 max-h-80 overflow-auto">
          {spotifyArtistList.map((item, index) => {
            switch (item?.status) {
              case "new_profile":
                return (
                  <RowProfileItem
                    key={`artist_${item.id}_${index}`}
                    className="newProfileArtistItem"
                    artist={item}
                    actionLabel="Connect"
                    handleArtist={onSelectArtist}
                  />
                );
              case "existing_profile":
                return (
                  <RowProfileItem
                    key={`artist_${item.id}_${index}`}
                    className="existingProfileArtistItem"
                    artist={item}
                    actionLabel="Add to Team"
                    handleArtist={(artist) => onSelectArtist(artist, true)}
                    footerLabel={`You already have access to this profile, do you want to add ${item.name} to your Team account?`}
                  />
                );
              default:
                return (
                  <RowProfileItem
                    key={`artist_${item.id}_${index}`}
                    className="teamProfileArtistItem"
                    artist={item}
                    actionLabel="Already in Team"
                    handleArtist={() => { }}
                    footerLabel={`You already have access to ${item.name} in your Team account`}
                  />
                );
            }
          })}
        </ul>
        :
        <ul className="noArtistsFoundContainer add-new border-b mb-5 max-h-80 overflow-auto">
          <li className="text-center mt-3 pb-3 border-b">
            No artists found for "<span className="font-semibold">{keyword}</span>".
          </li>
        </ul>
      }
    </>
  );
}

export default function NewProfile({ onFinishAddingToOrganization }: { onFinishAddingToOrganization: () => void }) {
  const basicTextOptions = {
    sectionTitle: "Enter your artist name or Spotify URL",
  };

  const [error, setError] = useState<string | null>(null);
  const [searchKeyword, setSearchKeyword] = useState("");

  const classes = useStyles();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [spotifyResults, setSpotifyResults] = useState<SpotifyArtistSearch[]>(
    []
  );

  const history = useHistory()

  const handlerSearchArtist = async (keyword: string) => {
    try {
      if (!keyword) return;
      setLoadingSearch(true);
      const arrArtists = await searchArtistSpotifyService(keyword);
      setSpotifyResults(arrArtists);
    } catch (error: any) {
      setError(error?.message || "");
    } finally {
      setLoadingSearch(false);
    }
  };

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(async (value: string) => {
        handlerSearchArtist(value);
      }, 1000),
    []
  );

  const handleChangeInputText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
    debouncedChangeHandler(event.target.value);
  };


  const handleSelectArtist = async (artist: SpotifyArtistSearch, isExistingProfile?: boolean) => {
    try {
      if (isExistingProfile) {
        if (!artist?.slug) {
          throw new Error("Invalid artist profile: missing slug");
        }
        await addBrandToOrganization(artist.slug);
        onFinishAddingToOrganization();
      } else {
        // 1. Ensure the artist is not already in the organization
        const { data: artistFound, error: artistFoundError } = await checkIfSpotifyArtistExistsForUser(artist.id);
        if (artistFound) {
          throw new Error("Artist already exists in the organization");
        };

        // 2. Fetch artist data from Spotify API
        const artistData = await getArtistBySpotifyIdService(artist.id);
        console.log("artistData: ", artistData)
        // 3. Create artist profile + get the brand slug
        const brandCreated = await createMinimalBrandService(artistData);

        if (!brandCreated) {
          throw new Error("Failed to create artist profile");
        }

        // 4. Add the brand to the organization
        await addBrandToOrganization(brandCreated.slug);

        // 5. Complete the flow
        onFinishAddingToOrganization();
      }
    } catch (error: Error | unknown) {
      // handle error
      console.log("error: ", error)
      setError(error instanceof Error ? error.message : "An unexpected error occurred");
    }
  };

  return (
    <>
      <EnterArtistContainer className="newProfileArtistComponent">
        <EnterArtistLabel>{basicTextOptions.sectionTitle}</EnterArtistLabel>
        <ArtistInputContainer className="artistInputContainer">
          <PlatformLogoContainer
            backgroundColor={SystemColors.GREEN_COLOR_LIGHT}
            padding={"13px"}
          >
            <SpotifyIcon height={"38px"} width={"38px"} />
          </PlatformLogoContainer>
          <TextField
            InputProps={{
              classes: {
                root: classes.inputBorder,
              },
              endAdornment: loadingSearch ? (
                <InputAdornment position="end">
                  <Lottie
                    height={24}
                    width={24}
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: SymphonyLoadingLottie,
                    }}
                  />
                </InputAdornment>
              ) : undefined,
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.formHelperText,
              },
            }}
            error={error !== null}
            value={searchKeyword}
            helperText={
              error ? error : undefined
            }
            onChange={handleChangeInputText}
            className={classes.textField}
            variant="outlined"
            fullWidth
            placeholder="Bad Bunny, SZA..."
          />
        </ArtistInputContainer>
      </EnterArtistContainer>
      {spotifyResults.length <= 0 ? (
        <>
          <AnotherSocialPlatformContainer>
            <CreateAccountWithoutSpotifyText>
              Or add it linking the social platform profile:
            </CreateAccountWithoutSpotifyText>
            <PlatformsButtonsContainer onClick={() => {
              history.push("/add-artist")
            }}>
              <PlatformButtonTikTokContainer>
                <TiktokLogo width={32} height={32} />
              </PlatformButtonTikTokContainer>
              <PlatformButtonInstagramContainer>
                <InstagramLogo width={32} height={32} />
              </PlatformButtonInstagramContainer>
              <PlatformButtonYoutubeContainer>
                <YoutubeLogo width={32} height={32} />
              </PlatformButtonYoutubeContainer>
            </PlatformsButtonsContainer>
          </AnotherSocialPlatformContainer>
        </>
      ) : (
        <ArtistList
          spotifyArtistList={spotifyResults}
          keyword={searchKeyword}
          onSelectArtist={handleSelectArtist}
        />
      )}
    </>
  );
}
