import LinkButton from 'components/shareable/LinkButton';
import { Body2, Caption, Subtitle2 } from 'components/shareable/Typography';
import { ConnectionsContext } from 'pages/post-auth/MarketingPage/hooks/ConnectionsContext';
import { FC, useContext } from 'react';
import { SystemColors } from 'types/globalStyles';
import { ReactComponent as FacebookIcon } from 'assets/images/facebook-rounded-icon.svg';
import { ReactComponent as InstagramIcon } from 'assets/images/instagram-bordered-icon.svg';
import { ReactComponent as InstagramGrayIcon } from 'assets/images/instagram-bw.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/arrowRight.svg';
import { useStyles } from './styles';
import { Divider } from '@material-ui/core';
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints';

const { PAPER_BACKGROUND_CONTAINER, PRIMARY_TEXT_COLOR, SECONDARY_TEXT_COLOR } = SystemColors;

interface CampaignConnectionsDetailsProps {
  onEditConnections: () => void;
}

export const CampaignConnectionsDetails: FC<CampaignConnectionsDetailsProps> = ({
  onEditConnections,
}) => {
  const classes = useStyles();
  const { mobileView } = useMediaBreakpoints();
  const { loggedInFbUser, facebookAdAccount, facebookPage, instagramPage } = useContext(ConnectionsContext);

  return (
    <div className='flex flex-col gap-2 px-6 py-6 rounded-lg bg-white'>
      <Subtitle2 color={SECONDARY_TEXT_COLOR}>Your ad will use these pages:</Subtitle2>
      <div className='flex p-2 pr-6 justify-between items-center rounded-lg' style={{ backgroundColor: PAPER_BACKGROUND_CONTAINER }}>
        <div className='flex gap-4 items-center'>
          <div className='flex gap-1 items-center'>
            <FacebookIcon width={24} height={24}/>
            <div className='flex gap-1 overflow-hidden items-center'>
              <img className='h-6 w-6 rounded-md' src={facebookPage?.picture.data.url} />
              <Body2 color={PRIMARY_TEXT_COLOR} className={classes.connectionsName}>{facebookPage?.name}</Body2>
            </div>
          </div>
          <ArrowIcon className={classes.grayIcon} />
          {instagramPage ? (
            <div className='flex gap-1 items-center'>
              <InstagramIcon width={24} height={24}/>
              <div className='flex gap-1 overflow-hidden items-center'>
                <img className='h-6 w-6 rounded-md' src={instagramPage?.instagramAccount.profile_pic} />
                <Body2 color={PRIMARY_TEXT_COLOR} className={classes.connectionsName}>{instagramPage?.instagramAccount.username}</Body2>
              </div>
            </div>
          ) : (
            <div className='flex gap-1 items-center'>
              <InstagramGrayIcon height={24} width={24}/>
              <Caption color={SECONDARY_TEXT_COLOR}>No Instagram Page</Caption>
            </div>
          )}
        </div>
        <LinkButton text={mobileView? 'Edit' : 'Edit connections'} onClick={onEditConnections} />
      </div>
      <div className='flex gap-2'>
        <div className='flex gap-1 items-center'>
          <Caption color={SECONDARY_TEXT_COLOR}>Fb profile:</Caption>
          <img className='h-4 w-4 rounded-full' src={loggedInFbUser?.picture?.data.url} />
          <Caption color={PRIMARY_TEXT_COLOR}>{loggedInFbUser?.name}</Caption>
        </div>
        <Divider orientation='vertical' flexItem />
        <div className='flex gap-1 items-center'>
          <Caption color={PRIMARY_TEXT_COLOR}>Ad account:</Caption>
          <Caption color={SECONDARY_TEXT_COLOR}>{facebookAdAccount?.name}</Caption>
        </div>
      </div>
    </div>
  );
};