import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import { CSSProperties } from "react";
import {
  Container,
} from "styles/shared";

export default function BrandsListItemModal(props: {
  className?: string;
  name: string;
  image: string;
  slug: string;
  isPro: boolean;
  onClickListItem?: (slug: string) => void;
}) {
  const {
    className = '',
    name,
    image,
    slug,
    isPro,
    onClickListItem = () => { },
  } = props;

  const styles: Record<string, CSSProperties> = {
    image: {
      width: "60px",
      height: "60px",
      borderRadius: "99%",
      marginRight: "16px",
    },
  };

  return (
    <Container
      className={`brandListItemModalComponent ${className}`}
      style={sty.brandListItemModalComponent}
      onClick={() => onClickListItem(slug)}
      borderBottom="1px solid #E5E7EB"
      padding="12px"
    >
      <img style={styles.image} src={image} />
      <div
        className="brandListItemName"
        style={sty.brandListItemName}
      >
        {name}
      </div>

      {isPro &&
        <UpgradeToProChip
          type="label"
          mainContainerProps={{
            margin: "0",
          }}
        />
      }
    </Container>
  );
}

const sty = {
  brandListItemModalComponent: {
    display: "flex", 
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  } as CSSProperties,
  brandListItemName: {
    fontSize: 16,
    marginRight: 'auto',
  } as CSSProperties,
};
