import { makeStyles } from '@material-ui/core/styles';
import { SystemColors } from 'types/globalStyles';

export const useStyles = makeStyles({
  stepper: {
    padding: '0 !important',
    '& .MuiStepContent-root': {
      marginTop: '0px !important',
      marginLeft: '7px !important',
      borderLeft: `1px solid ${SystemColors.DIVIDER_LINE} !important`,
    },
    '& .MuiStepConnector-root': {
      marginLeft: '7px !important',
      '& span': {
        borderLeft: `1px solid ${SystemColors.DIVIDER_LINE} !important`,
      },
    },
    '& .MuiStepLabel-iconContainer > div': {
      height: '16px !important',
      width: '16px !important',
      backgroundColor: SystemColors.DIVIDER_LINE,
    },
    '& .MuiStepConnector-vertical': {
      padding: '0 !important',
    },
  },
  lastStep: {
    '& .MuiStepContent-root': {
      borderLeft: 'none !important',
    },
  },
  fbErrorLogo: {
    minHeight: '24px',
    minWidth: '24px',
    '& path:first-of-type': {
      fill: SystemColors.ERROR_COLOR,
    },
  },
  fbErrorContainer: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: SystemColors.PAPER_ERROR_CONTAINER,
  },
});
