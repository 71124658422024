import { useContext, useEffect, useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import { BrandContent, ArtistPermissions, WebsiteType, WEBSITE_TYPES } from "types/global";
import CardTitle from "components/shareable/CommonComponent/CardTitle";
import SymphonyTabs from "components/shareable/SymphonyTabs";
import GeneralPurpleButton from "components/shareable/CommonComponent/PurpleButton";
import * as Sentry from "@sentry/react";
import { pageView } from "analytics";
import Axios from "helpers/Interceptor";
import { Container, MainContainer, StyledNavBar, TabsPanelsContainer } from "styles/shared";
import { BannerContainer, ContentContainer, TabsContainer } from "../../MarketingPage/styles";
import { NewLoader } from "components/Loader/ConnectBrandLoader";
import WebsiteListItem from "./WebsiteListItem";
import { formattedWebsiteUrl, getStatusToShowPaymentFailedBanner, isBrandAdministrator } from "utils";
import CreateNewSiteModal, { CreateSiteOptions, Steps } from "./CreateNewSiteModal";
import { WEBSITE_CREATION_TYPE } from "../types";
import { Body2, Caption, Headline2 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import { OverallWebsiteDashboard } from "./OverallWebsiteDashboard";
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";
import PaymentFailedBanner from "components/shareable/PaymentFailedBanner";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { getRecordDetails } from "services/symphonyApi/brandContentService";
import DefaultLearningIndicator, { LearningIndicatorType } from "components/shareable/LearningIndicator/default";
import ClickableChip from "pages/post-auth/MarketingPage/Components/CreateCampaignBadge";
import MusicSiteIcon from 'assets/images/websiteThemes/music-site.svg';
import DataCollectIcon from 'assets/images/websiteThemes/data-collector-site.svg';
import TourSiteIcon from 'assets/images/websiteThemes/tour.svg';
import PresaveIcon from 'assets/images/websiteThemes/presave-site.svg';
import DefaultAdditionalActions from "components/shareable/CommonComponent/CardTitle/DefaultAdditionalActions";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import { OrganizationSubscriptionStatusBanner } from "components/shareable/OrganizationFailedPayments";

enum WebsitePageTabs {
    SITES = 'sites',
    DASHBOARD = 'dashboard',
}

const WebsitesList = () => {
    const [loadingContent, setLoadingContent] = useState<boolean>(false)
    const [brandContents, setBrandContents] = useState<BrandContent[]>([])
    const [step, setStep] = useState<Steps>(Steps.SELECT_OPTION)
    const [initialSiteType, setInitialSiteType] = useState<WebsiteType | null>(null);

    const { currentBrand } = useContext(CurrentBrandContext);
    const { mobileView } = useMediaBreakpoints();

    const hasAdminAccess = isBrandAdministrator(currentBrand!)
    const tabs = [WebsitePageTabs.SITES, WebsitePageTabs.DASHBOARD];

    const showPaymentFailedBanner = getStatusToShowPaymentFailedBanner(currentBrand)
  const { remainingFreeTrial, isOrganizationInactive} = useCurrentTeam()


    const [selectedTab, setSelectedTab] = useTabs(tabs, WebsitePageTabs.SITES);

    const [actionedItem, setActionedItem] = useState<BrandContent | undefined>(undefined)

    const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)

    const openCreateSiteModal = (websiteKey?: string) => {
        setInitialSiteType(websiteKey as WebsiteType || null);
        setOpenCreateModal(true);
    }

    const closeCreateSiteModal = () => {
        setOpenCreateModal(false);
        setInitialSiteType(null);
    }

    async function fetchBrandContent() {
        try {
            const brandId = currentBrand?.id;
            Sentry.setContext('fetchBrandContent', { brandId, screen: 'Website' });
            setLoadingContent(true);
            const brandContentResponse = await Axios.get(`/brand-content/${brandId}?includes[]=campaigns`, { timeout: 60000 });

            if (brandContentResponse.data.data) {
                const filteredReleases = brandContentResponse.data.data.filter((o: any) => {
                    return o.content_metadata.published;
                });
                try {
                    const [latestRelease] = filteredReleases;
                    const isSingle = (
                        latestRelease?.content_metadata.type === "project" &&
                        latestRelease?.content_metadata.tracks.length <= 1 &&
                        latestRelease?.content_metadata.spotify_release_type === "single"
                    );
                    if (isSingle) {
                        const [single] = latestRelease.content_metadata.tracks;
                        const singleDetails = await getRecordDetails(single.spotify_id, 'single', brandId);

                        if (singleDetails) {
                            filteredReleases[0].single = singleDetails;
                        }
                    }
                } catch (error) {
                    console.error('Error in fetching brand content single', error);
                }
                setBrandContents(filteredReleases);
            } else {
                console.error("Error loading releases");
            }
        } catch (error) {
            Sentry.captureException(error);
            console.error('Error in fetching brand content', error);
        } finally {
            setLoadingContent(false);
        }
    }

    useEffect(() => {
        pageView("Website List")
        fetchBrandContent()
    }, [currentBrand])

    useEffect(() => {
        if (selectedTab === WebsitePageTabs.DASHBOARD) {
            pageView("Website Dashboard")
        }
    }, [selectedTab])

    function renderWebsiteItems() {
        if (brandContents.length === 0 && !hasAdminAccess) {
            return (
                <div className="flex w-full justify-center">
                    <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>No websites created yet.</Caption>
                </div>
            )
        }
        if (brandContents.length === 0) {
            return (
                <>
                    <div className="flex flex-col py-4">
                        <Headline2 color={SystemColors.PRIMARY_TEXT_COLOR}>Create your first site</Headline2>
                        <Body2>Choose a website template based on your goal.</Body2>
                    </div>
                    <CreateSiteOptions step={step} setStep={setStep} />
                </>
            )
        }
        const items = brandContents.map((item: BrandContent, index: number) => {

            const thumbnail = item.content_metadata.thumbnail_url || currentBrand?.image || ''
            const primaryTitle = item.name
            let websiteType: WebsiteType = WEBSITE_TYPES.RECORD_STREAMS
            let additionalLabel = null

            // if this is a presave, but it hasnt been saved yet - mark it as a presave site
            if (item.content_metadata.presave && !(item.content_metadata.presave.saved_at)) {
                websiteType = WEBSITE_TYPES.PRESAVE
            } else if (item.type === WEBSITE_TYPES.DATA_COLLECTOR) {
                websiteType = WEBSITE_TYPES.DATA_COLLECTOR
            } else if (item.type === WEBSITE_TYPES.TOUR) {
                websiteType = WEBSITE_TYPES.TOUR
            } else {
                switch (item.content_metadata.type) {
                    case 'single':
                    case 'record':
                        additionalLabel = 'Single'
                        break;
                    case 'album':
                    case 'project':
                        additionalLabel = 'Album/Project'
                        break;
                    case 'playlist':
                        additionalLabel = 'Playlist'
                        break;
                }
            }

            let mainStyle = {
                bgType: 'artwork',
                bgColor: SystemColors.PRIMARY_TEXT_COLOR as any,
                primaryColor: SystemColors.ACCENT_COLOR as any,
                secondaryColor: SystemColors.PRIMARY_TEXT_COLOR as any,
                buttonConfig: "boxes"
            }

            if (item.primary_customization) {
                mainStyle = {
                    ...mainStyle,
                    ...item.primary_customization
                }
            } else if (item.content_metadata.website?.style) {
                mainStyle = {
                    ...mainStyle,
                    ...item.content_metadata.website.style
                }
            } else if (item.content_metadata.presave?.style) {
                mainStyle = {
                    ...mainStyle,
                    ...item.content_metadata.presave.style
                }
            }

            let creationType: WEBSITE_CREATION_TYPE = WEBSITE_CREATION_TYPE.AUTOMATIC
            if (item.content_metadata.publishReason === 'marketing_campaign') {
                creationType = WEBSITE_CREATION_TYPE.CAMPAIGN
            }
            else if (item.content_metadata.presave) {
                creationType = WEBSITE_CREATION_TYPE.PRESAVE
            }

            const websiteUrl = formattedWebsiteUrl(currentBrand, item)
            const linkTo = item.content_metadata.presave && !item.content_metadata.presave.saved_at ? `/marketing/campaigns/${item.campaign_id}` : `/website/${item.type}/${item.slug}`
            return (
                <WebsiteListItem
                    content={item}
                    setActionedItem={setActionedItem}
                    titleText={primaryTitle}
                    thumbnailSrc={thumbnail}
                    subtitle={null}
                    websiteUrl={websiteUrl}
                    linkTo={linkTo}
                    onClick={() => { }}
                    creationType={creationType}
                    websiteType={websiteType}
                    websiteSubtypeLabel={additionalLabel}
                    createdAt={item.createdAt}
                    updatedAt={item.updatedAt}
                    style={{
                        bgType: mainStyle?.bgType,
                        primaryColor: mainStyle?.primaryColor,
                        buttonConfig: mainStyle?.buttonConfig,
                        secondaryColor: mainStyle?.secondaryColor,
                        bgColor: mainStyle?.bgColor,
                    }}
                />
            )
        })

        return (
            <Container>
                {items}
            </Container>)
    }

    const tabsToShow = [
        {
            value: WebsitePageTabs.SITES,
            label: "Websites"
        },
        {
            value: WebsitePageTabs.DASHBOARD,
            label: "Dashboard"
        }
    ]

    if (loadingContent) {
        return (
            <div className="flex-col fixed top-1/2 left-1/2 lg:pl-56 flex items-center transform-gpu -translate-x-1/2 -translate-y-1/2 ">
                <NewLoader black />
                <p className="mt-1 text-center">Loading Websites...</p>
            </div>
        )
    }

    return (
      <>
        <MainContainer
          flexDirection={"column"}
          padding={mobileView ? "0 0 92px 0" : "32px 80px"}
        >
          <ContentContainer>
            {(showPaymentFailedBanner ||
              remainingFreeTrial > 0 ||
              isOrganizationInactive) && (
              <BannerContainer>
                {showPaymentFailedBanner && (
                  <PaymentFailedBanner
                    customerId={currentBrand?.subscription?.cid}
                  />
                )}
                <OrganizationSubscriptionStatusBanner onlyShowFailedPayments={true} />
              </BannerContainer>
            )}
            <CardTitle
              title="Website"
              useDefaultPadding={false}
              titleTextPadding="16px 24px"
              subtitle="Own your audience and grow with our customizable website builder."
              mobile={mobileView}
              // additionalContent={
              //     <DefaultLearningIndicator
              //         text="Learn how to use Symphony's Website tools"
              //         type={LearningIndicatorType.WEBSITE}
              //     />
              // }
              additionalButtons={
                <>
                  {hasAdminAccess && (
                    <DefaultAdditionalActions
                      additionalItemsText="Popular Websites:"
                      buttonText="Create New Website"
                      onButtonClick={() => openCreateSiteModal()}
                      campaignItems={[
                        {
                          key: "music",
                          title: "Promote Music",
                          icon: MusicSiteIcon,
                          link: "#",
                        },
                        {
                          key: "data_collector",
                          title: "Collect Fan Data",
                          icon: DataCollectIcon,
                          link: "#",
                        },
                        {
                          key: "tour",
                          title: "Promote Events",
                          icon: TourSiteIcon,
                          link: "#",
                        },
                      ]}
                      onCampaignItemClick={(websiteKey) =>
                        openCreateSiteModal(websiteKey)
                      }
                    />
                  )}
                </>
              }
            />
            <TabsContainer
              borderRadius={mobileView ? "0px" : "8px"}
              padding="16px 40px 16px 40px"
            >
              <StyledNavBar>
                <SymphonyTabs
                  selectedValue={selectedTab!}
                  onChange={(value) => setSelectedTab(value as WebsitePageTabs)}
                  data={tabsToShow}
                />
              </StyledNavBar>
              <TabsPanelsContainer>
                <TabPanel hidden={selectedTab !== WebsitePageTabs.SITES}>
                  <Container
                    backgroundColor="#FFFFFF"
                    padding="0px 24px 16px"
                    borderRadius="8px"
                  >
                    {renderWebsiteItems()}
                  </Container>
                </TabPanel>
                <TabPanel hidden={selectedTab !== WebsitePageTabs.DASHBOARD}>
                  <Container>
                    <OverallWebsiteDashboard />
                  </Container>
                </TabPanel>
              </TabsPanelsContainer>
            </TabsContainer>
          </ContentContainer>
        </MainContainer>
        <CreateNewSiteModal
          open={openCreateModal}
          closeModal={closeCreateSiteModal}
          initialSiteType={initialSiteType}
        />
      </>
    );
};

export default WebsitesList;
