import {
  Body2,
  Caption,
  Headline1,
  Headline1Accent,
  Subtitle2,
} from "components/shareable/Typography"
import UpgradeToProLabel from "components/shareable/UpgradeToProLabel"
import { FunctionComponent, useContext, useEffect, useState } from "react"
import { SystemColors } from "types/globalStyles"
import useStyles, {
  BottomBar,
  Feature,
  FeatureIcon,
  FeatureList,
  FixedContainer,
  StyledImg,
  TextContainer,
  TitleContainer
} from "../../styles"

import { useMediaQuery, useTheme } from "@material-ui/core"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { CurrentUserContext } from "Hooks/CurrentUserContext"
import { track } from "analytics"
import QuestionMarkIcon from "assets/images/question-mark.svg"
import getSymbolFromCurrency from "currency-symbol-map"
import { getNumberWithDecimals } from "helpers/General"
import Axios from "helpers/Interceptor"
import Intercom from "helpers/Intercom"
import { getBrandPartnerDetails } from "helpers/partners"
import { SHARED_TOAST_OPTIONS } from "pages/post-auth/MarketingPage/Components/Modals/utils"
import { DEFAULT_CURRENCY } from "pages/post-auth/MarketingPage/constants"
import { toast } from "react-toastify"
import { getConvertedCurrency } from "services/symphonyApi"
import { getBrandStripeCustomerPortal } from "services/symphonyApi/brandService"
import { Container, StyledLabel } from "styles/shared"
import { CurrencyCodesType, PaymentMethods } from "types/global"
import { getStatusToShowPaymentFailedBanner } from "utils"
import { ADDITIONAL_SPEND_BUDGET, TEAM_PLAN_MONTHLY_AD_SPEND_LIMIT, PRO_PLAN_ADDITIONAL_SEAT_PRICE, MONTHLY_PRO_PLAN_PRICE, MONTHLY_TEAM_PLAN_PRICE, TEAM_PLAN_ADDITIONAL_SEAT_PRICE, YEARLY_BY_MONTH_PRO_PLAN_PRICE, YEARLY_BY_MONTH_TEAM_PLAN_PRICE } from "../../../../../constants"
import PrimaryButton from "../../../PrimaryButton"
import BillingPlanOptions from "../../Components/BillingOptions"
import { StripeFormattedData, redirectToCheckout } from "./api"
import { BillingCycle, FeatureType } from "../../utils"
import FeatureListSection from "../FeatureList"
import { BottomPrice } from "../BottomPrice"
import { upgradeToProInOrganization } from "services/symphonyApi/organizationService"

const { MONTHLY } = BillingCycle

interface Props {
  open: boolean
  onClose: () => void
  source?: string | null
  isOnboardingView?: boolean
  secondaryButtons?: React.ReactNode[]

  // for organization-specific functions
  isOrganization?: boolean
  hasOrganizationOverage?: boolean | undefined
  reloadBrands: () => void

  // parent sets billing cycle so its same for all modals
  billingCycle: BillingCycle
  setBillingCycle: (billingCycle: BillingCycle) => void
}



type CurrencyPrices = {
  annualConverted: number,
  monthlyConverted: number,
}

const CreatorContent: FunctionComponent<Props> = ({
  source,
  onClose,
  isOrganization,
  hasOrganizationOverage,
  reloadBrands,
  billingCycle = MONTHLY,
  setBillingCycle,
  isOnboardingView = false,
  secondaryButtons,
}) => {
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down(901))
  const classes = useStyles({ isMobileView: mobileView })
  const { currentBrand, reloadBrand } = useContext(CurrentBrandContext)
  const { reloadUser } = useContext(CurrentUserContext);
  const isUnpaid = getStatusToShowPaymentFailedBanner(currentBrand)

  const [monthlySpendCamptionValue, setMonthlySpendCamptionValue] = useState<number>(ADDITIONAL_SPEND_BUDGET)
  const [monthlyAditionalUserValue, setMonthlyAditionalUserValue] = useState<number>(PRO_PLAN_ADDITIONAL_SEAT_PRICE)
  const [currencyPrices, setCurrencyPrices] = useState<CurrencyPrices>({
    annualConverted: YEARLY_BY_MONTH_PRO_PLAN_PRICE,
    monthlyConverted: MONTHLY_PRO_PLAN_PRICE,
  })


  const currencyCode = currentBrand?.currency?.code || DEFAULT_CURRENCY
  const currencySymbol = getSymbolFromCurrency(currencyCode)

  const { freeTrialOver, slug: brandSlug } = currentBrand || {}


  const handleClickUpgradeToProButton = async () => {
    setLoading(true)
    try {

      if (isOrganization) {
        // for organizations, upgrading to Pro can be done through an endpoint
        if (brandSlug) {
          await upgradeToProInOrganization(brandSlug);
          await reloadUser()
          await reloadBrand()
          await reloadBrands()
          toast.success(`You've upgraded to Pro!`, SHARED_TOAST_OPTIONS)
          handleClickCloseIconButton()
        } else {
          toast.error("There was an error upgrading to Pro. Please try again.", SHARED_TOAST_OPTIONS)
        }
      } else if (isUnpaid) {
        // if its unpaid, but in past_due state - we can let the user 
        // self-update their payment method through Stripe Customer Portal.
        if (!currentBrand?.subscription?.retriesFailed) {
          const handleUpdatePaymentMethod = async () => {
            try {
              const response = await getBrandStripeCustomerPortal(currentBrand?.slug)

              window.location = response.url;
            } catch (error) {
            }
          }

          await handleUpdatePaymentMethod()
        } else {
          // if the subscription is in unpaid state, we need
          // to update the payment method to update the subscription
          //  OR create a new sub if they want to make a different type of 
          //     recurring_interval than their current one

          const data = {
            recurring_interval: billingCycle,
            payment_method: PaymentMethods.STRIPE,
            checkout_source: source,
          } as StripeFormattedData

          track("Checkout Started from Upgrade to Pro Modal", { source: source || null });
          redirectToCheckout(data, brandSlug, !freeTrialOver).then(async () => {
            await reloadBrand()
          })
        }

      } else {
        const data = {
          recurring_interval: billingCycle,
          payment_method: PaymentMethods.STRIPE,
          checkout_source: source,
        } as StripeFormattedData

        track("Checkout Started from Upgrade to Pro Modal", { source: source ? source : null });
        redirectToCheckout(data, brandSlug, !freeTrialOver).then(async () => {
          await reloadBrand()
        })

      }
    } catch (error) {
      console.error(error);
      toast.error("There was an updating the subscription.", SHARED_TOAST_OPTIONS);
    } finally {
      setLoading(false)
    }
  }

  const handleClickQuestionMarkIcon = () => Intercom.openSymphonyProArticle()

  const handleClickCloseIconButton = () => {
    if (onClose) onClose()
  }

  const fromPartner = getBrandPartnerDetails(currentBrand)

  // either discountAmount, or 1x (no discount)
  const partnerDiscount: number = fromPartner && fromPartner.discountAmount ? fromPartner.discountAmount : 1

  let showPartnerText;
  if (fromPartner && !isOrganization) {
    const partnerDiscountPercentage = 100 - (partnerDiscount! * 100)
    const basePartnerLabel = `artists get a ${partnerDiscountPercentage}% discount on monthly plans for their first 12 months! (${currencySymbol}${(currencyPrices.monthlyConverted * partnerDiscount!).toFixed(2)} / month instead of ${currencySymbol}${currencyPrices.monthlyConverted} / month)`
    switch (fromPartner.partnerId) {
      case 'UM':
        showPartnerText = `UnitedMasters SELECT ${basePartnerLabel}`
        break
      case 'Audiomack':
        showPartnerText = `Audiomack ${basePartnerLabel}`
        break
      case 'CDBaby':
        showPartnerText = `CDBaby ${basePartnerLabel}`
        break
      case 'Downtown':
        showPartnerText = `Downtown Music ${basePartnerLabel}`
        break
      case 'Daily Playlists':
        showPartnerText = `Daily Playlists ${basePartnerLabel}`
        break
    }
  }

  const getCaption = (feature: FeatureType) => {
    const { caption, feature: featureName, featureId } = feature
    if (typeof caption === 'string') return caption
    switch (featureId) {
      case 'marketing-spend':
        return (caption!)(currencyCode, monthlySpendCamptionValue)
      case 'invite-users':
        return (caption!)(currencyCode, monthlyAditionalUserValue)
      default:
        return ''
    }
  }

  const getPricesValues = async () => {
    let baseMonthly
    let baseAnnual
    if (isOrganization) {
      baseMonthly = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: MONTHLY_TEAM_PLAN_PRICE })
      baseAnnual = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: YEARLY_BY_MONTH_TEAM_PLAN_PRICE })
    } else {
      baseMonthly = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: MONTHLY_PRO_PLAN_PRICE })
      baseAnnual = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: YEARLY_BY_MONTH_PRO_PLAN_PRICE })
    }

    setCurrencyPrices({
      annualConverted: Number(getNumberWithDecimals(baseAnnual, 2)),
      monthlyConverted: Number(getNumberWithDecimals(baseMonthly, 2)),
    })
  }

  useEffect(() => {
    if (currencyCode !== DEFAULT_CURRENCY) {
      if (isOrganization) {
        getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: TEAM_PLAN_ADDITIONAL_SEAT_PRICE })
          .then((res) => setMonthlyAditionalUserValue(Number(getNumberWithDecimals(res, 2))))

        getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: TEAM_PLAN_MONTHLY_AD_SPEND_LIMIT })
          .then((res) => setMonthlySpendCamptionValue(Number(getNumberWithDecimals(res, 2))))
      } else {
        getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: PRO_PLAN_ADDITIONAL_SEAT_PRICE })
          .then((res) => setMonthlyAditionalUserValue(Number(getNumberWithDecimals(res, 2))))

        getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: ADDITIONAL_SPEND_BUDGET })
          .then((res) => setMonthlySpendCamptionValue(Number(getNumberWithDecimals(res, 2))))
      }

      getPricesValues()
    } else {
      // TODO: remove this else statement when we fix the way we switch from brand to brand, right now it keeps some values unchanged
      if (currencyPrices.monthlyConverted !== MONTHLY_PRO_PLAN_PRICE) {
        setCurrencyPrices({
          annualConverted: YEARLY_BY_MONTH_PRO_PLAN_PRICE,
          monthlyConverted: MONTHLY_PRO_PLAN_PRICE,
        })
        setMonthlyAditionalUserValue(Number(getNumberWithDecimals(PRO_PLAN_ADDITIONAL_SEAT_PRICE, 2)))
        setMonthlySpendCamptionValue(Number(getNumberWithDecimals(ADDITIONAL_SPEND_BUDGET, 2)))
      }
      if (isOrganization) {
        setCurrencyPrices({
          annualConverted: YEARLY_BY_MONTH_TEAM_PLAN_PRICE,
          monthlyConverted: MONTHLY_TEAM_PLAN_PRICE,
        })
        setMonthlyAditionalUserValue(Number(getNumberWithDecimals(TEAM_PLAN_ADDITIONAL_SEAT_PRICE, 2)))
        setMonthlySpendCamptionValue(Number(getNumberWithDecimals(TEAM_PLAN_MONTHLY_AD_SPEND_LIMIT, 2)))
      }
    }
  }, [currencyCode, isOrganization])


  function renderHeadline() {
    if (isOrganization) {
      // we need to check overages here
      if (hasOrganizationOverage) {

      } else {

      }
      return (
        <TitleContainer>
          <Headline1 color={SystemColors.PRIMARY_TEXT_COLOR}>
            Unlock the full power of
          </Headline1>
          <Headline1Accent>AI-powered marketing</Headline1Accent>
        </TitleContainer>
      )
    } else {
      if (freeTrialOver) {
        return (
          <TitleContainer>
            <Headline1 color={SystemColors.PRIMARY_TEXT_COLOR}>
              Unlock the full power of
            </Headline1>
            <Headline1Accent>AI-powered marketing</Headline1Accent>
          </TitleContainer>
        )
      } else {
        if (isOnboardingView) {
          return null
        } else {
          return (
            <>
              <TitleContainer>
                <Headline1 color={SystemColors.PRIMARY_TEXT_COLOR}>
                  Try Symphony Pro for free
                </Headline1>
                <Subtitle2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                  Everything you need to analyze, market, and grow your fanbase + business, free for 14 days.*
                </Subtitle2>
              </TitleContainer>
            </>
          )
        }
      }
    }
  }

  function renderBillingOptionButtons() {
    if (isOrganization) {
      return null
    } else if (isUnpaid && !currentBrand?.subscription?.retriesFailed) {

      return null
    } else {

      return (
        <BillingPlanOptions
          mobileView={mobileView}
          billingCycle={billingCycle}
          partnerDiscount={partnerDiscount}
          setBillingCycle={setBillingCycle}
        />
      );
    }
  }

  return (
    <>
      {renderHeadline()}
      {showPartnerText && (<div style={{
        background: "#ffd56b",
        borderRadius: "4px",
        padding: "12px",
        boxSizing: "border-box",
      }}>
        <p style={{
          fontWeight: "500",
          fontSize: "0.8rem",
          color: "black",
        }}>
          {showPartnerText}
        </p>
      </div>)}
      <FeatureListSection
        classes={classes}
        mobileView={mobileView}
        handleClickQuestionMarkIcon={handleClickQuestionMarkIcon}
        getCaption={getCaption}
        isTeam={false}
      />
      <BottomPrice
        mobileView={mobileView}
        loading={loading}
        currencySymbol={currencySymbol as CurrencyCodesType}
        currencyCode={currencyCode}
        freeTrialOver={freeTrialOver || false}
        handleClickUpgradeToProButton={handleClickUpgradeToProButton}
        secondaryButtons={secondaryButtons}
        isOnboardingView={isOnboardingView}
        isTeamsView={false}
        amount={billingCycle === MONTHLY ? currencyPrices.monthlyConverted : currencyPrices.annualConverted * 12}
        timePeriod={billingCycle.toLowerCase()}
        isOrganization={isOrganization}
        hasOrganizationOverage={hasOrganizationOverage}
        monthlyAditionalUserValue={monthlyAditionalUserValue}
        isUnpaid={isUnpaid}
        billingCycle={billingCycle}
        setBillingCycle={setBillingCycle}
        currencyPrices={currencyPrices}
        retriesFailed={currentBrand?.subscription?.retriesFailed}
        partnerDiscount={partnerDiscount}
      />
    </>
  )
}

export default CreatorContent

