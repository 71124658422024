import {
  useState,
  createContext,
  createElement as el,
  ReactNode,
  FunctionComponent,
  SetStateAction,
} from "react"

interface Data {
  loading: boolean
  isDisabledConnectButton: boolean
  connectModalType: string
  handleConnect?: () => void
}

interface Context {
  data: Data
  saveData: React.Dispatch<SetStateAction<Data>>
}

export const ConnectModalsContext = createContext<Context>({
  data: {} as Data,
  saveData: () => {},
})

type Props = {
  children: ReactNode[] | ReactNode
}

const MarketingDataProvider: FunctionComponent<Props> = ({ children }) => {
  const [data, saveData] = useState<Data>({} as Data)

  const context = { data, saveData }

  return el(ConnectModalsContext.Provider, { value: context }, children)
}

export default MarketingDataProvider
