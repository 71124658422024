import SecondaryButton from 'components/shareable/SecondaryButton';
import { FC, ReactNode, useContext, useState } from 'react';
import { useStyles } from './styles';
import { Body2, Caption } from 'components/shareable/Typography';
import { SystemColors } from 'types/globalStyles';
import LinkButton from 'components/shareable/LinkButton';
import Intercom from 'helpers/Intercom';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import { ReactComponent as QuestionMarkIcon } from 'assets/images/question-mark.svg'
import { ReactComponent as ExternalLinkIcon } from 'assets/images/external-link.svg'

interface ConnectionDetailProps {
  id: string;
  descriptions: string[];
  title: string;
  icon?: ReactNode;
  connected?: boolean;
  iconContainerColor?: string;
  disabled?: boolean;
  statusName?: string;
  hasAccess?: boolean;
  onConnect: () => void;
}

export const AdAccountDetail: FC<ConnectionDetailProps> = ({
  id,
  descriptions,
  title,
  icon,
  connected,
  iconContainerColor,
  disabled,
  statusName,
  hasAccess,
  onConnect,
}) => {
  const classes = useStyles();
  const { currentBrand } = useContext(CurrentBrandContext);
  const { connections } = currentBrand || {};
  const { facebook_business_manager } = connections || {};
  const formattedId = id.replace("act_", "");

  const [loading, setLoading] = useState(false);

  const redirectToAdAccountSettings = () => {
    const adAccountSettingsUrl = `https://business.facebook.com/settings/ad-accounts/${formattedId}${facebook_business_manager?.id ? "?business_id=" + facebook_business_manager.id : ""}`;
    window.open(adAccountSettingsUrl, "_blank");
  };

  const redirectToAdAccountReviewPage = () => {
    const adAccountUrl = `https://business.facebook.com/accountquality/${facebook_business_manager?.id + "/"}${formattedId}`;
    window.open(adAccountUrl, "_blank");
  };

  const handleConnect = async () => {
    setLoading(true);
    await onConnect();
    setLoading(false);
  };

  return (
    <div className={classes.detailContainer}>
      <div className='flex justify-between items-center gap-4 w-full'>
        <div className='flex gap-2 items-center'>
          <div className={classes.detailIcon} style={{ backgroundColor: iconContainerColor }}>
            {icon}
          </div>
          <div className='flex flex-col gap-1s'>
            <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>{title}</Body2>
            {descriptions.map((description, index) => (
              <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>{description}</Caption>
            ))}
            <Caption className={classes.grayContainer} color={SystemColors.SECONDARY_TEXT_COLOR}>Business: {title} ({id})</Caption>
          </div>
        </div>
        <SecondaryButton
          text={connected ? 'Connected' : 'Use'}
          onClick={handleConnect}
          loading={loading}
          disabled={disabled || connected}
          padding='8px 24px'
        />
      </div>
      {statusName && (
        <div className={classes.errorContainer}>
          <div className='flex flex-col gap-2'>
            <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>This ad account’s status is {statusName} and cannot be used</Body2>
            <LinkButton text='Review your account' onClick={redirectToAdAccountReviewPage} rightIcon={<ExternalLinkIcon />} />
          </div>
          <QuestionMarkIcon className={classes.questionMarkIcon} onClick={Intercom.openFacebookAdAccountInactiveArticle}/>
        </div>
      )}
      {!hasAccess && (
        <div className={classes.errorContainer}>
          <div className='flex flex-col gap-2'>
            <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>You don't have permission to advertise for this ad account. To use this account, click the link below to add yourself as an 'Advertiser':</Body2>
            <LinkButton text='Go to Ad Account settings' onClick={redirectToAdAccountSettings} />
          </div>
          <QuestionMarkIcon className={classes.questionMarkIcon} onClick={Intercom.openFacebookAdAccountInactiveArticle} />
        </div>
      )}
    </div>
  );
};