import Dialog from "@material-ui/core/Dialog"
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
  ChangeEvent,
  useMemo,
} from "react"
import CloseIcon from "components/shareable/CloseIcon"
import {
  CloseButtonContainer,
  CloseIconContainer,
  ModalContainer,
  ModalMainTitleLabel,
  ModalPlatformInputContainer,
  ModalSubtitleLabel,
  ModalTitlesContainer,
  ModalTitlesLabelsContainer,
  PlatformLogoContainer,
  useStyles,
  SearchListContainer
} from "../../../style"
import { InputAdornment, TextField, useMediaQuery, useTheme } from "@material-ui/core"
import { ReactComponent as SpotifyIcon } from "assets/images/spotify.svg"
import { SpotifyArtist, OnboardingArtist, CurrentBrand } from "types/global"
import debounce from "lodash/debounce"
import { getSpotifyToken } from "./api"
import Lottie from "react-lottie"
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json"
import { getHelperText, getModalContainerHeight } from "./utils"
import ArtistList from '../../ArtistList'
import Axios from 'helpers/Interceptor'
import axios, { AxiosResponse } from 'axios'
import { track } from "analytics"
import * as Sentry from "@sentry/react";
import { sleep } from "utils";
import { SEARCH_SOURCE_RESULTS } from "pages/post-auth/AddArtist"
import { checkIfSpotifyArtistExistsForUser } from "services/symphonyApi/brandService"

interface Props {
  open: boolean
  artist: string
  spotifySearchString: string
  onBoardingArtist: CurrentBrand | null
  searchResults: any
  setSearchResult: any
  searchSourceResults: string
  setSearchSourceResults: (source: SEARCH_SOURCE_RESULTS) => void
  setArtist: any
  setSpotifyArtist: any
  closeModal: () => void
  setSpotifySearchResult: Dispatch<SetStateAction<SpotifyArtist[]>>
  setSpotifySearchString: Dispatch<SetStateAction<string>>
  handleShowAlreadyExistsModal: (artist: OnboardingArtist) => void
  localSave?: boolean
}

const ConnectPlatformModal: FunctionComponent<Props> = ({
  open,
  artist,
  spotifySearchString,
  searchResults,
  setSearchResult,
  searchSourceResults,
  setSearchSourceResults,
  setArtist,
  setSpotifyArtist,
  closeModal,
  setSpotifySearchResult,
  setSpotifySearchString,
  handleShowAlreadyExistsModal,
  localSave = true,
  onBoardingArtist,
}: Props) => {
  const theme = useTheme();
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));
  const classes = useStyles();
  const [, setSpotifyToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectLoading, setSelectLoading] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [selected, setSelected] = useState<OnboardingArtist | null>(null)

  const containerHeight = getModalContainerHeight(searchResults.length)

  const helperText = getHelperText({
    error,
    results: searchResults,
    loading,
    spotifySearchString,
    textFieldValue,
  })

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          const result = await Axios.get(`/chartmetric/search/artists/spotify?q=${value}&limit=30`)
          setSearchResult(result.data.data?.artists || []);
          setSearchSourceResults(result.data.data?.source)
          setLoading(false)
        } catch (error) {
          console.error('error fetching artist', error);
          localStorage.removeItem('chartmetric_token');
          setError(true)
        }
      }, 800),
    []
  )

  const handleChangeTextField = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (error) setError(false)
    if (value.length) {
      setLoading(true)
      debouncedChangeHandler(value)
    } else {
      setSearchResult([]);
    }
    setTextFieldValue(value)
  }



  const selectArtist = async (artist: OnboardingArtist) => {
    let spotifyProfile = { artist: null } as { artist: SpotifyArtist | null }
    setSelectLoading(true)
    localStorage.removeItem('spotify_artist');
    setSpotifyArtist(null);
    if (artist.id) {
      track("Selected Artist from Modal", {
        ...artist,
        pulledFromChartmetric: Boolean(artist.chartmetric)
      })

      try {
        let links = []
        if (searchSourceResults === SEARCH_SOURCE_RESULTS.SPOTIFY) {
          links = [{ domain: 'spotify', url: [`https://open.spotify.com/artist/${artist.id}`] }]
        } else {
          const result = await Axios.get(`/chartmetric/artists/${artist.id}/urls`);
          links = result.data.data.obj;
        }
        spotifyProfile = await getSocialNetworkProfiles(links) as { artist: SpotifyArtist | null };
      } catch (err) {
        try {
          await sleep(5000);
          const result = await Axios.get(`/chartmetric/artists/${artist.id}/urls`);
          spotifyProfile = await getSocialNetworkProfiles(result.data.data.obj) as { artist: SpotifyArtist | null };
        } catch (err) {
          console.error('error fetching artist from chartmetric', err);
          Sentry.captureException(err);
          setError(true)
          setSelectLoading(false);
        }
      }
    } else {
      track("Created New Artist", {
        ...artist,
        pulledFromChartmetric: false,
      })
    }

    if (!error) {
      const { data: spotifyArtistExistsData, error: spotifyArtistExistsError } = await checkIfSpotifyArtistExistsForUser(spotifyProfile?.artist?.id || null)
      if (spotifyArtistExistsError) {
        setError(true);
        setSelectLoading(false);
        return;
      }
      if (spotifyArtistExistsData?.id) {
        track("New Artist Already Exists", artist)
        handleShowAlreadyExistsModal(artist);
        setSelectLoading(false)
        return;
      }
      if (!localSave) {
        const slug = onBoardingArtist?.slug || '';
        try {
          await Axios.put(`/brand/${slug}/connect`, {
            service: "spotify_artist",
            value: {
              chartmetric: {
                id: artist.id
              },
              spotify_artist: {
                ...spotifyProfile.artist,
              }
            }
          });
          setSpotifyArtist(spotifyProfile.artist);
        } catch (e) {
        }
      } else {
        setSpotifyArtist(spotifyProfile.artist)
        setArtist(artist);
      }
    }

    track("Linked Spotify from Modal")
    setSpotifySearchString(textFieldValue);
    setSpotifySearchResult(searchResults);
    closeModal();
    setError(false)
    setSelectLoading(false);
  }

  const getSocialNetworkProfiles = async (links: any[]) => {
    let spotifyNetworkProfile = {}

    const reqLinks = links.filter((item: { url: string; domain: string; }) => 
      ['spotify'].some(itm => itm === item.domain)
    )

    for (const data of reqLinks) {
      const { domain, url } = data as { domain: string; url: string; }

      if (domain === "spotify") {
        const artistID = url[0].replace('https://open.spotify.com/artist/', '');
        const spotifyResults = await searchSpotify(artistID)

        // setSpotifyArtist(spotifyResults?.data);
        let spotify_artist = JSON.parse(JSON.stringify(spotifyResults?.data));
        localStorage.setItem('spotify_artist', JSON.stringify(spotify_artist))
        track("Autolinked Spotify from Modal")

        const arr = []
        arr.push(spotifyResults?.data)
        spotifyNetworkProfile = { artist: spotifyResults?.data }
      }
    }

    return spotifyNetworkProfile
  }

  async function searchSpotify(artistId: string): Promise<AxiosResponse | undefined> {
    const spotify_token = localStorage.getItem('spotify_token')

    if (!spotify_token) {
      const { data: newSpotifyToken } = await getSpotifyToken()
      localStorage.setItem('spotify_token', newSpotifyToken.token)
      return await searchSpotify(artistId)
    } else {
      try {
        const spotifyHeaders = {
          Authorization: 'Bearer ' + spotify_token
        }
        const results = await axios.get(`https://api.spotify.com/v1/artists/${artistId}`, { headers: spotifyHeaders })
        return results;
      } catch (e: unknown) {
        const { response } = e as { response: { status: number } }
        const { status } = response

        if (status === 401) {
          console.error("Spotify access token expired ~ grabbing new one")
          const { data: newSpotifyToken } = await getSpotifyToken()
          localStorage.setItem('spotify_token', newSpotifyToken.token)
          return await searchSpotify(artistId)

        } else {
          console.error('error pulling spotify', response)
        }
      }
    }
  }

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler])

  useEffect(() => {
    (async () => {
      const spotifyToken = localStorage.getItem("spotify_token")

      if (!spotifyToken) {
        const { data } = await getSpotifyToken()
        if (data?.token) localStorage.setItem("spotify_token", data?.token)
        setSpotifyToken(data?.token)
      } else {
        setSpotifyToken(spotifyToken)
      }
    })()
  }, [])

  useEffect(() => {
    if (open) setTextFieldValue(spotifySearchString)
  }, [spotifySearchString, open])

  return (
    <Dialog
      open={open}
      BackdropProps={{ className: classes.modalBackground}}
      classes={{ 
        scrollPaper: classes.scrollPaper,
        root: classes.rootDialog 
      }}
      fullScreen={setResponsiveView}
    >
      <ModalContainer
        width={setResponsiveView ? "auto" : "678px"}
        margin={setResponsiveView ? "0px" : "auto"}
        height="100%"
        padding={setResponsiveView ? "16px" : "32px"}
        gap="32px"
      >
        <ModalTitlesContainer>
          <CloseButtonContainer>
            <CloseIconContainer onClick={closeModal}>
              <CloseIcon />
            </CloseIconContainer>
          </CloseButtonContainer>
          <ModalTitlesLabelsContainer>
            <ModalMainTitleLabel>Connect Spotify</ModalMainTitleLabel>
            <ModalSubtitleLabel>
              What is the name or URL for {artist} Spotify account?
            </ModalSubtitleLabel>
          </ModalTitlesLabelsContainer>
        </ModalTitlesContainer>
        <ModalPlatformInputContainer>
          <PlatformLogoContainer backgroundColor="#191919" width='48px' height='48px'>
              <SpotifyIcon height="32px" width="32px"/>
          </PlatformLogoContainer>
          <TextField
            InputProps={{
              classes: {
                root: classes.inputBorder,
              },
              endAdornment: loading && (
                <InputAdornment position="end">
                  <Lottie
                    height={24}
                    width={24}
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: SymphonyLoadingLottie,
                    }}
                  />
                </InputAdornment>
              ),
            }}
            value={textFieldValue}
            onChange={handleChangeTextField}
            className={classes.textField}
            variant="outlined"
            placeholder="Bad Bunny, Madonna..."
            error={!!error}
            fullWidth
            FormHelperTextProps={{
              classes: {
                root: classes.formHelperText
              },
            }}
            helperText={helperText}
          />
        </ModalPlatformInputContainer>
        {searchResults && searchResults.length > 0 && (
          <SearchListContainer height={containerHeight}>
            <ArtistList
              loading={selectLoading}
              artists={searchResults}
              selectArtist={(artist: OnboardingArtist) => {
                setSelected(artist)
                selectArtist(artist)
              }}
              selectedArtist={selected}
            />
          </SearchListContainer>
        )}
      </ModalContainer>
    </Dialog>
  )
}

export default ConnectPlatformModal
