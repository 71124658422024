import UpgradeToProLabel from "components/shareable/UpgradeToProLabel";
import { IconContainer } from "../styles";
import { StarsIcon } from "components/svg-icons";

export default function SubscriptionStatusIndicator(props: {
    handleOpenModal?: () => void
    text: string
    showStars?: boolean
    maxWidth?: string
    width?: string;
    margin?: string;
    backgroundColor?: string;
    textColor?: string;
}) {

    const {
        handleOpenModal,
        text,
        showStars,
        maxWidth,
        width,
        margin,
        backgroundColor,
        textColor
    } = props


    return (
        <div

            className={`px-5 justify-center ${handleOpenModal ? 'cursor-pointer' : ''}`}
            onClick={() => {
                if (handleOpenModal) handleOpenModal()
            }}
            style={{
                width: width || 'inherit',
                margin: margin || 'inherit'
            }}>
            <UpgradeToProLabel
                label={text}
                maxWidth={maxWidth}
                customColors={{
                    backgroundColor: backgroundColor || "rgba(245, 186, 35, 0.12)",
                    labelColor: textColor || "#F5BA23",
                }}
                {...(showStars ? {
                    iconBeforeLabel: (
                        <IconContainer width="15%">
                            <StarsIcon />
                        </IconContainer>
                    ),

                    iconAfterLabel: (
                        <IconContainer width="14%" transform="scaleX(-1)">
                            <StarsIcon />
                        </IconContainer>
                    )

                } : {})}


            />
        </div>

    )
}