import { FC, useContext, useState } from 'react';
import { DialogContent } from '@material-ui/core';
import DialogPaper from 'components/shareable/DialogPaper';
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints';
import { ConnectBusinessManagerSection } from './ConnectBusinessManager';
import { ConnectAdAccountSection } from './ConnectAdAccountSection';
import { ConnectionsContext } from 'pages/post-auth/MarketingPage/hooks/ConnectionsContext';
import { UnloggedView } from 'pages/post-auth/MarketingPage/Components/ConnectionsSection/UnloggedView';

interface FacebookAdAccountConnectModalProps {
  open: boolean;
  setupType: string;
  onClose: () => void;
}

export const FacebookAdAccountConnectModal: FC<FacebookAdAccountConnectModalProps> = ({
  setupType,
  open,
  onClose,
}) => {
  const { mobileView } = useMediaBreakpoints();
  const { loggedInFbUser } = useContext(ConnectionsContext);
  const [showBusinessManagerSection, setShowBusinessManagerSection] = useState(false);
  const [initCreateAdAccountProcess, setInitCreateAdAccountProcess] = useState(false);

  return (
    <DialogPaper
      open={open}
      fullScreen={Boolean(mobileView && loggedInFbUser)}
      dialogStyle={{
        margin: mobileView && loggedInFbUser ? 0 : 'auto 16px',
        borderRadius: mobileView && loggedInFbUser ? 0 : 8,
        maxWidth: 559,
      }}
      containerStyle={{
        margin: mobileView ? 0 : 'auto',
        padding: !loggedInFbUser ? '32px 24px' : 0
      }}
    >
      <DialogContent style={{ padding: '0 0 12px 0' }}>
        {!loggedInFbUser ? (
          <UnloggedView onClose={onClose} />
        ) : (
          showBusinessManagerSection ? (
            <ConnectBusinessManagerSection
              creatingAdAccount={showBusinessManagerSection}
              onClose={onClose}
              onReturn={() => setShowBusinessManagerSection(false)}
              onInitCreation={() => setInitCreateAdAccountProcess(true)}
            />
          ) : (
            <ConnectAdAccountSection
              setupType={setupType}
              initCreateAdAccountProcess={initCreateAdAccountProcess}
              onClose={onClose}
              setShowBusinessManagerSection={setShowBusinessManagerSection}
              setInitCreateAdAccountProcess={setInitCreateAdAccountProcess}
            />
          )
        )}
      </DialogContent>
    </DialogPaper >
  );
};