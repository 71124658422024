import Button from '@material-ui/core/Button';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { MEDIA_BREAKPOINTS } from 'types/global';
import { SystemColors } from 'types/globalStyles';

const useStyles = makeStyles((_theme: Theme) => ({
  tableContainer: {
    borderRadius: 6,
    maxHeight: 458,
  },
  tableHead: {
    color: "#707070",
  },
  withBorderBottom: {
    borderBottom: "1px solid #c8c7cc",
  },
  noBorderBottom: {
    borderBottom: "none",
  },
  downloadInvoiceButton: {
    textTransform: "none",
    borderRadius: 48,
    backgroundColor: "#ffffff",
    color: "#8800FF",
    shadowBox: "none",
    "&:hover": {
      backgroundColor: "#ffffff",
      color: "#8800FF",
    },
  },
}))

interface ContainerProps {
  flexDirection?: string
  gap?: string |number
  display?: string
  padding?: string
  margin?: string
  backgroundColor?: string
  borderBottom?: string
  alignItems?: string
  justifyContent?: string
  transform?: string
  minHeight?: string
  width?: string
}

export interface StyledLabelProps {
  fontSize?: number
  fontWeight?: number
  lineHeight?: string
  color?: string
  cursor?: string
  marginTop?: string
}

export interface StyledSpanProps {
  color?: string
}

export interface IconProps {
  margin?: string
}

interface ArrowImageContainerProps {
  width?: string
}

interface SupportButtonContainerProps {
  marginTop?: string
}

interface CardProps {
  padding?: string
  borderRadius?: string
  marginBottom?: string
  marginTop?: string
}

export const MainContainer = styled.div`
  background-color: #f5f3f6;
  width: 100%;
  height: 200%;
  ${MEDIA_BREAKPOINTS.mediumView} {
    height: fit-content;
  }
`;

export const Card = styled.div<CardProps>`
  display: "flex";
  flex-direction: column;
  border-radius: ${({ borderRadius }) => borderRadius || "8px"};
  padding: ${({ padding }) => padding || "40px 24px"};
  background-color: #ffff;
  width: 86%;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ marginTop }) => marginTop || "48px"};
  margin-bottom: ${({ marginBottom }) => marginBottom || "16px"};
  ${MEDIA_BREAKPOINTS.smallView} {
    border-radius: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    padding-bottom: 16px;
    width: 100%;
  }
`

export const StyledLabel = styled.p`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 18}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin-top: ${({ marginTop }: StyledLabelProps) => marginTop};
`

export const StyledNavBar = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom-width: 1px;
  margin-bottom: 32px;
  border-color: rgba(209, 213, 219, 1);
  flex-grow: 1;
  margin-top: 32px;
`

export const TabsPanelsContainer = styled.div`
  padding: 0px 0px;
  height: 100%;
`

export const Container = styled.div`
  gap: ${({ gap }: ContainerProps) => {
    if (typeof gap === "number") {
      return `${gap}px`
    }
    if (typeof gap === "string") {
      return gap
    }
    return undefined
  }};
  flex-direction: ${({ flexDirection }: ContainerProps) => flexDirection};
  display: ${({ display }: ContainerProps) => display};
  justify-content: ${({ justifyContent }: ContainerProps) => justifyContent};
  align-items: ${({ alignItems }: ContainerProps) => alignItems};
  margin: ${({ margin }: ContainerProps) => margin};
  padding: ${({ padding }: ContainerProps) => padding};
  background-color: ${({ backgroundColor }: ContainerProps) => backgroundColor};
  border-bottom: ${({ borderBottom }: ContainerProps) => borderBottom};
  transform: ${({ transform }: ContainerProps) => transform};
  min-height: ${({ minHeight }: ContainerProps) => minHeight};
  width: ${({ width }: ContainerProps) => width};
`

export const SupportButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
  margin-top: ${({ marginTop }: SupportButtonContainerProps) => marginTop};
`

export const StyledSpan = styled.span`
  color: ${({ color }: StyledSpanProps) => color};
`

export const UnorderedList = styled.ul`
  list-style: none;
`

export const ListItem = styled.li`
  padding-left: 16px;
  color: #707070;
  &::before {
    content: "\u2022";
    color: #707070;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`

export const Icon = styled.img`
  margin: ${({ margin }: IconProps) => margin};
`

export const ArrowImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px auto 26px auto;
  background-color: rgba(136, 0, 255, 0.24);
  width: ${({ width }: ArrowImageContainerProps) => width};
  border-radius: 12px;
`

export const SupportButton = withStyles((theme: Theme) => ({
  root: {
    width: 154,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    textTransform: "none",
    borderRadius: 48,
    backgroundColor: "#8800FF",
    color: "#ffffff",
    height: 40,
    shadowBox: "none",
    "&:hover": {
      backgroundColor: "#8800FF",
      color: "#ffffff",
    },
  },
  text: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}))(Button)

export const LogoutButton = withStyles((theme: Theme) => ({
  root: {
    border: "2px solid #8800FF",
    width: 100,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    textTransform: "none",
    borderRadius: 48,
    backgroundColor: "#ffffff",
    color: "#8800FF",
    height: 40,
    shadowBox: "none",
    "&:hover": {
      backgroundColor: "#ffffff",
      color: "#8800FF",
    },
  },
  text: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}))(Button)

export const GeneralPurpleButton = withStyles((theme: Theme) => ({
  root: {
    width: 260,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    textTransform: "none",
    borderRadius: 48,
    backgroundColor: "#8800FF",
    color: "#ffffff",
    height: 40,
    shadowBox: "none",
    "&:hover": {
      backgroundColor: "#8800FF",
      color: "#ffffff",
    },
  },
  text: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}))(Button)

export const UpgradeToProButton = withStyles((theme: Theme) => ({
  root: {
    width: 260,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    textTransform: "none",
    borderRadius: 48,
    backgroundColor: "#8800FF",
    color: "#ffffff",
    height: 40,
    shadowBox: "none",
    "&:hover": {
      backgroundColor: "#8800FF",
      color: "#ffffff",
    },
  },
  text: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}))(Button)

export const ChangePaymentMethodButton = withStyles((theme: Theme) => ({
  root: {
    width: 60,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    textTransform: "none",
    borderRadius: 48,
    backgroundColor: "#ffffff",
    color: "#8800FF",
    height: 40,
    shadowBox: "none",
    "&:hover": {
      backgroundColor: "#ffffff",
      color: "#8800FF",
    },
  },
  text: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}))(Button)

export const DowngradToFreeButton = withStyles((theme: Theme) => ({
  root: {
    border: "1px solid #8800FF",
    width: 187,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    textTransform: "none",
    borderRadius: 48,
    backgroundColor: "#ffffff",
    color: "#8800FF",
    height: 40,
    shadowBox: "none",
    "&:hover": {
      backgroundColor: "#ffffff",
      color: "#8800FF",
    },
  },
  text: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}))(Button)

export const BannerContainer = styled.div`
  margin: 32px auto 0 auto;
  width: 86%;
`

// TODO 630: Remove once a month has passed since the release of the currency update
export const StyledArticle = styled.article`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 16px 24px;
  border-radius: 8px;
  margin-top: 8px;
  background-color: ${SystemColors.PAPER_WARNING_CONTAINER}
`

export default useStyles
