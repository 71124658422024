import { FunctionComponent } from "react";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import Loaderv2 from "components/Loader/v2";

interface OptionProps {
  isProUser?: boolean;
  label?: string;
  mainContainerClassName?: string;
  labelClassName?: string;
  selected?: boolean;
  loading?: boolean;
}

const MenuProOption: FunctionComponent<OptionProps> = ({
  label,
  labelClassName,
  mainContainerClassName,
  selected,
  loading
}: OptionProps) => (
  <div className={mainContainerClassName}>
    <p className={labelClassName}>{label}</p>{" "}
    <div className="ml-auto flex items-center gap-2">
      {loading && <Loaderv2 />}
      <UpgradeToProChip
        type="gem"
        color={selected ? "white" : "purple"}
      />
    </div>
  </div>
);

export default MenuProOption;
