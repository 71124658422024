import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { Container, StyledLabel, SymphonyTooltip } from "styles/shared";
import useStyles from "../../styles";
import { DEFAULT_CURRENCY } from "pages/post-auth/MarketingPage/constants";
import getSymbolFromCurrency from "currency-symbol-map";
import PrimaryButton from "components/shareable/PrimaryButton";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import PaypalButtonNonPro from "pages/post-auth/MarketingPage/Components/PaypalButtonNonPro";
import { OnApproveActions, OnApproveData, CreateOrderActions } from "@paypal/paypal-js";
import { CurrencyCodesType, CurrentBrand, OrganizationData } from "types/global";
import { getBrandPartnerDetails } from "helpers/partners";
import { BillingCycle, format, labels, teamLabels } from "components/shareable/UpgradeToProModal/utils";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { YEARLY_BY_MONTH_PRO_PLAN_PRICE, MONTHLY_PRO_PLAN_PRICE, MONTHLY_TEAM_PLAN_PRICE, YEARLY_BY_MONTH_TEAM_PLAN_PRICE, TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE } from "../../../../../../../constants";
import Intercom from "helpers/Intercom";
import UpgradeToProLabel from "components/shareable/UpgradeToProLabel";
import { Headline1, Subtitle2 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import { ReactComponent as QuestionMarkIcon } from "assets/images/question-mark.svg";
import BillingPlanOptions from "components/shareable/UpgradeToProModal/Components/BillingOptions";
import { getConvertedCurrency } from "services/symphonyApi";
import { getNumberWithDecimals } from "helpers/General";
import PaypalButtonNonUSD from "pages/post-auth/MarketingPage/Components/PaypalButtonNonUSD";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";

type CurrencyPrices = {
    annualConverted: number,
    monthlyConverted: number,
}

const payPalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID as string;

interface SubmitCampaignOrUpgradeProps {
    isProUser: boolean;
    currentBrand: CurrentBrand;
    handleClickStripeSubmitButton: () => void;
    creatingCampaign?: boolean | undefined;
    currencyCode: CurrencyCodesType;

    // organization info 
    primaryOrganization?: OrganizationData | null | undefined;
    // only for non-pro users who are creating a subscription from here
    selectedBillingCycle: BillingCycle;
    setBillingCycle: Dispatch<SetStateAction<BillingCycle>>;

    // paypal checkout items
    createPaypalOrder: (data: Record<string, unknown>, actions: CreateOrderActions) => Promise<string>;
    onCancelPayPalOrder?: () => void;
    onErrorPayPalOrder?: () => void;
    onPayPalOrderApprove: (data: OnApproveData, actions: OnApproveActions) => Promise<void>;



}
export default function SubmitOrUpgradeVideoViews(props: SubmitCampaignOrUpgradeProps) {
    const {
        currentBrand,
        isProUser,
        selectedBillingCycle,
        setBillingCycle,
        handleClickStripeSubmitButton,
        creatingCampaign,
        createPaypalOrder,
        onCancelPayPalOrder,
        onErrorPayPalOrder,
        onPayPalOrderApprove,
        currencyCode,
        primaryOrganization
    } = props
    const classes = useStyles();

    const { brandIsInOrganization, hasProAccountOverage } = useCurrentTeam()

    // Check if brand belongs to an organization and if organization is ready to use
    const showOrganizationUI = brandIsInOrganization(currentBrand?.slug!) && primaryOrganization && primaryOrganization.status === 'ready'
    // New state for tracking if user wants to add to organization
    const [addToTeam, setAddToTeam] = useState(true);



    const [currencyPrices, setCurrencyPrices] = useState<CurrencyPrices>({
        annualConverted: YEARLY_BY_MONTH_PRO_PLAN_PRICE,
        monthlyConverted: MONTHLY_PRO_PLAN_PRICE,
    })
    const [monthlyAdditionalProPlanPrice, setMonthlyAdditionalProPlanPrice] = useState<number>(TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE)

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down(768))

    function renderSubmissionUI() {
        return (
            <>
                <Container className={clsx(classes.space, classes.noPaddingBottom)}
                    display="flex" flexDirection="column"
                    gap="8px">

                    <StyledLabel color="#707070" fontSize={14} fontWeight={400}>
                        Your marketing campaign may take up to 48 hours to go live. You will
                        be redirected to a payment page to finish checkout.
                    </StyledLabel>
                </Container>

                <Grid item className={clsx(classes.space, classes.noPaddingTop)}>
                    <Container display="flex" flexDirection="column" gap="12px" marginTop="16px">
                        <div>
                            <PrimaryButton
                                width="100%"
                                onClick={handleClickStripeSubmitButton}
                                disabled={creatingCampaign}
                                loading={creatingCampaign}
                                text={"Checkout with Card"} />
                        </div>
                        {currencyCode === 'USD' ? (
                            <div >
                                <PayPalScriptProvider options={{ "client-id": payPalClientId }}>
                                    <PayPalButtons
                                        className="w-full relative z-0"
                                        style={{ label: "pay", shape: "pill" }}
                                        fundingSource="paypal"
                                        createOrder={createPaypalOrder}
                                        onCancel={onCancelPayPalOrder}
                                        onError={onErrorPayPalOrder}
                                        onApprove={onPayPalOrderApprove}
                                    />
                                    <PayPalButtons
                                        className="w-full relative z-0"
                                        style={{ label: "pay", shape: "pill" }}
                                        fundingSource="venmo"
                                        createOrder={createPaypalOrder}
                                        onCancel={onCancelPayPalOrder}
                                        onError={onErrorPayPalOrder}
                                        onApprove={onPayPalOrderApprove}
                                    />
                                </PayPalScriptProvider>

                            </div>
                        ) : (
                            <PaypalButtonNonUSD width='100%' height='48px' />
                        )}
                    </Container>
                </Grid >
            </>
        )
    }


    // this is used ONLY for international currencies - specifically when a user selects an ad account with an international currency, and
    // we need to convert the "Upgrade to Pro" price to the international currency
    const getPricesValues = async () => {
        try {
            let baseMonthly
            let baseAnnual
            let baseAdditionalProPlanPrice

            if (showOrganizationUI) {
                [baseMonthly, baseAnnual, baseAdditionalProPlanPrice] = await Promise.all([
                    getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: MONTHLY_TEAM_PLAN_PRICE }),
                    getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: YEARLY_BY_MONTH_TEAM_PLAN_PRICE }),
                    getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE })
                ])
            } else {
                [baseMonthly, baseAnnual] = await Promise.all([
                    getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: MONTHLY_PRO_PLAN_PRICE }),
                    getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: YEARLY_BY_MONTH_PRO_PLAN_PRICE })
                ])
            }
            setCurrencyPrices({
                annualConverted: Number(getNumberWithDecimals(baseAnnual, 2)),
                monthlyConverted: Number(getNumberWithDecimals(baseMonthly, 2)),
            })
            if (baseAdditionalProPlanPrice) {
                setMonthlyAdditionalProPlanPrice(Number(getNumberWithDecimals(baseAdditionalProPlanPrice, 2)))
            }
        } catch (error) {
            console.error('Error converting currency:', error);
            // Fallback to default USD prices
            setCurrencyPrices({
                annualConverted: YEARLY_BY_MONTH_PRO_PLAN_PRICE,
                monthlyConverted: MONTHLY_PRO_PLAN_PRICE,
            })
            setMonthlyAdditionalProPlanPrice(TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE)
        }
    }

    useEffect(() => {
        if (currencyCode !== DEFAULT_CURRENCY) {
            getPricesValues()
        } else if (showOrganizationUI) {
            setCurrencyPrices({
                annualConverted: YEARLY_BY_MONTH_TEAM_PLAN_PRICE,
                monthlyConverted: MONTHLY_TEAM_PLAN_PRICE,
            })
        } else {
            setCurrencyPrices({
                annualConverted: YEARLY_BY_MONTH_PRO_PLAN_PRICE,
                monthlyConverted: MONTHLY_PRO_PLAN_PRICE,
            })
        }
    }, [currencyCode, showOrganizationUI])


    function renderOrganizationUpgradeToProText() {
        // if a user is on a ready organization, there is no free trial opportunity + no partner-driven discount
        // - they either have a UI that lets them know the plan s included,
        // - or a UI to let them know they're purchasing an additional seat
        const isOverage = primaryOrganization?.status === 'ready' && hasProAccountOverage

        // get the currency codes
        const currencySymbol = getSymbolFromCurrency(currencyCode)


        // used to determine how to calculate price
        const fromPartner = getBrandPartnerDetails(currentBrand)

        // based on selected billing cycle, get the price
        const { price, billed, id: priceId, billedTrialText } = teamLabels[selectedBillingCycle]

        // the price, formatted properly for the international currency
        const formattedAmount = format(priceId === "monthly" ? currencyPrices.monthlyConverted : currencyPrices.annualConverted * 12)
        const formattedExtraSpend = format(monthlyAdditionalProPlanPrice)
        const formattedCurrency = currencySymbol
        // the time period (monthly or annual)
        const formattedTimePeriod = billedTrialText

        // to open Upgrade to Pro details article
        const openIntercom = () => Intercom.openSymphonyProArticle();

        let subtitleText = isOverage ? `Once you submit your campaign, your profile will be upgraded to Pro as part of your Team Plan: ${formattedCurrency}${formattedExtraSpend} / ${formattedTimePeriod}.` : 'Once you submit your campaign, your profile will be upgraded to Pro as part of your Team Plan.'

        return (
            <>
                <Container className={clsx(classes.space, classes.noPaddingBottom)}
                    display="flex" flexDirection="column"
                    gap="8px">

                    <Container display="flex" flexDirection="row" gap="8px" >
                        <UpgradeToProLabel
                            maxWidth="210px"
                            color="PURPLE"
                            label="Symphony for Teams"
                        />
                        <button onClick={openIntercom}>
                            <SymphonyTooltip
                                className="w-6 h-6"
                                title="Click here to learn more."
                                arrow
                            >
                                <QuestionMarkIcon height={24} width={24} />
                            </SymphonyTooltip>
                        </button>
                    </Container>

                    {subtitleText && (<Subtitle2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                        {subtitleText}
                    </Subtitle2>)}
                    <StyledLabel color="#707070" fontSize={14} fontWeight={400}>
                        Your marketing campaign may take up to 48 hours to go live. You will
                        be redirected to a payment page to finish checkout.
                    </StyledLabel>
                </Container>

                <Grid item className={clsx(classes.space, classes.noPaddingTop)}>
                    <Container display="flex" flexDirection="column" gap="12px" marginTop="16px">
                        <div>
                            <PrimaryButton
                                width="100%"
                                onClick={handleClickStripeSubmitButton}
                                disabled={creatingCampaign}
                                loading={creatingCampaign}
                                text={"Checkout & Submit Campaign"}
                            />
                        </div>
                        <div >
                            {isProUser ? (

                                <PayPalScriptProvider options={{ "client-id": payPalClientId }}>
                                    <PayPalButtons
                                        className="w-full relative z-0"
                                        style={{ label: "pay", shape: "pill" }}
                                        fundingSource="paypal"
                                        createOrder={createPaypalOrder}
                                        onCancel={onCancelPayPalOrder}
                                        onError={onErrorPayPalOrder}
                                        onApprove={onPayPalOrderApprove}
                                    />
                                    <PayPalButtons
                                        className="w-full relative z-0"
                                        style={{ label: "pay", shape: "pill" }}
                                        fundingSource="venmo"
                                        createOrder={createPaypalOrder}
                                        onCancel={onCancelPayPalOrder}
                                        onError={onErrorPayPalOrder}
                                        onApprove={onPayPalOrderApprove}
                                    />
                                </PayPalScriptProvider>
                            ) : (
                                <PaypalButtonNonPro width='100%' height='48px' />
                            )}
                        </div>
                    </Container>
                </Grid >
            </>
        )
    }


    function renderUpgradeToProText() {

        if (showOrganizationUI) {
            return renderOrganizationUpgradeToProText()
        } else {
            const freeTrialOver = currentBrand.freeTrialOver

            // get the currency codes
            const currencySymbol = getSymbolFromCurrency(currencyCode)
            // used to determine how to calculate price
            const fromPartner = getBrandPartnerDetails(currentBrand)
            // based on selected billing cycle, get the price
            const { price, billed, id: priceId, billedTrialText } = labels[selectedBillingCycle]
            // either discountAmount, or 1x (no discount)
            const partnerDiscount: number = fromPartner && fromPartner.discountAmount ? fromPartner.discountAmount : 1
            // the price, formatted properly for the international currency
            const formattedAmount = format(priceId === "monthly" ? currencyPrices.monthlyConverted * partnerDiscount : currencyPrices.annualConverted * 12)
            const formattedCurrency = currencySymbol
            // the time period (monthly or annual)
            const formattedTimePeriod = billedTrialText

            // to open Upgrade to Pro details article
            const openIntercom = () => Intercom.openSymphonyProArticle();

            let headlineText;
            if (freeTrialOver) {
                headlineText = 'Upgrade to Pro to run AI-powered marketing campaigns.'
            } else {
                headlineText = 'Unlock AI-powered marketing campaigns with your 14-day free trial.'
            }
            let subtitleText;

            let showPartnerText;
            if (fromPartner) {
                switch (fromPartner.partnerId) {
                    case 'UM':
                        showPartnerText = `UnitedMasters SELECT artists `
                        break
                    case 'Audiomack':
                        showPartnerText = `Audiomack artists `
                        break
                    case 'CDBaby':
                        showPartnerText = `CDBaby artists `
                        break
                    case 'Daily Playlists':
                        showPartnerText = `Daily Playlists members `
                        break
                    case 'Downtown':
                        showPartnerText = `Downtown Music artists `
                        break
                }
                const partnerDiscountPercentage = 100 - (partnerDiscount! * 100)
                subtitleText = freeTrialOver ? `${showPartnerText} get a ${partnerDiscountPercentage}% discount on monthly plans for their first 12 months!` : `${showPartnerText} get a 14-day free trial + ${partnerDiscountPercentage}% discount on monthly plans for their first 12 months!`
            } else {
                subtitleText = freeTrialOver ? `Unlock the full power of Symphony for ${formattedCurrency}${formattedAmount} / ${formattedTimePeriod}.` : 'Start your 14-day free trial and unlock the full power of Symphony.'
            }

            return (
                <>
                    <Container className={clsx(classes.space, classes.noPaddingBottom)}
                        display="flex" flexDirection="column"
                        gap="8px">

                        <Container display="flex" flexDirection="row" gap="8px" >
                            <UpgradeToProLabel
                                maxWidth="160px"
                                color="PURPLE"
                                label="Symphony Pro"
                            />
                            <button onClick={openIntercom}>
                                <SymphonyTooltip
                                    className="w-6 h-6"
                                    title="Click here to learn more."
                                    arrow
                                >
                                    <QuestionMarkIcon height={24} width={24} />
                                </SymphonyTooltip>
                            </button>
                        </Container>
                        <Headline1 color={SystemColors.PRIMARY_TEXT_COLOR}>
                            {headlineText}
                        </Headline1>
                        {subtitleText && (<Subtitle2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                            {subtitleText}
                        </Subtitle2>)}
                        {/* {showPartnerText && (<div style={{
                        background: "#ffd56b",
                        borderRadius: "4px",
                        padding: "12px",
                        boxSizing: "border-box",
                    }}>
                        <p style={{
                            fontWeight: "500",
                            fontSize: "0.8rem",
                            color: "black",
                        }}>
                            {showPartnerText}
                        </p>
                    </div>)} */}
                        <BillingPlanOptions
                            mobileView={isMobile}
                            billingCycle={selectedBillingCycle}
                            setBillingCycle={setBillingCycle}
                            partnerDiscount={partnerDiscount}
                            specificCurrencyCode={currencyCode}
                        />

                        <StyledLabel color="#707070" fontSize={14} fontWeight={400}>
                            Your marketing campaign may take up to 48 hours to go live. You will
                            be redirected to a payment page to finish checkout. {!freeTrialOver && `Free trial is for 14 days, then ${formattedCurrency}${formattedAmount}${currencyCode !== 'USD' ? ` ${currencyCode}` : ``} / ${formattedTimePeriod} unless you cancel.  You\'ll get an email reminder 7 days before your trial ends.`}
                        </StyledLabel>
                    </Container>

                    <Grid item className={clsx(classes.space, classes.noPaddingTop)}>
                        <Container display="flex" flexDirection="column" gap="12px" marginTop="16px">
                            <div>
                                <PrimaryButton
                                    width="100%"
                                    onClick={handleClickStripeSubmitButton}
                                    disabled={creatingCampaign}
                                    loading={creatingCampaign}
                                    text={!freeTrialOver ? "Start Trial & Submit Campaign" : "Checkout & Submit Campaign"} />
                            </div>
                            <div >
                                {isProUser ? (

                                    <PayPalScriptProvider options={{ "client-id": payPalClientId }}>
                                        <PayPalButtons
                                            className="w-full relative z-0"
                                            style={{ label: "pay", shape: "pill" }}
                                            fundingSource="paypal"
                                            createOrder={createPaypalOrder}
                                            onCancel={onCancelPayPalOrder}
                                            onError={onErrorPayPalOrder}
                                            onApprove={onPayPalOrderApprove}
                                        />
                                        <PayPalButtons
                                            className="w-full relative z-0"
                                            style={{ label: "pay", shape: "pill" }}
                                            fundingSource="venmo"
                                            createOrder={createPaypalOrder}
                                            onCancel={onCancelPayPalOrder}
                                            onError={onErrorPayPalOrder}
                                            onApprove={onPayPalOrderApprove}
                                        />
                                    </PayPalScriptProvider>
                                ) : (
                                    <PaypalButtonNonPro width='100%' height='48px' />
                                )}
                            </div>
                        </Container>
                    </Grid >
                </>
            )
        }
    }

    return (
        <>
            {isProUser ? (
                renderSubmissionUI()
            ) : renderUpgradeToProText()}
        </>
    )

}