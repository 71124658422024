import React, { useContext, useEffect, useState } from "react";
import AuthLayout from "./auth/AuthLayout";
import { User, useAuth0 } from "@auth0/auth0-react";
import Loader from "../components/Loader";
import FB from "../helpers/FB";
import Axios from "../helpers/Interceptor";
import * as Sentry from "@sentry/react";
import { identify, registerOnce, setUserPropertiesOnce, getCookie } from "../analytics";
import Logger from "Logger";
import { AttributionDetails, RewardfulDetails, UserData, UserMetadata } from "types/global";
import PostAuthRoutes from "./full/PostAuthRoutes";
import getUserByAuth0Id from "./controllers/getUserByAuth0Id";
import updateUserMetadata from "./controllers/updateUserMetadata";
import { CurrentUserContext } from "Hooks/CurrentUserContext";

interface UserTrackingType {
  utmParams?: {
    [key: string]: string;
  };
  partnershipParams?: {
    [key: string]: string;
  };
  rewardfulParams?: {
    [key: string]: string;
  }
}
const IndexLayout = () => {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    user
  } = useAuth0();
  const [auth0Token, setToken] = useState<string | null>(null);

  // holds userData from the server
  const { currentUser: userData, setCurrentUser: setUserData } = useContext(CurrentUserContext);

  // const [userData, setUserData] = useState<UserData | null>(null)
  const [showLoading, setLoading] = useState<boolean>(false)


  const fetchAuth0Token = async () => {
    try {
      const token = await getAccessTokenSilently();
      return token;
    } catch (error) {
      Logger.error('Error fetching Auth0 token:', error);
      return null;
    }
  };

  // this will set the user across Hotjar, mixpanel, sentry, and pixels,
  // and also store metadata if needed
  const setTrackedUser = (user: UserData) => {
    identify({
      id: String(user.userid),
      email: user.email ? user.email : undefined,
      name: undefined
    });
    setUserData(user)
  };


  // this will set the user across Hotjar, mixpanel, sentry, and pixels,
  // and also store metadata if needed
  const setUserTrackingMetadata = async (userTrackingDetails: UserTrackingType, user: UserData) => {
    // this are local cookie values from the user
    const {
      utmParams,
      partnershipParams,
      rewardfulParams,
    } = userTrackingDetails;


    const existingMetadata: UserMetadata | null = user.metadata || null;
    let metadataUpdateNeeded = false;

    const newMetadata: {
      affiliate_referrer?: string;
      attribution?: AttributionDetails
      last_touch_attribution?: AttributionDetails
      rewardful?: RewardfulDetails
    } = {};


    // If there is no partnership referrer already assigned to this user, then add a new one
    const existingReferrer = existingMetadata && existingMetadata.affiliate_referrer;
    if (partnershipParams && partnershipParams.referrer && !(existingReferrer)) {
      newMetadata.affiliate_referrer = partnershipParams.referrer;
      metadataUpdateNeeded = true;
    }

    // Check and update for UTM data
    const existingAttribution = existingMetadata && existingMetadata.attribution;

    if (utmParams && Object.keys(utmParams).length > 0 && !existingAttribution) {
      newMetadata.attribution = utmParams;
      metadataUpdateNeeded = true;
    }

    // Check and update for Last Touch Attribution UTM data 
    if (utmParams && Object.keys(utmParams).length > 0 && existingAttribution) {
      newMetadata.last_touch_attribution = utmParams;
      metadataUpdateNeeded = true;
    }

    // Check and update for Rewardful data - this should refresh automatically,
    // as rewardful's clientside script will update the cookie on every page load
    //  - only add this if no partner referrer is already present
    if (rewardfulParams && !existingReferrer && (existingMetadata?.rewardful?.rewardful_via !== rewardfulParams.rewardful_via)) {
      newMetadata.rewardful = rewardfulParams;
      metadataUpdateNeeded = true;
    }

    // Update metadata on the server if needed
    if (metadataUpdateNeeded) {
      await updateUserMetadata(user.userid, newMetadata)
    }


    const savedObject: { [key: string]: any } = {
      ...newMetadata,
    };

    // Format and include both original and formatted UTM parameters
    if (newMetadata.attribution) {
      for (const key in newMetadata.attribution) {
        let words = key.split('_');
        words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        const formattedKey = 'Initial ' + words.join(' ');

        // Add both original and formatted UTM parameters
        savedObject[key] = (newMetadata.attribution as any)[key];
        savedObject[formattedKey] = (newMetadata.attribution as any)[key];
      }
    }


    // Add affiliate_referrer and formatted partner referral
    if (newMetadata.affiliate_referrer) {
      savedObject['affiliate_referrer'] = newMetadata.affiliate_referrer;
      savedObject['Referred by Partner'] = newMetadata.affiliate_referrer;
    }

    // Add Rewardful data and a boolean for referral status - and save to Mixpanel
    if (newMetadata.rewardful) {
      savedObject['rewardful_via'] = newMetadata.rewardful.rewardful_via;
      savedObject['Was Referred by Other User'] = true;
    } else {
      savedObject['Was Referred by Other User'] = false;
    }

    registerOnce({ ...savedObject });
    setUserPropertiesOnce(savedObject);

  };


  function processCookies(): UserTrackingType {
    // Pull out any cookies or UTM params that we should set on the user
    const savedObject: any = {
      utmParams: {},
      partnershipParams: {},
      rewardfulParams: {},
    };

    const cookies = document.cookie.split(';');

    cookies.forEach(cookieStr => {
      const [key, value] = cookieStr.split('=').map(str => str.trim());

      // For UTM Params
      // if (key.startsWith('utm_')) {
      //   let words: string[] = key.split('_');
      //   words = words.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1));
      //   // Saves UTM params as: Initial UTM Source, Initial UTM Medium, etc.
      //   const newKey: string = 'Initial ' + words.join(' ');
      //   savedObject.utmParams[newKey] = value;
      // }


      // Check for UTM parameters and store them in utmParams
      if (key.startsWith('utm_')) {
        savedObject.utmParams[key] = value;
      }

      // Check for 'referrer' cookie and store it in partnershipParams
      if (key === 'referrer') {
        savedObject.partnershipParams[key] = value;
      }

      // Check for Rewardful parameters and store them in rewardfulParams
      if (key === 'rewardful_via' || key === 'rewardful_affiliate_id') {
        savedObject.rewardfulParams[key] = value;
      }
    });

    // Clean up objects to avoid returning empty ones
    if (Object.keys(savedObject.utmParams).length === 0) {
      delete savedObject.utmParams;
    }
    if (Object.keys(savedObject.partnershipParams).length === 0) {
      delete savedObject.partnershipParams;
    }
    if (Object.keys(savedObject.rewardfulParams).length === 0) {
      delete savedObject.rewardfulParams;
    }

    return savedObject;
  }

  const handleToken = (token: string) => {
    if (!token) {
      Sentry.captureException("Could not get auth0 access token silently");
      logout({ returnTo: window.location.origin });
    } else {
      setToken(token);
      localStorage.setItem('accessToken', token);
    }
  };

  const checkRewardfulStatus = () => {
    const rewardful = (window as any).rewardful;
    if (rewardful) {
      rewardful('ready', function () {
        const Rewardful = (window as any).Rewardful;
        if (Rewardful.referral) {
          Logger.debug('Current referral ID: ', Rewardful.referral);
        } else {
          Logger.warn('No referral present.');
        }
      });
    }
  };

  const getUserData = async () => {
    // first get the auth0 token
    const token = await fetchAuth0Token();

    // then get the user
    // in this call - we should get the cookie values from the user,
    // and save them in DB (rewardful affiliate, utm params, partnership details)
    if (token) {
      handleToken(token);
      try {
        const userData: UserData = await getUserByAuth0Id(user!.sub!)
        checkFBStatus()
        const {
          utmParams,
          partnershipParams,
          rewardfulParams,
        } = processCookies()
        const userTrackingDetails: UserTrackingType = {
          utmParams,
          partnershipParams,
          rewardfulParams,
        }
        checkRewardfulStatus();

        setTrackedUser(userData);
        setUserTrackingMetadata(userTrackingDetails, userData);
      } catch (e) {
        Logger.error(e)
        handleError();

      }
    } else {
      handleError();
    }
  };

  const handleError = () => {
    Logger.error(`Error refreshing session. Redirecting to login screen.`);
    localStorage.clear();
    if (window.location.pathname !== '/auth/login') {
      //... rest of your logic
      // window.location.href = '/auth/login'
      logout({ returnTo: window.location.origin });

    }
  };


  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        getUserData()
        return
      }

      localStorage.clear();
      if (window.location.pathname !== '/auth/login') {
        // window.location.href = '/auth/login'
        var loginHint: any = 'login'
        const savedObject: any = {}

        try {
          const url: URL = new URL(window.location.href);
          const params: URLSearchParams = url.searchParams;
          // get target key/value from URLSearchParams object
          loginHint = params.get('t');

          savedObject.screen_hint = loginHint ? loginHint : 'login'

          // add utm params to savedObject
          params.forEach((value, key) => {
            if (key.startsWith('utm_')
              || key.startsWith('fbclid')
              || key.startsWith('ttclid')) {
              savedObject[key] = value;

              // add these to localStorage
              localStorage.setItem(key, value);

            }
          });

        } catch (e) {
          Logger.error(e)
        }
        loginWithRedirect(savedObject)
      }
    }
  }, [isLoading]);



  async function checkFBStatus() {

    var getFbStatus = new Promise(async (resolve, reject) => {
      const fb = FB();
      if (!fb) {
        const message = 'FB SDK with issues. Contact Support.';
        Sentry.captureException(message);
        resolve(false)
      }
      fb.getLoginStatus((response: any) => {
        if (response && response.status == 'connected') {
          Logger.info('FB User is authorized');
          resolve(true)
        } else {
          Logger.warn('FB User is not authorized');
          resolve(false)
        }
      }, true)
    })

    var loggedIn = await getFbStatus
    // check for user status
    if (loggedIn) {
      try {

        var userData = await Axios.get('/user/me')
        var data = userData.data.data

        if (data && data.facebook && data.facebook.fbTokenValid) {
          // we're good to go
          Logger.debug("Access token is good to go")
        } else {
          // get the FB access token and send to server to save
          const authResp = FB().getAuthResponse()
          if (authResp.accessToken) {
            await Axios.post('/user/me/connect', {
              service: 'facebook',
              value: authResp.accessToken
            })
          }
          Logger.debug("Access token is refreshed, now good to go")
        }
      } catch (e) {
        Logger.warn("Error getting user info. Access token check has issues")
      }
    }
  }

  return (
    <>
      {isLoading || !auth0Token || showLoading  ? (
        <Loader />
      ) : (
        <>
          {auth0Token && userData ? <PostAuthRoutes
            setLoading={setLoading}
            userData={userData}
            setUserData={setUserData}
          /> : <AuthLayout />}
        </>
      )}
    </>
  )
}

export default IndexLayout;
