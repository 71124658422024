import { FunctionComponent, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import CircularStep from "../CircularStep";
import { StyledLabel, Card, useStyles } from "./styles";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Container } from 'styles/shared';
import { SpendBudgetContext } from "Hooks/BudgetTabSpendContext";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import getSymbolFromCurrency from "currency-symbol-map";
import { MONTHLY_PRO_PLAN_PRICE } from '../../../../../constants';

interface Props {
  budget: number;
  isProUser: boolean;
  adAccountName: string;
}

const format = (budget: number, showDigits?: boolean) => {
  const numberOfDigits = showDigits ? 2 : 0

  return budget.toLocaleString("en-US", {
    minimumFractionDigits: numberOfDigits,
    maximumFractionDigits: numberOfDigits,
  })
}

const BudgetCard: FunctionComponent<Props> = ({ budget, isProUser, adAccountName }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const { currentBrand } = useContext(CurrentBrandContext)
  const freeTrialOver = currentBrand?.freeTrialOver
  const classes = useStyles();
  const adsComission = 0
  const proFee = !isProUser && freeTrialOver ? MONTHLY_PRO_PLAN_PRICE : 0;
  const { currency } = useContext(SpendBudgetContext)


  return (
    <Card borderRadius={isMobile ? undefined : "12px"}>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <CircularStep step={3} />
            </Grid>
            <StyledLabel fontSize={18} fontWeight={600}>
              Budget
            </StyledLabel>
          </Grid>
        </Grid>
        <Container className={clsx(classes.space, classes.border)}
          display="flex" flexDirection="row"
          justifyContent="space-between">
          <Container width="75%" justifyContent="flex-start">
            <StyledLabel fontSize={16} fontWeight={600}>
              Marketing Budget
            </StyledLabel>
            <StyledLabel color="#707070" fontSize={14} fontWeight={400}>
              This amount will be charged directly to your Meta Ad Account ({adAccountName}). {currency !== 'USD' && `Your ad account's currency is set to ${currency}.`}
            </StyledLabel>
          </Container>

          <Container width="25%" display="flex" justifyContent="flex-end">
            <StyledLabel fontSize={16} fontWeight={400}>
              {getSymbolFromCurrency(currency)}
              {format(budget - adsComission * budget, true)}
              
            </StyledLabel>
          </Container>

        </Container>

        {/* {!isProUser && (
          <Grid item className={clsx(classes.space, classes.border)}>
            <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
              <Grid item>
                <Grid container direction="column">
                  <Grid container direction="row">
                    <StyledLabel fontSize={16} fontWeight={600}>
                      Symphony Pro {freeTrialOver ? '(Monthly Subscription)' : '(14-day Free Trial)'}
                    </StyledLabel>
                    <button onClick={openIntercom}>
                      <SymphonyTooltip
                        className="ml-2 w-6 h-6"
                        title="Click here to learn more."
                        arrow
                      >
                        <QuestionMarkIcon height={24} width={24} />
                      </SymphonyTooltip>
                    </button>
                  </Grid>
                  <Grid item>
                    <StyledLabel color="#707070" fontSize={12} fontWeight={400}>
                      To run AI-powered marketing campaigns, sign up for Symphony Pro. You’ll be able to cancel anytime.
                    </StyledLabel>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <StyledLabel fontSize={16} fontWeight={400}>
                  {getSymbolFromCurrency(currency)}
                  {format(proFee, true)}
                </StyledLabel>
              </Grid>
            </Grid>
          </Grid>
        )} */}
        {/* <Grid item className={clsx(
          classes.space,
          classes.noPaddingX)}
          style={{
            paddingTop: 0,
          }}>
          <Grid container direction="column">

            <Grid item>
              <TotalBannerContainer
                // marginTop={isProUser && !isOnOldPlan ? 16 : 0}
              >
                <StyledLabel fontSize={16} fontWeight={600}>
                  Total
                </StyledLabel>
                <StyledLabel fontSize={18} fontWeight={500}>
                  {proTierTotal && `${getSymbolFromCurrency(currency)}${format(isProUser ? proTierTotal : freeTierTotal)}`}
                </StyledLabel>
              </TotalBannerContainer>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </Card>
  );
};

export default BudgetCard;
