/* eslint-disable react-hooks/exhaustive-deps */
import millify from "millify";
import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { LoadingIndicator } from "components/Loader";
import RowItem from "components/shareable/RowItem";
import Axios from "helpers/Interceptor";
import {
  Indicator as SpotifyIndicator,
  getSecondaryLabel,
  getPercentLabelColor,
} from "components/shareable/BrandConnectorIndicator";
import { initialStreamsData } from "../SongAds/CampaignDetails";
import SpotifyIcon from "assets/images/streaming/square-icons/spotify-sqr.svg";
import {
  StreamsData,
  Platforms,
  ConnectorIndicatorTypes,
  PlaylistData,
  PlaylistDatav2
} from "types/global";
import {
  getIndicatorPercentage,
  getIndicatorValue,
} from "../CampaignDetails/GeneralPerformance";
import ImpactChart from "components/shareable/ImpactChart/index";
import { getCountIndicatorProps } from "../CampaignDetails/ImpactStats/utils";
import moment from "moment";
import { track } from "analytics";
import UnlockDataBanner from "components/shareable/UnlockDataBanner";
import UnlockPresavesByDayImage from "assets/images/unlock-presaves-by-day.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
// import ChartLabelDesktopView from "./DetailsComponents/ChartLabelDesktopView";
// import ChartLabelResponsiveView from "./DetailsComponents/ChartLabelResponsiveView";
import Intercom from "helpers/Intercom";
import { SymphonyTooltip } from "styles/shared";
import QuestionMarkIcon from "assets/images/question-mark.svg";
import { Tab, Tabs } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { CampaignDetailsContainer } from "../SongAds/styles";
import PlaylistingOverviewCampaignCard from "../Components/OverviewCampaignCard/playlisting";
import { playlistingRejectionIdToText } from "./utils";
import { DEFAULT_CURRENCY } from "../constants";
import getSymbolFromCurrency from "currency-symbol-map";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import { FacebookCampaignSimpleBanner } from "../Components/FacebookCampaignIssuesBanner/simple-banner";

const getSpotifyStreamsIndicatorProps = (data: StreamsData) => {
  const value = getIndicatorValue(Platforms.streams, data);
  const percent = getIndicatorPercentage(Platforms.streams, data);
  const percentLabelColor = getPercentLabelColor(value);
  const showPercentIndicatorIcon = percent !== 0;
  const secondaryLabel = getSecondaryLabel(value, "total streams");

  return {
    icon: SpotifyIcon,
    alt: "spotify-icon.svg",
    mainLabel: "Spotify streams up",
    percentLabelColor,
    showPercentIndicatorIcon,
    secondaryLabel,
    percent,
    value,
    show: value > 0,
  };
};

const getImpactChartData = (
  spotifyStreamsData: StreamsData,
  campaignStartDate: string
) => {
  const chartData = spotifyStreamsData.data.data?.map(
    ({ datetime, views_stats }) => {
      const count = views_stats ? Number(views_stats) : 0;
      const splitted = datetime?.split("T")[0];
      return {
        type: ConnectorIndicatorTypes.SPOTIFY_STREAMS,
        day: moment(splitted).format("ddd"),
        count,
        date: datetime,
        campaignStartDate,
        valueOf: moment(splitted).valueOf(),
      };
    }
  );

  return chartData;
};

const getSpotifyStreams = async (args: {
  currentBrand: any;
  campaignDetails: any;
  contentSlug?: string;
  subtract?: boolean;
  setSpotifyStreamsData: Dispatch<SetStateAction<StreamsData>>;
}) => {
  try {
    const {
      currentBrand,
      campaignDetails,
      contentSlug,
      subtract,
      setSpotifyStreamsData,
    } = args;
    const createdAt = campaignDetails?.createdAt;
    const brandSlug = currentBrand.slug;
    const formattedStartDate = subtract
      ? moment(createdAt).subtract(3, "days").format("YYYY-MM-DD")
      : moment(createdAt).format("YYYY-MM-DD");
    const formattedEndDate = moment(createdAt)
      .add(45, "days")
      .format("YYYY-MM-DD");
    const spotifyStreamsPath = `dashboard/brand/${brandSlug}/content/${contentSlug}/spotify/streams?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    const responseSpotifyStreams = await Axios.get(spotifyStreamsPath);
    const spotifyStreamsData = await responseSpotifyStreams?.data;

    if (spotifyStreamsData) setSpotifyStreamsData(spotifyStreamsData);
  } catch (error) {
    //TODO: Add error handler
    console.log("Error on Playlisting/CampaignDetails component: ", error);
  }
};

const getCampaignDetails = async (
  id: string,
  setCampaignDetails: Dispatch<SetStateAction<any>>
) => {
  try {
    const path = `/campaign/${id}`;
    const response = await Axios.get(path);
    const data = response?.data?.data;
    if (data) setCampaignDetails(data);
  } catch (error) {
    //TODO: Add error handler
    console.log("Error on Playlisting/CampaignDetails component: ", error);
  }
};

export default function CampaignDetails(props: any) {
  const theme = useTheme()
  const hideBannerImage = useMediaQuery(theme.breakpoints.down(701))
  const isResponsiveChartLabel = useMediaQuery(theme.breakpoints.down(501))
  const showWeeklyXTicks = useMediaQuery(theme.breakpoints.down(601))

  const { selectedCampaign, currentBrand, isProUser, handleGoBack } = props
  const { campaign_metadata, id, createdAt } = selectedCampaign
  const { content, price_data, currency } = campaign_metadata
  const currencyCode = currency?.code || DEFAULT_CURRENCY

  const {
    name: contentName,
    thumbnail_url: contentThumbnail,
    slug: contentSlug,
  } = content

  const [isLoading, setLoading] = useState(true)
  const [playlistsData, setPlaylistsData] = useState<PlaylistDatav2[] | null>(null)
  const [spotifyStreamsChartData, setSpotifyStreamsChartData] = useState<StreamsData>(initialStreamsData)
  const [spotifyStreamsIndicatorsData, setSpotifyStreamsIndicatorsData] = useState<StreamsData>(initialStreamsData)
  const [campaignDetails, setCampaignDetails] = useState<any>({
    createdAt: "",
    status: "IN_REVIEW",
    campaign_metadata: {
      playlists: [],
      campaign_version: 'v1',
      note: null,
      price_data: {}
    }
  })
  const [overallCampaignStatus, setOverallCampaignStatus] = useState<any>({
    status: "pending",
    note: null,
  })

  const campaignStartDate = moment(campaignDetails?.createdAt).format("YYYY-MM-DD")

  // tabs
  const [playlistsView, setPlaylistsView] = useState<string>('active')

  useEffect(() => {
    if (selectedCampaign) {
      track("Campaign Details Viewed", {
        type: "get_playlisted_id",
        brand_id: currentBrand.id,
        brand_name: currentBrand.name,
        brand_slug: currentBrand.slug,
        content_name: content.name,
        content_slug: content.slug,
        content_id: content.id,
        tier: price_data.tier,
        price: price_data.price,
      });
    }
    if (selectedCampaign && currentBrand) {
      // getPlaylistStatuses();
      getCampaignDetails(id, setCampaignDetails);
    } else {
      //TODO: Add error handler
      console.log("could not get all data");
    }
  }, []);

  useEffect(() => {
    if (campaignDetails.createdAt) {
      const mainArgs = {
        currentBrand,
        contentSlug,
        campaignDetails,
      };

      const {
        campaign_metadata
      } = campaignDetails

      const {
        playlists,
        campaign_version = 'v1'
      } = campaign_metadata

      if ((!playlists && campaignDetails.status === 'ACTIVE')
        || campaign_version !== 'v2') {
        getPlaylistStatuses()
      } else {
        setPlaylistsData(playlists)
        const totalFollowers = getTotalActiveFollowers(playlists)
        switch (campaignDetails.status) {
          case 'DENIED':

            const msg = campaign_metadata && campaign_metadata.note ? playlistingRejectionIdToText(campaign_metadata.note) : null
            setOverallCampaignStatus({
              status: 'denied',
              note: msg
            })
            break;
          case 'IN_REVIEW':
            setOverallCampaignStatus({
              status: "in_review"
            })
            break;

          // TODO: this is where we check for playlists
          case 'ACTIVE':
            if (totalFollowers > price_data.tier) {
              setOverallCampaignStatus({
                status: "active",
                note: `Your song has been successfully accepted by playlists with a total of ${totalFollowers.toLocaleString('en')} followers!`,
              });
            } else {
              setOverallCampaignStatus({
                status: "active",
                note: "Your song has been accepted by our playlisting team! As playlists are being pitched, you'll be able to see updates here.",
              });
            }

            break;
          case 'FINISHED':
            setOverallCampaignStatus({
              status: "finished",
              note: `Your song has been successfully accepted by playlists with a total of ${totalFollowers.toLocaleString('en')} followers! You can review how long your song will be playlisted in the list below.`,
            });
            break;
          case 'PROCESSING':
            setOverallCampaignStatus({
              status: "processing",
              note: "Your payment is being processed. Once it's complete, we'll start pitching your song to playlists.",
            });
            break;
        }

      }

      getSpotifyStreams({
        ...mainArgs,
        subtract: true,
        setSpotifyStreamsData: setSpotifyStreamsChartData,
      });
      getSpotifyStreams({
        ...mainArgs,
        subtract: false,
        setSpotifyStreamsData: setSpotifyStreamsIndicatorsData,
      });
      setLoading(false);
      console.log("got all data");
    } else {
      //TODO: Add error handler
      console.log("could not get all data");
    }
  }, [campaignDetails.createdAt]);

  async function getPlaylistStatuses() {
    try {
      const campaigns = await Axios.get(
        `/campaign/brand/${currentBrand.id}/${selectedCampaign.id}/status`
      );

      const { data } = campaigns
      if (data.isError) throw new Error(data.message)

      const playlistsData = data.data
      if (playlistsData) {
        setPlaylistsData(playlistsData);
      }

      if (!playlistsData?.length) {
        setOverallCampaignStatus({
          status: "in_review"
        });
      } else {
        // summ up total followers
        const totalFollowers = playlistsData.reduce(
          (prevValue: any, currentValue: any) => {
            return prevValue + currentValue.playlistFollowers;
          },
          0
        );

        const tier = price_data.tier;
        if (totalFollowers < tier) {
          setOverallCampaignStatus({
            status: "active",
            note: "Your song has started being placed to our network of vetted playlists. As your song is added to more playlists, you'll be able to see updates here.",
          });
        } else if (totalFollowers >= tier) {
          setOverallCampaignStatus({
            status: "active",
            note: null,
          });
        }
      }
    } catch (e: any) {
      setOverallCampaignStatus({
        status: campaignDetails.status.toLowerCase(),
        note: "Campaign status may not be up-to-date. Please refresh the page or check back later.",
      });
    }
  }

  function adsStatusText() {
    switch (overallCampaignStatus.status) {
      case "pending":
        return undefined;
      case "active":
        return "active"
      case "error":
        return "issues"

      case "in_review":
        return "in_review"
      case "live_with_issues":
        return "live"
      case "denied":
        return "declined"
      case "finished":
        return "pitched"
      case "processing":
        return "processing"
      default:
        return undefined
    }
  }

  function showAdsStatusMessage() {

    const framework = (props: {
      title?: string,
      description: string | JSX.Element,
      type?: "success" | "warning"
    }) => {
      const {
        title,
        description,
        type
      } = props

      return (<div className="py-5 px-5 pb-0 lg:px-10">

        <div className="rounded-md"
          style={{
            backgroundColor: type === 'success' ? "#ebfaeb" : "#FDF6EC"
          }}>
          <div className="p-5">
            {title && (<p style={{
              color: type === 'success' ? "#07C806" : "#754600"
            }}>{title}</p>)}
            <p style={{
              color: "black"
            }}>
              {description}
              {/* <button
                className="font-medium"

                style={{
                  color: "#8800FF"
                }}
                onClick={() => {
                  Intercom.openInstagramBusinessAccountArticle()
                }}>Learn how to switch to a Business Account &gt;</button> */}
            </p>
          </div>

        </div>
      </div>)
    }
    switch (overallCampaignStatus.status) {
      case "pending":
        return null;

      case "active":
        if (!overallCampaignStatus.note) {
          return null;
        } else {
          return framework({
            description: overallCampaignStatus.note,
            type: "success"
          })
        }
      case 'finished':
        return framework({
          description: overallCampaignStatus.note,
          type: "success"
        })

      case "error":
        return (
          <div className="py-4 rounded-sm bg-red-500 text-white py-1 text-md px-3 text-center">
            <p className="w-8/12 m-auto">{overallCampaignStatus.note}</p>
          </div>
        );
      case "in_review":
        return framework({
          title: "Your playlisting campagn is being reviewed.",
          description: "Our curator team is reviewing your campaign for eligibility. We'll let you know if we have any questions and start pitching automatically once your campaign is approved."
        })

      case "live_with_issues":
        return (
          <div className="py-4 rounded-sm bg-green-500 text-white py-1 text-md px-3 text-center">
            <p className="w-8/12 m-auto">{overallCampaignStatus.note}</p>
          </div>
        );
      case "denied":
        return framework({
          title: "We weren't able to playlist your song.",
          description: (
            <>
              <span>Unfortunately we weren't able to find any playlists this time around that would drive meaningful engagement.
                You will be sent a full refund of {getSymbolFromCurrency(currencyCode)}{campaign_metadata.price_data.price.toLocaleString('en', {
                  minimumFractionDigits: 2
                })}.
              </span>
              {overallCampaignStatus.note && (
                <>
                  <br />
                  <br />
                  <span>Here are campaign rejection notes from your curators:</span>
                  <br />
                  <span>"{overallCampaignStatus.note}"</span>
                  <br />
                  <br />
                  <span>We recommend submitting different songs to see which one may be a best fit. Over 95% of playlisting campaigns are accepted and exceed their goal.</span>
                  <br />
                  <button
                    className="mt-2 font-medium"

                    style={{
                      color: "#8800FF"
                    }}
                    onClick={() => {
                      Intercom.openPlaylistingArticle()
                    }}>Learn how our curated pitching process works &gt;</button>
                </>

              )}
            </>
          ),
          type: "warning"
        })
    }
  }

  function returnColorStylingByStatus(status: string) {

    if (status === 'Pitching') return 'bg-green-200 text-black'
    else if (status === 'Declined') return 'bg-yellow-400'
    else if (status === 'Accepted') return 'bg-green-400 text-white'
  }

  function returnPlaylistsView() {

    let passedMsg = null
    if (playlistsData?.filter((o: PlaylistDatav2) => {
      return o.playlistStatus === 'Declined'
    }).length === 0) {
      // there are 0 declined items
      passedMsg = `Our team reaches out to a variety of high-engagement playlists as part of your pitch. Any playlists that don't accept your song can be tracked here.`

    } else {
      passedMsg = `Our team pitched these playlists, but we weren't able to secure a playlisting spot. We'll keep pitching until we secure some good playlists.`
    }



    return (
      <div className="px-2 md:px-6 h-120">
        <ul className="max-h-80 overflow-auto">
          {playlistsView === 'passed' ? (
            <li>
              <div className="sy-card">

                <div className="rounded-md"
                  style={{
                    backgroundColor: "#FDF6EC"
                  }}>
                  <div className="p-5">
                    <p style={{
                      color: "#754600"
                    }}>{passedMsg}</p>

                  </div>

                </div>
              </div>
            </li>
          ) : (null)}
          {playlistsData
            ?.sort((a: PlaylistDatav2, b: PlaylistDatav2) => {
              return b.playlistFollowers - a.playlistFollowers;
            })
            .sort((a: PlaylistDatav2, b: PlaylistDatav2) => {
              if (a.playlistStatus === "Accepted" && b.playlistStatus !== "Accepted") {
                return -1;
              } else if (a.playlistStatus !== "Accepted" && b.playlistStatus === "Accepted") {
                return 1;
              }

              if (a.playlistStatus === "Pitching" && b.playlistStatus !== "Pitching") {
                return -1;
              } else if (a.playlistStatus !== "Pitching" && b.playlistStatus === "Pitching") {
                return 1;
              }

              else {
                return 0;
              }
            })
            .filter((a: PlaylistDatav2) => {
              if (playlistsView === 'active') {
                return (a.playlistStatus === 'Pitching' || a.playlistStatus === 'Accepted') || !a.playlistStatus
              } else if (playlistsView === 'passed') {
                return a.playlistStatus === 'Declined';
              }

            })
            .map((item: PlaylistDatav2) => {

              const totalRemainingDays = item.playlistStatus === 'Accepted'
                && item.addedAt
                && item.estimatedRemovalDate ? dayjs(item.addedAt).add(30, 'days').diff(dayjs(), 'days') : 0

              return (
                <RowItem
                  key={item.playlistUrl}
                  // secondRowContent={

                  // }
                  imageStyles={`h-6`}
                  name={(
                    <div className="flex flex-row justify-center items-center">
                      {item.playlistImages && (<div className="w-12 h-12">
                        <img className="flex-shrink-0 w-12 h-12 rounded-md" src={item.playlistImages!.url} />
                      </div>)}
                      <div className="ml-2">
                        <div className="flex flex-col md:flex-row items-start md:items-center justify-center">
                          <div>{item.playlistName}</div>
                          {item.playlistStatus && (
                            <div className={`${clsx(returnColorStylingByStatus(item.playlistStatus), "text-xs py-1 px-2 rounded-md md:ml-2 uppercase")}`}>
                              <div>{item.playlistStatus}</div>
                            </div>
                          )}

                        </div>
                        <div className="mt-1 flex flex-row items-center">
                          <a
                            target="_blank"
                            href={item.playlistUrl}
                            className="text-green-500 text-xs"
                            rel="noreferrer"
                          >
                            View on Spotify
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                  showRightValue={true}
                  rightValue={(
                    <div className="flex items-end justify-content flex-col text-right">
                      <p>{item.playlistFollowers.toLocaleString(
                        "en"
                      )} Followers</p>
                      {item.playlistStatus === 'Accepted' &&
                        item.playlistPosition && (<p className="text-sm">Position: {item.playlistPosition}{item.totalTracks && `/${item.totalTracks}`}</p>)}
                      {item.playlistStatus === 'Accepted' &&
                        item.addedAt && (<p className="text-sm">Added On: {dayjs(item.addedAt).format("MMM D, YYYY")}</p>)}
                      {item.playlistStatus === 'Accepted' && item.estimatedRemovalDate && item.addedAt ?
                        totalRemainingDays &&
                          totalRemainingDays > 0 ? (<p className="text-sm">~{totalRemainingDays} Day{totalRemainingDays === 1 ? '' : 's'} Left on Playlist</p>) : (<p className="text-sm text-green-600">Playlisting Period Completed</p>) : null}
                    </div>
                  )}
                />
              );
            })}
        </ul>
      </div>
    )
  }

  function getTotalActiveFollowers(playlists?: PlaylistDatav2[]) {
    var playlistsToView = playlistsData ? playlistsData : playlists
    const acceptedPlaylists = playlistsToView?.filter((playlist: PlaylistDatav2) => (!playlist.playlistStatus || playlist.playlistStatus === 'Accepted'))
    const totalFollowers = acceptedPlaylists ? acceptedPlaylists!
      .reduce(
        (prevValue: any, currentValue: any) => {
          return prevValue + currentValue.playlistFollowers;
        },
        0
      ) : 0;

    return totalFollowers
  }

  function showPlaylistingData() {
    let acceptedPlaylists = playlistsData?.filter((playlist: PlaylistDatav2) => (!playlist.playlistStatus || playlist.playlistStatus === 'Accepted'))
    if (!acceptedPlaylists) acceptedPlaylists = []

    const totalFollowers = getTotalActiveFollowers(acceptedPlaylists)
    const spotifyStreamsIndicatorProps = getSpotifyStreamsIndicatorProps(
      spotifyStreamsIndicatorsData
    );

    const { value, percent } = spotifyStreamsIndicatorProps;

    const { src, alt, mainLabel, secondaryLabel } = getCountIndicatorProps(
      value,
      ConnectorIndicatorTypes.SPOTIFY_STREAMS
    );

    const impactChartData = getImpactChartData(
      spotifyStreamsChartData,
      campaignStartDate
    );

    if (overallCampaignStatus.status === 'denied') return null

    return (
      <>
        <div className="px-6 lg:px-10 py-5 mt-2">
          <p className="text-lg font-semibold">Performance</p>
        </div>
        <div className="px-6 lg:px-10 py-5 border-b border-t flex flex-row justify-between">
          <div className="w-5/12">
            <p className="text-md font-normal ">Total Followers Reached</p>
            <p className="text-xl font-semibold mt-2">
              {totalFollowers?.toLocaleString("en")}
            </p>
          </div>
          <div className="w-3/12">
            <p className="text-md font-normal ">Total Playlists</p>
            <p className="text-xl font-semibold mt-2">
              {acceptedPlaylists?.length.toLocaleString("en")}
            </p>
          </div>
          <div className="w-5/12 grid justify-items-end">
            {
              isProUser && spotifyStreamsIndicatorProps.show &&
              <SpotifyIndicator {...spotifyStreamsIndicatorProps} />
            }
          </div>
        </div>
        <div className="px-6 lg:px-10 py-5 mt-2">
          <p className="text-lg font-semibold  flex">Playlists <button className="ml-1.5 w-5 h-5"
            onClick={() => {
              Intercom.openPlaylistingArticle()
            }}>
            <SymphonyTooltip className="block w-6 h-6 cursor-pointer" title={
              "Click to learn more about how the playlisting process works."
            } arrow>
              <img
                className="w-5 h-5"
                alt="question-mark-icon.svg"
                src={QuestionMarkIcon}
              />
            </SymphonyTooltip>
          </button></p>
          <p className="text-sm text-gray-500">
            These are the playlists we're pitching for your campaign.
          </p>
        </div>
        {playlistsData && playlistsData.length > 0 ? (
          <>
            <Tabs
              scrollButtons="auto"
              variant="scrollable"
              value={playlistsView}
              onChange={(event, newValue) => setPlaylistsView(newValue)}
              aria-label="Settings Options Selector"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#8800FF"
                }
              }}>

              <Tab
                disableRipple
                value={'active'}
                label={<span
                  className={"capitalize font-medium text-base px-4 py-2"}>Active</span>} />
              <Tab
                disableRipple
                value={'passed'}

                label={<span
                  className={"capitalize font-medium text-base px-4 py-2"}>Passed</span>} />

            </Tabs>
            {returnPlaylistsView()}

          </>) : (
          <div className="sy-card pt-0">
            <div className="rounded-md"
              style={{
                backgroundColor: "#FDF6EC"
              }}>
              <div className="p-5">
                <p style={{
                  color: "#754600"
                }}>Once our playlisting team starts pitching to playlists, you'll be able to track the status of each playlist pitch here.</p>

              </div>

            </div>
          </div>
        )}

        {Boolean(impactChartData.length) && (
          <div className="px-6 lg:px-10 py-5 border-b border-t flex flex-col justify-between">
            <div className="w-full">
              <p className="text-lg font-semibold flex flex-row items-center gap-1">Impact<UpgradeToProChip type="gem" color="purple" height={18} width={18} /></p>
            </div>
            {isProUser ? (
              <div className="w-full mt-4">
                <ImpactChart
                  {...{ campaignStartDate }}
                  width="100%"
                  weekly={
                    (impactChartData.length >= 14 && showWeeklyXTicks) ||
                    impactChartData.length >= 28
                  }
                  margin={{ top: 8, left: -10, right: 15 }}
                  chartData={impactChartData}
                
                />
              </div>
            ) : (
              <>
                <div className="w-full mt-4">
                  <UnlockDataBanner
                    source="Playlisting Campaign Details - Impact Stats"
                    title="Track exactly how your marketing is growing your fanbase"
                    description="Go Pro to see how playlisting is growing your Spotify streams"
                    image={UnlockPresavesByDayImage}
                    hideImage={hideBannerImage}
                  />
                </div>
                <div className="w-full mt-4">
                  <img src={require("../../../../assets/images/campaignDetails/impactBlurredSpotify.png").default} />
                  <img src={require("../../../../assets/images/campaignDetails/impactBlurredChart.png").default} />
                </div>
              </>
            )}
          </div>
        )}
      </>
    );
  }



  return (
    <CampaignDetailsContainer backgroundColor="#F3F5F6">
      <PlaylistingOverviewCampaignCard
        isLoading={isLoading}
        goBack={handleGoBack}
        name={contentName}
        thumbnail={contentThumbnail}
        campaignStatus={campaignDetails && campaignDetails.status ? campaignDetails?.status.toLowerCase() : undefined}
        campaignStatusLabel={overallCampaignStatus && overallCampaignStatus.status ? adsStatusText() : undefined}
      />

      {overallCampaignStatus.status === 'processing' && (
        <FacebookCampaignSimpleBanner
          title="Your payment is still being processed."
          description="Once your payment is successfully processed, your campaign will be submitted directly. You'll only be charged if your payment is processed fully, otherwise you won't be charged for your campaign."
        />
      )}

      <div className=" bg-white shadow-none lg:shadow-lg pb-6 middle-wrapper
      justify-start">

        <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
          <p className="text-lg font-semibold">Goal</p>
          <p className="text-lg text-right">
            {price_data.tier.toLocaleString("en")} Followers across Playlists
          </p>
        </div>
        <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
          <p className="text-lg font-semibold">Campaign Submitted</p>
          <p className="text-lg text-right">
            {dayjs(createdAt).format("MMM DD, YYYY")}
          </p>
        </div>
        {showAdsStatusMessage()}

        {/* <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
        <p className="text-lg font-semibold">Budget</p>
        <p className="text-lg">${millify(price_data.price)}</p>
      </div> */}
        {
          isLoading ? (
            <div className="mx-auto my-6 flex-col flex items-center ">
              <LoadingIndicator height="100px" color="black" />
              <p className="mt-1 text-center">Loading Campaign Data...</p>
            </div>
          ) : (
            showPlaylistingData()
          )
        }
      </div>
    </CampaignDetailsContainer >
  );
}
