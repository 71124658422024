import { makeStyles, Theme } from "@material-ui/core"
import styled from "styled-components"
import { Container } from "styles/shared"
import { SystemColors } from "types/globalStyles"

const useStyles = makeStyles((theme: Theme) => ({
  warningIcon: {
    marginTop: theme.spacing(0.5),
    "& path": {
      stroke: SystemColors.WARNING_COLOR,
    },
  },
  positiveIcon: {
    marginTop: theme.spacing(0.5),
    "& path": {
      stroke: SystemColors.GREEN_COLOR,
    },
  },
  root: {
    height: 8,
    borderRadius: 24,

    '& .MuiLinearProgress-dashed': {
      display: "none"
    }
  },
  colorPrimary: {
    backgroundColor: "#E4900D40",
  },
  barColorPrimary: {
    backgroundColor: SystemColors.WARNING_COLOR,
  },
  colorSecondary: {
    backgroundColor: SystemColors.ERROR_COLOR,
  },

  zeroState_BarColorPrimary: {
    backgroundColor: SystemColors.GREEN_COLOR,
  },
  zeroState_BarColorSecondary: {
    backgroundColor: SystemColors.GREEN_COLOR_ON_GREEN,
  },
  
}))

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

interface CardProps {
  backgroundColor?: SystemColors | string | null
}
export const Card = styled.div<CardProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  border-radius: 16px;
  background-color: ${({ backgroundColor }) => backgroundColor || SystemColors.DARK_WARNING};
`

export const FlexContainer = styled(Container)`
  display: flex;
`

export const ProgressContainer = styled.div`
  width: 100%;
  margin-top: 16px;
`

export const FeeContainer = styled.div`
  margin-top: 8px;
  padding: 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const TotalContainer = styled.div`
  padding: 14px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ededed;
  border-radius: 6px;
`

export default useStyles
