import Axios from "helpers/Interceptor"
import * as Sentry from "@sentry/react"
import axios from 'axios'

interface TikTokValue {
  url: string;
  username: string;
  picture?: string;
}

export const updateInBrand = async (
  artist: { slug: string; id: string },
  value: TikTokValue,
) => {
  try {
    const response = await Axios.put(`/brand/${artist.slug}/connect`, {
      id: artist.id,
      name: artist.slug,
      service: "tiktok",
      value,
    })

    const data = response.data.data

    return { data, error: null }
  } catch (error) {
    console.log("error creating brand", error)
    Sentry.captureException(error)
    return { data: null, error }
  }
}


export const getTiktokPicture = async (username: string) => {
  try {

    const response = await Axios.get(`platform/getTiktokPicture/${username}`, {
      timeout: 15 * 1000,
    })

    return response.data.data

  } catch (e) {
    console.error('Error scraping TikTok: ', e)
    return null
  }
}

