import React, { FunctionComponent, useMemo } from "react";
import { ConnectorIndicatorTypes, IObjectKeys } from "types/global";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine,
} from "recharts";
import {
  getChartDivider,
  getBorderClassName,
  tooltipContentFormatter,
  tooltipLabelFormatter,
  xTickFormatter,
  yTickFormatter,
} from "./utils";
import {
  findClosest,
  findMinMax,
  findMinMaxCases,
} from "pages/post-auth/MarketingPage/CampaignDetails/ImpactStats/utils";
import moment from "moment";
import { calculateAverage } from "pages/post-auth/MarketingPage/CampaignDetails/ImpactStats";

interface Props {
  index?: number;
  isCompact?: boolean;
  width?: string;
  margin?: {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  };
  mainLabelComponent?: string | JSX.Element;
  chartData: {
    type: ConnectorIndicatorTypes;
    day: string;
    count: number;
    date: string;
    campaignStartDate: string;
    valueOf: number;
  }[];
  divider?: {
    top?: boolean;
    bottom?: boolean;
  };
  campaignStartDate?: string;
  customMainLabelComponents?: {
    changeAll?: boolean;
    components: {
      replacePosition: number;
      component: JSX.Element;
      props: IObjectKeys;
    }[];
  };
  weekly?: boolean
  showAverage?: boolean
  averageBeforeCampaign?: number;
  averageDuringCampaign?: number;
  isFirstDay?: boolean;
}

interface CustomizedDotProps {
  cx?: string | number;
  cy?: string | number;
  payload?: {
    campaignStartDate: string;
    count: number;
    date: string;
    day: string;
    type: ConnectorIndicatorTypes;
    valueOf: number;
  };
}

enum ReferenceLinePositions {
  start = "start",
  end = "end",
}

const { start, end } = ReferenceLinePositions;

const CustomizedDot: FunctionComponent<CustomizedDotProps> = ({
  cx: centerX,
  cy: centerY,
  payload,
}: CustomizedDotProps) => {
  let [cx, cy] = [centerX || 0, centerY || 0];
  const splitted = payload?.campaignStartDate.split("T")[0];
  const campaignStartDateValueOf = moment(splitted).valueOf();
  const showDot = campaignStartDateValueOf === payload?.valueOf;

  if (typeof cx === "string") cx = Number(centerX);
  if (typeof cy === "string") cy = Number(centerY);

  return (
    <React.Fragment>
      {showDot && (
        <svg
          x={cx - 6.5}
          y={cy - 6.5}
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="6.5" cy="6.5" r="6.5" fill="#07C806" />
        </svg>
      )}
    </React.Fragment>
  );
};

interface CustomReferenceLineLabelProps {
  position?: ReferenceLinePositions;
  viewBox: {
    height: number;
    width: number;
    x: number;
    y: number;
  };
  value?: string | number | null;
  isHorizontal?: boolean;
  label?: string;
  index: number;
  totalLabels: number;
  yOffset?: number;
}

const getReferenceLineLabelPosition = (
  x: number,
  y: number,
  index: number,
  totalLabels: number,
  position?: ReferenceLinePositions,
  isHorizontal?: boolean,
  yOffset: number = 0
) => {
  if (isHorizontal) {
    if(yOffset > 0) {
      return { x: x + 10, y: y + (yOffset) };
    }
    return { x: x + 10, y: y - 35 };
  }
  if (position === start) return { x: x - 100, y };
  if (position === end) return { x: x + 10, y };
  return { x, y };
};

const CustomReferenceLineLabel: FunctionComponent<
  CustomReferenceLineLabelProps
> = ({ viewBox, value, position, isHorizontal, label, index, totalLabels, yOffset = 0 }: CustomReferenceLineLabelProps) => {
  const { height, width, x, y } = viewBox;
  const { x: fixedX, y: fixedY } = getReferenceLineLabelPosition(x, y, index, totalLabels, position, isHorizontal, yOffset);

  const formatValue = (val: string | number | null | undefined) => {
    if (val === null) return null;
    if (typeof val === 'number' || (typeof val === 'string' && !isNaN(Number(val)))) {
      return Number(val).toLocaleString('en-US', { maximumFractionDigits: 0 });
    }
    return val;
  };

  const formattedValue = formatValue(value);

  return (
    <text
      x={fixedX}
      y={fixedY}
      fontSize={12}
      className="font-semibold"
      textAnchor="start"
      dominantBaseline="text-before-edge"
    >
      {isHorizontal ? (
        <>
          <tspan x={fixedX} dy="0em">{label}{formattedValue !== null ? ':' : ''}</tspan>
          {formattedValue !== null && <tspan x={fixedX} dy="1.2em">{formattedValue}</tspan>}
        </>
      ) : (
        <>
          <tspan x={fixedX} dy=".6em">
            Campaign Start
          </tspan>
          {formattedValue !== null && (
            <tspan x={fixedX} dy="1.2em">
              {formattedValue}
            </tspan>
          )}
        </>
      )}
    </text>
  );
};

const ImpactChart: FunctionComponent<Props> = ({
  index,
  chartData,
  divider,
  campaignStartDate,
  mainLabelComponent,
  width,
  margin,
  weekly,
  showAverage,
  averageBeforeCampaign,
  averageDuringCampaign,
  isFirstDay,
  isCompact
}: Props) => {
  const splitted = campaignStartDate?.split("T")[0];
  const campaignValueOf = moment(splitted).valueOf();
  const datesValuesOf = chartData.map((data) => data.valueOf);
  const datesCounts = chartData.map((data) => data.count);
  const { index: closestIndex } = findClosest(campaignValueOf, datesValuesOf);
  const maximumCountValue =
    findMinMax(datesCounts, findMinMaxCases.maximum) || 0;
  const minimumCountValue =
    findMinMax(datesCounts, findMinMaxCases.minimum) || 0;
  const { top: dividerTop, bottom: dividerBottom } = getChartDivider(divider);
  const borderClassName = getBorderClassName(dividerTop, dividerBottom);
  const referenceLineX = chartData[closestIndex]?.valueOf;
  const referenceLineLabelValue = moment(splitted).format("M/DD");
  const computedMinimumYValue = Math.floor(0.97 * minimumCountValue);
  const setAsAuto = maximumCountValue === 0 && minimumCountValue === 0;
  const referenceLineXLabelPosition =
    closestIndex === chartData.length - 1 ? start : end;

  const totalLabels = useMemo(() => {
    let count = 1; // Start with 1 for the campaign start label
    if (showAverage && averageBeforeCampaign !== undefined && chartData.filter(data => data.valueOf < referenceLineX).length > 3) {
      count++;
    }
    if (!isFirstDay && showAverage && averageDuringCampaign !== undefined) {
      count++;
    }
    return count;
  }, [showAverage, averageBeforeCampaign, averageDuringCampaign, isFirstDay, chartData, referenceLineX]);

  const checkOverlap = (value1: number, value2: number) => {
    return Math.abs(value1 - value2) < 0.05 * maximumCountValue;
  };

  const getYOffset = (index: number) => {
    const baseOffset = 5;
    let offset = index * baseOffset;
    
    // Check for overlap with Campaign Start label
    if (index === 2 && checkOverlap(averageDuringCampaign || 0, Number(referenceLineLabelValue))) {
      while (checkOverlap(averageDuringCampaign || 0, Number(referenceLineLabelValue))) {
        offset += 5; // Move down by 5 pixels at a time
      }
    }
    
    return offset;
  };

  let minTickGap = chartData.length > 4 ? 30 : 0
  return (
    <div className={`${borderClassName} pb-4`} key={index}>
      {mainLabelComponent ? mainLabelComponent : null}
      <ResponsiveContainer width={width ? width : window.innerWidth < 768 ? "100%" : "95%"} height={200}>
        <AreaChart data={chartData} margin={margin || { top: 8 }}>
          <CartesianGrid vertical={false} horizontal strokeDasharray="3 3" />
          <XAxis
            dataKey="valueOf"
            domain={["auto", "auto"]}
            axisLine={false}
            tickLine={false}
            tickFormatter={xTickFormatter(chartData, weekly)}
            minTickGap={minTickGap}
          />
          <YAxis
            tickCount={5}
            dataKey="count"
            domain={[setAsAuto ? "auto" : computedMinimumYValue, "auto"]}
            axisLine={false}
            tickLine={false}
            tickFormatter={yTickFormatter}
          />
          <Tooltip
            labelFormatter={tooltipLabelFormatter}
            formatter={tooltipContentFormatter}
          />

          <Area
            type="monotone"
            dataKey="count"
            stroke="#42c221"
            fill={"#d9ffd9"}
            fillOpacity="0.8"
            activeDot={{ r: 8 }}
            strokeWidth={4}
            dot={<CustomizedDot />}
            isAnimationActive={false}
          />
          <ReferenceLine
            strokeWidth={2}
            strokeDasharray="3 4"
            x={referenceLineX}
            stroke="#42c221"
            label={(props) => (
              <CustomReferenceLineLabel
                {...props}
                value={referenceLineLabelValue}
                position={referenceLineXLabelPosition}
                index={0}
                totalLabels={totalLabels}
                yOffset={getYOffset(0)}
              />
            )}
          />
          {showAverage && averageBeforeCampaign !== undefined && chartData.filter(data => data.valueOf < referenceLineX).length > 3 && (
            <ReferenceLine
              stroke="red"
              strokeDasharray="3 3"
              segment={[
                { x: chartData[0].valueOf, y: averageBeforeCampaign },
                { x: referenceLineX, y: averageBeforeCampaign }
              ]}
              label={(props) => (
                <CustomReferenceLineLabel
                  {...props}
                  value={averageBeforeCampaign.toFixed(2)}
                  isHorizontal={true}
                  label={isCompact ? "Average before" : "Average before Campaign"}
                  index={1}
                  totalLabels={totalLabels}
                />
              )}
            />
          )}
          {!isFirstDay && showAverage && averageDuringCampaign !== undefined && (
            <ReferenceLine
              stroke="purple"
              strokeWidth={4}
              strokeDasharray="3 3"
              segment={[
                { x: referenceLineX, y: averageDuringCampaign },
                { x: chartData[chartData.length - 1].valueOf, y: averageDuringCampaign }
              ]}
              label={(props) => (
                <CustomReferenceLineLabel
                  {...props}
                  value={null}
                  isHorizontal={true}
                  position={end}
                  label="New Average"
                  index={2}
                  totalLabels={totalLabels}
                  yOffset={getYOffset(2)}
                />
              )}
            >
              <animate attributeName="stroke-opacity" values="0;1;0" dur="3s" repeatCount="indefinite" />
            </ReferenceLine>
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ImpactChart;