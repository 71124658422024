import axios, { AxiosResponse, AxiosError } from "axios";
import { Fragment, useEffect, useState, useMemo, ChangeEvent, useContext, Dispatch, SetStateAction } from "react";
import { useTabs } from "react-headless-tabs";
import { useHistory } from "react-router-dom";
import Axios from "helpers/Interceptor";
import ConnectTab from "./connect";
import { InstagramBaseUrlRegex, STREAMLINK_REGEX } from "helpers/StreamingMappings";
import { pageView, setCurrentContext, setUserPropertiesOnce, track } from "analytics";
import { queryYouTubeApi } from "helpers/YouTube";
import {
  AnotherSocialPlatformContainer,
  ArtistInputContainer,
  ArtistNameBoldLabel,
  BackArrowImage,
  BackButtonContainer,
  BackButtonText,
  BackToDashboardContainer,
  CreateAccountWithoutSpotifyText,
  CreateNewArtistcontainer,
  CreateNewArtistImage,
  CreateNewBrandLabel,
  EnterArtistContainer,
  EnterArtistLabel,
  FlexContainer,
  LeftColumn,
  LogoContainer,
  LottieContainer,
  MainTitle,
  NoArtistItem,
  NoArtistLable,
  NoArtistLine,
  PlatformButtonContainer,
  PlatformLogoContainer,
  PlatformsButtonsContainer,
  RightColumn,
  SubTitle,
  Titles,
  useStyles,
} from "./style"
import { InputAdornment, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import Lottie from 'react-lottie';
import SymphonyLoadingLottie from 'assets/images/lotties/SymphonyLoading.json';
import GetStartedLottie from 'assets/images/lotties/GetStarted.json';
import { ReactComponent as SpotifyIcon } from "assets/images/streaming/icons/spotify-i.svg";
import { ReactComponent as SymphonyLogo } from 'assets/images/logo.svg';
import { ReactComponent as TiktokLogo } from 'assets/images/tiktok-black.svg'
import { ReactComponent as InstagramLogo } from 'assets/images/instagram-bordered-icon.svg'
import { ReactComponent as YoutubeLogo } from 'assets/images/streaming/icons/youtube-logo.svg'
import GrayArrow from "assets/images/arrow-right-icon.svg"
import ArtistList from './Components/ArtistList';
import CreateNewBrand from 'assets/images/CreateNewBrand.png';
import ProfilePreview from './Components/ProfilePreview';
import debounce from 'lodash/debounce';
import { toast, ToastOptions } from "react-toastify";
import { CurrentBrand, OnboardingArtist, SpotifyArtist, UserData, UserMetadata, YouTubeChannel } from "types/global";
import AlreadyExistingArtist from "./Components/ConnectPlatformModal/AlreadyExistingArtist";
import TiktokConnect from './Components/ConnectPlatformModal/TikTok';
import ConnectBrandLoader from "../../../components/Loader/ConnectBrandLoader";
import * as Sentry from "@sentry/react";
import { sleep } from "utils";
import YouTubeConnect from './Components/ConnectPlatformModal/Youtube';
import InstagramBusinessDiscoveryConnect from './Components/ConnectPlatformModal/InstagramBusinessDiscovery';
import { isPartner } from "helpers/partners";
import AccountType from "./Components/NewOnboarding/AccountType";
import MarketingExperience from "./Components/NewOnboarding/MarketingExperience";
import { MarketingSelectorOption } from "./Components/NewOnboarding/MarketingSelectorVertical";
import MarketingBudget from "./Components/NewOnboarding/MarketingBudget";
import MarketingGoals from "./Components/NewOnboarding/MarketingGoals";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import TeamsTotalCreators from "./Components/NewOnboarding/TeamsTotalCreators";
import { ProfileSearch } from "./Components/FindProfile";
import { checkIfSpotifyArtistExistsForUser } from "services/symphonyApi/brandService";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";

const TOAST_OPTIONS: ToastOptions = {
  position: "top-left",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  closeButton: true,
  style: {
    color: "black",
    width: "160%",
    paddingLeft: "20px",
    marginLeft: "40px",
    fontSize: "16px",
  },
}
export enum SOCIAL_PLATFORMS {
  INSTAGRAM = "instagram",
  SPOTIFY = "spotify",
  TIKTOK = "tiktok",
  YOUTUBE = "youtube",
  NONE = "",
}
export enum SEARCH_SOURCE_RESULTS {
  CHARTMETRIC = "chartmetric",
  SPOTIFY = "spotify",
}
type Props = {
  user: UserData,
  setUser: (user: UserData) => void,
  reloadBrands: () => void,
}

export enum OnboardingAccountTypes {
  CREATOR = 'creator',
  TEAM = 'team',
}

export enum MARKETING_GOALS {
  TRACK_DATA = 'track_data',
  COLLECT_DATA = 'collect_data',
  LINK_IN_BIO = 'link_in_bio',
  GROW_STREAMS = 'grow_streams',
  GROW_SOCIALS = 'grow_socials',
  SELL_TICKETS = 'sell_tickets',
  SELL_MERCH = 'sell_merch',
  UNDERSTAND_DATA = 'understand_data',
}

export type MarketingGoalType = {
  type: MARKETING_GOALS,
  title: string
}
// TODO 630: REWRITE THIS FILE!
const AddArtist = (props: Props) => {
  const { reloadBrands, user, setUser } = props;


  const { getTeamArtists } = useCurrentTeam()
  const { currentBrand, setCurrentBrand } = useContext(CurrentBrandContext) as { currentBrand: CurrentBrand, setCurrentBrand: Dispatch<SetStateAction<CurrentBrand>> }
  let NEW_ONBOARDING = !Boolean(user?.metadata?.onboarding_answers?.account_type)

  const tabs = NEW_ONBOARDING ? ["account_type", "teams-total-creators", "basic", 'marketing-goals', 'marketing-experience', 'marketing-budget', "connect", "profile"] : ["basic", "connect", "profile"];
  const defaultTab = NEW_ONBOARDING ? 'account_type' : "basic";
  const [isLoadingArtistDetails, setLoadingArtistDetails] = useState(false);
  const [selectedTab, setSelectedTab] = useTabs(tabs, defaultTab);
  const [searchResults, setSearchResult] = useState([]);
  const [searchSourceResults, setSearchSourceResults] = useState<SEARCH_SOURCE_RESULTS>(SEARCH_SOURCE_RESULTS.CHARTMETRIC);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [creatingBrand, setCreating] = useState<boolean>(false)
  const history = useHistory();
  const [spotifyToken, setSpotifyToken] = useState<string | null>(null);
  const [spotifyArtist, setSpotifyArtist] = useState<SpotifyArtist | null>(null);
  const [artist, setArtist] = useState<OnboardingArtist | null>(null);
  const [youtubeSearchResults, setYoutubeSearchResult] = useState<YouTubeChannel[]>([]);
  const [youtubeSearchString, setYoutubeSearchString] = useState<string>('');
  const [selectedYoutubeChannel, setSelectedYoutubeChannel] = useState<YouTubeChannel | null>(null);
  const [tiktokUrl, setTikTokUrl] = useState<{ username: string; url: string; picture?: string } | null>(null);
  const [selectedInstaPage, setSelectedInstaPage] = useState<any>('');
  const [isDisabled, setDisable] = useState(false);
  const [showConnectModal, setConnectModal] = useState<any>('');
  const [showPlatformModal, setShowPlatformModal] = useState<SOCIAL_PLATFORMS>(SOCIAL_PLATFORMS.NONE);
  const [showAlreadyExistsModal, setShowAlreadyExistsModal] = useState(false)
  const [showBackButton, setShowBackButton] = useState(false);
  const [alreadyExistArtist, setAlreadyExistArtist] = useState<OnboardingArtist | null>(null);
  const [error, setError] = useState(false)
  const [brandName, setBrandName] = useState("")



  // for new onboarding
  const [currentAccountType, setCurrentAccountType] = useState<OnboardingAccountTypes | null>(null)
  const [marketingExperience, setMarketingExperience] = useState<MarketingSelectorOption | null>(null)
  const [teamsTotalCreators, setTeamsTotalCreators] = useState<{
    value: number;
    label: string;
  } | null>(null)
  const [marketingBudget, setMarketingBudget] = useState<{
    value: number;
    label: string;
  } | null>(null)

  const [marketingGoals, setMarketingGoals] = useState<MarketingGoalType[] | null>(null)


  const onboardingAnswers: Record<string, any> = !NEW_ONBOARDING ? {} : {
    account_type: currentAccountType,
    marketing_experience: marketingExperience?.title,
    marketing_budget: marketingBudget?.value,
    marketing_budget_identifier: marketingBudget?.label,
    marketing_goals: marketingGoals && marketingGoals?.length > 0 ? marketingGoals.map((goal) => goal.type) : null,
    teams_total_creators: teamsTotalCreators?.value || undefined,
    teams_total_creators_identifier: teamsTotalCreators?.label || undefined,
  }


  // when tab changes, make sure we scroll to the top of the page
  // since this is a single page app view
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedTab]); // This effect runs when selectedTab changes


  // Mixpanel pageview
  useEffect(() => {
    pageView("Add Artist")

    if (user && user.hasBrands) {
      setShowBackButton(true);
    } else {
      toast.success('Account created!', TOAST_OPTIONS)
    }

    track("Began Artist Creation", {
      newOnboarding: NEW_ONBOARDING
    })
  }, [])


  useEffect(() => {
    // let fb_page = localStorage.getItem('fb_page');
    // const fb_data = fb_page !== null ? JSON.parse(fb_page) : null;
    // if (fb_data && fb_data.id) {
    //   setSelectedFBPage(fb_data)
    // }

    let spotify_artist = localStorage.getItem('spotify_artist');
    const artist = spotify_artist !== null ? JSON.parse(spotify_artist) : null;
    if (artist && artist.id) {
      setSpotifyArtist(artist)
    }

    let insta_page = localStorage.getItem('insta_page');
    const insta_data = insta_page !== null ? JSON.parse(insta_page) : null;
    if (insta_data && insta_data.id) {
      setSelectedInstaPage(insta_data)
    }

    let youtube_channel = localStorage.getItem('youtube_channel');
    const channel = youtube_channel !== null ? JSON.parse(youtube_channel) : null;
    if (channel && channel.id) {
      setSelectedYoutubeChannel(channel)
    }
  }, [])

  useEffect(() => {
    // either facebook or instagram page is only needed
    if (selectedInstaPage) {
      setDisable(false)
    }
  }, [selectedInstaPage])


  useEffect(() => {
    const spotifyToken = localStorage.getItem('spotify_token');
    if (!spotifyToken) {
      getSpotifyToken();
    } else {
      setSpotifyToken(spotifyToken);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (spotifyArtist?.id && artist && selectedTab === "connect") {
        setLoadingArtistDetails(true)
        const { data } = await checkIfSpotifyArtistExistsForUser(spotifyArtist.id)
        setLoadingArtistDetails(false)
        setShowAlreadyExistsModal(Boolean(data?.id))
      }
    })()
  }, [spotifyArtist?.id, artist])

  const handleCloseAlreadyExistsModal = () => {
    setShowAlreadyExistsModal(false)
  }


  const getSpotifyToken = async () => {
    const tokenData = await Axios.get('/token/spotify')

    if (tokenData && tokenData.data && tokenData.data.data.token) {
      localStorage.setItem('spotify_token', tokenData.data.data.token);
      setSpotifyToken(tokenData.data.data.token);
    }
  }

  const classes = useStyles();
  const theme = useTheme();
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));

  const InputProps = {
    classes: {
      root: classes.inputBorder,
    },
  };

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(async (value: string) => {
        Axios.get(`/chartmetric/search/artists/spotify?q=${value}&limit=30`)
          .then((result: AxiosResponse) => {
            setSearchResult(result.data.data?.artists || []);
            setSearchSourceResults(result.data.data?.source)
            setLoadingSearch(false)
          })
          .catch((err: AxiosError) => {
            console.error('error fetching artist', err);
            localStorage.removeItem('chartmetric_token');
            setLoadingSearch(false)
          });
      }, 800),
    []
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isLoadingArtistDetails) setLoadingArtistDetails(false)
    if (error) setError(false);

    const value = e.target.value;

    if (value.length) {
      setLoadingSearch(true);
      debouncedChangeHandler(value);
    } else {
      setSearchResult([]);
    }
    setSearchStr(value);
  };

  const clearPlatformsLocalStorage = (data: { except: string[] }) => {
    const socialValues = data.except;
    if (!socialValues.includes("youtube")) {
      localStorage.removeItem('youtube_channel');
      setSelectedYoutubeChannel(null);
    }
    if (!socialValues.includes("spotify")) {
      localStorage.removeItem('spotify_artist');
      setSpotifyArtist(null);
    }
    if (!socialValues.includes("tiktok")) {
      localStorage.removeItem('tiktok');
      setTikTokUrl(null)
    }
    if (!socialValues.includes("instagram")) {
      localStorage.removeItem('insta_page');
      setSelectedInstaPage(null);
    }
    if (!socialValues.includes("chartmetric")) {
      setArtist(null)
    }
    if (!socialValues.includes('spotify_list')) {
      setSearchResult([])
      setSearchStr('')
    }
  }

  const goToDashboard = () => {
    track("Finalized Artist Creation Flow - Going to Dashboard", {
      newOnboarding: NEW_ONBOARDING,
      ...onboardingAnswers
    })
    history.push('dashboard');
  }

  const handleGoBack = () => {
    if (NEW_ONBOARDING) {
      switch (selectedTab) {
        case 'account_type': goToDashboard(); break;
        case 'teams-total-creators': setSelectedTab('account_type'); break;
        case 'basic': {
          switch (currentAccountType) {
            case OnboardingAccountTypes.TEAM: setSelectedTab('teams-total-creators'); break;
            default: setSelectedTab('account_type'); break;
          }
          break;
        }
        case 'marketing-goals': setSelectedTab('basic'); break;
        case 'marketing-experience': setSelectedTab('marketing-goals'); break;
        case 'marketing-budget': setSelectedTab('marketing-experience'); break;
        case 'profile': setSelectedTab("marketing-budget"); break;
        case 'connect':
          setSelectedTab("marketing-budget");
          clearPlatformsLocalStorage({ except: ["none"] })
          break;
      }
    } else {
      switch (selectedTab) {
        case 'basic': goToDashboard(); break;
        case 'profile': setSelectedTab("connect"); break;
        case 'connect':
          setSelectedTab("basic");
          clearPlatformsLocalStorage({ except: ["none"] })
          break;
      }
    }
  }

  const handleGoNext = async (accountType?: OnboardingAccountTypes) => {
    if (NEW_ONBOARDING) {
      switch (selectedTab) {
        case 'account_type': {

          // if account type is team, go to teams total creators
          // otherwise go to spotify search / initial connection
          switch (accountType) {
            case OnboardingAccountTypes.TEAM: {
              track("Go to Teams Total Creators Tab", {
                ...artist,
                newOnboarding: true,
                ...onboardingAnswers
              })
              setSelectedTab("teams-total-creators");
              break;
            }
            default: {
              track("Go to Enter Artist URL Tab", {
                ...artist,
                newOnboarding: true,
                ...onboardingAnswers
              })
              setSelectedTab("basic");
              break;
            }
          }
          break;
        }
        case 'teams-total-creators': {
          track("Go to Basic Tab", {
            ...artist,
            newOnboarding: true,
            ...onboardingAnswers
          })
          setSelectedTab("basic"); break;
        }
        case 'basic': {
          track("Go to Marketing Goals Tab", {
            ...artist,
            newOnboarding: true,
            ...onboardingAnswers
          })

          setSelectedTab("marketing-goals"); break;
        }

        case 'marketing-goals': {
          track("Go to Marketing Experience Tab", {
            ...artist,
            newOnboarding: true,
            ...onboardingAnswers
          })

          setSelectedTab("marketing-experience"); break;
        }

        case 'marketing-experience': {
          track("Go to Marketing Budget Tab", {
            ...artist,
            newOnboarding: true,
            ...onboardingAnswers
          })

          setSelectedTab("marketing-budget"); break;
        }


        case 'marketing-budget': {
          track("Go to Connect Tab", {
            ...artist,
            newOnboarding: true,
            ...onboardingAnswers
          })

          setSelectedTab("connect"); break;
        }

        case 'connect': {
          track("Go to Profile Tab", {
            ...artist,
            newOnboarding: true,
            ...onboardingAnswers
          })

          const userRedeemedFreeTrialAlready = user?.metadata?.free_trial_redeemed || false;
          const usersOrganizationReady = user.organization?.status === 'ready';
          if (userRedeemedFreeTrialAlready && !usersOrganizationReady) {
            await reloadBrands();
            goToDashboard();
          } else {
            setCreating(false)
            setSelectedTab("profile");
          }
          break;
        }
        case 'profile':
          await getTeamArtists()
          await reloadBrands()
          goToDashboard()
          break;
      }
    } else {
      switch (selectedTab) {
        case 'basic': {
          track("Go to Connect Tab", {
            ...artist,
            newOnboarding: false,
            ...onboardingAnswers
          })

          setSelectedTab("connect"); break;
        }
        case 'connect': {
          track("Go to Profile Tab", {
            ...artist,
            newOnboarding: false,
            ...onboardingAnswers
          })

          const userRedeemedFreeTrialAlready = user?.metadata?.free_trial_redeemed || false;
          const usersOrganizationReady = user.organization?.status === 'ready';

          if (userRedeemedFreeTrialAlready && !usersOrganizationReady) {
            await reloadBrands();
            goToDashboard();
          } else {
            setCreating(false)
            setSelectedTab("profile");
          }

          break;
        }
        case 'profile':
          await reloadBrands()
          goToDashboard()
          break;
      }
    }
  }

  const selectArtist = async (artist: OnboardingArtist) => {
    Sentry.setTag("flow", 'selectArtist')
    Sentry.setContext("artist", { artist })

    let spotifyProfile = { artist: null } as { artist: SpotifyArtist | null }
    let error = false
    clearPlatformsLocalStorage({ except: ["spotify", "spotify_list"] })
    setArtist(artist)

    if (artist.id) {
      track("Selected Artist from List", {
        ...artist,
        pulledFromChartmetric: Boolean(artist.chartmetric),
      })
      try {
        setLoadingArtistDetails(true);
        let links: any[] = []
        if (searchSourceResults === SEARCH_SOURCE_RESULTS.SPOTIFY) {
          links = [{ domain: 'spotify', url: [`https://open.spotify.com/artist/${artist.id}`] }]
        } else {
          const result = await Axios.get(`/chartmetric/artists/${artist.id}/urls`, { timeout: 5000 });
          links = result.data.data?.obj || [];
        }
        spotifyProfile = await getSocialNetworkProfiles(links) as { artist: SpotifyArtist | null };
      } catch (error) {
        console.log('error', error)
        try {
          await sleep(5000);
          const result = await Axios.get(`/chartmetric/artists/${artist.id}/urls`, { timeout: 5000 });
          const links = result.data.data?.obj || [];
          spotifyProfile = await getSocialNetworkProfiles(links) as { artist: SpotifyArtist | null };
        } catch (err) {

          console.log("error calling  chartmetric", err)
          // in second error case, try using the spotify url to get the artist id

          if (searchStr && searchStr.includes('open.spotify.com/artist')) {
            const artistID = searchStr.replace('https://open.spotify.com/artist/', '');
            try {
              const spotifyResults = await searchSpotify(artistID)
              if (!spotifyResults || !spotifyResults.data) {
                throw "No spotify data found."
              }

              selectSpotifyArtist(spotifyResults?.data);

              track("Autolinked Spotify")
              const arr = []
              arr.push(spotifyResults?.data)
              spotifyProfile = { artist: spotifyResults?.data }
            } catch (e) {
              console.error('error fetching artist from chartmetric', e);
              Sentry.captureException(e);
              setError(true)
              error = true
            }
          } else {

            console.error('error fetching artist from chartmetric', err);
            Sentry.captureException(err);
            setError(true)
            error = true
          }
        }
      }
    } else {
      track("Created New Artist", {
        ...artist,
        pulledFromChartmetric: false,
        newOnboarding: NEW_ONBOARDING,
        ...onboardingAnswers
      })


    }
    if (!error) {
      const { data: spotifyArtistExistsData, error: spotifyArtistExistsError } = await checkIfSpotifyArtistExistsForUser(spotifyProfile?.artist?.id || null)

      if (spotifyArtistExistsError) error = true

      const { id } = spotifyArtistExistsData || {}

      if (!error) {
        if (id && selectedTab === "basic") {
          setShowAlreadyExistsModal(true)
          track("New Artist Already Exists", {
            ...artist
          })
        } else {
          handleGoNext()
        }

        setLoadingArtistDetails(false);
      } else {
        setError(true)
        Sentry.setTag("flow", 'CheckIfSpotifyArtistExists')
        Sentry.setContext("spotifyProfile", { spotifyProfile })
        Sentry.captureException(spotifyArtistExistsError);
      }
    }
    setLoadingArtistDetails(false);
  }

  // NEW ONBOARDING VALUES
  // =====================
  function userSelectedAccountType(accountType: OnboardingAccountTypes) {
    setCurrentAccountType(accountType)
    handleGoNext(accountType)
  }

  function handleSelectedCreators(option: {
    value: number,
    label: string
  }) {
    setTeamsTotalCreators(option)
    handleGoNext()
  }

  function handleSelectedMarketingExperience(option: MarketingSelectorOption) {
    setMarketingExperience(option)
    handleGoNext()
  }

  function handleSelectedBudget(option: {
    value: number,
    label: string
  }) {
    setMarketingBudget(option)
    handleGoNext()
  }

  function handleSelectedGoals(option: MarketingGoalType[]) {
    setMarketingGoals(option)
    handleGoNext()
  }

  // =====================

  const handleShowAlreadyExistsModal = (artist: OnboardingArtist) => {
    setAlreadyExistArtist(artist);
    setShowAlreadyExistsModal(true)
  }

  const selectInstagramPage = (item: { url: string, username: string, picture?: string }) => {
    setSelectedInstaPage(item);
    let insta_page = JSON.parse(JSON.stringify(item));
    localStorage.setItem('insta_page', JSON.stringify(insta_page))
  }

  const handleOpenTiktokConnectModal = () => setShowPlatformModal(SOCIAL_PLATFORMS.TIKTOK)
  const handleOpenInstagramConnectModal = () => setShowPlatformModal(SOCIAL_PLATFORMS.INSTAGRAM)
  const handleOpenYoutubeConnectModal = () => setShowPlatformModal(SOCIAL_PLATFORMS.YOUTUBE)

  const handleClosePlatformModal = () => setShowPlatformModal(SOCIAL_PLATFORMS.NONE)

  const getSocialNetworkProfiles = async (links: any[]) => {
    let spotifyNetworkProfile = { artist: null }
    try {
      Sentry.setTag("subflow", 'getSocialNetworkProfiles')
      Sentry.setContext("links", { links })

      const reqLinks = links.filter((item: { url: string; domain: string; }) =>
        ['facebook', 'instagram', 'youtube', 'spotify', 'tiktok'].some(itm => itm === item.domain)
      )

      for (const data of reqLinks) {
        const { domain, url } = data as { domain: string; url: string; }

        Sentry.setTag("domain", domain)

        if (domain === 'youtube') {
          try {
            const formatted = (url[0].replace('https', 'http')).split(/[?#]/)[0];
            let results;

            if (formatted.includes('channel')) {
              const channelID = formatted.replace('http://www.youtube.com/channel/', '');
              results = await queryYouTubeApi({
                endpoint: '/channels',
                params: {
                  id: channelID,
                  part: 'id,snippet,statistics',
                }
              })
            } else if (formatted.includes('user')) {
              const forUsername = formatted.replace('http://www.youtube.com/user/', '');
              results = await queryYouTubeApi({
                endpoint: '/channels',
                params: {
                  forUsername,
                  part: 'id,snippet,statistics',
                }
              })
            } else {
              const forUsername = formatted.replace('http://www.youtube.com/', '');
              results = await queryYouTubeApi({
                endpoint: '/channels',
                params: {
                  forUsername,
                  part: 'id,snippet,statistics',
                }
              })
            }

            if (results?.data?.items?.length) {
              track("Autolinked YouTube")

              setYoutubeSearchResult(results.data.items);
              selectYoutubeChannel(results.data.items[0]);
            }
          } catch (e) {
            Sentry.captureException(e)
          }
        }

        if (domain === "spotify") {
          try {
            const artistID = url[0].replace('https://open.spotify.com/artist/', '');
            const spotifyResults = await searchSpotify(artistID)
            selectSpotifyArtist(spotifyResults?.data);
            track("Autolinked Spotify")
            const arr = []
            arr.push(spotifyResults?.data)
            spotifyNetworkProfile = { artist: spotifyResults?.data }
          } catch (e) {
            Sentry.captureException(e)
          }
        }

        if (domain === "tiktok") {
          try {
            const foundTiktokUrl = url[0]
            if (foundTiktokUrl) {
              let externalId = null
              const regex = STREAMLINK_REGEX["tiktok"]

              for (const test in regex) {
                const item = regex[test]
                externalId = foundTiktokUrl.match(item)
                if (externalId && externalId[1]) break
              }

              if (externalId) {
                externalId = externalId[1]
                track("Autolinked TikTok")

                setTikTokUrl({
                  url: foundTiktokUrl,
                  username: externalId
                })
              }
            }
          } catch (e) {
            Sentry.captureException(e)
          }
        }

        if (domain === 'instagram') {
          try {
            let username = url[0].replace(InstagramBaseUrlRegex, '').replace('/', '');
            selectInstagramPage({ url: url[0], username });
          } catch (e) {
            Sentry.captureException(e)
          }
        }
      }
    } catch (e) {
      Sentry.captureException(e)
      console.error("Error pulling social networks", e)
    }

    return spotifyNetworkProfile
  }

  const selectTiktokUrl = (selectedTiktokArtist: { url: string; username: string, picture?: string }) => {
    let externalId = null
    const regex = STREAMLINK_REGEX["tiktok"]
    for (const test in regex) {
      const item = regex[test]
      externalId = selectedTiktokArtist.url.match(item)
      if (externalId && externalId[1]) break
    }

    if (externalId) {
      externalId = externalId[1]
      setTikTokUrl({
        url: selectedTiktokArtist.url,
        username: externalId,
        picture: selectedTiktokArtist.picture
      })
    }
  }

  async function searchSpotify(artistId: any): Promise<any> {
    const spotify_token = localStorage.getItem('spotify_token')

    if (!spotify_token) {
      await getSpotifyToken()
      return await searchSpotify(artistId)
    } else {
      try {
        const spotifyHeaders = {
          Authorization: 'Bearer ' + spotify_token
        }
        const results = await axios.get(`https://api.spotify.com/v1/artists/${artistId}`, { headers: spotifyHeaders })
        return results
      } catch (e: any) {
        const { response } = e
        const { status } = response

        if (status === 401) {
          console.error("Spotify access token expired ~ grabbing new one")
          await getSpotifyToken()
          return await searchSpotify(artistId)

        } else {
          console.error('error pulling spotify', response)
        }
      }
    }
  }

  const selectYoutubeChannel = async (channelInfo: any) => {
    setSelectedYoutubeChannel(channelInfo);
    let youtube_channel = JSON.parse(JSON.stringify(channelInfo));
    localStorage.setItem('youtube_channel', JSON.stringify(youtube_channel))
    setYoutubeSearchResult([])
    setConnectModal('')
    setShowPlatformModal(SOCIAL_PLATFORMS.NONE)
  }

  const selectSpotifyArtist = (item: any) => {
    setSpotifyArtist(item);
    let spotify_artist = JSON.parse(JSON.stringify(item));
    localStorage.setItem('spotify_artist', JSON.stringify(spotify_artist))
    setConnectModal('');
  }

  const hasConnectedSocialPlatforms = useMemo<boolean>(() =>
    Boolean(tiktokUrl) ||
    Boolean(spotifyArtist) ||
    Boolean(selectedYoutubeChannel) ||
    Boolean(selectedInstaPage) ||
    Boolean(artist)
    , [tiktokUrl, spotifyArtist, selectedYoutubeChannel, selectedInstaPage, artist]);

  async function createBrand() {
    if (!hasConnectedSocialPlatforms) return;
    setDisable(true)
    setCreating(true)

    let payload: { name: string | null, customization: Record<string, object> } = { name: brandName ? brandName : artist?.name || null, customization: {} }

    if (selectedYoutubeChannel) payload.customization['youtube_channel'] = selectedYoutubeChannel;

    if (spotifyArtist) payload.customization['spotify_artist'] = spotifyArtist;

    if (tiktokUrl) payload.customization['tiktok'] = tiktokUrl;

    if (selectedInstaPage) payload.customization['business_discovery_instagram'] = selectedInstaPage;

    if (artist && artist.chartmetric && artist.id && searchSourceResults === SEARCH_SOURCE_RESULTS.CHARTMETRIC) {
      payload.customization['chartmetric'] = { id: artist.id };
    }


    payload.customization['onboarding_answers'] = onboardingAnswers
    Axios.post('/brand', payload, { timeout: 60 * 1000 })
      .then(async (result) => {
        // set the local artist ID to returned id
        const { id, name, slug } = result.data.data;

        const additionalContextProps = {
          ...onboardingAnswers
        }

        if (user?.metadata?.rewardful_affiliate_link) {
          additionalContextProps.rewardful_affiliate_link = user?.metadata?.rewardful_affiliate_link
        }

        setCurrentContext({
          id,
          name,
          slug,
          additionalProps: additionalContextProps
        })
        localStorage.setItem('selected-brand', id)

        // set the user properties based on the onboarding_answers object
        const onboardingAnswerKeys = Object.keys(onboardingAnswers)
        onboardingAnswerKeys.forEach((key) => {
          const onboardingValue = onboardingAnswers && onboardingAnswers[key] ? onboardingAnswers[key] : null
          setUserPropertiesOnce({
            [`onboarding_${key}`]: onboardingValue
          })
        })

        const linkedConnections = Object.keys(payload.customization).filter((o: string) => Boolean(payload.customization[o]))
        track("Created Artist", {
          linkedConnections,
          newOnboarding: NEW_ONBOARDING,
          ...onboardingAnswers
        })
        track("Finished Artist Creation", {
          linkedConnections,
          newOnboarding: NEW_ONBOARDING,
          ...onboardingAnswers
        })
        track("CompleteRegistration", {
          linkedConnections,
          newOnboarding: NEW_ONBOARDING,
          ...onboardingAnswers
        })

        const brandResponse = await Axios.get(`/brand/${localStorage.getItem('selected-brand')}`);
        setCurrentBrand(brandResponse.data.data);
        setDisable(false)

        const currentUserMetadata: UserMetadata = user.metadata as UserMetadata
        currentUserMetadata.onboarding_answers = onboardingAnswers ? onboardingAnswers : currentUserMetadata.onboarding_answers
        if (!user.hasBrands) {
          setUser({
            ...user,
            metadata: currentUserMetadata,
            hasBrands: true,
          });
        } else {
          setUser({
            ...user,
            metadata: currentUserMetadata,
            hasBrands: true,
          });
        }
        handleGoNext();
      })
      .catch((err) => {
        console.log('error creating brand', err)
        setDisable(false)
        setCreating(false)
      })
  }

  let titleText = 'Get Started'
  let descriptionText = `Setup your account and grow your fanbase with Symphony.`
  let partnerType = isPartner(user)

  if (
    partnerType
    && !showBackButton
  ) {
    switch (partnerType.partnerId) {
      case 'UM':
        descriptionText = "We're excited to support the UnitedMasters SELECT community. Setup your account to start growing your fanbase with Symphony."
        break
      case 'Audiomack':
        descriptionText = "We're excited to support the Audiomack community. Setup your account to start growing your fanbase with Symphony."
        break
      case 'CDBaby':
        descriptionText = "We're excited to support the CDBaby community. Setup your account to start growing your fanbase with Symphony."
        break
      case 'Daily Playlists':
        descriptionText = "We're excited to support the Daily Playlists community. Setup your account to start growing your fanbase with Symphony."
        break
      case 'Downtown':
        descriptionText = "We're excited to support the Downtown Music community. Setup your account to start growing your fanbase with Symphony."
        break
      default:
        break
    }
  }

  let basicTextOptions = {
    titleText: 'Get Started',
    descriptionText: `Setup your account and grow your fanbase with Symphony.`,
    sectionTitle: 'Enter your artist name or Spotify URL',
  }

  if (NEW_ONBOARDING) {
    if (currentAccountType === OnboardingAccountTypes.CREATOR) {
      basicTextOptions = {
        titleText: 'Get Started',
        descriptionText: `Setup your account and grow your fanbase with Symphony.`,
        sectionTitle: 'Let\'s setup your profile. Enter your artist name or Spotify URL.',
      }

    } else {
      basicTextOptions = {
        titleText: 'Get Started',
        descriptionText: `Setup your account and grow your fanbase with Symphony.`,
        sectionTitle: 'Let\'s setup your first profile. Enter your artist name or Spotify URL.',
      }

    }
  }
  return (
    <Fragment>
      <FlexContainer hidden={selectedTab !== 'account_type'}>
        <AccountType
          setCurrentAccountType={userSelectedAccountType}
          responsiveView={setResponsiveView}
          handleGoBack={handleGoBack}
          showBackButton={showBackButton}
          titleText={titleText}
          descriptionText={descriptionText}
        />

      </FlexContainer>
      <FlexContainer hidden={selectedTab !== 'basic'}>
        <LeftColumn width={setResponsiveView ? "100%" : "50%"} isMobile={setResponsiveView}>
          {showBackButton && setResponsiveView &&
            <BackToDashboardContainer>
              <BackButtonContainer onClick={handleGoBack}>
                <BackArrowImage src={GrayArrow} />
              </BackButtonContainer>
              {!NEW_ONBOARDING && (<BackButtonText>Back to dashboard</BackButtonText>)}
            </BackToDashboardContainer>
          }
          <LogoContainer>
            <SymphonyLogo height={'30px'} />
          </LogoContainer>
          <Titles gap={setResponsiveView ? "8px" : "18px"}>
            {showBackButton && !setResponsiveView &&
              <BackToDashboardContainer>
                <BackButtonContainer onClick={handleGoBack}>
                  <BackArrowImage src={GrayArrow} />
                </BackButtonContainer>
                {!NEW_ONBOARDING && (<BackButtonText>Back to dashboard</BackButtonText>)}
              </BackToDashboardContainer>
            }
            <MainTitle>{titleText}</MainTitle>
            <SubTitle>{descriptionText}</SubTitle>
          </Titles>
          <EnterArtistContainer>
            <EnterArtistLabel>
              {basicTextOptions.sectionTitle}
            </EnterArtistLabel>
            <ArtistInputContainer>
              <PlatformLogoContainer backgroundColor='#191919' padding={setResponsiveView ? '8px' : '13px'}>
                <SpotifyIcon height={setResponsiveView ? '32px' : '38px'} width={setResponsiveView ? '32px' : '38px'} />
              </PlatformLogoContainer>
              <TextField
                InputProps={{
                  classes: {
                    root: classes.inputBorder,
                  },
                  endAdornment: loadingSearch ? (
                    <InputAdornment position="end">
                      <Lottie
                        height={24}
                        width={24}
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: SymphonyLoadingLottie,
                        }}
                      />
                    </InputAdornment>
                  ) : undefined,
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.formHelperText
                  },
                }}
                error={error}
                value={searchStr}
                helperText={error ? "An error occurred. Please try again later." : undefined}
                onChange={handleChange}
                className={classes.textField}
                variant="outlined"
                fullWidth
                placeholder="Bad Bunny, SZA..."
              />
            </ArtistInputContainer>
            {searchResults && searchResults.length > 0 && (
              <ArtistList
                loading={isLoadingArtistDetails}
                artists={searchResults}
                selectArtist={selectArtist}
                selectedArtist={artist}
                isMobile={setResponsiveView}
              />
            )}
            {!loadingSearch &&
              searchStr &&
              searchResults &&
              searchResults.length === 0 && (
                <>
                  <NoArtistLine />
                  <NoArtistItem>
                    <NoArtistLable>
                      No artists found for{" "}
                      <ArtistNameBoldLabel>{searchStr}</ArtistNameBoldLabel>
                    </NoArtistLable>
                  </NoArtistItem>
                  <NoArtistLine />
                  <CreateNewArtistcontainer
                    onClick={() =>
                      selectArtist({
                        chartmetric: false,
                        id: null,
                        name: searchStr,
                      } as OnboardingArtist)
                    }
                  >
                    <CreateNewArtistImage src={CreateNewBrand} />
                    <CreateNewBrandLabel>
                      Create new artist:&nbsp;
                      <ArtistNameBoldLabel>{searchStr}</ArtistNameBoldLabel>
                    </CreateNewBrandLabel>
                  </CreateNewArtistcontainer>
                </>
              )}
          </EnterArtistContainer>
          <AnotherSocialPlatformContainer>
            <CreateAccountWithoutSpotifyText>Or create your account with another social platform:</CreateAccountWithoutSpotifyText>
            <PlatformsButtonsContainer>
              <PlatformButtonContainer
                backgroundColor='rgba(25, 25, 25, 0.16)'
                onClick={handleOpenTiktokConnectModal}
              >
                <TiktokLogo width={32} height={32} />
              </PlatformButtonContainer>
              <PlatformButtonContainer
                backgroundColor='radial-gradient(144.41% 122.74% at 1.81% 90.83%, rgba(254, 213, 118, 0.16) 0%, rgba(244, 113, 51, 0.16) 26.34%, rgba(188, 48, 129, 0.16) 60.91%, rgba(76, 99, 210, 0.16) 100%)'
                onClick={handleOpenInstagramConnectModal}
              >
                <InstagramLogo width={32} height={32} />
              </PlatformButtonContainer>
              {/* TODO: Uncomment this when we fix YouTube connection */}
              {/* <PlatformButtonContainer
                backgroundColor='rgba(255, 0, 0, 0.16);'
                onClick={handleOpenYoutubeConnectModal}
              >
                <YoutubeLogo width={32} height={32} />
              </PlatformButtonContainer> */}
            </PlatformsButtonsContainer>
          </AnotherSocialPlatformContainer>
        </LeftColumn>
        <RightColumn display={setResponsiveView ? 'none' : 'flex'}>
          <LottieContainer>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: GetStartedLottie,
              }}
            />
          </LottieContainer>
        </RightColumn>
      </FlexContainer>
      <FlexContainer hidden={selectedTab !== 'marketing-experience'}>
        <MarketingExperience
          handleGoBack={handleGoBack}
          showBackButton={showBackButton}
          handleSelected={handleSelectedMarketingExperience}
        />

      </FlexContainer>
      <FlexContainer hidden={selectedTab !== 'teams-total-creators'}>
        <TeamsTotalCreators
          handleGoBack={handleGoBack}
          showBackButton={showBackButton}
          handleSelected={handleSelectedCreators}
        />

      </FlexContainer>
      <FlexContainer hidden={selectedTab !== 'marketing-budget'}>
        <MarketingBudget
          handleGoBack={handleGoBack}
          showBackButton={showBackButton}
          handleSelected={handleSelectedBudget}
        />

      </FlexContainer>
      <FlexContainer hidden={selectedTab !== 'marketing-goals'}>
        <MarketingGoals
          accountType={currentAccountType}
          handleGoBack={handleGoBack}
          showBackButton={showBackButton}
          handleSelected={handleSelectedGoals}
        />

      </FlexContainer>
      <FlexContainer hidden={selectedTab !== "connect"}>
        {
          creatingBrand ? <ConnectBrandLoader /> :
            <ConnectTab
              newOnboarding={NEW_ONBOARDING}
              selectedYoutubeChannel={selectedYoutubeChannel}
              setYoutubeSearchResult={setYoutubeSearchResult}
              selectYoutubeChannel={selectYoutubeChannel}
              youtubeSearchResults={youtubeSearchResults}
              spotifyArtist={spotifyArtist}
              showConnectModal={showConnectModal}
              setConnectModal={setConnectModal}
              tiktokArtist={tiktokUrl}
              selectedInstagramPage={selectedInstaPage}
              setInstagramPage={setSelectedInstaPage}
              artist={artist}
              handleGoBack={handleGoBack}
              handleGoNext={createBrand}
              isLoadingArtistDetails={isLoadingArtistDetails}
              searchResults={searchResults}
              setSearchResult={setSearchResult}
              searchSourceResults={searchSourceResults}
              setSearchSourceResults={setSearchSourceResults}
              setArtist={setArtist}
              setSpotifyArtist={setSpotifyArtist}
              handleShowAlreadyExistsModal={handleShowAlreadyExistsModal}
              selectTiktokArtist={selectTiktokUrl}
              setBrandName={setBrandName}
            />
        }
      </FlexContainer>
      <FlexContainer hidden={selectedTab !== "profile"}>
        {selectedTab === "profile" && (
          <ProfilePreview
            handleGoNext={handleGoNext}
            user={user}
            brandCreated={currentBrand}
            setSearchResult={setSearchResult}
            setSpotifyArtist={setSpotifyArtist}
            searchResults={searchResults}
            searchSourceResults={searchSourceResults}
            setSearchSourceResults={setSearchSourceResults}
            handleShowAlreadyExistsModal={handleShowAlreadyExistsModal}
          />)}
      </FlexContainer>

      {(Boolean(artist) || Boolean(alreadyExistArtist)) && (
        <AlreadyExistingArtist
          open={showAlreadyExistsModal}
          closeModal={handleCloseAlreadyExistsModal}
          artist={
            selectedTab === "basic"
              ? (artist as OnboardingArtist)
              : (alreadyExistArtist as OnboardingArtist)
          }
          createBrand={createBrand}
        />
      )}
      <YouTubeConnect
        open={showPlatformModal === SOCIAL_PLATFORMS.YOUTUBE}
        artist={artist}
        closeModal={handleClosePlatformModal}
        localSave={true}
        selectedYoutubeChannel={selectedYoutubeChannel}
        selectYoutubeChannel={(channel) => {
          selectYoutubeChannel(channel)
          clearPlatformsLocalStorage({ except: ['youtube'] });
          handleGoNext()
        }}
        youtubeSearchResults={youtubeSearchResults}
        setYoutubeSearchString={setYoutubeSearchString}
        youtubeSearchString={youtubeSearchString}
      />
      <TiktokConnect
        open={showPlatformModal === SOCIAL_PLATFORMS.TIKTOK}
        closeModal={handleClosePlatformModal}
        selectedTiktokArtist={tiktokUrl}
        artist={artist}
        selectTiktokArtist={(selectedTiktokArtist) => {
          clearPlatformsLocalStorage({ except: ['tiktok'] });
          selectTiktokUrl(selectedTiktokArtist)
          handleGoNext()
        }}
        localSave={true}
      />
      <InstagramBusinessDiscoveryConnect
        open={showPlatformModal === SOCIAL_PLATFORMS.INSTAGRAM}
        closeModal={handleClosePlatformModal}
        artist={artist}
        localSave={true}
        selectInstagramArtist={(selectedInstagramArtist) => {
          selectInstagramPage(selectedInstagramArtist)
          clearPlatformsLocalStorage({ except: ['instagram'] });
          handleGoNext()
        }}
        selectedInstagramArtist={selectedInstaPage}
      />
    </Fragment>
  );
};

export default AddArtist;