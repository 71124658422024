import { Body2, Caption } from "components/shareable/Typography"
import { GeographicBreakdown, LocationCount } from "../../../WebsiteDashboardv2/types"
import ReactCountryFlag from "react-country-flag"
import countryList from "react-select-country-list"
import { SystemColors } from "types/globalStyles"
import SymphonyBarChart from "components/shareable/DATA_PRESENTATION/Charts/BarChart"
import { isoCountries } from "helpers/Countries"
import millify from "millify" // Import millify for number formatting
import useMediaBreakpoints from "Hooks/useMediaBreakpoints"

interface Props {
  metrics: GeographicBreakdown,
  areaType: 'countries' | 'cities'
}

export function TopGeographiesBarChart({ metrics, areaType }: Props) {
  const { largeView: isDesktop } = useMediaBreakpoints(); // Use the hook to determine if it's desktop view

  if (!metrics) return null

  const area = metrics[areaType] || []
  const sortedBreakdown = area.sort((a, b) => b.count - a.count).slice(0, 5)

  if (!sortedBreakdown.length) {
    return (
      <div className="w-full flex justify-center py-2">
        <Body2>No data found</Body2>
      </div>
    )
  }

  const sortedData = sortedBreakdown.map((item: LocationCount, index: number) => ({
    ...item,
    uniqueId: `${areaType}-${index}-${item.count}`
  }));

  const getCountryCode = (countryName: string): string => {
    return Object.entries(isoCountries).find(([_, name]) => name === countryName)?.[0] || '';
  };

  const getTickContent = (payload: { value: string }): JSX.Element => {
    const uniqueId = payload.value;
    const itemData = sortedData.find((item: LocationCount) => item.uniqueId === uniqueId);
    if (!itemData) return <></>;

    const countryCode = areaType === 'countries' ? countryList().getValue(itemData.country) : getCountryCode(itemData.country);

    // Desktop view
    if (isDesktop) {
      return (
        <div key={itemData.uniqueId} className='flex flex-col justify-center items-center'>
          {countryCode && (
            <ReactCountryFlag countryCode={countryCode} width='fit-content' className="emojiFlag" />
          )}
          <Body2
            color={SystemColors.PRIMARY_TEXT_COLOR}
            style={{ width: 'fit-content', textAlign: 'center' }}
          >
            {areaType === 'countries' ? itemData.country : itemData.city}
          </Body2>
          {areaType === 'cities' && (
            <Caption
              color={SystemColors.SECONDARY_TEXT_COLOR}
              style={{ width: 'fit-content' }}
            >
              {countryCode}
            </Caption>
          )}
          <Caption
            color={SystemColors.SECONDARY_TEXT_COLOR}
            style={{ width: 'fit-content' }}
          >
            {itemData.count} View{itemData.count > 1 ? 's' : ''}
          </Caption>
        </div>
      );
    }
    
    // Mobile view
    return (
      <div key={itemData.uniqueId} className='getTickContentContainer flex justify-between items-center gap-2 w-full' style={{ marginTop: 7 }}>
        <div className='flex items-center gap-2' style={{ width: '70%', overflow: 'hidden' }}>
          {countryCode && (
            <ReactCountryFlag countryCode={countryCode} width='fit-content' className="emojiFlag" />
          )}
          <Body2
            color={SystemColors.PRIMARY_TEXT_COLOR}
            style={{ textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {areaType === 'countries' ? itemData.country : itemData.city}
          </Body2>
        </div>
        <Body2
          color={SystemColors.PRIMARY_TEXT_COLOR}
          style={{ width: '30%', textAlign: 'right' }}
        >
          {millify(itemData.count)}
        </Body2>
      </div>
    );
  };

  // Render different chart layouts based on desktop or mobile view
  return (
    <SymphonyBarChart
      xAxisKey="uniqueId"
      yAxisKey="count"
      direction={isDesktop ? "vertical" : "horizontal"} // Change direction based on view
      tickHeight={isDesktop ? 70 : 50} // Adjust tick height for mobile
      tickWidth={isDesktop ? 90 : 250} // Adjust tick width for mobile
      barWidth={isDesktop ? 90 : 150} // Adjust bar width for mobile
      barHeight={isDesktop ? undefined : 24} // Set bar height for mobile
      tick={getTickContent}
      data={sortedData}
    />
  )
}
