import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import InkArrowImage from 'assets/images/onboarding/inky-arrow.png';
import {
  MainContainer,
  MainTitleContainer,
  MainTitleLabel,
  SubTitelLabel,
  TitlesContainer,
  Chart,
  ChartDescriptionContainer,
  ChartDescriptionLabel,
  ChartLabelsContainer,
  ChartsWrapper,
  ChartsContainer,
  ChartStatContainer,
  ChartStatLabel,
  ConnectPlatformContainer,
  ConnectPlatformLabel,
  FanbaseSizeContainer,
  FanbaseSizeDescriptionLabel,
  FanbaseSizeInfoContainer,
  FanbaseSizeLabel,
  InkyArrow,
  InstagramLocoContainer,
  SoundCloudLogoContainer,
  SpotifyIconContainer,
  YoutubeIconContainer,
  MainBackground,
  GrayContainer,
  useStyles,
} from "./style"
import { motion } from "framer-motion";
import { ReactComponent as SpotifyIcon } from "assets/images/streaming/square-icons/spotify-sqr.svg";
import { ReactComponent as CoundCloudIcon } from "assets/images/onboarding/soundCloudWhite.svg";
import { ReactComponent as InstagramIcon } from "assets/images/instagram-bordered-white.svg";
import { ReactComponent as YoutubeIcon } from "assets/images/youtube-white.svg";
import { ReactComponent as TiktokIcon } from "assets/images/tiktok.svg";
import millify from 'millify';
import Soundcloud from '../../../../../components/connect/Soundcloud';
import YouTubeConnect from "../ConnectPlatformModal/Youtube";
import { pageView, setUserProperty, track } from 'analytics';
import moment from 'moment';
import Axios from 'helpers/Interceptor';
import { Switch, useMediaQuery, useTheme } from '@material-ui/core';
import OnboardingGraph from 'components/dashboard/OnboardingGraph';
import Instagram from '../ConnectPlatformModal/Instagram';
import { ConnectorInstagramPagesType, CurrentBrand, OnboardingArtist, SpotifyArtist, UserData } from 'types/global';
import TiktokConnect from '../ConnectPlatformModal/TikTok';
import InstagramBusinessDiscoveryConnect from '../ConnectPlatformModal/InstagramBusinessDiscovery';
import SpotifyConnect from '../ConnectPlatformModal/Spotify';
import { SEARCH_SOURCE_RESULTS } from 'pages/post-auth/AddArtist';
import SoundcloudConnectv2 from 'components/connect/Soundcloud';
import CreateProfileUpgradeToPro from 'components/shareable/UpgradeToProModal/onboarding-upgrade';
import PrimaryButton from 'components/shareable/PrimaryButton';
import { Container, StyledLabel } from 'styles/shared';
import { TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE } from '../../../../../constants';
import SwitchWithLabel from 'components/shareable/SwitchWithLabel';
import { isOrganizationAdministrator } from 'utils';
import { useCurrentTeam } from 'Hooks/CurrentTeamContext';
import { addBrandToOrganization } from 'services/symphonyApi/organizationService';

const siteIconsData = {
  spotify: require("assets/images/streaming/square-icons/spotify-sqr.svg").default,
  youtube: require("assets/images/youtube.svg").default,
  instagram: require("assets/images/insta.svg").default,
  tiktok: require("assets/images/tiktok.svg").default,
  soundcloud: require("assets/images/soundcloud.svg").default
}

interface Props {
  brandCreated?: CurrentBrand;
  handleGoNext: () => void;
  setSearchResult: any;
  searchResults: any[];
  setSearchSourceResults: (source: SEARCH_SOURCE_RESULTS) => void
  searchSourceResults: string,
  setSpotifyArtist: any;
  handleShowAlreadyExistsModal: (artist: OnboardingArtist) => void;
  user: UserData;
}

const ProfilePreview: FunctionComponent<Props> = ({
  brandCreated,
  handleGoNext,
  setSearchResult,
  searchResults,
  setSearchSourceResults,
  searchSourceResults,
  setSpotifyArtist,
  handleShowAlreadyExistsModal,
  user
}: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));

  // user's onboarding answers
  const onboardingAnswers = user.metadata?.onboarding_answers
  const accountType = onboardingAnswers?.account_type || 'creator'

  // loading states 
  const [loading, setLoading] = useState(false);
  const [nextScreenLoading, setNextScreenLoading] = useState(false);

  // organization settings
  const { organization: currentOrganization, hasProAccountOverage } = useCurrentTeam()
  const hasAdminAccess = currentOrganization && isOrganizationAdministrator(currentOrganization)
  const defaultAddToOrg = Boolean(hasAdminAccess && currentOrganization.status === 'ready')

  // if the organization s already setup and ready for new artists, then ask the
  // user if they want to add to the org + upgrade to Pro
  const [upgradeToPro, setUpgradeToPro] = useState(false);
  const [addToTeam, setAddToTeam] = useState(true);

  // connecting states (deprecated)
  const [showConnectModal, setConnectModal] = useState('');

  // local brand
  const [currentBrand, setCurrentBrand] = useState<any>(brandCreated);

  // stats UI presetation 
  const [overallStats, setOverallStats] = useState<any>({});
  const [spotifySearchString, setSpotifySearchString] = useState("");
  const [, setSpotifySearchResult] = useState<any>([]);
  const [selectedInstagramPage, setSelectedInstagramPage] = useState<ConnectorInstagramPagesType | null>(null)
  const [smallGraphData, setSmallGraphData] = useState<any>([
    {
      name: "Spotify Monthly Listeners",
      platformId: 'spotify',
      platformName: "Spotify",
      statIdentifier: 'monthly_listeners',
      statLabel: "Monthly Listeners",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['spotify'],
      needsConnection: currentBrand && currentBrand.connections.spotify_artist_page ? false : true,
      logoComponent: <SpotifyIconContainer><SpotifyIcon /></SpotifyIconContainer>,
    },
    {
      name: "Spotify Followers",
      platformId: 'spotify',
      platformName: "Spotify",
      statIdentifier: 'followers',
      statLabel: "Followers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['spotify'],
      needsConnection: currentBrand && currentBrand.connections.spotify_artist_page ? false : true,
      logoComponent: <SpotifyIconContainer><SpotifyIcon /></SpotifyIconContainer>,
    },
    {
      name: "YouTube Subscribers",
      platformId: 'youtube',
      platformName: "YouTube",
      statIdentifier: 'subscribers',
      statLabel: "Subscribers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['youtube'],
      needsConnection: currentBrand && currentBrand.connections.youtube_channel ? false : true,
      logoComponent: <YoutubeIconContainer><YoutubeIcon /></YoutubeIconContainer>,
    },
    {
      name: "TikTok Followers",
      platformId: 'tiktok',
      platformName: "TikTok",
      statIdentifier: 'followers',
      statLabel: "Followers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['tiktok'],
      needsConnection: currentBrand && currentBrand.connections.tiktok && currentBrand.connections.tiktok.url && currentBrand.connections.tiktok.username ? false : true,
      logoComponent: <SpotifyIconContainer><TiktokIcon /></SpotifyIconContainer>,
    },
    {
      name: "SoundCloud Followers",
      platformId: 'soundcloud',
      platformName: "SoundCloud",
      statIdentifier: 'followers',
      statLabel: "Followers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['soundcloud'],
      needsConnection: currentBrand && currentBrand.connections.soundcloud_artist_page ? false : true,
      logoComponent: <SoundCloudLogoContainer><CoundCloudIcon /></SoundCloudLogoContainer>,
    },
    {
      name: "Instagram Followers",
      platformId: 'instagram',
      platformName: "Instagram",
      statIdentifier: 'followers',
      statLabel: "Followers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['instagram'],
      needsConnection: currentBrand && (
        currentBrand.connections.business_discovery_instagram ||
        ((currentBrand.connections.instagram_page && currentBrand.connections.instagram_page.id) && (currentBrand.connections.facebook_page && currentBrand.connections.facebook_page.id) && (!currentBrand.connections.reauthenticateFbUser))
      ) ? false : true,
      logoComponent: <InstagramLocoContainer><InstagramIcon /></InstagramLocoContainer>,
    },
  ])

  function getFanbaseData() {
    return
    const getOverallFanbaseGrowth = async () => {
      setLoading(true);

      // Retrieve analytics data from Analytics API
      // the api needs a startDate and chartPeriod to get range of data
      const today = moment().add(1, 'days').startOf('day')
      const endDate = today.format('YYYY-MM-DD');
      const chartPeriod = 'weekly';

      let startDate = today.subtract(7, 'days').format('YYYY-MM-DD');
      const promises = smallGraphData.map((d: any) => {
        const { platformId, statIdentifier, name } = d;
        const url = `/dashboard/brand/${currentBrand.id}/fanbase/${platformId}/${statIdentifier}?startDate=${startDate}&endDate=${endDate}&chartPeriod=${chartPeriod}`
        return Axios.get(url)
      })

      Promise.all(promises)
        .then((results: any[]) => {
          plotChartData(results.map(result => result.data.data))
        })
        .catch((err: any) => {
          console.error('errored fetching data', err);
        })
        .finally(() => {
          setLoading(false);
        })
    }
    getOverallFanbaseGrowth()
  }

  const plotChartData = (dataArray: any) => {
    const currentPlatformData = smallGraphData
    const overallDataCalculator: any = {}

    dataArray.map((data: any) => {
      const {
        platform,
        stat,
      } = data

      // returns the data for the chart 
      const dataForChart: any = plotChart(data)

      var foundItem = currentPlatformData.find((d: any) => {
        return platform === d.platformId && stat == d.statIdentifier
      })

      foundItem.chartData.data = dataForChart.chartData
      foundItem.secondaryData = dataForChart.growthData

      // now we've got the items populated for each graph 

      var minValue = 0
      dataForChart.chartData.map((dateValue: any) => {
        minValue = minValue === 0 ? dateValue.value : Math.min(dateValue.value, minValue)
        if (!overallDataCalculator[dateValue.date]) {
          overallDataCalculator[dateValue.date] = {
            datetime: dateValue.date,
            values: []
          }
        }

        overallDataCalculator[dateValue.date].values.push(parseInt(dateValue.value))
      })
    })

    // overalldatacalculator is an object with:
    /**
     * {
     *  datetime: 2021-08-09T00:00:00.000Z
     *  values: []
     * }
     * 
     * Just calculate the average for now
     */

    var dateValues = Object.keys(overallDataCalculator)
    var weeklyOverallData = dateValues.map((d: any) => {
      const { values, datetime } = overallDataCalculator[d]
      const filteredValues = values.filter((v: any) => v !== 0)
      const total = filteredValues.reduce((acc: any, c: any) => acc + c, 0);
      // const avg = total / filteredValues.length;

      return {
        date: datetime,
        value: total

      }
    })

    const overallChart = {
      chartData: weeklyOverallData,
      percentage: 0
    }

    // in the case where NO DATA is available for any platform
    //  (for example, where an artist doesnt have any past data in chartmetrics and we're starting fresh),
    // show an empty chart
    if (overallChart.chartData.length > 0) {
      overallChart.percentage = ((weeklyOverallData[weeklyOverallData.length - 1].value - weeklyOverallData[0].value) / weeklyOverallData[0].value) * 100
    }

    setSmallGraphData(currentPlatformData)
    setOverallStats(overallChart)
  }

  const plotChart = (chartData: any) => {
    const totalDays = 7
    const lastWeekStartDate = moment().subtract(totalDays, 'days').startOf('day');

    /**
     * an item in the api's data reponse array looks like
     * {
     *    datetime: 2021-08-09T00:00:00.000Z
     *    fanbase_count: 123
     * }
     **/

    /**
     * get 7 days ago date
     *  - get each day for last 7 days
     */
    var localChartData: any = []

    // if no data was returned at all, it means we're still gathering data
    //  - return a fully empty chart breakdown
    if (chartData.chartData.length === 0) {
      return {
        chartData: localChartData,
        growthData: {
          currentGrowthValue: 0,
          percentage: 0
        }
      }
    }

    for (var i = 0; i < totalDays; i++) {
      // get each day of data based on avail date
      const date = moment(lastWeekStartDate).add(i, 'days').startOf('day');
      let dateData = chartData.chartData.find((d: any) => {
        return moment(d.datetime).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')
      })

      // if the dateData for the given day is found, then use that as the
      // lastVal AND set it for the day
      var dateDetails = {
        date: date.format("dd, MM/DD"),
        value: 0

      }

      if (dateData) {
        var fanCount = parseInt(dateData.fanbase_count)

        // if the fanbase count > 0, set it

        if (fanCount > 0) {
          // lastVal = dateData.fanbase_count
          dateDetails = {
            date: date.format("dd, MM/DD"),
            value: fanCount

          }
        } else {
          // otherwise, 
          //  - if this is the first value, get the next value
          //  - if this is the last value, get the value before it
          //  - if this is a middle value, ttake the averages of before and after
          if (i === 0) {
            const nextDate = date.clone().add(1, 'days').startOf('day');
            let nextDateData = chartData.chartData.find((d: any) => {
              return moment(d.datetime).format('YYYY-MM-DD') === moment(nextDate).format('YYYY-MM-DD')
            })

            if (nextDateData) {
              // if there is a found value, then use that value 
              var nextDayCount = parseInt(nextDateData.fanbase_count)

              if (nextDayCount > 0) {
                dateDetails = {
                  date: date.format("dd, MM/DD"),
                  value: nextDayCount
                }
              }
            }
          } else if (i === 6) {
            //  - if this is the last value, get the value before it
            const prevDate = date.clone().subtract(1, 'days').startOf('day');
            let prevDateData = chartData.chartData.find((d: any) => {
              return moment(d.datetime).format('YYYY-MM-DD') === moment(prevDate).format('YYYY-MM-DD')
            })

            if (prevDateData) {
              // if there is a found value, then use that value 
              var prevDayCount = parseInt(prevDateData.fanbase_count)

              if (prevDayCount > 0) {
                dateDetails = {
                  date: date.format("dd, MM/DD"),
                  value: prevDayCount
                }
              }
            }
          } else {
            //  - if this is a middle value, take the averages of before and after
            const nextDate = date.clone().add(1, 'days').startOf('day');
            let nextDateData = chartData.chartData.find((d: any) => {
              return moment(d.datetime).format('YYYY-MM-DD') === moment(nextDate).format('YYYY-MM-DD')
            })

            const prevDate = date.clone().subtract(1, 'days').startOf('day');
            let prevDateData = chartData.chartData.find((d: any) => {
              return moment(d.datetime).format('YYYY-MM-DD') === moment(prevDate).format('YYYY-MM-DD')
            })

            if (prevDateData && nextDateData) {
              var prevDayCount = parseInt(prevDateData.fanbase_count)
              var nextDayCount = parseInt(nextDateData.fanbase_count)

              if (nextDayCount > 0 && prevDateData > 0) {
                dateDetails = {
                  date: date.format("dd, MM/DD"),
                  value: (nextDayCount + prevDayCount) / 2
                }
              }
            }
          }
        }

      } else {
        if (i === 0) {
          const nextDate = date.clone().add(1, 'days').startOf('day');
          let nextDateData = chartData.chartData.find((d: any) => {
            return moment(d.datetime).format('YYYY-MM-DD') === moment(nextDate).format('YYYY-MM-DD')
          })


          if (nextDateData) {
            // if there is a found value, then use that value 
            var nextDayCount = parseInt(nextDateData.fanbase_count)
            if (nextDayCount > 0) {
              dateDetails = {
                date: date.format("dd, MM/DD"),
                value: nextDayCount
              }
            }
          } else {
            // loop thru the dates until a value is found 
            var dateAddition = 2
            var dataFound: any = null
            while (!dataFound) {
              if (dateAddition === 6) break

              let attemptedDate = date.clone().add(dateAddition, 'days').startOf('day');

              let attemptedDateData = chartData.chartData.find((d: any) => {
                return moment(d.datetime).format('YYYY-MM-DD') === moment(attemptedDate).format('YYYY-MM-DD')
              })

              if (attemptedDateData) {
                dataFound = attemptedDateData
              } else {
              }

              dateAddition++
            }

            if (dataFound) {

              var foundDataCount = parseInt(dataFound.fanbase_count)
              if (foundDataCount > 0) {
                dateDetails = {
                  date: date.format("dd, MM/DD"),
                  value: foundDataCount
                }
              }
            }
          }
        } else if (i === 6) {
          //  - if this is the last value, get the value before it
          const prevDate = date.clone().subtract(1, 'days').startOf('day');
          let prevDateData = chartData.chartData.find((d: any) => {
            return moment(d.datetime).format('YYYY-MM-DD') === moment(prevDate).format('YYYY-MM-DD')
          })

          if (prevDateData) {
            // if there is a found value, then use that value 
            var prevDayCount = parseInt(prevDateData.fanbase_count)

            if (prevDayCount > 0) {
              dateDetails = {
                date: date.format("dd, MM/DD"),
                value: prevDayCount
              }
            }
          }
        } else {
          //  - if this is a middle value, take the averages of before and after
          const nextDate = date.clone().add(1, 'days').startOf('day');
          let nextDateData = chartData.chartData.find((d: any) => {
            return moment(d.datetime).format('YYYY-MM-DD') === moment(nextDate).format('YYYY-MM-DD')
          })

          const prevDate = date.clone().subtract(1, 'days').startOf('day');
          let prevDateData = chartData.chartData.find((d: any) => {
            return moment(d.datetime).format('YYYY-MM-DD') === moment(prevDate).format('YYYY-MM-DD')
          })


          if (prevDateData && nextDateData) {
            var prevDayCount = parseInt(prevDateData.fanbase_count)
            var nextDayCount = parseInt(nextDateData.fanbase_count)

            if (nextDayCount > 0 && prevDayCount > 0) {
              dateDetails = {
                date: date.format("dd, MM/DD"),
                value: (nextDayCount + prevDayCount) / 2
              }
            }
          } else if (prevDateData) {
            var prevDayCount = parseInt(prevDateData.fanbase_count)
            if (prevDayCount > 0) {
              dateDetails = {
                date: date.format("dd, MM/DD"),
                value: prevDayCount
              }
            }
          } else if (nextDateData) {
            var nextDayCount = parseInt(nextDateData.fanbase_count)
            if (nextDayCount > 0) {
              dateDetails = {
                date: date.format("dd, MM/DD"),
                value: nextDayCount
              }
            }
          } else {
            // no data found for previous date OR next date
            // -- loop thru the dates until a value is found 
            var dateAddition = 2
            var dataFound: any = null
            while (!dataFound) {
              if (dateAddition === 6) break

              let attemptedDate = date.clone().add(dateAddition, 'days').startOf('day');
              let attemptedDateData = chartData.chartData.find((d: any) => {
                return moment(d.datetime).format('YYYY-MM-DD') === moment(attemptedDate).format('YYYY-MM-DD')
              })

              if (attemptedDateData) {
                dataFound = attemptedDateData
              } else {
              }

              dateAddition++
            }

            if (dataFound) {

              var foundDataCount = parseInt(dataFound.fanbase_count)
              if (foundDataCount > 0) {
                dateDetails = {
                  date: date.format("dd, MM/DD"),
                  value: foundDataCount
                }
              }
            }
          }
        }
      }
      localChartData.push(dateDetails)
    }

    // in the case where the first few days have 0 data, and the next few days dont,
    // update all of the first few days to the firstvalue found
    var firstFoundValue = 0
    localChartData.map((o: {
      date: string,
      value: number
    }, idx: number) => {
      const {
        value
      } = o

      if (value > 0) {
        firstFoundValue = value
      }
    })

    // now go thru the numbers and set each one to the fixed one
    localChartData = localChartData.map((o: {
      date: string,
      value: number
    }, idx: number) => {
      const {
        value,
        date
      } = o

      if (value === 0) {
        return {
          date: date,
          value: firstFoundValue
        }
      } else {
        return o
      }
    })


    return {
      chartData: localChartData,
      growthData: {
        currentGrowthValue: chartData.growthData.currentGrowthValue,
        percentage: ((localChartData[localChartData.length - 1].value - localChartData[0].value) / localChartData[0].value) * 100
      }
    }
  }

  // Function to check if the brand has data and fetch it if necessary
  async function brandHasData() {
    // If currentBrand exists, fetch fanbase data immediately
    if (currentBrand) {
      getFanbaseData()
    } else {
      // If currentBrand doesn't exist, retrieve the brand ID from localStorage
      const brandId = localStorage.getItem('selected-brand');

      // Create a promise to wait for the brand data
      var brandPromise = await new Promise(async (resolve: any, reject: any) => {
        // Set up an interval to check for brand data every second
        var refreshIntervalId = setInterval(async () => {
          // Fetch brand data from the API
          const brandResponse = await Axios.get(`/brand/${brandId}`);
          const brandData = brandResponse.data.data

          // If brand data is received, resolve the promise
          if (brandData) {
            clearInterval(refreshIntervalId);
            resolve(brandData)
          }
        }, 1000);
      })

      // Update the currentBrand state with the fetched brand data
      setCurrentBrand(brandPromise)
    }
  }

  // Effect hook to call brandHasData when currentBrand changes
  useEffect(() => {
    if (currentBrand !== null) {
      brandHasData()
    }
  }, [currentBrand])

  // Effect hook to fetch fanbase data on component mount
  useEffect(() => {
    getFanbaseData();
  }, []);

  function trackConnection(name: string) {
    track('Linked Platform',
      {
        brand_id: currentBrand.id,
        brand_slug: currentBrand.slug,
        brand_name: currentBrand.name,
        platform: name,
        page: "add-artist"
      });

    setUserProperty(`${name}_linked`, true)
  }

  async function handleExploreSymphony() {
    setNextScreenLoading(true)

    // before we go to the next page, we should first check
    // if the user wanted to add this artist to their organization +
    // upgrae to pro
    if (currentOrganization && currentOrganization.status === 'ready' && addToTeam) {
      // update the brand by adding it to the organization + making it pro
      await addBrandToOrganization(currentBrand.slug, upgradeToPro)
    }
    handleGoNext()
  }

  function updateConnector(platform: string, stat: string) {
    const currentPlatformsData = smallGraphData
    const platformDataIndex = smallGraphData.findIndex(({ platformId, statIdentifier }: { platformId: string; statIdentifier: string }) => platformId === platform && statIdentifier === stat)
    const platformDataUpdated = currentPlatformsData[platformDataIndex];
    platformDataUpdated.needsConnection = false;
    currentPlatformsData[platformDataIndex] = platformDataUpdated;
    setSmallGraphData(currentPlatformsData)
  }

  const calculatedFanbaseSize = useMemo(() => millify(overallStats.chartData?.reverse()[0]?.value || 0), [overallStats.chartData]);

  const filteredGraphsToShow = smallGraphData.sort((a: { needsConnection: boolean }, b: { needsConnection: boolean }) => {
    if (a.needsConnection && !b.needsConnection) return 1
    else if (b.needsConnection && !a.needsConnection) return -1
    else return 0
  })
    .filter((c: any) => {
      // this hides all of the items that arent currently connected
      if (c.needsConnection) return false
      return true
    })

  function AddToOrganizationFlow() {
    let totalAdditionalProCostText;
    if (hasProAccountOverage) {
      totalAdditionalProCostText = `Upgrade for a discounted Team Plan rate of $${TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE} / month.`

    } else {
      totalAdditionalProCostText = 'You have an extra Pro plan included in your Team Plan subscription.'
    }
    return (
      <Container display='flex' flexDirection='column' gap="8px" marginTop='24px'>
        <TitlesContainer>
          <MainTitleContainer>
            <MainTitleLabel>Let’s get started!</MainTitleLabel>
          </MainTitleContainer>
        </TitlesContainer>

        {/* TODO: Review if we need to let a user not add to Org */}
        {/* {addToOrganization && (
          <SwitchWithLabel
            label={`Would you like to add this profile "${currentBrand?.name}" to your team "${user.organization?.name}"?`}
            checked={addToTeam}
            onChange={() => setAddToTeam(!addToTeam)}
          />
        )} */}
        {addToTeam && (

          <Container borderRadius='8px'
            justifyContent='space-between'
            alignItems='center'
            style={{
              background: 'linear-gradient(0deg, rgba(136, 0, 255, 0.08), rgba(136, 0, 255, 0.08)), #FFFFFF'
            }}
            padding='12px 16px' display='flex'>
            <Container display='flex' flexDirection='column' gap='4px'>
              <StyledLabel color='#8800FF' fontSize={18}>Do you want to upgrade to Pro?</StyledLabel>
              <StyledLabel color='#8800FF' fontSize={14}>{totalAdditionalProCostText}</StyledLabel>
            </Container>
            <Switch
              onChange={() => setUpgradeToPro(!upgradeToPro)}
              checked={upgradeToPro}
              {...{ classes: { root: classes.automatedSwitch } }}
            />
          </Container>
        )}
      </Container>
    )
  }

  // if the user only linked 1 item, and the fanabse size is 0,
  //  hide the graphs and the first section
  const hideLetsGrowSection = true || calculatedFanbaseSize === '0' && filteredGraphsToShow.length === 1

  function renderActionView() {
    const userRedeemedFreeTrialAlready = user?.metadata?.free_trial_redeemed || false;

    let mainActions = [];

    let usersOrganizationReady = user.organization?.status === 'ready'
    // Conditionally push AddToOrganizationFlow component if the organization status is 'ready'
    if (usersOrganizationReady) {
      mainActions.push(<AddToOrganizationFlow key="addToOrganization" />);
      mainActions.push(
        <div key="exploreSymphonyButton" className={`${userRedeemedFreeTrialAlready ? 'mt-5' : 'mt-3'} mx-auto`}>
          <PrimaryButton
            width='100%'
            loading={nextScreenLoading}
            onClick={handleExploreSymphony}
            variant='outlined'
            text={userRedeemedFreeTrialAlready || usersOrganizationReady ? "Explore Symphony" : "Explore Symphony for free"} />
        </div>
      );
    } else {
      // Conditionally render OnboardingUpgradeToPro component if the free trial has not been redeemed
      if (!userRedeemedFreeTrialAlready) {
        mainActions.push(
          <CreateProfileUpgradeToPro
            accountType={accountType}
            key="onboardingUpgradeToPro"
            source="Onboarding Flow"
            secondaryButtons={[(
              <div key="exploreSymphonyButton" className={`mt-1 mx-auto`}>
                <PrimaryButton
                  width='100%'
                  loading={nextScreenLoading}
                  onClick={handleExploreSymphony}
                  variant='outlined'
                  text={userRedeemedFreeTrialAlready || usersOrganizationReady ? "Explore Symphony" : "Skip for now"} />
              </div>
            )]}
          />
        );
      }
    }


    // Return the array of components to render
    return mainActions;
  }


  return (
    <MainBackground>
      <MainContainer mobile={setResponsiveView}>
        {!hideLetsGrowSection && calculatedFanbaseSize && (
          <>
            <TitlesContainer>
              <MainTitleContainer>
                <MainTitleLabel>Let’s grow your fanbase!</MainTitleLabel>
                <SubTitelLabel>Here’s your current fanbase...</SubTitelLabel>
              </MainTitleContainer>
            </TitlesContainer>
            {!loading && <FanbaseSizeContainer>
              <InkyArrow src={InkArrowImage} />
              <FanbaseSizeInfoContainer>
                <FanbaseSizeDescriptionLabel>Total Fanbase Size</FanbaseSizeDescriptionLabel>
                <FanbaseSizeLabel>{calculatedFanbaseSize}</FanbaseSizeLabel>
              </FanbaseSizeInfoContainer>
            </FanbaseSizeContainer>}
          </>
        )}

        <ChartsContainer paddingY={setResponsiveView ? "0" : "32px"}>
          <ChartsWrapper gap={setResponsiveView ? "32px 16px" : "16px 32px"}>
            {!hideLetsGrowSection && filteredGraphsToShow
              .map((c: any, index: number) => {
                const {
                  name,
                  platformId,
                  platformName,
                  statIdentifier,
                  chartData,
                  secondaryData,
                  imageAsset,
                  statLabel,
                  needsConnection,
                  logoComponent,
                } = c

                if (loading) {
                  return (
                    <Chart key={`${platformId}-${statIdentifier}`} mobile={setResponsiveView}>
                      <GrayContainer />
                    </Chart>
                  )
                } else if (needsConnection) {
                  return (
                    <Chart key={`${platformId}-${statIdentifier}`} mobile={setResponsiveView}>
                      <ConnectPlatformContainer onClick={() => setConnectModal(platformId)}>
                        {logoComponent}
                        <ConnectPlatformLabel>{setResponsiveView ? "Connect" : `Connect ${platformName} to track your ${(statLabel as string).toLowerCase()}`}</ConnectPlatformLabel>
                      </ConnectPlatformContainer>
                    </Chart>
                  )
                } else if (chartData.data?.length) {
                  return (
                    <Chart
                      fullWidth={filteredGraphsToShow.length === 1}

                      key={`${platformId}-${statIdentifier}`} mobile={setResponsiveView}>
                      <OnboardingGraph
                        key={`${platformId}-${statIdentifier}`}
                        platformName={platformName}
                        subtitle={statLabel}
                        graphData={chartData.data || []}
                      />
                      <ChartDescriptionContainer>
                        {logoComponent}
                        <ChartLabelsContainer>
                          <ChartStatContainer>
                            <ChartStatLabel>{millify(secondaryData.currentGrowthValue || 0)}</ChartStatLabel>
                          </ChartStatContainer>
                          <ChartDescriptionLabel>{statLabel}</ChartDescriptionLabel>
                        </ChartLabelsContainer>
                      </ChartDescriptionContainer>
                    </Chart>
                  )
                } else {
                  return (
                    <Chart
                      fullWidth={filteredGraphsToShow.length === 1}

                      key={`${platformId}-${statIdentifier}`} mobile={setResponsiveView}>
                      <OnboardingGraph
                        key={`${platformId}-${statIdentifier}`}
                        platformName={platformName}
                        subtitle={statLabel}
                        graphData={chartData.data || []}
                      />
                    </Chart>
                  )
                }
              })}
            <div className='w-full'>
              {renderActionView()}

            </div>

          </ChartsWrapper>
        </ChartsContainer>
      </MainContainer>

      <TiktokConnect
        open={showConnectModal === 'tiktok'}
        localSave={false}
        closeModal={() => setConnectModal('')}
        artist={currentBrand}
        selectTiktokArtist={() => {
          trackConnection("tiktok")
          getFanbaseData()
          track('Linked Platform during Onboarding', {
            name: 'tiktok'
          })
          updateConnector('tiktok', 'followers')
        }}
        selectedTiktokArtist={currentBrand?.connections?.tiktok}
      />
      <SoundcloudConnectv2
        open={showConnectModal === "soundcloud"}
        closeModal={() => setConnectModal("")}
        selected={currentBrand?.connections?.soundcloud}
        artist={currentBrand}
        onSave={() => {
          trackConnection("soundcloud")
          getFanbaseData()
          track('Linked Platform during Onboarding', {
            name: 'soundcloud'
          })
          updateConnector("soundcloud", "followers")
        }}
      />
      <YouTubeConnect
        open={showConnectModal === "youtube"}
        setYoutubeSearchString={console.log}
        youtubeSearchString=""
        youtubeSearchResults={[]}
        artist={currentBrand}
        selectYoutubeChannel={currentBrand?.connections?.youtube}
        closeModal={() => setConnectModal("")}
        selectedYoutubeChannel={null}
        localSave={false}
        onSave={() => {
          trackConnection("youtube")
          getFanbaseData()
          track('Linked Platform during Onboarding', {
            name: 'youtube'
          })
          updateConnector("youtube", "subscribers")
        }}
      />
      <InstagramBusinessDiscoveryConnect
        open={showConnectModal === 'instagram'}
        localSave={false}
        closeModal={() => setConnectModal('')}
        artist={currentBrand}
        selectInstagramArtist={() => {
          trackConnection("instagram")
          getFanbaseData()
          track('Linked Platform during Onboarding', {
            name: 'instagram'
          })
          updateConnector("instagram", "followers")
        }}
        selectedInstagramArtist={currentBrand?.connections?.business_discovery_instagram}
      />
      <SpotifyConnect
        open={showConnectModal === "spotify"}
        closeModal={() => setConnectModal('')}
        artist=""
        setSpotifySearchResult={setSpotifySearchResult}
        setSpotifySearchString={setSpotifySearchString}
        spotifySearchString={spotifySearchString}
        onBoardingArtist={currentBrand}
        searchResults={searchResults}
        setSearchResult={setSearchResult}
        searchSourceResults={searchSourceResults}
        setSearchSourceResults={setSearchSourceResults}
        setArtist={setCurrentBrand}
        setSpotifyArtist={(artist: SpotifyArtist | null) => {
          if (!artist) return;
          trackConnection("spotify");
          track('Linked Platform during Onboarding', {
            name: 'spotify'
          })
          getFanbaseData()
          updateConnector("spotify", "monthly_listeners")
          updateConnector("spotify", "followers")
        }}
        handleShowAlreadyExistsModal={handleShowAlreadyExistsModal}
        localSave={false}
      />
    </MainBackground>
  )
}

export default ProfilePreview;
