import { useState } from "react";
import { Body2, Caption } from "components/shareable/Typography";
import { SystemColors } from "../../types/globalStyles";
import { ReactComponent as FacebookRoundedIcon } from "assets/images/facebook-rounded-icon.svg";
import { ImageContainer, MainContainer, SuccessChip, LinkedIgContainer } from "./styles";
import { ReactComponent as InstagramBwIcon } from "assets/images/instagram-bw.svg";
import { ReactComponent as InstagramIcon } from "assets/images/instagram-bordered-icon.svg";
import { ReactComponent as CheckGreen } from 'assets/images/check-green.svg';
import SecondaryButton from "components/shareable/SecondaryButton";

interface Props {
  title: string;
  subtitle: string;
  iconImg?: string;
  text?: string;
  buttonText?: string;
  instagramIcon?: boolean;
  fbIcon?: boolean;
  showSubtextIcon?: boolean;
  connectedChip?: boolean;
  connectedText?: string;
  emptyConnection?: boolean;
  disabledButton?: boolean;
  igLinked?: string;
  onClick?: () => Promise<void> | void;
}

export function AccountContainer({
  title,
  subtitle,
  iconImg,
  text,
  buttonText,
  instagramIcon,
  fbIcon,
  showSubtextIcon = true,
  connectedChip,
  connectedText,
  igLinked,
  emptyConnection,
  disabledButton,
  onClick,
}: Props) {

  const [loading, setLoading] = useState(false);

  const handleClick = async() => {
    setLoading(true);
    onClick && await onClick();
    setLoading(false);
  }

  const renderIcon = () => {
    if (iconImg) {
      return <img className="w-10 h-10 rounded-lg" src={iconImg} alt="icon" />;
    }
    if (instagramIcon) {
      return (
        <ImageContainer backgroundColor={SystemColors.PAPER_DIVIDER_CONTAINER_DARK}>
          <InstagramBwIcon />
        </ImageContainer>
      );
    }
    if (fbIcon) {
      return (
        <ImageContainer>
          <FacebookRoundedIcon />
        </ImageContainer>
      );
    }
    return null;
  };

  return (
    <MainContainer>
      <div className="w-full flex gap-2 items-center">
        {renderIcon()}
        <div className="flex flex-col">
          {(instagramIcon && showSubtextIcon && !emptyConnection) && (
            <div className="flex gap-1 items-center">
              <InstagramIcon width={16} height={16} />
              <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                IG Page
              </Caption>
            </div>
          )}
          {(fbIcon && showSubtextIcon && !emptyConnection) && (
            <div className="flex gap-1 items-center">
              <FacebookRoundedIcon width={16} height={16} />
              <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                FB Page
              </Caption>
            </div>
          )}
          <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>{title}</Body2>
          <Caption color={SystemColors.PRIMARY_TEXT_COLOR}>
            {subtitle}
          </Caption>
          <Caption color={SystemColors.PRIMARY_TEXT_COLOR}>{text}</Caption>
          {igLinked && (
            <LinkedIgContainer>
              <Caption color={SystemColors.PRIMARY_TEXT_COLOR}>Linked to: </Caption>
              <InstagramIcon width={12} height={12} />
              <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>@{igLinked} </Caption>
            </LinkedIgContainer>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2">
        {(buttonText && onClick) && (
          <SecondaryButton text={buttonText} onClick={handleClick} disabled={disabledButton || loading} loading={loading} height="39px"/>
        )}
        {connectedChip && (
          <SuccessChip>
            <Body2 color={SystemColors.SUCCESS} style={{ textWrap: 'nowrap' }}>{connectedText ? connectedText : 'Connected'}</Body2>
            <CheckGreen stroke="green"/>
          </SuccessChip>
        )}
      </div>
    </MainContainer>
  );
}
