/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useContext, useEffect, useState } from "react"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { ConversionsTask, CONVERSIONS_VIDEOS, VideoData } from "types/global"
import { CardsSection, StyledSection } from "./styles"
import StepVideoContainer from "components/shareable/StepVideoContainer"
import { ConversionsSetupDetails } from "./ConversionsStatus/ConversionsSetupDetails"
import {
  saveConversionsToken,
  validateConversionsToken,
  finishConversionsSetup,
  getLinkedPixels,
  getAdAccountBusiness,
} from "./api"
import useCommonStates from "./Hooks/useCommonStates"
import useMediaBreakpoints from "Hooks/useMediaBreakpoints"
import { Steps } from "./utils"
import EditConversionsStepModal from "./Components/EditConversionsStepModal"
import useModal from "Hooks/useModal"
import FinishedConversionsSetupModal from "./Components/FinishedConversionsSetupModal"
import ConnectFBPixelModal from "./Components/ConnectFBPixelModal"
import useEditModalHandler from "./Hooks/useEditModalHandler"
import useSetInitialData from "./Hooks/useSetInitialData"
import { SelectAdAccountModal } from "./ConversionsSteps/FacebookAdAccount/SelectAdAccountModal/SelectAdAccountModal"
import { StepFacebookAdAccount } from "./ConversionsSteps/FacebookAdAccount"
import StepFacebookPixel from "./ConversionsSteps/FacebookPixel"
import StepFacebookApiToken from "./ConversionsSteps/FacebookApiToken"
import ConversionsIndicator from "pages/post-auth/MarketingPage/Components/ConversionsIndicator"
import useSwitch from "Hooks/useSwitch"
import { setUserProperty, track } from "analytics"
import { isBusinessAdmin } from "services/facebookApi"

const { stepVideos } = CONVERSIONS_VIDEOS

export function AdvancedSettingsTab() {
  const { mobileView, smallView } = useMediaBreakpoints()
  const [isSetupComplete, setIsSetupComplete] = useState<boolean>(false)
  const [nextStep, setNextStep] = useState<ConversionsTask>()
  const [currentVideoData, setCurrentVideoData] = useState<VideoData>({} as VideoData)
  const [currentVideoGetStarted, setCurrentVideoGetStarted] = useState<VideoData>({} as VideoData)

  const { currentBrand, reloadBrand } = useContext(CurrentBrandContext)
  const { connections, conversionsStatus } = currentBrand || {}
  const isCompleted = Boolean(conversionsStatus?.conversions_tasks_completed)
  const conversionsError = Boolean(conversionsStatus?.isError)
  const isSetupValidated = Boolean(
    isCompleted && conversionsStatus?.isEventRegistered
  )
  const {
    facebook_pixel_conversions_api_access_token: brandAccessToken,
    facebook_pixel: brandFBPixel,
    facebook_ad_account: fbAdAccount,
    logged_in_fb_user: loggedInFBUser,
  } = connections || {}

  const { business: fbBusinessAccount } = fbAdAccount || {}

  const { facebookAdAccount } = useCommonStates(Steps.FACEBOOK_AD_ACCOUNT)
  const { facebookPixel } = useCommonStates(Steps.FACEBOOK_PIXEL)
  const { facebookApiToken } = useCommonStates(Steps.FACEBOOK_API_TOKEN)

  const finishedModal = useModal({
    nameTrack: "Completed Setup",
    metadata: {
      brandId: currentBrand?.id,
      brandName: currentBrand?.name,
      brandSlug: currentBrand?.slug,
    }
  })
  const fbPixelConnectModal = useModal()
  const fbAdAccountConnectModal = useModal()
  const watchVideoModal = useModal()
  const conversionsIndicator = useSwitch();

  const finishSetup = () => {
    setUserProperty("Conversions Enabled", true)
    track("Finished Setup", {
      brandId: currentBrand?.id,
      brandName: currentBrand?.name,
      brandSlug: currentBrand?.slug,
    })
  }

  const handleOnCloseFinishedModal = async () => {
    finishedModal.closeModal()
    const response = await finishConversionsSetup()
    if (!response.error) {
      reloadBrand()
    }
  }

  const handleChangeAPIAccessToken = (e: ChangeEvent<HTMLInputElement>) => {
    const { error, setError } = facebookApiToken
    if (error) setError(false)
    const value = e.target.value
    facebookApiToken.setStepValue(value.trim())
  }

  const handleOnSaveAPIAccessToken = async () => {
    facebookApiToken.setLoading(true)
    if (Boolean(brandAccessToken) && !selectedStepToEdit) {
      setSelectedStepToEdit(Steps.FACEBOOK_API_TOKEN)
      return
    }
    const fbPixelId = currentBrand?.connections?.facebook_pixel?.id
    const { error, stepValue: token, setError, setLoading } = facebookApiToken

    setSelectedStepToEdit(null)

    if (!token || !token.trim().length || !fbPixelId) return
    if (error) setError(false)

    const { verified } = await validateConversionsToken({
      conversionsToken: token,
      fbPixelId,
    })

    if (verified) {
      await saveConversionsToken({
        currentBrand,
        token,
        isMobileView: mobileView,
        onLoading: setLoading,
        onError: setError,
        reload: reloadBrand,
      })
    } else {
      setError(true)
    }

    track("Linked Access Token", {
      brandId: currentBrand?.id,
      brandName: currentBrand?.name,
      brandSlug: currentBrand?.slug,
    })

    finishSetup()
    facebookApiToken.setLoading(false)
  }

  const handleOnConnectFacebookPixel = () => {
    const fbPixelAlreadyConnected = Boolean(brandFBPixel?.id) && !brandFBPixel?.is_unavailable

    if (fbPixelAlreadyConnected && !selectedStepToEdit) {
      setSelectedStepToEdit(Steps.FACEBOOK_PIXEL)
      return
    }

    setSelectedStepToEdit(null)
    fbPixelConnectModal.openModal()
  }

  const handleOnConnectFacebookAdAccount = () => {
    setSelectedStepToEdit(null)
    fbAdAccountConnectModal.openModal()
  }

  const handleOnHoverStep = (data: VideoData) => () => setCurrentVideoData(data)

  const validateAdAccount = async () => {
    if (!fbBusinessAccount) {
      facebookAdAccount.setError(true)
    }
    if (fbBusinessAccount && !brandFBPixel) {
      const isAdmin: boolean = await isBusinessAdmin(fbBusinessAccount.id)
      facebookAdAccount.setError(!isAdmin)
    }
  }

  const validatePixel = async () => {
    if (brandFBPixel) {
      facebookPixel.setLoading(true)
      const linkedPixels = await getLinkedPixels({
        fbAdAccount: fbAdAccount,
        onError: facebookPixel.setError,
        onLoading: facebookPixel.setLoading,
        accessToken: loggedInFBUser?.access_token,
      })

      const isPixelLinked = linkedPixels.find(
        (pixelLinked: { id: string }) => pixelLinked?.id === brandFBPixel?.id
      )

      facebookPixel.setError(!Boolean(isPixelLinked))

      track("Verified Pixel", {
        brandId: currentBrand?.id,
        brandName: currentBrand?.name,
        brandSlug: currentBrand?.slug,
        verified: isPixelLinked,
      })

      facebookPixel.setLoading(false)
    }
  }

  const validateInitialData = async () => {
    await validateAdAccount();
    await validatePixel();
    if (!fbBusinessAccount) {
      await getAdAccountBusiness({
        currentBrand,
        reload: reloadBrand,
        accessToken: loggedInFBUser?.access_token,
      })
    }
  }

  const handleOnDisableConversions = () => {
    conversionsIndicator.disable()
    setSelectedStepToEdit(null)
    track("Edit after Setup", {
      brandId: currentBrand?.id,
      brandName: currentBrand?.name,
      brandSlug: currentBrand?.slug,
    })
  }

  const handleOnToggleConversions = () => {
    setSelectedStepToEdit("DISABLE_CONVERSIONS")
  }

  const {
    selectedStepToEdit,
    loadingEditModal,
    handleOnEditModal,
    setSelectedStepToEdit,
    handleCloseEditModal,
  } = useEditModalHandler({
    facebookAdAccount: {
      ...facebookAdAccount,
      onSave: handleOnConnectFacebookAdAccount,
    },
    facebookPixel: {
      ...facebookPixel,
      onSave: handleOnConnectFacebookPixel,
    },
    facebookApiToken: {
      ...facebookApiToken,
      onSave: handleOnSaveAPIAccessToken,
    },
  })

  useSetInitialData({
    facebookAdAccount,
    facebookPixel,
    facebookApiToken,
    currentBrand,
    nextStep,
    setNextStep,
    setCurrentVideoData,
    setIsSetupComplete,
    finishedModal,
    conversionsIndicator,
  })

  useEffect(() => {
    validateInitialData()
    track("Viewed Advanced Settings", {
      brandId: currentBrand?.id,
      brandName: currentBrand?.name,
      brandSlug: currentBrand?.slug,
    })
  }, [])

  useEffect(() => {
    setCurrentVideoGetStarted(stepVideos.CONVERSIONS_ONBOARDING)
  } , [])

  return (
    <CardsSection>
      <StyledSection
        display="flex"
        flexDirection="column"
        gap="16px"
        width={smallView ? "100%" : "60%"}
      >
        {conversionsIndicator.enabled && (
          <ConversionsIndicator
            hasError={conversionsError}
            conversionsEnabledDescription="Your IG ads will automatically be optimized for Conversions."
            isConversionsEnabled={conversionsIndicator.enabled}
            pixelId={brandFBPixel?.id}
            adAccountId={fbAdAccount?.id}
            toggleConversionsIndicator={handleOnToggleConversions}
            description="Symphony Conversions is successfully setup."
            padding="16px"
            responsiveOnSmallView
          />
        )}
        <ConversionsSetupDetails
          isSetupComplete={isSetupComplete}
          isSetupValidated={isSetupValidated}
          isSetupError={conversionsError}
        />
        <StepFacebookAdAccount
          {...{ facebookAdAccount }}
          onClickConnectButton={handleOnConnectFacebookAdAccount}
          onHover={handleOnHoverStep(stepVideos.AD_ACCOUNT_CONNECTED)}
          onClickWatchVideoButton={() => {
            setCurrentVideoData(stepVideos.AD_ACCOUNT_CONNECTED)
            watchVideoModal.openModal()
          }}
          conversionsEnabled={conversionsIndicator.enabled}
          onVerifyConnection={validateAdAccount}
          conversionsError={conversionsError}
          onSkipError={()=> facebookAdAccount.setError(false)}
        />
        <StepFacebookPixel
          {...{ facebookPixel, fbPixelConnectModal, conversionsIndicator, facebookAdAccount }}
          selectedFBPixelId={brandFBPixel?.id}
          onClickConnectButton={handleOnConnectFacebookPixel}
          onHover={handleOnHoverStep(
            facebookPixel.error
              ? stepVideos.FB_PIXEL_NOT_CONNECTED
              : stepVideos.FB_PIXEL_CONNECTED
          )}
          onClickWatchVideoButton={() => {
            setCurrentVideoData(
              facebookPixel.error
                ? stepVideos.FB_PIXEL_NOT_CONNECTED
                : stepVideos.FB_PIXEL_CONNECTED
            )
            watchVideoModal.openModal()
          }}
          onVerifyConnection={validatePixel}
          conversionsEnabled={conversionsIndicator.enabled}
          conversionsError={conversionsError}
        />
        <StepFacebookApiToken
          {...{ facebookApiToken, facebookPixel }}
          selectedFBPixelId={brandFBPixel?.id}
          onClickConnectButton={handleOnSaveAPIAccessToken}
          onChangeAPIAccessToken={handleChangeAPIAccessToken}
          onClickWatchVideoButton={() => {
            setCurrentVideoData(stepVideos.CONVERSIONS_ACCESS_TOKEN)
            watchVideoModal.openModal()
          }}
          onHover={handleOnHoverStep(stepVideos.CONVERSIONS_ACCESS_TOKEN)}
          conversionsEnabled={conversionsIndicator.enabled}
          conversionsError={conversionsError}
        />
      </StyledSection>
      <StyledSection
        width={smallView ? "0px" : "40%"}
        maxWidth="329px"
      >
        <StepVideoContainer
          {...currentVideoData}
          openModal={watchVideoModal.open}
          onCloseModal={watchVideoModal.closeModal}
          onOpenModal={watchVideoModal.openModal}
        />
      </StyledSection>
      <EditConversionsStepModal
        open={Boolean(selectedStepToEdit)}
        onClose={handleCloseEditModal}
        onEdit={handleOnEditModal || handleOnDisableConversions}
        loading={loadingEditModal}
      />
      <SelectAdAccountModal
        {...{ facebookPixel, facebookApiToken, facebookAdAccount }}
        isOpen={fbAdAccountConnectModal.open}
        closeModal={(reload?: boolean) => {
          if (reload) reloadBrand()
          fbAdAccountConnectModal.closeModal()
        }}
      />
      <ConnectFBPixelModal
        {...{ facebookPixel, facebookApiToken, facebookAdAccount }}
        open={fbPixelConnectModal.open}
        onClose={(reload?: boolean) => {
          if (reload) reloadBrand()
          fbPixelConnectModal.closeModal()
        }}
        onError={facebookPixel.setError}
      />
      <FinishedConversionsSetupModal
        open={finishedModal.open}
        onClose={handleOnCloseFinishedModal}
      />
    </CardsSection>
  )
}
