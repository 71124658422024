// NOTE: EDITING of the presave
import { Checkbox, Switch } from '@material-ui/core';
import InfoSection from 'components/InfoSection';
import { FunctionComponent, useEffect } from 'react';
import { useStyles } from './style';
import { ColorPicker, ToggleButtons, UploadArtworkSection } from '../CustomizeTab';
import { BackgroundTypes, StyleTypes } from 'types/global';
import { getToggleOptions } from '../../utils';
import { ARTWORK, COLOR } from '../../constants';
import CloudinaryUploadWidget from 'components/shareable/CloudinaryUploadWidget';
import InputWithDebounce from 'components/shareable/InputWithDebounce';
import { convertHexToRGB } from 'components/shareable/RecordPreview/utils';
import { getTraditionalStyleButtonBackgroundColor } from 'components/shareable/RecordPreview/utils';
import { convertRGBToHex } from 'components/shareable/RecordPreview/utils';
import isEqual from "lodash/isEqual";

interface Props {
  releaseTitle: string,
  setReleaseTitle: (value: string) => void,
  addSubtitle: boolean,
  subtitle: string,
  setAddSubtitle: (value: boolean) => void,
  setSubtitle: (value: string) => void
  artworkUrl: string,
  setSelectedArtworkUrl: (artwork: string) => void,
  buttonConfig: StyleTypes,
  setButtonConfig: (value: StyleTypes) => void,
  bgType: string,
  setBgType: (value: BackgroundTypes) => void,
  backgroundColor: string,
  setSelectedBackgroundColor: (value: string) => void,
  primaryColor: string,
  setPrimaryColor: (value: string) => void,
  secondaryColor: string,
  setSecondaryColor: (value: string) => void,
  withCustomWebPage: boolean,
  setWithCustomWebPage: (value: boolean) => void,
  customWebPageUrl: string,
  setCustomWebPageUrl: (value: string) => void,
}
const CustomizePresaveForm: FunctionComponent<Props> = ({
  releaseTitle,
  setReleaseTitle,
  addSubtitle,
  setAddSubtitle,
  subtitle,
  setSubtitle,
  artworkUrl,
  setSelectedArtworkUrl,
  buttonConfig,
  setButtonConfig,
  bgType,
  setBgType,
  backgroundColor,
  setSelectedBackgroundColor,
  primaryColor,
  setPrimaryColor,
  secondaryColor,
  setSecondaryColor,
  withCustomWebPage,
  setWithCustomWebPage,
  customWebPageUrl,
  setCustomWebPageUrl,
}: Props) => {
  const classes = useStyles();

  console.log('buttonConfig', buttonConfig)
  const toggleButtonOptions = getToggleOptions(buttonConfig);

  const handleOnUploadSuccess = (file: { info: { secure_url: string } }) => {
    const artwork = file.info.secure_url;
    setSelectedArtworkUrl(artwork)
  };

  const isBoxesStyleType = isEqual(buttonConfig, StyleTypes.BOXES);
  const isTraditionalStyleType = isEqual(buttonConfig, StyleTypes.TRADITIONAL);

  useEffect(() => {
    if (isTraditionalStyleType) {
      const { values: converted } = convertHexToRGB(primaryColor);
    }
  }, [buttonConfig, primaryColor]);

  const handleChangeTitle = (value: string) => {
    setReleaseTitle(value);
  };

  return (
    <div className={classes.dataColumn}>
      <InfoSection
        title="Customize your Pre-save Page"
        description="Add in your release details to automatically setup your pre-save page."
      />
      <InfoSection
        className="py-4 px-5 w-full lg:px-10 border-b"
        title="Release Title"
        description="Enter the title of your song/album."
      >
        <InputWithDebounce
          value={releaseTitle}
          onChange={handleChangeTitle}
          placeholder='Title'
        />
        <div className="mt-2 flex items-center">
          <Switch
            checked={addSubtitle}
            onChange={(e) => setAddSubtitle(e.target.checked)}
            color="primary"
          />
          <div>
            <h2 className="mb-0">Add a secondary line</h2>
          </div>
        </div>
        {addSubtitle && (
          <div className="mt-1">
            <p className="text-base text-gray-500">
              Enter a subtitle. This can be your artist name, a message, or
              anything else you want to add."
            </p>
            <InputWithDebounce
              value={subtitle}
              onChange={setSubtitle}
              placeholder="e.g. my new song coming soon"
            />
          </div>
        )}
      </InfoSection>
      <InfoSection
        className="py-4 px-5 w-full lg:px-10 border-b"
        title="Artwork"
        description="Select the artwork you want to display along with your pre-save. We recommend using at least an image that’s 1000x1000."
      >
        <UploadArtworkSection artworkUrl={artworkUrl} />
      </InfoSection>

      <InfoSection title="Style" description="">
        <div className="mt-2">
          <ToggleButtons
            initialSelectedOption={
              buttonConfig ||
              StyleTypes.STACKED
            }
            options={toggleButtonOptions}
            handleChange={setButtonConfig}
          />
        </div>
        <div className="flex flex-col mt-5">
          <div className="flex flex-row justify-between">
            <div className="w-1/2">
              <div className="font-bold text-base">Background</div>
              <select
                style={{ border: "1px solid rgb(229, 231, 235)" }}
                className="p-3 h-auto w-full"
                value={bgType}
                onChange={(e) => setBgType(e.target.value as BackgroundTypes)}
              >
                <option value={COLOR}>Color</option>
                <option value={ARTWORK}>Artwork</option>
              </select>
            </div>
            {bgType === COLOR && (
              <div className="w-1/2 ml-8">
                <ColorPicker
                  fullHeight
                  label="Background Color"
                  value={backgroundColor}
                  onChange={(e) => setSelectedBackgroundColor(e.target.value)}
                />
              </div>
            )}
          </div>
          {bgType === ARTWORK &&
            <span className="mt-2">
              Your artwork will be used as the background image.
            </span>
          }
          <div className="flex flex-row gap-x-8 mt-4">
            <ColorPicker
              label="Primary Color"
              value={primaryColor}
              onChange={(e) => setPrimaryColor(e.target.value)}
            />
            {(isBoxesStyleType || isTraditionalStyleType) &&
              <ColorPicker
                label="Button Color"
                value={secondaryColor}
                onChange={(e) => setSecondaryColor(e.target.value)}
              />
            }
          </div>
        </div>
      </InfoSection>
      <InfoSection title="Options" description="">
        <div className="flex flex-col">
          <div className="flex flex-row items-center">
            <Checkbox
              checked={withCustomWebPage}
              onChange={(e) => setWithCustomWebPage(e.target.checked)}
              color="default"
              classes={{
                root: classes.rootCheckbox,
              }}
            />
            <span>After pre-saving send fans to a custom webpage.</span>
          </div>
          {withCustomWebPage && (
            <InputWithDebounce
              value={customWebPageUrl}
              onChange={setCustomWebPageUrl}
              placeholder="URL"
            />
          )}
        </div>
      </InfoSection>
      <CloudinaryUploadWidget
        uploadButtonId="upload_artwork"
        resourceType="image"
        maxFiles={1}
        showUploadMoreButton={false}
        clientAllowedFormats={["jpg", "png"]}
        minImageWidth={300}
        onUploadAdded={(_fileId: string) => { }}
        onUploadSuccess={handleOnUploadSuccess}
        onUploadAbortAll={() => { }}
        onUploadAbort={(_fileId: string) => { }}
        onCancel={(_info: Record<string, unknown>) => { }}
      />
    </div>
  )
}

export default CustomizePresaveForm;
