import styled from "styled-components";
import { SystemColors } from '../../types/globalStyles';
import { MEDIA_BREAKPOINTS } from "types/global";

interface ImageContainerProps {
    backgroundColor?: string;
}

export const ImageContainer = styled.div<ImageContainerProps>`
    display: flex;
    align-items: center;
    background-color: ${(props) => props.backgroundColor || "#1977F329"};
    padding: 0.75rem;
    border-radius: 8px;
`;

export const MainContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${SystemColors.DIVIDER_LINE};
    padding: 12px;
    border-radius: 8px;
    gap: 16px;
`;

export const DescriptionContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    ${MEDIA_BREAKPOINTS.mobileView} {
        flex-direction: column;
        align-items: flex-start;
        & svg {
            transform: rotate(90deg);
        };
    }
`;

export const ConnectionDescription = styled.div`
    display: flex;
    width: 45%;
    align-items: center;
    gap: 8px;
    ${MEDIA_BREAKPOINTS.mobileView} {
        width: 100%;
    }
`;