import {
  FunctionComponent,
  SyntheticEvent,
  useState,
  useEffect,
  ChangeEventHandler,
  Fragment,
  useRef,
  MutableRefObject,
  useCallback,
} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import TrashIcon from "assets/images/trash.svg";
import UploadIconPurple from "assets/images/upload-icon-purple.svg";
import useStyles, {
  MainContainer,
  ClickableLabel,
  UploadLabel,
  TrashCanIconContainer,
  Icon,
  HiddenInput,
  UploadLabelContainer,
  UploadIconContainer,
  ReloadIconContainer,
} from "./styles";
import FileIcon from "assets/images/file-icon.svg";
import ReloadIcon from "assets/images/reload.svg";
import { StyledLabel } from "../../styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { useDropzone } from 'react-dropzone';

const HIDDEN_INPUT_ID = "upload-csv-input-file";

interface Props {
  file?: File;
  maxWidth?: string;
  loading?: boolean;
  onUpload?: ChangeEventHandler<HTMLInputElement> | ((file: File) => void);
  onDelete?: () => void;
  style?: CSSProperties;
  noBorder?: boolean;
  isDragDrop?: boolean;
}

const DragDropUpload: React.FC<{
  onUpload: (file: File) => void;
  children: React.ReactNode;
}> = ({ onUpload, children }) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      onUpload(acceptedFiles[0]);
    }
  }, [onUpload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.csv'],
    },
    multiple: false,
  });

  return (
    <div {...getRootProps()} style={{
      border: '1px dashed #777',
      borderRadius: 12,
      textAlign: 'center',
      cursor: 'pointer',
      height: '100%',
      background: isDragActive ? '#f0f0f0' : 'transparent',
      padding: isDragActive ? 16 : 0,
    }}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the CSV file here ...</p>
      ) : (
        children
      )}
    </div>
  );
};

const UploadInput: FunctionComponent<Props> = ({
  file,
  maxWidth,
  loading,
  onUpload = (file: File) => { },
  onDelete,
  style = {},
  noBorder = false,
  isDragDrop = false,
}: Props) => {
  const ref = useRef(null) as MutableRefObject<HTMLInputElement | null>;
  const classes = useStyles();
  const fileName = file?.name;
  const [clickedTrashIcon, setClickedTrashIcon] = useState(false);
  const label = loading ? "Uploading..." : "Upload a CSV";
  const htmlFor = clickedTrashIcon || fileName ? undefined : HIDDEN_INPUT_ID;
  const cursor = fileName ? undefined : "pointer";

  const handleClickTrashCanIcon = (e: SyntheticEvent) => {
    const current = ref?.current;

    e?.stopPropagation();
    if (current) current.value = "";
    if (onDelete) onDelete();
    setClickedTrashIcon(true);
  };

  useEffect(() => {
    if (clickedTrashIcon) setClickedTrashIcon(false);
  }, [clickedTrashIcon]);

  const uploadInput = (
    <MainContainer {...{ maxWidth }} style={style}>
      <ClickableLabel {...{ htmlFor, cursor }} style={{ border: noBorder ? 'none' : '1px dashed #777' }}>
        <UploadIconContainer>
          {!loading && fileName && (
            <Icon width={24} height={24} alt="file-icon.svg" src={FileIcon} />
          )}
          {loading ?
            <CircularProgress
              size={24}
              classes={{
                root: classes.root,
                colorPrimary: classes.colorPrimary,
              }}
            />
            :
            <Fragment>
              {!fileName && (
                <Icon
                  cursor="pointer"
                  width={24}
                  height={24}
                  alt="upload-icon-purple.svg"
                  src={UploadIconPurple}
                />
              )}
            </Fragment>
          }
        </UploadIconContainer>
        <UploadLabelContainer>
          {fileName ?
            <StyledLabel>{fileName}</StyledLabel>
            :
            <UploadLabel>{label}</UploadLabel>
          }
        </UploadLabelContainer>
        {fileName && (
          <Fragment>
            <ReloadIconContainer htmlFor={HIDDEN_INPUT_ID}>
              <Icon
                cursor="pointer"
                width={24}
                height={24}
                alt="reload-icon.svg"
                src={ReloadIcon}
              />
            </ReloadIconContainer>
            <TrashCanIconContainer onClick={handleClickTrashCanIcon}>
              <Icon
                cursor="pointer"
                width={24}
                height={24}
                alt="trash-icon.svg"
                src={TrashIcon}
              />
            </TrashCanIconContainer>
          </Fragment>
        )}
      </ClickableLabel>
      <HiddenInput
        {...{ ref }}
        id={HIDDEN_INPUT_ID}
        onChange={onUpload as ChangeEventHandler<HTMLInputElement>}
        type="file"
        accept=".csv"
      />
    </MainContainer>
  );

  if (isDragDrop) {
    return (
      <DragDropUpload onUpload={(file: File) => onUpload(file as any)}
      >
        {uploadInput}
      </DragDropUpload>
    )
  }

  return uploadInput;
};

export default UploadInput;
