import { FC, useContext, useEffect, useState } from "react";
import { SystemColors } from "types/globalStyles";
import { ReactComponent as AddIcon } from "assets/images/add-icon.svg";
import { ReactComponent as CheckIcon } from "assets/images/check-black.svg";
import { Body2, Caption, Subtitle1, Subtitle2 } from "components/shareable/Typography";
import { createAdAccount } from "./utils";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { FacebookAdAccount } from "types/global";
import { LoadingLottie } from "components/Loader/LoadingLottie";
import { useStyles } from "./styles";
import clsx from "clsx";
import { ConnectionsContext } from "pages/post-auth/MarketingPage/hooks/ConnectionsContext";

const {
  PAPER_SUCCESS_CONTAINER,
  PAPER_DIVIDER_CONTAINER,
  PAPER_ACCENT_CONTAINER,
  SECONDARY_TEXT_COLOR,
  PRIMARY_TEXT_COLOR,
  PAPER_ERROR_CONTAINER,
  SUCCESS,
  ACCENT_COLOR
} = SystemColors;

interface CreateAdAccountSectionProps {
  initCreateAdAccountProcess: boolean;
  onConnectAdAccount: (adAccount: FacebookAdAccount) => void;
  onShowBusinessManagerSection: () => void;
  finalizeCreateAdAccountProcess: () => void;
}

export const CreateAdAccountSection: FC<CreateAdAccountSectionProps> = ({
  initCreateAdAccountProcess,
  onConnectAdAccount,
  onShowBusinessManagerSection,
  finalizeCreateAdAccountProcess,
}) => {
  const { currentBrand, reloadBrand } = useContext(CurrentBrandContext);
  const { loggedInFbUser } = useContext(ConnectionsContext);
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorDescription, setErrorDescription] = useState<string>();
  const [success, setSuccess] = useState<boolean>(false);

  const { connections } = currentBrand || {};
  const { facebook_business_manager } = connections || {};
  const bgColor = success ? PAPER_SUCCESS_CONTAINER : errorTitle ? PAPER_DIVIDER_CONTAINER : PAPER_ACCENT_CONTAINER;
  const buttonText = success ? 'Account created!' : loading ? 'Creating account...' : 'Create a new Ad Account';
  const buttonTextColor = success ? SUCCESS : errorTitle ? SECONDARY_TEXT_COLOR : ACCENT_COLOR

  useEffect(() => {
    if (initCreateAdAccountProcess) {
      createNewAdAccount();
    }
  }, [initCreateAdAccountProcess]);

  const createNewAdAccount = async () => {
    setErrorTitle(undefined);
    if (!facebook_business_manager) {
      onShowBusinessManagerSection();
      return;
    };
    setLoading(true);

    const adAccountAdded = await createAdAccount(facebook_business_manager, loggedInFbUser?.access_token);

    if (!adAccountAdded?.isError) {
      setSuccess(true);
      onConnectAdAccount(adAccountAdded as FacebookAdAccount);
      reloadBrand();
    } else {
      if (adAccountAdded.errorDescription) {
        setErrorTitle(adAccountAdded.errorTitle);
        setErrorDescription(adAccountAdded.errorDescription)
      } else {
        setErrorDescription(adAccountAdded.errorTitle);
      }
    }

    setLoading(false);
    finalizeCreateAdAccountProcess();
  }

  const getIcon = () => {
    if (loading) return <LoadingLottie black width={24} height={24} />;
    if (success) return <CheckIcon height={24} width={24} className={classes.greenIcon} />;
    return <AddIcon height={24} width={24} className={clsx(errorTitle && classes.grayIcon)} />;
  };

  return (
    <div className="flex flex-col gap-4">
      <div
        className="flex gap-4 rounded-lg px-3 py-2 cursor-pointer"
        style={{ backgroundColor: bgColor }}
        onClick={createNewAdAccount}
      >
        <div className="flex p-2">
          {getIcon()}
        </div>
        <div className="flex flex-col gap-1 justify-center">
          <Subtitle2 color={buttonTextColor}>{buttonText}</Subtitle2>
          {loading && (
            <Caption color={SECONDARY_TEXT_COLOR}>We’ll create the new Ad Account using {facebook_business_manager?.name} Business Manager </Caption>
          )}
        </div>
      </div>
      {(errorTitle || errorDescription) && (
        <div className="flex flex-col rounded-lg px-6 py-4" style={{ backgroundColor: PAPER_ERROR_CONTAINER }}>
          {errorTitle && <Subtitle1>{errorTitle}</Subtitle1>}
          {errorDescription && <Body2>{errorDescription}</Body2>}
        </div>
      )}
    </div>
  );
};