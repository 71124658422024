import Axios from "helpers/Interceptor"
import { AttributionDetails, RewardfulDetails } from "types/global";


export default async function updateUserMetadata(userId: string, metadata: {
    affiliate_referrer?: string;
    attribution?: AttributionDetails
    last_touch_attribution?: AttributionDetails
    rewardful?: RewardfulDetails
}) {
    const response = await Axios.post(`/user/${userId}/add-metadata`, {
        ...metadata,
    })
    const userData = response.data
    return userData
}