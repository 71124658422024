import { AxiosResponse } from "axios";
import Axios from "helpers/Interceptor";
import { SpotifyArtistSearch } from "./interfaces";
import { OrganizationBrand } from "types/global";

const searchArtistSpotifyService = async (
  keyword: string
): Promise<SpotifyArtistSearch[]> => {
  try {
    if (!keyword) return [];
    const response = await Axios.get(
      `/organization/search/artists/spotify?q=${keyword}`
    );
    return response?.data?.data || [];
  } catch (err) {
    return [];
  }
};

/**
 * Creates a minimal brand profile for an artist with basic Spotify data
 * @param artistData - Object containing artist information from Spotify
 * @returns Promise resolving to object with brand slug if successful, null if failed
 */
const createMinimalBrandService = async (
  artistData: any
): Promise<{ slug: string } | null> => {
  // Construct payload with artist name and Spotify data
  console.log("artistData: ", artistData)
  const payload = {
    name: artistData.name,
    customization: {
      onboarding_answers: {},
      spotify_artist: artistData,
    },
  };

  try {
    // Make API call to create brand with 60 second timeout
    const response = await Axios.post("/brand", payload, {
      timeout: 60 * 1000,
    });
    return response?.data?.data || null;
  } catch (err) {
    return null;
  }
};


export {
  createMinimalBrandService,
  searchArtistSpotifyService
};
