import { FunctionComponent } from "react";
import "styles/react-datepicker.css";
import { LoadingIndicator } from "components/Loader";
import {
  LowBudgetWarningContainer,
  StyledSpan,
  StyledButton,
  FbSpendCapWarningContainer,
  Card,
  StyledLabel,
} from "./styles";
import Grid from "@material-ui/core/Grid";
import getSymbolFromCurrency from "currency-symbol-map";

interface Props {
  fbAdAccountId?: string;
  budgetLowError: boolean;
  currency: string;
  minimumCurrencyBudgetPerDay: number;
  suggestedEndDate: string;
  minimumSuggestedBudget: number;
  budgetValue: number;
  spendCap: number;
  loadingPayment: boolean;
  handleRefreshPaymentMethod: () => void;
}

const WarningsCard: FunctionComponent<Props> = ({
  fbAdAccountId,
  budgetLowError,
  currency,
  minimumCurrencyBudgetPerDay,
  minimumSuggestedBudget,
  suggestedEndDate,
  budgetValue,
  spendCap,
  loadingPayment,
  handleRefreshPaymentMethod,
}: Props) => {

  const handleClickUpdateOnFb = () => {
    const url = `https://business.facebook.com/ads/manager/account_settings/account_billing/?act=${
      fbAdAccountId?.split("act_")[1]
    }`;

    window.open(url);
  };

  const currencySymbol = currency ? getSymbolFromCurrency(currency) : ''

  return (
    <Card>
      {budgetLowError && (
        <LowBudgetWarningContainer>
          <StyledLabel color="#ffffff" alignText="center">
            <StyledSpan>
              Your budget is too low based on your start and end date. Please
              update to make sure you have at least {currencySymbol}
              {minimumCurrencyBudgetPerDay} {currency} / day being spent across
              your ads. We recommend updating to {currencySymbol}
              {minimumSuggestedBudget.toLocaleString()}
            </StyledSpan>{" "}
            {budgetValue >= minimumCurrencyBudgetPerDay && (
              <StyledSpan>
                or changing your end date to {suggestedEndDate} or earlier.
              </StyledSpan>
            )}
          </StyledLabel>
        </LowBudgetWarningContainer>
      )}
      {!!(budgetValue && spendCap && budgetValue * 100 > spendCap) && (
        <Grid container direction="column">
          <FbSpendCapWarningContainer>
            <StyledLabel width="66.67%" margin="auto">
              Your Facebook ad account has a spending limit of {currencySymbol}{spendCap / 100}
              . Decrease your budget to {currencySymbol}{spendCap / 100}, or update the spend
              limit on Facebook.
            </StyledLabel>
          </FbSpendCapWarningContainer>
          <Grid item>
            <Grid container alignItems="center">
              <StyledButton type="button" onClick={handleClickUpdateOnFb}>
                Update on Facebook
              </StyledButton>
              <StyledButton
                type="button"
                disabled={loadingPayment}
                onClick={handleRefreshPaymentMethod}
              >
                {loadingPayment ? (
                  <LoadingIndicator color="black" height="25px" />
                ) : (
                  "Refresh"
                )}
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

export default WarningsCard;
