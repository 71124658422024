import { makeStyles } from '@material-ui/core/styles';
import { MEDIA_BREAKPOINTS } from 'types/global';
import { SystemColors } from 'types/globalStyles';

export const useStyles = makeStyles(() => ({
  sectionsContainer: {
    display: 'flex',
    gap: '24px',
    justifyContent: 'center',
    [MEDIA_BREAKPOINTS.mobileView]: {
      flexDirection: 'column',
    },
  },
  mainSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    maxWidth: '672px',
    gap: '16px',
    [MEDIA_BREAKPOINTS.mobileView]: {
      width: '100%',
    },
  },
  stepsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    borderRadius: '12px',
    padding: '24px',
    backgroundColor: SystemColors.PAPER_CONTAINER,
  },
  videoContainer: {
    height: '100%',
    width: '20%',
    maxWidth: '250px',
    borderRadius: '12px',
    [MEDIA_BREAKPOINTS.mobileView]: {
      maxWidth: '100%',
      width: '100%',
      padding: '0px 16px',
    },
  },
  conversionsSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    borderRadius: '8px',
    padding: '24px',
    backgroundColor: SystemColors.PAPER_CONTAINER,
    [MEDIA_BREAKPOINTS.mobileView]: {
      borderRadius: '0px',
    },
  },
  accordion: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '& .MuiAccordionSummary-root': {
      padding: '0px'
    },
    '& .MuiAccordionSummary-content': {
      margin: 0
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px'
    },
  },
  errorContainer: {
    display: 'flex',
    gap: '12px',
    marginTop: '4px',
    borderRadius: '8px',
    padding: '16px 24px',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: SystemColors.PAPER_ERROR_CONTAINER,
  },
}));
