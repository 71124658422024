import mixpanel from 'mixpanel-browser';
import Axios from "../helpers/Interceptor";
import Hotjar from '@hotjar/browser'
import * as Sentry from "@sentry/react";
import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser'
import { BrandSubscriptionDetails, SubscriptionStatus, SubscriptionTier, SubscriptionType } from 'types/global';


const dev = process.env.NODE_ENV !== 'production'

let customerioAnalytics: any = null

var currentBrand: any = {
    id: null,
    name: null,
    slug: null
}

var currentUser: any = {
    id: null,
    email: null
}

function guidGenerator() {
    var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}


export function getParameterByName(name: string, url?: string) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts: any = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function setBrand(brand: any) {

}


export function enrichProperties() {

}

// export function trackEvent(name, metadata) {

// }

/**
 * Initializes all analytics items.
 */
export function analyticsInitialize() {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY!, {
        ignore_dnt: true,
        debug: process.env.NODE_ENV !== 'production'
    });

    customerioAnalytics = AnalyticsBrowser.load({
        writeKey: '8577efc0dfb2453adf6b'
    });

    if (process.env.NODE_ENV === 'development') {
        customerioAnalytics.debug(false);
    }
}

// Registers this on all events in mixpanel
export function registerOnce(properties: any) {
    mixpanel.register_once(properties)


}

// registers these values on the user themself
export function setUserPropertiesOnce(props: any) {
    mixpanel.people.set_once({
        ...props
    })

    // Customer.io
    if (customerioAnalytics) {
        customerioAnalytics.identify({
            ...props
        });
    }
}

/**
 * Handles page changes in the application.
 * Updates Intercom's last request timestamp to track user activity.
 * 
 * @remarks
 * This function is called whenever there is a page navigation/change to update
 * Intercom with the latest user activity timestamp.
 */
export function handlePageChange() {
    (window as any).Intercom("update", { last_request_at: String(Math.floor(Date.now() / 1000)) })
}

/**
 * Identifies the user.
 */
export function identify(user: {
    id: string,
    name?: string,
    email?: string,
}) {

    const {
        id,
        name,
        email
    } = user

    // INTERCOM
    // set intercom state
    var intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "ckyvy7h2",
        email: email, // Email address
        user_id: id,

        hide_default_launcher: window.innerWidth < 1024 ? true : false

        // created_at: "<%= current_user.created_at.to_i %>" // Signup date as a Unix timestamp
    }


    if (intercomSettings) {
        (window as any).Intercom("boot", intercomSettings)
    }

    // SENTRY
    // set user details
    Sentry.setUser({
        id: id,
        email: email ? email : undefined,
    });

    // CUSTOMERIO
    // identify user
    if (customerioAnalytics) {
        customerioAnalytics.identify(id, {
            email: email,
            name: name
        });
    }


    // MIXPANEL
    // Identify user via mixpanel
    mixpanel.identify(id);

    if (email) {
        // set email of user
        mixpanel.people.set_once({
            "$email": email
        })
    }

    // FB - init with external ID
    if ((window as any).fbq) {
        (window as any).fbq('init', '688819555732967', {
            external_id: id
        });
        (window as any).fbq('track', 'PageView');
    }

    // TikTok - init with email 
    if ((window as any).ttq) {
        (window as any).ttq.identify({
            email: email,
            external_id: id
        });
        (window as any).ttq.page();

    }

    // Hotjar 
    if (Hotjar.isReady()) {
        Hotjar.identify(id, {
            email: email ? email : false
        })
    }

    // gtag (google analytics)
    // if ((window as any).gtag) {
    //     (window as any).gtag('config', "GA_MEASUREMENT_ID", {
    //         "user_id": id
    //     });
    // }


    // identify locally
    currentUser.email = email
    currentUser.id = id
}

/**
 * Sets the current context using the brand data.
 * @param brand 
 */
export function setCurrentContext(brand: {
    id: number,
    name: string | null,
    slug: string | null,
    tier?: string | null,
    subscription?: BrandSubscriptionDetails,
    additionalProps?: Record<any, any>
}) {

    const {
        id,
        name,
        slug,
        additionalProps = {},
        subscription = {
            tier: 'free',
            type: null,
            status: null
        },
    } = brand


    currentBrand.id = id
    currentBrand.name = name
    currentBrand.slug = slug;


    const CONTEXT: {
        Brand: string | null,
        brandSlug: string | null,
        tier: SubscriptionTier | null,
        subscriptionType: SubscriptionType | null,
        subscriptionStatus: SubscriptionStatus | null,
        [key: string]: any,
    } = {
        Brand: name,
        brandSlug: slug,
        tier: subscription?.tier || null,
        subscriptionType: subscription?.type || null,
        subscriptionStatus: subscription?.status || null,
        ...additionalProps
    };

    // set current intercom context with brand
    (window as any).Intercom("update", CONTEXT)


}

/**
 * Tracks an event across Mixpanel + Intercom.
 * @param name 
 * @param metadata 
 */
export async function track(name: string, metadata?: any) {

    const event_time = new Date().getTime()

    const enrichedProps = {
        ...metadata
    }

    var fbp = getCookie('_fbp')
    var fbc = getCookie('_fbc')

    const _fbp = fbp && JSON.stringify(fbp) !== JSON.stringify({}) ? fbp : null
    var _fbc = fbc && JSON.stringify(fbc) !== JSON.stringify({}) ? fbc : null
    var fbclid = getParameterByName('fbclid')

    // either get it from parameter
    var ttp = getCookie('_ttp')

    var ttclid = getParameterByName('ttclid')

    if (!ttclid) {
        // or get it from cookie
        ttclid = getCookie('ttclid')
    }

    if (currentBrand.id) enrichedProps.brand_id = currentBrand.id
    if (currentBrand.name) enrichedProps.brand_name = currentBrand.name
    if (currentBrand.slug) enrichedProps.brand_slug = currentBrand.slug

    // Mixpanel
    mixpanel.track(name, enrichedProps);

    // CUSTOMERIO
    // identify user
    if (customerioAnalytics) {
        customerioAnalytics.track(name, enrichedProps);
    }

    // Intercom
    (window as any).Intercom('trackEvent', name, enrichedProps);

    // facebook
    const fbEventId: string = guidGenerator();
    const fbEventName: string = name;

    //  - fb pixel
    if ((window as any).fbq) {
        (window as any).fbq('trackCustom', fbEventName, {
            ...enrichedProps,
            userId: currentBrand.userId
        }, {
            event_id: fbEventId
        });

    }

    //  - tiktok
    if ((window as any).ttq) {
        (window as any).ttq.track(fbEventName, {
            ...enrichedProps,
            userId: currentBrand.userId
        }, {
            event_id: fbEventId
        });
    }

    // gtag (google analytics)
    if ((window as any).gtag) {
        (window as any).gtag('event', name
            // , {
            //     'event_category': <category>,
            //     'event_label': <label>,
            //     'value': <value>
            //   }
        );
    }


    // Serverside Event 

    //  - conversions API
    const ssBlob = {
        eventName: fbEventName,
        eventId: fbEventId,
        eventTime: event_time,
        data: enrichedProps,

        userAgent: navigator.userAgent,
        fbclid: fbclid,
        fbc: _fbc,
        fbp: _fbp,
        email: currentUser.email,
        userId: currentUser.id,
        pageUrl: window.location.href,
        referrer: document.referrer,

        // tiktok
        ttp: ttp,
        ttclid: ttclid
    }
    try {
        await Axios.post(`/orchestra/create`, ssBlob)
    } catch (e) {
        console.error("error calling orchestra", e)
    }
}

/**
 * Tracks a page view event across Mixpanel + Intercom.
 * @param pageName page name
 * @param metadata 
 */
export async function pageView(pageName: string, metadata?: any) {
    const eventName = 'View Page'

    track(eventName, {
        name: pageName,
        ...metadata
    })

    if (customerioAnalytics) {
        customerioAnalytics.page(pageName, {
            ...metadata
        })
    }
}


/**
 * Sets a user property on a user
 * @param name 
 * @param value 
 */
export function setUserProperty(name: string, value: any) {

    // Mixpanel 
    mixpanel.people.set(name, value)


    // Customer.io
    if (customerioAnalytics) {
        customerioAnalytics.identify({
            [name]: value
        });
    }

}

