import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

interface DatasetConfig {
  key: string;
  name: string;
  color: string;
  showIfPositive?: boolean;
}

interface SymphonyLineChartProps {
  data: {
    [key: string]: number | string;
  }[];
  datasets: DatasetConfig[];
  xAxisKey: string;
}

export function SymphonyLineChart({ data, datasets, xAxisKey }: SymphonyLineChartProps) {
  const filteredDatasets = datasets.filter(dataset => 
    !dataset.showIfPositive || data.some(item => (item[dataset.key] as number) > 0)
  );

  return (
    <ResponsiveContainer className="mx-auto" width="100%" height={window.innerWidth < 576 ? 175 : window.innerHeight * 0.3} minHeight="100px">
      <LineChart
        width={500}
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dy={12} dataKey={xAxisKey} />
        <YAxis domain={['auto', 'auto']} />
        <Legend verticalAlign="top" height={36} />
        <Tooltip />
        {filteredDatasets.map((dataset, index) => (
          <Line
            key={dataset.key}
            type="monotone"
            dataKey={dataset.key}
            name={dataset.name}
            stroke={dataset.color}
            fill="#d9ffd9"
            fillOpacity="0.8"
            activeDot={{ r: 8 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}