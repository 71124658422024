import { makeStyles, Theme } from "@material-ui/core";
import { SystemColors } from "types/globalStyles";

export const useStyles = makeStyles((theme: Theme) => ({
  dataGridRoot: {
    border: 'none !important',
    // outline: 'none !important',
    width: '100% !important',
    '& .MuiDataGrid-main': {
      // width: '100% !important',
      // overflow: 'hidden !important',
    },
    '& .MuiDataGrid-virtualScroller': {
      // overflow: 'auto !important',
      // '&::-webkit-scrollbar': {
      //   width: '8px',
      //   height: '8px',
      // },
      // '&::-webkit-scrollbar-track': {
      //   background: 'transparent',
      // },
      // '&::-webkit-scrollbar-thumb': {
      //   background: SystemColors.DIVIDER_LINE,
      //   borderRadius: '4px',
      // },
    },
    '& .MuiDataGrid-root': {
      // border: 'none',
    },
    '& .MuiDataGrid-row.Mui-selected': {
      // backgroundColor: `${SystemColors.PAPER_CONTAINER} !important`,
    },
    '& .MuiDataGrid-cell:focus': {
      // outline: 'none',
      // borderBottom: `1px solid ${SystemColors.DIVIDER_LINE}`,
    },
    '& .MuiDataGrid-cell, .MuiDataGrid-row': {
      // borderTop: `1px solid ${SystemColors.DIVIDER_LINE}`,
    },
    '& .MuiDataGrid-row:last-of-type': {
      // borderBottom: `1px solid ${SystemColors.DIVIDER_LINE}`,
    },
    '& .MuiDataGrid-cell:hover': {
    },
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-columnHeaders': {
      // border: 'none !important',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none !important',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: 16,
      fontFamily: 'DIN',
      color: `${SystemColors.SECONDARY_TEXT_COLOR} !important`,
    },
  },
  disabledChevron: {
    cursor: 'not-allowed',
    height: 16,
    width: 16,
    '& path': {
      stroke: SystemColors.DISABLED_TEXT_COLOR,
    },
  },
  enabledChevron: {
    cursor: 'pointer',
    height: 16,
    width: 16,
    '& path': {
      stroke: SystemColors.PRIMARY_TEXT_COLOR,
    },
  },
}));