import { FunctionComponent, useState, useEffect, useContext } from "react"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { Box, Tab, Tabs } from "@material-ui/core"
import { BillingCycle } from "./utils"
import { RightContent, TitleContainer } from "./styles"
import { TeamContent } from "./view/teams"
import CreatorContent from "./view/creators"
import { Headline1, Subtitle2 } from "../Typography"
import { SystemColors } from "types/globalStyles"
import UpgradeToProLabel from "../UpgradeToProLabel"
import { getConvertedCurrency } from "services/symphonyApi"

import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { YEARLY_BY_MONTH_PRO_PLAN_PRICE, MONTHLY_PRO_PLAN_PRICE, YEARLY_BY_MONTH_TEAM_PLAN_PRICE, MONTHLY_TEAM_PLAN_PRICE } from "../../../constants"
import { DEFAULT_CURRENCY } from "pages/post-auth/MarketingPage/constants"
import { getNumberWithDecimals } from "helpers/General"
import { useCurrentTeam } from "Hooks/CurrentTeamContext"

const { MONTHLY } = BillingCycle

interface Props {
  source?: string | null
  secondaryButtons?: React.ReactNode[];
  accountType?: 'creator' | 'team';
}

const CreateProfileUpgradeToPro: FunctionComponent<Props> = ({
  source,
  secondaryButtons,
  accountType = 'creator'
}) => {
  // Shared state for billing cycle between team/creator views
  const [billingCycle, setBillingCycle] = useState(MONTHLY)

  // UI responsive state
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down(901))

  // Organization context for team view
  const { organization } = useCurrentTeam()

  // State for plan type tabs (only shown when accountType is 'team' and no organization exists)
  const [planTabs, setPlanTabs] = useState<"creator" | "team">(accountType === 'team' ? "team" : "creator")

  // Add new state for pricing
  const [loading, setLoading] = useState(false)
  const [currencyPrices, setCurrencyPrices] = useState({
    annualConverted: YEARLY_BY_MONTH_PRO_PLAN_PRICE,
    monthlyConverted: MONTHLY_PRO_PLAN_PRICE
  })

  // Get currency info from brand context
  const { currentBrand } = useContext(CurrentBrandContext)
  const currencyCode = currentBrand?.currency?.code || DEFAULT_CURRENCY

  // Get initial prices based on plan type
  const getInitialPrices = (isTeam: boolean) => ({
    annualConverted: isTeam ? YEARLY_BY_MONTH_TEAM_PLAN_PRICE : YEARLY_BY_MONTH_PRO_PLAN_PRICE,
    monthlyConverted: isTeam ? MONTHLY_TEAM_PLAN_PRICE : MONTHLY_PRO_PLAN_PRICE
  })

  // Calculate prices based on currency and plan type
  useEffect(() => {
    const getPricesValues = async () => {
      setLoading(true)
      const isTeamPlan = planTabs === 'team'

      if (currencyCode === DEFAULT_CURRENCY) {
        setCurrencyPrices(getInitialPrices(isTeamPlan))
        setLoading(false)
        return
      }

      try {
        const baseMonthly = await getConvertedCurrency({
          from: DEFAULT_CURRENCY,
          to: currencyCode,
          amount: isTeamPlan ? MONTHLY_TEAM_PLAN_PRICE : MONTHLY_PRO_PLAN_PRICE
        })
        const baseAnnual = await getConvertedCurrency({
          from: DEFAULT_CURRENCY,
          to: currencyCode,
          amount: isTeamPlan ? YEARLY_BY_MONTH_TEAM_PLAN_PRICE : YEARLY_BY_MONTH_PRO_PLAN_PRICE
        })

        setCurrencyPrices({
          annualConverted: Number(getNumberWithDecimals(baseAnnual, 2)),
          monthlyConverted: Number(getNumberWithDecimals(baseMonthly, 2))
        })
      } finally {
        setLoading(false)
      }
    }

    getPricesValues()
  }, [currencyCode, planTabs])

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: "creator" | "team") => {
    setPlanTabs(newValue)
  }

  const upgradeText = (
    <>
      <div className="mb-2">
        <UpgradeToProLabel
          maxWidth={"180px"}
          color="PURPLE"
          label={"14-Day Free Trial"}
        />
      </div>
      <TitleContainer>
        <Headline1 color={SystemColors.PRIMARY_TEXT_COLOR}>
          Try Symphony Pro for free
        </Headline1>
        <Subtitle2 color={SystemColors.PRIMARY_TEXT_COLOR}>
          Everything you need to analyze, market, and grow your fanbase + business, free for 14 days.*
        </Subtitle2>
      </TitleContainer>
    </>
  )

  if (accountType === 'team') {
    return (
      <RightContent
        margin="0px auto 0px"
        padding={mobileView ? "0px" : "0px"}
        width={"100%"}
        maxHeight="unset"
      >
        {upgradeText}
        {!organization && (
          <Box sx={{ borderBottom: "1px solid #C8C7CC", marginBottom: '12px' }}>
            <Tabs
              value={planTabs}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#8800FF",
                },
              }}
            >
              <Tab
                className="capitalize"
                label="For Creators"
                value={"creator"}
                id="tab-creators"
                aria-controls="tabpanel-creators"
              />
              <Tab
                className="capitalize"
                label="For Teams"
                value={"team"}
                id="tab-teams"
                aria-controls="tabpanel-teams"
              />
            </Tabs>
          </Box>
        )}

        {planTabs === "creator" ? (
          <CreatorContent
            isOnboardingView={true}
            open={true}
            onClose={() => { }}
            secondaryButtons={secondaryButtons}
            source={source}
            isOrganization={false}
            hasOrganizationOverage={false}
            reloadBrands={() => { }}
            billingCycle={billingCycle}
            setBillingCycle={setBillingCycle}
          />
        ) : (
          <TeamContent
            isOnboardingView={true}
            secondaryButtons={secondaryButtons}
            billingCycle={billingCycle}
            setBillingCycle={setBillingCycle}
            source={source}
            reloadBrands={() => { }}
            hasOrganizationOverage={false}
            isOrganization={false}
            open
            onClose={() => { }}
          />
        )}
      </RightContent>
    )
  }

  // Default creator view (when accountType is 'creator')
  return (
    <RightContent
      margin="0px auto 0px"
      padding={mobileView ? "0px" : "0px"}
      width={"100%"}
      maxHeight="unset"
    >
      {upgradeText}
      <CreatorContent
        isOnboardingView={true}
        open={true}
        onClose={() => { }}
        secondaryButtons={secondaryButtons}
        source={source}
        isOrganization={false}
        hasOrganizationOverage={false}
        reloadBrands={() => { }}
        billingCycle={billingCycle}
        setBillingCycle={setBillingCycle}
      />
    </RightContent>
  )
}

export default CreateProfileUpgradeToPro
