import styled from 'styled-components';

interface HeaderContainerProps {
  justifyContent?: string,
}

interface TextContainerProps {
  textCenter?: boolean;
}

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }: HeaderContainerProps) => justifyContent || 'flex'};
  margin-bottom: 12px;
`;

export const CloseIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: rgba(136, 0, 255, 0.04);
  border-radius: 50%;
  cursor: pointer;
`;

export const ChipContainer = styled.div`
  background: rgba(136, 0, 255, 0.08);
  padding: 4px 8px;
  border-radius: 8px;
  width: fit-content;
  display: flex;
  gap: 8px;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: ${({ textCenter }: TextContainerProps) => textCenter ? 'center' : 'left'};
  gap: 8px;
`;

export const Title = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
`;
export const SubTitle = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #707070;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(136, 0, 255, 0.04);
  cursor: pointer;
`;

export const BackArrowImage = styled.img`
  width: 24px;
  height: 24px;
`;

export const ImageProfile = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin: auto;
`;