import dayjs from "dayjs"
import * as Sentry from "@sentry/react";

export const FACEBOOK_BUSINESS_BASE_URL = 'https://business.facebook.com';
export const ACCOUNT_QUALITY_URL = `${FACEBOOK_BUSINESS_BASE_URL}/accountquality`;
export const AD_ACCOUNT_SETTINGS_URL = `${FACEBOOK_BUSINESS_BASE_URL}/settings/ad-accounts`;
export const PIXEL_SETTINS_URL = `${FACEBOOK_BUSINESS_BASE_URL}/settings/events-dataset-news`;

var cachedFb = null
let fbLoginInProgress = false

const HARDCODED_ACCESS_TOKEN = null
// const HARDCODED_ACCESS_TOKEN = process.env.NODE_ENV === 'production' ? null
//     : "EAAQLqjh4uVoBACBGtBgf929yLgdsdgxdPZAoitlKoFlJ1ZBYZAnONTCRmSWxedK9xfaYvWiTS9fjsJZBxESBMsvtwpKr3b0tRIKl3cyaftIpaqvoeSCN6RTrm6mMo94RU8c5ebVVzQViiXctpZBd7nojdadDR8pxAd4qxew6Uj543KRsygZBmirzD4ZBZAhyNQQZD"

const { REACT_APP_SYSTEM_USER_IG_ID, REACT_APP_SYSTEM_USER_ACCESS_TOKEN } = process.env;
const FB_BASE_API_URL = 'https://graph.facebook.com/v20.0'

export function FB() {
    if (cachedFb !== null && typeof cachedFb !== 'undefined') {
        return cachedFb
    } else if (window.FB || FB) {
        cachedFb = window.FB || FB
        return window.FB || FB
    } else {
        return null
    }
}

export const AD_ACCOUNT_STATUS = {
    1: {
        name: "active",
        valid: true
    },
    2: {
        name: "disabled",
        valid: false
    },
    3: {
        name: "unsettled",
        valid: false
    },
    7: {
        name: "pending review",
        valid: false
    },
    8: {
        name: "pending review",
        valid: false
    },
    9: {
        name: "pending review",
        valid: false
    },
    100: {
        name: "pending closure",
        valid: false
    },
    101: {
        name: "temporarily unavailable",
        valid: false
    },
}

export function loginWithFB(authType = null, scope) {
    return new Promise((resolve, reject) => {
        const fb = FB();
        if (!fb) {
            const message = 'FB SDK with issues. Contact Support.';
            console.log('no FB found')
            Sentry.captureException(message);
            reject(message)
            return;
        }

        const loginData = {
            scope: scope ? scope : process.env.REACT_APP_FACEBOOK_SCOPE
        }

        if (authType) {
            loginData.auth_type = authType
        }

        try {
            if (fbLoginInProgress) {
                reject(false);
                return;
            }
            fbLoginInProgress = true
            fb.login(function (response) {
                fbLoginInProgress = false
                if (response.authResponse) {
                    resolve(response.authResponse);
                } else {
                    fbLoginInProgress = false
                    console.log('User cancelled login or did not fully authorize.', response);
                    reject(response);
                }
            }, loginData);
        } catch (e) {
            console.log('error logging in with facebook', e)
            reject(e)
        }
    })
}

export const isFBLoggedIn = async () => {
    const fb = FB();
    if (!fb) {
        const message = 'FB SDK with issues. Contact Support.';
        Sentry.captureException(message);
        return false;
    }
    try {
        const loggedUser = await fb.getAuthResponse();
        return Boolean(loggedUser)
    } catch (e) {
        console.error('Error checking if FB is logged in', e);
        return false;
    }
}

/**
 * returns an array with true or false with the invalid permissions based on access status
 * @param {*} array list of permissions to check for
 */
export async function checkForFBPermissions(array, access_token = '') {
    access_token = access_token || undefined
    const permissionsObject = {}
    array.map(o => {
        permissionsObject[o] = true
    })

    const declinedItems = []
    const checkPermissions = await queryFB('/me/permissions', {
        params: {
            access_token
        },
    })
    const accountPermissions = checkPermissions.data

    // loop through the permissions 
    let allValid = true
    accountPermissions.forEach(facebookPermission => {
        const { permission, status } = facebookPermission

        if (typeof permissionsObject[permission] !== 'undefined') {
            if (status !== 'granted') {
                permissionsObject[permission] = false
                allValid = false
                declinedItems.push(facebookPermission)
            }
        }
    })

    if (allValid) {
        return true
    } else {
        return declinedItems
    }
}
export async function queryFB(path, { method = 'get', params = {} }) {

    var pulledMethod = method ? method : 'get'
    var pulledParams = params ? params : {}


    if ((HARDCODED_ACCESS_TOKEN || (window.HARDCODED_ACCESS_TOKEN && !pulledParams.access_token))
        && !pulledParams.overrideAccessToken) {
        pulledParams['access_token'] = HARDCODED_ACCESS_TOKEN || window.HARDCODED_ACCESS_TOKEN
    }

    if (pulledParams.forceToken) {
        pulledParams['access_token'] = pulledParams.forceToken
    }

    return await new Promise((resolve, reject) => {
        const fb = FB();
        if (!fb) {
            const message = 'FB SDK with issues. Please contact Support.';
            Sentry.captureException(message);
            reject(message)
        }

        fb.api(path, pulledMethod, pulledParams, function (response) {

            if (!response || response.error) {
                console.error('Error occured calling FB query');
                console.log('response rror', response.error)

                if (response.error.code === 2500) {
                    // refresh auth token
                    loginWithFB().then((response) => {
                        queryFB(path, {
                            method: pulledMethod,
                            params: pulledParams
                        }).then(resolve).catch(reject)
                    })
                } else if (response.error.code === 190) {
                    // error code 190: The access token has been invalidated.
                    //  - to address, we get a new local access token and pass it to the server to turn into a
                    //    long-lived AT
                    //  - from there, the server can save the 

                    // loginWithFB().then((response) => {
                    //     const authResp = response
                    //     console.log('authResp', authResp)
                    //     if (authResp.accessToken) {
                    //         console.log('got new authResp', authResp)
                    //         // Axios.post('/user/me/connect', {
                    //         //     service: 'facebook',
                    //         //     value: authResp.accessToken
                    //         // }).then((response) => {
                    //         //     console.log("Access token is refreshed, now good to go", response)

                    //         // })
                    //     }

                    // })
                    reject()


                } else {
                    reject(response)

                }

            } else {
                resolve(response)
            }
        });
    })
}

export async function queryFbAdCampaignInsights({
    ad_account,
    fields,
    level,
    startDate,
    endDate,
    campaignIds,
    adIds,
    daily,
    breakdowns,
    sort,
    limit

}) {

    var getData = await new Promise(async (resolve, reject) => {
        try {

            const adAccountId = ad_account

            var obj = {
                fields: fields,
                level: 'account',
                time_range: {
                    since: dayjs(startDate).format('YYYY-MM-DD'),
                    until: dayjs(endDate).format('YYYY-MM-DD')
                },
                date_preset: 'maximum',
                default_summary: true,

            }

            if (daily) {
                obj.default_summary = false
                obj.time_increment = 1
            }

            if (breakdowns) {
                obj.breakdowns = breakdowns
            }

            if (sort) {
                obj.sort = sort
            }


            if (limit) {
                obj.limit = limit
            }


            if (campaignIds) {
                obj.filtering = `[{field:"campaign.id",operator:"IN",value: ${JSON.stringify(campaignIds)}}]`
            }
            if (adIds) {
                obj.filtering = `[{field:"ad.id",operator:"IN",value: ${JSON.stringify(adIds)}}]`

            }

            const results = await queryFB(`/${adAccountId}/insights`, {
                params: obj
            })

            const { data } = results

            resolve(data)
        } catch (e) {
            console.error('error pulling campaign stats', e)
            reject()
        }

    })

    return getData


}

export async function getFbAdItemInfo(props) {

    const {
        fields,
        ids,
        limit = 50,
        type = "campaigns"
    } = props

    // for each of the campaign ID calls, pull the data and return as one merged array 

    var pullerPromises = ids.map((itemId) => {

        return new Promise(async (resolve, reject) => {
            var url = `/${itemId}`
            try {

                var obj = {
                    fields: fields

                }

                if (limit) {
                    obj.limit = limit
                }

                if (type === 'ads') {
                    url += `/ads`
                }

                const results = await queryFB(url, {
                    params: obj
                })

                if (results.data) {
                    resolve(results.data)
                } else {
                    resolve(results)
                }

            } catch (e) {
                console.error("Error pulling campaign", e)
                resolve({})
            }

        })
    })

    var returnedItems = await Promise.all(pullerPromises)

    return returnedItems


}

export async function getInstagramPosts(props) {
    const {
        ig_account,
        fields = `
            id,ig_id,permalink,caption,media_product_type,media_type,media_url,is_shared_to_feed,
            like_count,comments_count,thumbnail_url,timestamp,children{id,ig_id,media_type,media_url,thumbnail_url},username
        `,
        limit = 20,
        sort = `["timestamp_descending"]`,
        access_token,
    } = props

    if (!ig_account) return []

    const igMedia = await new Promise(async (resolve, reject) => {
        var url = `/${ig_account}/media`
        try {
            var obj = {
                fields: fields,
                access_token,
            }

            if (limit) {
                obj.limit = limit
            }

            if (sort) {
                obj.sort = sort
            }

            const results = await queryFB(url, {
                params: obj
            })

            if (results.data) {
                resolve(results.data)
            } else {
                resolve(results)
            }

        } catch (e) {
            console.error("Error pulling instagram posts", e)
            resolve([])
        }
    })

    return igMedia
}

export async function getIgUserData(username) {
    const igBizDiscCall = new Promise(async (resolve, _reject) => {
        try {
            const endpoint = `${FB_BASE_API_URL}/${REACT_APP_SYSTEM_USER_IG_ID}`
            const fields = `business_discovery.username(${username}){id,name,username,followers_count,media_count,profile_picture_url}`
            const igUserData = await queryFB(endpoint, {
                params: { fields, access_token: REACT_APP_SYSTEM_USER_ACCESS_TOKEN },
            })
            resolve(igUserData.business_discovery)
        } catch (e) {
            console.error("Error querying instagram username", e)
            resolve(null)
        }
    })
    const timeout = new Promise((resolve, _reject) => setTimeout(() => resolve(false), 15 * 1000))

    const result = await Promise.race([igBizDiscCall, timeout])
    if (result) return igBizDiscCall; else return null
}

export async function getLocales(locales = '') {
    const endpoint = `${FB_BASE_API_URL}/search`;
    const response = await queryFB(endpoint, {
        params: {
            access_token: REACT_APP_SYSTEM_USER_ACCESS_TOKEN,
            type: 'adlocale',
            q: locales,
            limit: 100
        }
    });

    return response;
}

export function fbLoggedIn() {
    const fb = FB();
    if (!fb) {
        const message = 'FB SDK with issues. Contact Support.';
        Sentry.captureException(message);
        return false;
    }

    const p = fb.getAuthResponse()
    // fb.getLoginStatus(function (response) {
    //     if (response.status === 'connected') {
    //         // The user is logged in and has authenticated your
    //         // app, and response.authResponse supplies
    //         // the user's ID, a valid access token, a signed
    //         // request, and the time the access token 
    //         // and signed request each expire.
    //         return true
    //     } else if (response.status === 'not_authorized') {
    //         // The user hasn't authorized your application.  They
    //         // must click the Login button, or you must call FB.login
    //         // in response to a user gesture, to launch a login dialog.
    //         return false
    //     } else {
    //         // The user isn't logged in to Facebook. You can launch a
    //         // login dialog with a user gesture, but the user may have
    //         // to log in to Facebook before authorizing your application.
    //         return false
    //     }
    // })
    if (p === null) {
        return false
    } else {
        return true
    }
    // console.log('p', p)
    // return false
}

export default FB