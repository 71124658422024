// Import required UI components
import {
  Headline1,
  Headline1Accent,
} from "components/shareable/Typography";
import UpgradeToProLabel from "components/shareable/UpgradeToProLabel";

// Import React hooks
import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

// Import styles and types
import { SystemColors } from "types/globalStyles";
import useStyles, {
  BillingChipContainer,
  TitleContainer,
} from "../../styles";
import { BillingCycle, FeatureType } from "../../utils";

// Import Material UI components
import { useMediaQuery, useTheme } from "@material-ui/core";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { track } from "analytics";
import getSymbolFromCurrency from "currency-symbol-map";
import { getNumberWithDecimals } from "helpers/General";
import { SHARED_TOAST_OPTIONS } from "pages/post-auth/MarketingPage/Components/Modals/utils";
import { DEFAULT_CURRENCY } from "pages/post-auth/MarketingPage/constants";
import { toast } from "react-toastify";
import { PaymentMethods } from "types/global";
import {
  TEAM_PLAN_MONTHLY_AD_SPEND_LIMIT,
  PRO_PLAN_ADDITIONAL_SEAT_PRICE,
  MONTHLY_TEAM_PLAN_PRICE,
  YEARLY_BY_MONTH_TEAM_PLAN_PRICE,
  TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE,
  TEAM_PLAN_ADDITIONAL_SEAT_PRICE
} from "../../../../../constants";
import { StripeFormattedData } from "../../api";
import { getConvertedCurrency } from "services/symphonyApi";
import Intercom from "helpers/Intercom";
import FeatureListSection from "../FeatureList"
import { BottomPrice } from "../BottomPrice";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";

// Define billing cycle constants
const { MONTHLY, ANNUALLY } = BillingCycle;

// Define component props interface
interface Props {
  open: boolean;
  onClose: () => void;
  source?: string | null;
  isOrganization?: boolean;
  hasOrganizationOverage?: boolean | undefined;
  reloadBrands: () => void;
  billingCycle: BillingCycle;
  setBillingCycle: (billingCycle: BillingCycle) => void;
  isOnboardingView?: boolean;
  secondaryButtons?: React.ReactNode[];
}

// Define currency prices type
type CurrencyPrices = {
  annualConverted: number;
  monthlyConverted: number;
};

// Main component
export const TeamContent: FunctionComponent<Props> = ({
  source,
  isOrganization,
  hasOrganizationOverage,
  reloadBrands,
  billingCycle = MONTHLY,
  setBillingCycle,
  isOnboardingView = false,
  secondaryButtons
}) => {
  // Initialize state and hooks
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(901));
  const classes = useStyles({ isMobileView: mobileView });
  const { currentBrand, reloadBrand } = useContext(CurrentBrandContext);
  const { goToCheckout, freeTrialRedeemed } = useCurrentTeam();

  // Initialize pricing state
  const [monthlyAdSpendLimit, setMonthlySpendCamptionValue] = useState<number>(TEAM_PLAN_MONTHLY_AD_SPEND_LIMIT);
  const [additionalUserSeatsValue] = useState<number>(TEAM_PLAN_ADDITIONAL_SEAT_PRICE);
  const [currencyPrices, setCurrencyPrices] = useState<CurrencyPrices>({
    annualConverted: YEARLY_BY_MONTH_TEAM_PLAN_PRICE,
    monthlyConverted: MONTHLY_TEAM_PLAN_PRICE,
  });

  // Add new state for Pro account price
  const [additionalProProfilePrice, setMonthlyProAccountValue] = useState<number>(
    TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE
  );

  // Get currency information
  const currencyCode = currentBrand?.currency?.code || DEFAULT_CURRENCY;
  const currencySymbol = getSymbolFromCurrency(currencyCode);
  const { slug: brandSlug } = currentBrand || {};
  const freeTrialOver = freeTrialRedeemed || false;

  // Handle upgrade button click
  const handleClickUpgradeToProButton = useCallback(async () => {
    setLoading(true);
    try {
      const data = {
        recurring_interval: billingCycle,
        payment_method: PaymentMethods.STRIPE,
        checkout_source: source,
      } as StripeFormattedData;

      track("Checkout Started from Upgrade to Pro Modal", {
        source: source || null,
      });
      await goToCheckout(data, brandSlug, !freeTrialOver)
      await reloadBrand();
    } catch (error) {
      console.error(error);
      toast.error(
        "There was an updating the subscription.",
        SHARED_TOAST_OPTIONS
      );
    } finally {
      setLoading(false);
    }
  }, [billingCycle, brandSlug, freeTrialOver, goToCheckout, reloadBrand, source]);

  // Handle feature captions
  const getCaption = (feature: FeatureType) => {
    const { caption, featureId } = feature;
    if (typeof caption === "string") return caption;

    switch (featureId) {
      case 'ad-spend-limit-teams':
        return caption!(currencyCode, monthlyAdSpendLimit);
      case 'invite-users-teams':
        return caption!(currencyCode, additionalUserSeatsValue);
      case 'pro-profiles-discount':
        return caption!(currencyCode, additionalProProfilePrice);
      case 'included-pro-profiles':
        return caption!(currencyCode, additionalProProfilePrice);
      default:
        return caption!(currencyCode, 0); // Fallback for features without amounts
    }
  };

  // Handle currency conversion
  useEffect(() => {
    if (currencyCode !== DEFAULT_CURRENCY) {
      getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: MONTHLY_TEAM_PLAN_PRICE }).then((value) => {
        setCurrencyPrices(prev => ({
          ...prev,
          monthlyConverted: value
        }));
      })
      getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: YEARLY_BY_MONTH_TEAM_PLAN_PRICE }).then((value) => {
        setCurrencyPrices(prev => ({
          ...prev,
          annualConverted: value
        }));
      })
      getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: TEAM_PLAN_MONTHLY_AD_SPEND_LIMIT }).then((value) => {
        setMonthlySpendCamptionValue(value);
      })

      getConvertedCurrency({
        from: DEFAULT_CURRENCY,
        to: currencyCode,
        amount: TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE
      }).then((value) => {
        setMonthlyProAccountValue(value);
      });
    } else {
      setCurrencyPrices({
        annualConverted: YEARLY_BY_MONTH_TEAM_PLAN_PRICE,
        monthlyConverted: MONTHLY_TEAM_PLAN_PRICE,
      });
      setMonthlySpendCamptionValue(
        Number(getNumberWithDecimals(TEAM_PLAN_MONTHLY_AD_SPEND_LIMIT, 2))
      );
      setMonthlyProAccountValue(
        Number(getNumberWithDecimals(TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE, 2))
      );
    }
  }, [currencyCode, isOrganization]);

  // Render headline based on view type
  function renderHeadline() {
    if (isOnboardingView) {
      return null;
    } else {
      return (
        <TitleContainer>
          <Headline1 color={SystemColors.PRIMARY_TEXT_COLOR}>
            Grow your roster with Symphony for Teams
          </Headline1>
        </TitleContainer>
      );
    }
  }

  // Handle question mark icon click
  const handleClickQuestionMarkIcon = () => Intercom.openSymphonyProArticle();

  // Render top label based on view type
  function renderTopLabel() {
    return null
    if (isOnboardingView) {
      return null;
    } else {
      return (
        <UpgradeToProLabel
          maxWidth={"210px"}
          color="PURPLE"
          label={"Symphony for Teams"}
        />
      )
    }
  }

  // Render component
  return (
    <>
      {renderHeadline()}
      {renderTopLabel()}

      <FeatureListSection
        classes={classes}
        mobileView={mobileView}
        handleClickQuestionMarkIcon={handleClickQuestionMarkIcon}
        getCaption={getCaption}
        isTeam={true}
      />

      <BottomPrice
        mobileView={mobileView}
        loading={loading}
        currencySymbol={currencySymbol || '$'}
        currencyCode={currencyCode || 'USD'}
        freeTrialOver={freeTrialOver}
        handleClickUpgradeToProButton={handleClickUpgradeToProButton}
        secondaryButtons={secondaryButtons}
        isOnboardingView={isOnboardingView}
        isTeamsView={true}
        amount={billingCycle === MONTHLY ? currencyPrices.monthlyConverted : currencyPrices.annualConverted * 12}
        timePeriod={billingCycle.toLowerCase()}
        isOrganization={isOrganization}
        hasOrganizationOverage={hasOrganizationOverage}
        monthlyAditionalUserValue={additionalUserSeatsValue}
        billingCycle={billingCycle}
        setBillingCycle={setBillingCycle}
        currencyPrices={currencyPrices}
      />
    </>
  );
};

export default TeamContent;
