import { Container, OutlinedButton, StyledLabel, TextButton } from "styles/shared";
import { CurrentBrand, OrganizationData, PaymentMethods, Tiers, UserData } from "types/global";
import { MouseEvent, useContext, useEffect, useState } from "react";
import { Divider, Switch, TextField } from "@material-ui/core";
import SelectableArtist from "./SelectableArtist";
import PrimaryButton from "components/shareable/PrimaryButton";
import { AlertContainer } from "../Components/AddUserModal/styles";
import { Body1, Body2, Caption, Headline1, Headline2, Headline3 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import { MONTHLY_PRO_PLAN_PRICE, MONTHLY_TEAM_PLAN_PRICE, TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE, TEAM_PLAN_INCLUDED_PRO_ACCOUNTS } from "../../../../constants";
import { adminCreateNewOrganizationWithTrial, finishOrganizationSetup } from "services/symphonyApi/organizationService";
import { BrandUser, getBrandUsers } from "services/symphonyApi/brandService";
import Loaderv2 from "components/Loader/v2";
import { Autocomplete } from "@mui/material";
import SearchSelector from "components/ProfileDropdown/SearchSelector";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import { useHistory } from "react-router-dom";

const DEFAULT_INCLUDED_PRO_ACCOUNTS = TEAM_PLAN_INCLUDED_PRO_ACCOUNTS; // Number of included Pro accounts
const PRO_PROFILE_OVERAGE_PRICE = TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE; // Price for each additional Pro account
interface SelectableBrand {
    name: string;
    image: string;
    slug: string;
    selectedForPro: boolean;
    alreadyOnPro: boolean;
    proSeatIndex: number;
}

export default function OrganizationNeedsSetupState(props: {
    currentBrand: CurrentBrand;
    existingBrands: CurrentBrand[];
    organization: OrganizationData | null;
    onFinished: () => void;
    isAdminSetup?: boolean;
    title?: string;
    description?: string;

}) {
    const {
        existingBrands,
        organization,
        onFinished,
        isAdminSetup,
        title,
        description,
        currentBrand
    } = props

    // Used in Admin Setup flow to automatically view a created organization
    const { setSpoofedUserId } = useContext(CurrentUserContext);
    const history = useHistory()

    // Organization Name
    const [nameValue, setNameValue] = useState<string>(organization?.name || "")

    // Admin Setup Options:
    //  - admin setup lets an admin create a team on behalf of an organization or profile
    //  - they can select the main email of the user by default or enter an email address
    //  - the selected brand will be added by default to the team,
    //    and admins can search for additional brands to add to the team. they can also include the 
    //    selected user's Brands
    const [ownerEmail, setOwnerEmail] = useState<string>("")
    const [brandOwners, setBrandOwners] = useState<BrandUser[]>([])
    const [usersLoading, setUsersLoading] = useState<boolean>(false)
    const [importedBrands, setImportedBrands] = useState<CurrentBrand[]>([])
    const [openAdminSelector, setOpenAdminSelector] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    // total allocated seats - by default organization plans come with 5.
    // any additional seats are charged at $15 per profile.
    // Selected Artist State
    const defaultSelectableBrands = (): SelectableBrand[] => {
        let brands: SelectableBrand[] = []
        if (existingBrands && existingBrands.length) {
            let proCount = 0
            brands = existingBrands.map((o: CurrentBrand): SelectableBrand => {
                let selectedForPro = o.tier === Tiers.PRO
                if (o.tier === Tiers.PRO) proCount++
                return {
                    selectedForPro: selectedForPro,
                    proSeatIndex: selectedForPro ? proCount : 0,
                    name: o.name!,
                    image: o.image!,
                    slug: o.slug!,
                    alreadyOnPro: o.tier === Tiers.PRO
                }
            })
        }

        if (importedBrands && importedBrands.length) {
            let transformedImportedBrands: SelectableBrand[] = importedBrands.map((o: CurrentBrand): SelectableBrand => {
                return {
                    selectedForPro: o.tier === Tiers.PRO,
                    proSeatIndex: 0,
                    name: o.name!,
                    image: o.image!,
                    slug: o.slug!,
                    alreadyOnPro: o.tier === Tiers.PRO
                }
            })
            brands = brands.concat(transformedImportedBrands)
        }
        return brands
    }
    
    const defaultUsedSeats = defaultSelectableBrands().filter((o: SelectableBrand) => o.selectedForPro).length
    const [selectableBrands, setSelectableBrands] = useState<SelectableBrand[]>(defaultSelectableBrands())
    const [totalUsedSeats, setTotalUsedSeats] = useState<number>(defaultUsedSeats)

    // loading state
    const [loading, setLoading] = useState<boolean>(false)

    const userHasOverage = Boolean(totalUsedSeats - DEFAULT_INCLUDED_PRO_ACCOUNTS > 0)

    // Update selectableBrands only once when it's initially empty
    useEffect(() => {
        if (selectableBrands.length === 0 && existingBrands && existingBrands.length) {
            const updatedBrands = defaultSelectableBrands();
            setSelectableBrands(updatedBrands);
            const updatedUsedSeats = updatedBrands.filter((o: SelectableBrand) => o.selectedForPro).length;
            setTotalUsedSeats(updatedUsedSeats);
        }
    }, [existingBrands, selectableBrands]);

    // Admin Setup Hooks
    useEffect(() => {
        if (isAdminSetup) {
            if (importedBrands && importedBrands.length) {
                const updatedBrands = defaultSelectableBrands();
                setSelectableBrands(updatedBrands);
            }
        }
    }, [importedBrands]);

    useEffect(() => {
        if (isAdminSetup) {
            if (currentBrand?.id) {
                // Load brand users when currentBrand changes
                setUsersLoading(true)
                getBrandUsers({
                    brandId: currentBrand.id,
                    onSaveUsers: (users) => {
                        const pulledUsers = users?.users || []
                        setBrandOwners(pulledUsers)
                        setUsersLoading(false)
                    }
                })
            }
        }
    }, [currentBrand])

    useEffect(() => {
        const newTotalUsedSeats = selectableBrands.filter((o: SelectableBrand) => o.selectedForPro).length
        setTotalUsedSeats(newTotalUsedSeats)
    }, [selectableBrands])



    function overageCalculator() {

        const overage = totalUsedSeats - DEFAULT_INCLUDED_PRO_ACCOUNTS
        if (overage > 0) {
            return overage * PRO_PROFILE_OVERAGE_PRICE
        }

        return 0
    }

    function savingsCalculator() {
        if (totalUsedSeats < DEFAULT_INCLUDED_PRO_ACCOUNTS) {
            return 0
        }

        const PER_PROFILE_TEAM_PLAN_COST = MONTHLY_TEAM_PLAN_PRICE / DEFAULT_INCLUDED_PRO_ACCOUNTS;
        // Cost per profile under the team plan

        let savings = 0;

        if (totalUsedSeats <= DEFAULT_INCLUDED_PRO_ACCOUNTS) {
            // Calculate savings per profile when total used seats are less than or equal to 5
            savings = (MONTHLY_PRO_PLAN_PRICE - PER_PROFILE_TEAM_PLAN_COST) * totalUsedSeats;
        } else {
            // Calculate total savings when there are more than 5 profiles
            const baseSavings = (MONTHLY_PRO_PLAN_PRICE - PER_PROFILE_TEAM_PLAN_COST) * DEFAULT_INCLUDED_PRO_ACCOUNTS;
            const overageSeats = totalUsedSeats - DEFAULT_INCLUDED_PRO_ACCOUNTS;
            const overageSavings = (MONTHLY_PRO_PLAN_PRICE - TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE) * overageSeats;
            savings = baseSavings + overageSavings;
        }

        return Math.round(savings);
    }

    const handleCloseDropdown = () => setAnchorEl(null);

    const styles: Record<string, React.CSSProperties> = {
        inputStyles: {
            'borderRadius': '6px',
            'border': '1px solid #ADB7BC',
            'height': '67px',
            'width': '100%',
            'margin': '8px 0px',
            padding: "24px 16px",
            maxWidth: '400px'
        },
        artistList: {
            maxHeight: '35vh',
            overflow: 'auto'
        }
    }


    function handleSelectedForPro(brand: SelectableBrand, selected: boolean) {
        const newSelectableBrands = selectableBrands.map((o: SelectableBrand) => {
            if (o.name === brand.name) {

                return {
                    ...o,
                    selectedForPro: selected
                }
            }
            return o
        }).map((o: SelectableBrand, idx: number) => {
            if (o.selectedForPro) {
                return {
                    ...o,
                    proSeatIndex: idx + 1
                }
            }
            return o
        })
        setSelectableBrands(newSelectableBrands)
    }

    function handleRemoveBrand(brand: SelectableBrand) {
        const newSelectableBrands = selectableBrands.filter((o: SelectableBrand) => o.name !== brand.name);
        setSelectableBrands(newSelectableBrands)
    }


    function setAllSelectedForPro(selected: boolean) {

        const newSelectableBrands = selectableBrands.map((o: SelectableBrand) => {
            return {
                ...o,
                selectedForPro: o.alreadyOnPro ? true : selected
            }
        }).map((o: SelectableBrand, idx: number) => {
            if (o.selectedForPro) {
                return {
                    ...o,
                    proSeatIndex: idx + 1
                }
            }
            return o
        })
        setSelectableBrands(newSelectableBrands)
    }

    function renderSelectArtistList() {


        // Filter and map over brands selected for Pro
        const proSelectedBrands = selectableBrands.filter(brand => brand.selectedForPro)
            .map((brand, index) => ({
                ...brand,
                proSeatIndex: index + 1,
                overageSeat: index >= DEFAULT_INCLUDED_PRO_ACCOUNTS // true if it's beyond the included accounts
            }));

        return (
            <div style={styles.artistList}>
                {selectableBrands.map((brand: SelectableBrand, idx: number) => {
                    // Find the brand in the proSelectedBrands array to get the correct proSeatIndex
                    const proBrand = proSelectedBrands.find(proBrand => proBrand.name === brand.name);

                    return (
                        <SelectableArtist
                            overageSeat={brand.selectedForPro && proBrand ? proBrand.overageSeat : false}
                            canRemove={(brand.slug !== currentBrand?.slug) && (isAdminSetup ?? false)}
                            isAlreadyPro={brand.alreadyOnPro}
                            seatCount={brand.selectedForPro && proBrand ? proBrand.proSeatIndex : 0}
                            name={brand.name!}
                            selected={brand.selectedForPro}
                            image={brand.image!}
                            removeBrand={() => handleRemoveBrand(brand)}
                            setSelected={(selected) => handleSelectedForPro(brand, selected)} />
                    )

                })}
            </div>
        )
    }

    async function onSubmit() {
        setLoading(true)
        if (loading) return
        try {
            // redirectToOrganizationCheckout(selectedBrands, nameValue)
            const mappedBrands: {
                brandSlug: string;
                currentTier: "pro" | "free";
                upgradeToPro: boolean;
            }[] = selectableBrands.map((o: SelectableBrand) => {
                return {
                    brandSlug: o.slug,
                    currentTier: o.alreadyOnPro ? "pro" : "free",
                    upgradeToPro: !o.alreadyOnPro && o.selectedForPro,
                }
            })

            // if an admin is setting up the organization, we need to create a new organization with a trial
            // otherwise handle the default flow
            if (isAdminSetup) {
                const finishSetup = await adminCreateNewOrganizationWithTrial(nameValue, ownerEmail, mappedBrands)
                if (finishSetup) {
                    const {
                        primaryOwner,
                        organization
                    } = finishSetup
                    setSpoofedUserId(primaryOwner?.id ?? null)
                    onFinished()
                    if (organization) {
                        history.push('/team')
                    }
                }
            } else {
                const finishSetup = await finishOrganizationSetup(nameValue, mappedBrands)
            }
            onFinished()
        } catch (error) {
            console.error("error: ", error)
        } finally {
        }
        setLoading(false)


    }

    function showTeamSummary() {

        let showOverageInfo = userHasOverage ? (
            <>
                <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>Additional Pro Accounts: ${overageCalculator()} / month ({totalUsedSeats - DEFAULT_INCLUDED_PRO_ACCOUNTS < 0 ? 0 : totalUsedSeats - DEFAULT_INCLUDED_PRO_ACCOUNTS} profile{totalUsedSeats - DEFAULT_INCLUDED_PRO_ACCOUNTS !== 1 ? 's' : ''})</Body2>
                {/* <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>Total Monthly Cost: ${MONTHLY_TEAM_PLAN_PRICE + overageCalculator()}</Body2> */}
            </>
        ) : null
        return (
            <AlertContainer type="success">
                <Body1 color={SystemColors.PRIMARY_TEXT_COLOR}>You have {TEAM_PLAN_INCLUDED_PRO_ACCOUNTS} Pro accounts included in your Team plan. You can upgrade additional profiles to Pro for ${TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE} / month.</Body1>
                <Container padding="12px 0px" display="flex" flexDirection="column" gap="4px">
                    <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>Selected Pro Accounts: {totalUsedSeats}</Body2>
                    {showOverageInfo}
                </Container>
                {savingsCalculator() > 0 && (<Body2 color={SystemColors.ACCENT_COLOR}>You're saving ${savingsCalculator()} / month with a Team plan vs. buying individual Pro accounts.</Body2>)}

            </AlertContainer>
        )
    }


    return (
        <Container display="flex" flexDirection="column" gap="12px">
            <Headline1 color={SystemColors.PRIMARY_TEXT_COLOR}>{title || "Setup your Team"}</Headline1>
            <Headline3 color={SystemColors.SECONDARY_TEXT_COLOR}>
                {description || "You can manage unlimited profiles through your Team, and you have {TEAM_PLAN_INCLUDED_PRO_ACCOUNTS} Pro accounts included by default."}
            </Headline3>
            <Container marginTop="18px" display="flex" flexDirection="column">
                <Body1 color={SystemColors.PRIMARY_TEXT_COLOR}>
                    What do you want to call your team?
                </Body1>
                <input
                    style={styles.inputStyles}
                    value={nameValue}
                    placeholder={'Enter your team\'s name'}
                    onChange={(e) => setNameValue(e.target.value)}
                    maxLength={90}
                />
            </Container>

            {isAdminSetup && (
                <Container className="adminSetupOwnerEmail" marginTop="18px" display="flex" flexDirection="column">
                    <Body1 color={SystemColors.PRIMARY_TEXT_COLOR}>
                        What's the team owner's email?
                    </Body1>
                    {usersLoading ? (
                        <Loaderv2 />
                    ) : brandOwners.length > 0 ? (
                        <Autocomplete
                            freeSolo
                            options={brandOwners.map(user => user.email)}
                            value={ownerEmail}
                            onChange={(_, newValue) => {
                                setOwnerEmail(newValue || "");
                            }}
                            onInputChange={(_, newInputValue) => {
                                setOwnerEmail(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select or enter an email"
                                    type="email"
                                    fullWidth
                                    style={{ marginTop: '8px' }}
                                />
                            )}
                        />
                    ) : (
                        <input
                            type="email"
                            style={styles.inputStyles}
                            value={ownerEmail}
                            placeholder="Enter owner's email"
                            onChange={(e) => setOwnerEmail(e.target.value)}
                        />
                    )}
                </Container>
            )}

            <Container marginTop="18px" display="flex" flexDirection="column" gap="8px">

                {/* <SelectedArtistCountSection
                    numberOfArtistsSelected={totalUsedSeats}
                    totalArtists={5}
                /> */}
                <Container width={'100%'}
                    display="flex"
                    flexDirection="column"
                    gap="4px">
                    <Body1 color={SystemColors.PRIMARY_TEXT_COLOR}>
                        Select the profiles you want to add to your Team and upgrade to Pro.
                    </Body1>
                    <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>You can easily add and upgrade additional profiles after setup.</Body2>
                    {selectableBrands.length > 2 && (<Container display="flex" flexDirection="row" alignItems="center">
                        <OutlinedButton onClick={() => setAllSelectedForPro(true)}>
                            Upgrade All to Pro
                        </OutlinedButton>
                    </Container>)}
                </Container>

                {isAdminSetup && (
                    <Container display="flex" flexDirection="column" gap="8px">
                        <TextButton onClick={() => setOpenAdminSelector(true)}>
                            Import from Admin Search
                        </TextButton>
                    </Container>
                )}

                <Divider />
                {renderSelectArtistList()}
                <Divider />

            </Container>

            <Container marginTop="18px" display="flex" flexDirection="column" gap="12px">

                {showTeamSummary()}
            </Container>
            <Container marginTop="8px" display="flex" alignItems="center" justifyContent="center">
                <PrimaryButton text="Finish Setup"
                    onClick={() => onSubmit()}
                    disabled={!nameValue || nameValue.trim().length < 3}
                    loading={loading} />
            </Container>
            {isAdminSetup && (
                <SearchSelector
                    mode="select"
                    onImportSelected={(brands) => {
                        setImportedBrands(brands)
                    }}
                    open={openAdminSelector}
                    closeModal={() => {
                        setOpenAdminSelector(false)
                        handleCloseDropdown()
                    }}
                />
            )}
        </Container>
    )
}