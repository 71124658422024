
export enum WEBSITE_CREATION_TYPE {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
  PRESAVE = 'presave',
  CAMPAIGN = 'marketing',
}

export const WEBSITE_TYPES = {
  RECORD_STREAMS: "record_streams",
  DATA_COLLECTOR: "data_collector",
  TOUR: "tour",
} as const;

export type WebsiteType = typeof WEBSITE_TYPES[keyof typeof WEBSITE_TYPES];

export const RECORD_STREAMS_TYPES = {
  PROJECT: "project",
  PLAYLIST: "playlist",
  TRACK: "track",
} as const;

export type RecordStreamsType = typeof RECORD_STREAMS_TYPES[keyof typeof RECORD_STREAMS_TYPES];