/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useContext, useEffect, useState } from "react"
import { StyledLabel, Container } from "../../styles"
import { OrganizationData, SYMPHONY_TASKS } from "types/global"
import { GettingStartedContext } from "Hooks/GettingStartedContext/index"
import { ButtonText, Headline3 } from "components/shareable/Typography"
import { SystemColors } from "types/globalStyles"
import useStyles, { StyledButton } from "./styles"
import { UpgradeModalContext } from "Hooks/UpgradeModalContext"
import { DataGrid } from "@mui/x-data-grid"
import { Row, getDataGridColumns } from "./utils"
import useModal from "Hooks/useModal"
import DeleteUserModal from "../DeleteUserModal"
import AddUserModal from "../AddUserModal"
import UpgradeToProChip from "components/shareable/UpgradeToProChip"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { isBrandAdministrator } from "utils"
import { useHistory } from "react-router-dom"
import { PageTabs } from "../../../OrganizationPage/utils"
import { getBrandUsers, PulledUsers } from "services/symphonyApi/brandService"

interface Props {
  brandId?: number | null
  isProUser?: boolean
  email?: string
  isAdmin: boolean;
  brandBelongsToOrganization?: boolean;
  organization?: OrganizationData;
}

const UsersTab: FunctionComponent<Props> = ({
  isProUser,
  brandId,
  email,
  isAdmin,
  brandBelongsToOrganization,
}: Props) => {
  const classes = useStyles()
  const { handleOpenModal: openUpgradeToProModal } =
    useContext(UpgradeModalContext)
  const { currentBrand } = useContext(CurrentBrandContext);

  const [pulledUsers, setPulledUsers] = useState<PulledUsers>({ availableSeats: 0, users: [] })
  const addUserModal = useModal()
  const deleteUserModal = useModal()
  const [potentiallyDeletedUser, setPotentiallyDeletingUser] =
    useState<Row | null>(null)
  const history = useHistory();

  const { finishedTasks, finishTask } = useContext(GettingStartedContext)

  const dataGridColumns = getDataGridColumns({
    onRemove: (row: Row | null) => {
      if (brandBelongsToOrganization) {
        history.push(`/team?tab=${PageTabs.TEAM}`)
      } else {
        setPotentiallyDeletingUser(row as Row | null)
        deleteUserModal.openModal()
      }
    },
  }, isAdmin)

  const markStepAsCompleted = () => {
    if (!finishedTasks.includes(SYMPHONY_TASKS.TEAM_INVITED)) {
      finishTask(SYMPHONY_TASKS.TEAM_INVITED)
    }
  }

  const handleCloseAddModal = () => {
    addUserModal.closeModal()
  }

  const handleCloseDeleteModal = () => {
    setPotentiallyDeletingUser(null)
    getBrandUsers({
      brandId,
      onSaveUsers: setPulledUsers,
    })
    deleteUserModal.closeModal()
  }

  const handleClickAddNewMemberButton = () => {
    if (brandBelongsToOrganization) {
      history.push(`/team?tab=${PageTabs.TEAM}`)
    } else {
      if (isProUser) {
        addUserModal.openModal()
      } else {
        openUpgradeToProModal({
          source: "Settings - Add New User",
        })
      }
    }
  }

  useEffect(() => {
    getBrandUsers({
      brandId,
      onSaveUsers: setPulledUsers,
    })
    markStepAsCompleted()
  }, [])

  const hasReachedLimit = pulledUsers.users.length >= pulledUsers.availableSeats
  const willNotTouchLimit = pulledUsers.users.length - 1 >= pulledUsers.availableSeats

  return (
    <Container>
      <StyledLabel>
        <Container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="0px 0px 24px 0px"
          borderBottom="1px solid #E5E7EB"
        >
          <Headline3 color={SystemColors.PRIMARY_TEXT_COLOR}>Profile Members</Headline3>
          <StyledButton onClick={handleClickAddNewMemberButton}>
            <div className="flex flex-row items-center gap-1.5">
              <div><UpgradeToProChip type="gem" color="purple" height={18} width={18} /></div>
              <ButtonText>Invite a Member</ButtonText>
            </div>
          </StyledButton>
        </Container>
      </StyledLabel>
      {pulledUsers.users.length > 0 && (
        <Container margin="0px" minHeight="100px">
          <DataGrid
            autoHeight
            headerHeight={0}
            hideFooter
            classes={{ root: classes.dataGridRoot }}
            columns={dataGridColumns}
            //TODO: Data will change from the same endpoint
            rows={pulledUsers.users.map(({
              email: userEmail,
              id,
              isOwner,
              removable
            }) => {
              const isMe = email === userEmail
              return {
                id,
                email: userEmail,
                name: "-",
                isOwner,
                removable
              }
            }
            )}
          />
        </Container>
      )}
      <DeleteUserModal
        {...{ brandId }}
        open={deleteUserModal.open}
        member={potentiallyDeletedUser}
        willNotTouchLimit={willNotTouchLimit}
        onSaveUsers={setPulledUsers}
        onSetDeletedUser={setPotentiallyDeletingUser}
        onClose={handleCloseDeleteModal}
      />
      <AddUserModal
        {...{ brandId }}
        users={pulledUsers.users}
        hasReachedLimit={hasReachedLimit}
        open={addUserModal.open}
        onClose={handleCloseAddModal}
        onSaveUsers={setPulledUsers}
      />
    </Container>
  )
}

export default UsersTab
