import { FunctionComponent, useContext } from "react"
import useStyles, { Card, StyledLabel } from "../../styles"
import Grid from "@material-ui/core/Grid"
import clsx from "clsx"
import { TotalBannerContainer } from "./styles"
import { SpendBudgetContext } from "Hooks/BudgetTabSpendContext"
import { SystemColors } from "types/globalStyles"
import { Caption } from "components/shareable/Typography"
import getSymbolFromCurrency from "currency-symbol-map"
import { CurrencyCodesType } from "types/global"
import { DEFAULT_CURRENCY } from "pages/post-auth/MarketingPage/constants"

interface Props {
  budget: number
  currency?: CurrencyCodesType
}

const format = (budget: number) =>
  budget.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

const BudgetCard: FunctionComponent<Props> = ({ budget, currency }: Props) => {
  const classes = useStyles()
  const { data } = useContext(SpendBudgetContext)
  const { feePrice, feeUnit, effectiveSpendLimit: newSpentLimit } = data || {}
  const fee = feePrice || 0
  const currencySymbol = getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)

  return (
    <Card>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.withPaddingBottom)}>
          <Grid container direction="column">
            <Grid item>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <StyledLabel fontSize={16} fontWeight={600}>
                  Budget
                </StyledLabel>
                <StyledLabel fontSize={16} fontWeight={400}>
                  {currencySymbol}{format(budget)}
                </StyledLabel>
              </Grid>
            </Grid>
            {/* {fee > 0 && (
              <Grid item className={classes.spacedItem}>
                <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                  +{currencySymbol}{format(feePrice || 0)} per additional {currencySymbol}
                  {format(feeUnit || 0)}. Your new ad spend limit for this month
                  is {currencySymbol}{format(newSpentLimit || 0)}
                </Caption>
              </Grid>
            )} */}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default BudgetCard
