import { CurrentBrand } from "types/global"
import Axios from "helpers/Interceptor"
import { checkIfIsProTier, getAdsComission } from "utils"

export const saveDraft = async (args: {
  dataToSend: Record<string, unknown>
  campaignDraftData?: Record<string, unknown> | null
  audiencesData: Record<string, unknown>
  currentBrand: CurrentBrand
}) => {
  const { dataToSend, campaignDraftData, audiencesData, currentBrand } = args
  const campaignDraftId = campaignDraftData ? campaignDraftData.id : null
  const campaignMetadata = campaignDraftData?.campaign_metadata as Record<
    string,
    unknown
  >
  const brand =
    (campaignMetadata as { campaign_input: { brand: CurrentBrand } })
      ?.campaign_input.brand || currentBrand
  const brandId = brand.id
  const campaignInput =
    (campaignMetadata?.campaign_input as Record<string, unknown>) || {}
  const { geographicTargets, interestTargetingAudiences } = audiencesData
  const {
    currentLink,
    selectedFB_page,
    selectedFBAdAccount,
    selectedInstaPage,
    addVisualSelected,
    uploadedImages,
    selectedInstagramPost,
    caption,
    budgetValue,
    startDate,
    endDate,
    selectedTab,
    cta,
    campaignName,
    conversionsEnabled,
    conversionsEventName: conversionsEvent,
    customAudiences = [],
    additionalPageAudiences = [],
    selectedFBPixel,
    selectedFBBusinessManager,
    loggedInFbUser,
  } = dataToSend
  const isProUser = checkIfIsProTier(currentBrand)
  const adsComission = getAdsComission(isProUser)
  const marketingBudget = Number(
    ((budgetValue as number) - (budgetValue as number) * adsComission).toFixed(
      2
    )
  )
  const automationFee = Number(
    ((budgetValue as number) * adsComission).toFixed(2)
  )

  try {
    const response = await Axios.put(`campaign/brand/${brandId}/save-draft`, {
      id: campaignDraftId,
      brand_id: brandId,
      campaign_metadata: {
        ...campaignMetadata,
        access_token: currentBrand?.connections?.facebook_page?.access_token || null,
        campaign_type: "link_clicks",
        status_notes: null,
        budget: marketingBudget,
        automationFee,
        creative: selectedInstagramPost,
        startDate,
        endDate,
        content: {
          cta,
          link: currentLink,
          caption,
          campaign_name: campaignName,
        },
        saved_draft_step: selectedTab,
        add_visual_selected: addVisualSelected,
        campaign_input: {
          ...campaignInput,
          fbAdAccount: selectedFBAdAccount,
          price_data: {
            name: `${(budgetValue as number).toLocaleString(
              "en"
            )} FB/IG Ads Campaign for "${brand.name}"`,
            price: budgetValue as number,
            description: `"${brand.name}" (Increase Music Streams)`,
          },
          campaignName,
          igMedia: selectedInstagramPost || null,
          fbPixel: null,
          fbPage: selectedFB_page || null,
          igPage: selectedInstaPage,
          cta,
          link: currentLink,
          caption: null,
          goal: "increase_link_clicks",
          interestTargetingAudiences,
          targetedCountries: geographicTargets,
          targetingDemographics: dataToSend.demographics,
          record: null,
          brand: {
            ...currentBrand,
            connections: {
              ...currentBrand.connections,
              facebook_ad_account: selectedFBAdAccount,
              facebook_page: selectedFB_page,
              instagram_page: selectedInstaPage,
              facebook_pixel: selectedFBPixel,
              facebook_business_manager: selectedFBBusinessManager,
              logged_in_fb_user: loggedInFbUser,
            },
          },
          captions: null,
          budget: marketingBudget,
          startDate,
          endDate,
          customAudiences,
          additionalPageAudiences,
          assets: {
            all: uploadedImages,
          },
          conversionsEnabled,
          conversionsEventName: conversionsEvent,
        },
        campaign_state: {
          brand: currentBrand,
          accounts: {
            fbPage: selectedFB_page || null,
            igPage: selectedInstaPage,
            fbPixel: null,
            fbAdAccount: selectedFBAdAccount,
          },
          campaign: {
            conversions: {
              conversionsEventName: null,
              conversionsApiAccessToken: null,
            },
            details: {
              budget: marketingBudget,
              endDate,
              startDate,
            },
            creative: {
              cta,
              assets: {
                all: uploadedImages,
              },
              igMedia: selectedInstagramPost || null,
              captions: null,
              mainCaption: null,
            },
            targeting: {
              targetedCountries: geographicTargets,
              interestTargetingAudiences,
              customAudiences,
              additionalPageAudiences
            },
          },
        },
        campaign_platforms: ["fb"],
      },
    })
    const data = response.data.data
    return { data, error: null }
  } catch (error) {
    console.log("error when saving draft: ", error)
    return { data: null, error }
  }
}
