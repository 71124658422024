import React from 'react';

type SVGIconProps = {
  height?: string;
  width?: string;
  isSelected?: boolean;
};

export const VideoGoal: React.FC<SVGIconProps> = ({ height = '31.2', width = '31.2', isSelected }) => {
  const fillColor = isSelected ? '#8800FF' : '#707070';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24" // Replace with your SVG's viewbox
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path stroke={fillColor} stroke-linecap="round" strokeWidth="2" d="M15 12c0-.613.346-1.173.894-1.447l3.659-1.83A1 1 0 0 1 21 9.619v4.764a1 1 0 0 1-1.447.894l-3.659-1.829A1.618 1.618 0 0 1 15 12Z" clip-rule="evenodd" />
      <path stroke={fillColor} stroke-linecap="round" strokeWidth="2" d="M13 6H5a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2Z" />
    </svg>
  );
};

export const MusicGoal: React.FC<SVGIconProps> = ({ height = '31.2', width = '31.2', isSelected }) => {
  const fillColor = isSelected ? '#8800FF' : '#707070';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24" // Replace with your SVG's viewbox
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke={fillColor} stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" clip-path="url(#a)">
        <path d="M9 18V5l12-2v13" />
        <path d="M6 21a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM18 19a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M2 2h20v20H2z" /></clipPath></defs>
    </svg>
  );
};

export const DataGoal: React.FC<SVGIconProps> = ({ height = '23.4', width = '20.8', isSelected }) => {
  const fillColor = isSelected ? '#8800FF' : '#707070';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 16" // Replace with your SVG's viewbox
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >

      <path stroke={fillColor} stroke-linecap="round" strokeWidth="2" d="M7 6a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" clip-rule="evenodd" />
      <path stroke={fillColor} stroke-linecap="round" strokeWidth="2" d="M1 15a6 6 0 0 1 12 0M12.131 7.013l1 1.25c.112.14.322.152.45.025L16.369 5.5" />
    </svg>
  );
};


export const SocialsGoal: React.FC<SVGIconProps> = ({ height = '24.7', width = '22.1', isSelected }) => {
  const fillColor = isSelected ? '#8800FF' : '#707070';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 17" // Replace with your SVG's viewbox
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect stroke={fillColor} width="3" height="8" x="1.777" y="7.654" strokeWidth="2" rx=".5" />
      <path stroke={fillColor} strokeWidth="2" d="M17.76 7.702 15.288 14.8a1 1 0 0 1-.944.672H8.17a1 1 0 0 1-1-1V7.317a1 1 0 0 1 .205-.606l3.684-4.83a.5.5 0 0 1 .68-.11l1.371.94a.5.5 0 0 1 .183.595l-.667 1.702a1 1 0 0 0 .93 1.365h3.26a1 1 0 0 1 .945 1.329Z" />
    </svg >
  );
};



export const TicketsGoal: React.FC<SVGIconProps> = ({ height = '23.4', width = '20.8', isSelected }) => {
  const fillColor = isSelected ? '#8800FF' : '#707070';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 16" // Replace with your SVG's viewbox
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >

      <path stroke={fillColor} stroke-linecap="round" strokeWidth="2" d="M15 1H3a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM10 11h4M1 5h16" />
    </svg >
  );
};





export const MerchGoal: React.FC<SVGIconProps> = ({ height = '31.2', width = '31.2', isSelected }) => {
  const fillColor = isSelected ? '#8800FF' : '#707070';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24" // Replace with your SVG's viewbox
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path stroke={fillColor} stroke-linecap="round" strokeWidth="2" d="M16 6H6a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2Z" />
      <path stroke={fillColor} stroke-linecap="round" strokeWidth="2" d="M21 9v8a2 2 0 0 1-2 2H7M11 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
    </svg>
  );
};


export const UnderstandAnalyticsGoal: React.FC<SVGIconProps> = ({ height = '22.1', width = '22.1', isSelected }) => {
  const fillColor = isSelected ? '#8800FF' : '#707070';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17" // Replace with your SVG's viewbox
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path stroke={fillColor} stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 6.571V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h7.429" />
      <path stroke={fillColor} stroke-linecap="round" strokeWidth="2" d="M15 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" clip-rule="evenodd" />
      <path stroke={fillColor} stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="m5 11 3-4 2 2 3-4" />  </svg>
  );
};

