import { Body1, Body2, Body3, Caption, Caption1, Headline2, Headline3 } from 'components/shareable/Typography';
import { ConnectionsContext } from 'pages/post-auth/MarketingPage/hooks/ConnectionsContext';
import { FC, useContext } from 'react';
import { useStyles } from './styles';
import { SystemColors } from 'types/globalStyles';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import LinkButton from 'components/shareable/LinkButton';
import { ConnectedStepIcon } from './ConnectedStepIcon';
import Intercom from 'helpers/Intercom';

const { PRIMARY_TEXT_COLOR, DISABLED_TEXT_COLOR } = SystemColors;

interface ConnectionsStepsDescriptionProps {
  showConversionsStep?: boolean;
}

export const ConnectionsStepsDescription: FC<ConnectionsStepsDescriptionProps> = ({
  showConversionsStep = true,
}) => {
  const classes = useStyles();

  const {
    facebookAdAccount,
    facebookPage,
    instagramPage,
    conversionsEnabled,
  } = useContext(ConnectionsContext);

  return (
    <div className={classes.mainContainer}>
      {/* <Headline2 color={PRIMARY_TEXT_COLOR}>Accounts</Headline2> */}
      <Body1 color={PRIMARY_TEXT_COLOR}>Connected Accounts</Body1>
      {/* <Caption1>Connect your Accounts</Caption1> */}
      <Stepper orientation="vertical" className={classes.stepper}>
        <Step active={true} completed={Boolean(facebookAdAccount)}>
          <StepLabel StepIconComponent={() => <ConnectedStepIcon connected={Boolean(facebookAdAccount)} />}>
            <Body2>Ad Account</Body2>
          </StepLabel>
        </Step>
        <Step active={true} completed={Boolean(facebookPage && instagramPage)}>
          <StepLabel StepIconComponent={() => <ConnectedStepIcon connected={Boolean(facebookAdAccount)} />}>
            <Body2>FB & IG Page</Body2>
          </StepLabel>
        </Step>
        {showConversionsStep && (
          <Step active={true} completed={Boolean(conversionsEnabled)}>
            <StepLabel StepIconComponent={() => <ConnectedStepIcon connected={Boolean(conversionsEnabled)} />}>
              <div className='flex flex-col'>
                <Body1>Conversions</Body1>
                <Caption color={DISABLED_TEXT_COLOR}>Optional</Caption>
              </div>
            </StepLabel>
          </Step>
        )}
      </Stepper>
      <div className='flex flex-col gap-2'>
        <Body2>Having issues connecting your accounts?</Body2>
        <LinkButton onClick={Intercom.open} text='Contact Support' />
      </div>
    </div>
  );
};