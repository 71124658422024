import PrimaryButton from 'components/shareable/PrimaryButton';
import { FC } from 'react';
import { StyledLabel, Container } from 'styles/shared';
import { BillingChipContainer, FlexContainer } from '../styles';
import { BottomBar } from '../styles';
import { FixedContainer } from '../styles';
import BillingPlanOptions from '../Components/BillingOptions';
import { TeamBillingChip } from './teams/components/BillingChip';
import { CurrencyCodesType } from 'types/global';
import { BillingCycle } from '../utils';
import { BillingChip } from '../Components/BillingChip';

interface BottomPriceProps {
    // Essential props
    mobileView: boolean;
    loading: boolean;
    currencySymbol: string;
    currencyCode: CurrencyCodesType;
    freeTrialOver?: boolean;
    handleClickUpgradeToProButton: () => void;
    secondaryButtons?: React.ReactNode[];
    isOnboardingView?: boolean;

    // View type
    isTeamsView?: boolean;

    // Pricing props - simplified
    amount: number;
    timePeriod: string;

    // Optional organization props
    isOrganization?: boolean;
    hasOrganizationOverage?: boolean;
    monthlyAditionalUserValue?: number;

    // for unpaid status
    isUnpaid?: boolean;
    retriesFailed?: boolean;

    // New billing cycle props
    billingCycle: BillingCycle;
    setBillingCycle: (cycle: BillingCycle) => void;
    currencyPrices: {
        monthlyConverted: number;
        annualConverted: number;
    };

    // for partnership discounts
    partnerDiscount?: number;
}

export const BottomPrice: FC<BottomPriceProps> = ({
    mobileView,
    loading,
    currencySymbol,
    currencyCode,
    freeTrialOver,
    handleClickUpgradeToProButton,
    secondaryButtons,
    isOnboardingView,
    isTeamsView,
    amount,
    timePeriod,
    isOrganization,
    hasOrganizationOverage,
    monthlyAditionalUserValue,
    isUnpaid,
    billingCycle,
    setBillingCycle,
    currencyPrices,
    retriesFailed,
    partnerDiscount
}) => {
    const ContainerComponent = FlexContainer;

    const formatPrice = (price: number) => {
        return Number(price).toFixed(2).replace(/\.00$/, '');
    };

    const getPricingText = () => {
        // Organization cases
        if (isOrganization) {
            if (hasOrganizationOverage) {
                return `You can upgrade this profile to Pro as part of your Team plan for ${currencySymbol}${formatPrice(monthlyAditionalUserValue || 0)} / ${timePeriod}.`;
            }
            return `You can upgrade this profile to Pro for free as part of your Team plan subscription.`;
        }

        // Standard pricing text
        return freeTrialOver
            ? `Subscribe for ${currencySymbol}${formatPrice(amount)} / ${timePeriod}.`
            : `*Free trial is for 14 days, then ${currencySymbol}${formatPrice(amount)}${currencyCode !== 'USD' ? ` ${currencyCode}` : ''
            } / ${timePeriod} unless you cancel. You'll get an email reminder 7 days before your trial ends.`;
    };

    const getCheckoutButtonText = () => {
        if (isOrganization) {
            return hasOrganizationOverage ? `Upgrade to Pro` : `Upgrade to Pro for free`;
        }
        
        if (isUnpaid) {
            return `Update Payment Method & Reactivate`;
        }
        
        return freeTrialOver ? `Upgrade to Pro` : `Start my 14-day Free Trial`;
    };

    const renderBillingOptions = () => {
        if (isOrganization) {
            return null;
        }

        if (isUnpaid && !retriesFailed) {
            return null;
        }

        if (isTeamsView) {
            return (
                <BillingChipContainer isMobileView={mobileView} flexDirection={'column'}>
                    <BillingChip
                        currency={currencyCode}
                        overridePrice={currencyPrices?.monthlyConverted}
                        label="Monthly"
                        isActive={billingCycle === BillingCycle.MONTHLY}
                        onClick={() => setBillingCycle(BillingCycle.MONTHLY)}
                    />
                    <BillingChip
                        currency={currencyCode}
                        overridePrice={currencyPrices?.annualConverted}
                        label="Yearly (15% Discount)"
                        isActive={billingCycle === BillingCycle.ANNUALLY}
                        onClick={() => setBillingCycle(BillingCycle.ANNUALLY)}
                    />
                </BillingChipContainer>
            );
        }

        return (
            <BillingPlanOptions
                mobileView={mobileView}
                billingCycle={billingCycle}
                partnerDiscount={partnerDiscount}
                setBillingCycle={setBillingCycle}
            />
        );
    };

    return (
        <BottomBar isMobileView={mobileView}>
            <FixedContainer>
                <ContainerComponent
                    display="flex"
                    alignItems={mobileView ? undefined : "center"}
                    justifyContent={mobileView ? undefined : "space-between"}
                    className="w-full"
                    flexDirection={mobileView ? "column" : "column"}
                >
                    {renderBillingOptions()}
                    <StyledLabel
                        fontSize={14}
                        style={{
                            marginBottom: mobileView ? (isTeamsView ? 0 : '8px') : '8px'
                        }}
                    >
                        {getPricingText()}
                    </StyledLabel>

                    <ContainerComponent
                        display="flex"
                        className={mobileView ? (isTeamsView ? "mt-2" : undefined) : undefined}
                        style={{ width: "100%" }}
                        flexDirection={"column"}
                    >
                        <PrimaryButton
                            loading={loading}
                            width="100%"
                            disabled={loading}
                            text={getCheckoutButtonText()}
                            onClick={handleClickUpgradeToProButton}
                        />
                        {secondaryButtons && secondaryButtons?.length > 0 && isOnboardingView && (
                            secondaryButtons.map((button, index) => (
                                <div key={`secondary-button-${index}`} className="w-full">
                                    {button}
                                </div>
                            ))
                        )}
                    </ContainerComponent>
                </ContainerComponent>
            </FixedContainer>
        </BottomBar>
    );
}; 