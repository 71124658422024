import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { FC, useContext, useEffect, useState } from "react";
import { FacebookBusinessManager } from "types/global";
import HeaderModal from 'pages/post-auth/MarketingPage/Components/HeaderModal';
import { FilteredList } from "components/shareable/FilteredList";
import { getBusinessManagers } from "services/facebookApi";
import { BusinessAccountDetail } from "./BusinessManagerDetail";
import { ReactComponent as FacebookIcon } from 'assets/images/facebook-rounded-icon.svg';
import { updateInBrand } from "services/symphonyApi/brandService";
import PrimaryButton from "components/shareable/PrimaryButton";
import { ConnectionsContext } from "pages/post-auth/MarketingPage/hooks/ConnectionsContext";
import { useStyles } from "./styles";

interface ConnectBusinessManagerSectionProps {
  creatingAdAccount: boolean;
  onClose: () => void;
  onReturn: () => void;
  onInitCreation: () => void;
}

export const ConnectBusinessManagerSection: FC<ConnectBusinessManagerSectionProps> = ({
  creatingAdAccount,
  onClose,
  onReturn,
  onInitCreation
}) => {
  const classes = useStyles();
  const { currentBrand, reloadBrand } = useContext(CurrentBrandContext);
  const { facebookBusinessManager, setFacebookBusinessManager, loggedInFbUser } = useContext(ConnectionsContext);
  const [businessManagerOptions, setBusinessManagerOptions] = useState<FacebookBusinessManager[]>([]);
  const [businessManagerToShow, setBusinessManagerToShow] = useState<FacebookBusinessManager[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getBusinessManagerOptions();
  }, []);

  const getBusinessManagerOptions = async () => {
    setLoading(true);
    try {
      const businessManagerOptions = await getBusinessManagers(loggedInFbUser?.access_token);
      setBusinessManagerOptions(businessManagerOptions);
      setBusinessManagerToShow(businessManagerOptions);
    } catch (e) {
      setBusinessManagerToShow([]);
    }
    setLoading(false);
  }

  const filterOptionsByName = (list: string[]) => {
    const selectedAdAccounts = businessManagerOptions.filter(account => list.includes(account.name));
    setBusinessManagerToShow(selectedAdAccounts);
  }

  const handleOnConnect = async (businessManager: FacebookBusinessManager) => {
    await updateInBrand(String(currentBrand!.id), String(currentBrand!.slug), businessManager, 'facebook_business_manager')
    setFacebookBusinessManager(businessManager);
    reloadBrand();
  }

  return (
    <div className='flex flex-col gap-8 max-h-full'>
      <HeaderModal
        title="Select a Business Manager Account where the Ad account will be created"
        subtitle="To create an Ad Account you have to select first the Business Manager Account where this account will be created"
        closeModal={onClose}
      />
      <FilteredList
        list={businessManagerOptions.map(businessManager => businessManager.name)}
        filterList={filterOptionsByName}
        isEmpty={businessManagerToShow.length === 0}
        emptyMessage="No business managers found"
        loading={loading}
      >
        <div className={classes.accountsContainer}>
          {businessManagerToShow.map((businessManager) =>
            <BusinessAccountDetail
              title={businessManager.name}
              descriptions={[]}
              icon={<FacebookIcon />}
              iconContainerColor='#1977f329'
              onConnect={() => handleOnConnect(businessManager)}
              disabled={loading}
              connected={facebookBusinessManager?.id === businessManager.id}
            />
          )}
        </div>
      </FilteredList>
      <div className='flex gap-4'>
        <PrimaryButton text="Back" onClick={onReturn} />
        <PrimaryButton
          text="Create"
          disabled={!facebookBusinessManager}
          onClick={() => {
            onInitCreation()
            onReturn()
          }}
        />
      </div>
    </div>
  );
};