/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  useEffect,
  useContext,
  SetStateAction,
  Dispatch,
} from "react"
import { GEOGRAPHY_TARGETING_DEFAULTS, TABS } from "../../../../../constants"
import { MarketingDataContext } from "../../Data/Provider"
import { AgeRange, FBCustomAudience, FacebookPageResponse, FbSuggestion } from "types/global"
import { getNotRemovableOptions } from "../TargetingView/Components/AutoCompleteAudiences/utils"
import uniqBy from "lodash/uniqBy"
import PreviewAdsCard from "./Components/PreviewAdsCard"
import DurationCard from "./Components/DurationCard"
import TargetingCard from "./Components/TargetingCard"
import BudgetCard from "../../Components/BudgetCardSummary"
import { checkIfIsProTier, fixGeographicTargets } from "utils"
import { Container } from "./styles"
import Grid from "@material-ui/core/Grid"
import { useMediaQuery, useTheme } from "@material-ui/core"
import {
  getFormattedDemographics,
  validateTargetingCountriesAge,
  removeCountries as removeCountriesUtils,
  distributeBudget
} from '../../utils/fbCampaigns';
import useModal from 'Hooks/useModal';
import TargetingWarning from '../../Components/TargetingWarningModal';
import { AgeRestrictedCountry } from '../../constants'
import { getTargetingViewGenre } from "../../Components/TargetingView/utils"
import GoLiveCard from "../../Components/GoLiveCard"
import { CampaignInput } from "../../Components/GoLiveCard/types"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { ConnectionsContext } from "../../hooks/ConnectionsContext"

const NOT_REMOVABLE = "NOT_REMOVABLE"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface FinalizationProps {
  draft: string | null
  goal: string
  assets: object
  captions: object
  budget: number
  startDate: any
  endDate: any
  record: any
  countryTargetingBreakdown: any
  setCountryTargetingBreakdown: (breakdown: any) => void
  interestTargetingAudiences: any
  setInterestTargetingAudiences: (audiences: any) => void
  setSelectedTab: (tab: string) => void
  setShowTargetingView: Dispatch<SetStateAction<boolean>>
  fbSuggestions: FbSuggestion[]
  conversionsEnabled: boolean
  cancelSaving: () => void
  handleSaveTargetingData: () => void;
  customAudiences: FBCustomAudience[];
  additionalPageAudiences: FacebookPageResponse[];

}

export default function Finalization({
  isBelowRecommendedBudget,
  draft,
  budget,
  startDate,
  endDate,
  record,
  setSelectedTab,
  addVisualSelected,
  allowCampaignCreation,
  setShowTargetingView,
  fbSuggestions,
  captions,
  assets,
  goal,
  igMedia,
  cancelSaving,
  handleSaveTargetingData,
  customAudiences,
  additionalPageAudiences,
  primaryOrganization,
  subtype
}: any) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const {
    targets: targetingViewGeographicTargets,
    audiences: targetingViewAudiences,
    demographics: targetingDemographics,
    saveDemographics,
  } = useContext(MarketingDataContext)

  const { currentBrand: brand } = useContext(CurrentBrandContext)

  const {
    facebookAdAccount: fbAdAccount,
    facebookPixel: fbPixel,
    facebookPage: fbPage,
    instagramPage: igPage,
    conversionsEnabled,
  } = useContext(ConnectionsContext)

  const targetingWarningModal = useModal()

  const [loadingStep, setLoadingStep] = useState(3)
  const [, setLoadingAudiences] = useState(false)
  const [creatingCampaign, setCreatingCampaign] = useState(false)
  const [countriesToFix, setCountriesToFix] = useState<AgeRestrictedCountry[]>([])

  const genres = brand?.connections?.spotify_artist_page?.genres
  const genre = getTargetingViewGenre(genres)
  const notRemovableOptions = getNotRemovableOptions(genre)
  const initialTargetingAudiences = uniqBy(
    [...notRemovableOptions, ...fbSuggestions],
    "id"
  )
  const targetAudiences = !!targetingViewAudiences.length
    ? targetingViewAudiences
    : initialTargetingAudiences
  const fixedGeographicTargets = !!targetingViewGeographicTargets.length
    ? fixGeographicTargets(targetingViewGeographicTargets)
    : fixGeographicTargets(GEOGRAPHY_TARGETING_DEFAULTS)
  const geographicTargets = !!targetingViewGeographicTargets.length
    ? targetingViewGeographicTargets
    : GEOGRAPHY_TARGETING_DEFAULTS
  const interestTargetingAudiences = targetAudiences
    .filter(({ id }) => !id?.includes(NOT_REMOVABLE))
    .map(({ id, name }) => ({
      fbId: id,
      name,
    }))
  const isProUser = checkIfIsProTier(brand)
  const freeTrialOver = brand?.freeTrialOver;

  const campaignInput: CampaignInput = {
    campaign_type: "increase_music_streams",
    id: draft,
    brand,
    record,
    captions,
    assets,
    goal,
    link: null,
    caption: null,
    cta: null,
    campaignName: null,
    fbAdAccount,
    fbPixel,
    fbPage,
    igPage,
    igMedia,
    budget,
    startDate,
    endDate,
    interestTargetingAudiences,
    customAudiences,
    targetedCountries: fixedGeographicTargets,
    targetingDemographics,
    addVisualSelected,
    conversionsEnabled,
  }

  const handleClickPreviewAds = () => setSelectedTab(TABS.CREATIVE)
  const handleImproveAudienceTargeting = () => setShowTargetingView(true)
  const handleEditGeographicTargeting = () => setShowTargetingView(true)
  const handleOnErrorPayPalOrder = () => setCreatingCampaign(false)

  const validateCountriesToFix = () => {
    if (countriesToFix.length) {
      targetingWarningModal.openModal()
      return false
    }
    return true
  }

  const updateAgeRange = (ageRange: AgeRange) => {
    const { min: minAge, max: maxAge } = ageRange
    const { gender, languages: locales } = targetingDemographics
    const demographics = getFormattedDemographics({ minAge, maxAge, gender, locales })
    saveDemographics(demographics)
    targetingWarningModal.closeModal()
    setCountriesToFix([])
  }

  const removeCountries = () => {
    const newGeographicTargets = removeCountriesUtils(geographicTargets, countriesToFix)
    distributeBudget(newGeographicTargets)

    handleSaveTargetingData()
    setCountriesToFix([])
    targetingWarningModal.closeModal()
  }

  useEffect(() => {
    const countries = validateTargetingCountriesAge(geographicTargets)
    const hasInvalidAge = countries.find(country => targetingDemographics?.age?.min < country.age)
    if (hasInvalidAge) {
      setCountriesToFix(countries)
      targetingWarningModal.openModal()
    }
  }, [geographicTargets, targetingDemographics.age])


  return (
    <Container>
      <Grid className={isMobile ? "mb-0" : "mb-4"}>
        <PreviewAdsCard
          {...{
            record, conversionsEnabled
          }}
          subtype={subtype}
          handleClick={handleClickPreviewAds}
        />
      </Grid>
      <Grid className={isMobile ? "mb-0" : "mb-4"}>
        <DurationCard {...{ startDate, endDate }} />
      </Grid>
      <Grid className={isMobile ? "mb-0" : "mb-4"}>
        <TargetingCard
          {...{
            subtype,
            isBelowRecommendedBudget,
            loadingStep,
            targetAudiences,
            customAudiences,
            geographicTargets,
            demographicTargets: targetingDemographics,
            saveDemographics,
            handleImproveAudienceTargeting,
            handleEditGeographicTargeting,
            openTargetingWarningModal: targetingWarningModal.openModal,
            additionalPageAudiences
          }}
        />
      </Grid>
      <Grid className={isMobile ? "mb-0" : "mb-4"}>
        <BudgetCard {...{
          budget,
          isProUser,
          freeTrialOver,
          adAccountName: fbAdAccount ? fbAdAccount.name : ""
        }} />
      </Grid>
      <Grid className={isMobile ? "mb-0" : "mb-4"}>
        <GoLiveCard
          budget={budget}
          creatingCampaign={creatingCampaign}
          isProUser={isProUser}
          campaignInput={campaignInput}
          allowCampaignCreation={allowCampaignCreation}
          setCreatingCampaign={setCreatingCampaign}
          cancelSaving={cancelSaving}
          validateCountriesToFix={validateCountriesToFix}
          submissionName={`$${budget.toLocaleString("en")} FB/IG Ads Campaign for "${record.name}"`}
          submissionDescription={`"${record.name}" (Increase Music Streams)`}
          checkout_source="Increase Music Streams - Campaign Creation"
          primaryOrganization={primaryOrganization}
        />
      </Grid>
      {Boolean(countriesToFix.length) && <TargetingWarning
        open={targetingWarningModal.open}
        closeModal={targetingWarningModal.closeModal}
        countries={countriesToFix}
        currentAgeRange={{
          min: targetingDemographics?.age?.min || 0,
          max: targetingDemographics?.age?.max || 0
        }}
        removeCountries={removeCountries}
        updateAgeRange={updateAgeRange}
      />}
    </Container>
  )
}
