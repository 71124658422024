import { AD_ACCOUNT_STATUS, queryFB } from 'helpers/FB';
import Axios from 'helpers/Interceptor';
import Logger from 'Logger';
import { ToastOptions } from 'react-toastify';
import {
  FacebookAdAccount,
  CurrentBrand,
  FacebookBusinessManager,
} from 'types/global';

export const TOAST_OPTIONS: ToastOptions = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  closeButton: true,
  style: {
    color: 'black',
    width: '160%',
    paddingLeft: '20px',
    marginLeft: '40px',
    fontSize: '16px',
  },
};

export const formatAdAccount = (adAccount: FacebookAdAccount) => {
  const { user_tasks } = adAccount;
  let userHasCorrectAccess = false;

  if (user_tasks) {
    const hasAnalyze = user_tasks.indexOf('ANALYZE') > -1;
    const hasAdvertise = user_tasks.indexOf('ADVERTISE') > -1;
    userHasCorrectAccess = hasAnalyze && hasAdvertise;
  }

  return {
    ...adAccount,
    amount_spent: Number(adAccount.amount_spent) / 100,
    userHasAccess: userHasCorrectAccess,
  };
};

export const applySortingToAdAccounts = (adAccounts: FacebookAdAccount[]) => {
  return adAccounts
    .sort(sortAdAccountByNames)
    .sort(sortAdAccountsByAccess)
    .sort(sortAdAccountByStatus);
};

export const sortAdAccountByNames = (
  a: FacebookAdAccount,
  b: FacebookAdAccount
) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  return 0;
};

export const sortAdAccountsByAccess = (
  a: FacebookAdAccount,
  b: FacebookAdAccount
) => {
  if (a.userHasAccess && b.userHasAccess) return 0;
  if (a.userHasAccess && !b.userHasAccess) return 1;
  if (!b.userHasAccess && a.userHasAccess) return -1;
  return 0;
};

export const sortAdAccountByStatus = (
  a: FacebookAdAccount,
  b: FacebookAdAccount
) => {
  const accountStatusA = (AD_ACCOUNT_STATUS as any)[a.account_status].valid;
  const accountStatusB = (AD_ACCOUNT_STATUS as any)[b.account_status].valid;

  if (
    (accountStatusA && accountStatusB) ||
    (!accountStatusA && !accountStatusB)
  )
    return 0;
  if (accountStatusB && !accountStatusA) return 1;
  if (!accountStatusB && accountStatusA) return -1;
  return 0;
};

export const connectAdAccount = async (
  adAccount: FacebookAdAccount,
  brand?: CurrentBrand
) => {
  try {
    const response = await Axios.put(`/brand/${brand?.slug}/connect`, {
      id: brand?.id,
      name: brand?.slug,
      service: 'facebook_ad_account',
      value: adAccount,
    });
    if (!response.data.isError) return true;
  } catch (e) {
    return false;
  }
};

export const getAdAccountsFromBusiness = async (setupType: string, businessManager?: FacebookBusinessManager, access_token?: string) => {
  try {
    let queryUrl = `/me/adaccounts`
    if (businessManager && setupType && setupType === 'custom') {
      queryUrl = `/${businessManager.id}/owned_ad_accounts`
    }

    const results = await queryFB(queryUrl, {
      params: {
        fields: `amount_spent,name,id,account_status,user_tasks,currency,business{name,id}`,
        limit: 250,
        access_token,
      }
    })
    const adAccounts = results.data.map(formatAdAccount)
    const sortedAdAccounts = applySortingToAdAccounts(adAccounts);
    return sortedAdAccounts;
  } catch (e) {
    return [];
  }
};

export const createAdAccount = async (businessManager?: FacebookBusinessManager, access_token?: string) => {
  try {
    if (!businessManager) throw new Error('You need to be logged in to create an ad account');
    const creationUrl = `/${businessManager.id}/adaccount`;
    const creationPayload = {
      name: 'Symphony Ad Account',
      currency: 'USD',
      timezone_id: 1,
      media_agency: 'NONE',
      end_advertiser: businessManager.id,
      partner: process.env.REACT_APP_FACEBOOK_APP_ID,
      access_token,
    };

    const createdAdAccount = await queryFB(creationUrl, {
      method: 'post',
      params: creationPayload,
    });

    if (!createdAdAccount || createdAdAccount.error) throw new Error('There was an error creating the ad account');

    const user = await queryFB('/me', {
      method: 'get',
      params: {
        fields: 'business_users',
        access_token,
      },
    });

    const foundBusinessUser = user.business_users.data.find(
      (user: any) => user?.business?.id === businessManager.id
    );

    if (!foundBusinessUser) throw new Error('No business found for user');

    const url = `/${createdAdAccount.id}/assigned_users`;
    const payload = {
      user: foundBusinessUser.id,
      tasks: ['MANAGE', 'ADVERTISE', 'ANALYZE', 'DRAFT'],
      access_token,
    };

    const userAdded = await queryFB(url, {
      method: 'post',
      params: payload,
    });
    if (!userAdded || userAdded.error) throw new Error('There was an error adding the user to the ad account');

    return createdAdAccount;
  } catch (e: any) {
    Logger.error('Error creating ad account', e);
    return {
      isError: true,
      errorTitle: e?.error?.error_user_title || e.error.message,
      errorDescription: e?.error?.error_user_msg || null
    };
  }
};
