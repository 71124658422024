export enum SystemColors {
  DARK_SUCCESS = '#07c80629',
  WARNING_COLOR = '#E4900D',
  DARK_WARNING = '#E4900D29',
  ACCENT_COLOR = '#8800FF',
  ACCENT_COLOR_LIGHT = '#8800FF14',
  SUCCESS = '#07C806',
  ACCENT_COLOR_ON_DARK = '#B982FB',
  GREEN_COLOR = '#00892d',
  GREEN_COLOR_ON_GREEN = '#00892d4f',
  ERROR_COLOR = '#FF3D3D',
  PRIMARY_TEXT_COLOR = '#000000',
  SECONDARY_TEXT_COLOR = '#707070',
  DISABLED_TEXT_COLOR = '#B2B2B2',
  PRIMARY_DISABLE_CONTAINER = '#B2B2B2',
  SECONDARY_DISABLE_CONTAINER = '#707070',
  DARK_BLUE_PAPER_CONTAINER = '#1977f314',
  WHITE_TEXT_COLOR = '#FFFFFF',
  DIVIDER_LINE = '#EDECF2',
  DARK_DIVIDER_LINE = '#0000001F',
  PAPER_CONTAINER = '#FFFFFF',
  VIEW_ONLY_BACKGROUND = '#1977f350', // Brighter blue background (increased opacity from 14 to 29)
  PAPER_DARK_CONTAINER = '#000000',
  PAPER_LIGHT_CONTAINER = '#FFFFFFCB',
  PAPER_ERROR_CONTAINER = '#FF000028',
  PAPER_WARNING_CONTAINER = '#E4900D28',
  PAPER_BACKGROUND_CONTAINER = '#F5F3F6',
  PAPER_DIVIDER_CONTAINER = '#EDECF2',
  PAPER_DIVIDER_CONTAINER_DARK = '#D9D9D9',
  PAPER_LIGHT_GRAY_CONTAINER = '#E9E9E9',
  PAPER_ACCENT_CONTAINER = '#8800FF14',
  PAPER_ACCENT_CONTAINER_ON_DARK = '#B982FB63',
  PAPER_SUCCESS_CONTAINER = '#EBFBEB',
  VIDEO_BUTTON_CONTAINER = '#00000014',
  LIGHT_VIDEO_HOVER_LAYOUT = '#0000007A',
  DARK_VIDEO_HOVER_LAYOUT = '#000000B8',
  PAPER_DIVIDER_CONTAINER_LIGHT = '#EDECF214',
  GRADIENT = 'linear-gradient(166deg, #80F 0%, #F680C5 100%)',
  BAR_COLOR = '#2C2135',
  BACKGROUND_EMPTY_STATE = '#F0F0EF',
  DARK_GRAY = '#393939',
  BACKGROUND_CHART_BILLING = '#E4900D14',
  GRID_CHART_BILLING = '#7a7a7a80',
  GREEN_COLOR_LIGHT = '#1ED7601F',
  BACKGROUND_TIKTOK_ICON = '#19191929',
  BACKGROUND_YOUTUBE_ICON = '#ff000029',
  GRADIENT_INSTAGRAM = 'radial-gradient(144.41% 122.74% at 1.81% 90.83%, rgba(254, 213, 118, 0.16) 0%, rgba(244, 113, 51, 0.16) 26.34%, rgba(188, 48, 129, 0.16) 60.91%, rgba(76, 99, 210, 0.16) 100%)',
}
