import { Fragment, FunctionComponent, useContext, useState } from "react"
import PrimaryButton from "components/shareable/PrimaryButton"
import { ActionsContainer, WarningContainer } from "./styles"
import { ReactComponent as PlayIcon } from "assets/images/play-purple.svg"
import { Body2, Subtitle1 } from "components/shareable/Typography"
import SecondaryButton from "components/shareable/SecondaryButton"
import { SystemColors } from "types/globalStyles"
import { Row } from "../UsersTab/utils"
import DialogPaper from "components/shareable/DialogPaper"
import HeaderModal from "components/connect/connectModals/Spotify/Components/HeaderModal"
import useMediaBreakpoints from "Hooks/useMediaBreakpoints"
import { PRO_PLAN_ADDITIONAL_SEAT_PRICE } from "../../../../../constants"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { deleteBrandUser, PulledUsers } from "services/symphonyApi/brandService"

interface Props {
  brandId?: number | null
  member: Row | null
  open: boolean
  willNotTouchLimit?: boolean
  onSetDeletedUser: (user: Row | null) => void
  onSaveUsers: (pulledUsers: PulledUsers) => void
  onClose: () => void
}

const DeleteUserModal: FunctionComponent<Props> = ({
  brandId,
  member,
  open,
  willNotTouchLimit,
  onSaveUsers,
  onSetDeletedUser,
  onClose,
}: Props) => {
  const { mobileView } = useMediaBreakpoints()
  const [loading, setLoading] = useState(false)
  const { currentBrand } = useContext(CurrentBrandContext)
  const isOnOldPlan = currentBrand?.subscription?.onOldPlan

  const handleDeleteUser = async () => {
    setLoading(true)
    await deleteBrandUser({
      brandId,
      potentiallyDeletedUser: member,
      mobileView,
      onSaveUsers,
      onSetDeletedUser,
    })
    setLoading(false)
    onClose()
  }

  const handleClose = () => !loading && onClose()

  return (
    <DialogPaper {...{ open }} onClose={handleClose}>
      <Fragment>
        <HeaderModal
          HeaderIcon={<PlayIcon />}
          title="Remove member"
          subtitle="Are you sure you want to remove this member?"
          closeModal={handleClose}
        />
        <Subtitle1>{member?.email}</Subtitle1>
        {willNotTouchLimit && !isOnOldPlan && (
          <WarningContainer>
            <Subtitle1>Your monthly subscription will be reduced.</Subtitle1>
            <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
              Since you’re removing an extra user, your next invoice will be
              reduced by ${PRO_PLAN_ADDITIONAL_SEAT_PRICE}
            </Body2>
          </WarningContainer>
        )}
        <ActionsContainer>
          <SecondaryButton disabled={loading} text="Cancel" onClick={onClose} />
          <PrimaryButton
            {...{ loading }}
            width="202px"
            disabled={loading}
            variant="error"
            text="Yes, remove member"
            onClick={handleDeleteUser}
          />
        </ActionsContainer>
      </Fragment>
    </DialogPaper>
  )
}

export default DeleteUserModal
