import { Fragment, FunctionComponent, useContext, useState } from "react"
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import Axios from "helpers/Interceptor";
import PrimaryButton from "components/shareable/PrimaryButton"
import DialogPaper from "components/shareable/DialogPaper"
import HeaderModal from "components/connect/connectModals/Spotify/Components/HeaderModal"
import { ActionsContainer } from "./styles"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { SHARED_TOAST_OPTIONS, SHARED_TOAST_OPTIONS_ERROR } from "pages/post-auth/MarketingPage/Components/Modals/utils"
import { track } from "analytics";
import { useDetectAdBlock } from "adblock-detect-react";
import { goToUrl } from "utils";

interface Props {
  open: boolean
  onClose: () => void
  onReloadData: (data: { brandSlug?: string | null }) => Promise<void>
}

const CouponModal: FunctionComponent<Props> = ({
  open,
  onClose,
  onReloadData,
}: Props) => {
  const adBlockDetected = useDetectAdBlock();
  const [loading, setLoading] = useState(false)
  const { currentBrand } = useContext(CurrentBrandContext)
  const {
    slug: brandSlug,
    subscription: subscriptionDetails,
  } = currentBrand || {}

  const handleClose = () => !loading && onClose()

  const handleCancel = () => {
    if (adBlockDetected) {  
      onCancelSubscriptionPortal()
    } else {
      if (!loading) onClose()
    }
  }

  const onCancelSubscriptionPortal = async () => {
    if (loading) return
    try {
      setLoading(true);
      const { data } = await Axios.post(`/subscription/brand/${brandSlug}/cancel-subscription-portal`);
      track("User Redirect to Stripe Cancel Subscription", {
        brandSlug,
        ...(subscriptionDetails ? subscriptionDetails : {}),
      })

      goToUrl(data.data.url, setLoading);
      handleClose()
    } catch (error) {
      Sentry.captureException(error);
      console.error("onCancelSubscriptionPortal: ", error);
      toast.error("Something went wrong. Please try again or contact support.", SHARED_TOAST_OPTIONS_ERROR);
    } finally {
      setLoading(false)
    }
  }
  
  const onRedeemCouponOnCancelation = async () => {
    if (loading) return
    try {
      setLoading(true);
      await Axios.post(`/subscription/brand/${brandSlug}/redeem-coupon-on-cancelation`);
      track("User Manually Redeemed Coupon on Cancelation", {
        brandSlug,
        ...(subscriptionDetails ? subscriptionDetails : {}),
      })
      await onReloadData({ brandSlug })

      handleClose()
      toast.success("Discount successfully applied.", SHARED_TOAST_OPTIONS);
    } catch (error) {
      Sentry.captureException(error);
      console.error("onRedeemCouponOnCancelation: ", error);
      toast.error("Something went wrong. Please try again or contact support.", SHARED_TOAST_OPTIONS_ERROR);
    } finally {
      setLoading(false)
    }
  }

  return (
    <DialogPaper {...{ open }} onClose={handleClose}>
      <Fragment>
        <HeaderModal
          title="Before you cancel, maybe we can help"
          subtitle="Get 50% off Pro for the next 2 months + a free consultation. We'd hate to lose you."
          closeModal={handleClose}
        />
        <ActionsContainer>
          {!loading && (
            <div id="barecancel-trigger">
              <PrimaryButton
                {...{ loading }}
                disabled={loading}
                variant="outlined"
                text="Cancel Subscription"
                onClick={handleCancel}
            />
            </div>
          )}
          <PrimaryButton
            {...{ loading }}
            width="240px"
            disabled={loading}
            variant="light"
            text="Redeem 50% off for 2 months"
            onClick={onRedeemCouponOnCancelation}
          />
        </ActionsContainer>
      </Fragment>
    </DialogPaper>
  )
}

export default CouponModal
